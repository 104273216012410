import React, { useState, useEffect, useContext, useRef } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import { Button, Tab } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import { partnerInstance } from "../../../config/axios";
import { validateInvoice } from "../../../function/validate";
import history from "../../../config/history";
import SignupInvoiceSeqencing from "./signupInvoiceSeqencing";
import PartnerContext from "../../../context/context";

const SignupInvoice = () => {
  const { info } = useLocation();
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [title, setTitle] = useState("");
  const [customLine1, setCustomLine1] = useState("");
  const [customLine2, setCustomLine2] = useState("");
  const [receiptFooter, setReceiptFooter] = useState("");
  const [automaticPrint, setAutomaticPrint] = useState(false);
  const [clientAddress, setClientAddress] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const getInvoice = async () => {
      //setLoading(true);
      const invoiceData = await partnerInstance().get("/getInvoice");
      const { code, invoice } = invoiceData.data;
      if (code === 200) {
        setTitle(invoice.title);
        setCustomLine1(invoice.customline1);
        setCustomLine2(invoice.customline2);
        setReceiptFooter(invoice.footer);
        setClientAddress(invoice.clientaddress);
        setAutomaticPrint(invoice.automaticprint);
        //setLoading(false);
      }
    };

    //setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getInvoice();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const addInvoiceTemplate = async (e) => {
    e.preventDefault();
    const payload = {
      title,
      customline1: customLine1,
      customline2: customLine2,
      footer: receiptFooter,
      automaticprint: automaticPrint,
      clientaddress: clientAddress,
    };
    const validation = validateInvoice({
      title,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const updateTemplate = await partnerInstance().put("/editInvoice", payload);
    const { code, msg } = updateTemplate.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      if (info) {
        history.push("/partners/availablity");
      } else {
        history.push("/partners/setup");
      }

      // clearValues();
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <div className="invoice staff-page signupinvoive">
      {/* <div className="container">
        <div className="row">
          <div className="col-md-12"> */}
      <div className="invoice-tab-content">
        <Tabs defaultActiveKey="0" id="invoice-tab">
          <Tab eventKey="0" title="Invoice Configuration">
            <Form onSubmit={addInvoiceTemplate}>
              <Card className="inv-12">
                <Card.Img variant="top" />
                <Card.Body>
                  <Card.Text>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>
                            Invoice Title
                            <span className="red-color">*</span>
                          </Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Invoice"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                          />
                          {errors.title && (
                            <p className="error">{errors.title}</p>
                          )}
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Invoice Line 1</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            onChange={(e) => setCustomLine1(e.target.value)}
                            value={customLine1}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Invoice Line 2</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            onChange={(e) => setCustomLine2(e.target.value)}
                            value={customLine2}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Invoice Footer</Form.Label>

                          <Form.Control
                            size="lg"
                            type="text"
                            placeholder=""
                            onChange={(e) => setReceiptFooter(e.target.value)}
                            value={receiptFooter}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Form.Group>
                          <InputGroup.Prepend>
                            <div className="online-booking">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={automaticPrint}
                                  onChange={(e) =>
                                    setAutomaticPrint(e.target.checked)
                                  }
                                />
                                <span className="slider round" />
                              </label>
                            </div>{" "}
                            <Form.Label className="">
                              Print receipts upon sale completion
                            </Form.Label>
                          </InputGroup.Prepend>
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Form.Group>
                          <InputGroup.Prepend>
                            <div className="online-booking">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={clientAddress}
                                  onChange={(e) =>
                                    setClientAddress(e.target.checked)
                                  }
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                            <Form.Label className="">
                              Customer contact visible on invoices
                            </Form.Label>
                          </InputGroup.Prepend>
                        </Form.Group>
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>

                <div className="account-save mt-2 col-md-12 mb-4">
                  <Button type="submit" className="btn btn-perple">
                    Save
                  </Button>
                </div>
              </Card>
            </Form>
          </Tab>

          <Tab eventKey="1" title="Invoice Sequencing">
            <SignupInvoiceSeqencing />
          </Tab>
        </Tabs>
      </div>
      {/* </div>
        </div>
      </div> */}
    </div>
  );
};

export default SignupInvoice;
