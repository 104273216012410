import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OnlineProfile from './onlineProfile';
import ClientReviews from './clientReviews';
import Overview from './overviewPage';

const OnlineBooking = () => {
  const [key, setKey] = useState('overview');
  return (
    <div className="online-booking-page">
      <div className="container">
        <div className="online-booking-tab">
          <Tabs activeKey={key} id="uncontrolled-tab-example" onSelect={(e) => setKey(e)}>
            <Tab eventKey="overview" title="Overview">
              <Overview setKey={setKey} />
            </Tab>
            <Tab eventKey="profile" title="Online Profile">
              <OnlineProfile />
            </Tab>
            <Tab eventKey="review" title="Client Reviews">
              <ClientReviews />
            </Tab>

            {/* <Tab eventKey="link" title="Buttons and Links">
              nbgjhgjk
            </Tab>
            <Tab eventKey="tracking" title="Analytics Tracking">
              cvhgcj
            </Tab>

            <Tab eventKey="setting" title="Settings">
              nbgjhgjk
            </Tab> */}

          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default OnlineBooking;
