import React, { useState, useEffect, useCallback } from "react";
import { Form, Dropdown, Button, Tab, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import queryString from "query-string";
import moment from "moment";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { partnerInstance } from "../../../config/axios";
import { validateStaff } from "../../../function/validate";
import history from "../../../config/history";
import Loader from "../../loader/loader";
import { countrycode } from "../register/code";

const array = [];
const EditStaff = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [staffTitle, setStaffTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [errors, setErrors] = useState({});
  // const [checked2, setChecked2] = useState(false);
  const [userPermission, setUserPermission] = useState("");
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [appointmentBooking, setAppointmentBooking] = useState(false);
  // const [serviceid, setServiceId] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceCommission, setServiceCommission] = useState("");
  const [voucherCommission, setVoucherCommission] = useState("");
  const [selectAll, setSelectAll] = useState(true);
  const [selectLocationAll, setSelectLocationAll] = useState(true);
  // const [checked, setChecked] = useState(false);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [startEmployementDate, setStartEmployementDate] = useState(currentDate);
  const [endEmployementDate, setEndEmployementDate] = useState("");
  const [ID, setID] = useState("");
  const [locations, setLocations] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [key, setKey] = useState("0");
  // const [selectedService, setSelectedService] = useState([]);
  // const [phoneCodeList, setPhoneCodeList] = useState([]);

  useEffect(() => {
    // const arrayofid = [];
    const value = queryString.parse(window.location.search);
    const { id } = value;
    const getStaff = async () => {
      setLoading(true);
      const idd = {
        staffid: id,
      };
      setID(id);
      const staffData = await partnerInstance().post("/getStaff", idd);
      const { code, stafflist, servicelist, partnerLocation } = staffData.data;
      if (code === 200) {
        setEmail(stafflist.email);
        setFirstname(stafflist.firstname);
        setLastname(stafflist.lastname);
        setStaffTitle(stafflist.staffTitle);
        setNote(stafflist.note);
        setPhone(stafflist.phone);
        setPhoneCode(stafflist.phoneCode);
        // setStaff([...staff, service.staff]);
        setStartEmployementDate(
          stafflist.startEmployementDate
            ? stafflist.startEmployementDate
            : currentDate
        );
        setEndEmployementDate(stafflist.endEmployementDate);
        setUserPermission(stafflist.userpermissiontype);
        setAppointmentBooking(stafflist.appointmentBooking);
        setServiceCommission(parseInt(stafflist.servicecommission, 10));
        setVoucherCommission(parseInt(stafflist.vouchercommission, 10));
        // setSelectedService(stafflist.serviceid);
        const countryy = await partnerInstance().get("/getPartnerAccount");
        const { partnerData } = countryy.data;
        countrycode.map((el) =>
          partnerData.address.forEach((e) => {
            if (el.name === e.country) {
              setPhoneCode(el.code);
            }
          })
        );

        servicelist.forEach((el) => {
          const filterSelected = stafflist.serviceid.filter(
            (ele) => ele === el._id
          );
          if (filterSelected.length > 0) {
            el.checked = true;
          }
        });
        setServices(servicelist);
        const filter = servicelist.filter((item) => item.checked);
        if (filter.length === servicelist.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }

        partnerLocation.forEach((el) => {
          const filterSelected = stafflist.locationId.filter(
            (ele) => ele === el._id
          );
          if (filterSelected.length > 0) {
            el.checkedl = true;
          }
        });
        setLocations(partnerLocation);
        const filterTwo = partnerLocation.filter((item) => item.checkedl);
        if (filterTwo.length === partnerLocation.length) {
          setSelectLocationAll(true);
        } else {
          setSelectLocationAll(false);
        }
        setLoading(false);
      }
    };

    getStaff();
    // eslint-disable-next-line
  }, []);

  const updateStaff = async (e) => {
    setDisabled(true);
    const arrayofid = [];
    const arrayoflocation = [];
    e.preventDefault();
    // Try to understand this code simple stuff
    const filterSelectedIds = services.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });

    const filterSelectedLocation = locations.filter((el) => el.checkedl);
    filterSelectedLocation.forEach((el) => {
      arrayoflocation.push(el._id);
    });

    const payload = {
      firstname,
      lastname,
      email,
      staffTitle,
      note,
      phone,
      phoneCode,
      userPermission,
      // startDate,
      // endDate,
      locationId: arrayoflocation,
      appointmentBooking,
      serviceid: arrayofid,
      servicecommission: serviceCommission,
      vouchercommission: voucherCommission,
      startEmployementDate,
      endEmployementDate,

      // checked: false,
    };
    const validation = validateStaff({
      firstname,
      email,
      serviceCommission,
      voucherCommission,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      setDisabled(false);
      if (
        validation.errors.serviceCommission ||
        validation.errors.voucherCommission
      ) {
        setKey("2");
      } else {
        setKey("0");
      }
      return;
    }
    const saveData = await partnerInstance().put(`/editStaff/${ID}`, payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      history.push("/partners/staff");
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
      setDisabled(false);
    }
  };
  const clearValues = () => {
    setEmail("");
    setFirstname("");
    setLastname("");
    // setChecked2('');
    setPhone("");
  };
  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();

  const handleCheck = (e, el) => {
    const oldState = [...services];
    oldState.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    const filter = oldState.filter((item) => item.checked);
    if (filter.length === oldState.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setServices(services);
    update();
  };

  const handleSelectAll = (e) => {
    setSelectAll(!selectAll);
    services.forEach((el) => {
      if (selectAll) {
        services.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        setServices(array);
      } else {
        services.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
        setServices(services);
        update();
      }
    });
    setServices(services);
    update();
  };
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };

  const handleLocationCheck = (e, el) => {
    const oldState = [...locations];
    oldState.forEach((element) => {
      const { checkedl, _id } = element;
      if (el._id === _id) {
        element.checkedl = !checkedl;
      }
    });
    const filter = oldState.filter((item) => item.checkedl);
    if (filter.length === locations.length) {
      handleLocationSelectAll();
    } else {
      setSelectLocationAll(false);
    }
    setLocations(locations);
    update();
  };
  const handleLocationSelectAll = (e) => {
    setSelectLocationAll(!selectLocationAll);
    locations.forEach((el) => {
      if (selectLocationAll) {
        locations.forEach((element) => {
          el.checkedl = false;
          array.pop(element);
        });
        setLocations(array);
      } else {
        locations.forEach((el) => {
          el.checkedl = true;
          array.push(el._id);
        });
        setLocations(locations);
        update();
      }
    });
    setLocations(locations);
    update();
  };

  return (
    <PartnerWrapper heading="Edit Associate">
      {loading && <Loader />}
      <div className="add-staff">
        <div className="container">
          {/* <h2 className="admin-title">Edit Associate</h2> */}

          <div className="add-staff-content">
            <Form onSubmit={updateStaff} key={key}>
              <Tabs
                defaultActiveKey={key}
                id="staff-tab-example"
                onSelect={(e) => setKey(e)}
              >
                <Tab eventKey="0" title="Details">
                  <div className="add-staff-details">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicFirst">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your first name"
                                name="firstName"
                                autocomplete="off"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                              />
                              {errors.firstname && (
                                <p className="error">{errors.firstname}</p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your last name"
                                name="nickName"
                                autocomplete="off"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Mobile number</Form.Label>
                              <div className="number-fields">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    {phoneCode}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {countrycode.map((el) => (
                                      <Dropdown.Item
                                        key={el.id}
                                        onClick={() => {
                                          selectPhoneCode(el.code);
                                        }}
                                      >
                                        {" "}
                                        {el.code}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                  type="number"
                                  placeholder="Your mobile number"
                                  name="phone"
                                  autocomplete="off"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                />
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Your email address"
                                name="email"
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                              {errors.email && (
                                <p className="error">{errors.email}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>User Permission</Form.Label>
                              <Form.Control
                                as="select"
                                onChange={(e) =>
                                  setUserPermission(e.target.value)
                                }
                                value={userPermission}
                              >
                                <option value="No Access" defaultValue>
                                  No Access
                                </option>
                                <option value="Normal">Normal</option>
                                <option value="Advanced">Advanced</option>
                                <option value="Admin">Admin</option>
                              </Form.Control>
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <div className="online-booking">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setAppointmentBooking(!appointmentBooking)
                                  }
                                  checked={appointmentBooking}
                                />
                                <span className="slider round" />
                              </label>{" "}
                              Enable Appointments Booking
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Associate Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Associate Title"
                                name="text"
                                autoComplete="off"
                                onChange={(e) => setStaffTitle(e.target.value)}
                                value={staffTitle}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Notes</Form.Label>
                              <textarea
                                className="form-control text optional employee-modal-note"
                                rows="12"
                                placeholder="Add Notes"
                                name="employee[notes]"
                                id="employee_notes"
                                onChange={(e) => setNote(e.target.value)}
                                value={note}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Row>
                                <Form.Group className="col-md-6">
                                  <Form.Label>Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    default={startEmployementDate}
                                    onChange={(e) =>
                                      setStartEmployementDate(e.target.value)
                                    }
                                    value={startEmployementDate}
                                  />
                                </Form.Group>

                                <Form.Group className="col-md-6">
                                  <Form.Label>End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    min={startEmployementDate}
                                    onChange={(e) =>
                                      setEndEmployementDate(e.target.value)
                                    }
                                    value={endEmployementDate}
                                  />
                                </Form.Group>
                              </Row>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="1" title="Services">
                  <div className="add-services">
                    <p className="text-muted ">
                      Assign services this staff member can perform.
                    </p>
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group>
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                              <Form.Label className="sel-1">
                                Select All
                              </Form.Label>
                            </InputGroup.Prepend>
                          </Form.Group>
                        </div>

                        {/* {Object.keys(services).map((el) => (
                          <>
                            {' '}
                            <div className="col-sm-4">
                              <Form.Group>
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox />
                                  <Form.Label>
                                    {services[el].servicename}
                                  </Form.Label>
                                </InputGroup.Prepend>
                              </Form.Group>
                            </div>
                          </>
                        ))} */}
                        {services.map((item, i) => (
                          <div className="col-sm-4" key={i}>
                            <Form.Group>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  checked={item.checked}
                                  value={item._id}
                                  onClick={(e) => handleCheck(e, item)}
                                />
                                <Form.Label>{item.serviceName}</Form.Label>
                              </InputGroup.Prepend>
                            </Form.Group>
                          </div>
                        ))}
                      </div>
                    </Form>
                  </div>
                </Tab>

                <Tab eventKey="2" title="Commission">
                  <div className="add-commission">
                    <Form>
                      <div className="row">
                        <div className="col-sm-4">
                          <Form.Label>SERVICE COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) =>
                                setServiceCommission(e.target.value)
                              }
                              value={serviceCommission}
                            />
                            {errors.serviceCommission && (
                              <p className="error">
                                {errors.serviceCommission}
                              </p>
                            )}
                          </InputGroup>
                        </div>

                        {/* <div className="col-sm-4">
                          <Form.Label>PRODUCT COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              aria-label="Amount (to the nearest dollar)"
                            />
                          </InputGroup>
                        </div> */}

                        <div className="col-sm-4">
                          <Form.Label>VOUCHER COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) =>
                                setVoucherCommission(e.target.value)
                              }
                              value={voucherCommission}
                            />
                            {errors.voucherCommission && (
                              <p className="error">
                                {errors.voucherCommission}
                              </p>
                            )}
                          </InputGroup>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Tab>

                <Tab eventKey="3" title="Location">
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group>
                          <InputGroup.Prepend>
                            <InputGroup.Checkbox
                              checked={selectLocationAll}
                              onChange={handleLocationSelectAll}
                            />
                            <Form.Label className="sel-1">
                              Select All
                            </Form.Label>
                          </InputGroup.Prepend>
                        </Form.Group>
                      </div>
                      {locations.map((loc, i) => (
                        <div className="col-sm-4" key={i}>
                          <Form.Group>
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox
                                checked={loc.checkedl}
                                value={loc._id}
                                onClick={(e) => handleLocationCheck(e, loc)}
                              />
                              <Form.Label>{loc.locationName}</Form.Label>
                            </InputGroup.Prepend>
                          </Form.Group>
                        </div>
                      ))}
                    </div>
                  </Form>
                </Tab>
              </Tabs>

              <div className="row">
                <div className="col-md-12 ">
                  <div className="text-right mt">
                    <Button
                      disabled={disabled}
                      className="btn-perple mr-4"
                      type="submit"
                    >
                      Save
                    </Button>
                    <Link to="/partners/staff">
                      <Button variant="primary" className="btn-perple">
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default EditStaff;
