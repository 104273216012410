/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import store2 from "../../../assets/no-image.jpg";
import { websiteInstance } from "../../../config/axios";
// import history from '../../../config/history';
import Helmet from "react-helmet";
import { server } from "../../../config/keys";

const SelectServices = () => {
  const { state } = useLocation();

  let priceTotal = 0;
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [categories, setCategories] = useState([]);
  const [selectServices, setSelectServices] = useState(
    state ? state.servicesPanel : []
  );
  const [selectStaffs, setSelectStaffs] = useState([]);
  const [partnerSlot, setPartnerSlot] = useState({});
  const [sameCategory, setSameCategory] = useState(
    state ? state.sameCategory : false
  );
  const [hasBundle, setHasBundle] = useState(state ? state.hasBundle : false);
  const [currency, setCurrency] = useState("");
  const [storeImage, setStoreImage] = useState("");
  const [paymentRequired, setPaymentRequired] = useState(
    state ? state.paymentRequired : false
  );
  const [outService, setOutService] = useState(
    state ? state.outService : false
  );
  const [eventKey, setEventKey] = useState(state ? state.event : 0);

  // setID(id);
  const getBusinessPartner = useCallback(async () => {
    const payload = {
      partnerid: id,
    };
    const partnersData = await websiteInstance().post(
      "/getPartnerByPartnerId",
      payload
    );

    const { code, partnerData } = partnersData.data;
    if (code === 200) {
      // setPartner(partnerData);
      setCategories(partnerData.categoryid);
      setSelectStaffs(partnerData.staff);
      setPartnerSlot(partnerData.partnerLocation[0]);
      setCurrency(partnerData.currency);
      setStoreImage(partnerData.photo);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBusinessPartner();
  }, [getBusinessPartner]);

  const checkedServices = (data) => {
    const servicesCopy = [...selectServices];
    const index = servicesCopy.filter((el) => el.id === data._id);
    if (index.length === 0) {
      servicesCopy.push({
        servicename: data.serviceName,
        duration: data.duration,
        amount: data.specialPrice ? data.specialPrice : data.priceFrom,
        id: data._id,
        services: null,
        categoryid: data.serviceCategory,
        payment: data.isPayment,
      });
      setSelectServices(servicesCopy);
      const allEqual = servicesCopy.every(
        (v) => v.categoryid === servicesCopy[0].categoryid
      );
      setSameCategory(allEqual);
      if (servicesCopy[0].payment) {
        setPaymentRequired(true);
      } else if (!servicesCopy[0].payment && data.isPayment) {
        setPaymentRequired(true);
      } else {
        setPaymentRequired(false);
      }
      if (servicesCopy[0].outService) {
        setOutService(true);
      } else if (!servicesCopy[0].outService && data.outService) {
        setOutService(true);
      } else {
        setOutService(false);
      }
    } else {
      const removeService = servicesCopy.findIndex(
        (el) => el.id === index[0].id
      );
      if (removeService > -1) {
        servicesCopy.splice(removeService, 1);
        setSelectServices(servicesCopy);
      }
    }
  };

  const checkedBundles = (data) => {
    const servicesCopy = [...selectServices];
    const index = servicesCopy.filter((el) => el.id === data._id);
    if (index.length === 0) {
      servicesCopy.push({
        servicename: data.packagename,
        duration: data.packageDuration,
        amount: data.packageSpecialPrice,
        id: data._id,
        services: data.serviceid,
        categoryid: data.packagecategory,
        priceType: data.priceType,
        retailPrice: data.retailPrice,
        packagePrice: data.packagePrice,
        payment: true,
      });
      setSelectServices(servicesCopy);
      const allEqual = servicesCopy.every(
        (v) => v.categoryid === servicesCopy[0].categoryid
      );
      setSameCategory(allEqual);
      setHasBundle(true);
      setPaymentRequired(true);
    } else {
      const removeService = servicesCopy.findIndex(
        (el) => el.id === index[0].id
      );
      if (removeService > -1) {
        servicesCopy.splice(removeService, 1);
        setSelectServices(servicesCopy);
      }
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps" />
      </Helmet>
      <div className="service-bg new-select-service bg-color">
        <div className="container">
          <h2 className="page-title">
            <p>
              <span>Step 1 | </span> Select Services
            </p>
            <Link className="btn" to={`/storedetail/?partnerid=${id}`}>
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>
          <div className="select-serve">
            <div className="store-services ">
              <div className="row">
                <div className="col-md-4">
                  <div className="service-card">
                    <div className="box-details">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={storeImage ? `${server}/${storeImage}` : store2}
                          alt="store"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = store2;
                          }}
                        />
                        <Card.Body>
                          <Card.Title className=" heading-bold">
                            {partnerSlot.locationName}
                          </Card.Title>
                          <Card.Text className="">
                            {partnerSlot.addressFormat}

                            {partnerSlot.country}
                          </Card.Text>
                          <div className="border-des">
                            <div className="left-scrollbar">
                              {selectServices.length > 0 ? (
                                <>
                                  {selectServices.map((ele) => {
                                    priceTotal += parseInt(ele.amount);
                                    return (
                                      <div className="content-detail">
                                        <div className="product">
                                          <h6>{ele.servicename}</h6>
                                          <span>
                                            {Math.floor(
                                              parseInt(ele.duration) / 60
                                            ) === 0 ? (
                                              <>
                                                {parseInt(ele.duration) % 60}{" "}
                                                min
                                              </>
                                            ) : (
                                              <>
                                                {Math.floor(
                                                  parseInt(ele.duration) / 60
                                                )}
                                                h{" "}
                                                {parseInt(ele.duration) % 60 ===
                                                0 ? (
                                                  ""
                                                ) : (
                                                  <>
                                                    ,
                                                    {parseInt(ele.duration) %
                                                      60}
                                                    min
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                        <div className="rate">
                                          <span>
                                            {ele.amount === "0"
                                              ? "Free"
                                              : ele.amount}{" "}
                                            {ele.amount !== "0" ? currency : ""}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="no-service">
                                  <h4>No services selected yet</h4>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="total-rate">
                            <h6>Total</h6>
                            <h6>
                              {selectServices.length > 0
                                ? priceTotal === 0
                                  ? "Free"
                                  : priceTotal
                                : 0}{" "}
                              {selectServices.length > 0
                                ? priceTotal === 0
                                  ? ""
                                  : currency
                                : currency}
                            </h6>
                          </div>
                        </Card.Body>
                      </Card>
                      {selectServices.length > 0 ? (
                        <>
                          {sameCategory && !hasBundle ? (
                            <>
                              <div className="book-now">
                                <Link
                                  className="btn"
                                  to={{
                                    pathname: `/customerappointment/selectpreference/${id}`,
                                    state: {
                                      servicesPanel: selectServices,
                                      staffs: selectStaffs,
                                      partnerTime: partnerSlot,
                                      chooseStaff: sameCategory,
                                      bundle: hasBundle,
                                      currency,
                                      storeImage,
                                      paymentRequired,
                                      outService,
                                      eventKey,
                                    },
                                  }}
                                >
                                  Book Now
                                </Link>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="book-now">
                                <Link
                                  className="btn"
                                  to={{
                                    pathname: `/customerappointment/select-time/${id}`,
                                    state: {
                                      servicesPanel: selectServices,
                                      partnerTime: partnerSlot,
                                      staffs: selectStaffs,
                                      selectedStaff: {
                                        name: "",
                                        id: "",
                                        shiftSlot:
                                          selectStaffs.length > 0
                                            ? selectStaffs[0].shift
                                            : [],
                                      },
                                      chooseStaff: sameCategory,
                                      bundle: hasBundle,
                                      currency,
                                      storeImage,
                                      paymentRequired,
                                      outService,
                                      eventKey,
                                    },
                                  }}
                                >
                                  Book Now
                                </Link>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="service-card">
                    <div className="store-service-tab">
                      <div className="stepwizard">
                        <ProgressBar now={25} />
                        <span className="stepwizard-steps">Step 1</span>
                      </div>
                      {/* <div className="stepwizard">
                      <ol className="stepwizard-panel">
                        <li className="current stepwizard-step">Step 1</li>
                        <li className="stepwizard-step">Step 2</li>
                        <li className="stepwizard-step">Step 3</li>
                        <li className="stepwizard-step">Ready to go!</li>
                      </ol>
                    </div> */}
                      <div className="service-heading">Services</div>
                      <div className="select-service-tab">
                        {categories.length > 0 ? (
                          <Tabs
                            defaultActiveKey={eventKey}
                            id="uncontrolled-tab-example"
                            onSelect={(e) => setEventKey(+e)}
                          >
                            {categories.map((el, i) => (
                              <Tab eventKey={i} title={el.categoryname}>
                                <TabContent
                                  serviceid={el.serviceid}
                                  checkedServices={checkedServices}
                                  checkedBundles={checkedBundles}
                                  packageid={el.packageid}
                                  currency={currency}
                                  selectServices={selectServices}
                                />
                              </Tab>
                            ))}
                          </Tabs>
                        ) : (
                          <div className="noservice-found text-center">
                            No service is found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectServices;

const TabContent = ({
  serviceid,
  checkedServices,
  packageid,
  checkedBundles,
  currency,
  selectServices,
}) => {
  const { state } = useLocation();
  // const [count, setCount] = useState(0);
  return (
    <>
      <div className="scrollbar-table">
        {serviceid.map((item, i) => (
          <div className="select-service-list">
            <div className="select-servce-check service-checkbox">
              <Form.Check
                type="checkbox"
                label=""
                defaultChecked={
                  state
                    ? state.servicesPanel.some((ele) => ele.id === item._id)
                    : false
                }
                onClick={(e) => checkedServices(item)}
              />
            </div>
            <div className="select-service-name">
              <h4>{item.serviceName}</h4>
              <div className="service-amount">
                <span>
                  {Math.floor(parseInt(item.duration) / 60) === 0 ? (
                    <>{parseInt(item.duration) % 60} min</>
                  ) : (
                    <>
                      {Math.floor(parseInt(item.duration) / 60)}h{" "}
                      {parseInt(item.duration) % 60 === 0 ? (
                        ""
                      ) : (
                        <>
                          ,{parseInt(item.duration) % 60}
                          min
                        </>
                      )}
                    </>
                  )}
                </span>
                <span>
                  {item.specialPrice ? currency : ""}
                  {item.specialPrice ? item.priceFrom : ""}
                </span>
              </div>
            </div>
            <div className="new-service-cost ml-auto">
              <span>
                {item.specialPrice
                  ? item.specialPrice
                  : item.priceFrom === "0"
                  ? "Free"
                  : item.priceFrom}{" "}
                {item.priceFrom !== "0" ? currency : ""}
              </span>
            </div>
          </div>
        ))}

        {packageid.map((item) => (
          <div className="select-service-list">
            <div className="select-servce-check service-checkbox">
              <Form.Check
                type="checkbox"
                label=""
                defaultChecked={
                  state
                    ? state.servicesPanel.some((ele) => ele.id === item._id)
                    : false
                }
                onClick={(e) => checkedBundles(item)}
              />
            </div>
            <div className="select-service-name">
              <h4>{item.packagename}</h4>
              <div className="service-amount">
                <span>
                  {Math.floor(parseInt(item.packageDuration) / 60) === 0 ? (
                    <>{parseInt(item.packageDuration) % 60} min</>
                  ) : (
                    <>
                      {Math.floor(parseInt(item.packageDuration) / 60)}h{" "}
                      {parseInt(item.packageDuration) % 60 === 0 ? (
                        ""
                      ) : (
                        <>
                          ,{parseInt(item.packageDuration) % 60}
                          min
                        </>
                      )}
                    </>
                  )}
                  . {item.serviceid.length} Services
                </span>
              </div>
            </div>
            <div className="new-service-cost ml-auto">
              <span>
                {item.packageSpecialPrice}{" "}
                {item.packageSpecialPrice !== "0" ? currency : ""}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
