import React, { useState, useEffect, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { adminInstance, partnerInstance } from "../../../config/axios";

const UserPermission = () => {
  const [data, setData] = useState([]);

  const [userPermissions, setUserPermissions] = useState([]);

  const group1 = data.filter((el) => el.groupId === 1);
  const group2 = data.filter((el) => el.groupId === 2);
  const group3 = data.filter((el) => el.groupId === 3);
  const group4 = data.filter((el) => el.groupId === 4);
  const group5 = data.filter((el) => el.groupId === 5);
  const group6 = data.filter((el) => el.groupId === 6);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      getUserPermission();
      mounted.current = true;
    } else {
      fetchSections();
    }
    // eslint-disable-next-line
  }, [mounted.current]);

  const getUserPermission = async () => {
    const responseTwo = await partnerInstance().get("/getUserPermission");

    setUserPermissions(responseTwo.data);
  };

  const ischecked = (type, id) => {
    const array = userPermissions;
    switch (type) {
      case "Normal":
        const data = array[0].section.includes(id);
        if (data) {
          const index = array[0].section.indexOf(id);
          if (index > -1) {
            array[0].section.splice(index, 1);
            setUserPermissions(array);
          }
          return true;
        }
        array[0].section.push(id);
        setUserPermissions(array);
        return false;

      case "Advanced":
        const dataFive = array[1].section.includes(id);
        if (dataFive) {
          const index = array[1].section.indexOf(id);
          if (index > -1) {
            array[1].section.splice(index, 1);
            setUserPermissions(array);
          }
          return true;
        }
        array[1].section.push(id);
        setUserPermissions(array);
        return false;

      case "Admin":
        const dataOne = array[2].section.includes(id);
        if (dataOne) {
          const index = array[2].section.indexOf(id);
          if (index > -1) {
            array[2].section.splice(index, 1);
            setUserPermissions(array);
          }
          return true;
        }
        array[2].section.push(id);
        setUserPermissions(array);
        return false;

      default:
        return false;
    }
  };

  const HandleSave = async (e) => {
    e.preventDefault();
    const payload = {
      permissionData: userPermissions,
    };

    const savePermissions = await partnerInstance().post(
      "/updateUserPermission",
      payload
    );
    if (savePermissions) {
      toast.success("Permission Added Successfully", {
        containerId: "B",
      });
    }
  };

  const fetchSections = async () => {
    const response = await adminInstance().get("/section");

    setData(response.data);
  };

  const isPreSelect = (type, id) => {
    const user = type;
    switch (user) {
      case "Normal":
        const data = userPermissions[0].section.includes(id);
        if (data) {
          return true;
        }
        break;
      case "Advanced":
        const Lowdata = userPermissions[1].section.includes(id);
        if (Lowdata) {
          return true;
        }
        break;

      case "Admin":
        const MediumData = userPermissions[2].section.includes(id);
        if (MediumData) {
          return true;
        }
        break;

      default:
        return false;
    }
  };

  return (
    <div className="user-permission">
      <div className="container">
        <div className="user-12">
          <p className="text-muted ">
            Setup which sections are accessible to each user permission level.
            All logged in staff can access the calendar, and owner accounts have
            full system access.
          </p>
          <div className="partner-table">
            <Table responsive="lg">
              <thead>
                <tr>
                  <th className="text-heading text-left">BOOKINGS & CLIENTS</th>
                  <th className="text-heading">Normal</th>
                  <th className="text-heading text-left">Advanced</th>
                  <th className="text-heading">Admin</th>
                </tr>
              </thead>
              <tbody>
                {group1.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">{item.sectionname}</td>
                    <td
                      className={
                        item.sectionname === "Access Own Calendar"
                          ? "disabled-permmission"
                          : ""
                      }
                    >
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            disabled={
                              item.sectionname === "Access Own Calendar"
                            }
                            defaultChecked={isPreSelect("Normal", item._id)}
                            onChange={() => {
                              ischecked("Normal", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td
                      className={
                        item.sectionname === "Access Own Calendar"
                          ? "disabled-permmission"
                          : ""
                      }
                    >
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            disabled={
                              item.sectionname === "Access Own Calendar"
                            }
                            defaultChecked={isPreSelect("Advanced", item._id)}
                            onChange={() => {
                              ischecked("Advanced", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td className="disabled-permmission">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Admin", item._id)}
                            onChange={() => {
                              ischecked("Admin", item._id);
                            }}
                            disabled
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

              <thead>
                <tr>
                  <th className="text-heading text-left">BOOKINGS & CLIENTS</th>
                  <th className="text-heading">Normal</th>
                  <th className="text-heading text-left">Advanced</th>
                  <th className="text-heading">Admin</th>
                </tr>
              </thead>
              <tbody>
                {group2.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">{item.sectionname}</td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Normal", item._id)}
                            onChange={() => {
                              ischecked("Normal", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Advanced", item._id)}
                            onChange={() => {
                              ischecked("Advanced", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td className="disabled-permmission">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Admin", item._id)}
                            onChange={() => {
                              ischecked("Admin", item._id);
                            }}
                            disabled
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

              <thead>
                <tr>
                  <th className="text-heading text-left">BOOKINGS & CLIENTS</th>
                  <th className="text-heading">Normal</th>
                  <th className="text-heading text-left">Advanced</th>
                  <th className="text-heading">Admin</th>
                </tr>
              </thead>
              <tbody>
                {group3.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">{item.sectionname}</td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Normal", item._id)}
                            onChange={() => {
                              ischecked("Normal", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Advanced", item._id)}
                            onChange={() => {
                              ischecked("Advanced", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td className="disabled-permmission">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Admin", item._id)}
                            onChange={() => {
                              ischecked("Admin", item._id);
                            }}
                            disabled
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

              <thead>
                <tr>
                  <th className="text-heading text-left">BOOKINGS & CLIENTS</th>
                  <th className="text-heading">Normal</th>
                  <th className="text-heading text-left">Advanced</th>
                  <th className="text-heading">Admin</th>
                </tr>
              </thead>
              <tbody>
                {group4.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">{item.sectionname}</td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Normal", item._id)}
                            onChange={() => {
                              ischecked("Normal", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Advanced", item._id)}
                            onChange={() => {
                              ischecked("Advanced", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td className="disabled-permmission">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Admin", item._id)}
                            onChange={() => {
                              ischecked("Admin", item._id);
                            }}
                            disabled
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

              <thead>
                <tr>
                  <th className="text-heading text-left">BOOKINGS & CLIENTS</th>
                  <th className="text-heading">Normal</th>
                  <th className="text-heading text-left">Advanced</th>
                  <th className="text-heading">Admin</th>
                </tr>
              </thead>
              <tbody>
                {group5.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">{item.sectionname}</td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Normal", item._id)}
                            onChange={() => {
                              ischecked("Normal", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Advanced", item._id)}
                            onChange={() => {
                              ischecked("Advanced", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td className="disabled-permmission">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Admin", item._id)}
                            onChange={() => {
                              ischecked("Admin", item._id);
                            }}
                            disabled
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

              <thead>
                <tr>
                  <th className="text-heading text-left">BOOKINGS & CLIENTS</th>
                  <th className="text-heading">Normal</th>
                  <th className="text-heading text-left">Advanced</th>
                  <th className="text-heading">Admin</th>
                </tr>
              </thead>
              <tbody>
                {group6.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">{item.sectionname}</td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Normal", item._id)}
                            onChange={() => {
                              ischecked("Normal", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Advanced", item._id)}
                            onChange={() => {
                              ischecked("Advanced", item._id);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                    <td className="disabled-permmission">
                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={isPreSelect("Admin", item._id)}
                            onChange={() => {
                              ischecked("Admin", item._id);
                            }}
                            disabled
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="text-right">
            <Button onClick={HandleSave} className="btn-perple">
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPermission;
