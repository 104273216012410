/* eslint-disable radix */
/* eslint-disable quotes */
import { loadStripe } from "@stripe/stripe-js";
import { websiteInstance } from "../config/axios";
import { stripeKey, livestripeKey } from "../config/keys";

export const validateLogin = (values) => {
  console.log("login detail", values);
  const loginerrors = {};
  let isValid = true;
  const EmailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email.replace(/ /g, "")) {
    loginerrors.email = "Email is required";
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    loginerrors.email = "Email address is invalid";
    isValid = false;
  }
  if (!values.password.replace(/ /g, "")) {
    loginerrors.password = "Password is required";

    isValid = false;
  } else if (values.password.length < 5) {
    loginerrors.password = "Please Enter a valid Password";
    isValid = false;
  } else if (values.password.length > 15) {
    loginerrors.password = "Please Enter a valid Password";
    isValid = false;
  }
  return { isValid, loginerrors };
};
export const validateRegister = (values) => {
  const errors = {};
  let isValid = true;
  const EmailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  if (!values.firstname.replace(/ /g, "")) {
    errors.firstname = "firstname is required";
    isValid = false;
  } else if (values.firstname.length < 2) {
    errors.firstname = "firstname should be greater than 2 characters";
    isValid = false;
  } else if (values.firstname.length > 25) {
    errors.firstname = "firstname should be less than 25 characters";
    isValid = false;
  }
  if (!values.lastname.replace(/ /g, "")) {
    errors.lastname = "lastname is required";
    isValid = false;
  } else if (values.lastname.length < 2) {
    errors.lastname = "lastname should be greater than 2 characters";
    isValid = false;
  } else if (values.lastname.length > 25) {
    errors.lastname = "lastname should be less than 25 characters";
    isValid = false;
  }
  if (!values.email.replace(/ /g, "")) {
    errors.email = "Email is required";
    isValid = false;
  } else if (
    !isNaN(values.email.trim() ? values.email.split("@")[0] : null) &&
    !isNaN(
      values.email.trim() ? values.email.split("@")[1].split(".")[0] : null
    )
  ) {
    errors.email = "Invalid Email";
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    errors.email = "Invalid Email";
    isValid = false;
  }
  if (!values.password.replace(/ /g, "")) {
    errors.password = "Password is required";
    isValid = false;
  } else if (!passwordRegex.test(values.password)) {
    errors.password =
      "The password must be at least 8 characters long, and you must use at least one uppercase and one number";
    isValid = false;
  }
  if (!values.checked2) {
    console.log("come herere");
    errors.checked2 = "Please accept terms & conditions";
    isValid = false;
  }


  return { isValid, errors };
};

export const validateUserProfile = (values) => {
  const errors = {};
  let isValid = true;
  const EmailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  if (!values.firstname.replace(/ /g, "")) {
    errors.firstname = "firstname is required";
    isValid = false;
  } else if (values.firstname.length < 2) {
    errors.firstname = "firstname should be greater than 2 characters";
    isValid = false;
  } else if (values.firstname.length > 25) {
    errors.firstname = "firstname should be less than 25 characters";
    isValid = false;
  }

  if (!values.email.replace(/ /g, "")) {
    errors.email = "Email is required";
    isValid = false;
  } else if (
    !isNaN(values.email.trim() ? values.email.split("@")[0] : null) &&
    !isNaN(
      values.email.trim() ? values.email.split("@")[1].split(".")[0] : null
    )
  ) {
    errors.email = "Invalid Email";
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    errors.email = "Invalid Email";
    isValid = false;
  }

  return { isValid, errors };
};
// Partner validate
export const validatePartnerRegister = (values) => {
  const errors = {};
  let isValid = true;
  const EmailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  if (!values.firstname.replace(/ /g, "")) {
    errors.firstname = "firstname is required";
    isValid = false;
  } else if (values.firstname.length < 2) {
    errors.firstname = "firstname should be greater than 2 characters";
    isValid = false;
  } else if (values.firstname.length > 25) {
    errors.firstname = "firstname should be less than 25 characters";
    isValid = false;
  }
  if (!values.lastname.replace(/ /g, "")) {
    errors.lastname = "lastname is required";
    isValid = false;
  } else if (values.lastname.length < 2) {
    errors.lastname = "lastname should be greater than 2 characters";
    isValid = false;
  } else if (values.lastname.length > 25) {
    errors.lastname = "lastname should be less than 25 characters";
    isValid = false;
  }
  if (!values.email.replace(/ /g, "")) {
    errors.email = "Email is required";
    isValid = false;
  } else if (
    !isNaN(values.email.trim() ? values.email.split("@")[0] : null) &&
    !isNaN(
      values.email.trim() ? values.email.split("@")[1].split(".")[0] : null
    )
  ) {
    errors.email = "Invalid Email";
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    errors.email = "Invalid Email";
    isValid = false;
  }
  if (!values.password.replace(/ /g, "")) {
    errors.password = "Password is required";
    isValid = false;
  } else if (!passwordRegex.test(values.password)) {
    errors.password =
      "The password must be at least 8 characters long, and you must use at least one uppercase and one number";
    isValid = false;
  }
  if (!values.checked2) {
    
    errors.checked2 = "Please accept terms & conditions";
    isValid = false;
  }
  if (!values.companyName.replace(/ /g, "")) {
    errors.companyName = "companyName is required";
    isValid = false;
  } else if (values.companyName.length < 3) {
    errors.companyName = "companyname should be greater than 2 characters";
    isValid = false;
  } else if (values.companyName.length > 60) {
    errors.companyName = "companyname should be less than 60 characters";
    isValid = false;
  }

  if (!values.businessType.replace(/ /g, "")) {
    errors.businessType = "Bussiness is required";
    isValid = false;
  }
  console.log(values.checked2);
  return { isValid, errors };
};

export const validateForget = (values) => {
  const errors = {};
  let isValid = true;
  const EmailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.email.replace(/ /g, "")) {
    errors.email = "Email is required";
    isValid = false;
  } else if (
    !isNaN(values.email.trim() ? values.email.split("@")[0] : null) &&
    !isNaN(
      values.email.trim() ? values.email.split("@")[1].split(".")[0] : null
    )
  ) {
    errors.email = "Invalid Email";
    isValid = false;
  } else if (!EmailRegex.test(values.email)) {
    errors.email = "Invalid Email";
    isValid = false;
  }
  return { isValid, errors };
};

export const validateReset = (values) => {
  const reseterrors = {};
  let isValid = true;
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  if (!values.password.replace(/ /g, "")) {
    reseterrors.password = "Password is required";
    isValid = false;
  } else if (!passwordRegex.test(values.password)) {
    reseterrors.password =
      "The password must be at least 8 characters long, and you must use at least one uppercase and one number";
    isValid = false;
  }
  if (!values.repeatPassword) {
    reseterrors.repeatPassword = "Confirm password is required";
    isValid = false;
  } else if (values.password !== values.repeatPassword) {
    reseterrors.repeatPassword = "Confirm password does not match";
    isValid = false;
  }
  return { isValid, reseterrors };
};

export const validateService = (values) => {
  const errors = {};
  let isValid = true;
  if (values.priceType === "free") {
    if (!values.category) {
      errors.category = "Please select a Category";
      isValid = false;
    }
    if (!values.servicename.replace(/ /g, "")) {
      errors.servicename = "Servicename is required";
      isValid = false;
    }

    if (values.staff.length === 0) {
      errors.staff = "Staff is required";
      isValid = false;
    }
  } else {
    if (!values.category) {
      errors.category = "Please select a Category";
      isValid = false;
    }
    if (!values.servicename.replace(/ /g, "")) {
      errors.servicename = "Servicename is required";
      isValid = false;
    }

    if (values.staff.length === 0) {
      errors.staff = "Staff is required";
      isValid = false;
    }

    if (!values.priceFrom.replace(/ /g, "")) {
      errors.priceFrom = "Price is required";
      isValid = false;
    }
  }

  return { isValid, errors };
};
export const validateShiftTiming = (values) => {
  const errors = {};
  let isValid = true;
  const startarr = [];
  const endarr = [];

  values.shiftPanel.forEach((el) => {
    startarr.push(el.starttime);
    endarr.push(el.endtime);
  });
  if (startarr[1] <= endarr[0]) {
    errors.shiftPanel = "Shifts are overlapping";
    isValid = false;
  }
  if (startarr[1] >= endarr[1]) {
    errors.shiftPanel = "Shifts are overlapping";
    isValid = false;
  }
  return { isValid, errors };
};

export const validateStaff = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.firstname.replace(/ /g, "")) {
    errors.firstname = "Firstname is required";
    isValid = false;
  }
  if (!values.email.replace(/ /g, "")) {
    errors.email = "Email is required";
    isValid = false;
  }
  if (values.serviceCommission) {
    const x = parseFloat(values.serviceCommission);
    if (isNaN(x) || x < 0 || x > 100) {
      errors.serviceCommission =
        "Service Commission must be higher than 0 and equal or lower than 100";
      isValid = false;
    }
  }
  if (values.voucherCommission) {
    const x = parseFloat(values.voucherCommission);
    if (isNaN(x) || x < 0 || x > 100) {
      errors.voucherCommission =
        "Voucher Commission must be higher than 0 and equal or lower than 100";
      isValid = false;
    }
  }

  return { isValid, errors };
};

export const validateAppointment = (values) => {
  const errors = {};

  let isValid = true;
  values.appointmentPanel.forEach((el, i) => {
    if (!el.staffId) {
      errors.staffId = "Staff is required";
      errors.staffIndex = i;
      isValid = false;
    }
    if (!el.serviceId) {
      errors.serviceId = "Service is required";
      errors.serviceIndex = i;
      isValid = false;
    }
    if (!el.categoryId) {
      errors.categoryId = "Category is required";
      errors.categoryIndex = i;
      isValid = false;
    }
  });

  if (!values.customerId) {
    errors.customerId = "Customer is required";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateInvoice = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.title) {
    errors.title = "Title is required";
    isValid = false;
  }
  if (!values.customLine1) {
    errors.customLine1 = "customLine1 is required";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateTax = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.taxName.replace(/ /g, "")) {
    errors.taxName = "Tax name is required";
    isValid = false;
  }
  const x = parseFloat(values.taxRate);
  if (!values.taxRate.replace(/ /g, "")) {
    errors.taxRate = "Tax rate is required";
    isValid = false;
  } else if (isNaN(x) || x < 0 || x > 100) {
    errors.taxRate =
      "Tax Rate must be higher than 0 and equal or lower than 100";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateVoucher = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.voucherName.replace(/ /g, "")) {
    errors.voucherName = "Voucher name is required";
    isValid = false;
  }
  if (!values.voucherValue.replace(/ /g, "")) {
    errors.voucherValue = "Voucher value is required";
    isValid = false;
  }
  if (!values.retailPrice.replace(/ /g, "")) {
    errors.retailPrice = "Retail price is required";
    isValid = false;
  }
  if (parseInt(values.retailPrice) >= parseInt(values.voucherValue)) {
    errors.retailPrice = "Retail price must be lower than the voucher value";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateDiscountType = (values) => {
  const errors = {};
  let isValid = true;

  if (!isNaN(values.discountName)) {
    errors.discountName = "Discount name should be a alphabet";
    isValid = false;
  }
  if (!values.discountName.replace(/ /g, "")) {
    errors.discountName = "Discount name is required";
    isValid = false;
  }

  if (!values.discountValue.replace(/ /g, "")) {
    errors.discountValue = "Discount value is required";
    isValid = false;
  }

  if (values.discountType === "%") {
    const x = parseFloat(values.discountValue);
    if (isNaN(x) || x < 0 || x > 100) {
      errors.discountValue =
        "Discount value must be higher than 0 and equal or lower than 100";
      isValid = false;
    }
  }

  return { isValid, errors };
};
export const validateCustomer = (values) => {
  const errors = {};
  let isValid = true;

  if (!values.firstname.replace(/ /g, "")) {
    errors.firstname = "First name is required";
    isValid = false;
  }

  // if (!values.email.replace(/ /g, '')) {
  //   errors.email = 'Email is required';
  //   isValid = false;
  // }

  // if (!values.address.replace(/ /g, '')) {
  //   errors.address = 'Address is required';
  //   isValid = false;
  // }
  // if (!values.city.replace(/ /g, '')) {
  //   errors.city = 'City is required';
  //   isValid = false;
  // }
  // if (!values.state.replace(/ /g, '')) {
  //   errors.state = 'State is required';
  //   isValid = false;
  // }

  return { isValid, errors };
};

export const validateReferral = (values) => {
  const errors = {};
  let isValid = true;

  if (!values.name.replace(/ /g, "")) {
    errors.name = "Referral name is required";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateLocationType = (values) => {
  console.log(values);
  const errors = {};
  let isValid = true;
  const EmailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.locationName.replace(/ /g, "")) {
    errors.locationName = "Location name is required";
    isValid = false;
  } else if (!isNaN(values.locationName)) {
    errors.locationName = "Location name should be a alphabet";
    isValid = false;
  }
  if (!values.locationEmail.replace(/ /g, "")) {
    errors.locationEmail = "Location email is required";
    isValid = false;
  } else if (!EmailRegex.test(values.locationEmail)) {
    errors.locationEmail = "Email is Invalid";
    isValid = false;
  }
  if (!values.addLocation) {
    errors.addLocation = "Contact Location is required";
    isValid = false;
  }
  if (!values.locationContactNumber.replace(/ /g, "")) {
    errors.locationContactNumber = "Contact Number is required";
    isValid = false;
  }

  // if (!values.address.replace(/ /g, '')) {
  //   errors.address = 'Location is required';
  //   isValid = false;
  // }
  // if (!values.storeImage.replace(/ /g, '')) {
  //   errors.storeImage = 'Store Image is required';
  //   isValid = false;
  // }
  if (values.images && values.images.length === 0) {
    errors.images = "Store Images is required";
    isValid = false;
  }

  if (values.availability && !values.availability.some((a) => a.enabled)) {
    errors.availability = "Atleast One Slot is required";
    isValid = false;
  }

  return { isValid, errors };
};
export const validateLocationType2 = (values) => {
  console.log(values);
  const errors = {};
  let isValid = true;
  const EmailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values.locationName.replace(/ /g, "")) {
    errors.locationName = "Location name is required";
    isValid = false;
  } else if (!isNaN(values.locationName)) {
    errors.locationName = "Location name should be a alphabet";
    isValid = false;
  }
  if (!values.locationEmail.replace(/ /g, "")) {
    errors.locationEmail = "Location email is required";
    isValid = false;
  } else if (!EmailRegex.test(values.locationEmail)) {
    errors.locationEmail = "Email is Invalid";
    isValid = false;
  }
  // if (!values.addLocation) {
  //   errors.addLocation = "Contact Number is required";
  //   isValid = false;
  // }
  if (!values.locationContactNumber.replace(/ /g, "")) {
    errors.locationContactNumber = "Contact Number is required";
    isValid = false;
  }

  // if (!values.address.replace(/ /g, '')) {
  //   errors.address = 'Location is required';
  //   isValid = false;
  // }
  // if (!values.storeImage.replace(/ /g, '')) {
  //   errors.storeImage = 'Store Image is required';
  //   isValid = false;
  // }
  if (values.images && values.images.length === 0) {
    errors.images = "Store Images is required";
    isValid = false;
  }

  if (values.availability && !values.availability.some((a) => a.enabled)) {
    errors.availability = "Atleast One Slot is required";
    isValid = false;
  }

  return { isValid, errors };
};
//De-couple
export const validateAccountSetting = (values) => {
  console.log(values);
  const errors = {};
  // eslint-disable-next-line
  const re =/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  let isValid = true;
  if (!values.companyName.replace(/ /g, "")) {
    errors.companyName = "Business Name is required";
    isValid = false;
  }
  if (!values.Description) {
    errors.Description = "Description is required";
    isValid = false;
  }
  if (!values.currency) {
    errors.currency = "Description is required";
    isValid = false;
  }
  if (values.website) {
    if (!re.test(values.website)) {
      errors.website = "Website URl is invalid";
      isValid = false;
    }
  }

  if (values.instagram) {
    // eslint-disable-next-line
    if (!/^(http|https)\:\/\/www.instagram.com\/.*/i.test(values.instagram)) {
      errors.instagram = "Instagram URl is invalid";
      isValid = false;
    }
  }

  if (values.facebook) {
    // eslint-disable-next-line
    if (!/^(http|https)\:\/\/www.facebook.com\/.*/i.test(values.facebook)) {
      errors.facebook = "Facebook URl is invalid";
      isValid = false;
    }
  }

  return { isValid, errors };
};
export const validateAccountSetting2 = (values) => {
  console.log(values);
  const errors = {};
  // eslint-disable-next-line
  const re =/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  let isValid = true;
  if (!values.companyName.replace(/ /g, "")) {
    errors.companyName = "Business Name is required";
    isValid = false;
  }
  if (!values.Description) {
    errors.Description = "Description is required";
    isValid = false;
  }
  // if (!values.currency) {
  //   errors.currency = "Description is required";
  //   isValid = false;
  // }
  if (values.website) {
    if (!re.test(values.website)) {
      errors.website = "Website URl is invalid";
      isValid = false;
    }
  }

  if (values.instagram) {
    // eslint-disable-next-line
    if (!/^(http|https)\:\/\/www.instagram.com\/.*/i.test(values.instagram)) {
      errors.instagram = "Instagram URl is invalid";
      isValid = false;
    }
  }

  if (values.facebook) {
    // eslint-disable-next-line
    if (!/^(http|https)\:\/\/www.facebook.com\/.*/i.test(values.facebook)) {
      errors.facebook = "Facebook URl is invalid";
      isValid = false;
    }
  }

  return { isValid, errors };
};

export const validateInvoiceSequencing = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.invoiceno) {
    errors.invoiceno = "Invoice No. is required";
    isValid = false;
  } else {
    const x = parseFloat(values.invoiceno);
    if (isNaN(x) || x < 0 || x > 1000000) {
      errors.invoiceno =
        "Invoice No must be higher than 0 and equal or lower than 1000000";
      isValid = false;
    }
  }

  if (!values.invoiceprefix) {
    errors.invoiceprefix = "Invoice Prefix is required";
    isValid = false;
  } else {
    const x = values.invoiceprefix.length;
    if (x < 0 || x > 5) {
      errors.invoiceprefix = "Invoice Prefix must be shorter than 5 characters";
      isValid = false;
    }
  }

  return { isValid, errors };
};

export const validatePackage = (values) => {
  const errors = {};
  let isValid = true;

  if (!values.packagename.replace(/ /g, "")) {
    errors.packagename = "Packagename is required";
    isValid = false;
  }
  if (!values.category) {
    errors.category = "Package Category is required";
    isValid = false;
  }
  // if (!values.servicename.replace(/ /g, '')) {
  // errors.servicename = 'servicename is required';
  // isValid = false;
  // }
  if (values.servicePanel.length < 2) {
    errors.servicePanel = "Atleast 2 services must be added";
    isValid = false;
  }
  // if (!values.priceFrom.replace(/ /g, '')) {
  // errors.priceFrom = 'Price is required';
  // isValid = false;
  // }
  // if (!values.staff.trim()) {
  // errors.staff = 'Please Select Minimum One Staff';
  // isValid = false;
  // }

  return { isValid, errors };
};
export const validatePaymentType = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.paymentname.replace(/ /g, "")) {
    errors.paymentname = "Payment name is required";
    isValid = false;
  }

  if (!isNaN(values.paymentname)) {
    errors.paymentname = "Please Enter Valid Payment Name";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateCancelType = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.Name.replace(/ /g, "")) {
    errors.Name = "Cancellation name is required";
    isValid = false;
  } else if (!isNaN(values.Name)) {
    errors.Name = "Please Enter Valid Cancellation Name";
    isValid = false;
  }

  return { isValid, errors };
};

export const validateCategory = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.categoryname.replace(/ /g, "")) {
    errors.categoryname = "category name is required";
    isValid = false;
  } else if (values.categoryname.length < 2) {
    errors.categoryname = "category name should be greater than 1 character";
    isValid = false;
  } else if (values.categoryname.length > 50) {
    errors.categoryname = "category name should be less than 25 characters";
    isValid = false;
  }

  return { isValid, errors };
};
export const validatePopup = (values) => {
  const errors = {};
  let isValid = true;
  if (!values.startDate) {
    errors.startDate = "Start Date is required";
    isValid = false;
  }
  if (!values.endDate) {
    errors.endDate = "End Date is required";
    isValid = false;
  } else if (
    Math.round(
      (new Date(values.endDate) - new Date(values.startDate)) /
        (1000 * 60 * 60 * 24)
    ) < 0
  ) {
    errors.endDate = "End Date Should be greater than Start Date";
    isValid = false;
  }
  if (!values.description) {
    errors.description = "Description is required";
    isValid = false;
  }
  return { isValid, errors };
};

export const validateRange = (values) => {
  const errors = {};
  let isValid = true;
  if (
    Math.round(
      (new Date(values.endDate) - new Date(values.startDate)) /
        (1000 * 60 * 60 * 24)
    ) < 0
  ) {
    errors.endDate = "End Date Should be greater than Start Date";
    isValid = false;
  }
  return { isValid, errors };
};

export const checkKeys = async () => {
  const response = await websiteInstance().get("/getKeys");
  const istestKey = response.data.testKey;
  let key;
  if (istestKey) {
    key = stripeKey;
  } else {
    key = livestripeKey;
  }
  const stripePromise = loadStripe(key);
  return stripePromise;
};
