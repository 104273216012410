import React, { useContext } from "react";
import { Tabs, Tab, ProgressBar, Button } from "react-bootstrap";
import PartnerWrapper from "../partnerwrapper/wrapper";
import cross from "../../../assets/cross2.png";
import history from "../../../config/history";
import SignupPaymentType from "./signupPayment";
import SignupCancelReasion from "./signupCancelReasion";
import Helmet from "react-helmet";
import { StepCompletePrevent } from "./StepCompletePrevent";
import PartnerContext from "../../../context/context";

const SignupStep5 = () => {
  const { isStepComplete } = useContext(PartnerContext);
  StepCompletePrevent(isStepComplete);

  const Step4 = () => {
    history.push("/partners/signuptaxes");
  };
  const Step6 = () => {
    history.push("/partners/discount");
  };
  const Cross = () => {
    history.push("/partners/intro");
  };
  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form taxes">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3> Payment Types & Cancellation Reasons</h3>
                    <div className="signup-progress">
                      <ProgressBar now={60} />
                    </div>
                  </div>
                  <div className="signup-body">
                    <div className="signup-location signup-taxes">
                      <Tabs
                        defaultActiveKey="payment-types"
                        transition={false}
                        id="noanim-tab-example"
                      >
                        <Tab eventKey="payment-types" title="Payment Types">
                          <SignupPaymentType />
                        </Tab>
                        <Tab
                          eventKey="cancellation-reasons"
                          title="Cancellation Reasons"
                        >
                          <SignupCancelReasion />
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
                {/* <div className="signup-right"> */}
                <div className="signup-footer-btn">
                  <Button type="submit" onClick={Step4}>
                    Back
                  </Button>
                  <Button type="submit" onClick={Step6}>
                    Next
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupStep5;
