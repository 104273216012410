/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, DropdownButton } from "react-bootstrap";
//import { Link } from 'react-router-dom';
import moment from "moment";
import ReactExport from "react-export-excel";
import { CSVLink } from "react-csv";
import Pdf from "react-to-pdf";
import close from "../../../assets/close.png";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";

const ref = React.createRef();
const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const RevenueVoucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [copy, setCopy] = useState([]);
  const { username } = useContext(PartnerContext);
  const { currency } = username;

  useEffect(() => {
    getVouchers();
  }, []);
  const getVouchers = async () => {
    const response = await partnerInstance().get("/Vouchers");
    const { code, voucherData } = response.data;
    const arr = [];
    if (code === 200) {
      voucherData.voucher.forEach((el) => {
        el.voucherBuyByCustomer.forEach((item) => {
          arr.push({
            startDate: item.PurchaseDate,
            endDate: item.ExpiryDate,
            no: item.invoiceId ? item.invoiceId.invoiceno : "0",
            client: item.customerId.firstname,
            type: "hello",
            status: item.voucherStatus ? item.voucherStatus : "Valid",
            code: item.voucherCode,
            total: item.voucherValue,
            redeem: item.isReedemed ? item.voucherValue : 0,
            remaining: item.isReedemed ? 0 : item.voucherValue,
            id: item._id,
          });
        });
      });
      setVouchers(arr);
      setCopy(arr);
    }
  };

  const App = (data) => {
    history.push(`/partners/voucherviewinvoice?voucherId=${data}`);
  };

  return (
    <div className="revenue-voucher">
      <VouchersFilter
        vouchers={vouchers}
        voucherCopy={copy}
        setVouchers={setVouchers}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="revenue-body">
            <h4>Vouchers</h4>
            <div className="revenue-table">
              <Table responsive="lg" ref={ref}>
                {vouchers.length > 0 ? (
                  <>
                    <thead>
                      <tr>
                        <th>Issue date</th>
                        <th>Expiry date</th>
                        <th>Invoice no.</th>
                        <th>Client</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Code</th>
                        <th>Total</th>
                        <th>Redeemed</th>
                        <th>Remaining</th>
                      </tr>
                    </thead>
                    {vouchers.map((item) => (
                      <tbody>
                        <tr>
                          <td>{moment(item.startDate).format("YYYY-MM-DD")}</td>
                          <td>{moment(item.endDate).format("YYYY-MM-DD")}</td>
                          <td role="presentation" onClick={() => App(item.id)}>
                            <a href="javascript:void(0)" className="appoi-id">
                              {item.no}
                            </a>
                          </td>
                          <td>{item.client}</td>
                          <td>Gift Voucher</td>
                          <td>
                            <span className="valid">{item.status}</span>
                          </td>
                          <td>{item.code}</td>
                          <td>
                            {currency} {item.total}
                          </td>
                          <td>
                            {" "}
                            {currency} {item.redeem}
                          </td>
                          <td>
                            {" "}
                            {currency} {item.remaining}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <p>No Vouchers</p>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueVoucher;

const VouchersFilter = ({ vouchers, setVouchers, voucherCopy }) => {
  const [sidebartoggle, setSidebarToggle] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const copy = [...vouchers];
  const serviceSearch = (e) => {
    setSearchKeyword(e);
    if (+e.length > 0) {
      const filteredServices = copy.filter(
        (service) =>
          service.client.toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
          service.code.toLowerCase().indexOf(e.toLowerCase()) !== -1
      );
      setVouchers(filteredServices);
    } else {
      setVouchers(voucherCopy);
    }
  };

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(copy[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };

  const handleRefresh = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  const options = {
    orientation: "landscape",
    format: [1000, 1000],
  };

  return (
    <div className="vouchers-filter">
      <div className="revenue-filter">
        <div className="filter-left-block">
          <div className="revenue-search">
            <Form>
              <input
                type="text"
                placeholder="Search by Code or Client"
                className="form-control"
                value={searchKeyword}
                onChange={(e) => serviceSearch(e.target.value)}
                onKeyDown={handleRefresh}
              />
              <Button>
                <i className="fa fa-search" />
              </Button>
            </Form>
          </div>
        </div>

        <div className="filter-right-block">
          <div className="header-buttons">
            <DropdownButton
              className="dark-btn gbu6"
              id="dropdown-basic-button-export"
              title="Export"
            >
              <Pdf
                targetRef={ref}
                filename="voucher-list.pdf"
                options={options}
              >
                {({ toPdf }) => <button onClick={toPdf}>PDF</button>}
              </Pdf>
              <h6>
                {vouchers.length > 0 && (
                  <ExcelFile filename="test" element={<button>Excel</button>}>
                    <ExcelSheet data={vouchers} name="voucher-list">
                      {filterColumns(vouchers).map((col) => (
                        <ExcelColumn label={camelCase(col)} value={col} />
                      ))}
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </h6>
              <CSVLink data={vouchers} filename="voucher-list.csv">
                CSV
              </CSVLink>
            </DropdownButton>
            <Button
              variant="default"
              onClick={() => setSidebarToggle(!sidebartoggle)}
            >
              Filter <i className="fa fa-sliders" />
            </Button>
          </div>
        </div>
      </div>

      <SidebarFilter
        sidebartoggle={sidebartoggle}
        setSidebarToggle={setSidebarToggle}
        vouchers={vouchers}
        setVouchers={setVouchers}
        voucherCopy={voucherCopy}
      />
    </div>
  );
};

const SidebarFilter = ({
  sidebartoggle,
  setSidebarToggle,
  vouchers,
  setVouchers,
  voucherCopy,
}) => {
  const [vStatus, setVStatus] = useState("");

  const filterVouchers = () => {
    const old = [...voucherCopy];
    if (vStatus !== "All") {
      const filterVoucher = old.filter((el) => el.status === vStatus);
      if (filterVoucher.length > 0) {
        setVouchers(filterVoucher);
      } else {
        setVouchers([]);
      }
    } else {
      setVouchers(voucherCopy);
    }
    setSidebarToggle(!sidebartoggle);
  };

  const Close = () => {
    setVouchers(voucherCopy);
    setVStatus("All");
    setSidebarToggle(!sidebartoggle);
  };

  return (
    <div className={`sidebar-items ${sidebartoggle ? "sidebar-expand" : ""}`}>
      <div className="sidebar-title">
        <h2>Filters</h2>
        <span onClick={() => setSidebarToggle(!sidebartoggle)}>
          <img src={close} alt="close" />
        </span>
      </div>
      <Form>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-control"
                value={vStatus}
                onChange={(e) => setVStatus(e.target.value)}
              >
                <option value="All">All statuses</option>
                <option value="unpaid">Unpaid</option>
                <option value="Valid">Valid</option>
                <option value="expired">Expired</option>
                <option value="Redeemed">Redeemed</option>
                <option value="Refunded Invoice">Refunded Invoice</option>
                <option value="Voided Invoice">Voided Invoice</option>
              </select>
            </div>
          </div>

          <div className="col-md-12 text-right filter-buttons">
            <Button onClick={Close} variant="default" className="btn-perple">
              Clear
            </Button>
            <Button
              variant="primary"
              onClick={filterVouchers}
              className="btn-perple"
            >
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
