import React, { useState, useEffect, useRef, useContext } from "react";
import { ProgressBar, Button } from "react-bootstrap";
//import { toast } from "react-toastify";
import Card from "react-bootstrap/Card";
//import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import history from "../../../config/history";
//import SignupBusiness from "./business";
import cross from "../../../assets/cross2.png";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { partnerInstance } from "../../../config/axios";
import { validateAccountSetting } from "../../../function/validate";
import PartnerContext from "../../../context/context";
//import Loader from "../../loader/loader";
import websiteicon from "../../../assets/website-icon.png";
import facebookicon from "../../../assets/facebook.png";
import insta from "../../../assets/Insta.png";
import Helmet from "react-helmet";

const SignupStep1 = () => {
  //const [isSubmit, setIsSubmit] = useState(false);
  // const Step2 = () => {
  //   setIsSubmit(true);
  //   // history.push('/partners/signuplocation')
  // };
  const Cross = () => {
    history.push("/partners/intro");
  };

  const { username, isStepComplete } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  console.log("Step-11::", isStepComplete);
  //const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [timeZone, setTimezone] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [weekStart, setWeekStart] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [clientNotificationLanguage, setClientNotificationLanguage] =
    useState("");
  const [staffNotificationLanguage, setStaffNotificationLanguage] =
    useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [Description, setDescription] = useState("");
  const [timezones, setTimezones] = useState({});
  const [errors, setErrors] = useState({});
  const [currency, setCurrency] = useState("");
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  useEffect(() => {
    const getInvoice = async () => {
      const accountData = await partnerInstance().get("/getPartnerAccount");
      const { code, partnerData } = accountData.data;

      if (code === 200) {
        setCompanyName(partnerData.companyName);
        setBusinessType(partnerData.businessType.businessname);
        setTimezone(partnerData.timezone);
        setTimeFormat(partnerData.timeformat);
        setWeekStart(partnerData.weekStart);
        setClientNotificationLanguage(partnerData.clientNotificationLanguage);
        setStaffNotificationLanguage(partnerData.staffNotificationLanguage);
        setWebsite(partnerData.website);
        setFacebook(partnerData.facebook);
        setInstagram(partnerData.instagram);
        setDescription(partnerData.Description);
        // setAddress(partnerData.address);
        setCurrency(partnerData.currency);
        setFirstname(partnerData.firstname);
        setLastname(partnerData.lastname);
      }
    };

    const getTimezones = async () => {
      const timezoneData = await partnerInstance().get("/timezones");
      const { code, timezonesdata } = timezoneData.data;
      if (code === 200) {
        setTimezones(timezonesdata);
      }
    };
    //setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getTimezones();
        getInvoice();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const updateAccount = async (e) => {
    e.preventDefault();
    const payload = {
      companyName,
      timezone: timeZone,
      timeformat: timeFormat,
      weekStart,
      clientNotificationLanguage,
      staffNotificationLanguage,
      website,
      facebook,
      instagram,
      Description,
      currency,
      firstname,
      lastname,
    };

    const validation = validateAccountSetting({
      companyName,
      website,
      facebook,
      instagram,
      Description,
      currency,
    });

    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const accountData = await partnerInstance().put("/partnerAccount", payload);

    const { code } = accountData.data;
    if (code === 200) {
      history.push("/partners/signuplocation");
    }

    // const accountData = await partnerInstance().put("/partnerAccount", payload);

    // const { code, msg } = accountData.data;
    // if (code === 200) {
    //   history.push("/partners/signuplocation");
    // }
  };
  // if (props.isSubmit === true) {
  //   updateAccount()
  // }

  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3>Business Partner Setup</h3>
                    <div className="signup-progress">
                      <ProgressBar now={12} />
                    </div>
                  </div>
                  <div className="signup-body">
                    {/* <SignupBusiness isSubmit={isSubmit} /> */}

                    <div className="account-setting signup-business">
                      {/* <div className="container"> */}
                      {/* <h2 className="admin-title">
                Business Partner Setup
                  <Link className="add-location-link" to="/partners/location">
                   Add Location
                  </Link>
                      </h2> */}
                      <div className="back-setup">
                        <Form onSubmit={updateAccount}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="business-card">
                                {/* <Card> */}
                                {/* <Card.Img variant="top" /> */}
                                {/* <Card.Body> */}
                                <Form.Group>
                                  <Form.Label>Business Type</Form.Label>

                                  <Form.Control
                                    size="lg"
                                    type="text"
                                    placeholder="Type Your Business Type"
                                    value={businessType}
                                    readonly
                                  />
                                  {errors.companyName && (
                                    <p className="error">
                                      {errors.companyName}
                                    </p>
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="business-card">
                                {/* <Card> */}
                                {/* <Card.Img variant="top" /> */}
                                {/* <Card.Body> */}
                                <Form.Group className="ml-auto">
                                  <Form.Label>Business Name</Form.Label>

                                  <Form.Control
                                    size="lg"
                                    type="text"
                                    placeholder="Type Your Business Name"
                                    onChange={(e) =>
                                      setCompanyName(e.target.value)
                                    }
                                    value={companyName}
                                  />
                                  {errors.companyName && (
                                    <p className="error">
                                      {errors.companyName}
                                    </p>
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="des-field">
                                <Form.Group controlId="formBasicloginone">
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    placeholder="Add a short description"
                                    as="textarea"
                                    rows="3"
                                    value={Description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  {errors.Description && (
                                    <p className="error">
                                      {errors.Description}
                                    </p>
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                            {/* </Card.Body> */}
                            {/* </Card> */}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="business-card">
                                {/* <Card> */}
                                {/* <Card.Img variant="top" /> */}
                                {/* <Card.Body> */}
                                <Form.Group>
                                  <Form.Label>Currency</Form.Label>

                                  <Form.Control
                                    size="lg"
                                    type="text"
                                    placeholder="Type Your Currency"
                                    value={currency}
                                    onChange={(e) =>
                                      setCurrency(e.target.value)
                                    }
                                  />
                                  {errors.currency && (
                                    <p className="error">{errors.currency}</p>
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="Time-card">
                                {/* <Card>
                    <Card.Img variant="top" />
                    <Card.Body> */}
                                <Card.Title>Timezone Settings</Card.Title>
                                <Form.Group>
                                  <Form.Label>Time zone</Form.Label>

                                  <Form.Control
                                    as="select"
                                    size="lg"
                                    defaultValue={timeZone}
                                    selected={timeZone}
                                    value={timeZone}
                                    onChange={(e) =>
                                      setTimezone(e.target.value)
                                    }
                                  >
                                    {Object.keys(timezones).map((el, i) => (
                                      <option value={timezones[el].name}>
                                        {timezones[el].name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Time format</Form.Label>

                                  <Form.Control
                                    as="select"
                                    size="lg"
                                    value={timeFormat}
                                    onChange={(e) =>
                                      setTimeFormat(e.target.value)
                                    }
                                  >
                                    <option>12 hours (e.g. 9:00pm)</option>
                                    <option>24 hours (e.g. 21:00pm)</option>
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-0">
                                  <Form.Label>Week start</Form.Label>

                                  <Form.Control
                                    as="select"
                                    size="lg"
                                    value={weekStart}
                                    onChange={(e) =>
                                      setWeekStart(e.target.value)
                                    }
                                  >
                                    <option value="Sunday">Sunday</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                  </Form.Control>
                                </Form.Group>
                                {/* </Card.Body>
                  </Card> */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="business-card">
                                {/* <Card>
                    <Card.Img variant="top" />
                    <Card.Body>
                      <Card.Text> */}
                                <Card.Title className="pl-4">
                                  Social Connect
                                </Card.Title>
                                <Form.Group className="ml-auto">
                                  <Form.Label>Website</Form.Label>

                                  <InputGroup className="mb-2 mr-sm-2">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        <img src={websiteicon} alt="" />
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      id="inlineFormInputGroupUsername2"
                                      placeholder="Website"
                                      onChange={(e) =>
                                        setWebsite(e.target.value)
                                      }
                                      value={website}
                                    />
                                  </InputGroup>
                                  {errors.website && (
                                    <p className="error">{errors.website}</p>
                                  )}
                                </Form.Group>
                                <Form.Group className="ml-auto">
                                  <Form.Label>Facebook page</Form.Label>

                                  <InputGroup className="mb-2 mr-sm-2">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        <img src={facebookicon} alt="" />
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      id="inlineFormInputGroupUsername2"
                                      placeholder="Username"
                                      onChange={(e) =>
                                        setFacebook(e.target.value)
                                      }
                                      value={facebook}
                                    />
                                  </InputGroup>
                                  {errors.facebook && (
                                    <p className="error">{errors.facebook}</p>
                                  )}
                                </Form.Group>
                                <Form.Group className="ml-auto mb-0">
                                  <Form.Label>Instagram page</Form.Label>

                                  <InputGroup className="mb-2 mr-sm-2">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        <img src={insta} alt="" />
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      id="inlineFormInputGroupUsername2"
                                      placeholder="Username"
                                      onChange={(e) =>
                                        setInstagram(e.target.value)
                                      }
                                      value={instagram}
                                    />
                                  </InputGroup>
                                  {errors.instagram && (
                                    <p className="error">{errors.instagram}</p>
                                  )}
                                </Form.Group>
                                {/* </Card.Text>
                    </Card.Body>
                  </Card> */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="row">
                <div className="account-save col-md-12">
                  <Button type="submit" className="btn btn-primary">
                    Save
                  </Button>
                </div>
              </div> */}
                        </Form>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                {/* <div className="signup-right"> */}
                <div className="signup-footer-btn">
                  <Button type="submit" onClick={(e) => updateAccount(e)}>
                    Next
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupStep1;
