import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Button, Form } from "react-bootstrap";
//import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  //CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import PartnerWrapper from '../partnerwrapper/wrapper';
//import { stripeKey } from '../../../config/keys';
import { partnerInstance } from "../../../config/axios";
import PartnerContext from "../../../context/context";
import { checkKeys } from "../../../function/validate";
import history from "../../../config/history";
//import Loader from '../../loader/loader';
// import PricingPackage from './pricingPackege/pricingPackage';
// import Details from './pricingPackege/details';
// import CheckAvailablity from './pricingPackege/checkAvailability';
// import history from '../../../config/history';
//const stripePromise = loadStripe(stripeKey);

const PricingPayment = ({ handleStep, step, data, setAccountData }) => {
  console.log("data that come ..", data);
  data=data.newData;
  console.log("data that come ....", data);
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  //const [loading, setLoading] = useState(false);
  const [publish, setPublish] = useState(false);
  // const [data, setData] = useState();

  const AccountHolder = useCallback(async () => {
    //setLoading(true);

    const resData = await partnerInstance().get("/getPartnerAccount");

    if (resData.data.code === 200) {
      setPublish(resData.data.partnerData.publish);
      //setLoading(false);
    }
    //setData(formData.formData.newData);
    // eslint-disable-next-line
  }, []);
  console.log("Hey I am in this page yeah");
  useEffect(() => {
    //setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Billing and card processing"
        );
      if (findBook > -1) {
        AccountHolder();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  return (
    // <PartnerWrapper>
    <div className="h-100">
      <Elements stripe={checkKeys()}>
        <CheckOutForm
          publish={publish}
          AccountHolder={AccountHolder}
          data={data}
          step={step}
          handleStep={handleStep}
          setAccountData={setAccountData}
        />
      </Elements>
    </div>
    // </PartnerWrapper>
  );
};

export default PricingPayment;

const CheckOutForm = ({ publish, AccountHolder, data, step, handleStep, setAccountData }) => {
  const [accountType] = useState(data && data.accountType);
  const [name, setName] = useState("");
  const [errorName, seterrorName] = useState(false);
  const [firstname] = useState(data && data.firstname);
  const [lastname] = useState(data && data.lastname);
  const [address] = useState(data && data.address);
  const [city] = useState(data && data.city);
  const [State] = useState(data && data.State);
  const [postalCode] = useState(data && data.postalCode);
  const [taxNo] = useState(data && data.taxNo);
  const stripe = useStripe();
  const elements = useElements();
  //const [error, setError] = useState(null);
  const [error, setError] = useState(["", "", ""]);
  const [error2, setError2] = useState("");
  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    showIcon: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  if (data) {
  }
  const handleChange = (event, i) => {
    let error1 = [...error];
    console.log(event.error);
    if (event.error) {
      error1[i] = event.error.message;

      setError(error1);
      if (i === 1) {
        setError2(event.error.message);
      }
    } else {
      error1[i] = "";
      setError(error1);
      if (i === 1) {
        setError2("");
      }
    }
  };
  const handleCardName = (e) => {
    seterrorName(false);
    setName(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(error);
    const card = elements.getElement(CardNumberElement);

    const result = await stripe.createToken(card, { name: name });

    if (name.trim().length === 0) {
      seterrorName(true);
    }
    if (!result.error && name.trim().length !== 0) {
      setError(["", "", ""]);
      //setLoading(true);
      const payload = {
        stripeToken: result.token.id,
        accountType,
        firstname,
        lastname,
        address,
        city,
        State,
        postalCode,
        taxNo,
      };
      console.log('Payload', payload)
      const response = await partnerInstance().post("/connectOnline", payload);
      const { code, msg } = response.data;
      if (code === 200) {
        //setLoading(false);
        toast.success(msg, { containerId: "B" });
        history.push("/partners/online-payment");
        AccountHolder();
      } else {
        //setLoading(false);
        toast.error(msg, { containerId: "B" });
      }
    }
  };

  const send = () => {
    history.push("/partners/online-payment");
  };

  const handleBack = () => {
    handleStep(step - 1);
    setAccountData({
      accountType:data && data.accountType,
      postalCode:data && data.postalCode
    })
  };
  useEffect(()=>{
    if(publish) history.push("/partners/online-payment")
  })
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="payment-stripe-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <PricingPackage />
              <Details /> */}

                <div className="card-price-details pricing-section">
                  <h1>Step 3 of 3: Card Details</h1>
                  <p>
                    Your card is only used to top up your account if your
                    balance is become negative
                  </p>
                  <div className="row">
                    {!publish ? (
                      <div className="payment-stripe">
                        <div className="col-md-12">
                          <Form.Group>
                            <Form.Label>Card holder name</Form.Label>
                            <Form.Control
                              value={name}
                              onChange={(e) => handleCardName(e)}
                              className={`${errorName ? "error-box" : " "}`}
                            />
                            {errorName && (
                              <p className="error">Enter Card Holder Name</p>
                            )}
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>Card number</Form.Label>
                            <CardNumberElement
                              id="card-element"
                              class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                              options={CARD_ELEMENT_OPTIONS}
                              onChange={(e) => handleChange(e, 0)}
                            />
                            <p className="error">{error[0]}</p>
                          </Form.Group>

                          <div className="stripe-box">
                            <Form.Group>
                              <Form.Label>Expiry date</Form.Label>
                              <CardExpiryElement
                                id="card-element"
                                class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={(e) => handleChange(e, 1)}
                              />
                              <p className="error">{error2}</p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>CVV</Form.Label>
                              <CardCvcElement
                                id="card-element"
                                class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={(e) => handleChange(e, 2)}
                              />
                              <p className="error">{error[2]}</p>
                            </Form.Group>
                          </div>

                          {/* <div className="save-card-btn">
                            <Button type="submit" className="btn-perple">
                              Save Your Card
                            </Button>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="payment-stripe">
                        <div className="col-md-12">
                          <Button
                            className="stripe-btn btn-perple"
                            onClick={send}
                          >
                            Your Card Is Saved{" "}
                          </Button>
                          {/* <IbanElement
                id="card-element"
                class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                options={IBAN_ELEMENT_OPTIONS}
                onChange={handleChange}
              /> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <CheckAvailablity /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="signup-footer-btn payment-btn">
          <Link
            className=" btn-perple btn btn-primary"
            onClick={() => handleBack()}
            // to={{
            //   pathname: "/partners/online-booking",
            //   state: {
            //     accountType,
            //     firstname,
            //     lastname,
            //     address,
            //     city,
            //     State,
            //     postalCode,
            //     taxNo,
            //   },
            // }}
          >
            Back
          </Link>
          <Button type="submit" className="btn-perple">
            Save Your Card
          </Button>
          {/* {publish && (
                  <div className="step-btn">
                    <Link to="/partners/availablity" className="continue-btn">Continue</Link>
                  </div>
                )} */}
        </div>
      </Form>
    </>
  );
};
