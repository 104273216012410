import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import profile from "../../../../assets/no-image.jpg";
import { partnerInstance } from "../../../../config/axios";
import { server } from "../../../../config/keys";
import history from "../../../../config/history";

const OnlineProfile = () => {
  const [ID, setID] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [storePhoto, setStorePhoto] = useState("");
  const getLocations = async () => {
    const locat = await partnerInstance().get("/getPartnerLocations");

    const { code, partnerData } = locat.data;
    if (code === 200) {
      setID(partnerData.partnerLocation[0]._id);
      setLocationName(partnerData.partnerLocation[0].locationName);
      setAddress(partnerData.partnerLocation[0].addressFormat);
      setStatus(partnerData.publish ? "Online" : "Offline");
      setStorePhoto(partnerData.photo);
      setPartnerId(partnerData._id);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const unListProfile = async (data) => {
    confirmAlert({
      title: "Confirm to List",
      message:
        data !== "Online"
          ? "Add your online booking profile"
          : "Are you sure you want to remove your online booking profile ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const deleteCategor = await partnerInstance().post(
              "/unlistProfile"
            );
            const { code, msg } = deleteCategor.data;
            if (code === 200) {
              toast.success(msg, { containerId: "B" });
              getLocations();
            } else {
              toast.error(msg, { containerId: "B" });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="online-profile-page">
      <div className="listing-profile">
        <div className="top-side">
          <div className="listing-option">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {status === "Online" ? (
                  <>
                    <Dropdown.Item
                      onClick={() =>
                        history.push({
                          pathname: `/partners/editlocation/${ID}`,
                          info: {
                            offline: true,
                          },
                        })
                      }
                    >
                      Edit Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => unListProfile(status)}>
                      Unlist Profile
                    </Dropdown.Item>
                  </>
                ) : (
                  <Dropdown.Item onClick={() => unListProfile(status)}>
                    List Profile
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <img
            src={storePhoto ? `${server}/${storePhoto}` : profile}
            alt="profileimg"
          />
        </div>
        <div className="bottom-side">
          <div className="profile-name">
            <div className="name-status">
              <h6>{locationName}</h6>
              <h5 className={status === "Online" ? "online" : "ofline"}>
                {status}
              </h5>
            </div>

            {/* <p>Updated Tue 23 Mar, 2021</p> */}
          </div>
          <div className="address-info">
            <h6>{address}</h6>
          </div>
          <div className="profile-view-btn">
            <Link
              to={`/storedetail/?partnerid=${partnerId}`}
              className="btn btn-primary btn-perple"
            >
              View on Appointlee
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnlineProfile;
