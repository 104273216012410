import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
//import queryString from 'query-string';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';

const AdminForget = ({ name }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passType0, setPassType0] = useState('password');
  const [passType, setPassType] = useState('password');
  const [passType2, setPassType2] = useState('password');

  const validateChangePassword = (values) => {
    const reseterrors = {};
    let isValid = true;
    // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    if (!values.oldPassword.replace(/ /g, '')) {
      reseterrors.oldPassword = 'Old Password is required';
      isValid = false;
    }
    if (!values.currentPassword.replace(/ /g, '')) {
      reseterrors.currentPassword = 'Current Password is required';
      isValid = false;
    } else if (!passwordRegex.test(values.currentPassword)) {
      reseterrors.currentPassword = 'The password must be at least 8 characters long, and you must use at least one uppercase and one number';
      isValid = false;
    }
    if (!values.confirmPassword) {
      reseterrors.confirmPassword = 'Confirm password is required';
      isValid = false;
    } else if (values.currentPassword !== values.confirmPassword) {
      reseterrors.confirmPassword = 'Confirm password does not match';
      isValid = false;
    }
    return { isValid, reseterrors };
  };

  const changePassword = async (e) => {
    e.preventDefault();

    const validation = validateChangePassword({
      oldPassword,
      currentPassword,
      confirmPassword,
    });
    setErrors(validation.reseterrors);
    if (!validation.isValid) {
      return;
    }
    const payload = { oldPassword, currentPassword };
    const saveData = await adminInstance().post('/ChangePassword', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      setOldPassword('');
      setCurrentPassword('');
      setConfirmPassword('');
      toast.success(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  return (
    <AdminWrapper name={name}>
      <div className="forget-password-section">
        <div className="forget-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="forget-form login partner-login">
                  <h1>Change Password</h1>
                  <div className="reset-box">
                    <Form onSubmit={changePassword}>
                      <Form.Group controlId="formBasicloginone">
                        <Form.Control
                          type={passType0}
                          placeholder="Old Password"
                          name="password"
                          value={oldPassword}
                          autoComplete="off"
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <i
                          role="presentation"
                          className={
                            passType0 === 'password'
                              ? 'fa fa-eye-slash'
                              : 'fa fa-eye'
                              }
                          onClick={() => setPassType0(
                            passType0 === 'password' ? 'text' : 'password',
                          )}
                        />
                        {errors.oldPassword && (
                        <span className="error">{errors.oldPassword}</span>
                        )}
                      </Form.Group>
                      <Form.Group controlId="formBasicloginone">
                        <Form.Control
                          type={passType}
                          placeholder="New Password"
                          name="password"
                          value={currentPassword}
                          autoComplete="off"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <i
                          role="presentation"
                          className={
                                passType === 'password'
                                  ? 'fa fa-eye-slash'
                                  : 'fa fa-eye'
                              }
                          onClick={() => setPassType(
                            passType === 'password' ? 'text' : 'password',
                          )}
                        />
                        {errors.currentPassword && (
                        <span className="error">{errors.currentPassword}</span>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Control
                          type={passType2}
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          value={confirmPassword}
                          autoComplete="off"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <i
                          role="presentation"
                          className={
                                passType2 === 'password'
                                  ? 'fa fa-eye-slash'
                                  : 'fa fa-eye'
                              }
                          onClick={() => setPassType2(
                            passType2 === 'password' ? 'text' : 'password',
                          )}
                        />
                        {errors.confirmPassword && (
                        <span className="error">
                          {errors.confirmPassword}
                        </span>
                        )}
                      </Form.Group>
                      <Button type="submit" className="reset-btn">Continue</Button>
                    </Form>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminForget;
