import config from "./config.json";

const envData = config[process.env.REACT_APP_ENV];

export const server = envData.server;
export const client = envData.client;
export const facebookId = envData.facebookId;
export const googleId = envData.googleId;
export const stripeKey = envData.stripeKey;
export const livestripeKey = envData.livestripeKey;
export const stripeClient = envData.stripeClient;

// export const server = "http://localhost:3001";
// export const client = "http://localhost:3000";
// export const facebookId = '216170150235088';
// export const googleId = '710307600273-0sosnbb99e3e88l879ii4ij53u0lq5s3.apps.googleusercontent.com';
// export const stripeKey = 'pk_test_51IMrviDA3n0KS6L0fZIE6FGlrxbQtvVZMuw4rP3DMiQWx33j7yWNeOKZSKiljEIfStnzZzimvbN8Ub7YDYARxc5G00krPp2nGk';
// export const livestripeKey = 'pk_live_51IMrviDA3n0KS6L0JMqKrDTrB57IAvvw8RxzHEECAPgDTnYqShLIu1AFkKfSAaTrwYQerd6xtaLJG1RZ31urKhC800xOXKtpqP';
// export const stripeClient = 'ca_J2XpYtqMR0adoE4qC40h85cDV5RHYL2p';

// export const server = 'https://www.appointlee.com';
// export const client = 'https://staging.appointlee.com';
