import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Spinner, DropdownButton, Form } from "react-bootstrap";
import ReactExport from "react-export-excel";
import { CSVLink } from "react-csv";
import PartnerWrapper from "../partnerwrapper/wrapper";
import "./customer.css";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import Loader from "../../loader/loader";
import PartnerContext from "../../../context/context";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [copy, setCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex((boo) => boo.sectionname === "Clients");
      if (findBook > -1) {
        getCustomers();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);
  const getCustomers = async () => {
    setLoading(true);
    const customersData = await partnerInstance().get("/getCustomers");
    const { code, clientId: customerlist } = customersData.data;
    const arr = [];
    if (code === 200) {
      setCustomers(customerlist);
      customerlist.forEach((el) => {
        arr.push({
          firstname: el.firstname,
          lastname: el.lastname,
          blocked: el.isBlock ? "Yes" : "No",
          email: el.email,
          phoneCode: el.phoneCode,
          phone: el.phone,
          NotificationMethod: el.notificationMethod,
          language: el.language,
          ReferralSource: el.referralSource,
          dob: el.dob,
          Address: el.address,
          city: el.city,
          state: el.state,
          postalcode: el.postalCode,
          gender: el.gender,
        });
        setCopy(arr);
      });
      setLoading(false);
    }
  };

  const handleBlock = async (id, index) => {
    const oldState = [...customers];
    oldState[index].isLoading = true;
    setCustomers(oldState);
    const response = await partnerInstance().put(`/blockCustomer/${id}`);
    const {
      data: { code },
    } = response;
    if (code === 200) {
      const oldStatee = [...customers];
      oldState[index].isLoading = false;
      setCustomers(oldStatee);
      getCustomers();
    }
  };

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(copy[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };
  const uploadcustomers = [
    {
      firstname: "john",
      lastname: "smith",
      email: "john@gmail.com",
      phone: "796358945",
      gender: "male",
      bithday: "21-12-1996",
    },
  ];

  const headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Blocked", key: "blocked" },
    { label: "Email", key: "email" },
    { label: "Phone Code", key: "phoneCode" },
    { label: "Phone", key: "phone" },
    { label: "Notification Method", key: "NotificationMethod" },
    { label: "Language", key: "language" },
    { label: "Referral Source", key: "ReferralSource" },
    { label: "Birthday", key: "dob" },
    { label: "Address", key: "Address" },
    { label: "City", key: "city" },
    { label: "state", key: "state" },
    { label: "Postal Code", key: "postalcode" },
    { label: "Gender", key: "gender" },
  ];

  const customerSearch = (e) => {
    const old = [...copy];
    setSearchKeyword(e);
    if (parseInt(e.length) > 0) {
      const filteredServices = old.filter(
        (service) =>
          service.firstname.toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
          service.email.toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
          service.phone.toLowerCase().indexOf(e.toLowerCase()) !== -1
      );
      setCustomers(filteredServices);
    } else {
      setCustomers(copy);
    }
  };

  const handleRefresh = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Customers">
      {loading && <Loader />}
      <div className="container">
        <div className="customer-list-dropdown">
          {/* <h2 className="admin-title cus-title">Customer</h2> */}
          <div className="filter-right-block">
            <div className="revenue-search">
              <Form>
                <input
                  type="text"
                  placeholder="Search by Name, Email or Phone"
                  className="form-control"
                  value={searchKeyword}
                  onChange={(e) => customerSearch(e.target.value)}
                  onKeyDown={handleRefresh}
                />
                <Button>
                  <i className="fa fa-search" />
                </Button>
              </Form>
            </div>
            <div className="header-buttons ">
              <DropdownButton
                className="dark-btn gbu6"
                id="dropdown-basic-button-export"
                title="Options"
              >
                <h6>
                  {copy.length > 0 && (
                    <ExcelFile
                      filename="Appointlee Customers"
                      element={<button>Download Excel</button>}
                    >
                      <ExcelSheet data={copy} name="customers-list">
                        {filterColumns(copy).map((col) => (
                          <ExcelColumn label={camelCase(col)} value={col} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </h6>
                <CSVLink
                  data={copy}
                  headers={headers}
                  filename="customers-list.csv"
                >
                  Download CSV
                </CSVLink>
                <CSVLink
                  data={uploadcustomers}
                  filename="import-customers-list.csv"
                >
                  Import Clients
                </CSVLink>
              </DropdownButton>
            </div>
            <Link to="/partners/addcustomer" className="add-voucher-btn">
              {/* <i className="fa fa-plus" /> */} Add Customer
            </Link>
          </div>
        </div>
        <div className="customer-list">
          <div className="partner-table">
            {customers.length > 0 ? (
              <Table responsive="lg">
                <thead>
                  <tr>
                    <th className="text-left">Name</th>
                    <th>Email</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {customers &&
                    customers.map((el, i) => (
                      <>
                        <tr>
                          <td className="text-left">
                            {el.firstname} {el.lastname}
                          </td>

                          <td> {el.email ? el.email : "N/A"}</td>

                          <td className=" service-save text-center">
                            <Link
                              to={`/partners/customerprofile?customerid=${el._id}`}
                              className="btn btn-primary btn-perple"
                            >
                              view
                            </Link>
                            <Button
                              onClick={() => handleBlock(el._id, i)}
                              variant="default"
                              className="l-btn"
                            >
                              {el.isLoading ? (
                                <Spinner
                                  visible={i}
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : el.isBlock ? (
                                "Unblock"
                              ) : (
                                "Block"
                              )}
                            </Button>
                            {/* <Link
                            to={`/partners/editcustomer?customerid=${el._id}`}
                            className="btn btn-primary"
                          >
                            <i className="fa fa-edit" />
                          </Link> */}
                            {/* <Button
                            onClick={() => handleShowDelete(el._id)}
                            variant="danger"
                          >
                            <i className="fa fa-trash" />
                          </Button> */}
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </Table>
            ) : (
              <p>No Customers Found</p>
            )}
          </div>
        </div>
      </div>

      {/* <Modal show={showDelete} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Delete Customer
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeleteCustomer
            customerId={customerId}
            setShowDelete={setShowDelete}
            getCustomers={() => getCustomers()}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal> */}
    </PartnerWrapper>
  );
};

export default Customer;

// function DeleteCustomer({ customerId, getCustomers, setShowDelete }) {
//   const deleteIds = async (e) => {
//     e.preventDefault();
//     const payloadd = {
//       deleteId: customerId,
//     };
//
//     const deleteVoucher = await partnerInstance().post(
//       '/deleteCustomer/',
//       payloadd
//     );

//     const { code, msg } = deleteVoucher.data;
//     if (code === 200) {
//       setShowDelete(false);
//       getCustomers();
//       toast.success(msg, { containerId: 'B' });
//     } else if (code === 409) {
//       toast.error(msg, { containerId: 'B' });
//     } else {
//       toast.error(msg, { containerId: 'B' });
//     }
//   };

//   return (
//     <Form onSubmit={deleteIds}>
//       <p>Are You sure you want to delete ?</p>
//       <Button variant="primary" type="submit">
//         Delete
//       </Button>
//     </Form>
//   );
// }
