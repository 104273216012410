import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import moment from "moment";
import PartnerWrapper from "../partnerwrapper/wrapper";
// import staffimg from '../../../assets/partner/staff.png';
import { partnerInstance } from "../../../config/axios";
// import { validateService } from '../../../function/validate';
import history from "../../../config/history";
//import Discount from "../../../assets/discount.svg";
import { validateAppointment } from "../../../function/validate";

const AddAppointment = () => {
  const [services, setServices] = useState([]);
  // const [errors, setErrors] = useState({});
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState("");
  const [errors, setErrors] = useState({});
  const [appointmentPanel, setAppointmentPanel] = useState([
    {
      serviceId: "",
      staffId: "",
      startTime: "",
      duration: "",
      appointmentNote: "",
    },
  ]);
  const [staffs, setStaffs] = useState([]);
  const [staffId, setStaffId] = useState("");
  const [serviceId, setServiceId] = useState("");
  // const [duration, setDuration] = useState('');
  // const [startTime, setStartTime] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customersCopy, setCustomersCopy] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [extraDurationAfter, setExtraDurationAfter] = useState("");
  //const [extraDurationBefore, setExtraDurationBefore] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    const value = queryString.parse(window.location.search);
    const { customerid, starttime, startDate } = value;
    setStartDate(startDate);
    // setStartTime(starttime);

    appointmentPanel.forEach((el, j) => {
      el.startTime = starttime;
    });
    setStartTime(starttime);
    setAppointmentPanel(appointmentPanel);
    setCustomerId(customerid);

    const getServices = async () => {
      const servicesData = await partnerInstance().get("/getServicesOnly");
      //console.log("servicesData", servicesData);
      const { code, services: Services } = servicesData.data;
      if (code === 200) {
        setServices(Services);
      }
    };
    const getStaffs = async () => {
      const response = await partnerInstance().get("/getStaffs");
      //console.log("Staff", response);
      const { code, stafflist } = response.data;
      if (code === 200) {
        setStaffs(stafflist);
      }
    };
    const getCustomers = async () => {
      const response = await partnerInstance().get("/getCustomers");
      //console.log("Customers", customers);
      const { code, clientId } = response.data;
      if (code === 200) {
        // setCustomers(clientId);
        setCustomersCopy(clientId);
      }
    };

    getCustomers();
    getStaffs();
    getServices();
    // eslint-disable-next-line
  }, []);
  const getStaffById = async (id) => {
    const idd = {
      staffid: id,
      appointmentDate: startDate,
    };
    // eslint-disable-next-line
    const response = await partnerInstance().post("/getStaff", idd);
  };
  const getServiceById = async (serviceid, i) => {
    const payload = {
      serviceid,
    };
    const response = await partnerInstance().post("/getService", payload);
    const { code, service } = response.data;
    if (code === 200) {
      setDuration(service.duration);
      //setExtraDurationBefore(service.extraDurationBefore);
      setExtraDurationAfter(service.extraDurationAfter);
      appointmentPanel.forEach((el, j) => {
        if (i === j) {
          el.duration = service.duration;
        }
      });
      setAppointmentPanel(appointmentPanel);
    }
  };

  const addAppointment = async (e) => {
    e.preventDefault();

    const payload = {
      appointmentDate: startDate,
      customerId,
      appointmentPanel,
      staffId,
    };
    const validation = validateAppointment({
      customerId,
      staffId,
      appointmentPanel,
      serviceId,
    });

    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const saveData = await partnerInstance().post("/addAppointment", payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      // clearValues();
      history.push("/partners/calendar");
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  const timeData = [
    "12:00",
    "12:05",
    "12:10",
    "12:15",
    "12:20",
    "12:25",
    "12:30",
    "12:35",
    "12:40",
    "12:45",
    "12:50",
    "12:55",
    "01:00",
    "01:05",
    "01:10",
    "01:15",
    "01:20",
    "01:25",
    "01:30",
    "01:35",
    "01:40",
    "01:45",
    "01:50",
    "01:55",
    "02:00",
    "02:05",
    "02:10",
    "02:15",
    "02:20",
    "02:25",
    "02:30",
    "02:35",
    "02:40",
    "02:45",
    "02:50",
    "02:55",
    "03:00",
    "03:05",
    "03:10",
    "03:15",
    "03:20",
    "03:25",
    "03:30",
    "03:35",
    "03:40",
    "03:45",
    "03:50",
    "03:55",
    "04:00",
    "04:05",
    "04:10",
    "04:15",
    "04:20",
    "04:25",
    "04:30",
    "04:35",
    "04:40",
    "04:45",
    "04:50",
    "04:55",
    "05:00",
    "05:05",
    "05:10",
    "05:15",
    "05:20",
    "05:25",
    "05:30",
    "05:35",
    "05:40",
    "05:45",
    "05:50",
    "05:55",
    "06:00",
    "06:05",
    "06:10",
    "06:15",
    "06:20",
    "06:25",
    "06:30",
    "06:35",
    "06:40",
    "06:45",
    "06:50",
    "06:55",
    "07:00",
    "07:05",
    "07:10",
    "07:15",
    "07:20",
    "07:25",
    "07:30",
    "07:35",
    "07:40",
    "07:45",
    "07:50",
    "07:55",
    "08:00",
    "08:05",
    "08:10",
    "08:15",
    "08:20",
    "08:25",
    "08:30",
    "08:35",
    "08:40",
    "08:45",
    "08:50",
    "08:55",
    "09:00",
    "09:05",
    "09:10",
    "09:15",
    "09:20",
    "09:25",
    "09:30",
    "09:35",
    "09:40",
    "09:45",
    "09:50",
    "09:55",
    "10:00",
    "10:05",
    "10:10",
    "10:15",
    "10:20",
    "10:25",
    "10:30",
    "10:35",
    "10:40",
    "10:45",
    "10:50",
    "10:55",
    "11:00",
    "11:05",
    "11:10",
    "11:15",
    "11:20",
    "11:25",
    "11:30",
    "11:35",
    "11:40",
    "11:45",
    "11:50",
    "11:55",
    "12:00",
    "12:05",
    "12:10",
    "12:15",
    "12:20",
    "12:25",
    "12:30",
    "12:35",
    "12:40",
    "12:45",
    "12:50",
    "12:55",
    "13:00",
    "13:05",
    "13:10",
    "13:15",
    "13:20",
    "13:25",
    "13:30",
    "13:35",
    "13:40",
    "13:45",
    "13:50",
    "13:55",
    "14:00",
    "14:05",
    "14:10",
    "14:15",
    "14:20",
    "14:25",
    "14:30",
    "14:35",
    "14:40",
    "14:45",
    "14:50",
    "14:55",
    "15:00",
    "15:05",
    "15:10",
    "15:15",
    "15:20",
    "15:25",
    "15:30",
    "15:35",
    "15:40",
    "15:45",
    "15:50",
    "15:55",
    "16:00",
    "16:05",
    "16:10",
    "16:15",
    "16:20",
    "16:25",
    "16:30",
    "16:35",
    "16:40",
    "16:45",
    "16:50",
    "16:55",
    "17:00",
    "17:05",
    "17:10",
    "17:15",
    "17:20",
    "17:25",
    "17:30",
    "17:35",
    "17:40",
    "17:45",
    "17:50",
    "17:55",
    "18:00",
    "18:05",
    "18:10",
    "18:15",
    "18:20",
    "18:25",
    "18:30",
    "18:35",
    "18:40",
    "18:45",
    "18:50",
    "18:55",
    "19:00",
    "19:05",
    "19:10",
    "19:15",
    "19:20",
    "19:25",
    "19:30",
    "19:35",
    "19:40",
    "19:45",
    "19:50",
    "19:55",
    "20:00",
    "20:05",
    "20:10",
    "20:15",
    "20:20",
    "20:25",
    "20:30",
    "20:35",
    "20:40",
    "20:45",
    "20:50",
    "20:55",
    "21:00",
    "21:05",
    "21:10",
    "21:15",
    "21:20",
    "21:25",
    "21:30",
    "21:35",
    "21:40",
    "21:45",
    "21:50",
    "21:55",
    "22:00",
    "22:05",
    "22:10",
    "22:15",
    "22:20",
    "22:25",
    "22:30",
    "22:35",
    "22:40",
    "22:45",
    "22:50",
    "22:55",
    "23:00",
    "23:05",
    "23:10",
    "23:15",
    "23:20",
    "23:25",
    "23:30",
    "23:35",
    "23:40",
    "23:45",
    "23:50",
    "23:55",
  ];
  const deleteAppointmentPanel = (i, id) => {
    // const oldArray = [...appointmentPanel];
    // oldArray.pop();
    // setAppointmentPanel([...oldArray]);

    const oldStatee = [...appointmentPanel];
    oldStatee.splice(id, 1);
    setAppointmentPanel([...oldStatee]);
  };
  const selectAppointmentNote = (e, i) => {
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.appointmentNote = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
  };
  const selectCustomerId = (e) => {
    setCustomerId(e);

    // appointmentPanel.forEach((el, j) => {
    //   if (i === j) {
    //     el.customerId = e;
    //   }
    // });
    // setAppointmentPanel(appointmentPanel);
  };
  const selectService = (e, i) => {
    setServiceId(e);
    getServiceById(e, i);

    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.serviceId = e;
        // el.appointmentDate = startDate;
      }
    });
    setAppointmentPanel(appointmentPanel);
  };
  const selectStartTime = (e, i) => {
    setStartTime(e);
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.startTime = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
  };

  const selectDuration = (e, i) => {
    setDuration(e);
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.duration = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
  };
  const selectStaff = (e, i) => {
    setStaffId(e);
    getStaffById(e);
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.staffId = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
  };

  const addMoreAppointmentPanel = async () => {
    const durationInMinutes = parseInt(duration) + parseInt(extraDurationAfter);
    const nextStartTime = moment(startTime, "HH:mm:ss")
      .add(durationInMinutes, "minutes")
      .format("HH:mm");
    const oldArray = [...appointmentPanel];
    oldArray.push({
      serviceId: "",
      staffId: "",
      startTime: nextStartTime,
      duration: "",
    });
    setAppointmentPanel(oldArray);
  };

  const searchCustomer = (e) => {
    setCustomers(customersCopy);
    setSearchKeyword(e);
    const filteredCustomers = customersCopy.filter(
      (customer) =>
        customer.firstname.toLowerCase().indexOf(e.toLowerCase()) !== -1
    );

    if (filteredCustomers) {
      setCustomers(filteredCustomers);
    } else {
      setCustomers("");
    }
  };

  return (
    <PartnerWrapper heading="New Appointment">
      <div className="single-services">
        <div className="container">
          <h2 className="admin-title">
            New Appointment{" "}
            <Link to="/partners/calendar">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="single-services-content">
            <Form onSubmit={addAppointment}>
              <div className="row">
                <div className="col-md-7">
                  <div className="service-card">
                    <div className="service-body">
                      {appointmentPanel.map((el, i, array) => (
                        <div className="pricing-option">
                          <div className="remove-pricing">
                            {/* <i
                              onClick={() => deleteAppointmentPanel(i)}
                              className="fa fa-times"
                            /> */}

                            {array.length > 1 && (
                              <i
                                onClick={() =>
                                  deleteAppointmentPanel(i, el._id)
                                }
                                className="fa fa-times"
                              />
                            )}
                          </div>
                          <h5>Appointment</h5>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Start Time </Form.Label>
                                <Form.Control
                                  as="select"
                                  onChange={(e) =>
                                    selectStartTime(e.target.value, i)
                                  }
                                  value={el.startTime}
                                >
                                  {timeData.map((el, i) => (
                                    <option key={i} value={el}>
                                      {el}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Service </Form.Label>
                                <Form.Control
                                  as="select"
                                  onChange={(e) =>
                                    selectService(e.target.value, i)
                                  }
                                >
                                  <option value="">Select Service</option>
                                  {services &&
                                    services.map((el) => (
                                      <option value={el._id}>
                                        {el.serviceName}
                                      </option>
                                    ))}
                                </Form.Control>
                                {errors.serviceId && (
                                  <p className="error">{errors.serviceId}</p>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Duration </Form.Label>
                                {duration ? (
                                  <Form.Control
                                    as="select"
                                    onChange={(e) =>
                                      selectDuration(e.target.value, i)
                                    }
                                    value={el.duration}
                                  >
                                    <option value="5">5min</option>
                                    <option value="10">10min</option>
                                    <option value="15">15min</option>
                                    <option value="20">20min</option>
                                    <option value="25">25min</option>
                                    <option value="30">30min</option>
                                    <option value="35">35min</option>
                                    <option value="40">40min</option>
                                    <option value="45">45min</option>
                                    <option value="50">50min</option>
                                    <option value="55">55min</option>
                                    <option value="60" selected>
                                      1h
                                    </option>
                                    <option value="65">1h 5min</option>
                                    <option value="70">1h 10min</option>
                                    <option value="75">1h 15min</option>
                                    <option value="80">1h 20min</option>
                                    <option value="85">1h 25min</option>
                                    <option value="90">1h 30min</option>
                                    <option value="95">1h 35min</option>
                                    <option value="100">1h 40min</option>
                                    <option value="105">1h 45min</option>
                                    <option value="110">1h 50min</option>
                                    <option value="115">1h 55min</option>
                                    <option value="120">2h</option>
                                    <option value="135">2h 15min</option>
                                    <option value="150">2h 30min</option>
                                    <option value="165">2h 45min</option>
                                    <option value="180">3h</option>
                                    <option value="195">3h 15min</option>
                                    <option value="210">3h 30min</option>
                                    <option value="225">3h 45min</option>
                                    <option value="240">4h</option>
                                    <option value="255">4h 15min</option>
                                    <option value="310">4h 30min</option>
                                    <option value="325">4h 45min</option>
                                    <option value="340">5h</option>
                                    <option value="355">5h 15min</option>
                                    <option value="410">5h 30min</option>
                                    <option value="425">5h 45min</option>
                                    <option value="440">6h</option>
                                    <option value="455">6h 15min</option>
                                    <option value="510">6h 30min</option>
                                    <option value="525">6h 45min</option>
                                    <option value="540">7h</option>
                                    <option value="555">7h 15min</option>
                                    <option value="610">7h 30min</option>
                                    <option value="625">7h 45min</option>
                                    <option value="640">8h</option>
                                    <option value="655">8h 15min</option>
                                    <option value="710">8h 30min</option>
                                    <option value="725">8h 45min</option>
                                    <option value="740">9h</option>
                                    <option value="755">9h 15min</option>
                                    <option value="810">9h 30min</option>
                                    <option value="825">9h 45min</option>
                                    <option value="840">10h</option>
                                    <option value="855">10h 15min</option>
                                    <option value="910">10h 30min</option>
                                    <option value="925">10h 45min</option>
                                    <option value="940">11h</option>
                                    <option value="955">11h 15min</option>
                                    <option value="1010">11h 30min</option>
                                    <option value="1025">11h 45min</option>
                                    <option value="1040">12h</option>
                                  </Form.Control>
                                ) : (
                                  <Form.Control disabled as="select" />
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Staff </Form.Label>
                                <Form.Control
                                  as="select"
                                  onChange={(e) =>
                                    selectStaff(e.target.value, i)
                                  }
                                >
                                  <option value="">Select Staff</option>
                                  {staffs.map((el) => (
                                    <option value={el._id}>
                                      {el.firstname}
                                    </option>
                                  ))}
                                </Form.Control>
                                {errors.staffId && (
                                  <p className="error">{errors.staffId}</p>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-12">
                              <Form.Group controlId="formBasicloginone">
                                <Form.Label>Appointment notes </Form.Label>
                                <Form.Control
                                  placeholder="Add a short description"
                                  as="textarea"
                                  rows="3"
                                  onChange={(e) =>
                                    selectAppointmentNote(e.target.value, i)
                                  }
                                />
                              </Form.Group>
                            </div>
                            {extraDurationAfter && (
                              <p>
                                {" "}
                                {extraDurationAfter}
                                min processing time after the service{" "}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}

                      <div
                        className="add-pricing-option"
                        onClick={addMoreAppointmentPanel}
                      >
                        <span>
                          <i className="fa fa-plus" /> Add more service
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="service-card search-client">
                    <div className="service-body">
                      <Form.Group controlId="formBasicloginone">
                        <Form.Control
                          type="text"
                          placeholder="Search Client"
                          name="category"
                          autoComplete="off"
                          value={searchKeyword}
                          onChange={(e) => searchCustomer(e.target.value)}
                        />
                      </Form.Group>
                      {/* <div className="tax-head">
                        <img src={Discount} className="mb-2" alt="Discount" />
                        <h5>Add a Voucher Type</h5>
                        <p className="text-muted ">
                          Use the search to add a client, or keep empty to save
                          as walk-in.
                        </p>
                      </div> */}
                      <div className="clientt">
                        {/* <p>Create New Client +</p> */}

                        <Link
                          to={`/partners/addcustomer?starttime=${startTime}&startDate=${startDate}`}
                        >
                          <i className="fa fa-plus" />
                          Create New Client
                        </Link>
                      </div>
                      <div className="clientt">
                        {customers &&
                          customers.map((el) => (
                            <>
                              <div className="client-sec">
                                <div className="client-img">
                                  <label>
                                    <input
                                      type="radio"
                                      name="optradio"
                                      onChange={() => selectCustomerId(el._id)}
                                    />
                                  </label>
                                </div>
                                <div className="client-name">
                                  <h6>
                                    {el.firstname}
                                    {el.lastname}
                                  </h6>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                      {errors.customerId && (
                        <p className="error">{errors.customerId}</p>
                      )}
                    </div>
                  </div>
                  <div className="associate-pricing-buttons">
                    <Button variant="default" className="btn-perple">
                      Express Checkout
                    </Button>
                    <Button type="submit" className="btn-perple">
                      Save Appointment
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};
export default AddAppointment;
