import React from 'react';
import { Button } from 'react-bootstrap';
import overview from '../../../../assets/overview1.png';
import overviewtwo from '../../../../assets/overview2.png';
import overviewthree from '../../../../assets/overview3.png';

const Overview = ({ setKey }) => (
  <div className="overview-page">
    <div className="info-section-one">
      <div className="right-overview">
        <img src={overview} alt="overview" />
      </div>
      <div className="left-overview">
        <div className="main-content-overview">
          <h6>Appointlee Plus</h6>
          <h1>Effortless online booking</h1>
          <p>
            Join the world’s largest beauty network that sends millions
            of clients to Salons and Spas every month.
            Create a business profile and be discovered 24/7 on Appointlees marketplace,
            Instagram, Facebook, Google and your own website.
            Free up your time and focus on what you do best.
          </p>
        </div>
        <div className="start-now">
          <Button onClick={() => setKey('profile')}>Start Now</Button>
        </div>
      </div>

    </div>
    <div className="info-section-two">
      <div className="info-image">
        <img src={overviewtwo} alt="qualityimage" />
      </div>
      <div className="info-content-area main-content-overview">
        <h1>Attract quality new clients</h1>
        <p>
          Reach thousands of new clients who visit Appointlee every day. Rank higher
          in online searches and sharpen your marketing
          with integrated conversion tracking for Google and Facebook.
        </p>
      </div>
    </div>
    <div className="info-section-one info-section-three">
      <div className="left-overview">
        <div className="main-content-overview">
          <h6>Appointlee Plus</h6>
          <h1>Capture authentic ratings and reviews</h1>
          <p>
            Happy clients attract new sales, let your clients spread the word
            about why they love you. Appointlee Plus is the best way to get clients sharing
            verified reviews and ratings
            about their experience with your business.
          </p>
        </div>
      </div>
      <div className="right-overview">
        <img src={overviewthree} alt="overview" />
      </div>

    </div>
    <div className="main-content-overview bottom-text">
      <h1>Elevate your business to its full potential</h1>
      <div className="start-now overview-btn">
        <Button onClick={() => setKey('profile')}>Start Now</Button>
      </div>
    </div>
  </div>
);
export default Overview;
