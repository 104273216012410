/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from "react";
//import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Table,
  Dropdown,
  DropdownButton,
  //FormControl,
  Modal,
} from "react-bootstrap";
import queryString from "query-string";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../../loader/loader";
import PartnerWrapper from "../partnerwrapper/wrapper";
import user from "../../../assets/partner/user.jpg";
import success from "../../../assets/partner/success.png";
import "./appointment.css";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import { server } from "../../../config/keys";

const ViewAppointmentInvoice = () => {
  const [ID, setID] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [customerFirstname, setCustomerFirstname] = useState("");
  const [customerLastname, setCustomerLastname] = useState("");
  const [appointmentPanel, setAppointmentPanel] = useState([]);
  const [voidStatus, setVoidStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  //const [discountType, setDiscountType] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [fileName, setFileName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [loc, setLoc] = useState("");
  const { username } = useContext(PartnerContext);
  const { currency, sections } = username;
  const [invoiceNo, setInvoiceNo] = useState("");
  const [cashDate, setCashDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  //const [tax, setTax] = useState([]);
  const [taxTotal, setTaxTotal] = useState(0);
  const [footer, setFooter] = useState("");
  const [sub, setSub] = useState(0);
  //const [taxStatus, setTaxStatus] = useState(false);
  //const [saved, setSaved] = useState(false);
  const [show, setShow] = useState(false);
  const handleHide = () => setShow(false);
  const [applyVoucher, setApplyVoucher] = useState({});
  const [status, setStatus] = useState(false);
  const [refStatus, setRefStatus] = useState(false);
  const [tips, setTips] = useState("");
  const [printRecipts, setPrintReceipts] = useState(false);
  const [custInfo, setCustInfo] = useState(false);
  const [lineOne, setLineOne] = useState("");
  const [lineTwo, setLineTwo] = useState("");

  const getAppointment = async (id) => {
    setLoading(true);
    const idd = {
      appointmentId: id,
    };
    setID(id);
    const response = await partnerInstance().post("/getAppointment", idd);

    const { code, appointmentData } = response.data;
    if (code === 200) {
      const wait = [...appointmentData.appointmentPanel];
      wait.forEach((ele) => {
        const filterStaffPrice = ele.serviceId.staffPricing.findIndex(
          (sel) => sel.staffId === ele.staffId._id
        );
        if (
          filterStaffPrice > -1 &&
          ele.serviceId.staffPricing[filterStaffPrice].staffPrice
        ) {
          ele.serviceId.priceFrom =
            ele.serviceId.staffPricing[filterStaffPrice].staffPrice;
        } else if (ele.serviceId.specialPrice) {
          ele.serviceId.specialPrice;
        } else {
          ele.serviceId.priceFrom;
        }
        setAppointmentPanel(wait);
      });
      // setAppointmentPanel(appointmentData.appointmentPanel);
      //setSaved(true);
      appointmentData.appointmentPanel.forEach((el) => {
        setStaffEmail(el.staffId.firstname);
      });
      setAppointmentDate(appointmentData.appointmentDate);
      setLoc(appointmentData.partnerId.partnerLocation[0].locationName);
      setCustomerFirstname(appointmentData.customerId.firstname);
      setCustomerLastname(appointmentData.customerId.email);
      setFileName(appointmentData.invoiceId.pdfUrl);
      setTotalAmount(appointmentData.totalAmount);
      setTotalDuration(appointmentData.totalDuration);
      setApplyVoucher(appointmentData.Voucher);
      setTotalDiscount(appointmentData.totalDiscount);
      setSub(appointmentData.subtotal);
      setTaxTotal(appointmentData.tax);
      //setDiscountType(appointmentData.discountValue);
      setPaymentMethod(appointmentData.paymentMethod);
      setInvoiceNo(appointmentData.invoiceId.invoiceno);
      setStatus(appointmentData.invoiceId.cancelStatus);
      setRefStatus(appointmentData.invoiceId.refund);
      setCashDate(appointmentData.invoiceId.createdAt);
      setCompanyName(appointmentData.partnerId.companyName);
      setBillingAddress(
        appointmentData.partnerId.partnerLocation[0].billingDetails[0].address
      );
      //setTax(appointmentData.partnerId.tax);
      setTips(appointmentData.tip);
      //setTaxStatus(appointmentData.partnerId.retailPriceIncludeTax);
      setLine1(
        appointmentData.customerId.email
          ? appointmentData.customerId.email
          : "N/A"
      );
      setLine2(
        appointmentData.customerId.phone
          ? appointmentData.customerId.phone
          : "N/A"
      );
      setFooter(
        appointmentData.partnerId.invoice.footer
          ? appointmentData.partnerId.invoice.footer
          : ""
      );
      setPrintReceipts(appointmentData.partnerId.invoice.automaticprint);
      setCustInfo(appointmentData.partnerId.invoice.clientaddress);
      setLineOne(
        appointmentData.partnerId.invoice.customline1
          ? appointmentData.partnerId.invoice.customline1
          : ""
      );
      setLineTwo(
        appointmentData.partnerId.invoice.customline2
          ? appointmentData.partnerId.invoice.customline2
          : ""
      );
      setLoading(false);
    }
  };

  const handleVoid = async () => {
    const findBook = sections.findIndex(
      (boo) => boo.sectionname === "Can void invoices"
    );
    if (findBook > -1) {
      setVoidStatus(!voidStatus);
      const payload = {
        appointmentId: ID,
        cancelStatus: voidStatus,
      };
      const response = await partnerInstance().post("/cancelInvoice", payload);
      const { code } = response.data;
      if (code === 200) {
        history.push("/partners/calendar");
      }
    } else {
      toast.error("You Dont Have Permisssion to Void Invoices", {
        containerId: "B",
      });
    }
  };

  useEffect(() => {
    // getInvoiceData();
    const value = queryString.parse(window.location.search);
    const { appointmentId } = value;
    // setAppointmentId(appointmentId);
    getAppointment(appointmentId);
  }, []);

  let taxtotal;

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <>
      <PartnerWrapper heading="View Invoice">
        {/* {loading && (
        <Loader />
        )} */}
        <div className="view-appointment-invoice-page appointment-view">
          <div className="container">
            <h2 className="admin-title">
              View Invoice{" "}
              <Link to={`/partners/viewappointment?id=${ID}`}>
                <i className="fa fa-angle-left" /> Back
              </Link>
            </h2>

            <div className="view-appointment-invoice-content appointment-content">
              <div className="row">
                <div className="col-md-5">
                  <div className="appointment-card">
                    {/* Customer Details */}

                    <div className="customer-detail">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="customer-card">
                            <div className="customer-detail-appoint">
                              <div className="customer-detail-icon">
                                <img src={user} alt="" />
                              </div>
                              <div className="customer-detail-content">
                                <h4>{customerFirstname}</h4>
                                <p>{customerLastname}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="no-invoice">
                      <img src={success} alt="" />
                      <h4>Completed</h4>

                      <p>
                        Full payment received on{" "}
                        {new Date(appointmentDate).toString().substring(0, 15)}{" "}
                        at {loc} by {staffEmail}
                      </p>
                    </div>

                    <div className="appointment-buttons">
                      <span>
                        Total: {currency} {taxtotal || totalAmount}{" "}
                        {totalAmount === 0 ? "Free" : ""} (
                        {Math.floor(parseInt(totalDuration) / 60) === 0 ? (
                          <>{parseInt(totalDuration) % 60} min</>
                        ) : (
                          <>
                            {Math.floor(parseInt(totalDuration) / 60)}h{" "}
                            {parseInt(totalDuration) % 60 === 0 ? (
                              ""
                            ) : (
                              <>,{parseInt(totalDuration) % 60} min</>
                            )}
                          </>
                        )}
                        )
                      </span>
                      <div className="footer-button">
                        <DropdownButton
                          id="dropdown-basic-button-2"
                          title="More Option"
                        >
                          <Link to={`/partners/calendar?rebook=${ID}`}>
                            Rebook Appointment
                          </Link>
                          {printRecipts && (
                            <>
                              {fileName ? (
                                <a
                                  href={`${server}/${fileName}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Download
                                </a>
                              ) : (
                                ""
                              )}
                              {fileName ? (
                                <a
                                  href={`${server}/${fileName}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Print
                                </a>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                          {status ? (
                            ""
                          ) : (
                            <>
                              {refStatus ? (
                                <Dropdown.Item onClick={handleVoid}>
                                  Void
                                </Dropdown.Item>
                              ) : (
                                <>
                                  <Dropdown.Item onClick={() => setShow(true)}>
                                    Refund
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={handleVoid}>
                                    Void
                                  </Dropdown.Item>
                                </>
                              )}
                            </>
                          )}
                        </DropdownButton>
                        {/* <Button
                        className="appointment-btn"
                      >
                        Close
                      </Button> */}
                        <Link
                          to="/partners/calendar"
                          className=" btn btn-primary btn-perple"
                          style={{ color: "#fff" }}
                        >
                          Close
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="appointment-card">
                    <div className="appointment-body">
                      <div className="view-invoice-form">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th colSpan="5">
                                <h4
                                  style={{
                                    fontSize: "18px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  Invoice #{invoiceNo}
                                </h4>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="5">
                                <span
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    display: "block",
                                  }}
                                >
                                  {new Date(appointmentDate)
                                    .toString()
                                    .substring(0, 15)}
                                </span>
                              </th>
                              {/* <th>
                              <span>#{invoiceNo}</span>
                            </th> */}
                            </tr>
                            <tr>
                              <th colSpan="5">
                                <h5
                                  style={{
                                    fontSize: "16px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  {companyName}
                                </h5>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="5">
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  {lineOne}
                                </h5>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="5">
                                <h5
                                  style={{
                                    fontSize: "20px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  {lineTwo}
                                </h5>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="5">
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  {billingAddress}
                                </h5>
                              </th>
                            </tr>

                            {custInfo && (
                              <>
                                <tr>
                                  <th colSpan="5">
                                    <h5
                                      style={{
                                        fontSize: "15px",
                                        margin: "0px",
                                        textAlign: "left",
                                        textTransform: "none",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      Customer Email:{" "}
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          color: "#212529",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {" "}
                                        {line1}{" "}
                                      </span>
                                    </h5>
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan="5">
                                    <h5
                                      style={{
                                        fontSize: "15px",
                                        margin: "0px",
                                        textAlign: "left",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      Custom Mobile:{" "}
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          color: "#212529",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {line2}
                                      </span>
                                    </h5>
                                  </th>
                                </tr>
                              </>
                            )}
                          </thead>
                          <tbody>
                            {appointmentPanel &&
                              appointmentPanel.map((el) => (
                                <>
                                  <tr>
                                    <td colSpan="2" width="50%">
                                      <h5
                                        style={{
                                          fontSize: "14px",
                                          margin: "0px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {el.serviceId.serviceName}
                                      </h5>
                                      <p>
                                        {el.categoryId.categoryname}{" "}
                                        {el.startTime},{" "}
                                        {new Date(appointmentDate)
                                          .toString()
                                          .substring(0, 15)}{" "}
                                        with {el.staffId.firstname}{" "}
                                        {totalDiscount > 0 ? (
                                          <>
                                            {" "}
                                            , Special discount {el.discountName}
                                            {el.disGone}
                                            {el.discountType} off
                                          </>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </p>
                                    </td>{" "}
                                    <td width="50%">
                                      <h5
                                        style={{
                                          fontSize: "14px",
                                          margin: "0px",
                                          textAlign: "right",
                                        }}
                                      >
                                        {currency}{" "}
                                        {el.serviceId.specialPrice
                                          ? el.serviceId.specialPrice
                                          : el.serviceId.priceFrom}
                                      </h5>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            {/* <tr>
                              <td colSpan="2">
                                <h5
                                  style={{
                                    fontSize: '14px',
                                    margin: '0px',
                                    textAlign: 'left',
                                  }}
                                >
                                  Discount
                                </h5>
                              </td>
                              <td>
                                <h5
                                  style={{
                                    fontSize: '14px',
                                    margin: '0px',
                                    textAlign: 'right',
                                  }}
                                >
                                  {discountType === '%' ? (
                                    <>
                                      {totalDiscount}
                                      {discountType}
                                    </>
                                  ) : (
                                    <>
                                      {currency}
                                      {' '}
                                      {totalDiscount}
                                    </>
                                  )}
                                </h5>
                              </td>
                            </tr> */}
                            <tr>
                              <td colSpan="2">
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Subtotal
                                </h5>
                              </td>
                              <td>
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency} {sub}
                                </h5>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Tax
                                  {appointmentPanel.map((item) => (
                                    <>
                                      {item.serviceId.serviceTax.taxName && (
                                        <p>
                                          {item.serviceId.serviceTax.taxName} (
                                          {item.serviceId.serviceTax.taxRate}
                                          %)
                                        </p>
                                      )}
                                    </>
                                  ))}
                                </h5>
                              </td>
                              <td>
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency} {taxTotal}
                                </h5>
                              </td>
                            </tr>
                            {applyVoucher.Value && (
                              <>
                                <tr>
                                  <td colSpan="2">
                                    <h5
                                      style={{
                                        fontSize: "14px",
                                        margin: "0px",
                                        textAlign: "left",
                                      }}
                                    >
                                      Voucher ({applyVoucher.Code})
                                    </h5>
                                  </td>
                                  <td>
                                    <h5
                                      style={{
                                        fontSize: "14px",
                                        margin: "0px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {currency} {applyVoucher.Value}
                                    </h5>
                                  </td>
                                </tr>
                              </>
                            )}
                            <tr>
                              <td colSpan="2">
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Total
                                </h5>
                              </td>
                              <td>
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency} {totalAmount}
                                  {/* {retailPriceIncludeTax ? Tax = (el.taxrate * totalAmount) / (1 + Tax Rate)} */}
                                </h5>
                              </td>
                            </tr>
                            {tips && (
                              <tr>
                                <td colSpan="2">
                                  <h5
                                    style={{
                                      fontSize: "14px",
                                      margin: "0px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Tips
                                  </h5>
                                </td>
                                <td>
                                  <h5
                                    style={{
                                      fontSize: "14px",
                                      margin: "0px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {currency} {tips}
                                    {/* {retailPriceIncludeTax ? Tax = (el.taxrate * totalAmount) / (1 + Tax Rate)} */}
                                  </h5>
                                </td>
                              </tr>
                            )}

                            <tr>
                              <td colSpan="2">
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {paymentMethod}
                                </h5>
                                <p>
                                  {new Date(cashDate)
                                    .toString()
                                    .substring(0, 15)}{" "}
                                  at{" "}
                                  {moment(new Date(cashDate)).format("HH:mm")}
                                </p>
                              </td>
                              <td>
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency}{" "}
                                  {tips ? +totalAmount + +tips : totalAmount}
                                </h5>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Balance
                                </h5>
                              </td>
                              <td>
                                <h5
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency} {0}
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="3">
                                <h4
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  {companyName}
                                </h4>
                                <h6
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px",
                                    textAlign: "center",
                                  }}
                                >
                                  {footer}
                                </h6>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleHide} className="discount-12">
            <Modal.Header closeButton>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                Refund
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Refund
                appId={ID}
                totalamount={totalAmount}
                currency={currency}
              />
            </Modal.Body>
            <Modal.Footer />
          </Modal>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default ViewAppointmentInvoice;

function Refund({ appId, totalamount, currency }) {
  const deleteIds = async (e) => {
    e.preventDefault();
    const payload = {
      appointmentId: appId,
    };
    const response = await partnerInstance().post("/refundInvoice", payload);
    const { code, msg } = response.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      history.push("/partners/calendar");
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={deleteIds} className="text-center">
      <p>
        Are You sure you want to Refund {currency} {totalamount}?
      </p>
      <Button variant="primary" type="submit" className="btn-perple">
        Refund
      </Button>
    </Form>
  );
}
