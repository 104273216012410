import React, { useState, useEffect, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import MyProfile from './myprofile';
import { userInstance } from '../../config/axios';
import Loader from '../loader/loader';

const MyVouchers = ({ username, userImage }) => {
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(6);
  const [skip, setSkip] = useState(0);

  const getVochersData = useCallback(async () => {
    setLoading(true);
    const payload = {
      skip,
      limit,
    };
    const response = await userInstance().post('/clientVouchers', payload);
    const { code, data, length } = response.data;
    if (code === 200) {
      setVouchers(data);
      setTotalCount(length);
      setLoading(false);
    } else {
      setVouchers([]);
    }
  }, [skip, limit]);

  useEffect(() => {
    getVochersData();
  }, [getVochersData]);

  // const nextPage = () => {
  //   setSkip(skip + limit);
  // };

  // const previousPage = () => {
  //   setSkip(skip - limit);
  // };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);
  if (loading) {
    return (
      <MyProfile username={username} userImage={userImage}>
        <Loader />
      </MyProfile>
    );
  }

  return (
    <MyProfile username={username} userImage={userImage}>
      {/* {loading && (
      <Loader />
      )} */}
      <div className="my-voucher">
        <h3>My Vouchers</h3>
        <div className="voucher-section new-voucher">
          {vouchers.length > 0 ? (
            <>
              <div className="voucher-section-list d-flex flex-wrap">
                {/* {partnerVouchers.map((el) => ( */}
                {vouchers.map((el) => (
                  <div className="voucher-section-item">
                    <div className="signle-voucher">
                      <div className="voucher-card">
                        <div className="voucher-body">
                          <div className="voucher-body-box">
                            <h4>Voucher Value </h4>
                            <div className="text-center voucher-name">
                              <h2>
                                {el.partnerId.currency}
                                {' '}
                                {el.voucherValue}
                              </h2>
                            </div>
                            <div className="text-center gift-voucher">
                              <h4>
                                Vocher Code :
                                {' '}
                                {el.voucherCode}
                              </h4>
                              <h5>
                                <h4>Retail Price</h4>
                                {el.partnerId.currency}
                                {' '}
                                {el.price}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="store-pagination text-center">
                {totalCount >= limit && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pagination-section">
                        <ReactPaginate
                          previousLabel="← Previous"
                          nextLabel="Next →"
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                          containerClassName="pagination-chk"
                          previousLinkClassName="pagination__link"
                          nextLinkClassName="pagination__link"
                          disabledClassName="pagination__link--disabled"
                          activeClassName="pagination__link--active"
                        />
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </>
          ) : (
            <div className="col-md-12">
              <p className="no-result">No Vouchers</p>
            </div>
          )}


          {/* <div className="voucher-card">
                      <div className="voucher-body">
                        <div className="text-center voucher-name">
                          <h4>Voucher Value </h4>
                          <h2>
                            {el.partnerId.currency}
                            {' '}
                            {el.voucherValue}
                          </h2>
                        </div>
                        <div className="text-center gift-voucher">
                          <h2>
                            Vocher Code :
                            {' '}
                            {el.voucherCode}
                          </h2>

                          <h5>
                            <h4>Retail Price</h4>
                            {el.partnerId.currency}
                            {' '}
                            {el.price}
                          </h5>
                        </div>
                      </div>
                            </div> */}

        </div>
      </div>
    </MyProfile >
  );
};

export default MyVouchers;
