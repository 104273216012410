/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import React, { useEffect, useState, useContext, useRef } from "react";
import { Tab } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import Wrapper from "../partnerwrapper/wrapper";
import DailySales from "./dailysales";
import RevenueAppointment from "./appointment";
import RevenueInvoice from "./invoice";
import RevenueVoucher from "./voucher";
import RevenueCommission from "./commission";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";
import "./revenue.css";

const Revenue = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [ds, setD] = useState(false);
  const [ap, setAp] = useState(false);
  const [inv, setIn] = useState(false);
  const [vou, setVou] = useState(false);
  const [com, setCom] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [allVouchers, setVouchers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [totalServices, setTotalServices] = useState(0);
  const [totalAllServicess, setTotalAllServices] = useState(0);
  const [totalNoOfServicess, setTotalNoServices] = useState(0);
  const [totalRefundServices, setTotalRefundServices] = useState(0);
  const [totalPaymnentCollected, setTotalPaymentCollected] = useState(0);
  const [totalRefundValues, setTotalRefundValues] = useState(0);
  const [totalVouchers, setTotalVouchers] = useState(0);
  const [totalAllVouchers, setTotalAllVouchers] = useState(0);
  const [totalNoOfVouchers, setTotalNoVouchers] = useState(0);
  const [paymentListing, setPaymentListing] = useState([]);
  const [totalRefundVouchers, setTotalRefundVouchers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allReddemAmount, setAllRedeemAmount] = useState(0);
  const [crrentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const CalculateDailtSales = (partnerData) => {
    const arr = [];
    const voucherArr = [];
    let totalPayment = 0;
    let reddemAmts = 0;
    partnerData.voucher.forEach((el) => {
      el.voucherBuyByCustomer.forEach((item) => {
        voucherArr.push({
          create: item.invoiceId ? item.invoiceId.updatedAt : " ",
          total: item.price,
          refund: item.invoiceId ? item.invoiceId.refund : false,
          redeem: item.isReedemed,
          value: item.voucherValue,
          paymentMethod: item.paymentMethod,
        });
      });
    });
    setVouchers(voucherArr);
    const filterVDailySales = voucherArr.filter(
      (el) => moment(el.create).format("YYYY-MM-DD") === crrentDate
    );
    const filterVRefundValues = filterVDailySales.filter((item) => item.refund);
    const filterRedValues = filterVDailySales.filter((red) => red.redeem);
    filterRedValues.forEach((de) => {
      setAllRedeemAmount((reddemAmts += de.value));
    });
    partnerData.appointmentId.forEach((el) => {
      el.appointmentPanel.forEach((item) => {
        const amt = item.serviceId.specialPrice
          ? item.serviceId.specialPrice
          : item.serviceId.priceFrom;
        arr.push({
          client: el.customerId.firstname,
          service: item.serviceId.serviceName,
          ref: el.appointmentReference,
          id: el._id,
          date: el.appointmentDate,
          create: el.invoiceId ? el.invoiceId.updatedAt : "",
          time: item.startTime,
          duration: item.duration,
          staff: item.staffId.firstname,
          price: item.disGone
            ? el.discountType === "%"
              ? parseInt(amt) - parseInt(amt) * (parseInt(el.disGone) / 100)
              : parseInt(amt) - parseInt(item.disGone)
            : parseInt(amt),
          location: partnerData.partnerLocation[0].locationName,
          appointmentStatus: el.appointmentStatus
            ? el.appointmentStatus
            : "hello",
          invoice: el.invoiceId ? el.invoiceId.refund : false,
          totalAmount: el.totalAmount,
          paymentMethod: el.paymentMethod,
        });
        setAppointments(arr);
      });
    });
    const filterDailySales = arr.filter(
      (el) => moment(el.create).format("YYYY-MM-DD") === crrentDate
    );
    const filterRefundValues = filterDailySales.filter((item) => item.invoice);
    let totalServicess = 0;
    let totalAllServices = 0;
    let totalRefund = 0;
    let totalVoucher = 0;
    filterDailySales.forEach(
      (ele, i) => (
        (totalServicess += +ele.price),
        (totalAllServices = totalServicess),
        (totalPayment += +ele.totalAmount),
        partnerData.paymentType.forEach((go, k) => {
          if (go.paymentname === ele.paymentMethod) {
            go.paymentname, (go.totalCollected += +ele.price);
          }
        })
      )
    );
    filterRefundValues.forEach(
      (el, i) => (
        (totalServicess -= +el.price),
        (totalAllServices = totalServicess),
        (totalRefund += +el.price),
        partnerData.paymentType.forEach((go, k) => {
          if (go.paymentname === el.paymentMethod) {
            go.paymentname, (go.totalRefunded += +el.price);
          }
        })
      )
    );
    filterVDailySales.forEach(
      (item, i) => (
        (totalVoucher += item.total),
        (totalAllServices = totalVoucher),
        (totalPayment += item.total),
        partnerData.paymentType.forEach((go, k) => {
          if (go.paymentname === item.paymentMethod) {
            go.paymentname, (go.totalCollected += item.total);
          }
        })
      )
    );

    filterVRefundValues.forEach(
      (elem, i) => (
        (totalVoucher -= elem.total),
        (totalAllServices = totalVoucher),
        (totalRefund += elem.total),
        partnerData.paymentType.forEach((go, k) => {
          if (go.paymentname === elem.paymentMethod) {
            go.paymentname, (go.totalRefunded += elem.total);
          }
        })
      )
    );
    setTotalServices(totalServicess);
    setTotalVouchers(totalVoucher);
    setTotalAllServices(totalAllServices);
    setTotalNoServices(filterDailySales.length);
    setTotalRefundServices(filterRefundValues.length);
    setTotalNoVouchers(filterVDailySales.length);
    setTotalRefundVouchers(filterVRefundValues.length);
    setTotalPaymentCollected(totalPayment);
    setTotalRefundValues(totalRefund);
    setPaymentListing(partnerData.paymentType);
  };

  useEffect(() => {
    const getRevenue = async () => {
      setLoading(true);
      const response = await partnerInstance().get("/revenue");

      const { code, partnerData, InvoiceData } = response.data;
      if (code === 200) {
        CalculateDailtSales(partnerData);
        setInvoices(InvoiceData);
        setLoading(false);
      }
    };

    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Point of Sale"
        );
      if (findBook > -1) {
        getRevenue();
        const fndWork =
          sections &&
          sections.findIndex((el) => el.sectionname === "Daily Sales");
        if (fndWork > -1) {
          setD(true);
        }
        const fndPer =
          sections &&
          sections.findIndex((el) => el.sectionname === "Appointments");
        if (fndPer > -1) {
          setAp(true);
        }
        const setSta =
          sections && sections.findIndex((el) => el.sectionname === "Invoices");
        if (setSta > -1) {
          setIn(true);
        }
        const setH =
          sections && sections.findIndex((el) => el.sectionname === "Vouchers");
        if (setH > -1) {
          setVou(true);
        }
        const setC =
          sections &&
          sections.findIndex((el) => el.sectionname === "Commission");
        if (setC > -1) {
          setCom(true);
        }
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Wrapper heading="Revenue">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="revenue staff-page">
        <div className="container">
          {/* <h2 className="admin-title">Revenue</h2> */}
          <div className="row">
            <div className="col-md-12">
              <Tabs defaultActiveKey="0" id="uncontrolled-tab-example">
                {ds && (
                  <Tab eventKey="0" title="Daily Sales">
                    <DailySales
                      appointments={appointments}
                      totalServicess={totalServices}
                      totalAllServices={totalAllServicess}
                      totalNoOfServices={totalNoOfServicess}
                      totalRefundServices={totalRefundServices}
                      totalPaymnentCollected={totalPaymnentCollected}
                      totalRefundValues={totalRefundValues}
                      totalVouchers={totalVouchers}
                      totalAllVouchers={totalAllVouchers}
                      totalNoOfVouchers={totalNoOfVouchers}
                      totalRefundVouchers={totalRefundVouchers}
                      paymentListing={paymentListing}
                      allVouchers={allVouchers}
                      allReddemAmount={allReddemAmount}
                    />
                  </Tab>
                )}
                {ap && (
                  <Tab eventKey="1" title="Appointments">
                    <RevenueAppointment appointments={appointments} />
                  </Tab>
                )}
                {inv && (
                  <Tab eventKey="2" title="Invoices">
                    <RevenueInvoice invoices={invoices} />
                  </Tab>
                )}
                {vou && (
                  <Tab eventKey="3" title="Vouchers">
                    <RevenueVoucher />
                  </Tab>
                )}

                <Tab eventKey="4" title="Commission">
                  <RevenueCommission />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Revenue;
