import React, { useState, useEffect, useCallback, useRef } from "react";
import { Accordion, Card, Button, Modal, Form } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ReactStars from "react-stars";
import { toast } from "react-toastify";
import MyProfile from "./myprofile";
import appointments from "../../assets/venue4.jpg";
import Loader from "../loader/loader";
import { userInstance } from "../../config/axios";
import { server } from "../../config/keys";

const { google } = window;
const MyAppointments = ({ username, userImage }) => {
  localStorage.removeItem("Reschedule");

  const [upcomingAppointment, setUpcomingAppointment] = useState([]);
  const [pastAppointment, setPastAppointment] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  //const [showw, setShow3] = useState(false);
  const [appId, setAppId] = useState("");
  const [index, setIndex] = useState(0);
  const [selectServices, setSelectServices] = useState([]);
  const [sameCategory, setSameCategory] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState(null);

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  //const handleClose3 = () => setShow3(false);

  const handleShow = (id, index) => {
    setAppId(id);
    setIndex(index);
    setShow(true);
  };

  const handleShow2 = (id, index) => {
    setAppId(id);
    setIndex(index);
    setShow2(true);
  };

  const direction = (info, k) => {
    const oldState = [...upcomingAppointment];
    setLocationData(info.partnerId.partnerLocation);
    if (!oldState[k].showw) {
      oldState[k].showw = true;
      setUpcomingAppointment(oldState);
    } else {
      oldState[k].showw = false;
      setUpcomingAppointment(oldState);
    }
  };

  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();
  const upcomingAppointments = async () => {
    // setLoading(true);
    const response = await userInstance().get("/getAppointments");

    const { appointmentId } = response.data.appointments;
    const upcomingFilter = appointmentId.filter(
      (el) =>
        moment(
          `${el.appointmentDate} ${el.appointmentPanel[0].startTime}`
        ).toDate() >= moment(new Date()).toDate()
    );
    const pastAppointments = appointmentId.filter(
      (item) =>
        moment(
          `${item.appointmentDate} ${item.appointmentPanel[0].startTime}`
        ).toDate() <= moment(new Date()).toDate()
    );
    setUpcomingAppointment(upcomingFilter);
    setPastAppointment(pastAppointments);
    update();
    // setLoading(false);
  };

  const cancel = async () => {
    const payload = {
      appId,
    };

    setShow(false);
    const response = await userInstance().post("/cancelAppointment", payload);
    if (response.data.code === 200) {
      const oldState = [...upcomingAppointment];
      oldState[index].appointmentStatus = "Canceled";
      oldState[index].cancelStatus = true;
      setUpcomingAppointment(oldState);
      const oldStates = [...pastAppointment];
      oldStates[index].appointmentStatus = "Canceled";
      oldState[index].cancelStatus = true;
      setPastAppointment(oldStates);
      getStatus("Canceled");
    }
  };

  const Services = (data) => {
    const arr = [];
    const servicesCopy = [...selectServices];
    data.appointmentPanel.forEach((el) => {
      arr.push({
        servicename: el.serviceId.serviceName,
        amount: el.serviceId.priceFrom,
        duration: el.duration,
        id: el.serviceId._id,
        categoryid: el.categoryId,
      });
    });
    setSelectServices(arr);
    const allEqual = servicesCopy.every(
      (v) => v.categoryid === servicesCopy[0].categoryid
    );
    setSameCategory(allEqual);
  };

  useEffect(() => {
    upcomingAppointments();
    // eslint-disable-next-line
  }, []);

  const setData = (AppId) => {
    localStorage.setItem("Reschedule", true);
    localStorage.setItem("AppointmentId", AppId);
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getStatus = (data) => {
    if (data === "Canceled") {
      return (
        <div className="error-badge">
          <i className="fa fa-ban" aria-hidden="true" />
          Cancelled
        </div>
      );
    }
    if (data === "Confirmed") {
      return (
        <div className="success-badge">
          <i className="fa fa-check-square-o" aria-hidden="true" />
          Confirmed
        </div>
      );
    }
    if (data === "Started") {
      return (
        <div className="arrive-badge">
          <i className="fa fa-check-square-o" aria-hidden="true" />
          Started
        </div>
      );
    }
    if (data === "Completed") {
      return (
        <div className="success-badge">
          <i className="fa fa-check-square-o" aria-hidden="true" />
          Completed
        </div>
      );
    }
    if (data === "Arrived") {
      return (
        <div className="arrive-badge">
          <i className="fa fa-check-square-o" aria-hidden="true" />
          Arrived
        </div>
      );
    }
  };

  if (loading) {
    return (
      <MyProfile username={username} userImage={userImage}>
        <Loader />
      </MyProfile>
    );
  }

  return (
    <MyProfile username={username} userImage={userImage}>
      {/* {loading && (
      <Loader />
      )} */}
      <div className="my-appointments add-white-bg new-my-appointments">
        <Tabs defaultActiveKey="upcoming" id="uncontrolled-tab-example">
          <Tab eventKey="upcoming" title="Upcoming Appointments">
            <div className="upcomingappointments">
              {/* <h3>Upcoming Appointments</h3> */}
              <div className="appointment-scroll">
                {upcomingAppointment.length > 0 ? (
                  <>
                    {upcomingAppointment &&
                      upcomingAppointment.map((el, i) => (
                        <div className="upcoming-appointments">
                          <Accordion>
                            <Card>
                              <Card.Header>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                  onClick={() => Services(el)}
                                >
                                  <div className="appointment-section">
                                    <div className="appointment-img">
                                      <div className="appoint">
                                        <img
                                          src={
                                            el.partnerId.photo
                                              ? `${server}/${el.partnerId.photo}`
                                              : appointments
                                          }
                                          alt="fh"
                                        />
                                        <div className="appointment-name">
                                          <h6>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .locationName
                                            }
                                          </h6>
                                          <p>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .addressFormat
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      <div className="appointment-time">
                                        <h6>
                                          <i
                                            className="fa fa-clock-o"
                                            aria-hidden="true"
                                          />
                                          {el.appointmentDate ===
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "Today"
                                            : el.appointmentDate ===
                                              moment(new Date())
                                                .add(1, "day")
                                                .format("YYYY-MM-DD")
                                            ? "Tommorrow"
                                            : days[
                                                new Date(
                                                  el.appointmentDate
                                                ).getDay()
                                              ]}{" "}
                                          {el.appointmentPanel[0].startTime}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <div className="appointments-collapse">
                                    <div className="appointments-date">
                                      <h6>
                                        {el.AppointmentDate ===
                                        moment(new Date()).format("YYYY-MM-DD")
                                          ? "Today"
                                          : el.appointmentDate ===
                                            moment(new Date())
                                              .add(1, "day")
                                              .format("YYYY-MM-DD")
                                          ? "Tommorrow"
                                          : days[
                                              new Date(
                                                el.appointmentDate
                                              ).getDay()
                                            ]}{" "}
                                        {moment(el.appointmentDate).format(
                                          "DD MMM YYYY"
                                        )}{" "}
                                        at {el.appointmentPanel[0].startTime}
                                      </h6>
                                      {getStatus(el.appointmentStatus)}
                                    </div>
                                    <div className="appointment-img">
                                      <div className="appoint">
                                        <img
                                          src={
                                            el.partnerId.photo
                                              ? `${server}/${el.partnerId.photo}`
                                              : appointments
                                          }
                                          alt="fh"
                                        />
                                        <div className="appointment-name">
                                          <h6>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .locationName
                                            }
                                          </h6>
                                          <p>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .addressFormat
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      <div className="appointment-schedule">
                                        {!el.cancelStatus &&
                                        el.appointmentStatus !== "Completed" ? (
                                          <>
                                            <div
                                              role="presentation"
                                              className="direction"
                                            >
                                              <i
                                                className="fa fa-map-marker"
                                                aria-hidden="true"
                                                onClick={() => direction(el, i)}
                                              />
                                              <h6>Direction</h6>
                                            </div>
                                            <div className="reschedule">
                                              <Link
                                                to={{
                                                  pathname: `/customerappointment/selectpreference/${el.partnerId._id}`,
                                                  state: {
                                                    servicesPanel:
                                                      selectServices,
                                                    staffs: el.partnerId.staff,
                                                    partnerTime:
                                                      el.partnerId
                                                        .partnerLocation[0],
                                                    chooseStaff: sameCategory,
                                                  },
                                                }}
                                              >
                                                <i
                                                  className="fa fa-calendar-check-o"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    setData(el._id)
                                                  }
                                                />
                                                <h6>Reschedule</h6>
                                              </Link>
                                            </div>
                                            <div className="cancel">
                                              <i
                                                className="fa fa-times-circle-o"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  handleShow(el._id, i)
                                                }
                                              />
                                              <h6>Cancel</h6>
                                              <Modal
                                                show={show}
                                                onHide={handleClose}
                                                className="appointment-popup"
                                              >
                                                <Modal.Body>
                                                  <div className="close-modal">
                                                    <i
                                                      className="fa fa-times-circle"
                                                      aria-hidden="true"
                                                      onClick={handleClose}
                                                    />
                                                  </div>
                                                  <div className="appointment-modal">
                                                    <h6>
                                                      {" "}
                                                      Are you sure you want to
                                                      cancel this Appointment ?
                                                    </h6>
                                                    <div className="action-btn">
                                                      <Button
                                                        variant="secondary"
                                                        onClick={handleClose}
                                                        className="btn-perple"
                                                      >
                                                        No
                                                      </Button>
                                                      <Button
                                                        variant="primary"
                                                        onClick={() => cancel()}
                                                        className="btn-perple"
                                                      >
                                                        Yes
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </Modal.Body>
                                              </Modal>
                                              {/* <Modal show={showw} onHide={handleClose3} className="appointment-popup">
                                      <Modal.Body>
                                        <div className="close-modal">
                                          <i className="fa fa-times-circle" aria-hidden="true" onClick={handleClose3} />
                                        </div>
                                        <DirectionSection locationData={locationData} />
                                      </Modal.Body>
                                    </Modal> */}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="reschedule">
                                              <Link
                                                to={{
                                                  pathname: `/customerappointment/selectservices/${el.partnerId._id}`,
                                                }}
                                              >
                                                <i
                                                  className="fa fa-calendar-check-o"
                                                  aria-hidden="true"
                                                />
                                                <h6>Rebook</h6>
                                              </Link>
                                            </div>

                                            {el.appointmentStatus ===
                                              "Completed" && (
                                              <>
                                                {el.reviewComplete ? (
                                                  <div className="reschedule">
                                                    <i
                                                      className="fa fa-calendar-check-o"
                                                      aria-hidden="true"
                                                    />
                                                    <h6>
                                                      Review
                                                      <br /> Completed
                                                    </h6>
                                                  </div>
                                                ) : (
                                                  <div className="reschedule">
                                                    <i
                                                      className="fa fa-calendar-check-o"
                                                      aria-hidden="true"
                                                      onClick={() =>
                                                        handleShow2(el._id, i)
                                                      }
                                                    />
                                                    <h6>Review</h6>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <Modal
                                        show={show2}
                                        onHide={handleClose2}
                                        className="appointment-popup"
                                      >
                                        <Modal.Body>
                                          <div className="close-modal">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                              onClick={handleClose2}
                                            />
                                          </div>
                                          <ReviewSection
                                            appId={appId}
                                            setShow2={setShow2}
                                            upcomingAppointments={
                                              upcomingAppointments
                                            }
                                          />
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                    {el.bookingNote && (
                                      <div
                                        className="appointment-name"
                                        style={{ marginLeft: "0px" }}
                                      >
                                        <h6>Appointment Note</h6>
                                        <p>{el.bookingNote}</p>
                                      </div>
                                    )}
                                    {el.outServiceAddress && (
                                      <div
                                        className="appointment-name"
                                        style={{ marginLeft: "0px" }}
                                      >
                                        <h6>OutService Address</h6>
                                        <p>{el.outServiceAddress}</p>
                                      </div>
                                    )}
                                    <div>
                                      {el.showw && (
                                        <DirectionSection
                                          locationData={locationData}
                                        />
                                      )}
                                    </div>
                                    <div className="appointment-bottom">
                                      {el.appointmentPanel.map((wave) => (
                                        <>
                                          <div className="appoint-info">
                                            <div className="info-user">
                                              <h5>
                                                {wave.serviceId.serviceName}
                                              </h5>
                                              <span>
                                                {Math.floor(
                                                  parseInt(wave.duration) / 60
                                                ) === 0 ? (
                                                  <>
                                                    {parseInt(wave.duration) %
                                                      60}{" "}
                                                    min
                                                  </>
                                                ) : (
                                                  <>
                                                    {Math.floor(
                                                      parseInt(wave.duration) /
                                                        60
                                                    )}
                                                    h{" "}
                                                    {parseInt(wave.duration) %
                                                      60 ===
                                                    0 ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        ,
                                                        {parseInt(
                                                          wave.duration
                                                        ) % 60}
                                                        min
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                {} with {wave.staffId.firstname}
                                              </span>
                                            </div>
                                            <div className="user-price">
                                              <h6>
                                                {el.partnerId.currency}{" "}
                                                {wave.specialPackagePrice !==
                                                null
                                                  ? wave.specialPackagePrice
                                                  : wave.serviceId.specialPrice
                                                  ? wave.serviceId.specialPrice
                                                  : wave.serviceId.priceFrom}
                                              </h6>
                                            </div>
                                          </div>
                                        </>
                                      ))}

                                      <div className="totalprice">
                                        <h6>Total</h6>
                                        <h5>
                                          {el.partnerId.currency}{" "}
                                          {el.totalAmount}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </div>
                      ))}
                  </>
                ) : (
                  <p className="no-result">
                    No Upcoming Appointments ,
                    <Link className="book-now-link">Book Now!</Link>
                  </p>
                )}
              </div>
            </div>
          </Tab>

          <Tab eventKey="past" title="Past Appointments">
            <div className="pastappointments">
              {/* <h3>Past Appointments</h3> */}
              <div className="appointment-scroll">
                {pastAppointment.length > 0 ? (
                  <>
                    {pastAppointment &&
                      pastAppointment.map((el, i) => (
                        <div className="upcoming-appointments">
                          <Accordion>
                            <Card>
                              <Card.Header>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                >
                                  <div className="appointment-section">
                                    <div className="appointment-img">
                                      <div className="appoint">
                                        <img
                                          src={
                                            el.partnerId.photo
                                              ? `${server}/${el.partnerId.photo}`
                                              : appointments
                                          }
                                          alt="fh"
                                        />
                                        <div className="appointment-name">
                                          <h6>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .locationName
                                            }
                                          </h6>
                                          <p>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .addressFormat
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      <div className="appointment-time">
                                        <h6>
                                          <i
                                            className="fa fa-clock-o"
                                            aria-hidden="true"
                                          />
                                          {el.appointmentDate ===
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                            ? "Today"
                                            : el.appointmentDate ===
                                              moment(new Date())
                                                .add(1, "day")
                                                .format("YYYY-MM-DD")
                                            ? "Tommarow"
                                            : days[
                                                new Date(
                                                  el.appointmentDate
                                                ).getDay()
                                              ]}{" "}
                                          {el.appointmentPanel[0].startTime}
                                        </h6>
                                      </div>
                                    </div>
                                    {el.bookingNote && (
                                      <div
                                        className="appointment-name"
                                        style={{ marginLeft: "0px" }}
                                      >
                                        <h6>Appointment Note</h6>
                                        <p>{el.bookingNote}</p>
                                      </div>
                                    )}
                                    {el.outServiceAddress && (
                                      <div
                                        className="appointment-name"
                                        style={{ marginLeft: "0px" }}
                                      >
                                        <h6>OutService Address</h6>
                                        <p>{el.outServiceAddress}</p>
                                      </div>
                                    )}
                                  </div>
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <div className="appointments-collapse">
                                    <div className="appointments-date">
                                      <h6>
                                        {el.appointmentDate ===
                                        moment(new Date()).format("YYYY-MM-DD")
                                          ? "Today"
                                          : el.appointmentDate ===
                                            moment(new Date())
                                              .add(1, "day")
                                              .format("YYYY-MM-DD")
                                          ? "Tommarow"
                                          : days[
                                              new Date(
                                                el.appointmentDate
                                              ).getDay()
                                            ]}{" "}
                                        {moment(el.appointmentDate).format(
                                          "DD MMM YYYY"
                                        )}{" "}
                                        at {el.appointmentPanel[0].startTime}
                                      </h6>
                                      {getStatus(el.appointmentStatus)}
                                    </div>
                                    <div className="appointment-img">
                                      <div className="appoint">
                                        <img
                                          src={
                                            el.partnerId.photo
                                              ? `${server}/${el.partnerId.photo}`
                                              : appointments
                                          }
                                          alt="fh"
                                        />
                                        <div className="appointment-name">
                                          <h6>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .locationName
                                            }
                                          </h6>
                                          <p>
                                            {
                                              el.partnerId.partnerLocation[0]
                                                .addressFormat
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      <div className="appointment-schedule">
                                        <div className="reschedule">
                                          <Link
                                            to={{
                                              pathname: `/customerappointment/selectservices/${el.partnerId._id}`,
                                            }}
                                          >
                                            <i
                                              className="fa fa-calendar-check-o"
                                              aria-hidden="true"
                                            />
                                            <h6>Rebook</h6>
                                          </Link>
                                        </div>
                                        {el.appointmentStatus ===
                                          "Completed" && (
                                          <>
                                            {el.reviewComplete ? (
                                              <div className="reschedule">
                                                <i
                                                  className="fa fa-calendar-check-o"
                                                  aria-hidden="true"
                                                />
                                                <h6>
                                                  Review
                                                  <br /> Completed
                                                </h6>
                                              </div>
                                            ) : (
                                              <div className="reschedule">
                                                <i
                                                  className="fa fa-calendar-check-o"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    handleShow2(el._id, i)
                                                  }
                                                />
                                                <h6>Review</h6>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <Modal
                                        show={show2}
                                        onHide={handleClose2}
                                        className="appointment-popup"
                                      >
                                        <Modal.Body>
                                          <div className="close-modal">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                              onClick={handleClose2}
                                            />
                                          </div>
                                          <ReviewSection
                                            appId={appId}
                                            setShow2={setShow2}
                                            upcomingAppointments={
                                              upcomingAppointments
                                            }
                                          />
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                    <div className="appointment-bottom">
                                      {el.appointmentPanel.map((wave) => (
                                        <>
                                          <div className="appoint-info">
                                            <div className="info-user">
                                              <h5>
                                                {wave.serviceId.serviceName}
                                              </h5>
                                              <span>
                                                {Math.floor(
                                                  parseInt(wave.duration) / 60
                                                ) === 0 ? (
                                                  <>
                                                    {parseInt(wave.duration) %
                                                      60}{" "}
                                                    min
                                                  </>
                                                ) : (
                                                  <>
                                                    {Math.floor(
                                                      parseInt(wave.duration) /
                                                        60
                                                    )}
                                                    h{" "}
                                                    {parseInt(wave.duration) %
                                                      60 ===
                                                    0 ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        ,
                                                        {parseInt(
                                                          wave.duration
                                                        ) % 60}
                                                        min
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                {} with {wave.staffId.firstname}
                                              </span>
                                            </div>
                                            <div className="user-price">
                                              <h6>
                                                {el.partnerId.currency}{" "}
                                                {wave.specialPackagePrice !==
                                                null
                                                  ? wave.specialPackagePrice
                                                  : wave.serviceId.specialPrice
                                                  ? wave.serviceId.specialPrice
                                                  : wave.serviceId.priceFrom}
                                              </h6>
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                      <div className="totalprice">
                                        <h6>Total</h6>
                                        <h5>
                                          {el.partnerId.currency}{" "}
                                          {el.totalAmount}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </div>
                      ))}
                  </>
                ) : (
                  <p className="no-result">
                    No Past Appointments ,
                    <Link className="book-now-link">Book now</Link>
                  </p>
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </MyProfile>
  );
};
export default MyAppointments;

const ReviewSection = ({ appId, setShow2, upcomingAppointments }) => {
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validateReview = (values) => {
    const errorss = {};
    let isValid = true;
    if (!values.rating) {
      errorss.rating = "Rating is required";
      isValid = false;
    }
    if (!values.message) {
      errorss.message = "Message is required";
      isValid = false;
    }

    return { isValid, errorss };
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const addReview = async (e) => {
    e.preventDefault();
    const validation = validateReview({
      rating,
      message,
    });
    setErrors(validation.errorss);
    if (!validation.isValid) {
      return;
    }
    const payload = {
      id: appId,
      rating,
      message,
    };

    const revieResponse = await userInstance().post("/addReviews", payload);

    const { code, msg } = revieResponse.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      upcomingAppointments();
      setShow2(false);
    }
  };

  return (
    <div className="store-review">
      <div className="row">
        <div className="col-md-12">
          <div className="opening-hours">
            <h3>
              <i className="fa fa-envelope" /> Add Review
            </h3>
            <Form onSubmit={addReview}>
              <div className="row">
                <div className="col-md-12">
                  <div className="utf_leave_rating margin-bottom-30">
                    <ReactStars
                      count={5}
                      value={rating}
                      onChange={ratingChanged}
                      size={25}
                      color2="#ffd700"
                    />
                    <span>Rating: </span>
                  </div>
                </div>
                {errors.rating && <p className="error">{errors.rating}</p>}

                <div className="col-md-12">
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder="Your Message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    autoComplete="off"
                  />
                  {errors.message && <p className="error">{errors.message}</p>}
                </div>
              </div>
              <Button type="submit">Submit Review</Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const DirectionSection = ({ locationData }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    Map();
    // eslint-disable-next-line
  }, []);
  const Map = useCallback(async () => {
    const { lat } = locationData[0];
    const { lng } = locationData[0];
    try {
      const map = new google.maps.Map(mapRef.current, {
        zoom: 15,
        center: {
          lat,
          lng,
        },
      });

      new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map,
      });
    } catch (e) {
      console.log("error", e);
    }
    // eslint-disable-next-line
  }, [mapRef]);

  return <div id="map_canvas" style={{ height: 300 }} ref={mapRef} />;
};
