import React, { useState, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
//import getSymbolFromCurrency from "currency-symbol-map";
import queryString from "query-string";
import history from "./config/history";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Login from "./components/login/login";
import UserProfile from "./components/login/userProfile.";
import Register from "./components/register/register";
import Forget from "./components/forget/forget";
import StoreListing from "./components/store/strorelisting";
import StoreDetail from "./components/store/storedetail";
import PartnerLogin from "./components/partners/login/login";
import PartnerRegister from "./components/partners/register/register";
import PartnerForget from "./components/partners/forget/forget";
import AddStaff from "./components/partners/partnerstaff/addstaff";
import EditStaff from "./components/partners/partnerstaff/editstaff";
import SetUp from "./components/partners/setup/setup";
import AccountSetting from "./components/partners/setup/accountsetting";
import CancellationReason from "./components/partners/setup/cancel-reasons";
import Invoice from "./components/partners/setup/invoice";
import InvoiceSequencing from "./components/partners/setup/invoicesequencing";
import Taxes from "./components/partners/setup/taxes";
import PaymentTaxes from "./components/partners/setup/paymenttaxes";
import DiscountType from "./components/partners/setup/discounttype";
import LocationList from "./components/partners/setup/location";
import LocationDetail from "./components/partners/setup/locationdetail";
import EditLocation from "./components/partners/setup/editlocation";
import PartnerDashboard from "./components/partners/partnerdashboard/dashboard";
import PartnerServices from "./components/partners/partnerservices/services";
import AddServices from "./components/partners/partnerservices/addservice";
import EditService from "./components/partners/partnerservices/editservice";
import EditPackage from "./components/partners/partnerservices/editpackage";
import SingleServices from "./components/partners/partnerservices/singleservice";
import PackageServices from "./components/partners/partnerservices/package";
import PartnerStaff from "./components/partners/partnerstaff/staff";
import PartnerVoucher from "./components/partners/voucher/voucher";
import AddVoucher from "./components/partners/voucher/addvoucher";
import EditVoucher from "./components/partners/voucher/editvoucher";
import Referral from "./components/partners/setup/referral";
import Customer from "./components/partners/customer/customer";
import Appointment from "./components/partners/appointment/appointment";
import AddCustomer from "./components/partners/customer/addcustomer";
import EditCustomer from "./components/partners/customer/editcustomer";
import CustomerProfile from "./components/partners/customer/customerprofile";
import CalendarTwo from "./components/partners/appointment/calendar";
import NewAppointment from "./components/partners/appointment/addappointment";
import EditAppointment from "./components/partners/appointment/editappointment";
import ViewAppointmentInvoice from "./components/partners/appointment/viewinvoice";
import AppointmentCheckout from "./components/partners/appointment/checkout";
import ViewAppointment from "./components/partners/appointment/viewappointment";
import { UserProvider } from "./context/context";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { userInstance, partnerInstance, adminInstance } from "./config/axios";
import SelectServices from "./components/partners/customerappointment/selectservices";
import SelectTime from "./components/partners/customerappointment/selecttime";
import SelectPreference from "./components/partners/customerappointment/selectpreferences";
import VoucherCheckout from "./components/partners/voucher/vouchercheckout";
import VoucherViewInvoice from "./components/partners/voucher/voucherviewinvoice";
import PaymentDetail from "./components/partners/customerappointment/paymentdetail";
import Wishlist from "./components/store/wishlist";
import Revenue from "./components/partners/revenue/revenue";
import MyAppointments from "./components/myprofile/myappointments";
import MyVouchers from "./components/myprofile/myvouchers";
import MyFavorites from "./components/myprofile/myfavorites";
import EditProfile from "./components/myprofile/editprofile";
import Success from "./components/partners/customerappointment/success";
import TestCalender from "./components/partners/appointment/testcalender";
import ErrorPage from "./components/store/404";
import ErrorMsg from "./components/store/403";
import PartnerProfile from "./components/partners/partnerprofile/partnerprofile";
import Verify from "./components/verify/verify";
import { socket } from "./socket";
import AdminDashboard from "./components/admin/admindashboard/dashboard";
import AdminPricing from "./components/admin/admindashboard/adminpricing";
import AdminAppointments from "./components/admin/admindashboard/appointmnents";
import PartnerCommission from "./components/admin/admindashboard/partnercommission";
import AdminLogin from "./components/admin/login/login";
import AdminForget from "./components/admin/login/forget";
import AdminPartner from "./components/admin/partners/partner";
import AdminCustomer from "./components/admin/customer/customer";
import AccountBalance from "./components/admin/admintransactions/admintrasactions";
import MonthlyTransaction from "./components/admin/admintransactions/monthlyTransactions";
import AdminCron from "./components/admin/admintransactions/cron";
import Settings from "./components/admin/admintransactions/settings";
import MonthListing from "./components/admin/admintransactions/monthListing";
import Payout from "./components/admin/admintransactions/payout";
import AdminBusinessTypes from "./components/admin/client/client";
import VoucherDetail from "./components/voucher/voucherQuantity";
import GiftVoucher from "./components/voucher/giftVoucher";
import Paymentvoucher from "./components/voucher/paymentVoucher";
import PrintGift from "./components/voucher/printgift";
import EmailForm from "./components/voucher/email";
import Connect from "./components/partners/setup/connect";
import PricingPackage from "./components/partners/setup/pricingPackege/pricingPackage";
import Details from "./components/partners/setup/pricingPackege/details";
import OnlineListing from "./components/partners/setup/onlinelisting";
import CheckAvailablity from "./components/partners/setup/pricingPackege/checkAvailability";
import VoucherSuccess from "./components/voucher/signleVocuher";
import OnlineBooking from "./components/partners/setup/pricingPackege/onlineBooking";
import SignupMain from "./components/partners/partnersignup/signupmain";
import SignupStep1 from "./components/partners/partnersignup/signupstep1";
import SignupStep2 from "./components/partners/partnersignup/signupstep2";
import SignupStep3 from "./components/partners/partnersignup/signupstep3";
import SignupStep4 from "./components/partners/partnersignup/signupstep4";
import "./responsive.css";
import SignupStep5 from "./components/partners/partnersignup/signupstep5";
import SignupStep6 from "./components/partners/partnersignup/signupstep6";
import SignupStep7 from "./components/partners/partnersignup/signupstep7";
import SignupStep8 from "./components/partners/partnersignup/signupstep8";
import Bussiness from "./components/partners/setup/configurepages/bussiness";
import SignupInvoice from "./components/partners/partnersignup/signupinvoice";
import OnlinePayment from "./components/partners/partnersignup/online-payment";
import SignupOnlineBooking from "./components/partners/partnersignup/onlineBooking";
import SignUpCardDetails from "./components/partners/partnersignup/onlinelisting";

const App = () => {
  const [username, setUsername] = useState({});
  const [name, setName] = useState();
  //const [partnerId, setPartnerId] = useState("");
  const [userImage, setUserImage] = useState("");
  // eslint-disable-next-line
  const [currency, setCurrency] = useState("");
  // eslint-disable-next-line
  const [weekStart, setWeekStart] = useState("");
  //const [closedDate, setClosedDate] = useState([]);
  const [userNotifications, setUserNotifications] = useState([]);
  const [isStepComplete, setIsStepComplete] = useState("");

  const [isLogin, setIsLogin] = useState(localStorage.getItem("usertoken"));
  const [isPartnerLogin, setIsPartnerLogin] = useState(
    localStorage.getItem("partnertoken")
  );
  const PartnerLogOut = () => {
    localStorage.removeItem("partnertoken");
    localStorage.removeItem("authtoken");
    localStorage.removeItem("userid");
    setIsPartnerLogin(null);
    history.push("/partners/login");
  };
  const user = {
    username,
    currency,
    isPartnerLogin,
    PartnerLogOut,
    weekStart,
    setIsPartnerLogin,
    setUsername,
    isStepComplete,
    setIsStepComplete,
  };
  console.log('PP',isStepComplete);
  const LogOut = () => {
    localStorage.removeItem("usertoken");
    setIsLogin(null);
  };

  const getPartnerData = async () => {
    const partnerdata = await partnerInstance().get("/validateToken");
    
    const {
      code,
      userData,
      _id,
      currency,
      //permissionLevel,
      allsections,
      notifications,
      onlinePayment,
      isStepComplete,
    } = partnerdata.data;
    if (code === 200) {
      // const currencysymbol = await getSymbolFromCurrency(currency);
      socket.emit("join", _id);
      const data = {
        name: userData.firstname,
        image: userData.image,
        sections: allsections,
        notifications,
        onlinePayment,
        currency,
        isStepComplete,
      };
      setUsername(data);
      console.log('DDD',data)
      setIsStepComplete(data.isStepComplete)
    }
  };

  const getData = async () => {
    const userdata = await userInstance().get("/validateToken");
    const { code, userData } = userdata.data;
    if (code === 200) {
      socket.emit("join", userData._id);
      setName(userData.firstname);
      setUserImage(userData.image);
      setUserNotifications(userData.notifications);
    }
  };

  const getAdminData = async () => {
    const userdata = await adminInstance().get("/validateTokenAdmin");
    const { code, adminData } = userdata.data;
    if (code === 200) {
      setName(adminData.userName);
    }
  };

  const VerifyRoute = async () => {
    const value = queryString.parse(window.location.search);
    const userid = value.id;
    const { type } = value;
    const payload = { userid };
    if (type === "partner") {
      const data = await partnerInstance().post("/verify", payload);
      const { code, token, authToken } = data.data;
      if (code === 200) {
        localStorage.setItem("partnertoken", token);
        localStorage.setItem("authtoken", authToken);
        localStorage.setItem("userid", userid);
        window.location.href = "/partners/intro";
      }
    } else {
      const data = await userInstance().post("/verify", payload);
      const { code, token, authToken } = data.data;
      if (code === 200) {
        localStorage.setItem("usertoken", token);
        localStorage.setItem("authtoken", authToken);
        localStorage.setItem("userid", userid);
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    VerifyRoute();
    getData();
    getPartnerData();
    getAdminData();
  }, [isPartnerLogin]);

  const RegisterRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("usertoken") ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} {...rest} />
        )
      }
    />
  );

  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("usertoken") ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} {...rest} />
        )
      }
    />
  );

  const PartnerRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("partnertoken") ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/partners/login" />
        )
      }
    />
  );
  const UserRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("usertoken") ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("admintoken") ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  );

  return (
    <div className="App">
      <div className="main-wrapper">
        <>
          <Router history={history}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Header
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              LogOut={LogOut}
              username={name}
              userImage={userImage}
              userNotifications={userNotifications}
            />
            <main>
              <div className="main_content">
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Home />}
                    isLogin={isLogin}
                    setIsLogin={setIsLogin}
                  />
                  <LoginRoute
                    exact
                    path="/login"
                    component={() => <Login setIsLogin={setIsLogin} />}
                  />
                  <UserRoute
                    exact
                    path="/userProfile"
                    component={() => <UserProfile />}
                  />
                  <Route exact path="/success" render={() => <Success />} />
                  <Route
                    exact
                    path="/vouchersucess"
                    render={() => <VoucherSuccess />}
                  />
                  <RegisterRoute
                    exact
                    path="/register"
                    component={() => <Register setIsLogin={setIsLogin} />}
                  />
                  <Route exact path="/forget" render={() => <Forget />} />
                  <UserProvider value={user}>
                    <Switch>
                      <Route
                        exact
                        path="/partners/login"
                        render={() => <PartnerLogin />}
                      />
                      <Route
                        exact
                        path="/partners/register"
                        render={() => <PartnerRegister />}
                      />
                      <Route
                        exact
                        path="/partners/forget"
                        render={() => <PartnerForget />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/dashboard"
                        component={() => <PartnerDashboard />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/services"
                        component={() => <PartnerServices />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/addservices"
                        component={() => <AddServices />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/editservice"
                        component={() => <EditService />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/editpackage"
                        component={() => <EditPackage />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/addsingleservices"
                        component={() => <SingleServices />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/addpackage"
                        component={() => <PackageServices />}
                      />

                      <PartnerRoute
                        exact
                        path="/partners/staff"
                        component={() => <PartnerStaff />}
                      />

                      <PartnerRoute
                        exact
                        path="/partners/addstaff"
                        component={() => <AddStaff />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/editstaff"
                        component={() => <EditStaff />}
                      />

                      <PartnerRoute
                        exact
                        path="/partners/setup"
                        component={() => <SetUp />}
                      />

                      <PartnerRoute
                        exact
                        path="/partners/accountsetting"
                        component={() => <AccountSetting />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/invoice"
                        component={() => <Invoice />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/invoice-sequencing"
                        component={() => <InvoiceSequencing />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/taxes"
                        component={() => <Taxes />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/payment-taxes"
                        component={() => <PaymentTaxes />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/cancel-reason"
                        component={() => <CancellationReason />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/discount-type"
                        component={() => <DiscountType />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/location"
                        component={() => <LocationList />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/locationdetail"
                        component={() => <LocationDetail />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/referral"
                        component={() => <Referral />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/voucher"
                        component={() => <PartnerVoucher />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/editlocation/:id"
                        component={() => <EditLocation />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/addvoucher"
                        component={() => <AddVoucher />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/editvoucher"
                        component={() => <EditVoucher />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/customer"
                        component={() => <Customer />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/addcustomer"
                        component={() => <AddCustomer />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/editcustomer"
                        component={() => <EditCustomer />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/customerprofile"
                        component={() => <CustomerProfile />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/appointment"
                        component={() => <Appointment />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/calendar"
                        component={() => <CalendarTwo />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/addappointment"
                        component={() => <NewAppointment />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/editappointment"
                        component={() => <EditAppointment />}
                      />
                      <Route
                        exact
                        path="/auth"
                        component={() => <ErrorPage />}
                      />

                      {/* <Route exact path="/404" component={() => <ErrorPage />} />  */}
                      <PartnerRoute
                        exact
                        path="/partners/viewappointment"
                        component={() => <ViewAppointment />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/checkout"
                        component={() => <AppointmentCheckout />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/viewinvoice"
                        component={() => <ViewAppointmentInvoice />}
                      />
                      <Route
                        exact
                        path="/partners/intro"
                        component={() => <SignupMain />}
                      />
                      <Route
                        exact
                        path="/partners/business"
                        component={() => <SignupStep1 />}
                      />
                      <Route
                        exact
                        path="/partners/signuplocation"
                        component={() => <SignupStep2 />}
                      />
                      <Route
                        exact
                        path="/partners/photo"
                        component={() => <SignupStep3 />}
                      />
                      <Route
                        exact
                        path="/partners/signuptaxes"
                        component={() => <SignupStep4 />}
                      />
                      <Route
                        exact
                        path="/partners/payment"
                        component={() => <SignupStep5 />}
                      />
                      <Route
                        exact
                        path="/partners/discount"
                        component={() => <SignupStep6 />}
                      />
                      <Route
                        exact
                        path="/partners/pricing-package"
                        component={() => <SignupStep7 />}
                      />
                      <Route
                        exact
                        path="/partners/onlinepayment"
                        component={() => <SignupStep8 />}
                      />
                      <Route
                        exact
                        path="/partners/business-setup"
                        component={() => <Bussiness />}
                      />
                      <Route
                        exact
                        path="/partners/invoice-setup"
                        component={() => <SignupInvoice />}
                      />
                      <Route
                        exact
                        path="/partners/online-booking"
                        component={() => <SignupOnlineBooking />}
                      />
                      <Route
                        exact
                        path="/partners/online-payment"
                        component={() => <OnlinePayment />}
                      />
                      <UserRoute
                        exact
                        path="/customerappointment/selectservices/:id"
                        component={() => <SelectServices />}
                      />
                      <UserRoute
                        exact
                        path="/customerappointment/select-time/:id"
                        component={() => <SelectTime />}
                      />
                      <UserRoute
                        exact
                        path="/customerappointment/selectpreference/:id"
                        component={() => <SelectPreference />}
                      />
                      <UserRoute
                        exact
                        path="/customerappointment/payment/:id"
                        component={() => <PaymentDetail />}
                      />
                      <Route
                        exact
                        path="/store"
                        render={() => <StoreListing />}
                      />
                      <Route
                        exact
                        path="/storedetail"
                        render={() => <StoreDetail />}
                      />
                      <Route
                        exact
                        path="/voucherdetail/:id"
                        render={() => <VoucherDetail />}
                      />
                      <Route
                        exact
                        path="/giftVoucher/:id"
                        render={() => <GiftVoucher />}
                      />
                      <Route
                        exact
                        path="/emailFrom/:id"
                        render={() => <EmailForm />}
                      />
                      <Route
                        exact
                        path="/paymentVoucher/:id"
                        render={() => <Paymentvoucher />}
                      />
                      <Route
                        exact
                        path="/printGift/:id"
                        render={() => <PrintGift />}
                      />
                      <Route
                        exact
                        path="/verify"
                        component={() => <Verify />}
                      />
                      <Route
                        exact
                        path="/partners/onlinebooking"
                        component={() => <OnlineBooking />}
                      />
                      <Route
                        exact
                        path="/partners/vouchercheckout"
                        component={() => <VoucherCheckout />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/voucherviewinvoice"
                        component={() => <VoucherViewInvoice />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/connect"
                        component={() => <Connect />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/onlinelisting"
                        component={() => <PricingPackage />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/availablity"
                        component={() => <CheckAvailablity />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/details"
                        component={() => <Details />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/carddetails"
                        component={() => <OnlineListing />}
                      />
                      <Route
                        exact
                        path="/wishlist"
                        render={() => <Wishlist />}
                      />
                      <Route
                        exact
                        path="/test"
                        render={() => <TestCalender />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/revenue"
                        component={() => <Revenue />}
                      />

                      {/*
                      <UserRoute exact path="/myappointments" component={() => <MyAppointments username={name} userImage={userImage} />} /> */}

                      <UserRoute
                        exact
                        path="/myappointments"
                        component={() => (
                          <MyAppointments
                            username={name}
                            userImage={userImage}
                          />
                        )}
                      />
                      <UserRoute
                        exact
                        path="/myvouchers"
                        component={() => (
                          <MyVouchers username={name} userImage={userImage} />
                        )}
                      />
                      <UserRoute
                        exact
                        path="/myfavorites"
                        component={() => (
                          <MyFavorites username={name} userImage={userImage} />
                        )}
                      />
                      <UserRoute
                        exact
                        path="/partners/profile"
                        component={() => <PartnerProfile />}
                      />
                      <UserRoute
                        exact
                        path="/editprofile"
                        component={() => (
                          <EditProfile
                            getData={getData}
                            username={name}
                            userImage={userImage}
                          />
                        )}
                      />
                      {/* For Admin Panel */}
                      <AdminRoute
                        exact
                        path="/admin/dashboard"
                        component={() => <AdminDashboard name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/pricing"
                        component={() => <AdminPricing name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/partnercommission/:id"
                        component={() => <PartnerCommission name={name} />}
                      />
                      <Route
                        exact
                        path="/admin/login"
                        render={() => <AdminLogin />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/changepassword"
                        component={() => <AdminForget name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/partner"
                        component={() => <AdminPartner name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/customer"
                        component={() => <AdminCustomer name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/appointments"
                        component={() => <AdminAppointments name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/bussinessTypes"
                        component={() => <AdminBusinessTypes name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/transactions/:id"
                        component={() => <AccountBalance name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/monthlytransaction"
                        component={() => <MonthlyTransaction name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/monthlisting/:id"
                        component={() => <MonthListing name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/payout/:id"
                        component={() => <Payout name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/settings"
                        component={() => <Settings name={name} />}
                      />
                      <AdminRoute
                        exact
                        path="/admin/cron"
                        component={() => <AdminCron name={name} />}
                      />
                      <PartnerRoute
                        exact
                        path="/partners/step3"
                        component={() => <SignUpCardDetails />}
                      />
                      <Route component={() => <ErrorMsg />} />
                    </Switch>
                  </UserProvider>
                </Switch>
              </div>
            </main>
            <Footer />
          </Router>
        </>
      </div>
    </div>
  );
};

export default App;
