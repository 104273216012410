/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Form,
  Modal,
  //Dropdown,
  //DropdownButton,
  Spinner,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import queryString from "query-string";
import { toast } from "react-toastify";
import PartnerWrapper from "../partnerwrapper/wrapper";
import user from "../../../assets/partner/user.jpg";
import history from "../../../config/history";
import { partnerInstance } from "../../../config/axios";
import Loader from "../../loader/loader";
import PartnerContext from "../../../context/context";

const AppointmentCheckout = () => {
  const { username } = useContext(PartnerContext);
  const { currency } = username;
  const [ID, setID] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerFirstname, setCustomerFirstname] = useState("");
  const [customerLastname, setCustomerLastname] = useState("");
  const [customerId, setCustomerId] = useState("");
  //const [appointmentStatus, setAppointmentStatus] = useState("");
  //const [cancelStatus, setCancelStatus] = useState(false);
  const [appointmentPanel, setAppointmentPanel] = useState([]);
  const [PaymentTypes, setPaymentTypes] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [totalDuration, setTotalDuration] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [redeemTotal, setRedeemTotal] = useState(0);
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [tipAmount, setTipAmount] = useState(0);
  const [vouchers, setVouchers] = useState([]);
  const [vouchersCopy, setVouchersCopy] = useState([]);
  const [filteredVouchers, setFilteredVouchers] = useState([]);
  const [currentRedeemAmount, setCurrentRedeemAmount] = useState("");
  const [redeemAmount, setRedeemAmount] = useState("");
  const [disabled, setDisabled] = useState(false);
  //const [includeTax, setIncludeTax] = useState(false);
  const [applyVoucher, setApplyVoucher] = useState({ Value: "", Code: "" });
  // eslint-disable-next-line
  const [disName, setDisName] = useState(0);
  const [excludeTax, setExcludeTax] = useState(false);
  const [view, setView] = useState(false);
  const [cardDigit, setCardDigit] = useState(null);
  const [staffValid, setStaffValid] = useState(true);
  const handleViewClose = () => setView(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const getAppointment = async (id) => {
    setLoading(true);
    const idd = {
      appointmentId: id,
    };
    setID(id);
    const response = await partnerInstance().post("/getAppointment", idd);

    const { code, appointmentData, cardNo } = response.data;
    if (code === 200) {
      setCardDigit(cardNo);
      const wait = [...appointmentData.appointmentPanel];
      wait.forEach((el) => {
        const filterStaffPrice = el.serviceId.staffPricing.findIndex(
          (sel) => sel.staffId === el.staffId._id
        );
        if (
          filterStaffPrice > -1 &&
          el.serviceId.staffPricing[filterStaffPrice].staffPrice
        ) {
          el.serviceId.priceFrom =
            el.serviceId.staffPricing[filterStaffPrice].staffPrice;
        } else if (el.serviceId.specialPrice) {
          el.serviceId.specialPrice;
        } else {
          el.serviceId.priceFrom;
        }
        setAppointmentPanel(wait);
      });
      // setAppointmentPanel(appointmentData.appointmentPanel);
      setAppointmentDate(appointmentData.appointmentDate);
      setCustomerFirstname(appointmentData.customerId.firstname);
      setCustomerLastname(appointmentData.customerId.lastname);
      setCustomerId(appointmentData.customerId._id);
      setCustomerEmail(appointmentData.customerId.email);
      setCustomerAddress(appointmentData.customerId.phone);
      //setAppointmentStatus(appointmentData.appointmentStatus);
      setTotalDuration(appointmentData.totalDuration);
      setPaymentTypes(appointmentData.partnerId.paymentType);
      setExcludeTax(appointmentData.partnerId.retailPriceExcludeTax);
      //setIncludeTax(appointmentData.partnerId.retailPriceIncludeTax);
      setLoading(false);
    }
  };
  const getStaffs = async () => {
    const response = await partnerInstance().get("/getStaffs");
    const { code, stafflist } = response.data;
    if (code === 200) {
      setStaffs(stafflist);
    }
  };

  const getDiscounts = async () => {
    const response = await partnerInstance().get("/getDiscountType");
    const { code, discountTypeData } = response.data;
    if (code === 200) {
      const discountServiceSales = discountTypeData.filter(
        (el) => el.servicesale
      );
      setDiscounts(discountServiceSales);
      if (discountTypeData.length > 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const getStaffData = (item) => {
    const filter = staffs.filter((el) => el._id === item);

    if (filter.length > 0) {
      return filter[0].firstname;
    }
    return false;
  };

  useEffect(() => {
    const value = queryString.parse(window.location.search);
    const { appointmentId } = value;
    getStaffs();
    getAppointment(appointmentId);
    getDiscounts();
  }, []);

  const handleVoucher = () => {
    setView(true);
  };

  const getStaffById = async (id) => {
    const idd = {
      staffid: id,
      appointmentDate,
    };
    const response = await partnerInstance().post("/getStaffShift", idd);
    const { code, shiftTiming: shifttiming, getClosedDates } = response.data;
    if (code === 200) {
      const Closed = getClosedDates.closeddate;
      if (Closed.length > 0) {
        const filterClosedDates = Closed.filter(
          (el) =>
            new Date(appointmentDate).getTime() >=
              new Date(el.from).getTime() &&
            new Date(appointmentDate).getTime() <= new Date(el.to).getTime()
        );
        if (filterClosedDates.length > 0) {
          // setStaffValid(false);
          return [{ starttime: "00:00", endtime: "00:00" }];
        }
        // setStaffValid(true);
        return shifttiming;
      }
      return shifttiming;
    }
  };

  const handleStaff = async (e, i) => {
    let valid = false;
    const oldStatee = [...appointmentPanel];
    const shift = await getStaffById(e);
    const data = await getStaffData(e);
    const staffExists = oldStatee[i].serviceId.staff.includes(e);
    if (staffExists) {
      if (
        oldStatee[i].startTime >= shift[0].starttime &&
        oldStatee[i].startTime <= shift[0].endtime
      ) {
        setStaffValid(true);
        oldStatee[i].staffValid = true;
        oldStatee[i].message = "";
        valid = true;
      } else {
        setStaffValid(false);
        oldStatee[i].staffValid = false;
        oldStatee[i].message = `${data} is not working in this duration`;
        valid = false;
      }
      if (shift.length > 1 && !valid) {
        if (
          oldStatee[i].startTime >= shift[1].starttime &&
          oldStatee[i].startTime <= shift[1].endtime
        ) {
          setStaffValid(true);
          oldStatee[i].staffValid = true;
          oldStatee[i].message = "";
        } else {
          setStaffValid(false);
          oldStatee[i].staffValid = false;
          oldStatee[i].message = `${data} is not working in this duration`;
        }
      }
    } else {
      setStaffValid(false);
      oldStatee[i].message = `${data} is not associated with this service`;
    }

    oldStatee.forEach((el, j) => {
      if (i === j) {
        el.staffId._id = e;
        el.staffId.firstname = data;
      }
    });

    setAppointmentPanel(oldStatee);
  };

  const handleDiscount = (e, i) => {
    setCurrentRedeemAmount("");
    setRedeemTotal(0);
    setApplyVoucher({ Value: "", Code: "" });
    const discountData = e.split("@");
    if (e !== "Choose") {
      setDisName(`${discountData[0]}${discountData[1]}${discountData[2]} off`);
    } else {
      setDisName("");
    }
    if (discountData[2] === "%") {
      const oldStatee = [...appointmentPanel];
      oldStatee.forEach((el, j) => {
        if (i === j) {
          if (el.serviceId.specialPrice) {
            el.totalDiscount =
              (el.serviceId.specialPrice * discountData[1]) / 100;
            el.discountValue =
              el.serviceId.specialPrice -
              (el.serviceId.specialPrice * discountData[1]) / 100;
          } else {
            el.totalDiscount = (el.serviceId.priceFrom * discountData[1]) / 100;
            el.discountValue =
              el.serviceId.priceFrom -
              (el.serviceId.priceFrom * discountData[1]) / 100;
          }

          el.discountType = discountData[2];
          el.disGone = discountData[1];
          el.discountName = discountData[0];
        }
      });
      setDiscountValue(discountData[2]);
      setAppointmentPanel(oldStatee);
    } else {
      const oldStatee = [...appointmentPanel];
      oldStatee.forEach((el, j) => {
        if (i === j) {
          if (el.serviceId.specialPrice) {
            el.totalDiscount = discountData[1];
            el.discountValue = el.serviceId.specialPrice - discountData[1];
          } else {
            el.totalDiscount = discountData[1];
            el.discountValue = el.serviceId.priceFrom - discountData[1];
          }
          el.discountType = discountData[2];
          el.disGone = discountData[1];
          el.discountName = discountData[0];
        }
      });
      setAppointmentPanel(oldStatee);
      setDiscountValue(discountData[2]);
    }
  };

  let totalamount = 0;
  let totaldiscount = 0;
  let tax = 0;
  let subTotal = 0;
  let totalSubTotal = 0;
  const handleTipAmount = (e) => {
    if (e === "") {
      setTipAmount(0);
    } else {
      setTipAmount(e);
      totalamount += e;
    }
  };

  const handleNegative = (e) => {
    if (e.which === 109 || e.which === 189) {
      e.preventDefault();
    }
  };

  const calculateTax = (data) => {
    let value = 0;
    let specValue = 0;
    let fromValue = 0;
    if (data.discountType) {
      specValue = data.discountValue;
      fromValue = data.discountValue;
    } else {
      specValue = data.serviceId.specialPrice;
      fromValue = data.serviceId.priceFrom;
    }
    if (excludeTax) {
      if (specValue) {
        value =
          parseInt(specValue) *
          (parseInt(data.serviceId.serviceTax.taxRate) / 100);
        totalamount += value;
      } else {
        value =
          parseInt(fromValue) *
          (parseInt(data.serviceId.serviceTax.taxRate) / 100);
        totalamount += value;
      }
    } else if (specValue) {
      value =
        ((parseInt(data.serviceId.serviceTax.taxRate) / 100) * specValue) /
        (1 + parseInt(data.serviceId.serviceTax.taxRate) / 100);
    } else {
      value =
        ((parseInt(data.serviceId.serviceTax.taxRate) / 100) * fromValue) /
        (1 + parseInt(data.serviceId.serviceTax.taxRate) / 100);
    }

    tax += value;
    return value.toFixed(2);
  };

  const IncExPrice = (data) => {
    let value = 0;
    let sub = 0;
    let full = 0;
    let specValue = 0;
    let fromValue = 0;
    if (data.discountType) {
      specValue = data.discountValue;
      fromValue = data.discountValue;
    } else {
      specValue = data.serviceId.specialPrice;
      fromValue = data.serviceId.priceFrom;
    }
    if (excludeTax) {
      if (specValue) {
        full = parseInt(specValue);
      } else {
        full = parseInt(fromValue);
      }
    } else if (specValue) {
      sub = specValue;
      value =
        ((parseInt(data.serviceId.serviceTax.taxRate) / 100) * specValue) /
        (1 + parseInt(data.serviceId.serviceTax.taxRate) / 100);
      full = sub - value.toFixed(2);
    } else {
      sub = fromValue;
      value =
        ((parseInt(data.serviceId.serviceTax.taxRate) / 100) * fromValue) /
        (1 + parseInt(data.serviceId.serviceTax.taxRate) / 100);
      full = sub - value.toFixed(2);
    }
    subTotal = full;
    totalSubTotal += full;
    return subTotal;
  };

  const completeCheckout = async (e, k) => {
    const payload = {
      customerFirstname,
      customerLastname,
      customerEmail,
      customerAddress,
      appointmentId: ID,
      appointmentPanel,
      tax,
      subTotal: totalSubTotal,
      tipAmount,
      applyVoucher,
      totalAmount:
        tipAmount && redeemTotal
          ? parseInt(tipAmount) + redeemTotal
          : tipAmount
          ? parseInt(tipAmount) + totalamount
          : redeemTotal || totalamount - currentRedeemAmount,
      totalDiscount: totaldiscount,
      discountValue,
      paymentMethod: e,
      appointmentDate,
    };

    if (!staffValid) {
      return false;
    }

    const oldState = [...PaymentTypes];
    oldState[k].isLoading = true;
    setPaymentTypes(oldState);

    const saveData = await partnerInstance().post("/checkout", payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      localStorage.removeItem("custId");
      history.push(`/partners/viewinvoice?appointmentId=${ID}`);
    } else {
      toast.error(msg, { containerId: "B" });
      const oldstate = [...PaymentTypes];
      oldstate[k].isLoading = false;
      setPaymentTypes(oldstate);
    }
  };

  const RemoveVoucher = () => {
    setCurrentRedeemAmount("");
    setRedeemTotal(0);
    setApplyVoucher({ Value: "", Code: "" });
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Checkout">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="checkout-appointment-page appointment-view">
        <div className="container">
          <h2 className="admin-title">
            Checkout{" "}
            <Link to="/partners/calendar">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="checkout-appointment-content appointment-content">
            <div className="row">
              <div className="col-md-7">
                <div className="appointment-card">
                  <div className="appointment-body">
                    <div className="view-appointment-form">
                      <h4>
                        {new Date(appointmentDate).toString().substring(0, 15)}
                      </h4>
                      <div className="scrollbar">
                        {appointmentPanel &&
                          appointmentPanel.map((el, i) => (
                            <div className="view-appointment-list">
                              <div className="customer-appointment">
                                <div className="customer-appoint-box">
                                  <div className="appoint-box-name">
                                    <h4>{el.serviceId.serviceName}</h4>
                                    <span>
                                      {el.startTime} |{" "}
                                      {Math.floor(
                                        parseInt(el.duration) / 60
                                      ) === 0 ? (
                                        <>{parseInt(el.duration) % 60} min </>
                                      ) : (
                                        <>
                                          {Math.floor(
                                            parseInt(el.duration) / 60
                                          )}
                                          h{" "}
                                          {parseInt(el.duration) % 60 === 0 ? (
                                            ""
                                          ) : (
                                            <>
                                              ,{parseInt(el.duration) % 60} min{" "}
                                            </>
                                          )}
                                        </>
                                      )}
                                      with {getStaffData(el.staffId._id)},{" "}
                                      {el.discountValue ? (
                                        <>
                                          <span>
                                            {el.discountName} {el.disGone}{" "}
                                            {el.discountType} off
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                  {el.discountType && (
                                    <>
                                      <div className="appoint-box-price">
                                        <h6>
                                          {currency}{" "}
                                          <span>
                                            {el.serviceId.specialPrice
                                              ? el.serviceId.specialPrice
                                              : el.serviceId.priceFrom}
                                          </span>
                                        </h6>
                                      </div>
                                    </>
                                  )}
                                  <div className="appoint-box-price">
                                    <h6>
                                      {currency}{" "}
                                      {el.serviceId.specialPrice
                                        ? parseInt(
                                            el.serviceId.specialPrice
                                          ) === 0
                                          ? `${el.serviceId.specialPrice} Free`
                                          : el.discountValue >= 0
                                          ? el.discountValue
                                          : el.serviceId.specialPrice
                                        : parseInt(el.serviceId.priceFrom) === 0
                                        ? `${el.serviceId.priceFrom} Free`
                                        : el.discountValue >= 0
                                        ? el.discountValue
                                        : el.serviceId.priceFrom}
                                    </h6>
                                  </div>
                                  <div className="service-details">
                                    <Form.Row>
                                      <Form.Group
                                        as={Col}
                                        controlId="formGridCity"
                                      >
                                        <Form.Label>Qty</Form.Label>
                                        <Form.Control value="1" disabled />
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        controlId="formGridZip"
                                      >
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control
                                          disabled
                                          value={
                                            el.serviceId.specialPrice
                                              ? el.serviceId.specialPrice
                                              : el.serviceId.priceFrom
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        controlId="formGridState"
                                      >
                                        <Form.Label>Staff</Form.Label>
                                        <Form.Control
                                          as="select"
                                          defaultValue="Choose..."
                                          onChange={(e) =>
                                            handleStaff(e.target.value, i)
                                          }
                                          value={el.staffId._id}
                                        >
                                          {staffs.map((ele) => (
                                            <option value={ele._id}>
                                              {ele.firstname}
                                            </option>
                                          ))}
                                        </Form.Control>
                                        <span className="error-msg">
                                          {" "}
                                          {el.message && el.message}
                                        </span>
                                      </Form.Group>
                                      {el.serviceId.priceFrom ||
                                      el.serviceId.specialPrice > 0 ? (
                                        <Form.Group
                                          as={Col}
                                          controlId="formGridState"
                                        >
                                          <Form.Label>Discount</Form.Label>
                                          <Form.Control
                                            as="select"
                                            disabled={disabled}
                                            defaultValue="Choose..."
                                            onChange={(e) =>
                                              handleDiscount(e.target.value, i)
                                            }
                                            value={el.discountvalue}
                                          >
                                            {discounts &&
                                              discounts.map((ele, k) => (
                                                <>
                                                  {k === 0 ? (
                                                    <option>Choose...</option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <option
                                                    value={`${ele.discountname}@${ele.discountvalue}@${ele.discountvaluetype}`}
                                                  >
                                                    {ele.discountname}{" "}
                                                    {ele.discountvalue}{" "}
                                                    {ele.discountvaluetype}
                                                  </option>
                                                </>
                                              ))}
                                          </Form.Control>
                                        </Form.Group>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Row>
                                  </div>
                                </div>

                                <div className="subtotal">
                                  <div className="subtotal-upper">
                                    <span>SubTotal:</span>{" "}
                                    <span>
                                      {currency} {IncExPrice(el)}
                                    </span>
                                  </div>
                                  {el.serviceId.serviceTax.taxName && (
                                    <div className="subtotal-lower">
                                      <span>
                                        {el.serviceId.serviceTax.taxName} (
                                        {el.serviceId.serviceTax.taxRate}
                                        %) :
                                      </span>{" "}
                                      <span>
                                        {calculateTax(el)}
                                        {}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className=" col-md-12 ">
                        <div className="total-output">
                          <div className="total-d">
                            <h4>Total</h4>

                            {appointmentPanel.forEach((el) => {
                              el.totalDiscount
                                ? (totaldiscount += parseInt(el.totalDiscount))
                                : (totaldiscount += 0);

                              el.discountValue >= 0
                                ? (totalamount += parseInt(el.discountValue))
                                : (totalamount += parseInt(
                                    el.serviceId.specialPrice
                                      ? el.serviceId.specialPrice
                                      : el.serviceId.priceFrom
                                  ));
                            })}
                            <span>
                              {currency} {totalamount}
                            </span>
                          </div>
                          {currentRedeemAmount && (
                            <div className="total-d">
                              <h4>Voucher</h4>
                              <span>
                                {currency} {currentRedeemAmount}
                              </span>
                              <div
                                className="cross-btn"
                                onClick={RemoveVoucher}
                              >
                                <i className="fa fa-times" aria-hidden="true" />
                              </div>
                            </div>
                          )}
                          <div className="add-more">
                            <span>
                              <input
                                type="number"
                                onChange={(e) =>
                                  handleTipAmount(e.target.value)
                                }
                                min="0"
                                onKeyDown={handleNegative}
                                placeholder="Add Tip"
                              />
                            </span>
                          </div>

                          <div className="balance-d">
                            <h4>Balance</h4>
                            <span>
                              {currency}{" "}
                              {tipAmount && redeemTotal
                                ? parseInt(tipAmount) + redeemTotal
                                : tipAmount
                                ? parseInt(tipAmount) + totalamount
                                : redeemTotal ||
                                  totalamount - currentRedeemAmount}
                              {/* parseInt(tipAmount) + parseInt(totalamount) */}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <Link to={`/partners/viewinvoice?appointmentId=${ID}`}>
                        Proceed
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="appointment-card">
                  {/* Customer Details */}

                  <div className="customer-detail">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="customer-card">
                          <div className="customer-detail-appoint">
                            <div className="customer-detail-icon">
                              <img alt={user} src={user} />
                            </div>
                            <div className="customer-detail-content">
                              <h4>
                                {customerFirstname} {customerLastname}
                              </h4>
                              <p>{customerEmail}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pay-status">
                    <Form.Group controlId="formBasicEmail">
                      <label>To Pay</label>
                      <Form.Control
                        placeholder={`${currency} ${
                          tipAmount && redeemTotal
                            ? parseInt(tipAmount) + redeemTotal
                            : tipAmount
                            ? parseInt(tipAmount) + totalamount
                            : redeemTotal || totalamount - currentRedeemAmount
                        } `}
                        disable
                      />
                    </Form.Group>
                  </div>

                  {/* <div className="no-invoice">
                        <img src={invoice} />
                        <p>No invoice issued yet</p>
                      </div> */}

                  <div className="Cash-buttons ">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="cash-btn">
                          {PaymentTypes.map((item, k) => (
                            <>
                              {item.isLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <Button
                                  className="appointment-btn btn-perple"
                                  onClick={() =>
                                    completeCheckout(item.paymentname, k)
                                  }
                                >
                                  {item.paymentname}{" "}
                                  {item.paymentname === "Card"
                                    ? cardDigit
                                    : null}
                                </Button>
                              )}
                            </>
                          ))}

                          <Button
                            className="appointment-btn btn-perple"
                            onClick={handleVoucher}
                          >
                            Voucher
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="appointment-buttons">
                    <span>
                      Total:{" "}
                      {tipAmount && redeemTotal
                        ? parseInt(tipAmount) + redeemTotal
                        : tipAmount
                        ? parseInt(tipAmount) + totalamount
                        : redeemTotal || totalamount - currentRedeemAmount}
                      (
                      {Math.floor(parseInt(totalDuration) / 60) === 0 ? (
                        <>{parseInt(totalDuration) % 60} min</>
                      ) : (
                        <>
                          {Math.floor(parseInt(totalDuration) / 60)}h{" "}
                          {parseInt(totalDuration) % 60 === 0 ? (
                            ""
                          ) : (
                            <>,{parseInt(totalDuration) % 60} min</>
                          )}
                        </>
                      )}
                      )
                    </span>
                    {/* <div className="footer-button">
                      <DropdownButton
                        id="dropdown-basic-button-2"
                        title="More Option"
                      >
                        <Link to="/partners/addappointment">
                          Edit Appointment
                        </Link>
                        <Dropdown.Item>Reschedule</Dropdown.Item>
                        <Dropdown.Item onClick={handleCancel}>Cancel</Dropdown.Item>
                      </DropdownButton>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="category-popup" show={view} onHide={handleViewClose}>
        <Modal.Header closeButton>
          <Modal.Title>Voucher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddVoucher
            setView={setView}
            vouchers={vouchers}
            setVouchers={setVouchers}
            vouchersCopy={vouchersCopy}
            setFilteredVouchers={setFilteredVouchers}
            filteredVouchers={filteredVouchers}
            customerId={customerId}
            setVouchersCopy={setVouchersCopy}
            setRedeemTotal={setRedeemTotal}
            redeemTotal={redeemTotal}
            totalamount={totalamount}
            redeemAmount={redeemAmount}
            setRedeemAmount={setRedeemAmount}
            setCurrentRedeemAmount={setCurrentRedeemAmount}
            currentRedeemAmount={currentRedeemAmount}
            appointmentPanel={appointmentPanel}
            applyVoucher={applyVoucher}
            setApplyVoucher={setApplyVoucher}
          />
        </Modal.Body>
      </Modal>
    </PartnerWrapper>
  );
};

export default AppointmentCheckout;

const AddVoucher = ({
  setView,
  totalamount,
  setRedeemTotal,
  setCurrentRedeemAmount,
  appointmentPanel,
  applyVoucher,
  setApplyVoucher,
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [call, setCall] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentValue, setCurrentValue] = useState(0);
  const [searchCount, setSearchCount] = useState(0);
  const [message, setMessage] = useState("");
  const [voCode, setVoCode] = useState("");

  const redeemVoucher = (e) => {
    if (totalamount >= e) {
      setCurrentRedeemAmount(e);
      setRedeemTotal(totalamount - e);
      setApplyVoucher({ Value: e, Code: voCode });
      setView(false);
      setCall(true);
    } else {
      setView(true);
      setMessage("Voucher Value is greater than Total Value");
      setCall(false);
    }
  };

  const voucherSearch = async (e) => {
    setSearchKeyword(e);
    const payload = {
      code: e,
    };
    const response = await partnerInstance().post("/getVoucherByCode", payload);
    const { code, findVoucher, getVoucherServices } = response.data;
    if (code === 200) {
      if (!applyVoucher.Value) {
        if (findVoucher && findVoucher.voucherStatus !== "Voided Invoice") {
          if (!findVoucher.isReedemed) {
            const copy = [...appointmentPanel];
            const ser = getVoucherServices.voucher[0].serviceid;
            const filter = copy.filter((el) => ser.includes(el.serviceId._id));
            setSearchCount(filter.length);
            setCurrentValue(findVoucher.voucherValue);
            setVoCode(findVoucher.voucherCode);
            setStartDate(
              new Date(findVoucher.PurchaseDate).toString().substring(0, 15)
            );
            setEndDate(
              new Date(findVoucher.ExpiryDate).toString().substring(0, 15)
            );
            setCall(true);
            setMessage(
              "You Can't Redeem this Voucher because this Service is Not Applicable on this Voucher"
            );
          } else {
            setCall(true);
            setMessage("This Voucher was already reedemed");
            setStartDate(
              new Date(findVoucher.PurchaseDate).toString().substring(0, 15)
            );
            setEndDate(
              new Date(findVoucher.ExpiryDate).toString().substring(0, 15)
            );
          }
        } else {
          setCall(false);
          setMessage("No Vouchers Found");
        }
      } else {
        setCall(true);
        setMessage(
          "This voucher was already selected for payment on this invoice."
        );
        setStartDate(
          new Date(findVoucher.PurchaseDate).toString().substring(0, 15)
        );
        setEndDate(
          new Date(findVoucher.ExpiryDate).toString().substring(0, 15)
        );
        setCurrentValue("");
      }
    }
  };

  return (
    <div className="add-category">
      <Form.Group controlId="formBasicloginone">
        <Form.Label>Voucher Code</Form.Label>
        <Form.Control
          type="text"
          placeholder="Search Voucher"
          name="category"
          autoComplete="off"
          value={searchKeyword}
          onChange={(e) => voucherSearch(e.target.value)}
        />
      </Form.Group>

      <div className="select-categories">
        <Card>
          <Card.Body>
            {call ? (
              <>
                {searchCount > 0 ? (
                  <div className="select-list">
                    <div className="redeem-popup">
                      <h5>hello</h5>
                      <p>Redemption Amount: </p>

                      <div className="add-service-price">
                        <span>{currentValue}</span>
                      </div>
                      <p>
                        Purchase Date:
                        {startDate}
                      </p>
                      <p>
                        Expiry Date:
                        {endDate}
                      </p>
                      <div className="login-button" />
                      <Button
                        type="submit"
                        className="l-btn"
                        onClick={() => redeemVoucher(currentValue)}
                      >
                        Redeem
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="select-list">
                    <div>
                      <h5>{message}</h5>
                      <p>Redemption Amount: </p>

                      <div className="add-service-price">
                        <span>{currentValue}</span>
                      </div>
                      <div className="login-button" />
                      <p>
                        Purchase Date:
                        {startDate}
                      </p>
                      <p>
                        Expiry Date:
                        {endDate}
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <p>{message}</p>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

AddVoucher.propTypes = {
  setView: PropTypes.func.isRequired,
  totalamount: PropTypes.number.isRequired,
  setRedeemTotal: PropTypes.func.isRequired,
  setCurrentRedeemAmount: PropTypes.func.isRequired,
  appointmentPanel: PropTypes.arrayOf(PropTypes.array).isRequired,
  setApplyVoucher: PropTypes.func.isRequired,
};
