/* eslint-disable radix */
import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  Form,
  //   Modal,
  Dropdown,
  FormControl,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import PartnerWrapper from "../partnerwrapper/wrapper";
import user from "../../../assets/partner/user.jpg";
import search from "../../../assets/partner/search.png";
import { partnerInstance } from "../../../config/axios";
import PartnerContext from "../../../context/context";
import history from "../../../config/history";
// import Discount from '../../../assets/discount.svg';
import { validateAppointment } from "../../../function/validate";
import "./appointment.css";

const NewAppointment = () => {
  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const [viewCheckout, setViewCheckout] = useState(false);
  const [button, setButton] = useState("");
  const [services, setServices] = useState([]);
  const round = (date, duration, method) =>
    moment(Math[method](+date / +duration) * +duration);

  const date = moment();

  const roundedDate = round(date, moment.duration(5, "minutes"), "ceil");
  const [startTime, setStartTime] = useState(
    roundedDate.toString().substring(16, 21)
  );
  //console.log('This is the start Time',startTime);
  // const [startTime, setStartTime] = useState(
  //   moment(currentTime).toString().substring(16, 21)
  // );
  const [duration, setDuration] = useState("5");
  const [errors, setErrors] = useState({});
  const [appointmentPanel, setAppointmentPanel] = useState([
    {
      categoryId: "",
      serviceId: "",
      staffId: "",
      startTime: "",
      duration: "5",
      appointmentNote: "",
      extradurationAfter: null,
      extradurationBefore: null,
    },
  ]);
  const [staffs, setStaffs] = useState([]);
  const [staffId, setStaffId] = useState("");
  //const [serviceId, setServiceId] = useState('');

  const [customers, setCustomers] = useState([]);
  const [customersCopy, setCustomersCopy] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  // eslint-disable-next-line
  const [extraDurationAfter, setExtraDurationAfter] = useState("0");
  // eslint-disable-next-line
  const [extraDurationBefore, setExtraDurationBefore] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  //const [customerDetail, setCustomerDetail] = useState([]);
  const [isBlock, setIsBlock] = useState();
  const [timeData, setTimeData] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  //const [shiftTiming, setShiftTiming] = useState([]);
  const [staffValid, setStaffValid] = useState(true);
  // eslint-disable-next-line
  const [totalPrice, setTotalPrice] = useState(0);
  //const [totalDuration, setTotalDuration] = useState(0);

  useEffect(() => {
    const setSta =
      sections &&
      sections.findIndex((el) => el.sectionname === "Can check out sales");
    //console.log("The value of setSta", setSta);
    if (setSta > -1) {
      setViewCheckout(false);
    } else {
      setViewCheckout(true);
    }
    const value = queryString.parse(window.location.search);

    const { customerid, starttime, startDate: startdate, staffId } = value;
    //console.log("StartTime", starttime);
    //console.log("Start Date", startDate);
    if (startdate) {
      setStartDate(startdate);
    } else {
      setStartDate(startDate);
    }
    //console.log("Staff id", staffId);
    setStartTime(starttime);
    setStaffId(staffId);
    if (starttime) {
      appointmentPanel.forEach((el) => {
        el.startTime = starttime;
        el.staffId = staffId;
        setStartTime(starttime);
      });
      setAppointmentPanel(appointmentPanel);
    } else {
      appointmentPanel.forEach((el) => {
        el.startTime = startTime;
        el.staffId = staffId;
      });
      setAppointmentPanel(appointmentPanel);
    }

    // setStartTime(starttime);

    setCustomerId(customerid);
    const getStaffs = async () => {
      const response = await partnerInstance().get("/getStaffs");
      const { code, stafflist } = response.data;
      if (code === 200) {
        //console.log('staff List',stafflist);
        const whoHasAppointmentBooking = stafflist.filter(
          (bo) => bo.appointmentBooking
        );
        //console.log('Select Staff',whoHasAppointmentBooking);
        setStaffs(whoHasAppointmentBooking);
      }
    };
    const getCustomers = async () => {
      const response = await partnerInstance().get("/getCustomers");
      //console.log(response)
      const { code, clientId } = response.data;
      //console.log('Am I getting something here',clientId)
      if (code === 200) {
        // setCustomers(clientId);
        //console.log('Customers',clientId);
        setCustomersCopy(clientId);
        const autoSelectcustomer = localStorage.getItem("custId");
        if (autoSelectcustomer) {
          getCustomerDetail(autoSelectcustomer);
        }
      }
    };
    const getCategories = async () => {
      const categoriesData = await partnerInstance().get("/getCategories");
      const { code, categories: Categories } = categoriesData.data;
      //console.log("Categories", Categories);
      if (code === 200) {
        setCategories(Categories);
        appointmentPanel.forEach((el) => {
          el.categories = Categories;
        });
        setAppointmentPanel(appointmentPanel);
      }
    };
    const time = () => {
      const x = 5; // minutes interval
      const timeDataList = []; // time array
      let tt = 0; // start time
      // loop to increment the time and push results in array
      for (let i = 0; tt < 24 * 60; i++) {
        const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        const mm = tt % 60; // getting minutes of the hour in 0-55 format
        timeDataList[i] = `${`0${hh % 24}`.slice(-2)}:${`0${mm}`.slice(-2)}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt += x;
      }
      //console.log("Time Data List", timeDataList);
      setTimeData(timeDataList);
    };

    time();
    getCategories();
    getCustomers();
    getStaffs();

    if (customerid) {
      getCustomerDetail(customerid);
    }

    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   setTotalPrice(priceTotal);
  // }, [priceStatus]);
  const getServicesByCategory = async (id, data, i) => {
    setErrors({});
    const payloadd = {
      catId: id,
      staffId: data,
    };

    // const catId = packageCategoryid;

    const servicesData = await partnerInstance().post(
      "/getServicesByCategory",
      payloadd
    );
    const { code, services: Services } = servicesData.data;
    if (code === 200) {
      const dataa = [Services];
      //console.log("Data I am getting by the category", dataa);
      // setServices(dataa[0].serviceid);
      // appointmentPanel.forEach((el, j) => {
      //   if (i === j) {
      //     el.services = dataa[0].serviceid;
      //     // el.appointmentDate = startDate;
      //   }
      // });
      // setAppointmentPanel(appointmentPanel);
      appointmentPanel.forEach((el, j) => {
        if (i === j) {
          el.categoryId = id;
          el.services = [...dataa[0].serviceid, ...dataa[0].packageid];
          // el.appointmentDate = startDate;
        }
      });
      setServices([...dataa[0].serviceid, ...dataa[0].packageid]);
      setAppointmentPanel(appointmentPanel);
      console.log("Hey cheeck out this manipulation", [
        ...dataa[0].serviceid,
        ...dataa[0].packageid,
      ]);
      return [...dataa[0].serviceid, ...dataa[0].packageid];
    }
  };

  const getStaffById = async (id) => {
    const idd = {
      staffid: id,
      appointmentDate: startDate,
    };
    const response = await partnerInstance().post("/getStaffShift", idd);
    const { code, shiftTiming: shifttiming, getClosedDates } = response.data;
    if (code === 200) {
      const Closed = getClosedDates.closeddate;
      if (Closed.length > 0) {
        const filterClosedDates = Closed.filter(
          (el) =>
            new Date(startDate).getTime() >= new Date(el.from).getTime() &&
            new Date(startDate).getTime() <= new Date(el.to).getTime()
        );
        if (filterClosedDates.length > 0) {
          setStaffValid(false);
          return [{ starttime: "00:00", endtime: "00:00" }];
        }
        setStaffValid(true);
        //setShiftTiming(shifttiming);
        return shifttiming;
      }
      //setShiftTiming(shifttiming);
      return shifttiming;
    }
  };

  const getCustomerDetail = async (id) => {
    const idd = {
      customerid: id,
    };
    const response = await partnerInstance().post("/getCustomer", idd);
    const { code, customerData } = response.data;
    if (code === 200) {
      if (customerData) {
        //setCustomerDetail([customerData]);
        setFirstname(customerData.firstname);
        setLastname(customerData.lastname);
        setEmail(customerData.email);
        setCustomerId(customerData._id);
        setIsBlock(customerData.isBlock);
      } else {
        //setCustomerDetail([customerData]);
        setFirstname("");
        setLastname("");
        setEmail("");
        setCustomerId("");
        history.push(
          `/partners/addappointment/?startDate=${startDate}&starttime=${startTime}&staffId=${staffId}`
        );
      }
    }
  };
  const getServiceById = async (serviceid, i) => {
    //setServiceId(serviceid);
    setErrors({});
    const payload = {
      serviceid,
    };
    const response = await partnerInstance().post("/getService", payload);

    const { code, service } = response.data;
    if (code === 200) {
      //console.log("Hey this is the service I am getting in", service);
      const outService = service.outService
        ? parseInt(service.outServiceExtraTime)
        : 0;
      const whoHasAppointmentBooking = service.staff.filter(
        (bo) => bo.appointmentBooking
      );
      setDuration(service.duration);
      setExtraDurationBefore(service.extraDurationBefore);
      setExtraDurationAfter(service.extraDurationAfter);
      setStaffs(whoHasAppointmentBooking);
      const state = [...appointmentPanel];
      const filterStaffPrice = await service.staffPricing.findIndex(
        (sel) => sel.staffId === state[i].staffId
      );
      if (
        filterStaffPrice > -1 &&
        service.staffPricing[filterStaffPrice].staffPrice
      ) {
        //console.log.log("Am I in this if condition");
        state.forEach((el, j) => {
          const nextStartTime = moment(el.startTime, "HH:mm:ss")
            .add(
              service.staffPricing[filterStaffPrice].staffDuration,
              "minutes"
            )
            .format("HH:mm");
          if (i === j) {
            el.totalPrice =
              totalPrice +
              parseInt(service.staffPricing[filterStaffPrice].staffPrice);
            el.duration = service.staffPricing[filterStaffPrice].staffDuration;
            el.endTime = nextStartTime;
            el.extradurationAfter = service.extraDurationAfter;
            el.extradurationBefore = service.extraDurationBefore;
          }
        });
        setAppointmentPanel(state);
      } else if (service.specialPrice) {
        state.forEach((el, j) => {
          const nextStartTime = moment(el.startTime, "HH:mm:ss")
            .add(service.duration, "minutes")
            .format("HH:mm");
          if (i === j) {
            el.totalPrice = totalPrice + parseInt(service.specialPrice);
            el.duration = +service.duration + outService;
            el.endTime = nextStartTime;
            el.extradurationAfter = service.extraDurationAfter;
            el.extradurationBefore = service.extraDurationBefore;
          }
        });
        setAppointmentPanel(state);
      } else {
        state.forEach((el, j) => {
          const nextStartTime = moment(el.startTime, "HH:mm:ss")
            .add(service.duration, "minutes")
            .format("HH:mm");
          if (i === j) {
            el.totalPrice = totalPrice + parseInt(service.priceFrom);
            el.duration = +service.duration + outService;
            el.endTime = nextStartTime;
            el.extradurationAfter = service.extraDurationAfter;
            el.extradurationBefore = service.extraDurationBefore;
          }
        });
        setAppointmentPanel(state);
      }
      if (staffId) {
        const nextStartTime = moment(startTime, "HH:mm:ss")
          .add(service.duration, "minutes")
          .format("HH:mm");
        const shift = await getStaffById(staffId);
        let valid = false;
        if (
          startTime >= shift[0].starttime &&
          nextStartTime <= shift[0].endtime
        ) {
          setStaffValid(true);
          state[i].staffValid = true;
          valid = true;
          update();
        } else {
          setStaffValid(false);
          state[i].staffValid = false;
          valid = false;
          update();
        }
        if (shift.length > 1 && !valid) {
          if (
            startTime >= shift[1].starttime &&
            nextStartTime <= shift[1].endtime
          ) {
            setStaffValid(true);
            state[i].staffValid = true;
            update();
          } else {
            state[i].staffValid = false;
            setStaffValid(false);
            update();
          }
        }

        setAppointmentPanel(state);
        update();
      }
    }

    // return service;

    // return service;
  };

  const addAppointment = async (e) => {
    e.preventDefault();
    const payload = {
      appointmentDate: startDate,
      customerId,
      appointmentPanel,
      staffId,
      totalAmount: totalPrice,
      totalDuration: total,
    };
    const validation = validateAppointment({
      customerId,
      appointmentPanel,
    });

    if (!staffValid) {
      validation.isValid = false;
    }
    setErrors(validation.errors);
    if (!validation.isValid) {
      return true;
    }
    const saveData = await partnerInstance().post("/addAppointment", payload);
    const { code, msg, appointmentId } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      // clearValues();
      if (button === "Save") {
        history.push("/partners/calendar");
      } else {
        history.push(`/partners/checkout?appointmentId=${appointmentId}`);
      }
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  const selectAppointmentNote = (e, i) => {
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.appointmentNote = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
  };

  const selectCustomerId = (e) => {
    getCustomerDetail(e);
    setCustomerId(e);
  };

  const removeCustomerId = (e) => {
    getCustomerDetail(null);
  };

  const selectStartTime = async (e, i) => {
    setStartTime(e);

    const nextStartTime = moment(e.toString(), "HH:mm:ss")
      .add(duration, "minutes")
      .format("HH:mm");

    // appointmentPanel.forEach((el, j) => {
    //   if (i === j) {
    //     el.startTime = e.toString();
    //     el.endTime = nextStartTime;
    //   }
    // });
    //let newPanel=[...appointmentPanel];
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.startTime = e.toString();
        el.endTime = nextStartTime;
      }
    });

    let current = moment(e.toString(), "HH:mm:ss");
    let k = -1;
    let swapIndex = -1;
    appointmentPanel.forEach((el, j) => {
      if (j < i) {
        if (swapIndex === -1) {
          //console.log("I am inside this if condition");
          let previous = moment(el.startTime.toString(), "HH:mm:ss");
          let check = previous.isAfter(current);
          //console.log(check);
          if (check === true) {
            swapIndex = j;
            k = 1;
          }
        }
      } else {
        if (j > i) {
          if (k !== 1) {
            let after = moment(el.startTime.toString(), "HH:mm:ss");
            let check = after.isBefore(current);
            if (check === true) {
              swapIndex = j;
            }
          }
        }
      }
    });

    if (swapIndex !== -1) {
      let obj = appointmentPanel[i];
      appointmentPanel[i] = appointmentPanel[swapIndex];
      appointmentPanel[swapIndex] = obj;
    }

    //setAppointmentPanel(appointmentPanel);
    setAppointmentPanel(appointmentPanel);
    if (staffId) {
      const shift = await getStaffById(staffId);
      let valid = false;
      if (
        e.toString() >= shift[0].starttime &&
        e.toString() <= shift[0].endtime &&
        nextStartTime.toString() >= shift[0].starttime &&
        nextStartTime.toString() <= shift[0].endtime
      ) {
        setStaffValid(true);
        appointmentPanel[i].staffValid = true;
        valid = true;
      } else {
        setStaffValid(false);
        appointmentPanel[i].staffValid = false;
        valid = false;
      }
      if (shift.length > 1 && !valid) {
        if (
          e.toString() >= shift[1].starttime &&
          e.toString() <= shift[1].endtime &&
          nextStartTime.toString() >= shift[1].starttime &&
          nextStartTime.toString() <= shift[1].endtime
        ) {
          setStaffValid(true);
          appointmentPanel[i].staffValid = true;
        } else {
          setStaffValid(false);
          appointmentPanel[i].staffValid = false;
        }
      }
      update();
    }
  };

  const selectDuration = async (e, i) => {
    //console.log('This is the duration yeah',e)

    //converting e to HH::mm format
    //let m = e % 60;
    //let h = (e-m)/60;
    //let ee = h.toString() + ":" + (m<10?"0":"") + m.toString();

    //converting previous ti HH:mm format
    //let previous=appointmentPanel[i].duration;

    //let mm=previous%60;
    //let hh=(previous-mm)/60;
    //let previousduration=hh.toString() + ":" + (mm<10?"0":"") + mm.toString();

    //let newTime=moment(ee.toString(),'HH:mm:ss')
    //let oldTime=moment(previousduration.toString(),'HH:mm:ss')

    //let hours=newTime.diff(oldTime,'hours');
    //let minutes=newTime.diff(oldTime,'minutes');
    //console.log('Minutes difference',minutes)

    setDuration(e);
    let nextStartTime;

    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        nextStartTime = moment(el.startTime, "HH:mm:ss")
          .add(e, "minutes")
          .format("HH:mm");
        el.duration = e;
        el.endTime = nextStartTime;
      }
    });

    setAppointmentPanel(appointmentPanel);
    if (staffId) {
      const shift = await getStaffById(staffId);
      const nextStartTime = moment(startTime, "HH:mm:ss")
        .add(e, "minutes")
        .format("HH:mm");
      let valid = false;
      if (
        startTime >= shift[0].starttime &&
        nextStartTime.toString() <= shift[0].endtime
      ) {
        setStaffValid(true);
        appointmentPanel[i].staffValid = true;
        valid = true;
      } else {
        setStaffValid(false);
        appointmentPanel[i].staffValid = false;
        valid = false;
      }
      if (shift.length > 1 && !valid) {
        if (
          startTime >= shift[1].starttime &&
          nextStartTime.toString() <= shift[1].endtime
        ) {
          setStaffValid(true);
          appointmentPanel[i].staffValid = true;
        } else {
          setStaffValid(false);
          appointmentPanel[i].staffValid = false;
        }
      }
      update();
    }
  };

  const selectStaff = async (e, i) => {
    //console.log("This is e", e);
    setStaffId(e);
    appointmentPanel.forEach(async (el, j) => {
      if (i === j) {
        el.staffId = e;
      }
      await getServiceById(el.serviceId, i);
    });
    setAppointmentPanel(appointmentPanel);
    const shift = await getStaffById(e);
    let valid = false;
    await getServicesByCategory(categoryId, e, i);
    // const durationInMinutes =
    //   parseInt(duration) - parseInt(extraDurationBefore);
    // const StartTime = moment(startTime, 'HH:mm:ss')
    //   .subtract(parseInt(extraDurationBefore), 'minutes')
    //   .format('HH:mm');

    if (startTime >= shift[0].starttime && startTime <= shift[0].endtime) {
      setStaffValid(true);
      appointmentPanel[i].staffValid = true;
      valid = true;
    } else {
      setStaffValid(false);
      appointmentPanel[i].staffValid = false;
      valid = false;
    }
    if (shift.length > 1 && !valid) {
      if (startTime >= shift[1].starttime && startTime <= shift[1].endtime) {
        setStaffValid(true);
        appointmentPanel[i].staffValid = true;
      } else {
        setStaffValid(false);
        appointmentPanel[i].staffValid = false;
      }
    }
    update();
  };

  const selectService = async (e, i) => {
    if (e !== "Select Service") {
      const filterPack = services.find((item) => item._id === e);
      if (filterPack.serviceName) {
        //console.log("I am inside this if condition");
        appointmentPanel.forEach((el, j) => {
          if (i === j) {
            el.serviceId = filterPack._id;
          }
        });
        setAppointmentPanel(appointmentPanel);
        await getServiceById(e, i);
      } else if (filterPack.serviceid.length > 0) {
        const id = filterPack.serviceid[0]._id;
        appointmentPanel.forEach((el, j) => {
          if (i === j) {
            el.serviceId = id;
            el.startTime = startTime;
          }
        });
        setAppointmentPanel(appointmentPanel);
        await getServiceById(id, i);
        filterPack.serviceid.forEach(async (item, l) => {
          if (l > 0) {
            const du = appointmentPanel[l - 1].duration;
            const st = appointmentPanel[l - 1].endTime;
            setStartTime(st);
            const durationInMinutes =
              parseInt(du) + parseInt(extraDurationAfter);
            const nextStartTime = moment(st, "HH:mm:ss")
              .add(durationInMinutes, "minutes")
              .format("HH:mm");
            appointmentPanel.push({
              categories,
              categoryId,
              serviceId: item._id,
              services,
              staffId,
              startTime: nextStartTime,
              duration: "",
            });
            await getServiceById(item._id, l);
          }
        });
      }
    }
  };
  const selectCategory = async (e, i) => {
    setCategoryId(e);
    await getServicesByCategory(e, staffId, i);
  };

  const addMoreAppointmentPanel = async () => {
    let copyPanel = [...appointmentPanel];
    //console.log('This is the copyPanel',copyPanel);
    let nextstartTime;
    let endTime_;
    let extraduration;
    if (copyPanel[copyPanel.length - 1].endTime !== undefined) {
      endTime_ = copyPanel[copyPanel.length - 1].endTime;
    } else {
      endTime_ = copyPanel[copyPanel.length - 1].startTime;
    }
    if (
      copyPanel[copyPanel.length - 1].extradurationAfter !== null &&
      copyPanel[copyPanel.length - 1].extradurationAfter !== undefined
    ) {
      extraduration = copyPanel[copyPanel.length - 1].extradurationAfter;
      //console.log('Am I inside here')
    } else {
      extraduration = "5";
      //console.log('or Am I inside here')
    }
    //console.log('Thi is the endTime here',endTime_)
    //console.log('This is the extra duration for a particular',extraduration);
    nextstartTime = moment(endTime_, "HH:mm:ss")
      .add(extraduration, "minutes")
      .format("HH:mm");

    //console.log('This is the start Time',startTime);
    //console.log('staff Id',)
    //console.log('Duration',duration);
    //console.log('Extra Duration After',extraDurationAfter);
    setStaffId(staffId);

    const durationInMinutes = parseInt(duration) + parseInt(extraDurationAfter);
    const myduration = parseInt(extraDurationAfter);
    //console.log('This is where I am having a problem',durationInMinutes);
    const nextStartTime = moment(startTime, "HH:mm:ss")
      .add(durationInMinutes, "minutes")
      .format("HH:mm");

    setStartTime(nextstartTime);
    //console.log('nextStart time',nextStartTime);
    const oldArray = [...appointmentPanel];
    //console.log('This is the appointment panel',appointmentPanel)
    //console.log('This is the last array value in this case yeah',oldArray[-1]);
    //const endTime=oldArray[oldArray.length-1].endTime;
    let endTime;
    // eslint-disable-next-line
    let result;

    if (oldArray[oldArray.length - 1].endTime !== undefined) {
      endTime = oldArray[oldArray.length - 1].endTime;
      result = moment(endTime, "HH:mm:ss")
        .add(myduration, "minutes")
        .format("HH:mm");
      //console.log("This is the result", result);
    } else {
      //console.log("I am inside this else condition");
      result = nextStartTime;
    }
    //console.log('This is the end Time',endTime)
    oldArray.push({
      categories,
      categoryId: "",
      serviceId: "",
      staffId,
      startTime: nextstartTime,
      duration: 0,
      totalPrice: 0,
    });
    setAppointmentPanel(oldArray);
    //setDuration(5)
  };

  const searchCustomer = (e) => {
    setCustomers(customersCopy);
    setSearchKeyword(e);
    const filteredCustomers = customersCopy.filter(
      (customer) =>
        customer.firstname.toLowerCase().indexOf(e.toLowerCase()) !== -1
    );

    if (+e.length > 0) {
      setCustomers(filteredCustomers);
    } else {
      setCustomers([]);
    }
  };

  const handleBlock = async (id) => {
    const response = await partnerInstance().put(`/blockCustomer/${id}`);
    const { code, updatedData } = response.data;
    if (code === 200) {
      setIsBlock(updatedData.isBlock);
      setCustomerId(updatedData._id);
    }
  };
  const deleteAppointmentPanel = (i) => {
    // const oldArray = [...appointmentPanel];
    // oldArray.pop();
    // setAppointmentPanel([...oldArray]);
    const oldStatee = [...appointmentPanel];
    oldStatee.splice(i, 1);
    setAppointmentPanel(oldStatee);
  };
  // const handleCheckout = () => {
  //   history.push(`/partners/checkout?appointmentId=${ID}`);
  // };
  let total = 0;
  let priceTotal = 0;
  //console.log("Appointment Panel", appointmentPanel);
  return (
    <PartnerWrapper heading="New appointment">
      <div className="add-appointment-page appointment-view">
        <div className="container">
          <h2 className="admin-title">
            {/* New Appointment{" "} */}
            <Link to="/partners/calendar">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="add-appointment-content appointment-content">
            <Form onSubmit={addAppointment}>
              <div className="row">
                <div className="col-md-5">
                  <div className="appointment-card">
                    {/* CustomerSearch */}
                    {!customerId ? (
                      <div className="customer-search">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="customer-card">
                              <h4>Customers</h4>

                              <Form inline>
                                <FormControl
                                  type="text"
                                  placeholder="Search Customer"
                                  value={searchKeyword}
                                  onChange={(e) =>
                                    searchCustomer(e.target.value)
                                  }
                                />
                                <Button>
                                  <i className="fa fa-search" />
                                </Button>
                              </Form>
                              {errors.customerId && (
                                <p className="error">{errors.customerId}</p>
                              )}
                              <div className="search-container-user">
                                <Link
                                  to={`/partners/addcustomer?starttime=${startTime}&startDate=${startDate}&staffId=${staffId}`}
                                >
                                  <i className="fa fa-plus" /> Add new customer
                                </Link>
                                <div className="search-user-list">
                                  {customers &&
                                    customers.map((el) => (
                                      <>
                                        <div
                                          role="presentation"
                                          className="search-items"
                                          onClick={() =>
                                            selectCustomerId(el._id)
                                          }
                                        >
                                          <div className="search-icon">
                                            <img alt={user} src={user} />
                                          </div>
                                          <div className="search-content">
                                            <h4>
                                              {" "}
                                              {el.firstname}
                                              {el.lastname}
                                            </h4>
                                            <p>{el.email}</p>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </div>
                              <div className="no-customer">
                                <img alt={user} src={search} />
                                <p>
                                  Use the search to add a customers, or keep
                                  empty to save as walk-in.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="customer-detail">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="customer-card">
                              <div className="customer-detail-appoint">
                                <div className="customer-detail-icon">
                                  <img alt={user} src={user} />
                                </div>
                                <div className="customer-detail-content">
                                  <h4>
                                    {firstname} {lastname}
                                  </h4>
                                  <p>{email}</p>
                                </div>
                                <div className="customer-detail-action">
                                  <Dropdown className="appointment-action">
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <i
                                        className="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to={`/partners/editcustomer?customerid=${customerId}&starttime=${startTime}&startDate=${startDate}&staffId=${staffId}`}
                                        >
                                          Edit customer
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <div
                                          role="presentation"
                                          onClick={() =>
                                            handleBlock(customerId)
                                          }
                                        >
                                          {isBlock ? "Unblock" : "Block"}
                                        </div>
                                      </Dropdown.Item>

                                      <Dropdown.Item>
                                        <Link
                                          onClick={() =>
                                            removeCustomerId(customerId)
                                          }
                                          variant="danger"
                                        >
                                          Remove from appointment
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Customer Details */}

                    {/* Footer Buttons */}

                    <div className="appointment-buttons">
                      <span>
                        Total: {/* {totalPrice} */}
                        {appointmentPanel
                          ? appointmentPanel.forEach((el) => {
                              priceTotal += parseInt(el.totalPrice);
                            })
                          : ""}
                        {priceTotal > 0 ? parseInt(priceTotal) : "0"}
                        {appointmentPanel.forEach((el) => {
                          total += parseInt(el.duration);
                        })}
                        ({" "}
                        {parseInt(total) > 0 ? (
                          Math.floor(parseInt(total) / 60) === 0 ? (
                            <>
                              {parseInt(total) % 60}
                              min
                            </>
                          ) : (
                            <>
                              {Math.floor(parseInt(total) / 60)}
                              h, {parseInt(total) % 60}
                              min
                            </>
                          )
                        ) : (
                          "0h"
                        )}
                        )
                      </span>
                      <div className="footer-button">
                        <Button
                          className="border-btn"
                          id="border-btn"
                          disabled={viewCheckout}
                          type="submit"
                          value="1"
                          onClick={() => setButton("Express")}
                        >
                          Express Checkout
                        </Button>
                        <Button
                          className="btn-perple"
                          id="appointment-btn"
                          type="submit"
                          value="2"
                          onClick={() => setButton("Save")}
                        >
                          Save Appointment
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="appointment-card">
                    <div className="appointment-body">
                      <div className="add-appointment-form">
                        <h4>
                          {new Date(startDate).toString().substring(0, 15)}
                        </h4>
                        <Form>
                          {appointmentPanel.map((el, i, array) => (
                            <div className="pricing-option">
                              <div className="remove-pricing">
                                {/* <i
                              onClick={() => deleteAppointmentPanel(i)}
                              className="fa fa-times"
                            /> */}

                                {array.length > 1 && (
                                  <i
                                    role="presentation"
                                    onClick={() =>
                                      deleteAppointmentPanel(i, el._id)
                                    }
                                    className="fa fa-times"
                                  />
                                )}
                              </div>
                              <h5>Appointment</h5>
                              <div className="row">
                                <div className="col-md-12">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Category </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) =>
                                        selectCategory(e.target.value, i)
                                      }
                                      value={el.categoryId}
                                    >
                                      <option value="">Select Category</option>
                                      {el.categories &&
                                        el.categories.map((ele) => (
                                          <option value={ele._id}>
                                            {ele.categoryname}
                                          </option>
                                        ))}
                                    </Form.Control>

                                    {errors.categoryIndex === i && (
                                      <p className="error">
                                        {errors.categoryId}
                                      </p>
                                    )}
                                  </Form.Group>
                                </div>

                                <div className="col-md-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Start Time </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) =>
                                        selectStartTime(e.target.value, i)
                                      }
                                      value={el.startTime ? el.startTime : ""}
                                    >
                                      {timeData.map((elem) => (
                                        <option value={elem}>{elem}</option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </div>
                                <div className="col-md-8">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Service </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) =>
                                        selectService(e.target.value, i)
                                      }
                                      value={el.serviceId}
                                    >
                                      {el.services && el.services.length > 0 ? (
                                        <>
                                          {el.services &&
                                            el.services.map((ele, k) => (
                                              <>
                                                {k === 0 ? (
                                                  <option>
                                                    Select Service
                                                  </option>
                                                ) : (
                                                  ""
                                                )}

                                                <option value={ele._id}>
                                                  {ele.serviceName
                                                    ? ele.serviceName
                                                    : ele.packagename}
                                                </option>
                                              </>
                                            ))}
                                        </>
                                      ) : (
                                        <option>No Services</option>
                                      )}
                                    </Form.Control>
                                    {errors.serviceIndex === i && (
                                      <p className="error">
                                        {errors.serviceId}
                                      </p>
                                    )}
                                    {/* {errors.serviceId && (
                                      <p className="error">
                                        {errors.serviceId}
                                      </p>
                                    )} */}
                                  </Form.Group>
                                </div>
                                <div className="col-md-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Duration </Form.Label>
                                    {duration ? (
                                      <Form.Control
                                        as="select"
                                        onChange={(e) =>
                                          selectDuration(e.target.value, i)
                                        }
                                        value={el.duration}
                                      >
                                        <option value="5">5min</option>
                                        <option value="10">10min</option>
                                        <option value="15">15min</option>
                                        <option value="20">20min</option>
                                        <option value="25">25min</option>
                                        <option value="30">30min</option>
                                        <option value="35">35min</option>
                                        <option value="40">40min</option>
                                        <option value="45">45min</option>
                                        <option value="50">50min</option>
                                        <option value="55">55min</option>
                                        <option value="60" selected>
                                          1h
                                        </option>
                                        <option value="65">1h 5min</option>
                                        <option value="70">1h 10min</option>
                                        <option value="75">1h 15min</option>
                                        <option value="80">1h 20min</option>
                                        <option value="85">1h 25min</option>
                                        <option value="90">1h 30min</option>
                                        <option value="95">1h 35min</option>
                                        <option value="100">1h 40min</option>
                                        <option value="105">1h 45min</option>
                                        <option value="110">1h 50min</option>
                                        <option value="115">1h 55min</option>
                                        <option value="120">2h</option>
                                        <option value="135">2h 15min</option>
                                        <option value="150">2h 30min</option>
                                        <option value="165">2h 45min</option>
                                        <option value="180">3h</option>
                                        <option value="195">3h 15min</option>
                                        <option value="210">3h 30min</option>
                                        <option value="225">3h 45min</option>
                                        <option value="240">4h</option>
                                        <option value="255">4h 15min</option>

                                        <option value="270">4h 30min</option>
                                        <option value="285">4h 45min</option>
                                        <option value="300">5h</option>
                                        <option value="315">5h 15min</option>
                                        <option value="330">5h 30min</option>
                                        <option value="345">5h 45min</option>
                                        <option value="360">6h</option>
                                        <option value="375">6h 15min</option>
                                        <option value="390">6h 30min</option>
                                        <option value="405">6h 45min</option>
                                        <option value="420">7h</option>
                                        <option value="435">7h 15min</option>
                                        <option value="450">7h 30min</option>
                                        <option value="465">7h 45min</option>
                                        <option value="480">8h</option>
                                        <option value="495">8h 15min</option>
                                        <option value="510">8h 30min</option>
                                        <option value="525">8h 45min</option>
                                        <option value="540">9h</option>
                                        <option value="555">9h 15min</option>
                                        <option value="570">9h 30min</option>
                                        <option value="585">9h 45min</option>
                                        <option value="600">10h</option>
                                        <option value="615">10h 15min</option>
                                        <option value="630">10h 30min</option>
                                        <option value="645">10h 45min</option>
                                        <option value="660">11h</option>
                                        <option value="675">11h 15min</option>
                                        <option value="690">11h 30min</option>
                                        <option value="705">11h 45min</option>
                                        <option value="720">12h</option>
                                      </Form.Control>
                                    ) : (
                                      <Form.Control disabled as="select" />
                                    )}
                                  </Form.Group>
                                </div>
                                <div className="col-md-8">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Staff </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) =>
                                        selectStaff(e.target.value, i)
                                      }
                                      value={el.staffId}
                                    >
                                      {staffs.map((ele) => (
                                        <option value={ele._id}>
                                          {ele.firstname}
                                        </option>
                                      ))}
                                    </Form.Control>
                                    {errors.staffIndex === i && (
                                      <p className="error">{errors.staffId}</p>
                                    )}
                                    {el.staffValid === false ? (
                                      <p className="error">
                                        This staff is not working in this
                                        duration, Please select another staff or
                                        another duration
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </Form.Group>
                                </div>
                                <div className="col-md-12">
                                  <Form.Group controlId="formBasicloginone">
                                    <Form.Label>Appointment notes </Form.Label>
                                    <Form.Control
                                      placeholder="Add a short description"
                                      as="textarea"
                                      rows="3"
                                      onChange={(e) =>
                                        selectAppointmentNote(e.target.value, i)
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-md-12">
                                  {el.extradurationBefore !== undefined &&
                                    el.extradurationBefore !== null && (
                                      <p>
                                        {" "}
                                        {el.extradurationBefore} min processing
                                        time before the service{" "}
                                      </p>
                                    )}
                                </div>
                                <div className="col-md-12">
                                  {el.extradurationAfter !== undefined &&
                                    el.extradurationAfter !== null && (
                                      <p>
                                        {" "}
                                        {el.extradurationAfter} min processing
                                        time after the service{" "}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div
                            role="presentation"
                            className="add-pricing-option"
                            onClick={addMoreAppointmentPanel}
                          >
                            <span>
                              <i className="fa fa-plus" /> Add more appointment
                            </span>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default NewAppointment;
