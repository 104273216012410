import React from 'react';
import Helmet from 'react-helmet';
import './verify.css';

const Verify = () => (
  <div className="w-100">
    <Helmet>
      <body className="verify" />
    </Helmet>
    <div className="Verify-container">
      <div className="verify-content">
        <h1 className="loading"> Verifying</h1>
      </div>
    </div>

  </div>
);

export default Verify;
