/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, DropdownButton } from "react-bootstrap";
import moment from "moment";
import Pdf from "react-to-pdf";
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";
import close from "../../../assets/close.png";
import PartnerContext from "../../../context/context";
import { partnerInstance } from "../../../config/axios";

const ref = React.createRef();
const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const DailySales = ({
  appointments,
  totalServicess,
  totalAllServices,
  totalNoOfServices,
  totalRefundServices,
  totalPaymnentCollected,
  totalRefundValues,
  totalVouchers,
  totalAllVouchers,
  totalNoOfVouchers,
  totalRefundVouchers,
  allVouchers,
  paymentListing,
  allReddemAmount,
}) => {
  const [app, setApp] = useState([]);
  const [totalServices, setTotalServices] = useState(0);
  const [vouch, setVouch] = useState([]);
  const [totalNoOfServicess, setTotalNoServices] = useState(0);
  const [totalNoOfrefunds, setTotalNoRefunds] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [totalRefundCollected, setTotalRefundCollected] = useState(0);
  const [totalRefundVoucher, setTotalRefundVouchers] = useState(0);
  const [voucherSales, setVoucherSales] = useState(0);
  const [noOfVouchers, setNoVouchers] = useState(0);
  const [payments, setPayments] = useState([]);
  const [refundVouchers, setRefundVouchers] = useState(0);
  const [AllRedAmounts, setAllRedAmounts] = useState(0);
  const { username } = useContext(PartnerContext);
  const { currency } = username;
  let totalPayOut = 0;
  useEffect(() => {
    setApp(appointments);
    setPayments(paymentListing);
    setTotalServices(totalServicess);
    setTotalNoServices(totalNoOfServices);
    setTotalNoRefunds(totalRefundServices);
    setTotalRefundCollected(totalRefundValues);
    setTotalRefundVouchers(totalRefundVouchers);
    setVoucherSales(totalVouchers);
    setNoVouchers(totalNoOfVouchers);
    setVouch(allVouchers);
    setRefundVouchers(totalRefundVouchers);
    setAllRedAmounts(allReddemAmount);
    paymentListing.forEach((el) => {
      // eslint-disable-next-line
      setTotalPay((totalPayOut += el.totalCollected));
    });
  }, [
    appointments,
    totalServicess,
    totalAllServices,
    totalNoOfServices,
    totalRefundServices,
    totalPaymnentCollected,
    totalRefundValues,
    totalVouchers,
    totalAllVouchers,
    totalNoOfVouchers,
    totalRefundVouchers,
    allVouchers,
    paymentListing,
    allReddemAmount,
  ]);

  totalPayOut += allReddemAmount;

  return (
    <div className="daily-sales">
      <DailySalesFilter
        app={app}
        setVouch={setVouch}
        vouch={vouch}
        totalServicess={totalServicess}
        totalAllServices={totalAllServices}
        totalNoOfServices={totalNoOfServicess}
        setTotalServices={setTotalServices}
        voucherSales={voucherSales}
        setVoucherSales={setVoucherSales}
        setTotalNoServices={setTotalNoServices}
        totalNoOfrefunds={totalNoOfrefunds}
        setTotalNoRefunds={setTotalNoRefunds}
        totalPay={totalPay}
        setTotalPay={setTotalPay}
        totalRefundCollected={totalRefundCollected}
        setTotalRefundCollected={setTotalRefundCollected}
        noOfVouchers={noOfVouchers}
        setNoVouchers={setNoVouchers}
        refundVouchers={refundVouchers}
        setRefundVouchers={setRefundVouchers}
        setApp={setApp}
        payments={payments}
        setPayments={setPayments}
        AllRedAmounts={AllRedAmounts}
        totalServices={totalServices}
        totalPayOut={totalPayOut}
        setTotalRefundVouchers={setTotalRefundVouchers}
      />
      <div className="row" ref={ref}>
        <div className="col-md-6">
          <div className="revenue-body">
            <h4>Transaction Summary</h4>
            <div className="revenue-table">
              <Table responsive="lg">
                <thead>
                  <tr>
                    <th>Item type</th>
                    <th>Sales qty</th>
                    <th>Refund qty</th>
                    <th>Gross total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Services</td>
                    <td>{totalNoOfServicess}</td>
                    <td>{totalNoOfrefunds}</td>
                    <td>
                      {currency} {totalServices}
                    </td>
                  </tr>
                  <tr>
                    <td>Products</td>
                    <td>0</td>
                    <td>0</td>
                    <td> {currency} 0.00</td>
                  </tr>
                  <tr>
                    <td>Vouchers</td>
                    <td>{noOfVouchers}</td>
                    <td>{totalRefundVoucher}</td>
                    <td>
                      {" "}
                      {currency} {voucherSales}
                    </td>
                  </tr>
                  <tr>
                    <td>Paid Plans</td>
                    <td>0</td>
                    <td>0</td>
                    <td> {currency} 0.00</td>
                  </tr>
                  <tr>
                    <td>Late cancellation fees</td>
                    <td>0</td>
                    <td>0</td>
                    <td> {currency} 0.00</td>
                  </tr>
                  <tr>
                    <td>No show fees</td>
                    <td>0</td>
                    <td>0</td>
                    <td> {currency} 0.00</td>
                  </tr>
                  <tr className="bold-text">
                    <td>Total Sales</td>
                    <td>{totalNoOfServicess + noOfVouchers}</td>
                    <td>{totalNoOfrefunds + totalRefundVoucher}</td>
                    <td>
                      {currency} {totalServices + voucherSales}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="revenue-body">
            <h4>Cash Movement Summary</h4>
            <div className="revenue-table">
              <Table responsive="lg">
                <thead>
                  <tr>
                    <th>Payment type</th>
                    <th>Payments collected</th>
                    <th>Refunds paid</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((el) => {
                    return (
                      <>
                        <tr>
                          <td>{el.paymentname}</td>
                          <td>
                            {currency} {el.totalCollected}
                          </td>
                          <td>
                            {currency} {el.totalRefunded}
                          </td>
                        </tr>
                      </>
                    );
                  })}

                  <td>Voucher Redemptions</td>
                  <td>
                    {" "}
                    {currency} {AllRedAmounts}
                  </td>
                  <td> {currency} 0.00</td>

                  <tr className="bold-text">
                    <td>Payments collected</td>
                    <td>
                      {currency} {totalPay}
                    </td>
                    <td>
                      {currency} {totalRefundCollected}
                    </td>
                  </tr>
                  <tr className="bold-text">
                    <td>Of which tips</td>
                    <td> {currency} 0.00</td>
                    <td> {currency} 0.00</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailySales;

const DailySalesFilter = ({
  app,
  totalServices,
  payments,
  setPayments,
  setVoucherSales,
  totalNoOfServices,
  setTotalRefundCollected,
  setTotalServices,
  voucherSales,
  setTotalNoServices,
  totalNoOfrefunds,
  setRefundVouchers,
  refundVouchers,
  setTotalNoRefunds,
  totalPay,
  setTotalPay,
  vouch,
  setNoVouchers,
  noOfVouchers,
  AllRedAmounts,
  setTotalRefundVouchers,
}) => {
  const [sidebartoggle, setSidebarToggle] = useState(false);
  const [crrentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const csvData = [];
  const secData = [];

  // prevous data
  const previousData = (data) => {
    const old = [...app];
    const vOld = [...vouch];
    let voucher = 0;
    let service = 0;
    let total = 0;
    let refund = 0;
    const current = data;
    setCurrentDate(current);
    const filterVDailySales = vOld.filter(
      (el) => moment(el.create).format("YYYY-MM-DD") === current
    );
    const filterVRefundValues = filterVDailySales.filter((item) => item.refund);
    if (filterVDailySales.length > 0) {
      filterVDailySales.forEach(
        (item, i) => (
          setVoucherSales((voucher += +item.total)),
          setNoVouchers(filterVDailySales.length),
          payments.forEach((go, k) => {
            if (go.paymentname === item.paymentMethod) {
              go.paymentname, (go.totalCollected += +item.total);
              setTotalPay((total += +item.total));
            }
          })
        )
      );
      setPayments(payments);
    } else {
      setVoucherSales(0);
      setNoVouchers(0);
      setTotalPay(0);
      setRefundVouchers(0);
      payments.forEach((go, k) => {
        go.paymentname, (go.totalCollected = 0);
      });
    }

    if (filterVRefundValues.length > 0) {
      filterVRefundValues.forEach(
        (el, i) => (
          setVoucherSales((voucher -= +el.total)),
          setTotalRefundVouchers(filterVRefundValues.length),
          payments.forEach((go, k) => {
            if (go.paymentname === el.paymentMethod) {
              go.paymentname, (go.totalRefunded += +el.total);
              setTotalRefundCollected((refund += +el.total));
            }
          })
        )
      );
      setPayments(payments);
    } else {
      setTotalRefundCollected(0);
      setTotalRefundVouchers(0);
      setTotalNoRefunds(0);
      payments.forEach((go, k) => {
        go.paymentname, (go.totalRefunded = 0);
      });
    }

    const filterDailySales = old.filter(
      (el) => moment(el.create).format("YYYY-MM-DD") === current
    );
    const filterRefundValues = filterDailySales.filter((item) => item.invoice);
    if (filterDailySales.length > 0) {
      filterDailySales.forEach(
        (item, i) => (
          setTotalServices((service += +item.price)),
          setTotalNoServices(filterDailySales.length),
          payments.forEach((go, k) => {
            if (go.paymentname === item.paymentMethod) {
              go.paymentname, (go.totalCollected += +item.price);
              setTotalPay((total += +item.price));
            }
          })
        )
      );
      setPayments(payments);
    } else {
      setTotalServices(0);
      setTotalNoServices(0);
      setTotalNoRefunds(0);
      setTotalPay(0);
      payments.forEach((go, k) => {
        go.paymentname, (go.totalCollected = 0);
      });
      setPayments(payments);
    }

    if (filterRefundValues.length > 0) {
      filterRefundValues.forEach(
        (el, i) => (
          setTotalServices((service -= +el.price)),
          setTotalNoRefunds(filterRefundValues.length),
          payments.forEach((go, k) => {
            if (go.paymentname === el.paymentMethod) {
              go.paymentname, (go.totalRefunded += +el.price);
              setTotalRefundCollected((refund += +el.price));
            }
          })
        )
      );
    } else {
      setTotalRefundCollected(0);
      setTotalNoRefunds(0);
      payments.forEach((go, k) => {
        go.paymentname, (go.totalRefunded = 0);
      });
    }
  };

  csvData.push(
    {
      Item_type: "Services",
      Sales_qty: totalNoOfServices,
      Refund_qty: totalNoOfrefunds,
      Gross_total: totalServices,
    },
    {
      Item_type: "Products",
      Sales_qty: 0,
      Refund_qty: 0,
      Gross_total: 0,
    },
    {
      Item_type: "Vouchers",
      Sales_qty: noOfVouchers,
      Refund_qty: refundVouchers,
      Gross_total: voucherSales,
    },
    {
      Item_type: "Paid Plans",
      Sales_qty: 0,
      Refund_qty: 0,
      Gross_total: 0,
    },
    {
      Item_type: "Late cancellation fees",
      Sales_qty: 0,
      Refund_qty: 0,
      Gross_total: 0,
    },
    {
      Item_type: "No show fees",
      Sales_qty: 0,
      Refund_qty: 0,
      Gross_total: 0,
    },
    {
      Item_type: "Total Sales",
      Sales_qty: totalNoOfServices + noOfVouchers,
      Refund_qty: totalNoOfrefunds + refundVouchers,
      Gross_total: totalServices + voucherSales,
    }
  );

  const arr = [];
  payments.forEach((reff) => {
    const obj = {
      Payment_type: reff.paymentname,
      Payments_collected: reff.totalCollected,
      Refunds_paid: reff.totalRefunded,
    };
    arr.push(obj);
  }),
    secData.push(
      {
        Payment_type: "Voucher_Redemptions",
        Payments_collected: AllRedAmounts,
        Refunds_paid: 0,
      },
      {
        Payment_type: "Payments_collected",
        Payments_collected: totalPay,
        Refunds_paid: 0,
      },
      {
        Payment_type: "Of_which_tips",
        Payments_collected: 0,
        Refunds_paid: 0,
      }
    );

  const four = [...arr, ...secData];
  const AllData = [...csvData, ...four];

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(csvData[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };

  const camelCaseTwo = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumnsTwo = () => {
    const columns = Object.keys(four[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };

  const options = {
    orientation: "landscape",
    format: [1000, 1000],
  };

  return (
    <div className="dailysales-filter">
      <div className="revenue-filter">
        <div className="filter-left-block">
          <div className="schedule-date-toolbar">
            <div className="btn-group js-date-toolbar">
              <div
                className="btn btn-default navigate"
                data-action="previous"
                title="Previous"
                onClick={() =>
                  previousData(
                    moment(crrentDate).subtract(1, "days").format("YYYY-MM-DD")
                  )
                }
              >
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </div>
              <div
                className="btn btn-default hidden-xs navigate active"
                data-action="today"
                onClick={() =>
                  previousData(moment(new Date()).format("YYYY-MM-DD"))
                }
              >
                Today
              </div>
              <div className="btn btn-default select-date" title="Change Date">
                <span className="display-date">{crrentDate}</span>
                <Form.Control
                  type="date"
                  id="birthday"
                  max={moment(new Date()).format("YYYY-MM-DD")}
                  name="birthday"
                  onChange={(e) => previousData(e.target.value)}
                />
              </div>
              <div
                className={
                  crrentDate !== moment(new Date()).format("YYYY-MM-DD")
                    ? "btn btn-default navigate"
                    : "btn btn-default disable-cursor"
                }
                data-action="next"
                title="Next"
                onClick={() =>
                  previousData(
                    moment(crrentDate).add(1, "days").format("YYYY-MM-DD")
                  )
                }
              >
                <i className="fa fa-arrow-right" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>

        <div className="filter-right-block">
          <div className="header-buttons ">
            <DropdownButton
              className="dark-btn gbu6"
              id="dropdown-basic-button-export"
              title="Export"
            >
              <Pdf
                targetRef={ref}
                filename={`daily-sales${crrentDate}.pdf`}
                options={options}
              >
                {({ toPdf }) => <button onClick={toPdf}>PDF</button>}
              </Pdf>
              {csvData.length && payments.length > 0 && (
                <ExcelFile
                  filename={`daily-sales${crrentDate}`}
                  element={<button>Excel</button>}
                >
                  <ExcelSheet data={csvData} name="Transaction Summary">
                    {filterColumns(csvData).map((col) => (
                      <ExcelColumn label={camelCase(col)} value={col} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={four} name="Cash Movement Summary">
                    {filterColumnsTwo(four).map((col) => (
                      <ExcelColumn label={camelCaseTwo(col)} value={col} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
              )}
              <CSVLink data={AllData} filename={`daily-sales${crrentDate}.csv`}>
                CSV
              </CSVLink>
            </DropdownButton>
            <Button
              variant="default"
              onClick={() => setSidebarToggle(!sidebartoggle)}
            >
              Filter <i className="fa fa-sliders" />
            </Button>
          </div>
        </div>
      </div>

      <SidebarFilter
        sidebartoggle={sidebartoggle}
        setSidebarToggle={setSidebarToggle}
      />
    </div>
  );
};

const SidebarFilter = ({ sidebartoggle, setSidebarToggle }) => {
  const [locations, setLocations] = useState([]);
  const [loc, setLoc] = useState("");
  const getLocations = async () => {
    const locat = await partnerInstance().get("/getPartnerLocations");
    const { code, partnerData } = locat.data;
    if (code === 200) {
      setLocations(partnerData.partnerLocation);
    }
  };
  useEffect(() => {
    getLocations();
  }, []);

  const Close = () => {
    setLoc("All");
    setSidebarToggle(!sidebartoggle);
  };
  return (
    <div className={`sidebar-items ${sidebartoggle ? "sidebar-expand" : ""}`}>
      <div className="sidebar-title">
        <h2>Filters</h2>
        <span onClick={() => setSidebarToggle(!sidebartoggle)}>
          <img src={close} alt="close" />
        </span>
      </div>
      <Form>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Location</label>
              <select
                className="form-control"
                onChange={(e) => setLoc(e.target.value)}
                value={loc}
              >
                <option value="All">All</option>
                {locations.map((item) => (
                  <option value={item.locationName}>{item.locationName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-12 text-right filter-buttons">
            <Button onClick={Close} variant="default" className="btn-perple">
              Clear
            </Button>
            <Button
              variant="primary"
              onClick={() => setSidebarToggle(!sidebartoggle)}
              className="btn-perple"
            >
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
