import React, { useState, useContext } from "react";
import { Tabs, Tab, ProgressBar, Button } from "react-bootstrap";
import PartnerWrapper from "../partnerwrapper/wrapper";
import cross from "../../../assets/cross2.png";
import SignupDiscount from "./signupDiscount";
import history from "../../../config/history";
import SignupReferral from "./signupreferral";
import Helmet from "react-helmet";
import { StepCompletePrevent } from "./StepCompletePrevent";
import PartnerContext from "../../../context/context";

const SignupStep6 = () => {
  const { isStepComplete } = useContext(PartnerContext);
  StepCompletePrevent(isStepComplete);

  const [isFill, setIsFill] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedTab, setSelectedTab] = useState("discount");
  const [isReferral, setIsReferral] = useState(false);
  const Step5 = () => {
    history.push("/partners/payment");
  };
  const OnlineBooking = () => {
    console.log("PARTNER ::>>", isFill);
    setShow(true);
    if (isFill) {
      if (isReferral) {
        history.push("/partners/online-booking");
      } else {
        setSelectedTab("referral");
      }
    }
    if (isReferral) {
      if (!isFill) {
        setSelectedTab("discount");
      } else {
        history.push("/partners/online-booking");
      }
    }
  };
  const Cross = () => {
    history.push("/partners/intro");
  };
  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form taxes">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3> Discount & Referral</h3>
                    <div className="signup-progress">
                      <ProgressBar now={72} />
                    </div>
                  </div>
                  <div className="signup-body">
                    <div className="signup-location signup-taxes">
                      <Tabs
                        // defaultActiveKey="discount"
                        activeKey={selectedTab}
                        onSelect={(k) => setSelectedTab(k)}
                        transition={false}
                        id="noanim-tab-example"
                      >
                        <Tab eventKey="discount" title="Discount">
                          <SignupDiscount setIsFill={setIsFill} />
                          {!isFill && show ? (
                            <p className="error">Please Add discount</p>
                          ) : (
                            ""
                          )}
                        </Tab>
                        <Tab eventKey="referral" title="Referral">
                          <SignupReferral setIsReferral={setIsReferral} />
                          {!isReferral && show ? (
                            <p className="error">Please Add referral</p>
                          ) : (
                            ""
                          )}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
                {/* <div className="signup-right"> */}
                <div className="signup-footer-btn">
                  <Button type="submit" onClick={Step5}>
                    Back
                  </Button>
                  <Button type="submit" onClick={OnlineBooking}>
                    Next
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupStep6;
