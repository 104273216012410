import React, { useState } from "react";
import { Form, Card, ProgressBar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { server } from "../../config/keys";
import history from "../../config/history";
import store2 from "../../assets/no-image.jpg";
import { Helmet } from "react-helmet";

const EmailForm = () => {
  const { state } = useLocation();
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [value] = useState(state.value);
  const [total] = useState(state.total);
  const [voucherId] = useState(state.voucherId);
  const [locationName] = useState(state.locationName);
  const [currency] = useState(state.currency);
  const [country] = useState(state.country);
  const [storeImage] = useState(state.storeImage);
  const [companyName] = useState(state.companyName);
  const [firstname, setFirstName] = useState(state.firstname);
  const [lastname, setLastName] = useState(state.lastname);
  const [message, setMessage] = useState(state.message);
  const [email, setEmail] = useState(state.email);
  const [errors, setErrors] = useState({});
  const [servicessLength] = useState(state.servicessLength);
  const [retailPrice] = useState(state.retailPrice);
  const [quantity] = useState(state.quantity);
  const [voucherValue] = useState(state.voucherValue);
  const [rt] = useState(state.rt);
  console.log("RT", rt);

  const validateEm = (values) => {
    const errors = {};
    let isValid = true;
    if (!values.firstname) {
      errors.firstname = "Firstname is required";
      isValid = false;
    }
    if (!values.email) {
      errors.email = "Email is required";
      isValid = false;
    }

    return { isValid, errors };
  };

  const validateEmail = (e) => {
    e.preventDefault();
    const validation = validateEm({
      firstname,
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    history.push({
      pathname: `/paymentVoucher/${id}`,
      state: {
        value,
        total,
        currency,
        locationName,
        country,
        storeImage,
        companyName,
        voucherId,
        firstname,
        email,
        lastname,
        message,
        giftType: "email",
        servicessLength,
        retailPrice,
        quantity,
        voucherValue,
        rt,
      },
    });
  };

  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps" />
      </Helmet>
      <div className="voucher-detail new-voucher-detail">
        <div className="service-bg new-select-service bg-color">
          <div className="container">
            <h2 className="page-title">
              <p>
                <span>Step 3 | </span> Recipient's info
              </p>
              <Link
                className="btn"
                to={{
                  pathname: `/giftVoucher/${id}`,
                  state: {
                    value,
                    total,
                    voucherId,
                    currency,
                    locationName,
                    country,
                    storeImage,
                    companyName,
                    servicessLength,
                    retailPrice,
                    quantity,
                    voucherValue,
                    rt,
                  },
                }}
              >
                <i className="fa fa-angle-left" /> Back
              </Link>
            </h2>
            <div className="select-serve">
              <div className="store-services ">
                <div className="row">
                  <div className="col-md-4">
                    <div className="service-card">
                      <div className="box-details">
                        <Card>
                          <Card.Img
                            variant="top"
                            src={
                              storeImage ? `${server}/${storeImage}` : store2
                            }
                          />
                          <Card.Body>
                            <Card.Title className=" heading-bold">
                              {companyName}
                            </Card.Title>
                            <Card.Text className="">
                              {locationName},{country}
                            </Card.Text>
                            <div className="border-des">
                              <div className="left-scrollbar">
                                <div className="content-detail">
                                  <div className="product">
                                    <h6>Referral Voucher</h6>
                                    <span>{servicessLength} services</span>
                                  </div>
                                  <div className="rate">
                                    <span>
                                      {currency} {value}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="total-rate">
                              <h6>Total</h6>
                              <h6>
                                {currency} {total}
                              </h6>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="service-card">
                      <div className="stepwizard">
                        <ProgressBar now={75} />
                        <span className="stepwizard-steps">Step 3</span>
                      </div>
                      {/* <div className="stepwizard">
                        <ol className="stepwizard-panel">
                          <li className="stepwizard-step">Step 1</li>
                          <li className="stepwizard-step">Step 2</li>
                          <li className="current stepwizard-step">Step 3</li>
                          <li className="stepwizard-step">Ready to go!</li>
                        </ol>
                      </div> */}
                      <div className="email-form-section">
                        <h4 className="addional-info">Recipient's info</h4>
                        <Form onSubmit={validateEmail}>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="First name"
                                  value={firstname}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                                {errors.firstname && (
                                  <p className="error">{errors.firstname}</p>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-6">
                              <Form.Group controlId="formBasicPassword">
                                <Form.Label>Last name (Optional)</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Last Name"
                                  value={lastname}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-12">
                              <Form.Group controlId="formBasicPassword">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && (
                                  <p className="error">{errors.email}</p>
                                )}
                                <Form.Text className="text-muted">
                                  The voucher will be sent to this email
                                  address.
                                </Form.Text>
                              </Form.Group>
                            </div>
                            <div className="col-md-12">
                              <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>
                                  Personalised message (Optional)
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="continue-btn text-right">
                            <button
                              type="submit"
                              className="btn btn-primary l-btn"
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmailForm;
