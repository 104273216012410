export const countries = [
  {
    COUNTRY: 'AFGHANISTAN',
    COUNTRY_CODE: 'AFG',
    CURRENCY_CODE: 'AFA',
    NAME_OF_CURRENCY: 'Afghani',
  },
  {
    COUNTRY: 'ALBANIA',
    COUNTRY_CODE: 'ALB',
    CURRENCY_CODE: 'ALL',
    NAME_OF_CURRENCY: 'Lek',
  },
  {
    COUNTRY: 'ALGERIA',
    COUNTRY_CODE: 'DZA',
    CURRENCY_CODE: 'DZD',
    NAME_OF_CURRENCY: 'Dinar',
  },
  {
    COUNTRY: 'AMERICAN SAMOA',
    COUNTRY_CODE: 'ASM',
    CURRENCY_CODE: 'USD / ESP',
    NAME_OF_CURRENCY: 'Dollar / Peseta',
  },
  {
    COUNTRY: 'ANDORRA',
    COUNTRY_CODE: 'AND',
    CURRENCY_CODE: 'FAF / ADP',
    NAME_OF_CURRENCY: 'Franc / Peseta',
  },
  {
    COUNTRY: 'ANGOLA',
    COUNTRY_CODE: 'AGO',
    CURRENCY_CODE: 'AON',
    NAME_OF_CURRENCY: 'New Kwanza',
  },
  {
    COUNTRY: 'ANGUILLA',
    COUNTRY_CODE: 'AIA',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'ANTARCTICA',
    COUNTRY_CODE: 'ATA',
    CURRENCY_CODE: '-',
    NAME_OF_CURRENCY: '-',
  },
  {
    COUNTRY: 'ANTIGUA AND BARBUDA',
    COUNTRY_CODE: 'ATG',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'ARGENTINA',
    COUNTRY_CODE: 'ARG',
    CURRENCY_CODE: 'ARS',
    NAME_OF_CURRENCY: 'Peso',
  },
  {
    COUNTRY: 'ARMENIA',
    COUNTRY_CODE: 'ARM',
    CURRENCY_CODE: 'AMD',
    NAME_OF_CURRENCY: 'Dram',
  },
  {
    COUNTRY: 'ARUBA',
    COUNTRY_CODE: 'ABW',
    CURRENCY_CODE: 'AWG',
    NAME_OF_CURRENCY: 'Florin',
  },
  {
    COUNTRY: 'AUSTRALIA',
    COUNTRY_CODE: 'AUS',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },
  {
    COUNTRY: 'AUSTRIA',
    COUNTRY_CODE: 'AUT',
    CURRENCY_CODE: 'ATS',
    NAME_OF_CURRENCY: 'Schilling',
  },
  {
    COUNTRY: 'AZERBAIJAN',
    COUNTRY_CODE: 'AZE',
    CURRENCY_CODE: 'AZM',
    NAME_OF_CURRENCY: 'Manet',
  },

  {
    COUNTRY: 'BAHAMAS',
    COUNTRY_CODE: 'BHS',
    CURRENCY_CODE: 'BSD',
    NAME_OF_CURRENCY: 'Bahamas Dollar',
  },
  {
    COUNTRY: 'BAHRAIN',
    COUNTRY_CODE: 'BHR',
    CURRENCY_CODE: 'BHD',
    NAME_OF_CURRENCY: 'Dinar',
  },
  {
    COUNTRY: 'BANGLADESH',
    COUNTRY_CODE: 'BGD',
    CURRENCY_CODE: 'BDT',
    NAME_OF_CURRENCY: 'Taka',
  },
  {
    COUNTRY: 'BARBADOS',
    COUNTRY_CODE: 'BRB',
    CURRENCY_CODE: 'BBD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'BELARUS',
    COUNTRY_CODE: 'BLR',
    CURRENCY_CODE: 'BYB',
    NAME_OF_CURRENCY: 'Belarussian Rouble',
  },
  {
    COUNTRY: 'BELGIUM',
    COUNTRY_CODE: 'BEL',
    CURRENCY_CODE: 'BEF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'BELIZE',
    COUNTRY_CODE: 'BLZ',
    CURRENCY_CODE: 'BZD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'BENIN',
    COUNTRY_CODE: 'BEN',
    CURRENCY_CODE: 'XOF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'BERMUDA',
    COUNTRY_CODE: 'BMU',
    CURRENCY_CODE: 'BMD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'BHUTAN',
    COUNTRY_CODE: 'BTN',
    CURRENCY_CODE: 'INR / BTN',
    NAME_OF_CURRENCY: 'Ngultrum / Ind. Rupee',
  },
  {
    COUNTRY: 'BOLIVIA',
    COUNTRY_CODE: 'BOL',
    CURRENCY_CODE: 'BOB',
    NAME_OF_CURRENCY: 'Boliviano',
  },
  {
    COUNTRY: 'BOSNIA AND HERZEGOWINA',
    COUNTRY_CODE: 'BIH',
    CURRENCY_CODE: '-',
    NAME_OF_CURRENCY: '-',
  },
  {
    COUNTRY: 'BOTSWANA',
    COUNTRY_CODE: 'BWA',
    CURRENCY_CODE: 'BWP',
    NAME_OF_CURRENCY: 'Pula',
  },
  {
    COUNTRY: 'BOUVET ISLAND',
    COUNTRY_CODE: 'BVT',
    CURRENCY_CODE: 'NOK',
    NAME_OF_CURRENCY: 'Norwegian Krone',
  },
  {
    COUNTRY: 'BRAZIL',
    COUNTRY_CODE: 'BRA',
    CURRENCY_CODE: 'BRL',
    NAME_OF_CURRENCY: 'Real',
  },
  {
    COUNTRY: 'BRIT. INDIAN OCEAN TERRITORY',
    COUNTRY_CODE: 'IOT',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'BRUNEI DARUSSALAM',
    COUNTRY_CODE: 'BRN',
    CURRENCY_CODE: 'BND',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'BULGARIA',
    COUNTRY_CODE: 'BGR',
    CURRENCY_CODE: 'BGL',
    NAME_OF_CURRENCY: 'Lev',
  },
  {
    COUNTRY: 'BURKINA FASO',
    COUNTRY_CODE: 'BFA',
    CURRENCY_CODE: 'XOF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'BURUNDI',
    COUNTRY_CODE: 'BDI',
    CURRENCY_CODE: 'BIF',
    NAME_OF_CURRENCY: 'Franc',
  },

  {
    COUNTRY: 'CAMBODIA',
    COUNTRY_CODE: 'KHM',
    CURRENCY_CODE: 'KHR',
    NAME_OF_CURRENCY: 'Riel',
  },
  {
    COUNTRY: 'CAMEROON',
    COUNTRY_CODE: 'CMR',
    CURRENCY_CODE: 'XAF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'CANADA',
    COUNTRY_CODE: 'CAN',
    CURRENCY_CODE: 'CAD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'CAPE VERDE',
    COUNTRY_CODE: 'CPV',
    CURRENCY_CODE: 'CVE',
    NAME_OF_CURRENCY: 'Escudo',
  },
  {
    COUNTRY: 'CAYMAN ISLANDS',
    COUNTRY_CODE: 'CYM',
    CURRENCY_CODE: 'CYD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'CENTRAL AFRICAN REPUBLIC',
    COUNTRY_CODE: 'CAF',
    CURRENCY_CODE: 'XAF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'CHAD',
    COUNTRY_CODE: 'TCD',
    CURRENCY_CODE: 'XAF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'CHILE',
    COUNTRY_CODE: 'CHL',
    CURRENCY_CODE: 'CLP',
    NAME_OF_CURRENCY: 'Peso',
  },
  {
    COUNTRY: 'CHINA',
    COUNTRY_CODE: 'CHN',
    CURRENCY_CODE: 'CNY',
    NAME_OF_CURRENCY: 'Renminbi Yuan',
  },
  {
    COUNTRY: 'CHRISTMAS ISLAND',
    COUNTRY_CODE: 'CXR',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },
  {
    COUNTRY: 'COCOS (KEELING) ISLANDS',
    COUNTRY_CODE: 'CCK',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },
  {
    COUNTRY: 'COLOMBIA',
    COUNTRY_CODE: 'COL',
    CURRENCY_CODE: 'COP',
    NAME_OF_CURRENCY: 'Peso',
  },
  {
    COUNTRY: 'COMOROS',
    COUNTRY_CODE: 'COM',
    CURRENCY_CODE: 'KMF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'CONGO',
    COUNTRY_CODE: 'COG',
    CURRENCY_CODE: 'XAF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'COOK ISLANDS',
    COUNTRY_CODE: 'COK',
    CURRENCY_CODE: 'NZD',
    NAME_OF_CURRENCY: 'N.Z. Dollar',
  },
  {
    COUNTRY: 'COSTA RICA',
    COUNTRY_CODE: 'CRI',
    CURRENCY_CODE: 'CRC',
    NAME_OF_CURRENCY: 'Colon',
  },
  {
    COUNTRY: "COTE D'IVOIRE",
    COUNTRY_CODE: 'CIV',
    CURRENCY_CODE: 'XOF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'CROATIA',
    COUNTRY_CODE: 'HRV',
    CURRENCY_CODE: 'HRK',
    NAME_OF_CURRENCY: 'Kuna',
  },
  {
    COUNTRY: 'CUBA',
    COUNTRY_CODE: 'CUB',
    CURRENCY_CODE: 'CUP',
    NAME_OF_CURRENCY: 'Peso',
  },
  {
    COUNTRY: 'CYPRUS',
    COUNTRY_CODE: 'CYP',
    CURRENCY_CODE: 'CYP',
    NAME_OF_CURRENCY: 'Pound',
  },
  {
    COUNTRY: 'CZECH REPUBLIC',
    COUNTRY_CODE: 'CZE',
    CURRENCY_CODE: 'CZK',
    NAME_OF_CURRENCY: 'Koruna',
  },

  {
    COUNTRY: 'DENMARK',
    COUNTRY_CODE: 'DNK',
    CURRENCY_CODE: 'DKK',
    NAME_OF_CURRENCY: 'Danish Krone',
  },
  {
    COUNTRY: 'DJIBOUTI',
    COUNTRY_CODE: 'DJI',
    CURRENCY_CODE: 'DJF',
    NAME_OF_CURRENCY: 'Djibouti Franc',
  },
  {
    COUNTRY: 'DOMINICA',
    COUNTRY_CODE: 'DMA',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'DOMINICAN REPUBLIC',
    COUNTRY_CODE: 'DOM',
    CURRENCY_CODE: 'DOP',
    NAME_OF_CURRENCY: 'Peso',
  },

  {
    COUNTRY: 'EAST TIMOR',
    COUNTRY_CODE: 'TMP',
    CURRENCY_CODE: 'TPE / IDR',
    NAME_OF_CURRENCY: 'Timor Escudo/Indon. Rupiah',
  },
  {
    COUNTRY: 'ECUADOR',
    COUNTRY_CODE: 'ECU',
    CURRENCY_CODE: 'ECS',
    NAME_OF_CURRENCY: 'Sucre',
  },
  {
    COUNTRY: 'EGYPT',
    COUNTRY_CODE: 'EGY',
    CURRENCY_CODE: 'EGP',
    NAME_OF_CURRENCY: 'Pound',
  },
  {
    COUNTRY: 'EL SALVADOR',
    COUNTRY_CODE: 'SLV',
    CURRENCY_CODE: 'SVC',
    NAME_OF_CURRENCY: 'Colon',
  },
  {
    COUNTRY: 'EQUATORIAL GUINEA',
    COUNTRY_CODE: 'GNQ',
    CURRENCY_CODE: 'XAF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'ERITREA',
    COUNTRY_CODE: 'ERI',
    CURRENCY_CODE: '-',
    NAME_OF_CURRENCY: '-',
  },
  {
    COUNTRY: 'ESTONIA',
    COUNTRY_CODE: 'EST',
    CURRENCY_CODE: 'EEK',
    NAME_OF_CURRENCY: 'Kroon',
  },
  {
    COUNTRY: 'ETHIOPIA',
    COUNTRY_CODE: 'ETH',
    CURRENCY_CODE: 'ETB',
    NAME_OF_CURRENCY: 'Birr',
  },

  {
    COUNTRY: 'FALKLAND ISLANDS (MALVINAS)',
    COUNTRY_CODE: 'FLK',
    CURRENCY_CODE: 'FKP',
    NAME_OF_CURRENCY: 'Pound',
  },
  {
    COUNTRY: 'FAEROE ISLANDS',
    COUNTRY_CODE: 'FRO',
    CURRENCY_CODE: 'DKK',
    NAME_OF_CURRENCY: 'Danish Krone',
  },
  {
    COUNTRY: 'FIJI',
    COUNTRY_CODE: 'FJI',
    CURRENCY_CODE: 'FJD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'FINLAND',
    COUNTRY_CODE: 'FIN',
    CURRENCY_CODE: 'FIM',
    NAME_OF_CURRENCY: 'Markka',
  },
  {
    COUNTRY: 'FRANCE',
    COUNTRY_CODE: 'FRA',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'FRENCH GUIANA',
    COUNTRY_CODE: 'GUF',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'FRENCH POLYNESIA',
    COUNTRY_CODE: 'PYF',
    CURRENCY_CODE: 'XPF',
    NAME_OF_CURRENCY: 'C.F.P. Franc',
  },
  {
    COUNTRY: 'FRENCH SOUTHERN TERRITOR.',
    COUNTRY_CODE: 'ATF',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },

  {
    COUNTRY: 'GABON',
    COUNTRY_CODE: 'GAB',
    CURRENCY_CODE: 'XAF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'GAMBIA',
    COUNTRY_CODE: 'GMB',
    CURRENCY_CODE: 'GMD',
    NAME_OF_CURRENCY: 'Dalasi',
  },
  {
    COUNTRY: 'GEORGIA',
    COUNTRY_CODE: 'GEO',
    CURRENCY_CODE: 'GEL',
    NAME_OF_CURRENCY: 'Lari (1)',
  },
  {
    COUNTRY: 'GERMANY',
    COUNTRY_CODE: 'DEU',
    CURRENCY_CODE: 'DEM',
    NAME_OF_CURRENCY: 'Mark',
  },
  {
    COUNTRY: 'GHANA',
    COUNTRY_CODE: 'GHA',
    CURRENCY_CODE: 'GHC',
    NAME_OF_CURRENCY: 'Cedi',
  },
  {
    COUNTRY: 'GIBRALTAR',
    COUNTRY_CODE: 'GIB',
    CURRENCY_CODE: 'GIP',
    NAME_OF_CURRENCY: 'Pound',
  },
  {
    COUNTRY: 'GREECE',
    COUNTRY_CODE: 'GRC',
    CURRENCY_CODE: 'GRD',
    NAME_OF_CURRENCY: 'Drachma',
  },
  {
    COUNTRY: 'GREENLAND',
    COUNTRY_CODE: 'GRL',
    CURRENCY_CODE: 'DKK',
    NAME_OF_CURRENCY: 'Danish Krone',
  },
  {
    COUNTRY: 'GRENADA',
    COUNTRY_CODE: 'GRD',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'GUADELOUPE',
    COUNTRY_CODE: 'GLP',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'GUAM',
    COUNTRY_CODE: 'GUM',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'GUATEMALA',
    COUNTRY_CODE: 'GTM',
    CURRENCY_CODE: 'GTQ',
    NAME_OF_CURRENCY: 'Quetzal',
  },
  {
    COUNTRY: 'GUINEA',
    COUNTRY_CODE: 'GIN',
    CURRENCY_CODE: 'GNF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'GUINEA-BISSAU',
    COUNTRY_CODE: 'GNB',
    CURRENCY_CODE: 'GWP',
    NAME_OF_CURRENCY: 'Peso',
  },
  {
    COUNTRY: 'GUYANA',
    COUNTRY_CODE: 'GUY',
    CURRENCY_CODE: 'GYD',
    NAME_OF_CURRENCY: 'Guyana Dollar',
  },

  {
    COUNTRY: 'HAITI',
    COUNTRY_CODE: 'HTI',
    CURRENCY_CODE: 'HTG / USD',
    NAME_OF_CURRENCY: 'Gourde / Dollar',
  },
  {
    COUNTRY: 'HEARD AND MC DONALD ISLANDS',
    COUNTRY_CODE: 'HMD',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },
  {
    COUNTRY: 'HONDURAS',
    COUNTRY_CODE: 'HND',
    CURRENCY_CODE: 'HNL',
    NAME_OF_CURRENCY: 'Lempira',
  },
  {
    COUNTRY: 'HONG KONG',
    COUNTRY_CODE: 'HKG',
    CURRENCY_CODE: 'HKD',
    NAME_OF_CURRENCY: 'Hong Kong Dollar',
  },
  {
    COUNTRY: 'HUNGARY',
    COUNTRY_CODE: 'HUN',
    CURRENCY_CODE: 'HUF',
    NAME_OF_CURRENCY: 'Forint',
  },

  {
    COUNTRY: 'ICELAND',
    COUNTRY_CODE: 'ISL',
    CURRENCY_CODE: 'ISK',
    NAME_OF_CURRENCY: 'Krona',
  },
  {
    COUNTRY: 'INDIA',
    COUNTRY_CODE: 'IND',
    CURRENCY_CODE: 'INR',
    NAME_OF_CURRENCY: 'Rupee',
  },
  {
    COUNTRY: 'INDONESIA',
    COUNTRY_CODE: 'IDN',
    CURRENCY_CODE: 'IDR',
    NAME_OF_CURRENCY: 'Rupiah',
  },
  {
    COUNTRY: 'IRAN',
    COUNTRY_CODE: 'IRN',
    CURRENCY_CODE: 'IRR',
    NAME_OF_CURRENCY: 'Rial',
  },
  {
    COUNTRY: 'IRAQ',
    COUNTRY_CODE: 'IRQ',
    CURRENCY_CODE: 'IQD',
    NAME_OF_CURRENCY: 'Dinar',
  },
  {
    COUNTRY: 'IRELAND',
    COUNTRY_CODE: 'IRL',
    CURRENCY_CODE: 'IRP',
    NAME_OF_CURRENCY: 'Punt',
  },
  {
    COUNTRY: 'ISRAEL',
    COUNTRY_CODE: 'ISR',
    CURRENCY_CODE: 'ILS',
    NAME_OF_CURRENCY: 'New Shekel',
  },
  {
    COUNTRY: 'ITALY',
    COUNTRY_CODE: 'ITA',
    CURRENCY_CODE: 'ITL',
    NAME_OF_CURRENCY: 'Lira',
  },

  {
    COUNTRY: 'JAMAICA',
    COUNTRY_CODE: 'JAM',
    CURRENCY_CODE: 'JMD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'JAPAN',
    COUNTRY_CODE: 'JPN',
    CURRENCY_CODE: 'JPY',
    NAME_OF_CURRENCY: 'Yen',
  },
  {
    COUNTRY: 'JORDAN',
    COUNTRY_CODE: 'JOR',
    CURRENCY_CODE: 'JOD',
    NAME_OF_CURRENCY: 'Dinar',
  },

  {
    COUNTRY: 'KAZAKHSTAN',
    COUNTRY_CODE: 'KAZ',
    CURRENCY_CODE: 'KZT',
    NAME_OF_CURRENCY: 'Tongo',
  },
  {
    COUNTRY: 'KENYA',
    COUNTRY_CODE: 'KEN',
    CURRENCY_CODE: 'KES',
    NAME_OF_CURRENCY: 'Shilling',
  },
  {
    COUNTRY: 'KIRIBATI',
    COUNTRY_CODE: 'KIR',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },
  {
    COUNTRY: "KOREA, DEM. PEOPLE'S REP. OF",
    COUNTRY_CODE: 'PRK',
    CURRENCY_CODE: 'KPW',
    NAME_OF_CURRENCY: 'Won',
  },
  {
    COUNTRY: 'KOREA, REPUBLIC OF',
    COUNTRY_CODE: 'KOR',
    CURRENCY_CODE: 'KRW',
    NAME_OF_CURRENCY: 'Won',
  },
  {
    COUNTRY: 'KUWAIT',
    COUNTRY_CODE: 'KWT',
    CURRENCY_CODE: 'KWD',
    NAME_OF_CURRENCY: 'Dinar',
  },
  {
    COUNTRY: 'KYRGYZSTAN',
    COUNTRY_CODE: 'KGZ',
    CURRENCY_CODE: 'KGS',
    NAME_OF_CURRENCY: 'Som',
  },

  {
    COUNTRY: "LAO PEOPLE'S DEMOCRATIC REP.",
    COUNTRY_CODE: 'LAO',
    CURRENCY_CODE: 'LAK',
    NAME_OF_CURRENCY: 'Kip',
  },
  {
    COUNTRY: 'LATVIA',
    COUNTRY_CODE: 'LVA',
    CURRENCY_CODE: 'LVL / LVR',
    NAME_OF_CURRENCY: 'Lat / Latv. Rouble',
  },
  {
    COUNTRY: 'LEBANON',
    COUNTRY_CODE: 'LBN',
    CURRENCY_CODE: 'LBP',
    NAME_OF_CURRENCY: 'Pound',
  },
  {
    COUNTRY: 'LESOTHO',
    COUNTRY_CODE: 'LSO',
    CURRENCY_CODE: 'ZAR / ZAL',
    NAME_OF_CURRENCY: 'Rand / Loti',
  },
  {
    COUNTRY: 'LIBERIA',
    COUNTRY_CODE: 'LBR',
    CURRENCY_CODE: 'LBD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'LIBYAN ARAB JAMAHIRIYA',
    COUNTRY_CODE: 'LBY',
    CURRENCY_CODE: 'LYD',
    NAME_OF_CURRENCY: 'Dinar',
  },
  {
    COUNTRY: 'LIECHTENSTEIN',
    COUNTRY_CODE: 'LIE',
    CURRENCY_CODE: 'CHF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'LITHUANIA',
    COUNTRY_CODE: 'LTU',
    CURRENCY_CODE: 'LTL',
    NAME_OF_CURRENCY: 'Litas',
  },
  {
    COUNTRY: 'LUXEMBOURG',
    COUNTRY_CODE: 'LUX',
    CURRENCY_CODE: 'LUF',
    NAME_OF_CURRENCY: 'Lux. Franc',
  },

  {
    COUNTRY: 'MACAU',
    COUNTRY_CODE: 'MAC',
    CURRENCY_CODE: 'MOD',
    NAME_OF_CURRENCY: 'Pataca',
  },
  {
    COUNTRY: 'MACEDONIA',
    COUNTRY_CODE: 'MKD',
    CURRENCY_CODE: 'MKD',
    NAME_OF_CURRENCY: 'Macedonia Denar',
  },
  {
    COUNTRY: 'MADAGASCAR',
    COUNTRY_CODE: 'MDG',
    CURRENCY_CODE: 'MGF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'MALAWI',
    COUNTRY_CODE: 'MWI',
    CURRENCY_CODE: 'MWK',
    NAME_OF_CURRENCY: 'Kwacha',
  },
  {
    COUNTRY: 'MALAYSIA',
    COUNTRY_CODE: 'MYS',
    CURRENCY_CODE: 'MYR',
    NAME_OF_CURRENCY: 'Ringgit',
  },
  {
    COUNTRY: 'MALDIVES',
    COUNTRY_CODE: 'MDV',
    CURRENCY_CODE: 'MVR',
    NAME_OF_CURRENCY: 'Rufiyaa',
  },
  {
    COUNTRY: 'MALI',
    COUNTRY_CODE: 'MLI',
    CURRENCY_CODE: 'XOF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'MALTA',
    COUNTRY_CODE: 'MLT',
    CURRENCY_CODE: 'MTL',
    NAME_OF_CURRENCY: 'Lira',
  },
  {
    COUNTRY: 'MARSHALL ISLANDS',
    COUNTRY_CODE: 'MHL',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'MARTINIQUE',
    COUNTRY_CODE: 'MTQ',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'MAURITANIA',
    COUNTRY_CODE: 'MRT',
    CURRENCY_CODE: 'MRO',
    NAME_OF_CURRENCY: 'Ouguiya',
  },
  {
    COUNTRY: 'MAURITIUS',
    COUNTRY_CODE: 'MUS',
    CURRENCY_CODE: 'MUR',
    NAME_OF_CURRENCY: 'Rupee',
  },
  {
    COUNTRY: 'MEXICO',
    COUNTRY_CODE: 'MEX',
    CURRENCY_CODE: 'MXN',
    NAME_OF_CURRENCY: 'New Peso',
  },
  {
    COUNTRY: 'MICRONESIA  (FED. STATES OF)',
    COUNTRY_CODE: 'FSM',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'MOLDOVA, REPUBLIC OF',
    COUNTRY_CODE: 'MDA',
    CURRENCY_CODE: 'MDL',
    NAME_OF_CURRENCY: 'Lou',
  },
  {
    COUNTRY: 'MONACO',
    COUNTRY_CODE: 'MCO',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'MONGOLIA',
    COUNTRY_CODE: 'MNG',
    CURRENCY_CODE: 'MNT',
    NAME_OF_CURRENCY: 'Tugrik',
  },
  {
    COUNTRY: 'MONTSERRAT',
    COUNTRY_CODE: 'MSR',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'MOROCCO',
    COUNTRY_CODE: 'MAR',
    CURRENCY_CODE: 'MAD',
    NAME_OF_CURRENCY: 'Dirham',
  },
  {
    COUNTRY: 'MOZAMBIQUE',
    COUNTRY_CODE: 'MOZ',
    CURRENCY_CODE: 'MZM',
    NAME_OF_CURRENCY: 'Metical',
  },
  {
    COUNTRY: 'MYANMAR',
    COUNTRY_CODE: 'MMR',
    CURRENCY_CODE: 'MMK',
    NAME_OF_CURRENCY: 'Kyat',
  },

  {
    COUNTRY: 'NAMIBIA',
    COUNTRY_CODE: 'NAM',
    CURRENCY_CODE: 'ZAR / NAD',
    NAME_OF_CURRENCY: 'Rand / Nam. Dollar',
  },
  {
    COUNTRY: 'NAURU',
    COUNTRY_CODE: 'NRU',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },
  {
    COUNTRY: 'NEPAL',
    COUNTRY_CODE: 'NPL',
    CURRENCY_CODE: 'NPR',
    NAME_OF_CURRENCY: 'Rupee',
  },
  {
    COUNTRY: 'NETHERLANDS',
    COUNTRY_CODE: 'NLD',
    CURRENCY_CODE: 'NLG',
    NAME_OF_CURRENCY: 'Guilder',
  },
  {
    COUNTRY: 'NETHERLANDS ANTILLES',
    COUNTRY_CODE: 'ANT',
    CURRENCY_CODE: 'ANG',
    NAME_OF_CURRENCY: 'Guilder',
  },
  {
    COUNTRY: 'NEW CALEDONIA',
    COUNTRY_CODE: 'NCL',
    CURRENCY_CODE: 'XPF',
    NAME_OF_CURRENCY: 'C.F.P. Franc',
  },
  {
    COUNTRY: 'NEW ZEALAND',
    COUNTRY_CODE: 'NZL',
    CURRENCY_CODE: 'NZD',
    NAME_OF_CURRENCY: 'N.Z. Dollar',
  },
  {
    COUNTRY: 'NICARAGUA',
    COUNTRY_CODE: 'NIC',
    CURRENCY_CODE: 'NIO',
    NAME_OF_CURRENCY: 'Gold Cordoba',
  },
  {
    COUNTRY: 'NIGER',
    COUNTRY_CODE: 'NER',
    CURRENCY_CODE: 'XOF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'NIGERIA',
    COUNTRY_CODE: 'NGA',
    CURRENCY_CODE: 'NGN',
    NAME_OF_CURRENCY: 'Naira',
  },
  {
    COUNTRY: 'NIUE',
    COUNTRY_CODE: 'NIU',
    CURRENCY_CODE: 'NZD',
    NAME_OF_CURRENCY: 'N.Z. Dollar',
  },
  {
    COUNTRY: 'NORFOLK ISLAND',
    COUNTRY_CODE: 'NFK',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },
  {
    COUNTRY: 'NORTHERN MARIANA ISLANDS',
    COUNTRY_CODE: 'MNP',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'NORWAY',
    COUNTRY_CODE: 'NOR',
    CURRENCY_CODE: 'NOK',
    NAME_OF_CURRENCY: 'Norwegian Krone',
  },

  {
    COUNTRY: 'OMAN',
    COUNTRY_CODE: 'OMN',
    CURRENCY_CODE: 'OMR',
    NAME_OF_CURRENCY: 'Rial',
  },

  {
    COUNTRY: 'PAKISTAN',
    COUNTRY_CODE: 'PAK',
    CURRENCY_CODE: 'PKR',
    NAME_OF_CURRENCY: 'Rupee',
  },
  {
    COUNTRY: 'PALAU',
    COUNTRY_CODE: 'PLW',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'PANAMA',
    COUNTRY_CODE: 'PAN',
    CURRENCY_CODE: 'PAB / USD',
    NAME_OF_CURRENCY: 'Balboa / Dollar',
  },
  {
    COUNTRY: 'PAPUA NEW GUINEA',
    COUNTRY_CODE: 'PNG',
    CURRENCY_CODE: 'PGK',
    NAME_OF_CURRENCY: 'Kina',
  },
  {
    COUNTRY: 'PARAGUAY',
    COUNTRY_CODE: 'PRY',
    CURRENCY_CODE: 'PYG',
    NAME_OF_CURRENCY: 'Guarani',
  },
  {
    COUNTRY: 'PERU',
    COUNTRY_CODE: 'PER',
    CURRENCY_CODE: 'PEN',
    NAME_OF_CURRENCY: 'New Sol',
  },
  {
    COUNTRY: 'PHILIPPINES',
    COUNTRY_CODE: 'PHL',
    CURRENCY_CODE: 'PHP',
    NAME_OF_CURRENCY: 'Peso',
  },
  {
    COUNTRY: 'PITCAIRN',
    COUNTRY_CODE: 'PCN',
    CURRENCY_CODE: 'NZD',
    NAME_OF_CURRENCY: 'N.Z. Dollar',
  },
  {
    COUNTRY: 'POLAND',
    COUNTRY_CODE: 'POL',
    CURRENCY_CODE: 'PLZ',
    NAME_OF_CURRENCY: 'Zloty',
  },
  {
    COUNTRY: 'PORTUGAL',
    COUNTRY_CODE: 'PRT',
    CURRENCY_CODE: 'PTE',
    NAME_OF_CURRENCY: 'Escudo',
  },
  {
    COUNTRY: 'PUERTO RICO',
    COUNTRY_CODE: 'PRI',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },

  {
    COUNTRY: 'QATAR',
    COUNTRY_CODE: 'QAT',
    CURRENCY_CODE: 'QAR',
    NAME_OF_CURRENCY: 'Riyal',
  },

  {
    COUNTRY: 'REUNION',
    COUNTRY_CODE: 'REU',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'ROMANIA',
    COUNTRY_CODE: 'ROM',
    CURRENCY_CODE: 'ROL',
    NAME_OF_CURRENCY: 'Leu',
  },
  {
    COUNTRY: 'RUSSIAN FEDERATION',
    COUNTRY_CODE: 'RUS',
    CURRENCY_CODE: 'RUR',
    NAME_OF_CURRENCY: 'Rouble',
  },
  {
    COUNTRY: 'RWANDA',
    COUNTRY_CODE: 'RWA',
    CURRENCY_CODE: 'RWF',
    NAME_OF_CURRENCY: 'Franc',
  },

  {
    COUNTRY: 'ST. HELENA',
    COUNTRY_CODE: 'SHN',
    CURRENCY_CODE: 'SHP',
    NAME_OF_CURRENCY: 'Pound Sterling',
  },
  {
    COUNTRY: 'SAINT KITTS AND NEVIS',
    COUNTRY_CODE: 'KNA',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'SAINT LUCIA',
    COUNTRY_CODE: 'LCA',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'ST. PIERRE AND MIQUELON',
    COUNTRY_CODE: 'SPM',
    CURRENCY_CODE: 'FRF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'SAINT VINCENT / GRENADINES',
    COUNTRY_CODE: 'VCT',
    CURRENCY_CODE: 'XCD',
    NAME_OF_CURRENCY: 'East Caribb. Dollar',
  },
  {
    COUNTRY: 'SAMOA WESTERN',
    COUNTRY_CODE: 'WSM',
    CURRENCY_CODE: 'WST',
    NAME_OF_CURRENCY: 'Tala',
  },
  {
    COUNTRY: 'SAN MARINO',
    COUNTRY_CODE: 'SMR',
    CURRENCY_CODE: 'ITL',
    NAME_OF_CURRENCY: 'Lira',
  },
  {
    COUNTRY: 'SAO TOME AND PRINCIPE',
    COUNTRY_CODE: 'STP',
    CURRENCY_CODE: 'STD',
    NAME_OF_CURRENCY: 'Dobra',
  },
  {
    COUNTRY: 'SAUDI ARABIA',
    COUNTRY_CODE: 'SAU',
    CURRENCY_CODE: 'SAR',
    NAME_OF_CURRENCY: 'Riyal',
  },
  {
    COUNTRY: 'SENEGAL',
    COUNTRY_CODE: 'SEN',
    CURRENCY_CODE: 'XOF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'SEYCHELLES',
    COUNTRY_CODE: 'SYC',
    CURRENCY_CODE: 'SCR',
    NAME_OF_CURRENCY: 'Rupee',
  },
  {
    COUNTRY: 'SIERRA LEONE',
    COUNTRY_CODE: 'SLE',
    CURRENCY_CODE: 'SLL',
    NAME_OF_CURRENCY: 'Leone',
  },
  {
    COUNTRY: 'SINGAPORE',
    COUNTRY_CODE: 'SGP',
    CURRENCY_CODE: 'SGD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'SLOVAKIA',
    COUNTRY_CODE: 'SVK',
    CURRENCY_CODE: 'SKK',
    NAME_OF_CURRENCY: 'Koruna',
  },
  {
    COUNTRY: 'SLOVENIA',
    COUNTRY_CODE: 'SVN',
    CURRENCY_CODE: 'SIT',
    NAME_OF_CURRENCY: 'Tolar',
  },
  {
    COUNTRY: 'SOLOMON ISLANDS',
    COUNTRY_CODE: 'SLB',
    CURRENCY_CODE: 'SBD',
    NAME_OF_CURRENCY: 'Solom. Dollar',
  },
  {
    COUNTRY: 'SOMALIA',
    COUNTRY_CODE: 'SOM',
    CURRENCY_CODE: 'SOS',
    NAME_OF_CURRENCY: 'Shilling',
  },
  {
    COUNTRY: 'SOUTH AFRICA',
    COUNTRY_CODE: 'ZAF',
    CURRENCY_CODE: 'ZAR',
    NAME_OF_CURRENCY: 'Rand',
  },
  {
    COUNTRY: 'SPAIN',
    COUNTRY_CODE: 'ESP',
    CURRENCY_CODE: 'ESP',
    NAME_OF_CURRENCY: 'Peseta',
  },
  {
    COUNTRY: 'SRI LANKA',
    COUNTRY_CODE: 'LKA',
    CURRENCY_CODE: 'LKR',
    NAME_OF_CURRENCY: 'Rupee',
  },
  {
    COUNTRY: 'SUDAN',
    COUNTRY_CODE: 'SDN',
    CURRENCY_CODE: 'SDP / SDD',
    NAME_OF_CURRENCY: 'Sud. Pound / Dinar',
  },
  {
    COUNTRY: 'SURINAME',
    COUNTRY_CODE: 'SUR',
    CURRENCY_CODE: 'SRG',
    NAME_OF_CURRENCY: 'Guilder',
  },
  {
    COUNTRY: 'SVALBARD / JAN MAYEN IS.',
    COUNTRY_CODE: 'SJM',
    CURRENCY_CODE: 'NOK',
    NAME_OF_CURRENCY: 'Norwegian Krone',
  },
  {
    COUNTRY: 'SWAZILAND',
    COUNTRY_CODE: 'SWZ',
    CURRENCY_CODE: 'SZL',
    NAME_OF_CURRENCY: 'Lilangeni',
  },
  {
    COUNTRY: 'SWEDEN',
    COUNTRY_CODE: 'SWE',
    CURRENCY_CODE: 'SEK',
    NAME_OF_CURRENCY: 'Krona',
  },
  {
    COUNTRY: 'SWITZERLAND',
    COUNTRY_CODE: 'CHE',
    CURRENCY_CODE: 'CHF',
    NAME_OF_CURRENCY: 'Franc',
  },
  {
    COUNTRY: 'SYRIAN ARAB REPUBLIC',
    COUNTRY_CODE: 'SYR',
    CURRENCY_CODE: 'SYP',
    NAME_OF_CURRENCY: 'Pound',
  },

  {
    COUNTRY: 'TAIWAN, PROVINCE OF CHINA',
    COUNTRY_CODE: 'TWN',
    CURRENCY_CODE: 'TWD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'TAJIKISTAN',
    COUNTRY_CODE: 'TJK',
    CURRENCY_CODE: 'RUR',
    NAME_OF_CURRENCY: 'Russian Rouble',
  },
  {
    COUNTRY: 'TANZANIA, UNITED REPUBLIC OF',
    COUNTRY_CODE: 'TZA',
    CURRENCY_CODE: 'TZS',
    NAME_OF_CURRENCY: 'Shilling',
  },
  {
    COUNTRY: 'THAILAND',
    COUNTRY_CODE: 'THA',
    CURRENCY_CODE: 'THB',
    NAME_OF_CURRENCY: 'Baht',
  },
  {
    COUNTRY: 'TOGO',
    COUNTRY_CODE: 'TGO',
    CURRENCY_CODE: 'XOF',
    NAME_OF_CURRENCY: 'C.F.A. Franc',
  },
  {
    COUNTRY: 'TOKELAU',
    COUNTRY_CODE: 'TKL',
    CURRENCY_CODE: 'NZD',
    NAME_OF_CURRENCY: 'N.Z. Dollar',
  },
  {
    COUNTRY: 'TONGA',
    COUNTRY_CODE: 'TON',
    CURRENCY_CODE: 'TOP',
    NAME_OF_CURRENCY: "Pa'anga",
  },
  {
    COUNTRY: 'TRINIDAD AND TOBAGO',
    COUNTRY_CODE: 'TTO',
    CURRENCY_CODE: 'TTD',
    NAME_OF_CURRENCY: 'T.T. Dollar',
  },
  {
    COUNTRY: 'TUNISIA',
    COUNTRY_CODE: 'TUN',
    CURRENCY_CODE: 'TND',
    NAME_OF_CURRENCY: 'Dinar',
  },
  {
    COUNTRY: 'TURKEY',
    COUNTRY_CODE: 'TUR',
    CURRENCY_CODE: 'TRL',
    NAME_OF_CURRENCY: 'Lira',
  },
  {
    COUNTRY: 'TURKMENISTAN',
    COUNTRY_CODE: 'TKM',
    CURRENCY_CODE: 'TMM',
    NAME_OF_CURRENCY: 'Manat',
  },
  {
    COUNTRY: 'TURKS AND CAICOS ISLANDS',
    COUNTRY_CODE: 'TCA',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'TUVALU',
    COUNTRY_CODE: 'TUV',
    CURRENCY_CODE: 'AUD',
    NAME_OF_CURRENCY: 'Australia Dollar',
  },

  {
    COUNTRY: 'UGANDA',
    COUNTRY_CODE: 'UGA',
    CURRENCY_CODE: 'UGX',
    NAME_OF_CURRENCY: 'Shilling',
  },
  {
    COUNTRY: 'UKRAINE',
    COUNTRY_CODE: 'UKR',
    CURRENCY_CODE: 'UAK',
    NAME_OF_CURRENCY: 'Karbovanet',
  },
  {
    COUNTRY: 'UNITED ARAB EMIRATES',
    COUNTRY_CODE: 'ARE',
    CURRENCY_CODE: 'AED',
    NAME_OF_CURRENCY: 'Dirham',
  },
  {
    COUNTRY: 'UNITED KINGDOM',
    COUNTRY_CODE: 'GBR',
    CURRENCY_CODE: 'GBP',
    NAME_OF_CURRENCY: 'Pound Sterling',
  },
  {
    COUNTRY: 'UNITED STATES',
    COUNTRY_CODE: 'USA',
    CURRENCY_CODE: 'USD / US$',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'UNITED STATES MIN. OUTL. ISLS.',
    COUNTRY_CODE: 'UMI',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'URUGUAY',
    COUNTRY_CODE: 'URY',
    CURRENCY_CODE: 'UYU',
    NAME_OF_CURRENCY: 'Peso',
  },
  {
    COUNTRY: 'UZBEKISTAN',
    COUNTRY_CODE: 'UZB',
    CURRENCY_CODE: 'UZS',
    NAME_OF_CURRENCY: 'Sum',
  },

  {
    COUNTRY: 'VANUATU',
    COUNTRY_CODE: 'VUT',
    CURRENCY_CODE: 'VUV',
    NAME_OF_CURRENCY: 'Vatu',
  },
  {
    COUNTRY: 'VATICAN CITY STATE (HOLY SEE)',
    COUNTRY_CODE: 'BAT',
    CURRENCY_CODE: 'ITL',
    NAME_OF_CURRENCY: 'Lira',
  },
  {
    COUNTRY: 'VENEZUELA',
    COUNTRY_CODE: 'VEN',
    CURRENCY_CODE: 'VEB',
    NAME_OF_CURRENCY: 'Bolivar',
  },
  {
    COUNTRY: 'VIET NAM',
    COUNTRY_CODE: 'VNM',
    CURRENCY_CODE: 'VND',
    NAME_OF_CURRENCY: 'Dong',
  },
  {
    COUNTRY: 'VIRGIN ISLANDS (BRITISH)',
    COUNTRY_CODE: 'VGB',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },
  {
    COUNTRY: 'VIRGIN ISLANDS (U.S.)',
    COUNTRY_CODE: 'VIR',
    CURRENCY_CODE: 'USD',
    NAME_OF_CURRENCY: 'Dollar',
  },

  {
    COUNTRY: 'WALLIS AND FUTUNA ISLANDS',
    COUNTRY_CODE: 'WLF',
    CURRENCY_CODE: 'XPF',
    NAME_OF_CURRENCY: 'C.F.P. Franc',
  },
  {
    COUNTRY: 'WESTERN SAHARA',
    COUNTRY_CODE: 'ESH',
    CURRENCY_CODE: 'MAD',
    NAME_OF_CURRENCY: 'Morocco Dirham',
  },

  {
    COUNTRY: 'YEMEN',
    COUNTRY_CODE: 'YEM',
    CURRENCY_CODE: 'YER',
    NAME_OF_CURRENCY: 'Riyal',
  },
  {
    COUNTRY: 'YUGOSLAVIA',
    COUNTRY_CODE: 'YUG',
    CURRENCY_CODE: 'YUN',
    NAME_OF_CURRENCY: 'New Dinar',
  },

  {
    COUNTRY: 'ZAIRE',
    COUNTRY_CODE: 'ZAR',
    CURRENCY_CODE: 'ZRN',
    NAME_OF_CURRENCY: 'New Zaire',
  },
  {
    COUNTRY: 'ZAMBIA',
    COUNTRY_CODE: 'ZMB',
    CURRENCY_CODE: 'ZMK',
    NAME_OF_CURRENCY: 'Kwacha',
  },
  {
    COUNTRY: 'ZIMBABWE',
    COUNTRY_CODE: 'ZWE',
    CURRENCY_CODE: 'ZWD',
    NAME_OF_CURRENCY: 'Zimb. Dollar',
  },

  {
    COUNTRY: 'EUROPEAN CURRENCY UNIT',
    COUNTRY_CODE: '-',
    CURRENCY_CODE: 'XEU',
    NAME_OF_CURRENCY: 'Europ. Currency Unit',
  },
  {
    COUNTRY: 'SPECIAL DRAWING RIGHTS',
    COUNTRY_CODE: '-',
    CURRENCY_CODE: 'XDR',
    NAME_OF_CURRENCY: 'Special Drawing Rights',
  },
];
