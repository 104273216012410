import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
// import { Link } from 'react-router-dom';
// import Card from 'react-bootstrap/Card';
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
// import Container from 'react-bootstrap/Container';
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
// import PartnerWrapper from '../partnerwrapper/wrapper';
import { partnerInstance } from "../../../config/axios";
import "./style.css";
import { validateInvoiceSequencing } from "../../../function/validate";

const InvoiceSequencing = () => {
  const [show, setShow] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [invoiceprefix, setInvoicePrefix] = useState("");
  const [invoiceno, setInvoiceNo] = useState("");
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    getInvoiceSequencing();
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setErrors("");
  };

  useEffect(() => {
    getInvoiceSequencing();
  }, []);

  const getInvoiceSequencing = async () => {
    const sequencingData = await partnerInstance().get("/getInvoiceSequencing");
    const { code, invoiceSequencingData } = sequencingData.data;
    if (code === 200) {
      setCompanyName(invoiceSequencingData.companyName);
      setInvoicePrefix(invoiceSequencingData.invoiceprefix);
      setInvoiceNo(invoiceSequencingData.invoiceno);
    }
  };
  const updateInvoiceSequencing = async (e) => {
    e.preventDefault();
    const payload = {
      invoiceprefix,
      invoiceno,
    };
    const validation = validateInvoiceSequencing({
      invoiceno,
      invoiceprefix,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const accountData = await partnerInstance().put(
      "/invoiceSequencing",
      payload
    );
    const { code, msg } = accountData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      setShow(false);
      // getInvoiceSequencing();
      // history.push('/partners/staff');
      // clearValues();
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <div className="invoice-sequence">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="partner-table">
              <Table responsive="lg">
                <thead>
                  <tr>
                    <th className="text-left">Location Name</th>
                    <th>Invoice Prefix</th>
                    <th className="text-center">Invoice Sequence No.</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">{companyName}</td>
                    <td>{invoiceprefix || "N/A"}</td>
                    <td className="text-center">{invoiceno || "N/A"}</td>
                    <td>
                      <Button onClick={handleShow} className="btn-perple">
                        Edit
                        {/* <i className="fa fa-pencil-square-o" /> */}{" "}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} classes="seq-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Change Sequencing
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={updateInvoiceSequencing}>
          <Modal.Body>
            <p className="mb-3 text-muted">
              You are about to introduce this change for Websul
            </p>

            <Row>
              <Form.Group className="col-md-6">
                <Form.Label>Invoice No. Prefix</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Prefix"
                  onChange={(e) => setInvoicePrefix(e.target.value)}
                  value={invoiceprefix}
                />
                {errors.invoiceprefix && (
                  <p className="error">{errors.invoiceprefix}</p>
                )}
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Label>Next Invoice Number</Form.Label>
                <Form.Control
                  size="lg"
                  type="number"
                  placeholder="Number"
                  onChange={(e) => setInvoiceNo(e.target.value)}
                  value={invoiceno}
                />
                {errors.invoiceno && (
                  <p className="error">{errors.invoiceno}</p>
                )}
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-perple" variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default InvoiceSequencing;
