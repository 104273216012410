import React, { useEffect, useState } from 'react';
import {
  Table,
} from 'react-bootstrap';
//import moment from 'moment';
import { Link } from 'react-router-dom';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';
// import Loader from '../../loader/loader';

const MonthlyTransaction = ({ name }) => {
  const [dataa, setData] = useState([]);

  useEffect(() => {
    monthBalanceListing();
  }, []);

  const monthBalanceListing = async () => {
    const saveCategory = await adminInstance().get(
      '/monthBalanceTransaction',
    );
    const {
      code, data,
    } = saveCategory.data;
    if (code === 200) {
      setData(data);
    }
  };

  return (
    <AdminWrapper name={name}>
      <div className="Admin-partners">
        <div className="container">
          <div className="back-btn">
            <h2 className="admin-title">Account</h2>
            <Link to="/admin/partner"><h2>Back</h2></Link>
          </div>
          <div className="row">
            {/* <input
              type="month"
              value={current}
              onChange={(e) => setRange(e)}
            /> */}
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="partner-header">
                    <h2>Account</h2>
                  </div> */}
                  <div className="partner-table">
                    {dataa && dataa.length > 0 ? (
                      <Table responsive="lg">
                        <thead>
                          <tr>
                            <th className="text-left">Date</th>
                            <th className="text-left">Online Amount</th>
                            <th className="text-left">Offline Amount</th>
                            <th className="text-left">Commission</th>
                            <th className="text-left">Payout</th>

                            {/* <th className="text-center">(Commission + Fixed Charge)</th> */}
                          </tr>
                        </thead>
                        {dataa && dataa.map((el) => (
                          <tbody>
                            <tr>
                              <td className="text-left">
                                {el.month}
                                {' '}
                                {el.year}
                              </td>
                              <td className="text-left">{el.onLineAmount}</td>
                              <td className="text-left">{el.offLineAmount}</td>
                              <td className="text-left">{el.commission}</td>
                              <td className="text-left">
                                {el.payout}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    ) : (
                      <div className="noservice-found"><h3>No Transactions</h3></div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="store-pagination col-md-12 text-center">
            {/* {totalCount >= limit && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel="← Previous"
                    nextLabel="Next →"
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName="pagination-chk"
                    previousLinkClassName="pagination__link"
                    nextLinkClassName="pagination__link"
                    disabledClassName="pagination__link--disabled"
                    activeClassName="pagination__link--active"
                  />
                </div>
              </div>
            </div>
            )} */}

          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default MonthlyTransaction;
