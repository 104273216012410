import React, { useContext, useEffect, useRef, useState } from "react";
//import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown, Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import menu from "../../../assets/partner/menu-icon.png";
// import admin from '../../../assets/partner/user.jpg';
import admin from "../../../assets/no-image.jpg";
import { server } from "../../../config/keys";
import PartnerContext from "../../../context/context";
import { partnerInstance } from "../../../config/axios";
import NotificationPage from "../../notification/notification";
import { time2 } from "../../../socket";
//import logo from "../../../assets/appointlee-new-logo1.png";

const PartnerHeader = ({ setMenuToggle, menutoggle, heading }) => {
  const {
    username,
    isPartnerLogin,
    PartnerLogOut,
    //setIsPartnerLogin,
    //setIsStepComplete,
    //isStepComplete,
  } = useContext(PartnerContext);
  const { notifications } = username;
  const titleRef = useRef(notifications);
  const [pnotification, setPnotification] = useState(false);
  const [notification, setNotification] = useState([]);
  const [count, setCount] = useState(0);
  // eslint-disable-next-line
  const [data, setData] = useState(username.isStepComplete);
  // eslint-disable-next-line
  const [activePage, setActivePage] = useState(" ");

  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/partners/")[1];
    setActivePage(path);
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      const tokenData = localStorage.getItem("authtoken");
      const sam = localStorage.getItem("partnertoken");
      const payload = {
        tokenData,
        sam,
      };
      const response = await partnerInstance().post("/checktoken", payload);

      if (response.status === 200) {
        toast.success("Your Token Is Expired", "Message", 4000);
        window.location.href = "/partners/login";
        localStorage.removeItem("partnertoken");
        localStorage.removeItem("authtoken");
      }
    };
    validateToken();

    time2((data) => {
      if (data) {
        setNotification(data);
        const filter = data && data.filter((item) => !item.read);
        setCount(filter.length);
      }
    });

    setNotification(titleRef.current);
    if (titleRef.current) {
      const filter =
        titleRef.current && titleRef.current.filter((item) => !item.read);
      setCount(filter.length);
    } else {
      setCount(0);
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setPnotification(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  const handleshownotify = async () => {
    setPnotification(!pnotification);
    if (count > 0) {
      await partnerInstance().post("/readAllPartnerNotifications");

      setCount(0);
    }
  };

  const adminaccessLog = () => {
    localStorage.removeItem("admindata");
    PartnerLogOut();
    window.close();
  };

  return (
    <div className="admin-header">
      <div className="menu-toggle">
        {data === false ? (
          <span>
            <img src={menu} alt="Menu" />
          </span>
        ) : (
          <span onClick={() => setMenuToggle(!menutoggle)} role="presentation">
            <img src={menu} alt="Menu" />
          </span>
        )}
        {/* <span onClick={() => setMenuToggle(!menutoggle)}>
          <img src={menu} alt='Menu' />
        </span> */}{" "}
        <div className="patners-logo">
          {/* <img src={logo} alt="" /> */}
          {/* <h3>{activePage} </h3> */}
          <h3>{heading}</h3>
        </div>
      </div>

      {isPartnerLogin && !localStorage.getItem("admindata") ? (
        <>
          <div className="admin-notify">
            <div className="notification-area">
              {data === false ? (
                <i className="fa fa-bell" aria-hidden="true" />
              ) : (
                <i
                  className="fa fa-bell"
                  aria-hidden="true"
                  onClick={handleshownotify}
                />
              )}

              <Badge variant="warning">{count}</Badge>
            </div>
            <div className="admin-info p-pic">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic-admin">
                  <div className="admin-info-img">
                    <img
                      src={
                        username.image ? `${server}/${username.image}` : admin
                      }
                      alt="Admin"
                    />
                  </div>
                  <div className="p-name">{username.name}</div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {data === false ? (
                    <>
                      <Link to="/">My Profile</Link>
                      <Link to="/">Settings</Link>{" "}
                    </>
                  ) : (
                    <>
                      <Link to="/partners/profile">My Profile</Link>
                      <Link to="/partners/setup">Settings</Link>
                    </>
                  )}

                  <Dropdown.Item onClick={() => PartnerLogOut()}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {pnotification && (
              <NotificationPage
                handleshownotify={handleshownotify}
                notification={notification}
                wrapperRef={wrapperRef}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="admin-info-view">
            <span className="admin-access">Admin Access</span>
            <span
              className="close-acount"
              role="presentation"
              onClick={() => adminaccessLog()}
            >
              Back To Admin
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default PartnerHeader;
