import React, { useState, useEffect, useContext, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { partnerInstance } from "../../../config/axios";
import { validateReferral } from "../../../function/validate";
import PartnerContext from "../../../context/context";
import history from "../../../config/history";

function SignupReferral({ setIsReferral }) {
  const { info } = useLocation();
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [show, setShow] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setName("");
    setShow(true);
    setStatus(true);
  };
  const [name, setName] = useState("");
  const [date] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [status, setStatus] = useState(true);
  const [errors, setErrors] = useState({});
  const [referrals, setReferrals] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [referralId, setReferralId] = useState("");

  useEffect(() => {
    //setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getReferral();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const getReferral = async () => {
    //setLoading(true);
    const referralData = await partnerInstance().get("/getAllReferral");
    const { code, referral } = referralData.data;
    if (code === 200) {
      setReferrals(referral);
      if (referral.length > 0) {
        setIsReferral(true);
      } else {
        setIsReferral(false);
      }

      //setLoading(false);
    }
  };
  const handleShowDelete = (id) => {
    setReferralId(id);
    setShowDelete(true);
  };
  const handleHide = () => {
    setShowEdit(false);
    setShowDelete(false);
  };

  const handleShowEdit = (id, namee, statuss) => {
    setReferralId(id);
    setName(namee);
    setStatus(statuss);
    setShowEdit(true);
  };

  const addReferral = async (e) => {
    e.preventDefault();
    const payload = {
      name,
      date,
      status,
    };
    const validation = validateReferral({
      name,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveData = await partnerInstance().post("/addReferral", payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      getReferral();
      setShow(false);
      setName("");
      if (info) {
        history.push("/partners/availablity");
      }
      // clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <>
      <div className="">
        {/* <div className="container"> */}
        <h2 className="admin-title">
          Referral
          <Button onClick={handleShow} className="btn-perple">
            {" "}
            Add Referral
          </Button>
        </h2>

        <div className="partner-table">
          <Table responsive="lg">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date Added </th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {referrals && referrals.length ? (
                referrals.map((el, i) => (
                  <>
                    <tr>
                      <td>{el.name}</td>
                      <td>{el.date}</td>
                      {el.status ? (
                        <td>
                          <span className="active-badge">Active</span>
                        </td>
                      ) : (
                        <td>
                          <span className="active-badge">Inactive</span>
                        </td>
                      )}
                      <td>
                        <Button
                          className="btn-perple"
                          onClick={() =>
                            handleShowEdit(el._id, el.name, el.status)
                          }
                        >
                          Edit
                          {/* <i className="fa fa-edit" /> */}{" "}
                        </Button>
                        <Button
                          className="btn-perple"
                          onClick={() => handleShowDelete(el._id)}
                        >
                          Delete
                          {/* <i className="fa fa-trash" /> */}{" "}
                        </Button>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* </div> */}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Add Referral
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={addReferral}>
            <Form.Group>
              <Form.Label>Referral name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Referral name"
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Discount name</Form.Label>
              <Form.Control size="lg" type="text" placeholder="Invoice" />
            </Form.Group> */}
            <div className="row">
              <div className="col-md-6">
                <div className="service-header">
                  <h2 className="admin-inner-title">Active</h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-body servicebody">
                  <div className="online-booking">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={() => setStatus(!status)}
                        checked={status}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right p-0">
              <Button variant="primary" type="submit" className="btn-perple">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showEdit} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            EDIT REFERRAL
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditReferral
            validateReferral={validateReferral}
            setShowEdit={setShowEdit}
            referralId={referralId}
            name={name}
            status={status}
            setStatus={setStatus}
            setName={setName}
            getReferral={getReferral}
            date={date}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>

      <Modal show={showDelete} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Delete Referral
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeleteReferral
            referralId={referralId}
            setShowDelete={setShowDelete}
            getReferral={() => getReferral()}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

export default SignupReferral;

function EditReferral({
  setShowEdit,
  referralId,
  name,
  status,
  setStatus,
  setName,
  getReferral,
  date,
}) {
  // const [categoryname, setCategoryname] = useState('');
  // const [categorydescription, setCategorydescription] = useState('');
  const [errors, setErrors] = useState({});
  // const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const updateReferral = async (e) => {
    e.preventDefault();
    const payloadd = {
      name,
      date,
      status,
    };
    const validation = validateReferral({
      name,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveCategory = await partnerInstance().put(
      `/editReferral/${referralId}`,
      payloadd
    );
    const { code, msg } = saveCategory.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      getReferral();
      toast.success(msg, { containerId: "B" });
      // clearValues();
      setShowEdit(false);
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  // const clearValues = () => {
  //   setCategoryname('');
  //   setCategorydescription('');
  // };
  return (
    <Form onSubmit={updateReferral}>
      <Form.Group>
        <Form.Label>Referral name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Referral name"
        />
        {errors.name && <p className="error">{errors.name}</p>}
      </Form.Group>
      <div className="row">
        <div className="col-md-6">
          <div className="service-header">
            <h2 className="admin-inner-title">Active</h2>
          </div>
        </div>
        <div className="col-md-6">
          <div className="service-body refers">
            <div className="online-booking">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={() => setStatus(!status)}
                  checked={status}
                />
                <span className="slider round" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right p-0">
        <Button variant="primary" type="submit" className="btn-perple">
          Update
        </Button>
      </div>
    </Form>
  );
}

function DeleteReferral({ referralId, getReferral, setShowDelete }) {
  const deleteIds = async (e) => {
    e.preventDefault();
    const payloadd = {
      deleteId: referralId,
    };
    const deleteReferral = await partnerInstance().post(
      "/deleteReferral/",
      payloadd
    );

    const { code, msg } = deleteReferral.data;
    if (code === 200) {
      setShowDelete(false);
      getReferral();
      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={deleteIds}>
      <p>Are You sure you want to delete ?</p>
      <Button variant="primary" type="submit" className="btn-perple">
        Delete
      </Button>
    </Form>
  );
}

EditReferral.propTypes = {
  referralId: PropTypes.string.isRequired,
  setShowEdit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  getReferral: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};

DeleteReferral.propTypes = {
  referralId: PropTypes.string.isRequired,
  setShowDelete: PropTypes.func.isRequired,
  getReferral: PropTypes.func.isRequired,
};
