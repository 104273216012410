import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
// import Closedatemodal from './closedatemodal';
import { partnerInstance } from "../../../config/axios";
import Loader from "../../loader/loader";

const StaffMember = () => {
  const [staffs, setStaffs] = useState([]);
  const [loading, setLoading] = useState(false);
  const getStaff = async () => {
    setLoading(true);
    const staffData = await partnerInstance().get("/getStaffs");
    const { code, stafflist } = staffData.data;
    if (code === 200) {
      setStaffs(stafflist);
      setLoading(false);
    }
  };
  useEffect(() => {
    getStaff();
  }, []);
  const editStaff = (id) => {
    window.location.href = `/partners/editstaff/?id=${id}`;
  };
  const deleteStaff = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this Staff.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const deleteStaff = await partnerInstance().post("/deleteStaff/", {
              deleteId: id,
            });

            const { code } = deleteStaff.data;
            if (code === 200) {
              toast.success("Staff Deleted Successfully", {
                containerId: "B",
              });
              getStaff();
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="close-staff">
        <div className="container">
          <div className="close-date user-12">
            <Link
              to="/partners/addstaff"
              className="btn btn-primary btn-perple"
            >
              Add Associates
            </Link>
          </div>

          <div className="partner-table">
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-left">Name</th>
                  <th className="text-center">Appointments</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">Mobile No</th>
                  <th className="text-center">Permission level</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {staffs.map((el) => (
                  <tr>
                    <td className="text-left" onClick={() => editStaff(el._id)}>
                      {el.firstname} {el.lastname}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => editStaff(el._id)}
                    >
                      {el.appointmentBooking
                        ? "Calender Bookings Enabled"
                        : "Calender Bookings Disabled"}
                    </td>

                    <td
                      className="text-center"
                      onClick={() => editStaff(el._id)}
                    >
                      {el.email ? el.email : "N/A"}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => editStaff(el._id)}
                    >
                      {el.phone ? el.phone : "N/A"}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => editStaff(el._id)}
                    >
                      {el.userpermissiontype ? el.userpermissiontype : "N/A"}
                    </td>
                    <td></td>
                    {el._id !== localStorage.getItem("userid") ? (
                      <td className="login-button service-save text-center">
                        <Button
                          className="delete-btn btn-perple"
                          onClick={() => deleteStaff(el._id)}
                        >
                          Delete
                          {/* <i className="fa fa-trash" /> */}
                        </Button>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>

                  //  <>  </>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffMember;
