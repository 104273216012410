import React,{useContext} from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
import screenImg from "../../../assets/screen-img.png";
import cross from "../../../assets/cross2.png";
import history from "../../../config/history";
import "./signupmain.css";
import PartnerContext from "../../../context/context";
import {StepCompletePrevent} from './StepCompletePrevent'

const SignupMain = () => {
  const {isStepComplete } = useContext(PartnerContext);  
  StepCompletePrevent(isStepComplete)
  const StepForm = () => {
    history.push("/partners/business");
  };

  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      {/* <Helmet>
        <html
          lang='en'
          className={`partners-profile  ${dis === false ? 'disable' : ''}`}
        />
      </Helmet> */}
      <PartnerWrapper>
        <div className="partners-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="signup-block-container">
                  <div className="cross-icon">
                    <Link to="/">
                      <img src={cross} alt="" />
                    </Link>
                  </div>
                  <div className="sb-left">
                    <h2>
                      Expand your <br /> business potentials <br /> with
                      Appointlee
                    </h2>
                    <p>
                      Get access to great online booking features used by
                      millions of customers. List your business on Appointlee,
                      get new clients, more bookings and boost your growth.
                    </p>
                    <div className="sb-btn">
                      <Button onClick={() => StepForm()}>Start Now</Button>
                    </div>
                  </div>
                  <div className="sb-right">
                    <div className="sb-right-img">
                      <img src={screenImg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupMain;
