import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { toast } from "react-toastify";
import { partnerInstance } from "../../../config/axios";
import { validatePopup } from "../../../function/validate";

const Closedatemodal = ({ show, setShow, item }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState(null);
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setShow(false);
    setStartDate(null);
    setEndDate(null);
    setDescription(null);
  };
  // const handleShow = () => setShow(true);
  useEffect(() => {
    if (item && item._id) {
      setStartDate(item.from);
      setEndDate(item.to);
      setDescription(item.description);
    }
  }, [item]);

  const saveChanges = async (e) => {
    e.preventDefault();
    const payload = {
      startDate,
      endDate,
      description,
    };

    const validation = validatePopup({
      startDate,
      endDate,
      description,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    if (item && item._id) {
      const updateCloseDate = await partnerInstance().post(
        `/editClosedDate/${item._id}`,
        payload
      );
      if (updateCloseDate) {
        toast.success("Closed Date Updated Successfully", {
          containerId: "B",
        });
      }
      handleClose();
    } else {
      const saveClosedDate = await partnerInstance().post(
        "/addclosedate",
        payload
      );
      if (saveClosedDate) {
        toast.success("Closed Date Added Successfully", {
          containerId: "B",
        });
      }
      handleClose();
    }
  };
  return (
    <Container>
      <Row>
        <div>
          <Modal
            className="closedatemodal"
            show={show}
            onHide={() => setShow(false)}
          >
            <Modal.Header closeButton onClick={handleClose}>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                {item && item._id ? "Update Closed Date" : "New Close Date"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert key={0} variant="primary">
                Online bookings can not be placed during closed dates.
              </Alert>
              <Row>
                <Form.Group className="col-md-6">
                  <Form.Label>
                    Start Date<span className="red-color">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="startDate"
                    name="startDate"
                    min={moment().format("YYYY-MM-DD")}
                    value={startDate}
                    minDate={new Date()}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  {errors.startDate && (
                    <p className="error">{errors.startDate}</p>
                  )}
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label>
                    End Date<span className="red-color">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="endDate"
                    min={moment().format("YYYY-MM-DD")}
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  {errors.endDate && <p className="error">{errors.endDate}</p>}
                </Form.Group>
              </Row>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                  Description<span className="red-color">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  id="description"
                  name="description"
                  value={description}
                  rows={3}
                  placeholder="e.g:Holiday"
                  onChange={(e) => setDescription(e.target.value)}
                />
                {errors.description && (
                  <p className="error">{errors.description}</p>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="btn-perple"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={saveChanges}
                className="btn-perple"
              >
                {item && item._id ? "Update" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Row>
    </Container>
  );
};

export default Closedatemodal;
