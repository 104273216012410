import React from 'react';
// import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
// import PartnerWrapper from '../partnerwrapper/wrapper';
import errorimg from '../../assets/404.png';

const ErrorMsg = () => (
  <>
    <Helmet>
      <body className="error-404" />
    </Helmet>

    <div className="page-404">
      <div className="error-content">
        {/* <div className="error-img"> */}
          <img src={errorimg} alt="not-found" />
        {/* </div> */}
        <h3>Whoops... Page Not Found:( </h3>
        <div className="btn-404">
          <Link to="/partners/dashboard">Back To Home</Link>
        </div>
      </div>
    </div>
  </>
);

export default ErrorMsg;
