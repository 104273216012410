import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Form,
  Table,
  Dropdown,
  DropdownButton,
  Modal,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import PartnerWrapper from "../partnerwrapper/wrapper";
import history from "../../../config/history";
import user from "../../../assets/partner/user.jpg";
import success from "../../../assets/partner/success.png";
import location from "../../../assets/venue5.jpg";
import { partnerInstance } from "../../../config/axios";
import { server } from "../../../config/keys";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";

const VoucherViewInvoice = () => {
  const { username } = useContext(PartnerContext);
  const { currency, sections } = username;
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [loc, setLocation] = useState("");
  const [staffName, setStaffName] = useState("");
  const [fileName, setFileName] = useState("");
  const [voucherValue, setVoucherValue] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [show, setShow] = useState(false);
  const [voucherName, setVoucherName] = useState("");
  const [services, setServices] = useState([]);
  const [status, setStatus] = useState(false);
  const [refStatus, setRefStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState(0);
  const [printReciepts, setPrintReciepts] = useState(false);
  const [copy, setCopy] = useState(0);
  const [price, setPrice] = useState(0);
  let totalPrice = 0;
  const [noOfVouchers, setNoOfVouchers] = useState([]);
  const value = queryString.parse(window.location.search);
  const { voucherId } = value;
  const handleHide = () => setShow(false);
  const getVocherID = async (data) => {
    setLoading(true);
    const payload = {
      id: data,
    };
    const response = await partnerInstance().post(
      "/getVoucherInvoice",
      payload
    );

    const { code, getVoucher, voucherData, servicelist } = response.data;
    if (code === 200) {
      setCopy(servicelist.length);
      setCustomerName(getVoucher.customerId.firstname);
      setCustomerEmail(getVoucher.customerId.email);
      setPurchaseDate(
        new Date(getVoucher.PurchaseDate).toString().substring(0, 15)
      );
      setLocation(voucherData.partnerLocation[0].locationName);
      setStaffName(getVoucher.staffId ? getVoucher.staffId.firstname : "");
      setVoucherValue(voucherData.voucher[0].voucherValue);
      setServices(voucherData.voucher[0].serviceid);
      setFileName(getVoucher.invoiceId.pdfUrl);
      setStatus(getVoucher.invoiceId.cancelStatus);
      setRefStatus(getVoucher.invoiceId.refund);
      setInvoiceNo(getVoucher.invoiceId.invoiceno);
      setVoucherCode(getVoucher.voucherCode);
      setExpiryDate(
        new Date(getVoucher.ExpiryDate).toString().substring(0, 15)
      );
      setVoucherName(voucherData.voucher[0].voucherName);
      setPrice(getVoucher.price);
      setPrintReciepts(voucherData.invoice.automaticprint);
      setNoOfVouchers(getVoucher.invoiceId.voucherID);
      setLoading(false);
    }
  };

  useEffect(() => {
    getVocherID(voucherId);
    // eslint-disable-next-line
  }, []);

  const handleVoid = async () => {
    const findBook = sections.findIndex(
      (boo) => boo.sectionname === "Can void invoices"
    );
    if (findBook > -1) {
      const payload = {
        appointmentId: voucherId,
      };
      const response = await partnerInstance().post(
        "/voidVoucherInvoice",
        payload
      );
      const { code } = response.data;
      if (code === 200) {
        history.push("/partners/voucher");
      }
    } else {
      toast.error("You Dont Have Permisssion to Void Invoices", {
        containerId: "B",
      });
    }
  };

  return (
    <PartnerWrapper>
      {loading && <Loader />}
      <div className="view-appointment-invoice-page appointment-view">
        <div className="container">
          <h2 className="admin-title">
            View Voucher{" "}
            <Link to="/partners/voucher">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="view-appointment-invoice-content appointment-content">
            <div className="row">
              <div className="col-md-5">
                <div className="appointment-card">
                  {/* Customer Details */}

                  <div className="customer-detail">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="customer-card">
                          <div className="customer-detail-appoint">
                            <div className="customer-detail-icon">
                              <img src={user} alt="" />
                            </div>
                            <div className="customer-detail-content">
                              <h4>{customerName}</h4>
                              <p>{customerEmail}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="no-invoice">
                    <img src={success} alt="" />
                    <h4>Completed</h4>
                    <p>
                      Full payment received on {purchaseDate} at {loc}{" "}
                      {staffName ? <>by {staffName}</> : ""}
                    </p>
                  </div>

                  <div className="appointment-buttons">
                    <div className="footer-button">
                      <DropdownButton
                        id="dropdown-basic-button-2"
                        title="More Option"
                      >
                        {" "}
                        {printReciepts && (
                          <>
                            {fileName ? (
                              <a
                                href={`${server}/${fileName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download
                              </a>
                            ) : (
                              ""
                            )}
                            {fileName ? (
                              <a
                                href={`${server}/${fileName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Print
                              </a>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {status ? (
                          ""
                        ) : (
                          <>
                            {refStatus ? (
                              <Dropdown.Item onClick={handleVoid}>
                                Void
                              </Dropdown.Item>
                            ) : (
                              <>
                                <Dropdown.Item onClick={() => setShow(true)}>
                                  Refund
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleVoid}>
                                  Void
                                </Dropdown.Item>
                              </>
                            )}
                          </>
                        )}
                      </DropdownButton>
                      <Link
                        to="/partners/voucher"
                        className=" btn btn-primary btn-perple"
                        style={{ color: "#fff" }}
                      >
                        Close
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="appointment-card">
                  <div className="appointment-body">
                    <div className="voucher-tab staff-page">
                      <Tabs
                        defaultActiveKey="0"
                        id="uncontrolled-tab-example-voucher"
                      >
                        <Tab eventKey="0" title="Voucher">
                          <div className="view-voucher">
                            <div className="view-voucher-content">
                              <img src={location} alt="" />
                              <h4>{loc}</h4>

                              <div className="voucher-value">
                                <span>Voucher value</span>
                                <h3>
                                  {currency} {voucherValue}
                                </h3>
                              </div>
                              <div className="vouhcer-code">
                                <p>Voucher code: {voucherCode}</p>
                                {copy === services.length ? (
                                  <p>Redeem on all services</p>
                                ) : (
                                  <p>Redeem on {services.length} service</p>
                                )}

                                <p>Valid until {expiryDate}</p>
                                <span>For multiple-use</span>
                              </div>
                            </div>
                          </div>
                        </Tab>

                        <Tab eventKey="1" title="Invoice">
                          <div className="view-invoice-form">
                            <Table>
                              <thead>
                                <tr>
                                  <th colSpan="2">
                                    <h4>Invoice #{invoiceNo}</h4>
                                  </th>
                                </tr>
                                <tr>
                                  <th>
                                    <span>{purchaseDate}</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {noOfVouchers.map((item) => {
                                  totalPrice += +item.price;
                                  return (
                                    <>
                                      <tr>
                                        <td width="50%">
                                          <h5>{voucherName}</h5>
                                          <p>
                                            {item.voucherValue}, {expiryDate}{" "}
                                            with {staffName}, Code :{" "}
                                            {item.voucherCode}
                                          </p>
                                        </td>
                                        <td width="50%">
                                          <h5
                                            style={{
                                              textAlign: "right",
                                            }}
                                          >
                                            {currency} {item.price}
                                          </h5>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}

                                <tr>
                                  <td width="50%">
                                    <h5>Subtotal</h5>
                                  </td>
                                  <td width="50%">
                                    <h5
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      {currency} {totalPrice}
                                    </h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="50%">
                                    <h5>Total</h5>
                                  </td>
                                  <td width="50%">
                                    <h5
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      {currency} {totalPrice}
                                    </h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="50%">
                                    <h5>Cash</h5>
                                    <p>{purchaseDate}</p>
                                  </td>
                                  <td width="50%">
                                    <h5
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      {currency} {totalPrice}
                                    </h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="50%">
                                    <h5>Balance</h5>
                                  </td>
                                  <td width="50%">
                                    <h5
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      {" "}
                                      {currency} 0
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
              <Modal show={show} onHide={handleHide} className="discount-12">
                <Modal.Header closeButton>
                  <div />
                  <Modal.Title id="example-custom-modal-styling-title ">
                    Refund
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Refund
                    appId={voucherId}
                    totalamount={price}
                    currency={currency}
                  />
                </Modal.Body>
                <Modal.Footer />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default VoucherViewInvoice;

function Refund({ appId, totalamount, currency }) {
  const deleteIds = async (e) => {
    e.preventDefault();
    const payload = {
      appointmentId: appId,
    };
    const response = await partnerInstance().post(
      "/refundVoucherInvoice",
      payload
    );
    const { code, msg } = response.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
    history.push("/partners/voucher");
  };

  return (
    <Form onSubmit={deleteIds}>
      <p>
        Are You sure you want to Refund
        {currency} {totalamount}?
      </p>
      <Button variant="primary" type="submit">
        Refund
      </Button>
    </Form>
  );
}
