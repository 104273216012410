import React, { useState, useEffect } from 'react';
import './login.css';
import { Form, Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validateUserProfile } from '../../function/validate';
import { userInstance } from '../../config/axios';
import 'react-phone-input-2/lib/material.css';
// dummy text for code setup
const UserProfile = () => {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [phoneCode, setPhoneCode] = useState('+91');

  const updateProfile = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      firstname,
      lastname,
      phone,
      phoneCode,
    };

    const validation = validateUserProfile({
      firstname,
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const updateUser = await userInstance().post('/userUpdateProfile', payload);
    const { code, msg } = updateUser.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  useEffect(() => {
    const getUserProfile = async () => {
      const Data = await userInstance().get('/validateToken');
   
      const { code, userData } = Data.data;
      if (code === 200) {
        setEmail(userData.email);
        setFirstname(userData.firstname);
        setLastname(userData.lastname);
        setPhone(userData.phone);
        setPhoneCode(userData.code);
      }
    };
    getUserProfile();
  }, []);

  const uploadImage = async (e) => {
    const data = new FormData();
    data.append('file', e.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const ImageSaved = await userInstance().post(
      '/uploadUserPhoto',
      data,
      config
    );
    if (ImageSaved.data.code === 200) {
      toast.success(ImageSaved.data.msg, { containerId: 'B' });
    }
  };

  return (
    <div className="login">
      <div className="login-section">
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="login-form">
                <h1>Update The Profile</h1>
                <div className="login-box">
                  <div className="row">
                    <div className="col-sm-4 text-center">
                      <div className="kv-avatar">
                        <div className="file-loading">
                          <input
                            id="avatar-1"
                            name="avatar-1"
                            type="file"
                            onChange={(e) => uploadImage(e)}
                          />
                        </div>
                      </div>
                      <div className="kv-avatar-hint">
                        <small>{'Select file < 1500 KB'}</small>
                      </div>
                    </div>
                  </div>
                  <Form onSubmit={updateProfile}>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Your email address"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>First Name</Form.Label>
                      <div className="password-show">
                        <Form.Control
                          type={firstname}
                          placeholder="Your FirstName"
                          name="firstname"
                          autoComplete="off"
                          value={firstname}
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                      </div>
                      {errors.firstname && (
                        <p className="error">{errors.firstname}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Last Name</Form.Label>
                      <div className="password-show">
                        <Form.Control
                          type={lastname}
                          placeholder="Your LastName"
                          name="lastname"
                          autoComplete="off"
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                    {/* <div className="col-md-12"> */}
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Mobile number</Form.Label>
                        <div className="number-fields">
                          <PhoneInput
                             country="us"
                            value={phoneCode}
                            onChange={(e) => setPhoneCode(`+${e}`)}
                            readonly
                          />
                          <Form.Control
                            type="number"
                            placeholder="Your mobile number"
                            name="phone"
                            autocomplete="off"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                          />
                        </div>
                      </Form.Group>
                    {/* </div> */}

                    <div className="login-button">
                      <Button type="submit" className="l-btn">
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
