/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Form,
  Modal,
  //Tab,
  Dropdown,
  DropdownButton,
  //FormControl,
} from "react-bootstrap";
//import Tabs from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import queryString from "query-string";
import PartnerWrapper from "../partnerwrapper/wrapper";
import user from "../../../assets/partner/user.jpg";
import invoice from "../../../assets/partner/invoice.png";
import success from "../../../assets/partner/success.png";
import "./appointment.css";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import Loader from "../../loader/loader";
import PartnerContext from "../../../context/context";

const ViewAppointment = () => {
  const [ID, setID] = useState("");
  //const [serviceName, setServiceName] = useState('');
  const [appointmentDate, setAppointmentDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [staffFirstName, setStaffFirstName] = useState("");
  //const [staffLastName, setStaffLastName] = useState('');
  //const [staffEmail, setStaffEmail] = useState('');
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [reference, setReference] = useState("");
  //const [startTime, setStartTime] = useState('');
  const [loc, setLoc] = useState("");
  const [cashDate, setCashDate] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [serviceDuration, setServiceDuration] = useState("");
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [cancelStatus, setCancelStatus] = useState(false);
  const [appointmentPanel, setAppointmentPanel] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [show, setShow] = useState(false);
  const [outServiceAddress, setOutAddress] = useState("");
  const [appointmentNotes, setAppointmentNotes] = useState("");
  const { username } = useContext(PartnerContext);
  const { currency, sections } = username;
  const handleClose = () => setShow(false);
  let priceTotal = 0;
  let durationTotal = 0;
  const getAppointment = async (id) => {
    setLoading(true);
    const idd = {
      appointmentId: id,
    };

    setID(id);
    const response = await partnerInstance().post("/getAppointment", idd);
    const { code, appointmentData } = response.data;
    if (code === 200) {
      const wait = [...appointmentData.appointmentPanel];
      setAppointmentPanel(wait);
      setAppointmentDate(appointmentData.appointmentDate);
      setLoc(appointmentData.partnerId.partnerLocation[0].locationName);
      setReference(appointmentData.appointmentReference);
      setCashDate(
        appointmentData.invoiceId ? appointmentData.invoiceId.createdAt : "N/A"
      );
      setCustomerFirstName(appointmentData.customerId.firstname);
      setCustomerEmail(appointmentData.customerId.email);
      setAppointmentStatus(appointmentData.appointmentStatus);
      setAppointmentPanel(appointmentData.appointmentPanel);
      setPaymentMethod(appointmentData.paymentMethod);
      setAppointmentNotes(appointmentData.bookingNote);
      setOutAddress(appointmentData.outServiceAddress);
      appointmentData.appointmentPanel.forEach((el) => {
        const filterStaffPrice = el.serviceId.staffPricing.findIndex(
          (sel) => sel.staffId === el.staffId._id
        );
        if (
          filterStaffPrice > -1 &&
          el.serviceId.staffPricing[filterStaffPrice].staffPrice
        ) {
          el.serviceId.priceFrom =
            el.serviceId.staffPricing[filterStaffPrice].staffPrice;
          el.serviceId.duration =
            el.serviceId.staffPricing[filterStaffPrice].staffDuration;
          durationTotal += parseInt(
            el.serviceId.staffPricing[filterStaffPrice].staffDuration
          );
          priceTotal += parseInt(
            el.serviceId.staffPricing[filterStaffPrice].staffPrice
          );
        } else if (el.serviceId.specialPrice) {
          el.serviceId.specialPrice;
          el.serviceId.duration;
          durationTotal += parseInt(el.duration);
          priceTotal += el.disGone
            ? el.discountType === "%"
              ? parseInt(el.serviceId.specialPrice) -
                parseInt(el.serviceId.specialPrice) *
                  (parseInt(el.disGone) / 100)
              : parseInt(el.serviceId.specialPrice) - parseInt(el.disGone)
            : parseInt(el.serviceId.specialPrice);
        } else {
          el.serviceId.priceFrom;
          el.serviceId.duration;
          durationTotal += parseInt(el.duration);
          priceTotal += el.disGone
            ? el.discountType === "%"
              ? parseInt(el.serviceId.priceFrom) -
                parseInt(el.serviceId.priceFrom) * (parseInt(el.disGone) / 100)
              : parseInt(el.serviceId.priceFrom) - parseInt(el.disGone)
            : parseInt(el.serviceId.priceFrom);
        }
        setStaffFirstName(el.staffId.firstname);
        //setStaffLastName(el.staffId.lastname);
        //setStaffEmail(el.staffId.email);
        //setServiceName(el.serviceId.serviceName);
        //setStartTime(el.startTime);
        setServiceDuration(durationTotal);
      });

      setServicePrice(priceTotal);
      setLoading(false);
    }
  };

  useEffect(() => {
    const value = queryString.parse(window.location.search);
    const { id } = value;
    getAppointment(id);
    // eslint-disable-next-line
  }, []);
  const handleAppointmentStatus = async (e) => {
    const payload = {
      appointmentId: ID,
      appointmentStatus: e,
    };
    const response = await partnerInstance().post(
      "/updateAppointmentStatus",
      payload
    );
    const { code } = response.data;
    if (code === 200) {
      history.push("/partners/calendar");
    }
  };

  const handleCheckout = () => {
    const setSta = sections.findIndex(
      (el) => el.sectionname === "Can check out sales"
    );
    if (setSta > -1) {
      history.push(`/partners/checkout?appointmentId=${ID}`);
    } else {
      toast.error("You Dont have Permisssion For Checkout Sales", {
        containerId: "B",
      });
    }
  };
  const handleViewInvoice = () => {
    history.push(`/partners/viewinvoice?appointmentId=${ID}`);
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }
  return (
    <PartnerWrapper>
      {/* {loading && (
      <Loader />
      )} */}
      <div className="view-appointment-page appointment-view">
        <div className="container">
          <h2 className="admin-title">
            View Appointment{" "}
            <Link to="/partners/calendar">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="view-appointment-content appointment-content">
            <div className="row">
              <div className="col-md-5">
                <div className="appointment-card">
                  {/* Customer Details */}

                  <div className="customer-detail">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="customer-card">
                          <div className="customer-detail-appoint">
                            <div className="customer-detail-icon">
                              <img src={user} alt="" />
                            </div>
                            <div className="customer-detail-content">
                              <h4>{customerFirstName}</h4>
                              <p>{customerEmail}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {paymentMethod ? (
                    <div className="no-invoice">
                      <img src={success} alt="" />
                      <h4>Completed</h4>

                      <p>
                        Full payment received on{" "}
                        {new Date(appointmentDate).toString().substring(0, 15)}{" "}
                        at {loc} by {staffFirstName}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="select-status">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            as="select"
                            value={appointmentStatus}
                            onChange={(e) =>
                              handleAppointmentStatus(e.target.value)
                            }
                          >
                            <option value="New Appointment">
                              New Appointment
                            </option>
                            <option value="Confirmed">Confirmed</option>
                            <option value="Arrived">Arrived</option>
                            <option value="Started">Started</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="no-invoice">
                        <img src={invoice} alt="" />
                        <p>No invoice issued yet</p>
                      </div>
                    </>
                  )}

                  <div className="appointment-buttons">
                    <span>
                      Total: {currency} {servicePrice} ({" "}
                      {Math.floor(parseInt(serviceDuration) / 60) === 0 ? (
                        <>{parseInt(serviceDuration) % 60} min</>
                      ) : (
                        <>
                          {Math.floor(parseInt(serviceDuration) / 60)}h{" "}
                          {parseInt(serviceDuration) % 60 === 0 ? (
                            ""
                          ) : (
                            <>,{parseInt(serviceDuration) % 60} min</>
                          )}
                        </>
                      )}
                      )
                    </span>

                    <div className="footer-button">
                      <DropdownButton
                        id="dropdown-basic-button-2"
                        title="More Option"
                      >
                        {!paymentMethod ? (
                          <>
                            <Link
                              to={`/partners/editappointment?appointmentId=${ID}`}
                            >
                              Edit Appointment
                            </Link>

                            <Link to="/partners/calendar">Reschedule</Link>
                            {appointmentStatus !== "Canceled" && (
                              <Dropdown.Item onClick={() => setShow(true)}>
                                Cancel
                              </Dropdown.Item>
                            )}
                          </>
                        ) : (
                          <Link to={`/partners/calendar?rebook=${ID}`}>
                            Rebook Appointment
                          </Link>
                        )}
                      </DropdownButton>

                      {paymentMethod ? (
                        <Button
                          className="btn-perple"
                          onClick={handleViewInvoice}
                        >
                          View Invoice
                        </Button>
                      ) : (
                        <Button className="btn-perple" onClick={handleCheckout}>
                          Checkout
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="appointment-card">
                  <div className="appointment-body">
                    <div className="view-appointment-form">
                      <h4>{appointmentDate}</h4>
                      {/* <h4>{new Date(appointmentDate).toString}</h4> */}

                      <div className="view-appointment-list">
                        <div className="customer-appointment">
                          {appointmentPanel.map((el) => (
                            <div className="customer-appoint-box">
                              <div className="appoint-box-name">
                                <h4>{el.serviceId.serviceName}</h4>
                                <span>
                                  {el.startTime} |{" "}
                                  {Math.floor(parseInt(el.duration) / 60) ===
                                  0 ? (
                                    <>{parseInt(el.duration) % 60} min </>
                                  ) : (
                                    <>
                                      {Math.floor(parseInt(el.duration) / 60)}h{" "}
                                      {parseInt(el.duration) % 60 === 0 ? (
                                        ""
                                      ) : (
                                        <>,{parseInt(el.duration) % 60} min</>
                                      )}
                                    </>
                                  )}
                                  with {el.staffId.firstname}
                                </span>
                              </div>
                              {el.discountType && (
                                <>
                                  <div className="appoint-box-price">
                                    <h6>
                                      {currency}{" "}
                                      <span>
                                        {el.serviceId.specialPrice
                                          ? el.serviceId.specialPrice
                                          : el.serviceId.priceFrom}
                                      </span>
                                    </h6>
                                  </div>
                                </>
                              )}
                              <div className="appoint-box-price">
                                <h6>
                                  {currency}{" "}
                                  {el.serviceId.specialPrice
                                    ? parseInt(el.serviceId.specialPrice) === 0
                                      ? `${el.serviceId.specialPrice} Free`
                                      : el.disGone >= 0
                                      ? el.discountType === "%"
                                        ? el.serviceId.specialPrice -
                                          el.serviceId.specialPrice *
                                            (parseInt(el.disGone) / 100)
                                        : el.serviceId.specialPrice - el.disGone
                                      : el.serviceId.specialPrice
                                    : parseInt(el.serviceId.priceFrom) === 0
                                    ? `${el.serviceId.priceFrom} Free`
                                    : el.disGone >= 0
                                    ? el.discountType === "%"
                                      ? el.serviceId.priceFrom -
                                        el.serviceId.priceFrom *
                                          (parseInt(el.disGone) / 100)
                                      : el.serviceId.priceFrom - el.disGone
                                    : el.serviceId.priceFrom}
                                </h6>
                              </div>
                              {/* {el.serviceId.specialPrice ? (
                                <>
                                  {' '}
                                  <div className="service-discount">
                                    <p>{el.serviceId.priceFrom}</p>
                                  </div>
                                  <div className="appoint-box-price">
                                    <h6>
                                      {currency}
                                      {' '}
                                      {el.serviceId.specialPrice}
                                    </h6>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <div className="appoint-box-price">
                                    <h6>

                                      {el.serviceId.priceFrom > 0 ? (
                                        <>
                                          {currency}
                                          {' '}
                                          {el.serviceId.priceFrom}
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          {el.serviceId.priceFrom}
                                          (Free)
                                          {' '}
                                        </>
                                      )}
                                    </h6>
                                  </div>
                                  {' '}
                                </>
                              )} */}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="appointment-history">
                        <h4>Appointment History</h4>
                        <p>
                          Booked by {customerFirstName}, reference {reference}{" "}
                          {cashDate !== "N/A" && (
                            <>
                              at{" "}
                              {new Date(cashDate).toString().substring(0, 15)}{" "}
                              at {moment(new Date(cashDate)).format("HH:mm")}
                            </>
                          )}
                        </p>
                      </div>
                      {appointmentNotes && (
                        <div className="appointment-history">
                          <h4>Appointment Note</h4>
                          <p>{appointmentNotes}</p>
                        </div>
                      )}
                      {outServiceAddress && (
                        <div className="appointment-history">
                          <h4>OutService Address</h4>
                          <p>{outServiceAddress}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            className="discount-12 cancle-popup"
          >
            <Modal.Header closeButton>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                Cancel Appointment
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <CancelAppointment
                setCancelStatus={setCancelStatus}
                cancelStatus={cancelStatus}
                setShow={setShow}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default ViewAppointment;

function CancelAppointment({ setCancelStatus, cancelStatus, setShow }) {
  const [cancels, setCancels] = useState([]);

  const handleCancel = async (e) => {
    e.preventDefault();
    setCancelStatus(!cancelStatus);
    const value = queryString.parse(window.location.search);
    const userid = value.id;
    const payload = { userid, cancelStatus };

    const response = await partnerInstance().post(
      "/cancelAppointment",
      payload
    );
    const { code, msg } = response.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      history.push("/partners/calendar");
    }
  };

  useEffect(() => {
    const getPaymentType = async () => {
      const paymentData = await partnerInstance().get(
        "/getCancellationReasons"
      );
      const { code, cancellationReason } = paymentData.data;
      if (code === 200) {
        setCancels(cancellationReason);
      }
    };
    getPaymentType();
  }, []);

  return (
    <Form onSubmit={handleCancel}>
      <div>
        <Form.Group>
          <select
            className="browser-default custom-select"
            // onChange={(e) => setDefaultTaxValue(e.target.value)}
            // value={defaultTaxValue}
          >
            <option value="No Reason Provided">No Reason Provided</option>
            {cancels.map((item, i) => (
              <option key={i} value={item._id}>
                {item.Name}
              </option>
            ))}
          </select>
        </Form.Group>
      </div>
      <div className="popup-btn text-right">
        <Button
          onClick={() => setShow(false)}
          className="cancel-appointment btn-perple"
        >
          Cancel
        </Button>
        <Button type="submit" className="btn-perple">
          Ok
        </Button>
      </div>
    </Form>
  );
}
