import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';
import Loader from '../../loader/loader';

const AdminDashboard = ({ name }) => {
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalPartners, setTotalPartners] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [adminCommission, setAdminCommission] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllCustomers = async () => {
    setLoading(true);
    const partnerData = await adminInstance().get('/adminDashboard');
    const {
      code, allPartners, allUsers, allAppointments, totalComission,
    } = partnerData.data;
    if (code === 200) {
      setTotalCustomers(allUsers);
      setTotalPartners(allPartners);
      setTotalAppointments(allAppointments);
      setAdminCommission(Math.round(totalComission));
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  if (loading) {
    return (
      <AdminWrapper>
        <Loader />
      </AdminWrapper>
    );
  }
  return (
    <AdminWrapper name={name}>
      <div className="admin-dashboard">
        <div className="container">
          <h2 className="super-admin-title">Dashboard</h2>
          <div className="row">

            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="admin-card">
                <Link to="/admin/appointments">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col text-left">
                        <span className="fa fa-calendar text-muted" />
                      </div>
                      <div className="col-auto">
                        <h6 className="text-muted mb-2">Total Appointments</h6>
                        <span className="font-weight-700 h4 mb-0">{totalAppointments}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="admin-card">
                <Link to="/admin/partner">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col text-left">
                        <span className="fa fa-handshake-o text-muted" />
                      </div>
                      <div className="col-auto">
                        <h6 className="text-muted mb-2">Total Partners</h6>

                        <span className="font-weight-700 h4 mb-0">{totalPartners}</span>
                      </div>

                    </div>
                  </div>
                </Link>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6  col-lg-6">
              <div className="admin-card">
                <Link to="/admin/bussinessTypes">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col text-left">
                        <span className="fa fa-user text-muted" />
                      </div>
                      <div className="col-auto">
                        <h6 className="text-muted mb-2">Total Bussiness Types</h6>
                        <span className="font-weight-700 h4 mb-0">
                          {adminCommission}
                        </span>
                      </div>

                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-md-6  col-lg-6">
              <div className="admin-card">
                <Link to="/admin/customer">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col text-left">
                        <span className="fa fa-users text-muted" />
                      </div>
                      <div className="col-auto">
                        <h6 className="text-muted mb-2">Total Customers</h6>
                        <span className="font-weight-700 h4 mb-0">{totalCustomers}</span>
                      </div>

                    </div>
                  </div>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminDashboard;
