import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
// import logo from '../../../assets/partner/logo.png';
import PartnerContext from "../../../context/context";
import cross from "../../../assets/cross2.png";
// import logo from '../../../assets/loader-new.png';
// import menu from '../../../assets/partner/menu-icon.png';
import logobig from "../../../assets/logo-img.png";
import logoicon from "../../../assets/logo-icon.png";
import dashboard from "../../../assets/dashboard.png";
// import calendar from "../../../assets/calendar.png";
import service from "../../../assets/service.png";
// import voucher from "../../../assets/voucher.png";
import customer from "../../../assets/customer.png";
import associate from "../../../assets/associate.png";
import revenue from "../../../assets/revenue.png";
import configure from "../../../assets/configure.png";
import creditcard from "../../../assets/creditcard.png";

const PartnerMenu = ({ menutoggle, setMenuToggle }) => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  // const [data, setData] = useState(username.isStepComplete);

  const renderTooltip = (props) => (
    <Tooltip id="BB" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <div className={`admin-menu ${menutoggle ? "menu-show" : ""}`}>
      <div className="menu-logo">
        <span onClick={() => setMenuToggle(!menutoggle)} role="presentation">
          <img src={logoicon} alt="Menu" className="sidebar-logo-icon" />
          <img src={cross} alt="close" className="sidebar-cross-icon" />
          <img src={logobig} alt="" className="sidebar-full-logo" />
        </span>
      </div>

      <div className="admin-menu-box">
        <div className="menu-list">
          <ul>
            <Nav>
              {sections && sections.find((el) => el.sectionname === "Home") && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip("Dashboard")}
                >
                  <NavLink to="/partners/dashboard" id="BB">
                    <img src={dashboard} alt="" />
                    {/* <i className="fa fa-home" /> */}{" "}
                    <span> Dashboard </span>
                  </NavLink>
                </OverlayTrigger>
              )}

              {sections &&
                sections.find(
                  (el) => el.sectionname === "Access Own Calendar"
                ) && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Appointments")}
                  >
                    <NavLink to="/partners/calendar">
                      {/* <img src={calendar} alt="" /> */}
                      <i className="fa fa-calendar" />{" "}
                      <span> Appointments</span>
                    </NavLink>
                  </OverlayTrigger>
                )}

              {sections && sections.find((el) => el.sectionname === "Clients") && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip("Services")}
                >
                  <NavLink to="/partners/services">
                    <img src={service} alt="" />
                    <span>Services</span>
                  </NavLink>
                </OverlayTrigger>
              )}

              {sections &&
                sections.find(
                  (el) => el.sectionname === "Display Voucher List"
                ) && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Voucher")}
                  >
                    <NavLink to="/partners/voucher">
                      {/* <img src={voucher} alt="" /> */}
                      <i className="fa fa-gift" /> <span>Voucher</span>
                    </NavLink>
                  </OverlayTrigger>
                )}

              {sections && sections.find((el) => el.sectionname === "Clients") && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip("Cutomers")}
                >
                  <Link to="/partners/customer">
                    <img src={customer} alt="" />
                    {/* <i className="fa fa-user" /> */} <span>Customers</span>
                  </Link>
                </OverlayTrigger>
              )}

              {sections &&
                sections.find((el) => el.sectionname === "Staff Members") && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Associates")}
                  >
                    <NavLink to="/partners/staff">
                      <img src={associate} alt="" />
                      {/* <i className="fa fa-briefcase" /> */}{" "}
                      <span> Associates</span>
                    </NavLink>
                  </OverlayTrigger>
                )}

              {sections &&
                sections.find((el) => el.sectionname === "Point of Sale") && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Revenue")}
                  >
                    <NavLink to="/partners/revenue">
                      <img src={revenue} alt="" />
                      {/* <i className="fa fa-money" /> */}{" "}
                      <span> Revenue</span>
                    </NavLink>
                  </OverlayTrigger>
                )}

              {sections &&
                sections.find((el) => el.sectionname === "Account Setup") && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Configuration")}
                  >
                    <NavLink to="/partners/setup">
                      <img src={configure} alt="" />
                      {/* <i className="fa fa-cog" /> */}{" "}
                      <span> Configuration</span>
                    </NavLink>
                  </OverlayTrigger>
                )}
              <span className="sep" />

              {sections &&
                sections.find(
                  (el) => el.sectionname === "Billing and card processing"
                ) && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Online Appointlee")}
                  >
                    <NavLink to="/partners/onlinelisting">
                      {/* <img src={calendar} alt="" /> */}
                      <i className="fa fa-calendar" />{" "}
                      <span>Online Appointlee</span>
                    </NavLink>
                  </OverlayTrigger>
                )}

              {sections &&
                sections.find((el) => el.sectionname === "Online Booking") && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Online Payment")}
                  >
                    <NavLink to="/partners/connect">
                      <img src={creditcard} alt="" />{" "}
                      <span>Online Payment</span>
                    </NavLink>
                  </OverlayTrigger>
                )}
            </Nav>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PartnerMenu;
