/* eslint-disable radix */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
import React, { useEffect, useState, useContext } from "react";
import { Table, Form, Button, DropdownButton } from "react-bootstrap";
//import { Link } from 'react-router-dom';
import moment from "moment";
import ReactExport from "react-export-excel";
import { CSVLink } from "react-csv";
import Pdf from "react-to-pdf";
import close from "../../../assets/close.png";
import { partnerInstance } from "../../../config/axios";
import { validateRange } from "../../../function/validate";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";

const ref = React.createRef();
const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const RevenueAppointment = ({ appointments }) => {
  const [app, setApp] = useState([]);
  const { username } = useContext(PartnerContext);
  const { currency } = username;

  useEffect(() => {
    setApp(appointments);
  }, [appointments]);

  const App = (data) => {
    history.push(`/partners/viewappointment?id=${data}`);
  };

  const getAppointmentstatus = (data) => {
    if (data === "Canceled") {
      return <span className=" badge cancelled">Cancelled</span>;
    }
    if (data === "Confirmed") {
      return <span className="badge completed">Confirmed</span>;
    }
    if (data === "Started") {
      return <span className="badge started">Started</span>;
    }
    if (data === "Completed") {
      return <span className="badge completed">Completed</span>;
    }
    if (data === "Arrived") {
      return <span className="badge started">Arrived</span>;
    }
    return <span className="badge new">New</span>;
  };

  return (
    <div className="revenue-appointment">
      <AppointmentsFilter filterApp={appointments} setApp={setApp} />
      <div className="row">
        <div className="col-md-12">
          <div className="revenue-body">
            <h4>Appointments</h4>
            <div className="revenue-table" ref={ref}>
              <Table responsive="lg" CellSpacing="0" width="100%">
                {app.length > 0 ? (
                  <>
                    <thead>
                      <tr>
                        <th>Ref #</th>
                        <th>Client</th>
                        <th>Service</th>
                        <th className="text-center">Date/Time</th>
                        {/* <th>Time</th> */}
                        <th className="text-center"> Duration</th>
                        <th className="text-center">Location</th>
                        <th>Staff</th>
                        <th>Price</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    {app &&
                      app.map((el) => (
                        <tbody>
                          <tr>
                            <td role="presentation" onClick={() => App(el.id)}>
                              <a href="javascript:void(0)" className="appoi-id">
                                {el.ref}
                              </a>
                            </td>
                            <td>{el.client}</td>
                            <td>{el.service}</td>
                            <td className="text-center">
                              {el.date}
                              <br />
                              {el.time}
                            </td>
                            {/* <td>{el.time}</td> */}
                            <td className="text-center">
                              {Math.floor(parseInt(el.duration) / 60) === 0 ? (
                                <>{parseInt(el.duration) % 60} min</>
                              ) : (
                                <>
                                  {Math.floor(parseInt(el.duration) / 60)}h{" "}
                                  {parseInt(el.duration) % 60 === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      ,{parseInt(el.duration) % 60}
                                      min
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                            <td className="text-center">{el.location}</td>
                            <td>{el.staff}</td>
                            <td>
                              {currency} {el.price}
                            </td>
                            <td className="text-center">
                              {getAppointmentstatus(el.appointmentStatus)}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </>
                ) : (
                  <p>No appointments Found</p>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueAppointment;

const AppointmentsFilter = ({ filterApp, setApp }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menutoggle, setMenuToggle] = useState(false);
  const [rangeName, setRangeName] = useState("all_past_time");
  const [sidebartoggle, setSidebarToggle] = useState(false);
  const copy = [...filterApp];

  const serviceSearch = (e) => {
    setSearchKeyword(e);
    if (parseInt(e.length) > 0) {
      const filteredServices = copy.filter(
        (service) =>
          service.client.toLowerCase().indexOf(e.toLowerCase()) !== -1
      );
      setApp(filteredServices);
    } else {
      setApp(filterApp);
    }
  };

  const handleRefresh = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  // excel format

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(copy[0]);
    const filterColsByKey = columns.filter((c) => c !== "invoice");
    return filterColsByKey; // OR return columns
  };
  const options = {
    orientation: "landscape",
    format: [1000, 1000],
  };

  return (
    <div className="appointments-filter">
      <div className="revenue-filter">
        <div className="filter-left-block">
          <div className="calendar-box-view">
            <div className="calendar-filter-content">
              <div className="calendar-box">
                <span onClick={() => setMenuToggle(!menutoggle)}>
                  {rangeName}
                  <i className="fa fa-calendar" />
                </span>
              </div>
            </div>

            <div className="revenue-search">
              <Form>
                <input
                  type="text"
                  placeholder="Search by Reference or Client"
                  className="form-control"
                  value={searchKeyword}
                  onChange={(e) => serviceSearch(e.target.value)}
                  onKeyDown={handleRefresh}
                />
                <Button>
                  <i className="fa fa-search" />
                </Button>
              </Form>
            </div>
          </div>
        </div>

        <div className="filter-right-block">
          <div className="header-buttons ">
            <DropdownButton
              className="dark-btn gbu6"
              id="dropdown-basic-button-export"
              title="Export"
            >
              <Pdf
                targetRef={ref}
                filename="appointment-list.pdf"
                options={options}
              >
                {({ toPdf }) => <button onClick={toPdf}>PDF</button>}
              </Pdf>
              <h6>
                {filterApp.length > 0 && (
                  <ExcelFile
                    filename="Appointlee-Appointments"
                    element={<button>Excel</button>}
                  >
                    <ExcelSheet data={filterApp} name="appointment-list">
                      {filterColumns(filterApp).map((col) => (
                        <ExcelColumn label={camelCase(col)} value={col} />
                      ))}
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </h6>
              <CSVLink data={filterApp} filename="appointment-list.csv">
                CSV
              </CSVLink>
            </DropdownButton>
            <Button
              variant="default"
              onClick={() => setSidebarToggle(!sidebartoggle)}
            >
              Filter <i className="fa fa-sliders" />
            </Button>
          </div>
        </div>
      </div>

      <FilterItems
        menutoggle={menutoggle}
        setMenuToggle={setMenuToggle}
        App={filterApp}
        setApp={setApp}
        setRangeName={setRangeName}
        rangeName={rangeName}
      />
      <SidebarFilter
        sidebartoggle={sidebartoggle}
        setSidebarToggle={setSidebarToggle}
        Staff={filterApp}
        setApp={setApp}
      />
    </div>
  );
};

const FilterItems = ({
  menutoggle,
  setMenuToggle,
  App,
  setApp,
  rangeName,
  setRangeName,
}) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(4, "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [errors, setErrors] = useState({});

  const setRange = (data) => {
    setRangeName(data);
    if (data === "today") {
      setStartDate(moment(new Date()).format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "yesterday") {
      setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_7_days") {
      setStartDate(moment().subtract(7, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_30_days") {
      setStartDate(moment().subtract(30, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_90_days") {
      setStartDate(moment().subtract(90, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_month") {
      setStartDate(
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
      );
      setEndDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    } else if (data === "last_year") {
      setStartDate(
        moment().subtract(1, "year").startOf("year").format("YYYY-DD-MM")
      );
      setEndDate(
        moment().subtract(1, "year").endOf("month").format("YYYY-MM-DD")
      );
    } else if (data === "week_to_date") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "month_to_date") {
      setStartDate(moment().clone().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "quarter_to_date") {
      setStartDate(moment().startOf("quarter").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "year_to_date") {
      setStartDate(moment().startOf("year").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "all_past_time") {
      setStartDate(moment().subtract(4, "year").format("YYYY-MM-DD"));
      setEndDate(moment().add(10, "year").format("YYYY-MM-DD"));
    }
  };
  const getDate = () => {
    const old = [...App];
    const validation = validateRange({
      startDate,
      endDate,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const filterAppByDate = old.filter(
      (el) =>
        moment(el.date) >= moment(startDate) &&
        moment(el.date) <= moment(endDate)
    );
    setApp(filterAppByDate);
    setMenuToggle(!menutoggle);
  };

  const Can = () => {
    setRangeName("all_past_time");
    setApp(App);
    setMenuToggle(!menutoggle);
  };

  const Start = (e) => {
    setStartDate(e.target.value);
  };

  const End = (e) => {
    setEndDate(e.target.value);
    setRangeName(`${startDate}  To  ${e.target.value}`);
  };

  return (
    <div className={`filter-items ${menutoggle ? "filter-expand" : ""}`}>
      <Form>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Date range</label>
              <select
                className="form-control"
                onChange={(e) => setRange(e.target.value)}
                value={rangeName}
              >
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last_7_days">Last 7 days</option>
                <option value="last_30_days">Last 30 days</option>
                <option value="last_90_days">Last 90 days</option>
                <option value="last_month">Last month</option>
                <option value="last_year">Last year</option>
                <option value="week_to_date">Week to date</option>
                <option value="month_to_date">Month to date</option>
                <option value="quarter_to_date">Quarter to date</option>
                <option value="year_to_date">Year to date</option>
                <option value="all_past_time">All time</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Starting</label>
              <input
                type="date"
                placeholder=""
                className="form-control"
                value={startDate}
                onChange={(e) => Start(e)}
              />
            </div>
            {errors.startDate && <p className="error">{errors.startDate}</p>}
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Ending</label>
              <input
                type="date"
                placeholder=""
                className="form-control"
                value={endDate}
                onChange={(e) => End(e)}
              />
            </div>
            {errors.endDate && <p className="error">{errors.endDate}</p>}
          </div>

          <div className="col-md-12 text-right filter-buttons">
            <Button onClick={Can} variant="default" className="btn-perple">
              Cancel
            </Button>
            <Button variant="primary" onClick={getDate} className="btn-perple">
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

const SidebarFilter = ({ sidebartoggle, setSidebarToggle, setApp, Staff }) => {
  const [staffs, setStaffs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectstaff, setSelectStaff] = useState("");
  const [loc, setLoc] = useState("");
  const getStaff = async () => {
    const staffData = await partnerInstance().get("/getStaffs");
    const { code, stafflist } = staffData.data;
    if (code === 200) {
      setStaffs(stafflist);
    }
  };
  const getLocations = async () => {
    const locat = await partnerInstance().get("/getPartnerLocations");
    const { code, partnerData } = locat.data;
    if (code === 200) {
      setLocations(partnerData.partnerLocation);
    }
  };
  useEffect(() => {
    getStaff();
    getLocations();
  }, []);

  const filterStaff = () => {
    const old = [...Staff];
    if (selectstaff === "All" && loc === "All") {
      setApp(old);
    } else if (selectstaff === "All" && loc !== "All") {
      const filterStaffs = old.filter((item) => item.location === loc);
      setApp(filterStaffs);
    } else if (selectstaff !== "All" && loc === "All") {
      const filterStaffs = old.filter((item) => item.staff === selectstaff);
      setApp(filterStaffs);
    } else {
      const filterStaffs = old.filter(
        (item) => item.staff === selectstaff && item.location === loc
      );
      setApp(filterStaffs);
    }

    setSidebarToggle(!sidebartoggle);
  };

  const Close = () => {
    setApp(Staff);
    setLoc("All");
    setSelectStaff("All");
    setSidebarToggle(!sidebartoggle);
  };

  return (
    <div className={`sidebar-items ${sidebartoggle ? "sidebar-expand" : ""}`}>
      <div className="sidebar-title">
        <h2>Filters</h2>
        <span onClick={() => setSidebarToggle(!sidebartoggle)}>
          <img src={close} alt="close" />
        </span>
      </div>
      <Form>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Location</label>
              <select
                className="form-control"
                onChange={(e) => setLoc(e.target.value)}
                value={loc}
              >
                <option value="All">All</option>
                {locations.map((item) => (
                  <option value={item.locationName}>{item.locationName}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label>Staff</label>
              <select
                className="form-control"
                onChange={(e) => setSelectStaff(e.target.value)}
                value={selectstaff}
              >
                <option value="All">All</option>
                {staffs.map((el) => (
                  <option value={el.firstname}>{el.firstname}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label>Channel</label>
              <select className="form-control">
                <option value="dubai">All Channels</option>
                <option value="online">All online channel</option>
              </select>
            </div>
          </div>

          <div className="col-md-12 text-right filter-buttons">
            <Button onClick={Close} variant="default" className="btn-perple">
              Clear
            </Button>
            <Button
              variant="primary"
              onClick={filterStaff}
              className="btn-perple"
            >
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
