import React from "react";
import { Button } from "react-bootstrap";
// import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
import BookingScreen from "../../../assets/booking-new.png";
import cross from "../../../assets/cross2.png";
import history from "../../../config/history";
import { partnerInstance } from "../../../config/axios";
import "./signupmain.css";
import Helmet from "react-helmet";
import check from "../../../assets/check-new.png";

const SignupOnlineBooking = () => {
  const Step8 = async () => {
    // eslint-disable-next-line
    const update = await partnerInstance().post("/updateIsStep", {
      isStepComplete: true,
    });
    history.push("/partners/dashboard");
  };
  const Step6 = () => {
    history.push("/partners/pricing-package");
  };
  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="partners-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="signup-block-container online-booking-container">
                  <div className="cross-icon">
                    <Link to="/partners/dashboard">
                      <img src={cross} alt="" />
                    </Link>
                  </div>
                  <div className="sb-left">
                    <h2>
                      You’re All Setup For More Benefits Proceed to Online
                      Booking
                    </h2>
                    <ul className="booking-list">
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>Online Booking</h3>
                          <p>
                            Appointlee mobile app plus website and social page
                            integration
                          </p>
                        </div>
                      </li>
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>No Show Protection</h3>
                          <p>
                            Eleminate no shows and late cancellations by
                            charging clients
                          </p>
                        </div>
                      </li>
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>Intelligent Marketing</h3>
                          <p>
                            Send campaigns to boots sales and fill your calendar
                          </p>
                        </div>
                      </li>
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>Integrated Card Processing</h3>
                          <p>
                            Effortless and secure in-app payments with
                            Appointlee Pay
                          </p>
                        </div>
                      </li>
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>SMS text Messages</h3>
                          <p>
                            Automatically reminds clients about thier
                            appointments
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div className="sb-btn">
                      <Button onClick={() => Step6()}>Start Now </Button>
                      <Button onClick={() => Step8()}>Skip</Button>
                    </div>
                  </div>
                  <div className="sb-right">
                    <div className="sb-right-img">
                      <img src={BookingScreen} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupOnlineBooking;
