import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';
// import Loader from '../../loader/loader';

const AdminAppointments = ({ name }) => {
  const [dataa, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);

  const monthBalanceListing = useCallback(async () => {
    const payload = {
      skip,
      limit,
    };
    const saveCategory = await adminInstance().post(
      '/appointmentsListing',
      payload,
    );
    const {
      code, data, length,
    } = saveCategory.data;
    if (code === 200) {
      setData(data);
      setTotalCount(length);
    }
  }, [skip, limit]);

  useEffect(() => {
    monthBalanceListing();
  }, [monthBalanceListing]);

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);

  return (
    <AdminWrapper name={name}>
      <div className="Admin-partners">
        <div className="appointment-admin">
          <div className="container">
            <div className="back-btn">
              <h2 className="admin-title">Appointments</h2>
              <Link to="/admin/dashboard"><h2>Back</h2></Link>
            </div>
            <div className="row">
              {/* <input
              type="month"
              value={current}
              onChange={(e) => setRange(e)}
            /> */}
              <div className="col-sm-12 col-md-12  col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* <div className="partner-header">
                    <h2>Account</h2>
                  </div> */}
                    <div className="partner-table2">
                      {dataa && dataa.length > 0 ? (
                        <Table responsive="lg">
                          <thead>
                          <tr>
                            <th className="text-left">Date</th>
                            <th className="text-left">Partner</th>
                            <th className="text-left">Customer</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Amount</th>
                            {/* <th className="text-center">(Commission + Fixed Charge)</th> */}
                          </tr>
                        </thead>
                          {dataa && dataa.map((el) => (
                          <tbody>
                            <tr>
                              <td className="text-left">
                                {el.appointmentDate}
                                {' '}
                                {el.year}
                              </td>
                              <td className="text-left">{el.partnerId.firstname}</td>
                              <td className="text-left">{el.customerId.firstname}</td>
                              <td className="text-center">{el.appointmentStatus}</td>
                              <td className="text-center">
                                {el.partnerId.currency}
                                {' '}
                                {el.totalAmount}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        </Table>
                      ) : (
                        <div className="noservice-found"><h3>No Appointments</h3></div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="store-pagination col-md-12 text-center">
              {totalCount >= limit && (
              <div className="row">
                <div className="col-md-12">
                  <div className="pagination-section">
                    <ReactPaginate
                      previousLabel="← Previous"
                      nextLabel="Next →"
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName="pagination-chk"
                      previousLinkClassName="pagination__link"
                      nextLinkClassName="pagination__link"
                      disabledClassName="pagination__link--disabled"
                      activeClassName="pagination__link--active"
                    />
                  </div>
                </div>
              </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminAppointments;
