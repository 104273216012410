import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../admin.css';
import Helmet from 'react-helmet';
import AdminHeader from '../adminheader/header';
import AdminMenu from '../adminmenu/menu';

const AdminWrapper = ({ children, name }) => {
  const [menutoggle1, setMenuToggle1] = useState(false);


  return (
    <div className="super-admin-wrapper">
      <Helmet>
        <body className="super-admin-view" />
      </Helmet>

      <div className="super-admin-content-view">
        <AdminMenu menutoggle1={menutoggle1} />

        <div
          className={`super-admin-container ${menutoggle1 ? `admin-wrapper-expand` : ``}`}
        >
          <div className="admin-header-view">
            <AdminHeader
              setMenuToggle1={setMenuToggle1}
              menutoggle1={menutoggle1}
              name={name}
            />
          </div>

          <div className="super-admin-content">{children}</div>
        </div>
      </div>
    </div>
  );
};
AdminWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
export default AdminWrapper;
