import React, { useEffect, useState } from 'react';
import {
  Table,
} from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';
// import Loader from '../../loader/loader';

const MonthListing = ({ name }) => {
  const [dataa, setData] = useState([]);
  //   const now = new Date();
  //   const currentYear = now.getFullYear();
  //   const currentMonth = (`0${now.getMonth() + 1}`).slice(-2);
  //   const [current, setCurrent] = useState(`${currentYear}-${currentMonth}`);
  //   const monthData = `${currentYear}-${currentMonth}`;
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf('/') + 1);
  useEffect(() => {
    monthBalanceListing();
    // eslint-disable-next-line
  }, []);

  const monthBalanceListing = async () => {
    const payloadd = {
      Id: id,
    };

    const saveCategory = await adminInstance().post(
      '/monthBalanceListing',
      payloadd,
    );
    const {
      code, data,
    } = saveCategory.data;
    if (code === 200) {
      setData(data);
    }
  };

  return (
    <AdminWrapper name={name}>
      <div className="Admin-partners">
        <div className="container">
          <div className="back-btn">
            <h2 className="admin-title">Account</h2>
            <Link to="/admin/partner"><h2>Back</h2></Link>
          </div>
          <div className="row">
            {/* <input
              type="month"
              value={current}
              onChange={(e) => setRange(e)}
            /> */}
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="partner-header">
                    <h2>Account</h2>
                  </div> */}
                  <div className="partner-table">
                    {dataa && dataa.length > 0 ? (
                      <Table responsive="lg">
                        <thead>
                          <tr>
                            <th className="text-left">Month Name</th>
                            <th className="text-left">Monthly Bill</th>
                            <th className="text-left">Currency</th>
                            <th className="text-center">Details</th>
                            {/* <th className="text-center">Amount</th>
                            <th className="text-center">(Commission + Fixed Charge)</th> */}
                          </tr>
                        </thead>
                        {dataa && dataa.map((el) => (
                          <tbody>
                            <tr>
                              <td className="text-left">
                                {moment(el.day, 'MM').format('MMMM')}
                                {' '}
                                {el.year}
                              </td>
                              <td className="text-left">{el.value}</td>
                              <td className="text-left">{el.currency}</td>
                              <td className="text-center">
                                <Link
                                  className="delete-btn act-balance"
                                  to={{
                                    pathname: `/admin/transactions/${id}`,
                                    state: {
                                      month: el.day,
                                      year: el.year,
                                    },
                                  }}
                                >
                                  {' '}
                                  Details
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    ) : (
                      <div className="noservice-found"><h3>No Transactions</h3></div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="store-pagination col-md-12 text-center">
            {/* {totalCount >= limit && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel="← Previous"
                    nextLabel="Next →"
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName="pagination-chk"
                    previousLinkClassName="pagination__link"
                    nextLinkClassName="pagination__link"
                    disabledClassName="pagination__link--disabled"
                    activeClassName="pagination__link--active"
                  />
                </div>
              </div>
            </div>
            )} */}

          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default MonthListing;
