import React, { useState, useEffect, useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { validatePaymentType } from "../../../function/validate";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";
import "./style.css";
import { partnerInstance } from "../../../config/axios";

const PaymentTaxes = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [showEdit, setShowEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentname, setPaymentname] = useState("");
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShow(true);
    setDisabled(false);
    setPaymentname("");
    setErrors("");
  };

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getPaymentType();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const getPaymentType = async () => {
    setLoading(true);
    const paymentData = await partnerInstance().get("/getPaymentType");
    const { code, paymentType } = paymentData.data;
    if (code === 200) {
      setPaymentTypes(paymentType);
      setLoading(false);
    }
  };

  const addPaymenttype = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const payload = {
      paymentname,
    };
    const validation = validatePaymentType({
      paymentname,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      setDisabled(false);

      return;
    }
    const paymenttype = await partnerInstance().post(
      "/addPaymentType",
      payload
    );
    const { code, msg } = paymenttype.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      setPaymentname("");
      setShow(false);
      getPaymentType();
      setDisabled(false);
      // getInvoiceSequencing();
      // history.push('/partners/staff');
      // clearValues();
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const handleShowEdit = (id, paymentnamee) => {
    setPaymentTypeId(id);
    setPaymentname(paymentnamee);
    setDisabled(false);
    setShowEdit(true);
    setErrors("");
  };
  const handleShowDelete = (id) => {
    setPaymentTypeId(id);
    setShowDelete(true);
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Payment Types">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="payment-tax">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="admin-title">
                {/* Payment Types */}
                <Button onClick={handleShow} className="btn-perple">
                  Add Payment Type
                </Button>
              </h2>
              <div className="partner-table">
                <Table responsive="lg">
                  <thead>
                    <tr>
                      <th className="text-left">Seriel No.</th>
                      <th className="text-center">Payment Type</th>
                      <th className="text-center">Action</th>
                      {/* <th>Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {paymentTypes.map((el, i) => (
                      <tr>
                        <td className="text-left">{i + 1}</td>
                        <td className="text-center">{el.paymentname}</td>
                        <td className="text-center">
                          {i !== 0 ? (
                            <Button
                              onClick={() =>
                                handleShowEdit(el._id, el.paymentname)
                              }
                              className="btn-perple"
                            >
                              Edit
                              {/* <i className="fa fa-pencil-square-o" /> */}
                            </Button>
                          ) : (
                            ""
                          )}

                          {i !== 0 ? (
                            <Button
                              className="btn-perple"
                              onClick={() => handleShowDelete(el._id)}
                            >
                              Delete
                              {/* <i className="fa fa-trash" /> */}{" "}
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="pay-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Add Payment Type
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addPaymenttype}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Payment Type Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Cash"
                onChange={(e) => setPaymentname(e.target.value)}
                value={paymentname}
              />
              {errors.paymentname && (
                <p className="error">{errors.paymentname}</p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={disabled}
              type="submit"
              variant="primary"
              className="btn-perple"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEdit} onHide={handleClose} className="pay-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Edit Payment Type
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditPaymentType
            paymentTypeId={paymentTypeId}
            paymentname={paymentname}
            setPaymentname={setPaymentname}
            setShowEdit={setShowEdit}
            getPaymentType={() => getPaymentType()}
            setErrors={setErrors}
            errors={errors}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>

      <Modal show={showDelete} onHide={handleClose} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Delete Payment Type
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeletePayment
            paymentTypeId={paymentTypeId}
            setShowDelete={setShowDelete}
            getPaymentType={() => getPaymentType()}
            setDisabled={setDisabled}
            disabled={disabled}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </PartnerWrapper>
  );
};

export default PaymentTaxes;

function EditPaymentType({
  paymentTypeId,
  paymentname,
  setShowEdit,
  setPaymentname,
  disabled,
  setDisabled,
  getPaymentType,
  setErrors,
  errors,
}) {
  const updatePaymentType = async (e) => {
    setDisabled(true);
    try {
      e.preventDefault();
      const payloadd = {
        paymentname,
      };
      const validation = validatePaymentType({
        paymentname,
      });

      setErrors(validation.errors);
      if (!validation.isValid) {
        setDisabled(false);
        return;
      }

      const updatepaymenttype = await partnerInstance().put(
        `/editPaymentType/${paymentTypeId}`,
        payloadd
      );
      const { code, msg } = updatepaymenttype.data;
      if (code === 200) {
        // setCategoryname(categoryAdded.categoryname);
        toast.success(msg, { containerId: "B" });
        setShowEdit(false);
        getPaymentType();
        setDisabled(false);
        clearValues();
      } else if (code === 409) {
        toast.error(msg, { containerId: "B" });
      } else {
        toast.error(msg, { containerId: "B" });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const clearValues = () => {
    setPaymentname("");
  };
  return (
    <Form onSubmit={updatePaymentType}>
      <Form.Group>
        <Form.Label>Payment name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Tax name"
          onChange={(e) => setPaymentname(e.target.value)}
          value={paymentname}
        />
        {errors.paymentname && <p className="error">{errors.paymentname}</p>}
      </Form.Group>
      <div className="text-right p-0">
        <Button
          disabled={disabled}
          variant="primary"
          type="submit"
          className="btn-perple"
        >
          Update
        </Button>
      </div>
    </Form>
  );
}

function DeletePayment({
  paymentTypeId,
  getPaymentType,
  setShowDelete,
  setDisabled,
  disabled,
}) {
  const deleteIds = async (e) => {
    setDisabled(true);
    e.preventDefault();
    const payloadd = {
      deleteId: paymentTypeId,
    };
    const deleteReferral = await partnerInstance().post(
      "/deletePaymentType/",
      payloadd
    );

    const { code, msg } = deleteReferral.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      setShowDelete(false);
      getPaymentType();
      setDisabled(false);
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={deleteIds}>
      <p>Are You sure you want to delete ?</p>
      <div className="text-right p-0">
        <Button
          disabled={disabled}
          variant="primary"
          type="submit"
          className="btn-perple"
        >
          Delete
        </Button>
      </div>
    </Form>
  );
}

EditPaymentType.propTypes = {
  paymentTypeId: PropTypes.string.isRequired,
  paymentname: PropTypes.string.isRequired,
  setShowEdit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  setPaymentname: PropTypes.func.isRequired,
  getPaymentType: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
};

DeletePayment.propTypes = {
  paymentTypeId: PropTypes.string.isRequired,
  setShowDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  getPaymentType: PropTypes.func.isRequired,
};
