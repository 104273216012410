import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import logo from '../../assets/appointlee-new-logo1.png';
import footerimg from '../../assets/footer.png';
import appstore from '../../assets/playstore.png';
import googleplay from '../../assets/googleplay.png';

const Footer = () => (
  <>

    <div className="footer">

      <footer className="">
        <div className="footer-upper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-upper-content d-flex flex-wrap ">
                  <div className="footer-upper-left">
                    <Link className="footer-logo" to="">
                      <img src={logo} alt="logo" />
                    </Link>
                    <ul className="footer-upper-list">
                      <li className="footer-upper-item">
                        <Link className="footer-upper-link" to="">
                          For professionals
                        </Link>
                      </li>
                      <li className="footer-upper-item">
                        <Link className="footer-upper-link" to="">
                          Pricing
                        </Link>
                      </li>
                      <li className="footer-upper-item">
                        <Link className="footer-upper-link" to="">
                          Careers at Appointlee
                        </Link>
                      </li>
                      <li className="footer-upper-item">
                        <Link className="footer-upper-link" to="">
                          Booking Terms
                        </Link>
                      </li>

                    </ul>
                  </div>
                  <div className="footer-upper-right">
                    <div className="fur-box d-flex flex-wrap">
                      <div className="fur-box-left">
                        <h2>
                          Download
                          {' '}
                          <br />
                          Our App
                          {' '}
                        </h2>
                        <div className="store-box d-flex flex-wrap">
                          <Link className="store-content" to="">
                            <img src={appstore} alt="appstore" />
                          </Link>
                          <Link className="store-content" to="">
                            <img src={googleplay} alt="appstore" />
                          </Link>

                        </div>
                      </div>
                      <div className="fur-box-right">

                        <div className="fur-box-img">
                          <img src={footerimg} alt="" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="footer-box d-flex align-items-center justify-content-between">
                  <div className="copyright">
                    2020 Appointlee All rights reserved
                  </div>
                  <ul className="footer-nav-list d-flex ">
                    <li className="footer-nav-item">
                      <a className="footer-nav-link" href="#!">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="footer-nav-item">
                      <a className="footer-nav-link" href="#!">
                        Website Terms
                      </a>
                    </li>
                    <li className="footer-nav-item">
                      <a className="footer-nav-link" href="#!">
                        Customer  Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

        </div>

      </footer>

    </div>
    <div className="footer-login">
      <footer className="bg-color">
        <div className="footer-upper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-box d-flex align-items-center justify-content-between">
                  <div className="copyright">
                    2020 Appointlee All rights reserved
                  </div>
                  <ul className="footer-nav-list d-flex ">
                    <li className="footer-nav-item">
                      <a className="footer-nav-link" href="#!">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="footer-nav-item">
                      <a className="footer-nav-link" href="#!">
                        Website Terms
                      </a>
                    </li>
                    <li className="footer-nav-item">
                      <a className="footer-nav-link" href="#!">
                        Customer  Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

        </div>

      </footer>
    </div>
    <div className="social-icon-box">
      <div className="social-icon">
        <i className="fa fa-instagram" aria-hidden="true" />
        <i className="fa fa-facebook" aria-hidden="true" />
        <i className="fa fa-twitter" aria-hidden="true" />
        <i className="fa fa-youtube-play" aria-hidden="true" />
      </div>
    </div>
  </>
);

export default Footer;
