import React, {
  useState, useEffect, useCallback,
} from 'react';
import ReactStars from 'react-stars';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import profile from '../../../../assets/no-image.jpg';
import { partnerInstance } from '../../../../config/axios';
import { server } from '../../../../config/keys';

const ClientReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [limit] = useState(3);
  const [skip, setSkip] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const getReviews = useCallback(async () => {
    const payload = {
      skip,
      limit,
    };
    const locat = await partnerInstance().post('/clientReviews', payload);

    const { code, findData, length } = locat.data;
    if (code === 200) {
      setReviews(findData);
      setTotalCount(length);
    }
  }, [skip, limit]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);

  return (
    <div className="client-review-page">
      <div className="store-review">
        <div className="row">
          <div className="col-md-12">
            <div className="comments utf_listing_reviews">
              <ul>
                {reviews && reviews.length > 0 ? (
                  <>
                    {reviews && reviews.map((el) => (
                      <li>
                        <div className="avatar">
                          <img src={el.reviewFrom.image ? `${ server }/${ el.reviewFrom.image }` : profile} alt="" />
                        </div>
                        <div className="utf_comment_content">
                          <div
                            className="utf_star_rating_section"
                            data-rating="5"
                          >
                            <ReactStars
                              count={5}
                              value={el.rate}
                              size={25}


                              edit={false}
                              color2="#ffd700"
                            />
                          </div>

                          <div className="utf_by_comment">
                            {el.reviewFrom.firstname}
                            {' '}
                            {el.reviewFrom.lastname}
                            <span className="date">
                              <i className="fa fa-clock-o" />
                              {moment(el.createdAt).format('YYYY-MM-DD')}
                              {' '}
                              {moment(el.createdAt).format('HH:mm')}
                            </span>
                          </div>
                          <p>
                            {el.review}
                          </p>
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                  <div className="noservice-found"><h3>No Reviews</h3></div>
                )}

              </ul>

            </div>

          </div>

        </div>
      </div>
      <div className="store-pagination">
        {totalCount >= limit && (
          <div className="row">
            <div className="col-md-12">
              <div className="pagination-section">
                <ReactPaginate
                  previousLabel="← Previous"
                  nextLabel="Next →"
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination-chk"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientReviews;
