/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-self-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Form,
  //   Modal,
  // Tab,
  Dropdown,
  FormControl,
  // Tabs,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import Loader from '../../loader/loader';
import PartnerWrapper from '../partnerwrapper/wrapper';
import user from '../../../assets/partner/user.jpg';
import search from '../../../assets/partner/search.png';
import { partnerInstance } from '../../../config/axios';
import history from '../../../config/history';
// import Discount from '../../../assets/discount.svg';
import { validateAppointment } from '../../../function/validate';
import './appointment.css';

const EditAppointment = () => {
  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();
  const [services, setServices] = useState([]);
  // const [errors, setErrors] = useState({});
  const [startTime, setStartTime] = useState('');
  const [duration, setDuration] = useState('');
  const [errors, setErrors] = useState({});
  const [timeData, setTimeData] = useState([]);
  const [appointmentPanel, setAppointmentPanel] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [staffId, setStaffId] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState('');
  // const [duration, setDuration] = useState('');
  // const [startTime, setStartTime] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customersCopy, setCustomersCopy] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [extraDurationAfter, setExtraDurationAfter] = useState('');
  const [extraDurationBefore, setExtraDurationBefore] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  // const [customerDetail, setCustomerDetail] = useState([]);
  const [isBlock, setIsBlock] = useState();
  // const [appointmentid, setAppointmentId] = useState("");
  const [categories, setCategories] = useState([]);
  // eslint-disable-next-line
  const [categoryId, setCategoryId] = useState("");
  const [staffValid, setStaffValid] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  // const [totalDuration, setTotalDuration] = useState(0);
  const [custVoid, setCustVoid] = useState(false);
  // eslint-disable-next-line
  let durationTotal = 0;
  let priceTotal = 0;
  const value = queryString.parse(window.location.search);
  const { customerid, starttime, appointmentId } = value;
  useEffect(() => {
    // setStartTime(starttime);
    // setAppointmentId(appointmentId);
    // appointmentPanel.forEach((el, j) => {
    //   el.startTime = starttime;
    // });
    setStartTime(starttime);
    // setAppointmentPanel(appointmentPanel);
    setCustomerId(customerid);

    const getServices = async () => {
      const servicesData = await partnerInstance().get('/getServicesOnly');
      const { code, services: Services } = servicesData.data;
      if (code === 200) {
        setServices(Services);
      }
    };
    const getStaffs = async () => {
      const response = await partnerInstance().get('/getStaffs');
      const { code, stafflist } = response.data;
      if (code === 200) {
        const whoHasAppointmentBooking = stafflist.filter(
          (bo) => bo.appointmentBooking,
        );
        setStaffs(whoHasAppointmentBooking);
      }
    };
    const getCustomers = async () => {
      const response = await partnerInstance().get('/getCustomers');
      const { code, clientId } = response.data;
      if (code === 200) {
        // setCustomers(clientId);
        setCustomersCopy(clientId);
      }
    };
    const getCategories = async () => {
      const categoriesData = await partnerInstance().get('/getCategories');
      const { code, categories: Categories } = categoriesData.data;
      if (code === 200) {
        setCategories(Categories);
        // appointmentPanel.forEach((el) => {
        //   el.categories = Categories;
        // });
        // setAppointmentPanel(appointmentPanel);
      }
    };
    const time = () => {
      const x = 5; // minutes interval
      const timeDataList = []; // time array
      let tt = 0; // start time
      // loop to increment the time and push results in array
      for (let i = 0; tt < 24 * 60; i++) {
        const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        const mm = tt % 60; // getting minutes of the hour in 0-55 format
        timeDataList[i] = `${`0${hh % 24}`.slice(-2)}:${`0${mm}`.slice(-2)}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt += x;
      }
      setTimeData(timeDataList);
    };

    time();
    getCategories();
    getCustomers();
    getStaffs();
    getServices();
    getAppointmentById(appointmentId);
    // eslint-disable-next-line
  }, []);
  const getServicesByCategory = async (id) => {
    const payloadd = {
      catId: id,
    };

    const servicesData = await partnerInstance().post(
      '/getServicesByCategory',
      payloadd,
    );
    const { code, services: Services } = servicesData.data;
    if (code === 200) {
      const dataa = [Services];
      setServices(dataa[0].serviceid);
    }
  };
  const getAppointmentById = async (id) => {
    setLoading(true);
    const idd = {
      appointmentId: id,
    };
    const response = await partnerInstance().post('/getAppointment', idd);
    const { code, appointmentData } = response.data;
    if (code === 200) {
      const wait = [...appointmentData.appointmentPanel];
      wait.forEach((el) => {
        const filterStaffPrice = el.serviceId.staffPricing.findIndex(
          (sel) => sel.staffId === el.staffId._id,
        );
        if (
          filterStaffPrice > -1
          && el.serviceId.staffPricing[filterStaffPrice].staffPrice
        ) {
          el.serviceId.priceFrom = el.serviceId.staffPricing[filterStaffPrice].staffPrice;
          el.serviceId.duration = el.serviceId.staffPricing[filterStaffPrice].staffDuration;
          durationTotal += parseInt(
            el.serviceId.staffPricing[filterStaffPrice].staffDuration,
          );
          el.totalPrice = totalPrice
            + parseInt(el.serviceId.staffPricing[filterStaffPrice].staffPrice);
        } else if (el.serviceId.specialPrice) {
          el.serviceId.specialPrice;
          el.serviceId.duration;
          durationTotal += parseInt(el.duration);
          el.totalPrice = totalPrice + parseInt(el.serviceId.specialPrice);
        } else {
          el.serviceId.priceFrom;
          el.serviceId.duration;
          durationTotal += parseInt(el.duration);
          el.totalPrice = totalPrice + parseInt(el.serviceId.priceFrom);
        }
      });
      setAppointmentPanel(wait);
      setStaffId(wait[0].staffId._id);
      setStartTime(wait[0].startTime);
      setStartDate(appointmentData.appointmentDate);
      if (customerid) {
        getCustomerDetail(customerid);
      } else {
        setCustomerId(appointmentData.customerId._id);
        setFirstname(appointmentData.customerId.firstname);
        setLastname(appointmentData.customerId.lastname);
        setEmail(appointmentData.customerId.email);
        setIsBlock(appointmentData.customerId.isBlock);
        setCustVoid(appointmentData.customerId.void);
      }
      // setTotalDuration(durationTotal);
      setTotalPrice(priceTotal);
      setLoading(false);
    }
  };
  const getStaffById = async (id) => {
    const idd = {
      staffid: id,
      appointmentDate: startDate,
    };
    const response = await partnerInstance().post('/getStaffShift', idd);
    const { code, shiftTiming: shifttiming, getClosedDates } = response.data;
    if (code === 200) {
      const Closed = getClosedDates.closeddate;
      if (Closed.length > 0) {
        const filterClosedDates = Closed.filter(
          (el) => new Date(startDate).getTime() >= new Date(el.from).getTime()
            && new Date(startDate).getTime() <= new Date(el.to).getTime(),
        );
        if (filterClosedDates.length > 0) {
          setStaffValid(false);
          return [{ starttime: '00:00', endtime: '00:00' }];
        }
        setStaffValid(true);
        return shifttiming;
      }
      return shifttiming;
    }
  };

  const getCustomerDetail = async (id) => {
    const idd = {
      customerid: id,
    };
    const response = await partnerInstance().post('/getCustomer', idd);
    const { code, customerData } = response.data;

    if (code === 200) {
      if (customerData) {
        // setCustomerDetail([customerData]);
        setFirstname(customerData.firstname);
        setLastname(customerData.lastname);
        setEmail(customerData.email);
        setCustomerId(customerData._id);
        setIsBlock(customerData.isBlock);
        setCustVoid(customerData.void);
      } else {
        // setCustomerDetail([customerData]);
        setFirstname('');
        setLastname('');
        setEmail('');
        setCustomerId('');
        setCustVoid(false);
        history.push(
          `/partners/editappointment/?appointmentId=${appointmentId}`,
        );
      }
    }
  };

  const getServiceById = async (serviceid, i) => {
    setServiceId(serviceid);
    setErrors({});
    const payload = {
      serviceid,
    };
    const response = await partnerInstance().post('/getService', payload);
    const { code, service } = response.data;
    if (code === 200) {
      const outService = service.outService
        ? parseInt(service.outServiceExtraTime)
        : 0;
      const whoHasAppointmentBooking = service.staff.filter(
        (bo) => bo.appointmentBooking,
      );
      setDuration(service.duration);
      setExtraDurationBefore(service.extraDurationBefore);
      setExtraDurationAfter(service.extraDurationAfter);
      setStaffs(whoHasAppointmentBooking);
      const state = [...appointmentPanel];
      const filterStaffPrice = service.staffPricing.findIndex(
        (sel) => sel.staffId === state[i].staffId,
      );
      if (
        filterStaffPrice > -1
        && service.staffPricing[filterStaffPrice].staffPrice
      ) {
        state.forEach((el, j) => {
          const nextStartTime = moment(el.startTime, 'HH:mm:ss')
            .add(
              service.staffPricing[filterStaffPrice].staffDuration,
              'minutes',
            )
            .format('HH:mm');
          if (i === j) {
            el.totalPrice = totalPrice
              + parseInt(service.staffPricing[filterStaffPrice].staffPrice);
            el.duration = service.staffPricing[filterStaffPrice].staffDuration;
            el.endTime = nextStartTime;
          }
        });
        setAppointmentPanel(state);
      } else if (service.specialPrice) {
        state.forEach((el, j) => {
          const nextStartTime = moment(el.startTime, 'HH:mm:ss')
            .add(service.duration, 'minutes')
            .format('HH:mm');
          if (i === j) {
            el.totalPrice = totalPrice + parseInt(service.specialPrice);
            el.duration = +service.duration + outService;
            el.endTime = nextStartTime;
          }
        });
        setAppointmentPanel(state);
      } else {
        state.forEach((el, j) => {
          const nextStartTime = moment(el.startTime, 'HH:mm:ss')
            .add(service.duration, 'minutes')
            .format('HH:mm');
          if (i === j) {
            el.totalPrice = totalPrice + parseInt(service.priceFrom);
            el.duration = +service.duration + outService;
            el.endTime = nextStartTime;
          }
        });
        setAppointmentPanel(state);
      }
      if (staffId) {
        const nextStartTime = moment(startTime, 'HH:mm:ss')
          .add(service.duration, 'minutes')
          .format('HH:mm');
        const shift = await getStaffById(staffId);
        let valid = false;
        if (
          startTime >= shift[0].starttime
          && nextStartTime <= shift[0].endtime
        ) {
          setStaffValid(true);
          state[i].staffValid = true;
          valid = true;
          update();
        } else {
          setStaffValid(false);
          state[i].staffValid = false;
          valid = false;
          update();
        }
        if (shift.length > 1 && !valid) {
          if (
            startTime >= shift[1].starttime
            && nextStartTime <= shift[1].endtime
          ) {
            setStaffValid(true);
            state[i].staffValid = true;
            update();
          } else {
            state[i].staffValid = false;
            setStaffValid(false);
            update();
          }
        }
        setAppointmentPanel(state);
        update();
      }
    }

    // return service;

    // return service;
  };

  const updateAppointment = async (e) => {
    e.preventDefault();
    const payload = {
      appointmentId,
      appointmentDate: startDate,
      customerId,
      appointmentPanel,
      totalAmount: totalPrice,
      totalDuration: total,
    };

    const validation = validateAppointment({
      customerId,
      staffId,
      appointmentPanel,
      serviceId,
    });

    if (!staffValid) {
      validation.isValid = false;
    }
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const saveData = await partnerInstance().post('/editAppointment', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      // clearValues();
      if (button === 'Save') {
        history.push('/partners/calendar');
      } else {
        history.push(`/partners/checkout?appointmentId=${appointmentId}`);
      }
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  const deleteAppointmentPanel = (i) => {
    // const oldArray = [...appointmentPanel];
    // oldArray.pop();
    // setAppointmentPanel([...oldArray]);
    const oldStatee = [...appointmentPanel];
    oldStatee.splice(i, 1);

    setAppointmentPanel(oldStatee);
  };

  const selectAppointmentNote = (e, i) => {
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.appointmentNote = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
  };
  const selectCustomerId = (e) => {
    getCustomerDetail(e);
    setCustomerId(e);
  };
  const removeCustomerId = (e) => {
    getCustomerDetail(null);
  };

  const selectStartTime = async (e, i) => {
    setStartTime(e);
    const nextStartTime = moment(e.toString(), 'HH:mm:ss')
      .add(duration, 'minutes')
      .format('HH:mm');
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.startTime = e.toString();
        el.endTime = nextStartTime;
      }
    });
    setAppointmentPanel(appointmentPanel);
    if (staffId) {
      const shift = await getStaffById(staffId);
      let valid = false;
      if (
        e.toString() >= shift[0].starttime
        && e.toString() <= shift[0].endtime
        && nextStartTime.toString() >= shift[0].starttime
        && nextStartTime.toString() <= shift[0].endtime
      ) {
        setStaffValid(true);
        appointmentPanel[i].staffValid = true;
        valid = true;
      } else {
        setStaffValid(false);
        appointmentPanel[i].staffValid = false;
        valid = false;
      }
      if (shift.length > 1 && !valid) {
        if (
          e.toString() >= shift[1].starttime
          && e.toString() <= shift[0].endtime
          && nextStartTime.toString() >= shift[1].starttime
          && nextStartTime.toString() <= shift[1].endtime
        ) {
          setStaffValid(true);
          appointmentPanel[i].staffValid = true;
        } else {
          setStaffValid(false);
          appointmentPanel[i].staffValid = false;
        }
      }
      update();
    }
  };

  const selectDuration = async (e, i) => {
    setDuration(e);
    appointmentPanel.forEach((el, j) => {
      const nextStartTime = moment(el.startTime, 'HH:mm:ss')
        .add(e, 'minutes')
        .format('HH:mm');
      if (i === j) {
        el.duration = e;
        el.endTime = nextStartTime;
        // setTotalDuration(parseInt(e));
      }
    });
    setAppointmentPanel(appointmentPanel);
    if (staffId) {
      const shift = await getStaffById(staffId);
      const nextStartTime = moment(startTime, 'HH:mm:ss')
        .add(e, 'minutes')
        .format('HH:mm');
      let valid = false;
      if (
        startTime >= shift[0].starttime
        && nextStartTime.toString() <= shift[0].endtime
      ) {
        setStaffValid(true);
        appointmentPanel[i].staffValid = true;
        valid = true;
      } else {
        setStaffValid(false);
        appointmentPanel[i].staffValid = false;
        valid = false;
      }
      if (shift.length > 1 && !valid) {
        if (
          startTime >= shift[1].starttime
          && nextStartTime.toString() <= shift[1].endtime
        ) {
          setStaffValid(true);
          appointmentPanel[i].staffValid = true;
        } else {
          setStaffValid(false);
          appointmentPanel[i].staffValid = false;
        }
      }
      update();
    }
  };

  const selectStaff = async (e, i) => {
    setStaffId(e);
    appointmentPanel.forEach(async (el, j) => {
      if (i === j) {
        el.staffId = e;
      }
      await getServiceById(el.serviceId, i);
    });
    setAppointmentPanel(appointmentPanel);
    const shift = await getStaffById(e);
    let valid = false;
    await getServicesByCategory(categoryId, e, i);
    // const durationInMinutes =
    //   parseInt(duration) - parseInt(extraDurationBefore);
    // const StartTime = moment(startTime, "HH:mm:ss")
    //   .subtract(parseInt(extraDurationBefore), "minutes")
    //   .format("HH:mm");

    if (startTime >= shift[0].starttime && startTime <= shift[0].endtime) {
      setStaffValid(true);
      appointmentPanel[i].staffValid = true;
      valid = true;
    } else {
      setStaffValid(false);
      appointmentPanel[i].staffValid = false;
      valid = false;
    }
    if (shift.length > 1 && !valid) {
      if (startTime >= shift[1].starttime && startTime <= shift[1].endtime) {
        setStaffValid(true);
        appointmentPanel[i].staffValid = true;
      } else {
        setStaffValid(false);
        appointmentPanel[i].staffValid = false;
      }
    }
    update();
  };

  const selectService = async (e, i) => {
    setServiceId(e);
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.serviceId = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
    await getServiceById(e, i);
  };
  const selectCategory = async (e, i) => {
    // setCategoryId(e);
    appointmentPanel.forEach((el, j) => {
      if (i === j) {
        el.categoryId._id = e;
      }
    });
    setAppointmentPanel(appointmentPanel);
    await getServicesByCategory(e, i);
  };

  const addMoreAppointmentPanel = async () => {
    setStaffId(staffId);
    const du = appointmentPanel[appointmentPanel.length - 1].duration;
    const st = appointmentPanel[appointmentPanel.length - 1].endTime;
    const durationInMinutes = parseInt(du) + parseInt(extraDurationAfter);
    const nextStartTime = moment(st, 'HH:mm:ss')
      .add(durationInMinutes, 'minutes')
      .format('HH:mm');
    const oldArray = [...appointmentPanel];
    oldArray.push({
      categories,
      categoryId: { _id: '' },
      serviceId: '',
      staffId,
      startTime: nextStartTime,
      duration: 0,
      totalPrice: 0,
    });
    setAppointmentPanel(oldArray);
  };

  const searchCustomer = (e) => {
    setCustomers(customersCopy);
    setSearchKeyword(e);
    const filteredCustomers = customersCopy.filter(
      (customer) => customer.firstname.toLowerCase().indexOf(e.toLowerCase()) !== -1,
    );

    if (+e.length > 0) {
      setCustomers(filteredCustomers);
    } else {
      setCustomers([]);
    }
  };

  const handleBlock = async (id) => {
    const response = await partnerInstance().put(`/blockCustomer/${id}`);
    const { code, updatedData } = response.data;
    if (code === 200) {
      setIsBlock(updatedData.isBlock);
      setCustomerId(updatedData._id);
    }
  };

  let total = 0;
  return (
    <PartnerWrapper heading="Edit Appointment">
      {loading && <Loader />}
      <div className="add-appointment-page appointment-view">
        <div className="container">
          <h2 className="admin-title">
            Edit Appointment
            {' '}
            <Link to="/partners/calendar">
              <i className="fa fa-angle-left" />
              {' '}
              Back
            </Link>
          </h2>

          <div className="add-appointment-content appointment-content">
            <Form onSubmit={updateAppointment}>
              <div className="row">
                <div className="col-md-5">
                  <div className="appointment-card">
                    {/* CustomerSearch */}
                    {!customerId ? (
                      <div className="customer-search">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="customer-card">
                              <h4>Customers</h4>

                              <Form inline>
                                <FormControl
                                  type="text"
                                  placeholder="Search Customer"
                                  value={searchKeyword}
                                  onChange={(e) => searchCustomer(e.target.value)}
                                />
                                <Button>
                                  <i className="fa fa-search" />
                                </Button>
                              </Form>
                              {errors.customerId && (
                                <p className="error">{errors.customerId}</p>
                              )}
                              <div className="search-container-user">
                                <Link
                                  to={`/partners/addcustomer?appointmentId=${appointmentId}`}
                                >
                                  <i className="fa fa-plus" />
                                  {' '}
                                  Add new customer
                                </Link>
                                {customers
                                  && customers.map((el) => (
                                    <>
                                      <div
                                        role="presentation"
                                        className="search-items"
                                        onClick={() => selectCustomerId(el._id)}
                                      >
                                        <div className="search-icon">
                                          <img alt={user} src={user} />
                                        </div>
                                        <div className="search-content">
                                          <h4>
                                            {' '}
                                            {el.firstname}
                                            {el.lastname}
                                          </h4>
                                          <p>{el.email}</p>
                                        </div>
                                      </div>
                                    </>

                                    // <div className="search-items">
                                    //   <div className="search-icon">
                                    //     <img src={user} />
                                    //   </div>
                                    //   <div className="search-content">
                                    //     <h4>Syed Websul</h4>
                                    //     <p>syedwebsul@hgmail.com</p>
                                    //   </div>
                                    // </div>
                                  ))}
                              </div>
                              <div className="no-customer">
                                <img alt={user} src={search} />
                                <p>
                                  Use the search to add a customers, or keep
                                  empty to save as walk-in.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="customer-detail">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="customer-card">
                              <div className="customer-detail-appoint">
                                <div className="customer-detail-icon">
                                  <img alt={user} src={user} />
                                </div>
                                <div className="customer-detail-content">
                                  <h4>
                                    {firstname}
                                    {' '}
                                    {lastname}
                                  </h4>
                                  <p>{email}</p>
                                </div>
                                <div className="customer-detail-action">
                                  <Dropdown className="appointment-action">
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <i
                                        className="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {!custVoid && (
                                        <>
                                          <Dropdown.Item>
                                            <Link
                                              to={`/partners/editcustomer?customerid=${customerId}&appointmentId=${appointmentId}`}
                                            >
                                              Edit customer
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <div
                                              role="presentation"
                                              onClick={() => handleBlock(customerId)}
                                            >
                                              {isBlock ? 'Unblock' : 'Block'}
                                            </div>
                                          </Dropdown.Item>
                                        </>
                                      )}
                                      <Dropdown.Item>
                                        <Link
                                          onClick={() => removeCustomerId(customerId)}
                                          variant="danger"
                                        >
                                          Remove from appointment
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                              {/* <div className="customer-details-activities staff-page">
                                <Tabs
                                  defaultActiveKey="0"
                                  id="uncontrolled-tab-example-4"
                                >
                                  <Tab eventKey="0" title="Appointments">
                                    <CustomerDetailAppointments
                                      customerDetail={customerDetail}
                                    />
                                  </Tab>
                                  <Tab eventKey="1" title="Invoices">
                                    <CustomerDetailInvoices />
                                  </Tab>
                                </Tabs>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Customer Details */}

                    {/* Footer Buttons */}

                    <div className="appointment-buttons">
                      <span>
                        Total:
                        {' '}
                        {/* {totalPrice} */}
                        {appointmentPanel
                          ? appointmentPanel.forEach((el) => {
                            priceTotal += parseInt(el.totalPrice);
                          })
                          : ''}
                        {priceTotal > 0 ? parseInt(priceTotal) : '0'}
                        {appointmentPanel.forEach((el) => {
                          total += parseInt(el.duration);
                        })}
                        (
                        {' '}
                        {parseInt(total) > 0 ? (
                          Math.floor(parseInt(total) / 60) === 0 ? (
                            <>
                              {parseInt(total) % 60}
                              min
                            </>
                          ) : (
                            <>
                              {Math.floor(parseInt(total) / 60)}
                              h,
                              {' '}
                              {parseInt(total) % 60}
                              min
                            </>
                          )
                        ) : (
                          '0h'
                        )}
                        )
                      </span>
                      <div className="footer-button">
                        <Button
                          className="border-btn"
                          id="border-btn"
                          type="submit"
                          value="1"
                          onClick={() => setButton('Express')}
                        >
                          Express Checkout
                        </Button>
                        <Button
                          className="btn-perple"
                          id="appointment-btn"
                          type="submit"
                          value="2"
                          onClick={() => setButton('Save')}
                        >
                          Save Appointment
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="appointment-card">
                    <div className="appointment-body">
                      <div className="add-appointment-form">
                        <h4>
                          {new Date(startDate).toString().substring(0, 15)}
                        </h4>
                        <Form>
                          {appointmentPanel.map((el, i, array) => (
                            <div className="pricing-option">
                              <div className="remove-pricing">
                                {/* <i
                              onClick={() => deleteAppointmentPanel(i)}
                              className="fa fa-times"
                            /> */}

                                {array.length > 1 && (
                                  <i
                                    role="presentation"
                                    onClick={() => deleteAppointmentPanel(i, el._id)}
                                    className="fa fa-times"
                                  />
                                )}
                              </div>
                              <h5>Appointment</h5>

                              <div className="row">
                                <div className="col-md-12">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Category </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) => selectCategory(e.target.value, i)}
                                      value={el.categoryId._id}
                                    >
                                      {categories
                                        && categories.map((ele, k) => (
                                          <>
                                            {k === 0 ? (
                                              <option>Select Category</option>
                                            ) : (
                                              ''
                                            )}
                                            <option value={ele._id}>
                                              {ele.categoryname}
                                            </option>
                                          </>
                                        ))}
                                    </Form.Control>

                                    {errors.categoryIndex === i && (
                                      <p className="error">
                                        {errors.categoryId}
                                      </p>
                                    )}
                                  </Form.Group>
                                </div>
                                <div className="col-md-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Start Time </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) => selectStartTime(e.target.value, i)}
                                      value={el.startTime}
                                    >
                                      {timeData.map((item, j) => (
                                        <option key={j} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </div>
                                <div className="col-md-8">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Service </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) => selectService(e.target.value, i)}
                                      value={el.serviceId._id}
                                    >
                                      {services
                                        && services.map((ele, k) => (
                                          <>
                                            {k === 0 ? (
                                              <option>Select Service</option>
                                            ) : (
                                              ''
                                            )}

                                            <option value={ele._id}>
                                              {ele.serviceName}
                                            </option>
                                          </>
                                        ))}
                                    </Form.Control>
                                    {errors.serviceIndex === i && (
                                      <p className="error">
                                        {errors.serviceId}
                                      </p>
                                    )}
                                    {/* {errors.serviceId && (
                                      <p className="error">
                                        {errors.serviceId}
                                      </p>
                                    )} */}
                                  </Form.Group>
                                </div>
                                <div className="col-md-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Duration </Form.Label>
                                    {el.duration ? (
                                      <Form.Control
                                        as="select"
                                        onChange={(e) => selectDuration(e.target.value, i)}
                                        value={el.duration}
                                      >
                                        <option value="5">5min</option>
                                        <option value="10">10min</option>
                                        <option value="15">15min</option>
                                        <option value="20">20min</option>
                                        <option value="25">25min</option>
                                        <option value="30">30min</option>
                                        <option value="35">35min</option>
                                        <option value="40">40min</option>
                                        <option value="45">45min</option>
                                        <option value="50">50min</option>
                                        <option value="55">55min</option>
                                        <option value="60" selected>
                                          1h
                                        </option>
                                        <option value="65">1h 5min</option>
                                        <option value="70">1h 10min</option>
                                        <option value="75">1h 15min</option>
                                        <option value="80">1h 20min</option>
                                        <option value="85">1h 25min</option>
                                        <option value="90">1h 30min</option>
                                        <option value="95">1h 35min</option>
                                        <option value="100">1h 40min</option>
                                        <option value="105">1h 45min</option>
                                        <option value="110">1h 50min</option>
                                        <option value="115">1h 55min</option>
                                        <option value="120">2h</option>
                                        <option value="135">2h 15min</option>
                                        <option value="150">2h 30min</option>
                                        <option value="165">2h 45min</option>
                                        <option value="180">3h</option>
                                        <option value="195">3h 15min</option>
                                        <option value="210">3h 30min</option>
                                        <option value="225">3h 45min</option>
                                        <option value="240">4h</option>
                                        <option value="255">4h 15min</option>

                                        <option value="270">4h 30min</option>
                                        <option value="285">4h 45min</option>
                                        <option value="300">5h</option>
                                        <option value="315">5h 15min</option>
                                        <option value="330">5h 30min</option>
                                        <option value="345">5h 45min</option>
                                        <option value="360">6h</option>
                                        <option value="375">6h 15min</option>
                                        <option value="390">6h 30min</option>
                                        <option value="405">6h 45min</option>
                                        <option value="420">7h</option>
                                        <option value="435">7h 15min</option>
                                        <option value="450">7h 30min</option>
                                        <option value="465">7h 45min</option>
                                        <option value="480">8h</option>
                                        <option value="495">8h 15min</option>
                                        <option value="510">8h 30min</option>
                                        <option value="525">8h 45min</option>
                                        <option value="540">9h</option>
                                        <option value="555">9h 15min</option>
                                        <option value="570">9h 30min</option>
                                        <option value="585">9h 45min</option>
                                        <option value="600">10h</option>
                                        <option value="615">10h 15min</option>
                                        <option value="630">10h 30min</option>
                                        <option value="645">10h 45min</option>
                                        <option value="660">11h</option>
                                        <option value="675">11h 15min</option>
                                        <option value="690">11h 30min</option>
                                        <option value="705">11h 45min</option>
                                        <option value="720">12h</option>
                                      </Form.Control>
                                    ) : (
                                      <Form.Control disabled as="select" />
                                    )}
                                  </Form.Group>
                                </div>
                                <div className="col-md-8">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Staff </Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) => selectStaff(e.target.value, i)}
                                      value={el.staffId._id}
                                    >
                                      {staffs.map((ele) => (
                                        <option value={ele._id}>
                                          {ele.firstname}
                                        </option>
                                      ))}
                                    </Form.Control>
                                    {errors.staffIndex === i && (
                                      <p className="error">{errors.staffId}</p>
                                    )}
                                    {/* {errors.staffId && (
                                      <p className="error">{errors.staffId}</p>
                                    )} */}
                                    {!staffValid && (
                                      <p className="error">
                                        This staff is not working in this
                                        duration, Please select another staff
                                      </p>
                                    )}
                                  </Form.Group>
                                </div>
                                <div className="col-md-12">
                                  <Form.Group controlId="formBasicloginone">
                                    <Form.Label>Appointment notes </Form.Label>
                                    <Form.Control
                                      placeholder="Add a short description"
                                      as="textarea"
                                      rows="3"
                                      onChange={(e) => selectAppointmentNote(e.target.value, i)}
                                    />
                                  </Form.Group>
                                </div>
                                {extraDurationBefore && (
                                  <p>
                                    {' '}
                                    {extraDurationBefore}
                                    min processing time before the service
                                    {' '}
                                  </p>
                                )}
                                {extraDurationAfter && (
                                  <p>
                                    {' '}
                                    {extraDurationAfter}
                                    min processing time after the service
                                    {' '}
                                  </p>
                                )}
                              </div>
                            </div>
                          ))}
                          <div
                            role="presentation"
                            className="add-pricing-option"
                            onClick={addMoreAppointmentPanel}
                          >
                            <span>
                              <i className="fa fa-plus" />
                              {' '}
                              Add more appointment
                            </span>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default EditAppointment;
