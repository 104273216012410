import React from 'react';
// import { Button } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
// import PartnerWrapper from '../partnerwrapper/wrapper';

const NotAuthorised = () => (
  <>
    <Helmet>
      <body className="error-404" />
    </Helmet>
    <div className="page-404">
      <div className="error-content">
        <h1>401</h1>
        <h3>Not Authorised</h3>
        <p>You don't have permissions to access this page. If you'd like access, ask your account owner to update the permission settings.</p>
        <div className="btn-404">
          <Link to="/partners/calendar">Back To Home</Link>
        </div>
      </div>
    </div>
  </>
);

export default NotAuthorised;
