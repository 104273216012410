import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, Form, Modal, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import PartnerWrapper from "../partnerwrapper/wrapper";
import staffimg from "../../../assets/partner/staff.png";
import { partnerInstance } from "../../../config/axios";
import { validateService } from "../../../function/validate";
import { server } from "../../../config/keys";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import "./singleservice.css";
import Loader from "../../loader/loader";

// const j = 1;
const array = [];
const SingleServices = () => {
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const { username } = useContext(PartnerContext);
  const [loading, setLoading] = useState(false);
  const { currency, onlinePayment } = username;
  const [view, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const [categories, setCategories] = useState([]);
  // const [category, setCategory] = useState('');
  const [serviceCategoryId, setServiceCategoryId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [staffs, setStaffs] = useState([]);
  // const [staff, setStaff] = useState([]);
  // const [onlineBooking, setOnlineBooking] = useState(true);
  const [outService, setOutService] = useState(false);
  const [staffCommission, setStaffCommission] = useState(false);
  const [extraDurationBefore, setExtraDurationBefore] = useState("15");
  const [extraDurationAfter, setExtraDurationAfter] = useState("15");
  const [voucherSale, setVoucherSale] = useState(false);
  const [serviceTax, setServiceTax] = useState({
    taxName: "",
    taxRate: "",
    _id: "",
    default: false,
  });
  const [voucherExpiry, setVoucherExpiry] = useState("");
  const [duration, setDuration] = useState("60");
  const [priceType, setPriceType] = useState("");
  const [specialPrice, setSpecialPrice] = useState("");
  const [priceFrom, setPriceFrom] = useState("");
  const [selectAll, setSelectAll] = useState(true);
  const [disablePriceFrom, setDisablePriceFrom] = useState(false);
  const [disableSpecialPrice, setDisableSpecialPrice] = useState(false);
  const [validd, setValidd] = useState(true);
  const [outServiceExtraTime, setOutServiceExtraTime] = useState("");
  const [isPayment, setIsPayment] = useState(false);
  const [taxess, setTaxess] = useState([]);
  const [staffPricing, setStaffPricing] = useState([
    // {
    //   staffId: '',
    //   staffDuration: 0,
    //   staffPrice: 0,
    // },
  ]);

  // const [staffDuration, setStaffDuration] = useState('');
  const value = queryString.parse(window.location.search);
  const { catid, catname } = value;

  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();
  // setCategory(category);
  const getStaffs = useCallback(async () => {
    setLoading(true);
    const staffsData = await partnerInstance().get("/getStaffs");
    const { code, stafflist } = staffsData.data;
    if (code === 200) {
      stafflist.forEach((el) => {
        el.checked = true;
      });
      setStaffs(stafflist);

      stafflist.forEach((el, i) => {
        const obj = {};
        obj.staffId = el._id;
        obj.staffName = el.firstname;
        obj.image = el.image;
        staffPricing.push(obj);
      });
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // setCategory(catname);
    setServiceCategoryId(catid);
    const getCategories = async () => {
      const categoriesData = await partnerInstance().get("/getCategories");
      const { code, categories } = categoriesData.data;
      if (code === 200) {
        setCategories(categories);
      }
    };
    const getTaxes = async () => {
      const taxDataa = await partnerInstance().get("/getTaxes");
      const { code, taxData } = taxDataa.data;
      if (code === 200) {
        setTaxess(taxData.tax);
      }
    };
    getTaxes();
    getCategories();
    // getTreatments();
    getStaffs();
    // eslint-disable-next-line
  }, [getStaffs]);

  // const VoucherSale = (e) => {
  //   if (e.target.value === 'true') {
  //     setVoucherSale(false);
  //   } else {
  //     setVoucherSale(true);
  //   }
  // };
  const selectVoucherExpiry = (e) => {
    setVoucherExpiry(e.target.value);
  };

  let valid = true;
  const addService = async (e) => {
    e.preventDefault();
    const arrayofid = [];
    // Try to understand this code simple stuff
    const filterSelectedIds = staffs.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });
    let pr = priceFrom;
    if (parseInt(pr) <= parseInt(specialPrice)) {
      // setValid(false);
      valid = false;
      setValidd(valid);
    } else {
      // setValid(true);
      valid = true;
      setValidd(valid);
    }
    let outserviceextratime;
    if (outService) {
      outserviceextratime = outServiceExtraTime;
    } else {
      outserviceextratime = "";
    }
    if (priceType === "free") {
      pr = "0";
    }

    const payload = {
      serviceName,
      serviceDescription,
      serviceCategoryId,
      extraDurationBefore,
      extraDurationAfter,
      duration,
      priceFrom: pr,
      staff: arrayofid,
      voucherSale,
      staffCommission,
      serviceTax,
      voucherExpiry,
      outService,
      specialPrice,
      outServiceExtraTime: outserviceextratime,
      staffPricing,
      isPayment,
      priceType,
      // onlineBooking,
    };

    const validation = validateService({
      servicename: serviceName,
      category: serviceCategoryId,
      staff: arrayofid,
      priceFrom,
      priceType,
    });

    setErrors(validation.errors);
    if (!validation.isValid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (!valid) {
      return;
    }

    const saveData = await partnerInstance().post("/addService", payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      // clearValues();
      history.push("/partners/services");
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  // const clearValues = () => {
  //   setTreatmentType('');
  // };
  const selectPriceType = (e) => {
    setPriceType(e);
    if (e === "free") {
      setDisablePriceFrom(true);
      setDisableSpecialPrice(true);
      setPriceFrom("");
      setSpecialPrice("");
    } else if (e === "fixed") {
      setDisableSpecialPrice(true);
      setDisablePriceFrom(false);
      setSpecialPrice("");
    } else {
      setDisablePriceFrom(false);
      setDisableSpecialPrice(false);
    }
  };

  const handleCheck = (e, el) => {
    const oldState = [...staffs];
    oldState.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    const filter = oldState.filter((item) => item.checked);
    if (filter.length === oldState.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setStaffs(staffs);
    update();
  };
  const handleSelectAll = (e) => {
    setSelectAll(!selectAll);
    staffs.forEach((el) => {
      if (selectAll) {
        staffs.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        setStaffs(array);
      } else {
        staffs.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
        setStaffs(staffs);
        update();
      }
    });
    setStaffs(staffs);
    update();
  };

  const SelectTax = (e) => {
    const filter = taxess.filter((el, i) => i === parseInt(e.target.value));
    const data = {
      taxName: filter[0].taxname,
      taxRate: filter[0].taxrate,
      _id: filter[0]._id,
      default: filter[0].default,
    };
    setServiceTax(data);
  };
  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Service Definition">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="single-services">
        <div className="container">
          <h2 className="admin-title">
            {" "}
            {catid ? (
              <Link
                to={`/partners/addservices?catid=${catid}&catname=${catname}`}
              >
                <i className="fa fa-angle-left" /> Back
              </Link>
            ) : (
              <Link to="/partners/addservices">
                <i className="fa fa-angle-left" /> Back
              </Link>
            )}
          </h2>

          <div className="single-services-content">
            <Form onSubmit={addService}>
              <div className="row">
                <div className="col-md-6">
                  <div className="service-card">
                    <div className="service-body">
                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>
                          Service Name
                          <span className="red-color">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Service name"
                          name="name"
                          autoComplete="off"
                          onChange={(e) => setServiceName(e.target.value)}
                          value={serviceName}
                        />
                        {errors.servicename && (
                          <p className="error">{errors.servicename}</p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>
                          Select Service Category
                          <span className="red-color">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          disabled={!!catid}
                          onChange={(e) => setServiceCategoryId(e.target.value)}
                          value={serviceCategoryId}
                        >
                          <option value="">Select Category</option>
                          {categories.map((el, i) => (
                            <>
                              <option key={i} value={el._id}>
                                {el.categoryname}
                              </option>
                            </>
                          ))}
                        </Form.Control>
                        {errors.category && (
                          <p className="error">{errors.category}</p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Service Description</Form.Label>
                        <Form.Control
                          placeholder="Add a short description"
                          as="textarea"
                          rows="3"
                          onChange={(e) =>
                            setServiceDescription(e.target.value)
                          }
                          value={serviceDescription}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="service-card">
                    <div className="service-header">
                      <h2 className="admin-inner-title">Service Setup</h2>
                    </div>
                    <div className="service-body">
                      <div className="pricing-option">
                        <h5>Pricing</h5>

                        <div className="pricing-input">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) => setDuration(e.target.value)}
                            >
                              <option value="5">5min</option>
                              <option value="10">10min</option>
                              <option value="15">15min</option>
                              <option value="20">20min</option>
                              <option value="25">25min</option>
                              <option value="30">30min</option>
                              <option value="35">35min</option>
                              <option value="40">40min</option>
                              <option value="45">45min</option>
                              <option value="50">50min</option>
                              <option value="55">55min</option>
                              <option value="60" selected>
                                1h
                              </option>
                              <option value="65">1h 5min</option>
                              <option value="70">1h 10min</option>
                              <option value="75">1h 15min</option>
                              <option value="80">1h 20min</option>
                              <option value="85">1h 25min</option>
                              <option value="90">1h 30min</option>
                              <option value="95">1h 35min</option>
                              <option value="100">1h 40min</option>
                              <option value="105">1h 45min</option>
                              <option value="110">1h 50min</option>
                              <option value="115">1h 55min</option>
                              <option value="120">2h</option>
                              <option value="135">2h 15min</option>
                              <option value="150">2h 30min</option>
                              <option value="165">2h 45min</option>
                              <option value="180">3h</option>
                              <option value="195">3h 15min</option>
                              <option value="210">3h 30min</option>
                              <option value="225">3h 45min</option>
                              <option value="240">4h</option>
                              <option value="255">4h 15min</option>

                              <option value="270">4h 30min</option>
                              <option value="285">4h 45min</option>
                              <option value="300">5h</option>
                              <option value="315">5h 15min</option>
                              <option value="330">5h 30min</option>
                              <option value="345">5h 45min</option>
                              <option value="360">6h</option>
                              <option value="375">6h 15min</option>
                              <option value="390">6h 30min</option>
                              <option value="405">6h 45min</option>
                              <option value="420">7h</option>
                              <option value="435">7h 15min</option>
                              <option value="450">7h 30min</option>
                              <option value="465">7h 45min</option>
                              <option value="480">8h</option>
                              <option value="495">8h 15min</option>
                              <option value="510">8h 30min</option>
                              <option value="525">8h 45min</option>
                              <option value="540">9h</option>
                              <option value="555">9h 15min</option>
                              <option value="570">9h 30min</option>
                              <option value="585">9h 45min</option>
                              <option value="600">10h</option>
                              <option value="615">10h 15min</option>
                              <option value="630">10h 30min</option>
                              <option value="645">10h 45min</option>
                              <option value="660">11h</option>
                              <option value="675">11h 15min</option>
                              <option value="690">11h 30min</option>
                              <option value="705">11h 45min</option>
                              <option value="720">12h</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Price Type</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) => selectPriceType(e.target.value)}
                              value={priceType}
                            >
                              <option value="from">From</option>
                              <option value="free">Free</option>
                              <option value="fixed">Fixed</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Extra Time (Before)</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) =>
                                setExtraDurationBefore(e.target.value)
                              }
                              // onClick={addExtraDuration}
                              value={extraDurationBefore}
                            >
                              <option value="0"> 0min</option>
                              <option value="5">5min</option>
                              <option value="10">10min</option>
                              <option value="15" selected>
                                15min
                              </option>
                              <option value="20">20min</option>
                              <option value="25">25min</option>
                              <option value="30">30min</option>
                              <option value="35">35min</option>
                              <option value="40">40min</option>
                              <option value="45">45min</option>
                              <option value="50">50min</option>
                              <option value="55">55min</option>
                              <option value="60">1h</option>
                              <option value="65">1h 5min</option>
                              <option value="70">1h 10min</option>
                              <option value="75">1h 15min</option>
                              <option value="80">1h 20min</option>
                              <option value="85">1h 25min</option>
                              <option value="90">1h 30min</option>
                              <option value="95">1h 35min</option>
                              <option value="100">1h 40min</option>
                              <option value="105">1h 45min</option>
                              <option value="110">1h 50min</option>
                              <option value="115">1h 55min</option>
                              <option value="120">2h</option>
                              <option value="135">2h 15min</option>
                              <option value="150">2h 30min</option>
                              <option value="165">2h 45min</option>
                              <option value="180">3h</option>
                              <option value="195">3h 15min</option>
                              <option value="210">3h 30min</option>
                              <option value="225">3h 45min</option>
                              <option value="240">4h</option>
                              <option value="255">4h 15min</option>

                              <option value="270">4h 30min</option>
                              <option value="285">4h 45min</option>
                              <option value="300">5h</option>
                              <option value="315">5h 15min</option>
                              <option value="330">5h 30min</option>
                              <option value="345">5h 45min</option>
                              <option value="360">6h</option>
                              <option value="375">6h 15min</option>
                              <option value="390">6h 30min</option>
                              <option value="405">6h 45min</option>
                              <option value="420">7h</option>
                              <option value="435">7h 15min</option>
                              <option value="450">7h 30min</option>
                              <option value="465">7h 45min</option>
                              <option value="480">8h</option>
                              <option value="495">8h 15min</option>
                              <option value="510">8h 30min</option>
                              <option value="525">8h 45min</option>
                              <option value="540">9h</option>
                              <option value="555">9h 15min</option>
                              <option value="570">9h 30min</option>
                              <option value="585">9h 45min</option>
                              <option value="600">10h</option>
                              <option value="615">10h 15min</option>
                              <option value="630">10h 30min</option>
                              <option value="645">10h 45min</option>
                              <option value="660">11h</option>
                              <option value="675">11h 15min</option>
                              <option value="690">11h 30min</option>
                              <option value="705">11h 45min</option>
                              <option value="720">12h</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Extra Time (After)</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) =>
                                setExtraDurationAfter(e.target.value)
                              }
                              // onClick={addExtraDuration}
                              value={extraDurationAfter}
                            >
                              <option value="0"> 0min</option>
                              <option value="5">5min</option>
                              <option value="10">10min</option>
                              <option value="15" selected>
                                15min
                              </option>
                              <option value="20">20min</option>
                              <option value="25">25min</option>
                              <option value="30">30min</option>
                              <option value="35">35min</option>
                              <option value="40">40min</option>
                              <option value="45">45min</option>
                              <option value="50">50min</option>
                              <option value="55">55min</option>
                              <option value="60">1h</option>
                              <option value="65">1h 5min</option>
                              <option value="70">1h 10min</option>
                              <option value="75">1h 15min</option>
                              <option value="80">1h 20min</option>
                              <option value="85">1h 25min</option>
                              <option value="90">1h 30min</option>
                              <option value="95">1h 35min</option>
                              <option value="100">1h 40min</option>
                              <option value="105">1h 45min</option>
                              <option value="110">1h 50min</option>
                              <option value="115">1h 55min</option>
                              <option value="120">2h</option>
                              <option value="135">2h 15min</option>
                              <option value="150">2h 30min</option>
                              <option value="165">2h 45min</option>
                              <option value="180">3h</option>
                              <option value="195">3h 15min</option>
                              <option value="210">3h 30min</option>
                              <option value="225">3h 45min</option>
                              <option value="240">4h</option>
                              <option value="255">4h 15min</option>

                              <option value="270">4h 30min</option>
                              <option value="285">4h 45min</option>
                              <option value="300">5h</option>
                              <option value="315">5h 15min</option>
                              <option value="330">5h 30min</option>
                              <option value="345">5h 45min</option>
                              <option value="360">6h</option>
                              <option value="375">6h 15min</option>
                              <option value="390">6h 30min</option>
                              <option value="405">6h 45min</option>
                              <option value="420">7h</option>
                              <option value="435">7h 15min</option>
                              <option value="450">7h 30min</option>
                              <option value="465">7h 45min</option>
                              <option value="480">8h</option>
                              <option value="495">8h 15min</option>
                              <option value="510">8h 30min</option>
                              <option value="525">8h 45min</option>
                              <option value="540">9h</option>
                              <option value="555">9h 15min</option>
                              <option value="570">9h 30min</option>
                              <option value="585">9h 45min</option>
                              <option value="600">10h</option>
                              <option value="615">10h 15min</option>
                              <option value="630">10h 30min</option>
                              <option value="645">10h 45min</option>
                              <option value="660">11h</option>
                              <option value="675">11h 15min</option>
                              <option value="690">11h 30min</option>
                              <option value="705">11h 45min</option>
                              <option value="720">12h</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              Price (from)
                              <span className="red-color">*</span>
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                disabled={disablePriceFrom}
                                type="number"
                                placeholder="0.00"
                                onChange={(e) => setPriceFrom(e.target.value)}
                                value={priceFrom}
                              />
                              <InputGroup.Prepend>
                                <InputGroup.Text> {currency}</InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                            {errors.priceFrom && (
                              <p className="error">{errors.priceFrom}</p>
                            )}
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Promotion Price</Form.Label>
                            <InputGroup>
                              <Form.Control
                                disabled={disableSpecialPrice}
                                type="number"
                                placeholder="0.00"
                                onChange={(e) =>
                                  setSpecialPrice(e.target.value)
                                }
                                value={specialPrice}
                              />
                              <InputGroup.Prepend>
                                <InputGroup.Text> {currency}</InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                            {!validd ? (
                              <p className="error">
                                Promotion price should be less than price
                              </p>
                            ) : (
                              <></>
                            )}
                          </Form.Group>

                          {/* <Form.Group className="full" controlId="formBasicloginone">
          <Form.Label>Pricing name (optional)</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. Long hair"
            name="name"
            autoComplete="off"
            onChange={(e) => handlePriceName(e, i)}
          />
        </Form.Group> */}
                        </div>
                      </div>

                      <div
                        role="presentation"
                        className="add-pricing-option"
                        onClick={handleViewShow}
                      >
                        <span>
                          <i className="fa fa-plus" /> Advanced pricing option
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="service-card">
                    <div className="out-service">
                      <div className="service-header">
                        <h2 className="admin-inner-title">Out Service</h2>
                        <p>Is this an Out Service?</p>
                      </div>

                      <div className="online-booking">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => setOutService(e.target.checked)}
                            checked={outService}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                    <div className="service-body">
                      {!outService ? null : (
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Out service Extra Time</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) =>
                              setOutServiceExtraTime(e.target.value)
                            }
                            value={outServiceExtraTime}
                          >
                            <option value="0">5min</option>
                            <option value="5">5min</option>
                            <option value="10">10min</option>
                            <option value="15">15min</option>
                            <option value="20">20min</option>
                            <option value="25">25min</option>
                            <option value="30">30min</option>
                            <option value="35">35min</option>
                            <option value="40">40min</option>
                            <option value="45">45min</option>
                            <option value="50">50min</option>
                            <option value="55">55min</option>
                            <option value="60" selected>
                              1h
                            </option>
                            <option value="65">1h 5min</option>
                            <option value="70">1h 10min</option>
                            <option value="75">1h 15min</option>
                            <option value="80">1h 20min</option>
                            <option value="85">1h 25min</option>
                            <option value="90">1h 30min</option>
                            <option value="95">1h 35min</option>
                            <option value="100">1h 40min</option>
                            <option value="105">1h 45min</option>
                            <option value="110">1h 50min</option>
                            <option value="115">1h 55min</option>
                            <option value="120">2h</option>
                            <option value="135">2h 15min</option>
                            <option value="150">2h 30min</option>
                            <option value="165">2h 45min</option>
                            <option value="180">3h</option>
                            <option value="195">3h 15min</option>
                            <option value="210">3h 30min</option>
                            <option value="225">3h 45min</option>
                            <option value="240">4h</option>
                            <option value="255">4h 15min</option>

                            <option value="270">4h 30min</option>
                            <option value="285">4h 45min</option>
                            <option value="300">5h</option>
                            <option value="315">5h 15min</option>
                            <option value="330">5h 30min</option>
                            <option value="345">5h 45min</option>
                            <option value="360">6h</option>
                            <option value="375">6h 15min</option>
                            <option value="390">6h 30min</option>
                            <option value="405">6h 45min</option>
                            <option value="420">7h</option>
                            <option value="435">7h 15min</option>
                            <option value="450">7h 30min</option>
                            <option value="465">7h 45min</option>
                            <option value="480">8h</option>
                            <option value="495">8h 15min</option>
                            <option value="510">8h 30min</option>
                            <option value="525">8h 45min</option>
                            <option value="540">9h</option>
                            <option value="555">9h 15min</option>
                            <option value="570">9h 30min</option>
                            <option value="585">9h 45min</option>
                            <option value="600">10h</option>
                            <option value="615">10h 15min</option>
                            <option value="630">10h 30min</option>
                            <option value="645">10h 45min</option>
                            <option value="660">11h</option>
                            <option value="675">11h 15min</option>
                            <option value="690">11h 30min</option>
                            <option value="705">11h 45min</option>
                            <option value="720">12h</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </div>
                  </div>
                  <div className="service-card">
                    <div className="service-header">
                      <h2 className="admin-inner-title">Payment</h2>
                      <p>Is payment required for this service?</p>
                    </div>
                    <div className="service-body">
                      <div className="online-booking">
                        <label
                          className={
                            !onlinePayment ? "switch disable" : "switch"
                          }
                        >
                          <input
                            type="checkbox"
                            disabled={!onlinePayment}
                            onChange={() => setIsPayment(!isPayment)}
                            checked={isPayment}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      {!onlinePayment && (
                        <p>
                          Enable Online Payments under ONLINE Appointlee in
                          order
                          <br /> to enable this feature
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="service-card">
                    <div className="service-header">
                      <h2 className="admin-inner-title">Associate</h2>
                      <p>Who can performe this service?</p>
                    </div>

                    <div className="service-body">
                      <div className="staff-section">
                        <div className="select-all-staff">
                          <Form.Check
                            type="checkbox"
                            label="Select all"
                            id="selectall"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                        <div className="staff-list">
                          {staffs.map((item, i) => (
                            <div className="staff-item">
                              <Form.Check
                                type="checkbox"
                                id="staff1"
                                checked={item.checked}
                                value={item._id}
                                onClick={(e) => handleCheck(e, item)}
                              />
                              <img src={staffimg} alt="" />
                              {item.firstname}
                            </div>
                          ))}
                          {/* {errors.staff && (
                            <p className="error">{errors.staff}</p>
                          )} */}
                        </div>

                        <div className="staff-copmmision">
                          <h5>Associate commission</h5>
                          <p>Can the associate get commission?</p>

                          <div className="online-booking">
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={() =>
                                  setStaffCommission(!staffCommission)
                                }
                                checked={staffCommission}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-card">
                    <div className="service-header">
                      <h2 className="admin-inner-title">Tax and Vouchers</h2>
                    </div>

                    <div className="service-body">
                      <div className="sales-setting">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Tax Type</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => SelectTax(e)}
                            // value={serviceTax}
                          >
                            <option value="No Tax">No Tax </option>

                            {taxess.map((item, i) => (
                              <option key={i} value={i}>
                                {item.taxname} ({item.taxrate}% )
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <div className="voucher-sales">
                          <h5>Voucher sales</h5>
                          <p>Enable voucher for this service?</p>
                          <div className="online-booking">
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setVoucherSale(e.target.checked)
                                }
                                checked={voucherSale}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                        {!voucherSale ? null : (
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Voucher will expiry after</Form.Label>
                            <Form.Control
                              as="select"
                              onClick={selectVoucherExpiry}
                            >
                              <option value="6Month">Default 6 Month</option>
                              <option value="14days">14 days</option>
                              <option value="1month">1 month</option>
                            </Form.Control>
                          </Form.Group>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="login-button service-save">
                    <Button type="submit" className="btn-perple">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        className="advance-pricing-popup"
        show={view}
        onHide={handleViewClose}
      >
        <AddPricing
          staffs={staffs}
          handleViewClose={handleViewClose}
          priceType={priceType}
          selectPriceType={selectPriceType}
          duration={duration}
          setDuration={setDuration}
          // staffDuration={staffDuration}
          // setStaffDuration={setStaffDuration}
          addService={addService}
          disablePriceFrom={disablePriceFrom}
          disableSpecialPrice={disableSpecialPrice}
          setPriceFrom={setPriceFrom}
          priceFrom={priceFrom}
          setSpecialPrice={setSpecialPrice}
          specialPrice={specialPrice}
          staffPricing={staffPricing}
          setStaffPricing={setStaffPricing}
        />
      </Modal>
    </PartnerWrapper>
  );
};

export default SingleServices;
function AddPricing({
  setPriceFrom,
  priceFrom,
  setSpecialPrice,
  specialPrice,
  addService,
  staffs,
  handleViewClose,
  duration,
  setDuration,
  priceType,
  selectPriceType,
  staffDuration,
  disableSpecialPrice,
  disablePriceFrom,
  staffPricing,
  setStaffPricing,
}) {
  const addStaffPrice = (e) => {
    e.preventDefault();
    handleViewClose();
  };

  const newArr = [...staffPricing];

  const selectStaffDuration = (e, i, id, staffName) => {
    newArr[i].staffDuration = e;
    newArr[i].staffName = staffName;
  };

  const selectStaffPrice = (e, i, id, staffName) => {
    newArr[i].staffPrice = e;
    newArr[i].staffName = staffName;
  };

  const saveData = () => {
    setStaffPricing(newArr);
  };

  const cancelData = () => {
    const copy = [...staffs];
    handleViewClose();
    const wave = [];
    copy.forEach((item) => {
      const obj = {};
      obj.id = item._id;
      obj.staffName = item.firstname;
      wave.push(obj);
    });
    setStaffPricing(wave);
  };

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Service Pricing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="advance-pricing-option">
          <Form onSubmit={addStaffPrice}>
            <div className="advance-pricing-box">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => setDuration(e.target.value)}
                  value={duration}
                >
                  <option value="0">0min</option>
                  <option value="5">5min</option>
                  <option value="10">10min</option>
                  <option value="15">15min</option>
                  <option value="20">20min</option>
                  <option value="25">25min</option>
                  <option value="30">30min</option>
                  <option value="35">35min</option>
                  <option value="40">40min</option>
                  <option value="45">45min</option>
                  <option value="50">50min</option>
                  <option value="55">55min</option>
                  <option value="60" selected>
                    1h
                  </option>
                  <option value="65">1h 5min</option>
                  <option value="70">1h 10min</option>
                  <option value="75">1h 15min</option>
                  <option value="80">1h 20min</option>
                  <option value="85">1h 25min</option>
                  <option value="90">1h 30min</option>
                  <option value="95">1h 35min</option>
                  <option value="100">1h 40min</option>
                  <option value="105">1h 45min</option>
                  <option value="110">1h 50min</option>
                  <option value="115">1h 55min</option>
                  <option value="120">2h</option>
                  <option value="135">2h 15min</option>
                  <option value="150">2h 30min</option>
                  <option value="165">2h 45min</option>
                  <option value="180">3h</option>
                  <option value="195">3h 15min</option>
                  <option value="210">3h 30min</option>
                  <option value="225">3h 45min</option>
                  <option value="240">4h</option>
                  <option value="255">4h 15min</option>

                  <option value="270">4h 30min</option>
                  <option value="285">4h 45min</option>
                  <option value="300">5h</option>
                  <option value="315">5h 15min</option>
                  <option value="330">5h 30min</option>
                  <option value="345">5h 45min</option>
                  <option value="360">6h</option>
                  <option value="375">6h 15min</option>
                  <option value="390">6h 30min</option>
                  <option value="405">6h 45min</option>
                  <option value="420">7h</option>
                  <option value="435">7h 15min</option>
                  <option value="450">7h 30min</option>
                  <option value="465">7h 45min</option>
                  <option value="480">8h</option>
                  <option value="495">8h 15min</option>
                  <option value="510">8h 30min</option>
                  <option value="525">8h 45min</option>
                  <option value="540">9h</option>
                  <option value="555">9h 15min</option>
                  <option value="570">9h 30min</option>
                  <option value="585">9h 45min</option>
                  <option value="600">10h</option>
                  <option value="615">10h 15min</option>
                  <option value="630">10h 30min</option>
                  <option value="645">10h 45min</option>
                  <option value="660">11h</option>
                  <option value="675">11h 15min</option>
                  <option value="690">11h 30min</option>
                  <option value="705">11h 45min</option>
                  <option value="720">12h</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Price Type</Form.Label>
                <Form.Control
                  as="select"
                  value={priceType}
                  onChange={(e) => selectPriceType(e.target.value)}
                >
                  <option value="from">From</option>
                  <option value="free">Free</option>
                  <option value="fixed">Fixed</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  disabled={disablePriceFrom}
                  type="number"
                  placeholder="0.00"
                  onChange={(e) => setPriceFrom(e.target.value)}
                  value={priceFrom}
                />
                {/* {errors.priceFrom && (
                  <p className="error">{errors.priceFrom}</p>
                )} */}
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Promotion Price</Form.Label>
                <Form.Control
                  disabled={disableSpecialPrice}
                  type="number"
                  placeholder="0.00"
                  onChange={(e) => setSpecialPrice(e.target.value)}
                  value={specialPrice}
                />
              </Form.Group>
            </div>

            <div className="special-price-associate">
              <h4>Special pricing per associate</h4>
              {staffPricing.map((el, i) => (
                <div className="associate-row">
                  <div className="associate-user">
                    <img
                      src={el.image ? `${server}/${el.image}` : staffimg}
                      alt="Admin"
                    />
                    <p>{el.staffName}</p>
                  </div>

                  <div className="price-associate">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Associate Price</Form.Label>
                      <Form.Control
                        placeholder="0.00"
                        type="number"
                        value={el.staffPrice}
                        onChange={(e) =>
                          selectStaffPrice(
                            e.target.value,
                            i,
                            el.staffId,
                            el.staffName
                          )
                        }
                        // value={staffPrice}
                      />
                    </Form.Group>
                  </div>

                  <div className="duratiion-associate">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Duration</Form.Label>
                      <Form.Control
                        as="select"
                        value={el.staffDuration}
                        onClick={(e) =>
                          selectStaffDuration(
                            e.target.value,
                            i,
                            el.staffId,
                            el.staffName
                          )
                        }
                      >
                        <option value="0">0min</option>
                        <option value="5">5min</option>
                        <option value="10">10min</option>
                        <option value="15">15min</option>
                        <option value="20">20min</option>
                        <option value="25">25min</option>
                        <option value="30">30min</option>
                        <option value="35">35min</option>
                        <option value="40">40min</option>
                        <option value="45">45min</option>
                        <option value="50">50min</option>
                        <option value="55">55min</option>
                        <option value="60" selected>
                          1h
                        </option>
                        <option value="65">1h 5min</option>
                        <option value="70">1h 10min</option>
                        <option value="75">1h 15min</option>
                        <option value="80">1h 20min</option>
                        <option value="85">1h 25min</option>
                        <option value="90">1h 30min</option>
                        <option value="95">1h 35min</option>
                        <option value="100">1h 40min</option>
                        <option value="105">1h 45min</option>
                        <option value="110">1h 50min</option>
                        <option value="115">1h 55min</option>
                        <option value="120">2h</option>
                        <option value="135">2h 15min</option>
                        <option value="150">2h 30min</option>
                        <option value="165">2h 45min</option>
                        <option value="180">3h</option>
                        <option value="195">3h 15min</option>
                        <option value="210">3h 30min</option>
                        <option value="225">3h 45min</option>
                        <option value="240">4h</option>
                        <option value="255">4h 15min</option>

                        <option value="270">4h 30min</option>
                        <option value="285">4h 45min</option>
                        <option value="300">5h</option>
                        <option value="315">5h 15min</option>
                        <option value="330">5h 30min</option>
                        <option value="345">5h 45min</option>
                        <option value="360">6h</option>
                        <option value="375">6h 15min</option>
                        <option value="390">6h 30min</option>
                        <option value="405">6h 45min</option>
                        <option value="420">7h</option>
                        <option value="435">7h 15min</option>
                        <option value="450">7h 30min</option>
                        <option value="465">7h 45min</option>
                        <option value="480">8h</option>
                        <option value="495">8h 15min</option>
                        <option value="510">8h 30min</option>
                        <option value="525">8h 45min</option>
                        <option value="540">9h</option>
                        <option value="555">9h 15min</option>
                        <option value="570">9h 30min</option>
                        <option value="585">9h 45min</option>
                        <option value="600">10h</option>
                        <option value="615">10h 15min</option>
                        <option value="630">10h 30min</option>
                        <option value="645">10h 45min</option>
                        <option value="660">11h</option>
                        <option value="675">11h 15min</option>
                        <option value="690">11h 30min</option>
                        <option value="705">11h 45min</option>
                        <option value="720">12h</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              ))}
            </div>

            <div className="associate-pricing-buttons">
              <Button
                variant="default"
                className="btn-perple"
                onClick={cancelData}
              >
                Cancel
              </Button>
              <Button type="submit" className="btn-perple" onHide={saveData}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </div>
  );
}
