/* eslint-disable radix */
import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AdminWrapper from "../adminwrapper/wrapper";
import { adminInstance } from "../../../config/axios";
import Loader from "../../loader/loader";

const PartnerCommission = ({ name }) => {
  const [bussiness, setAllBusiness] = useState([]);
  const [show2, setshow2] = useState(false);
  const handleClose2 = () => setshow2(false);
  const [monthlyFee, setMonthlyFee] = useState("");
  const [commission, setCommission] = useState("");
  const [fixedCharge, setFixedCharge] = useState("");
  const [include, setInclude] = useState(false);
  const [bussId, setBussId] = useState("");
  const [loading, setLoading] = useState(false);
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);

  const getAllBussiness = async () => {
    setLoading(true);
    const payloadd = {
      Id: id,
    };
    const partnerData = await adminInstance().post("/partnerPricing", payloadd);
    const { code, getPrice } = partnerData.data;
    if (code === 200) {
      setAllBusiness(getPrice.pricing);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBussiness();
    // eslint-disable-next-line
  }, []);

  const validateBusiness = (values) => {
    const errorss = {};
    let isValid = true;

    if (!values.monthlyFee) {
      errorss.monthlyFee = "Monthly Fee is required";
      isValid = false;
    }
    if (!values.commission) {
      errorss.commission = "Commission is required";
      isValid = false;
    } else if (values.commission) {
      const x = parseFloat(values.commission);
      if (isNaN(x) || x < 0 || x > 100) {
        errorss.commission =
          "Discount value must be higher than 0 and equal or lower than 100";
        isValid = false;
      }
    }
    if (!values.fixedCharge) {
      errorss.fixedCharge = "Fixed Charge is required";
      isValid = false;
    }

    return { isValid, errorss };
  };

  const handleEdit = (data) => {
    setBussId(id);
    setMonthlyFee(data.monthlyFee);
    setCommission(data.commission);
    setFixedCharge(data.fixedCharge);
    setInclude(data.include);
    setshow2(true);
  };

  if (loading) {
    return (
      <AdminWrapper>
        <Loader />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper name={name}>
      <div className="Admin-client">
        <div className="container">
          <div className="back-btn">
            <h2 className="admin-title">Partner Commission</h2>
            <Link to="/admin/partner">
              <h2>Back</h2>
            </Link>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="partner-header">
                    <h2>Partner Commission</h2>
                  </div>
                  <div className="partner-table">
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th className="text-center">Monthly Fee</th>
                          <th className="text-center">Commission(%)</th>
                          <th className="text-center">Fixed Charge</th>
                          <th className="text-center bracket">
                            Monthly Fee Reqiured
                            <span>
                              (Minimum monthly fee is part of commission or not)
                            </span>{" "}
                          </th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      {bussiness &&
                        bussiness.map((el) => (
                          <tbody>
                            <tr>
                              <td className="text-center">{el.monthlyFee}</td>
                              <td className="text-center">{el.commission}</td>
                              <td className="text-center">{el.fixedCharge}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={el.include} />
                                  <span className="slider round" />
                                </label>
                              </td>
                              <td className="text-center">
                                <Button
                                  className="delete-btn"
                                  onClick={() => handleEdit(el)}
                                >
                                  {" "}
                                  Edit
                                </Button>{" "}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={show2} onHide={handleClose2} className="discount-12">
            <Modal.Header closeButton>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                Edit Pricing
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <EditBusiness
                validateBusiness={validateBusiness}
                setShow2={setshow2}
                bussId={bussId}
                monthlyFee={monthlyFee}
                commission={commission}
                fixedCharge={fixedCharge}
                include={include}
                getAllBussiness={getAllBussiness}
                setMonthlyFee={setMonthlyFee}
                setCommission={setCommission}
                setFixedCharge={setFixedCharge}
                setInclude={setInclude}
              />
            </Modal.Body>
            <Modal.Footer />
          </Modal>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default PartnerCommission;

function EditBusiness({
  validateBusiness,
  setShow2,
  bussId,
  country,
  currency,
  monthlyFee,
  fixedCharge,
  commission,
  getAllBussiness,
  setMonthlyFee,
  setCommission,
  setFixedCharge,
  include,
  setInclude,
}) {
  const [errors, setErrors] = useState({});

  const updateBusiness = async (e) => {
    e.preventDefault();
    const payloadd = {
      monthlyFee,
      commission,
      fixedCharge,
      bussId,
      include,
    };
    const validation = validateBusiness({
      monthlyFee,
      commission,
      fixedCharge,
    });
    setErrors(validation.errorss);
    if (!validation.isValid) {
      return;
    }
    const saveCategory = await adminInstance().post(
      "/commission/add",
      payloadd
    );
    const { code, msg } = saveCategory.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      getAllBussiness();
      toast.success(msg, { containerId: "B" });
      // clearValues();
      setShow2(false);
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  // const clearValues = () => {
  //   setCategoryname('');
  //   setCategorydescription('');
  // };
  return (
    <Form onSubmit={updateBusiness}>
      <Form.Group>
        <Form.Label>Monthly Fee</Form.Label>
        <Form.Control
          size="lg"
          type="Number"
          onChange={(e) => setMonthlyFee(e.target.value)}
          value={monthlyFee}
          placeholder="Monthly Fee"
        />
        {errors.monthlyFee && <p className="error">{errors.monthlyFee}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label>Commission</Form.Label>
        <Form.Control
          size="lg"
          type="Number"
          onChange={(e) => setCommission(e.target.value)}
          value={commission}
          placeholder="Commission"
        />
        {errors.commission && <p className="error">{errors.commission}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label>Fixed Charge</Form.Label>
        <Form.Control
          size="lg"
          type="Number"
          onChange={(e) => setFixedCharge(e.target.value)}
          value={fixedCharge}
          placeholder="Fixed Charge"
        />
        {errors.fixedCharge && <p className="error">{errors.fixedCharge}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label style={{ display: "block" }}>
          {" "}
          Monthly Fee Reqiured (Minimum monthly fee is part of commission or
          not){" "}
        </Form.Label>
        <label className="switch">
          <input
            type="checkbox"
            checked={include}
            onChange={(e) => setInclude(e.target.checked)}
          />
          <span className="slider round" />
        </label>
      </Form.Group>

      <Button variant="primary" type="submit">
        Update
      </Button>
    </Form>
  );
}
