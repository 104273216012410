/* eslint-disable radix */
import React, { useEffect, useState, useCallback } from "react";
import { Table, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactExport from "react-export-excel";
import AdminWrapper from "../adminwrapper/wrapper";
import { adminInstance } from "../../../config/axios";
import { client } from "../../../config/keys";
import Loader from "../../loader/loader";

const AdminPartner = ({ name }) => {
  const [partners, setPartners] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [copy, setCopy] = useState([]);
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const getAllPartners = useCallback(async () => {
    if (skip === 0) {
      setLoading(true);
    }

    const payload = {
      skip,
      limit,
    };
    const partnerData = await adminInstance().post("/partners", payload);
    const { code, findData, length } = partnerData.data;
    const arr = [];
    if (code === 200) {
      setPartners(findData);
      setTotalCount(length);
      findData.forEach((el) => {
        arr.push({
          name: el.firstname,
          email: el.email,
          phone: el.phone,
          Address: el.address[0].country,
          BussinessType: el.businessType.businessname,
          status: el.publish ? "Online" : "Offline",
        });
        setCopy(arr);
      });
      setLoading(false);
    }
  }, [skip, limit]);

  useEffect(() => {
    getAllPartners();
  }, [getAllPartners]);

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);

  const setPublish = async (id) => {
    const payload = {
      id,
    };
    const saveCategory = await adminInstance().post("/publishStore", payload);
    const { code } = saveCategory.data;
    if (code === 200) {
      getAllPartners();
    }
  };

  const setAccess = async (data) => {
    const payload = {
      id: data,
    };
    const loginUser = await adminInstance().post(
      "/accessPartnerAccount",
      payload
    );
    const { code, msg, token, authToken, userid } = loginUser.data;
    if (code === 200) {
      localStorage.setItem("userid", userid);
      localStorage.setItem("partnertoken", token);
      localStorage.setItem("authtoken", authToken);
      localStorage.setItem("admindata", "admin");
      window.open(`${client}/partners/dashboard`);
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(copy[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };

  if (loading) {
    return (
      <AdminWrapper>
        <Loader />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper name={name}>
      <div className="Admin-partners">
        <div className="container">
          <div className="partner-heading">
            <h2 className="admin-title">Partners</h2>
            {copy.length > 0 && (
              <ExcelFile
                filename="Appointlee Partners"
                element={<button>Download Excel</button>}
              >
                <ExcelSheet data={copy} name="partners-list">
                  {filterColumns(copy).map((col) => (
                    <ExcelColumn label={camelCase(col)} value={col} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            )}
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="partner-header">
                    <h2>Partners</h2>
                  </div> */}
                  <div className="partner-table">
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th className="text-left">Name</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Phone</th>
                          <th className="text-center">Address</th>
                          <th className="text-center">Business Type</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      {partners &&
                        partners.map((el) => (
                          <tbody>
                            <tr>
                              <td className="text-left">
                                {el.firstname} {el.lastname}
                              </td>
                              <td className="text-center">{el.email}</td>
                              <td className="text-center">{el.phone}</td>
                              <td className="text-center">
                                {el.address[0].country}
                              </td>
                              <td className="text-center">
                                {el.businessType.businessname}
                              </td>
                              <td className="text-center">
                                {el.publish ? "Online" : "Offline"}
                              </td>
                              <td className="text-center">
                                {/* {el.publish ? (
                                <Button className="delete-btn" onClick={() => setPublish(el._id)}> Online</Button>
                              ) : (
                                <Button className="delete-btn" onClick={() => setPublish(el._id)}> Offline</Button>
                              )}
                              {' '}
                              <Link
                                className="delete-btn act-balance"
                                to={{
                                  pathname: `/admin/monthlisting/${el._id}`,
                                }}
                              >
                                {' '}
                                Account Balance
                              </Link> */}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname: `/admin/monthlisting/${el._id}`,
                                      }}
                                    >
                                      {" "}
                                      Account Balance
                                    </Link>
                                    {el.publish ? (
                                      <Dropdown.Item
                                        onClick={() => setPublish(el._id)}
                                      >
                                        Online
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        onClick={() => setPublish(el._id)}
                                      >
                                        Offline
                                      </Dropdown.Item>
                                    )}

                                    <Dropdown.Item
                                      onClick={() => setAccess(el._id)}
                                    >
                                      Access Account
                                    </Dropdown.Item>
                                    <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname: `/admin/partnercommission/${el._id}`,
                                      }}
                                    >
                                      {" "}
                                      Commission Settings{" "}
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname: `/admin/payout/${el._id}`,
                                      }}
                                    >
                                      {" "}
                                      Payouts{" "}
                                    </Link>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </Table>
                  </div>
                  {/* <Button onClick={() => Pay()}>Pay </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="store-pagination col-md-12 text-center">
            {totalCount >= limit && (
              <div className="row">
                <div className="col-md-12">
                  <div className="pagination-section">
                    <ReactPaginate
                      previousLabel="← Previous"
                      nextLabel="Next →"
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName="pagination-chk"
                      previousLinkClassName="pagination__link"
                      nextLinkClassName="pagination__link"
                      disabledClassName="pagination__link--disabled"
                      activeClassName="pagination__link--active"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminPartner;
