import React, { useState, useEffect, useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { toast } from "react-toastify";
import moment from "moment";
import PartnerWrapper from "../partnerwrapper/wrapper";
import Discount from "../../../assets/discount.svg";
import history from "../../../config/history";
import { partnerInstance } from "../../../config/axios";
import Loader from "../../loader/loader";
import { validateDiscountType } from "../../../function/validate";
import PartnerContext from "../../../context/context";
import "./style.css";

const DiscountType = () => {
  const { info } = useLocation();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { username } = useContext(PartnerContext);
  const { currency, sections } = username;
  const titleRef = useRef(sections);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleClose = () => setShow(false);
  const [discountName, setDiscountName] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  // const [invoiceno, setInvoiceNo] = useState('');
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line
  const [voucherSale, setVoucherSale] = useState(true);
  const [serviceSale, setServiceSale] = useState(true);
  const [discountType, setDiscountType] = useState("%");
  const [discountTypes, setDiscountTypes] = useState([]);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [discountId, setDiscountId] = useState("");
  const handleShow = () => {
    clearValues();
    setShow(true);
    setErrors("");
  };

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getDiscountType();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  // get discount type
  const getDiscountType = async () => {
    setLoading(true);
    const discount = await partnerInstance().get("/getDiscountType");
    const { code, discountTypeData } = discount.data;
    if (code === 200) {
      setDiscountTypes(discountTypeData);
      setLoading(false);
    }
  };

  // called on change of discount type (% or INR)
  const handleDiscountType = (e) => {
    setDiscountType(e);
  };
  // add discount type
  const addDiscountType = async (e) => {
    e.preventDefault();
    const payload = {
      discountName,
      discountValue,
      discountType,
      voucherSale,
      serviceSale,
      currentDate,
    };
    const validation = validateDiscountType({
      discountName,
      discountValue,
      discountType,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const discountData = await partnerInstance().post(
      "/addDiscountType",
      payload
    );
    const { code, msg } = discountData.data;
    if (code === 200) {
      getDiscountType();
      toast.success(msg, { containerId: "B" });
      setShow(false);
      clearValues();
      if (info) {
        history.push("/partners/availablity");
      }
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const clearValues = () => {
    setDiscountName("");
    setDiscountValue("");
    setServiceSale(false);
  };
  // Close edit modal
  const handleHide = () => {
    setShowEdit(false);
    setShowDelete(false);
  };

  // For pass data to edit modal
  const handleShowEdit = (
    id,
    discountname,
    discountvalue,
    discountvaluetype,
    servicesale
  ) => {
    setDiscountId(id);
    setDiscountName(discountname);
    setDiscountValue(discountvalue);
    setDiscountType(discountvaluetype);
    setServiceSale(servicesale);
    setShowEdit(true);
    setErrors("");
  };

  const handleShowDelete = (id) => {
    setDiscountId(id);
    setShowDelete(true);
  };
  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Discount Types">
      {/* {loading && (
   <Loader />
      )} */}
      <div className="discount-type">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="admin-title">
                {/* Discount Types */}
                <Button onClick={handleShow} className="btn-perple">
                  Add Discount
                </Button>
              </h2>

              <div className="">
                {discountTypes.length > 0 ? (
                  <>
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th className="text-center">Discount Name</th>
                          <th className="text-center">Discount Value</th>
                          <th className="text-center">Discount Value Type</th>
                          <th className="text-center">Discount Date</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {discountTypes.map((el, i) => (
                          <tr>
                            <td className="text-center">{el.discountname}</td>
                            <td className="text-center">{el.discountvalue}</td>
                            <td className="text-center">
                              {el.discountvaluetype}
                            </td>
                            <td className="text-center">{el.discountdate}</td>
                            <td className="text-center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  style={{ marginRight: "10px" }}
                                  onClick={() =>
                                    handleShowEdit(
                                      el._id,
                                      el.discountname,
                                      el.discountvalue,
                                      el.discountvaluetype,
                                      el.servicesale
                                    )
                                  }
                                >
                                  <i className="fa fa-pencil-square-o" />{" "}
                                </Button>

                                <Button
                                  className="delete-btn"
                                  onClick={() => handleShowDelete(el._id)}
                                >
                                  <i className="fa fa-trash" />{" "}
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <Card>
                      <Card.Img variant="top" />
                      <Card.Body>
                        <Card.Title />
                        <Card.Text>
                          <div className="tax-head">
                            <img
                              src={Discount}
                              className="mb-2"
                              alt="Discount"
                            />
                            <h5>No Discount</h5>
                            <p className="text-muted ">
                              You do not have any discounts yet, create new
                              discount to apply it on checkout
                            </p>
                            <Button onClick={handleShow} className="btn-perple">
                              {" "}
                              Add Discount
                            </Button>
                          </div>
                          <div>{/* <h1 /> */}</div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            New Discount Type
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addDiscountType}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Discount name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Discount"
                onChange={(e) => setDiscountName(e.target.value)}
                value={discountName}
              />
              {errors.discountName && (
                <p className="error">{errors.discountName}</p>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Discount value</Form.Label>
              <Row>
                <Col md={7}>
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{discountType}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="inlineFormInputGroup"
                      type="number"
                      placeholder=""
                      onChange={(e) => setDiscountValue(e.target.value)}
                      value={discountValue}
                    />
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <div
                    className="btn-group btn-group-toggle disablebutton"
                    data-toggle="buttons"
                  >
                    {discountType === currency ? (
                      <>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value={currency}
                          className="btn btn-primary active1"
                        >
                          {currency}
                        </Button>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value="%"
                          className="btn "
                        >
                          %
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value={currency}
                          className="btn "
                        >
                          {currency}
                        </Button>
                        <Button
                          onClick={(e) => handleDiscountType(e.target.value)}
                          value="%"
                          className="btn btn-primary active1"
                        >
                          %
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              {errors.discountValue && (
                <p className="error">{errors.discountValue}</p>
              )}
            </Form.Group>

            <Form.Group>
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  onChange={(e) => setServiceSale(e.target.checked)}
                  checked={serviceSale}
                />
                <Form.Label className="">Discount on Service</Form.Label>
              </InputGroup.Prepend>
            </Form.Group>

            {/* <Form.Group>
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  onChange={() => setVoucherSale(!voucherSale)}
                  checked={voucherSale}
                />
                <Form.Label className="">Discount on Voucher</Form.Label>
              </InputGroup.Prepend>
            </Form.Group> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="btn-perple">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEdit} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            EDIT DISCOUNT TYPE
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditDiscountType
            discountId={discountId}
            discountName={discountName}
            discountValue={discountValue}
            discountType={discountType}
            setDiscountName={setDiscountName}
            setDiscountValue={setDiscountValue}
            setDiscountType={setDiscountType}
            setShowEdit={setShowEdit}
            getDiscountType={() => getDiscountType()}
            setErrors={setErrors}
            errors={errors}
            handleDiscountType={handleDiscountType}
            currency={currency}
            setServiceSale={setServiceSale}
            serviceSale={serviceSale}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>

      <Modal show={showDelete} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            DELETE DISCOUNT TYPE
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeleteDiscountType
            discountId={discountId}
            setShowDelete={setShowDelete}
            getDiscountType={() => getDiscountType()}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </PartnerWrapper>
  );
};

export default DiscountType;

function EditDiscountType({
  handleDiscountType,
  discountId,
  discountName,
  setDiscountName,
  discountValue,
  setDiscountValue,
  discountType,
  setShowEdit,
  getDiscountType,
  setErrors,
  errors,
  currency,
  setServiceSale,
  serviceSale,
}) {
  const updateDiscountType = async (e) => {
    e.preventDefault();
    const payloadd = {
      discountName,
      discountValue,
      discountType,
      servicesale: serviceSale,
    };

    const validation = validateDiscountType({
      discountName,
      discountValue,
      discountType,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const updateDiscount = await partnerInstance().put(
      `/editDiscountType/${discountId}`,
      payloadd
    );
    const { code, msg } = updateDiscount.data;
    if (code === 200) {
      setShowEdit(false);
      getDiscountType();
      toast.success(msg, { containerId: "B" });
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const clearValues = () => {
    setDiscountName("");
    setDiscountValue("");
  };

  return (
    <Form onSubmit={updateDiscountType}>
      <Form.Group>
        <Form.Label>Discount name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Invoice"
          onChange={(e) => setDiscountName(e.target.value)}
          value={discountName}
        />
        {errors.discountName && <p className="error">{errors.discountName}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label>Discount value</Form.Label>
        <Row>
          <Col md={7}>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>{discountType}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="inlineFormInputGroup"
                placeholder=""
                onChange={(e) => setDiscountValue(e.target.value)}
                value={discountValue}
              />
            </InputGroup>
          </Col>
          <Col md={4}>
            <div
              className="btn-group btn-group-toggle disablebutton"
              data-toggle="buttons"
            >
              {discountType === currency ? (
                <>
                  <Button
                    onClick={(e) => handleDiscountType(e.target.value)}
                    value={currency}
                    className="btn btn-primary active1"
                  >
                    {currency}
                  </Button>
                  <Button
                    onClick={(e) => handleDiscountType(e.target.value)}
                    value="%"
                    className="btn "
                  >
                    %
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={(e) => handleDiscountType(e.target.value)}
                    value={currency}
                    className="btn "
                  >
                    {currency}
                  </Button>
                  <Button
                    onClick={(e) => handleDiscountType(e.target.value)}
                    value="%"
                    className="btn btn-primary active1"
                  >
                    %
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="discount-type-checkbox">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  onChange={(e) => setServiceSale(e.target.checked)}
                  checked={serviceSale}
                />
                <Form.Label className="">Discount on Service</Form.Label>
              </InputGroup.Prepend>
            </Form.Group>
          </Col>
        </Row>
        {errors.discountValue && (
          <p className="error">{errors.discountValue}</p>
        )}
      </Form.Group>

      {/* <Form.Group>
        <InputGroup.Prepend>
          <InputGroup.Checkbox
            onChange={() => setServiceSale(!serviceSale)}
            checked={serviceSale}
          />
          <Form.Label className="">Enable For Service Sales</Form.Label>
        </InputGroup.Prepend>
      </Form.Group>

      <Form.Group>
        <InputGroup.Prepend>
          <InputGroup.Checkbox
            onChange={() => setVoucherSale(!voucherSale)}
            checked={voucherSale}
          />
          <Form.Label className="">Enable For Voucher Sales</Form.Label>
        </InputGroup.Prepend>
      </Form.Group> */}
      <div className="text-right p-0">
        <Button variant="primary" type="submit" className="btn-perple">
          Save
        </Button>
      </div>
    </Form>
  );
}

function DeleteDiscountType({ discountId, getDiscountType, setShowDelete }) {
  const deleteIds = async (e) => {
    e.preventDefault();
    const payloadd = {
      deleteId: discountId,
    };
    const deleteDiscount = await partnerInstance().post(
      "/deleteDiscountType/",
      payloadd
    );

    const { code, msg } = deleteDiscount.data;
    if (code === 200) {
      setShowDelete(false);
      getDiscountType();
      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={deleteIds}>
      <div className="voucher-delete">
        <p>Are You sure you want to delete ?</p>
        <Button variant="primary" type="submit" className="btn-perple">
          Delete
        </Button>
      </div>
    </Form>
  );
}

EditDiscountType.propTypes = {
  handleDiscountType: PropTypes.func.isRequired,
  discountId: PropTypes.string.isRequired,
  discountName: PropTypes.string.isRequired,
  setDiscountName: PropTypes.string.isRequired,
  discountValue: PropTypes.number.isRequired,
  setDiscountValue: PropTypes.func.isRequired,
  discountType: PropTypes.string.isRequired,
  setShowEdit: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  getDiscountType: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
};

DeleteDiscountType.propTypes = {
  discountId: PropTypes.string.isRequired,
  setShowDelete: PropTypes.func.isRequired,
  getDiscountType: PropTypes.func.isRequired,
};
