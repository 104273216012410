import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Modal, InputGroup } from "react-bootstrap";
import PartnerWrapper from "../partnerwrapper/wrapper";
import "./voucher.css";
import { partnerInstance } from "../../../config/axios";
import { toast } from "react-toastify";
import history from "../../../config/history";
import queryString from "query-string";
import { validateVoucher } from "../../../function/validate";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";

const EditVoucher = () => {
  const { username } = useContext(PartnerContext);
  const { currency } = username;
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const [voucherName, setVoucherName] = useState("");
  const [voucherValue, setVoucherValue] = useState("");
  const [retailPrice, setRetailPrice] = useState("");
  const [validDays, setValidDays] = useState("");
  // eslint-disable-next-line
  const [serviceId, setServiceId] = useState([]);
  // const [packageId, setPackageId] = useState([]);
  const [noOfSales, setNoOfSales] = useState("");
  const [voucherTitle, setVoucherTitle] = useState("");
  const [voucherDescription, setVoucherDescription] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState({});
  const [services, setServices] = useState([]);
  const [copy, setCopy] = useState(0);
  // const [checked, setChecked] = useState(false);
  const [ID, setID] = useState("");

  const getVoucher = async () => {
    setLoading(true);
    const value = queryString.parse(window.location.search);
    const { id } = value;
    const idd = {
      voucherid: id,
    };
    setID(id);
    const voucher = await partnerInstance().post("/getVoucher", idd);

    const { code, voucherData, servicelist } = voucher.data;
    if (code === 200) {
      setVoucherName(voucherData.voucher[0].voucherName);
      setCopy(servicelist.length);
      setVoucherValue(voucherData.voucher[0].voucherValue);
      setRetailPrice(voucherData.voucher[0].retailPrice);
      setValidDays(voucherData.voucher[0].validDays);
      setNoOfSales(voucherData.voucher[0].noofsales);
      setVoucherTitle(voucherData.voucher[0].vouchertitle);
      setVoucherDescription(voucherData.voucher[0].voucherdescription);
      setNote(voucherData.voucher[0].note);
      setServiceId(voucherData.voucher[0].serviceid);
      servicelist.forEach((el) => {
        const filterSelected = voucherData.voucher[0].serviceid.filter(
          (e) => e === el._id
        );
        if (filterSelected.length > 0) {
          el.checked = true;
        }
      });
      setServices(servicelist);
      setLoading(false);
      // update();
    }
  };
  useEffect(() => {
    getVoucher();
  }, []);

  const getfilterServices = () => {
    const arrayof = [];
    const filterSelectedId = services.filter((el) => el.checked);
    filterSelectedId.forEach((el) => {
      arrayof.push(el._id);
    });
    return arrayof.length;
  };

  const updateVoucher = async (e) => {
    e.preventDefault();
    const arrayofid = [];

    const filterSelectedIds = services.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });

    const payload = {
      voucherName,
      voucherValue,
      retailPrice,
      validDays,
      serviceid: arrayofid,
      // packageid: packageId,
      noofsales: noOfSales,
      vouchertitle: voucherTitle,
      voucherdescription: voucherDescription,
      note,
    };
    const validation = validateVoucher({
      voucherName,
      voucherValue,
      retailPrice,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const voucher = await partnerInstance().post(`/editVoucher/${ID}`, payload);
    const { code, msg } = voucher.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      history.push("/partners/voucher");
      // clearValues();
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <PartnerWrapper>
      {loading && <Loader />}
      <div className="setup add-voucher">
        <div className="container">
          <Form onSubmit={updateVoucher}>
            <div className="row">
              <div className="col-md-12">
                <h2 className="admin-title">
                  Add Voucher
                  <Link to="/partners/voucher">
                    <i className="fa fa-angle-left" /> Back
                  </Link>
                </h2>
              </div>
              <div className="col-md-6">
                <div className="service-card">
                  <div className="service-body">
                    <h2 className="admin-title admin-inner-title">
                      Voucher Info{" "}
                    </h2>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Voucher Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setVoucherName(e.target.value)}
                        value={voucherName}
                      />
                      {errors.voucherName && (
                        <p className="error">{errors.voucherName}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Voucher Value</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>{currency}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder=""
                          type="number"
                          onChange={(e) => setVoucherValue(e.target.value)}
                          value={voucherValue}
                        />
                        {errors.voucherValue && (
                          <p className="error">{errors.voucherValue}</p>
                        )}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Retail Price </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>{currency}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder=""
                          type="number"
                          onChange={(e) => setRetailPrice(e.target.value)}
                          value={retailPrice}
                        />
                      </InputGroup>
                      {errors.retailPrice && (
                        <p className="error">{errors.retailPrice}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Valid Till</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setValidDays(e.target.value)}
                        value={validDays}
                      >
                        {" "}
                        <option value="14 Days">14 Days</option>
                        <option value="1 months">1 months</option>
                        <option value="2 months">2 months</option>
                        <option value="3 months">3 months</option>
                        <option value="6 months">6 months</option>
                        <option value="1 year">1 year</option>
                        <option value="3 Year">3 Year</option>
                        <option value="5 year">5 year</option>
                        <option value="Forever">Forever</option>
                      </Form.Control>
                    </Form.Group>
                    {/* <Form.Group
                      controlId="formBasicloginone"
                      className="switch-sec"
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          // onChange={() => setNoOfSales(!noOfSales)}
                          // checked={noOfSales}
                        />
                        <span className="slider round" />
                      </label>
                      <br />
                      <span className="limit-sale">Limit Amount of sales</span>
                    </Form.Group> */}
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>No of sales</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setNoOfSales(e.target.value)}
                        value={noOfSales}
                      >
                        {" "}
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        <option value="2500">2500</option>
                      </Form.Control>
                    </Form.Group>

                    <h2 className="admin-title admin-inner-title">
                      Services included
                    </h2>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Included services </Form.Label>
                      <div className="all-services">
                        <span>
                          {" "}
                          {copy !== getfilterServices() ? (
                            <>{getfilterServices()} services selected</>
                          ) : (
                            <p>Selected All</p>
                          )}{" "}
                        </span>
                        <span>
                          <Button onClick={handleViewShow}>Edit</Button>
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-card">
                  <div className="service-body">
                    <h2 className="admin-title admin-inner-title">Text</h2>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Voucher title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setVoucherTitle(e.target.value)}
                        value={voucherTitle}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Voucher description</Form.Label>
                      <Form.Control
                        placeholder=""
                        as="textarea"
                        rows="3"
                        onChange={(e) => setVoucherDescription(e.target.value)}
                        value={voucherDescription}
                      />
                    </Form.Group>

                    {/* <h2 className="admin-title">Voucher colour</h2>
                    <p>Select a color that matches your business.</p>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Choose a Color</Form.Label>
                      <div className="choose-color">
                        <div className="color-input orange-color">
                          <Form.Check
                            name="radio-c"
                            id="orange-c"
                            type="radio"
                            // onChange={(e) => setVoucherColor(e.target.value)}
                            // defaultChecked
                          />
                        </div>
                        <div className="color-input blue-color">
                          <Form.Check name="radio-c" id="blue-c" type="radio" />
                        </div>
                        <div className="color-input green-color">
                          <Form.Check
                            name="radio-c"
                            id="green-c"
                            type="radio"
                          />
                        </div>
                        <div className="color-input dark-color">
                          <Form.Check name="radio-c" id="dark-c" type="radio" />
                        </div>
                      </div>
                    </Form.Group> */}

                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Note</Form.Label>
                      <Form.Control
                        placeholder="Notes for the client"
                        as="textarea"
                        rows="3"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-right">
                <Button type="submit" className=" btn-perple">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <Modal
        className="category-popup select-service-popup"
        show={view}
        onHide={handleViewClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectServices
            services={services}
            setServices={setServices}
            currency={currency}
            handleViewClose={handleViewClose}
          />
        </Modal.Body>
      </Modal>
    </PartnerWrapper>
  );
};

export default EditVoucher;

const SelectServices = ({
  services,
  setServices,
  currency,
  handleViewClose,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const array = [];
  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const oldState = [...services];
  useEffect(() => {
    const filter = oldState.filter((item) => item.checked);
    if (filter.length === oldState.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    // eslint-disable-next-line
  }, []);

  const update = useUpdate();
  const handleCheck = (i, el) => {
    oldState.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    const filter = oldState.filter((item) => item.checked);
    if (filter.length === oldState.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setServices(services);

    update();
  };

  const handleSelectAll = (e) => {
    setSelectAll(!selectAll);
    services.forEach((el) => {
      if (selectAll) {
        services.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        setServices(array);
      } else {
        services.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
        setServices(services);
        update();
      }
    });
    setServices(services);
    update();
  };

  const Close = () => {
    setServices(oldState);
    handleViewClose();
  };

  return (
    <>
      <div className="add-category">
        <Form>
          <div className="col-sm-12">
            <div className="select-servicespopup">
              <Form.Group>
                <InputGroup.Prepend>
                  <InputGroup.Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <Form.Label>Select All</Form.Label>
                </InputGroup.Prepend>
              </Form.Group>
            </div>
          </div>
          {services &&
            services.map((item, i) => (
              <div className="col-sm-12" key={i}>
                {/* <Form.Group>
                <InputGroup.Prepend>
                  <InputGroup.Checkbox
                    checked={item.checked}
                    value={item._id}
                    onClick={(e) => handleCheck(e, item)}
                  />
                  <Form.Label>{item.serviceName}</Form.Label>
                </InputGroup.Prepend>
              </Form.Group> */}

                <div className="select-servicespopup">
                  <Form.Group>
                    <InputGroup.Prepend>
                      <InputGroup.Checkbox
                        checked={item.checked}
                        value={item._id}
                        onClick={(e) => handleCheck(e, item)}
                      />
                      <Form.Label>{item.serviceName}</Form.Label>
                    </InputGroup.Prepend>
                  </Form.Group>
                  <span>
                    {currency} {item.priceFrom ? item.priceFrom : "0"}
                  </span>
                </div>
              </div>
            ))}
        </Form>
        {/* <button onClick={handleViewClose} className="voucher-btn">Save</button> */}
        <div className="col-md-12 text-right" style={{ marginTop: "20px" }}>
          <Button onClick={Close} type="submit" className=" btn-perple">
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
