import React, {
  useState, useEffect, useCallback,
} from 'react';
import Slider from 'react-slick';
import './selectservices.css';
import { ProgressBar } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { websiteInstance } from '../../../config/axios';
import { server } from '../../../config/keys';
import Helmet from 'react-helmet';
import store2 from '../../../assets/no-image.jpg';

const SelectTime = () => {
  const { state } = useLocation();
  let priceTotal = 0;
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf('/') + 1);
  const [selectServices] = useState(state.servicesPanel);
  const [staffName] = useState(state.selectedStaff.name);
  const [staffId] = useState(state.selectedStaff.id);
  const [shifts] = useState(state.selectedStaff.shiftSlot);
  const [selectStaffs] = useState(state.staffs);
  const [partnerSlot] = useState(state.partnerTime);
  const [currency] = useState(state.currency);
  const [closedDates, setClosedDates] = useState([]);
  const [storeImage] = useState(state.storeImage);
  const [paymentRequired] = useState(state.paymentRequired);
  const [outService] = useState(state.outService);
  const [eventKey] = useState(state.eventKey);
  const [availableSlots] = useState(
    state.partnerTime.availability,
  );
  const [timeData, setTimeData] = useState([]);
  const [message, setMessage] = useState('No Timings Available');
  const [appointmentDate, setAppointmentDate] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  );
  //const [monthName, setMonthName] = useState(moment(new Date()).month());
  const [chooseStaff] = useState(state.chooseStaff);
  const [bundle] = useState(state.bundle);
  //const [prevDate, setPrevDate] = useState('');
  const [nextDate, setNextDate] = useState('');
  const customeSlider = React.createRef();
  const days = [];
  const DAYS = () => {
    const dateStart = moment(new Date());
    const dateEnd = moment().add(60, 'days');
    while (dateEnd.diff(dateStart, 'days') >= 0) {
      days.push({
        dayNo: dateStart.format('D'),
        daysName: dateStart.format('ddd'),
        date: dateStart.format('YYYY-MM-DD'),
      });
      dateStart.add(1, 'days');
    }
  };
  DAYS();
  const time = () => {
    const x = 5; // minutes interval
    const timeDataList = []; // time array
    let tt = 0; // start time
    // loop to increment the time and push results in array
    for (let i = 0; tt < 24 * 60; i++) {
      const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      const mm = tt % 60; // getting minutes of the hour in 0-55 format
      timeDataList[i] = `${`0${hh % 24}`.slice(-2)}:${`0${mm}`.slice(-2)}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt += x;
    }
    setTimeData(timeDataList);
    return timeDataList;
  };
  // const defaultSlots = () => {
  //   const x = 5; // minutes interval
  //   const timeDataList = []; // time array
  //   let tt = 0; // start time
  //   // loop to increment the time and push results in array
  //   for (let i = 0; tt < 24 * 60; i++) {
  //     const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
  //     const mm = tt % 60; // getting minutes of the hour in 0-55 format
  //     timeDataList[i] = `${`0${hh % 24}`.slice(-2)}:${`0${mm}`.slice(-2)}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
  //     tt += x;
  //   }

  //   const setFullName = moment(new Date()).format('dddd');
  //   const filterSlots = availableSlots.filter(
  //     (item) => item.Day === setFullName,
  //   );
  //   const getStartTime = filterSlots[0].startTime;
  //   const getEndTime = filterSlots[0].endTime;
  //   const timeStartDate = moment(getStartTime, 'hh:mm A').format('HH:mm');
  //   const timeEndDate = moment(getEndTime, 'hh:mm A').format('HH:mm');
  //   const filterFullSlots = timeDataList.filter(
  //     (ele) => timeStartDate <= ele && timeEndDate >= ele,
  //   );
  //   setTimeData(filterFullSlots);
  // };

  const getBusinessPartner = useCallback(async () => {
    const payload = {
      partnerid: id,
    };
    const partnersData = await websiteInstance().post(
      '/getPartnerByPartnerId',
      payload,
    );
    const { code, partnerData } = partnersData.data;
    if (code === 200) {
      setClosedDates(partnerData.closeddate);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    // if (state.bundle) {
    //   defaultSlots();
    // } else {
    setSlots(appointmentDate);
    // }
    getBusinessPartner();
    // eslint-disable-next-line
  }, [getBusinessPartner]);

  const settings = {
    focusOnSelect: true,
    dots: false,
    infinite: false,
    className: 'center',
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    beforeChange(currentSlide, nextSlide) {
      gotoPrev(currentSlide, nextSlide);
    },
    afterChange(currentSlide) {
      gotoNext(currentSlide);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const gotoNext = (currentSlide) => {
    const filterNext = days.filter((el, i) => i === currentSlide);
    if (filterNext.length > 0) {
      //const dateData = moment(new Date(filterNext[0].date)).month();
      //setMonthName(dateData);
      setAppointmentDate(filterNext[0].date);
      setNextDate(filterNext[0].date);
      setSlots(filterNext[0].date);
    }
  };

  const gotoPrev = (currentSlide) => {
    customeSlider.current.slickPrev();
    const filterPrev = days.filter((el, i) => i === currentSlide);
    if (filterPrev.length > 0) {
      // setSlots(filterPrev[0].date);
      // setPrevDate(filterPrev[0].date);
    }
  };

  const setSlots = async (data) => {
    const setName = moment(data).format('dddd');
    const finClosed = availableSlots.find((info) => info.Day === setName);
    if (finClosed.enabled) {
      const Closed = closedDates;
      const filterClosedDates = Closed.filter((el) => new Date(data).getTime() >= new Date(el.from).getTime()
        && new Date(data).getTime() <= new Date(el.to).getTime());
      if (filterClosedDates.length > 0) {
        setTimeData([]);
        setMessage('Closed');
      } else {
        const setFullName = moment(data).format('dddd');
        const filterSlots = shifts.find(
          (item) => moment(item.date).format('dddd') === setFullName,
        );
        let timeStartDate;
        let timeEndDate;
        if (filterSlots) {
          // const currentTime = moment(data);
          const getStartTime = new Date().toLocaleDateString() === new Date(data).toLocaleDateString()
            ? moment().format('HH:mm') : filterSlots.timing[0].starttime;
       
          const getEndTime = filterSlots.timing[0].endtime;
          timeStartDate = moment(getStartTime, 'hh:mm A').format('HH:mm');
          timeEndDate = moment(getEndTime, 'hh:mm A').format('HH:mm');
          // if (chooseStaff) {
          if (shifts.length > 0) {
            setAppointmentDate(data);
            const Index = shifts.findIndex((el) => new Date(el.date).toLocaleDateString() === new Date(data).toLocaleDateString()
              || (el.repeat === 'weekly' && moment(el.date).format('dddd') === setFullName));
            if (Index > -1) {
              if (shifts[Index].repeat === 'weekly') {
                if (
                  new Date(data).toLocaleDateString()
                  === new Date(shifts[Index].date).toLocaleDateString()
                  || moment(shifts[Index].date).format('dddd') === setFullName
                ) {
                  const oldState = await time();
                  const filterFullSlots = oldState.filter(
                    (ele) => timeStartDate <= ele && timeEndDate >= ele,
                  );
                  if (filterFullSlots.length > 0) {
                    setTimeData(filterFullSlots);
                  } else {
                    setTimeData([]);
                  }
                } else {
                  setTimeData([]);
                  setMessage('Staff Not available on this day');
                }
              } else {
                setMessage('Helllllo');
                const oldState = await time();
                const filterFullSlots = oldState.filter(
                  (ele) => timeStartDate <= ele && timeEndDate >= ele,
                );
                if (filterFullSlots.length > 0) {
                  setTimeData(filterFullSlots);
                } else {
                  setTimeData([]);
                }
              }
            } else {
              setTimeData([]);
              setMessage('Staff Not available on this day');
            }
          } else {
            setTimeData([]);
            setMessage('Sunday Closed');
          }
          // } else {
          //   setMessage('Shifts Not Selected Yet');
          //   setTimeData([]);
          // }
        } else if (setFullName !== 'Sunday') {
          setAppointmentDate(data);
          const oldState = await time();
          const filterFullSlots = oldState.filter(
            (ele) => timeStartDate <= ele && timeEndDate >= ele,
          );
          if (filterFullSlots.length > 0) {
            setTimeData(filterFullSlots);
          } else {
            setTimeData([]);
          }
        } else {
          setTimeData([]);
          const setFullNamee = moment(nextDate).format('dddd');
          if (setFullNamee === 'Sunday') {
            setMessage('No Timings Available');
          } else {
            setMessage('Sunday Closed');
          }
        }
      }
    } else {
      setTimeData([]);
      setMessage('Store Closed');
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps" />
      </Helmet>
      <div className="service-bg new-select-service bg-color">
        <div className="container">

          <h2 className="page-title">
            {chooseStaff && !bundle ?
              <>
                <p><span> Step 3 | </span> Select Time </p>
              </>
              :
              <>
                <p><span> Step 2 | </span> Select Time </p>
              </>
            }
            {chooseStaff && !bundle ? (
              <Link
                className="btn"
                to={{
                  pathname: `/customerappointment/selectpreference/${id}`,
                  state: {
                    servicesPanel: selectServices,
                    staffs: selectStaffs,
                    partnerTime: partnerSlot,
                    chooseStaff,
                    bundle,
                    currency,
                    storeImage,
                    paymentRequired,
                    outService,
                    eventKey,
                  },
                }}
              >
                <i className="fa fa-angle-left" />
                {' '}
                Back
              </Link>
            ) : (
              <Link
                className="btn"
                to={{
                  pathname: `/customerappointment/selectservices/${id}`,
                  state: {
                    servicesPanel: selectServices,
                    event: eventKey,
                    paymentRequired,
                    outService,
                    sameCategory: chooseStaff,
                  },
                }}
              >
                <i className="fa fa-angle-left" />
                {' '}
                Back
              </Link>
            )}

          </h2>
          <div className="select-time">
            <div className="row">

              <div className="col-md-4">
                <div className="service-card">

                  <div className="box-details">
                    <Card>
                      <Card.Img variant="top" src={storeImage ? `${server}/${storeImage}` : store2} />
                      <Card.Body>
                        <Card.Title className="heading-bold">
                          {partnerSlot.locationName}
                        </Card.Title>
                        <Card.Text className="">
                          {partnerSlot.addressFormat}
                          ,
                          {partnerSlot.country}
                        </Card.Text>

                        <div className="border-des">
                          <div className="left-scrollbar">
                            {selectServices.length > 0 ? (
                              <>
                                {selectServices.map((ele) => {
                                  priceTotal += parseInt(ele.amount);
                                  return (
                                    <div className="content-detail">
                                      <div className="product">
                                        <h6>{ele.servicename}</h6>
                                        <span>
                                          {Math.floor(parseInt(ele.duration) / 60) === 0 ? (
                                            <>
                                              {parseInt(ele.duration) % 60}
                                              {' '}
                                              min
                                            </>
                                          ) : (
                                            <>
                                              {Math.floor(parseInt(ele.duration) / 60)}
                                              h
                                              {' '}
                                              {parseInt(ele.duration) % 60 === 0 ? (
                                                ''
                                              ) : (
                                                <>
                                                  ,
                                                  {parseInt(ele.duration) % 60}
                                                  min
                                                </>
                                              )}

                                            </>
                                          )}
                                          { }
                                          {' '}
                                          {chooseStaff && !bundle ? (
                                            <>
                                              with
                                              {' '}
                                              {staffName}
                                            </>
                                          ) : (

                                            ''

                                          )}

                                        </span>
                                      </div>
                                      <div className="rate">
                                        <span>
                                          {ele.amount === '0' ? 'Free' : ele.amount}
                                          {' '}
                                          {ele.amount !== '0' ? currency : ''}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="no-service">
                                <h4>
                                  No services selected yet
                                </h4>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="total-rate">
                          <h6>Total</h6>
                          <h6>
                            {selectServices.length > 0 ? priceTotal === 0 ? 'Free' : priceTotal : 0}
                            {' '}
                            {selectServices.length > 0 ? priceTotal === 0 ? '' : currency : currency}
                          </h6>
                        </div>
                        <div className="book-now">
                          {/* <Button className="btn" variant="primary">
                            Book Now */}
                          {' '}
                          {/* <i className="fa fa-check-circle" aria-hidden="true" /> */}
                          {/* </Button> */}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="time-details ">
                  <div className="service-card">
                    {chooseStaff && !bundle ? (
                      <div className="stepwizard">
                        <ProgressBar now={75} />
                        <span className="stepwizard-steps">Step 3</span>
                      </div>
                      // <div className="stepwizard">
                      //   <ol className="stepwizard-panel">
                      //     <li className="stepwizard-step">Step 1</li>
                      //     <li className="stepwizard-step">Step 2</li>
                      //     <li className="current stepwizard-step">Step 3</li>
                      //     <li className="stepwizard-step">Ready to go!</li>
                      //   </ol>
                      // </div>
                    ) : (
                      <div className="stepwizard">
                        <ProgressBar now={75} />
                        <span className="stepwizard-steps">Step 2</span>
                      </div>
                      // <div className="stepwizard">
                      //   <ol className="stepwizard-panel">
                      //     <li className="stepwizard-step">Step 1</li>
                      //     <li className="current stepwizard-step">Step 2</li>
                      //     <li className="stepwizard-step">Ready to go!</li>
                      //   </ol>
                      // </div>
                    )}
                    <div className="heading-month">
                      {/* <h4>
                      {moment(new Date()).month(monthName).format('MMMM')}
                    </h4> */}
                      <div className="date-calendar">
                        <Slider {...settings} ref={customeSlider}>
                          {days.map((item, i) => (
                            <>
                              <div
                                role="presentation"
                                className="calendar-grid"
                                key={i}
                                onClick={() => setSlots(item.date)}
                              >
                                <h6>{item.daysName}</h6>
                                <span>{item.dayNo}</span>
                              </div>
                            </>
                          ))}
                        </Slider>
                      </div>
                      <div className="time-scrollbar">
                        {timeData.length > 0 ? (
                          <>
                            {timeData.map((el) => (
                              <Link
                                to={{
                                  pathname: `/customerappointment/payment/${id}`,
                                  state: {
                                    servicesPanel: selectServices,
                                    selectedStaff: {
                                      name: staffName,
                                      id: staffId,
                                      shiftSlot: shifts,
                                    },
                                    selectedTime: el,
                                    selectedDate: appointmentDate,
                                    staffs: selectStaffs,
                                    partnerTime: partnerSlot,
                                    chooseStaff,
                                    bundle,
                                    currency,
                                    storeImage,
                                    paymentRequired,
                                    outService,
                                    eventKey,
                                  },
                                }}
                              >
                                <div className="selet-time-box">
                                  <div className="chek-3">
                                    <h4>{el}</h4>

                                    {/* <div className="service-checkbox">
                                <Form.Check type="checkbox" label="" />
                              </div> */}
                                  </div>
                                </div>
                              </Link>
                            ))}
                          </>
                        ) : (
                          <p className="no-availability">{message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTime;
