/* eslint-disable radix */
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import store2 from '../../../assets/no-image.jpg';
import './selectservices.css';
import user from '../../../assets/partner/avatar-1.jpg';
// eslint-disable-next-line import/order
import { Link, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { server } from '../../../config/keys';
import { ProgressBar} from 'react-bootstrap';

; const SelectPreference = () => {
  const { state } = useLocation();
  
  let priceTotal = 0;
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf('/') + 1);
  const [selectServices] = useState(state.servicesPanel);
  const [selectStaffs] = useState(state.staffs);
  const [partnerSlot] = useState(state.partnerTime);
  const [chooseStaff] = useState(state.chooseStaff);
  const [currency] = useState(state.currency);
  const [storeImage] = useState(state.storeImage);
  const [paymentRequired] = useState(state.paymentRequired);
  const [outService] = useState(state.outService);
  const [eventKey] = useState(state.eventKey);


  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps " />
      </Helmet>
      <div className="service-bg new-select-service bg-color">
        <div className="container">

          <h2 className="page-title">
            <p><span> Step 2 | </span> Select Associate </p>
            {' '}
            {localStorage.getItem('Reschedule') ? (
              <Link
                className="btn"
                to={{
                  pathname: '/myappointments',
                }}
              >
                <i className="fa fa-angle-left" />
                {' '}
                Back
              </Link>
            ) : (
              <Link
                className="btn"
                to={{
                  pathname: `/customerappointment/selectservices/${id}`,
                  state: {
                    servicesPanel: selectServices,
                    paymentRequired,
                    outService,
                    sameCategory: chooseStaff,
                    event: eventKey,
                  },
                }}
              >
                <i className="fa fa-angle-left" />
                {' '}
                Back
              </Link>
            )}

          </h2>
          <div className="select-associate">
            <div className="row">

              <div className="col-md-4">
                <div className="service-card">

                  <div className="box-details">
                    <Card>
                      <Card.Img variant="top" src={storeImage ? `${server}/${storeImage}` : store2} />
                      <Card.Body>
                        <Card.Title className="heading-bold">
                          {partnerSlot.locationName}
                        </Card.Title>
                        <Card.Text className="">
                          {partnerSlot.addressFormat}
                          ,
                          {partnerSlot.country}
                        </Card.Text>

                        <div className="border-des">
                          <div className="left-scrollbar">
                            {selectServices.length > 0 ? (
                              <>
                                {selectServices.map((ele) => {
                                  priceTotal += parseInt(ele.amount);
                                  return (
                                    <div className="content-detail">
                                      <div className="product">
                                        <h6>{ele.servicename}</h6>
                                        <span>
                                          {Math.floor(parseInt(ele.duration) / 60) === 0 ? (
                                            <>
                                              {parseInt(ele.duration) % 60}
                                              {' '}
                                              min
                                            </>
                                          ) : (
                                            <>
                                              {Math.floor(parseInt(ele.duration) / 60)}
                                              h
                                              {' '}
                                              {parseInt(ele.duration) % 60 === 0 ? (
                                                ''
                                              ) : (
                                                <>
                                                  ,
                                                  {parseInt(ele.duration) % 60}
                                                  min
                                                </>
                                              )}

                                            </>
                                          )}

                                        </span>
                                      </div>
                                      <div className="rate">
                                        <span>
                                          {ele.amount === '0' ? 'Free' : ele.amount}
                                          {' '}
                                          {ele.amount !== '0' ? currency : ''}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="no-service">
                                <h4>
                                  No services selected yet
                                </h4>
                              </div>
                            )}

                          </div>
                        </div>
                        <div className="total-rate">
                          <h6>Total</h6>
                          <h6>
                            {selectServices.length > 0 ? priceTotal === 0 ? 'Free' : priceTotal : 0}
                            {' '}
                            {selectServices.length > 0 ? priceTotal === 0 ? '' : currency : currency}
                          </h6>
                        </div>

                      </Card.Body>
                    </Card>
                    <div className="book-now">
                      {/* <Button className="btn" variant="primary">
                            Book Now */}
                      {' '}
                      {/* <i className="fa fa-check-circle" aria-hidden="true" /> */}
                      {/* </Button> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="associate-details ">
                  <div className="service-card">
                    <div className="stepwizard">
                      <ProgressBar now={50} />
                      <span className="stepwizard-steps">Step 2</span>
                    </div>
                    {/* <div className="stepwizard">
                    <ol className="stepwizard-panel">
                      <li className="stepwizard-step">Step 1</li>
                      <li className="current stepwizard-step">Step 2</li>
                      <li className="stepwizard-step">Step 3</li>
                      <li className="stepwizard-step">Ready to go!</li>
                    </ol>
                  </div> */}
                    <div className="prefer-scrollbar">
                      {selectStaffs.map((el) => (
                        <div className="select-staff-table">
                          <Link
                            to={{
                              pathname: `/customerappointment/select-time/${id}`,
                              state: {
                                servicesPanel: selectServices,
                                partnerTime: partnerSlot,
                                staffs: selectStaffs,
                                selectedStaff: {
                                  name: el.firstname,
                                  id: el._id,
                                  shiftSlot: el.shift,
                                },
                                chooseStaff,
                                currency,
                                storeImage,
                                paymentRequired,
                                outService,
                                eventKey,
                              },
                            }}
                          >
                            <div className="select-staff-pic">
                              <img src={user} alt=''/>
                            </div>
                            <div className="select-staff-name">
                              <h4>{el.firstname}</h4>
                              <span>Maximum availability</span>
                            </div>
                          </Link>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPreference;
