import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, ProgressBar } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { server } from "../../config/keys";
import store2 from "../../assets/no-image.jpg";

const GiftVoucher = () => {
  const { state } = useLocation();
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [value] = useState(state.value);
  const [total] = useState(state.total);
  const [voucherId] = useState(state.voucherId);
  const [locationName] = useState(state.locationName);
  const [currency] = useState(state.currency);
  const [country] = useState(state.country);
  const [storeImage] = useState(state.storeImage);
  const [companyName] = useState(state.companyName);
  const [servicessLength] = useState(state.servicessLength);
  const [retailPrice] = useState(state.retailPrice);
  const [voucherValue] = useState(state.voucherValue);
  const [rt] = useState(state.rt);
  const [quantity] = useState(state.quantity);

  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps" />
      </Helmet>
      <div className="voucher-detail new-voucher-detail">
        <div className="service-bg new-select-service bg-color">
          <div className="container">
            <h2 className="page-title">
              <p>
                <span>Step 2 | </span> Choose Recipient
              </p>
              <Link
                className="btn"
                to={{
                  pathname: `/voucherdetail/${id}`,
                  state: {
                    voucher: {
                      voucherValue,
                      retailPrice: value,
                      _id: voucherId,
                    },
                    currencyy: currency,
                    locName: locationName,
                    countryy: country,
                    storeImage,
                    company: companyName,
                    partnerServices: servicessLength,
                    quantity,
                    rt,
                  },
                }}
              >
                <i className="fa fa-angle-left" /> Back
              </Link>
            </h2>
            <div className="select-serve">
              <div className="store-services ">
                <div className="row">
                  <div className="col-md-4">
                    <div className="service-card">
                      <div className="box-details">
                        <Card>
                          <Card.Img
                            variant="top"
                            src={
                              storeImage ? `${server}/${storeImage}` : store2
                            }
                          />
                          <Card.Body>
                            <Card.Title className="heading-bold">
                              {companyName}
                            </Card.Title>
                            <Card.Text className="">
                              {locationName},{country}
                            </Card.Text>
                            <div className="border-des">
                              <div className="left-scrollbar">
                                <div className="content-detail">
                                  <div className="product">
                                    <h6>Referral Voucher</h6>
                                    <span>{servicessLength} services</span>
                                  </div>
                                  <div className="rate">
                                    <span>
                                      {currency} {value}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="total-rate">
                              <h6>Total</h6>
                              <h6>
                                {currency} {total}
                              </h6>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="service-card">
                      <div className="stepwizard">
                        <ProgressBar now={50} />
                        <span className="stepwizard-steps">Step 2</span>
                      </div>
                      {/* <div className="stepwizard">
                        <ol className="stepwizard-panel">
                          <li className="stepwizard-step">Step 1</li>
                          <li className="current stepwizard-step">Step 2</li>
                          <li className="stepwizard-step">Ready to go!</li>
                        </ol>
                      </div> */}
                      <div className="voucher-gift">
                        <div className="select-staff-table vocuher-table">
                          <Link
                            to={{
                              pathname: `/paymentVoucher/${id}`,
                              state: {
                                value,
                                total,
                                currency,
                                locationName,
                                country,
                                storeImage,
                                companyName,
                                voucherId,
                                giftType: "treat",
                                servicessLength,
                                retailPrice,
                                quantity,
                                voucherValue,
                                rt,
                              },
                            }}
                          >
                            <div className="voucher-icon">
                              <div className="select-staff-pic">
                                <img src={store2} alt="voucher" />
                              </div>
                              <div className="select-staff-name">
                                <h4>A Treat for me</h4>
                                {/* <span>Maximum availability</span> */}
                              </div>
                            </div>
                            <div className="voucher-icon text-right">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </div>
                          </Link>
                        </div>

                        <div className="select-staff-table vocuher-table">
                          <Link
                            to={{
                              pathname: `/printGift/${id}`,
                              state: {
                                value,
                                total,
                                currency,
                                locationName,
                                country,
                                storeImage,
                                companyName,
                                voucherId,
                                firstname: null,
                                email: null,
                                lastname: null,
                                message: null,
                                giftType: "print",
                                servicessLength,
                                retailPrice,
                                quantity,
                                voucherValue,
                                rt,
                              },
                            }}
                          >
                            <div className="voucher-icon">
                              <div className="select-staff-pic">
                                <img src={store2} alt="voucher" />
                              </div>
                              <div className="select-staff-name">
                                <h4>Print as a gift</h4>
                                {/* <span>Maximum availability</span> */}
                              </div>
                            </div>
                            <div className="voucher-icon text-right">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </div>
                          </Link>
                        </div>

                        <div className="select-staff-table vocuher-table">
                          <Link
                            to={{
                              pathname: `/emailFrom/${id}`,
                              state: {
                                value,
                                total,
                                currency,
                                locationName,
                                country,
                                storeImage,
                                companyName,
                                voucherId,
                                firstname: null,
                                email: null,
                                lastname: null,
                                message: null,
                                giftType: "email",
                                servicessLength,
                                retailPrice,
                                quantity,
                                voucherValue,
                                rt,
                              },
                            }}
                          >
                            <div className="voucher-icon">
                              <div className="select-staff-pic">
                                <img src={store2} alt="voucher" />
                              </div>
                              <div className="select-staff-name">
                                <h4>email as a Gift</h4>
                                {/* <span>Maximum availability</span> */}
                              </div>
                            </div>
                            <div className="voucher-icon text-right">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GiftVoucher;
