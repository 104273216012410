import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import PartnerWrapper from '../partnerwrapper/wrapper';
import history from '../../../config/history';
import PartnerContext from '../../../context/context';
import Loader from '../../loader/loader';
import './style.css';
import cross from '../../../assets/cross2.png';

const SetUp = () => {
  const { username } = useContext(PartnerContext);
  const [loading, setLoading] = useState(false);
  const { sections } = username;
  const titleRef = useRef(sections);

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook = titleRef.current
        && titleRef.current.findIndex(
          (boo) => boo.sectionname === 'Account Setup',
        );
      if (findBook > -1) {
        setLoading(false);
      } else {
        history.push('/auth');
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  return (
    <PartnerWrapper>
      {loading && <Loader />}
      <div className="setup partner-setup">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form">
                <div className="cross-icon">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3>Configuration</h3>
                  </div>
                  <div className="signup-body">
                    {/* <div className="row">
                      <div className="col-md-12"> */}
                    <div className="account-card">
                      <Card>
                        <Card.Body>
                          <Card.Text>
                            <Link
                              className="text-Primary"
                              to="/partners/accountsetting"
                            >
                              Business Partner Setup
                              {' '}
                            </Link>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-md-12"> */}
                    <div className="account-card">
                      <div className="signup-head">
                        <h3>Billing Configuration</h3>
                      </div>
                      <Card>
                        <Card.Body>
                          <Link
                            className="text-Primary "
                            to="/partners/invoice"
                          >
                            Invoice
                            {' '}
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Link className="text-Primary " to="/partners/taxes">
                            Taxes
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Link
                            className="text-Primary "
                            to="/partners/payment-taxes"
                          >
                            Payment Types
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Link
                            className="text-Primary "
                            to="/partners/cancel-reason"
                          >
                            Cancellation Reasons
                          </Link>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Body>
                          <Link
                            className="text-Primary "
                            to="/partners/discount-type"
                          >
                            Discount
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card>
                        <Card.Body>
                          <Link
                            className="text-Primary "
                            to="/partners/referral"
                          >
                            Referral
                          </Link>
                        </Card.Body>
                      </Card>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
                {/* <div className="signup-right">
              <div className="signup-footer-btn">
                <Button typr="submit">Next</Button>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default SetUp;
