import React, {
  useState, useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PartnerWrapper from '../../partnerwrapper/wrapper';
import { partnerInstance } from '../../../../config/axios';
import Loader from '../../../loader/loader';

const CheckAvailablity = () => {
  const [ID, setID] = useState('');
  const [uploadPhotos, setUploadPhotos] = useState(false);
  const [profileDetails, setProfileDetails] = useState(false);
  const [referel, setReferel] = useState(false);
  const [tax, setTax] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [payment, setPayment] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const getLocations = async () => {
    setLoading(true);
    const locat = await partnerInstance().get('/getPartnerLocations');
  
    const { code, partnerData } = locat.data;
    if (code === 200) {
      setID(partnerData.partnerLocation[0]._id);
      if (partnerData.partnerLocation[0].images.length > 0 && partnerData.photo) {
        setUploadPhotos(true);
      }
      if (partnerData.partnerLocation[0].lat) {
        setProfileDetails(true);
      }
      if (partnerData.referral.length > 0) {
        setReferel(true);
      }
      if (partnerData.tax.length > 0) {
        setTax(true);
      }
      if (partnerData.discount.length > 0) {
        setDiscount(true);
      }

      if (partnerData.cancellationReason.length > 0) {
        setCancel(true);
      }
      if (partnerData.paymentType.length > 0) {
        setPayment(true);
      }
      if (partnerData.invoice) {
        setInvoice(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  // const calculatePercentage = () => {
  //   const one = uploadPhotos ? 11.1111111111 : 0;
  //   const two = profileDetails ? 11.1111111111 : 0;
  //   const three = referel ? 11.1111111111 : 0;
  //   const four = tax ? 11.1111111111 : 0;
  //   const five = discount ? 11.1111111111 : 0;
  //   const six = cancel ? 11.1111111111 : 0;
  //   const seven = payment ? 11.1111111111 : 0;
  //   const eight = invoice ? 11.1111111111 : 0;
  //   const nine = 11.1111111111;

  //   return one + two + three + four + five + six + seven + eight + nine;
  // };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }
  console.log(uploadPhotos)
  console.log(discount)
  return (
    <PartnerWrapper>
      <div className="payment-stripe-container mb-5">
        <div className="container-fluid">
          <div className="check-option">
            <h1>
              {(profileDetails && referel && cancel && payment && tax && invoice) ? 'Your Information is updated click to continue'
                : 'Please Complete these steps for online booking'}
            </h1>
          </div>
          <div className="row">
            <Link
              to={{
                pathname: `/partners/editlocation/${ID}`,
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={profileDetails ? 100 : 0} text={profileDetails ? 100 : '0'} />
                <h6>Profile Details</h6>
              </div>
            </Link>
            <Link
              to={{
                pathname: `/partners/editlocation/${ID}`,
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={100} text={100} />
                <h6>Availability</h6>
              </div>
            </Link>
            {/* <Link
              to={{
                pathname: `/partners/editlocation/${ID}`,
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={uploadPhotos ? 100 : 0} text={uploadPhotos ? 100 : '0'} />
                <h6>Upload Photos</h6>
              </div>
            </Link> */}
            <Link
              to={{
                pathname: '/partners/invoice',
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={invoice ? 100 : 0} text={invoice ? 100 : '0'} />
                <h6>Setup Invoice</h6>
              </div>
            </Link>
            <Link
              to={{
                pathname: '/partners/taxes',
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={tax ? 100 : 0} text={tax ? 100 : '0'} />
                <h6>Setup Tax</h6>
              </div>
            </Link>
            {/* <Link
              to={{
                pathname: '/partners/discount-type',
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={discount ? 100 : 0} text={discount ? 100 : '0'} />
                <h6>Setup Discount</h6>
              </div>
            </Link> */}
            <Link
              to={{
                pathname: '/partners/payment-taxes',
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={payment ? 100 : 0} text={payment ? 100 : '0'} />
                <h6>Setup Payment</h6>
              </div>
            </Link>
            <Link
              to={{
                pathname: '/partners/cancel-reason',
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={cancel ? 100 : 0} text={cancel ? 100 : '0'} />
                <h6>Setup Cancellation Reasons</h6>
              </div>
            </Link>
            <Link
              to={{
                pathname: '/partners/referral',
                info: {
                  online: true,
                },
              }}
              className="col-md-3"
            >
              <div className="details-box">
                <CircularProgressbar value={referel ? 100 : 0} text={referel ? 100 : '0'} />
                <h6>Setup Referreal</h6>
              </div>
            </Link>
          </div>
        </div>
        <div>
          {(profileDetails && referel && cancel && payment && tax && invoice) ? (
            <div className="step-btn ">
              <Link
                to="/partners/onlinelisting"
                className="back-btn"
              >
                Continue
              </Link>

            </div>
          ) : (
            <div className="step-btn ">
              <Button className="back-btn disabled">
                Continue
              </Button>
            </div>
          )}
        </div>
      </div>
    </PartnerWrapper>
  );
};
export default CheckAvailablity;
