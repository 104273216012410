import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Button, Form } from "react-bootstrap";
//import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  //CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
//import { stripeKey } from '../../../config/keys';
import { partnerInstance } from "../../../config/axios";
import PartnerContext from "../../../context/context";
import { checkKeys } from "../../../function/validate";
import history from "../../../config/history";
import Loader from "../../loader/loader";

//const stripePromise = loadStripe(stripeKey);
const OnlineListing = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [loading, setLoading] = useState(false);
  const [publish, setPublish] = useState(false);

  const AccountHolder = useCallback(async () => {
    setLoading(true);
    const resData = await partnerInstance().get("/getPartnerAccount");

    if (resData.data.code === 200) {
      setPublish(resData.data.partnerData.publish);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Billing and card processing"
        );
      if (findBook > -1) {
        AccountHolder();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper>
      <div className="w-100">
        <Elements stripe={checkKeys()}>
          <CheckOutForm
            publish={publish}
            setLoading={setLoading}
            AccountHolder={AccountHolder}
          />
        </Elements>
      </div>
    </PartnerWrapper>
  );
};

export default OnlineListing;

const CheckOutForm = ({ publish, setLoading, AccountHolder }) => {
  const { state } = useLocation();
  const [name, setName] = useState("");
  const [accountType] = useState(state.accountType);
  const [firstname] = useState(state.firstname);
  const [lastname] = useState(state.lastname);
  const [address] = useState(state.address);
  const [city] = useState(state.city);
  const [State] = useState(state.State);
  const [postalCode] = useState(state.postalCode);
  const [taxNo] = useState(state.taxNo);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(["", "", ""]);
  const [error2, setError2] = useState("");
  const [errorName, seterrorName] = useState(false);
  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    showIcon: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = (event, i) => {
    const error1 = [...error];

    if (event.error) {
      error1[i] = event.error.message;
      setError(error1);
      if (i === 1) {
        setError2(event.error.message);
      }
    } else {
      error1[i] = "";
      setError(error1);
      if (i === 1) {
        setError2("");
      }
    }
  };
  const handleCardName = (e) => {
    seterrorName(false);
    setName(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardNumberElement);
    // console.log(error)

    const result = await stripe.createToken(card, { name: name });
    // console.log(error)
    //console.log('This is the result',result)
    if (name.trim().length === 0) {
      seterrorName(true);
    }
    // console.log('Is the control reaching here');
    if (!result.error && name.trim().length !== 0) {
      setError(["", "", ""]);
      setLoading(true);
      const payload = {
        stripeToken: result.token.id,
        accountType,
        firstname,
        lastname,
        address,
        city,
        State,
        postalCode,
        taxNo,
      };
      console.log("Is the control here");
      const response = await partnerInstance().post("/connectOnline", payload);
      const { code, msg } = response.data;
      if (code === 200) {
        setLoading(false);
        toast.success(msg, { containerId: "B" });
        history.push("/partners/onlinelisting");
        AccountHolder();
      } else {
        setLoading(false);
        toast.error(msg, { containerId: "B" });
      }
    }
  };

  //   const IBAN_STYLE = {
  //     base: {
  //       color: '#32325d',
  //       fontSize: '16px',
  //       '::placeholder': {
  //         color: '#aab7c4'
  //       },
  //       ':-webkit-autofill': {
  //         color: '#32325d',
  //       },
  //     },
  //     invalid: {
  //       color: '#fa755a',
  //       iconColor: '#fa755a',
  //       ':-webkit-autofill': {
  //         color: '#fa755a',
  //       },
  //     }
  //   };
  //   const IBAN_ELEMENT_OPTIONS = {
  //     supportedCountries: ['SEPA'],
  //     // Elements can use a placeholder as an example IBAN that reflects
  //     // the IBAN format of your customer's country. If you know your
  //     // customer's country, we recommend that you pass it to the Element as the
  //     // placeholderCountry.
  //     placeholderCountry: 'DE',
  //     style: IBAN_STYLE,
  //   };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="payment-stripe-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <PricingPackage />
              <Details /> */}

                <div className="card-price-details pricing-section">
                  <h1>Step 3 of 3: Card Details</h1>
                  <p>
                    Your card is only used to top up your account if your
                    balance is become negative
                  </p>
                  <div className="row">
                    {!publish ? (
                      <div className="payment-stripe">
                        <div className="col-md-12">
                          {/* <CardElement
                            id="card-element"
                            class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={handleChange}
                          /> */}
                          <Form.Group>
                            <Form.Label>Card holder name</Form.Label>

                            <Form.Control
                              value={name}
                              onChange={(e) => handleCardName(e)}
                              className={`${errorName ? "error-box" : " "}`}
                            />
                            {errorName && (
                              <p className="error">Enter Card Holder Name</p>
                            )}
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Card number</Form.Label>
                            <CardNumberElement
                              id="card-element"
                              class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                              options={CARD_ELEMENT_OPTIONS}
                              onChange={(e) => handleChange(e, 0)}
                            />
                            <p className="error">{error[0]}</p>
                          </Form.Group>
                          <div className="stripe-box">
                            <Form.Group>
                              <Form.Label>Expiry date</Form.Label>
                              <CardExpiryElement
                                id="card-element"
                                class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={(e) => handleChange(e, 1)}
                              />
                              <p className="error">{error2}</p>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>CVV</Form.Label>
                              <CardCvcElement
                                id="card-element"
                                class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={(e) => handleChange(e, 2)}
                              />
                              <p className="error">{error[2]}</p>
                            </Form.Group>
                          </div>

                          {/* <div className="save-card-btn">
                            <Button type="submit" className="btn-perple">
                              Save Your Card
                            </Button>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="payment-stripe">
                        <div className="col-md-12">
                          <Button className="stripe-btn btn-perple">
                            Your Card Is Saved
                          </Button>
                          {/* <IbanElement
                id="card-element"
                class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                options={IBAN_ELEMENT_OPTIONS}
                onChange={handleChange}
              /> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <CheckAvailablity /> */}
                <div className="step-btn">
                  <Link
                    className="btn btn-primary btn-perple"
                    to={{
                      pathname: "/partners/details",
                      state: {
                        accountType,
                        firstname,
                        lastname,
                        address,
                        city,
                        State,
                        postalCode,
                        taxNo,
                      },
                    }}
                  >
                    Back
                  </Link>
                  <div className="save-card-btn">
                    <Button type="submit" className="btn-perple">
                      Save Your Card
                    </Button>
                  </div>

                  {/* {publish && (
                  <div className="step-btn">
                    <Link to="/partners/availablity" className="continue-btn">Continue</Link>
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
