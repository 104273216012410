import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Button, Form } from "react-bootstrap";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { partnerInstance } from "../../../config/axios";
import { checkKeys } from "../../../function/validate";
import PartnerContext from "../../../context/context";
import history from "../../../config/history";
import Loader from "../../../components/loader/loader";
const OnlinePayment = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [accountId, setAccountId] = useState(null);
  const AccountHolder = useCallback(async () => {
    const resData = await partnerInstance().get("/getStripeAccountStatus");

    if (resData.data.code === 200) {
      setAccountId(resData.data.status);
      //setLoading(false);
    }
  }, []);

  useEffect(() => {
    //setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Online Booking"
        );
      if (findBook > -1) {
        AccountHolder();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  return (
    <div className="w-100">
      <Elements stripe={checkKeys()}>
        <CheckOutForm
          accountId={accountId}
          //setLoading={setLoading}
        />
      </Elements>
    </div>
  );
};

export default OnlinePayment;

const CheckOutForm = ({ accountId }) => {
  const stripe = useStripe();
  // const elements = useElements();
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [rountingNo, setRoutingNo] = useState("11000-000");
  const [accountNo, setAccountNo] = useState("");
  const [loader, setLoader] = useState();
  const [editForm, setEditFrom] = useState(false);

  const validateEm = (values) => {
    const errors = {};
    let isValid = true;
    if (!values.name) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!values.rountingNo) {
      errors.rountingNo = "Routing Number is required";
      isValid = false;
    }
    if (!values.accountNo) {
      errors.accountNo = "Account Number is required";
      isValid = false;
    }

    return { isValid, errors };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (loader === true) {
      return <Loader />;
    }
    const validation = validateEm({
      name,
      rountingNo,
      accountNo,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      setLoader(false);
      return;
    }
    const result = await stripe.createToken("bank_account", {
      country: "CA",
      currency: "CAD",
      routing_number: rountingNo,
      account_number: accountNo,
      account_holder_name: name,
      account_holder_type: "individual",
    });

    // const card = elements.getElement(CardElement);
    // const result = await stripe.createToken(card);

    if (result.error) {
      setError(result.error.message);
      setLoader(false);
    } else {
      setError(null);
      const payload = {
        stripeToken: result.token.id,
      };

      const response = await partnerInstance().post(
        "/connecttostripe",
        payload
      );
      setLoader(false);
      const { code, msg } = response.data;
      if (code === 200) {
        window.location.href = `${response.data.link}`;
      } else {
        toast.error(msg, { containerId: "B" });
        setLoader(false);
      }
    }
  };
  if (loader === false) {
    return (
      <div className="verify-container">
        <Loader />
      </div>
    );
  }

  const send = () => {
    history.push("/partners/dashboard");
  };
  const handelUpdate = async (event) => {
    event.preventDefault();

    setLoader(true);
    const validation = validateEm({
      name,
      rountingNo,
      accountNo,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    if (loader === true) {
      return <Loader />;
    }
    const result = await stripe.createToken("bank_account", {
      country: "CA",
      currency: "CAD",
      routing_number: rountingNo,
      account_number: accountNo,
      account_holder_name: name,
      account_holder_type: "individual",
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      setError(null);
      //setLoading(true);
      const payload = {
        stripeToken: result.token.id,
      };
      console.log("Payload::", payload);
      const response = await partnerInstance().post(
        "/connecttostripeupdate",
        payload
      );
      setLoader(false);
      const { code, msg } = response.data;
      if (code === 200) {
        //setLoading(false);

        window.location.href = `${response.data.link}`;
      } else {
        //setLoading(false);
        toast.error(msg, { containerId: "B" });
      }
    }
  };
  return (
    <div className="verify-container">
      {/* <div className="container">
        <div className="row"> */}
      {accountId === null ? (
        <Form onSubmit={handleSubmit}>
          {/* <div className="w-100"> */}
          <div className="verify-identity signup-verify-identity">
            <h5>Please Add your Account....</h5>
            <Form.Group controlId="formBasicInput">
              <Form.Control
                type="text"
                placeholder="Enter Account Holder Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </Form.Group>

            <Form.Group controlId="formBasicInput">
              <Form.Control
                type="text"
                placeholder="Enter Routing Number"
                value={rountingNo}
                onChange={(e) => setRoutingNo(e.target.value)}
              />
              {errors.rountingNo && (
                <p className="error">{errors.rountingNo}</p>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicInput">
              <Form.Control
                type="number"
                placeholder="Enter Account Number"
                value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
              />
              {errors.accountNo && <p className="error">{errors.accountNo}</p>}
            </Form.Group>
            {/* <CardElement

                  id="card-element"
                  class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={handleChange}
                /> */}

            {error}
          </div>
          <div className="signup-footer-btn verify-btn">
            <Button
              className="btn-perple"
              onClick={() => history.push("/partners/online-payment")}
            >
              Back
            </Button>
            <Button type="submit" className="btn-perple">
              Add Account
            </Button>
          </div>
          {/* </div> */}
        </Form>
      ) : (
        <div className="col-md-12">
          <div className="verify-identity">
            <div className="verify-btn text-center mt-0">
              <Button className="identity-varify btn-perple" onClick={send}>
                Your Identity is Verified
              </Button>
            </div>
            <br />
            <div className="verify-btn text-center mt-0">
              <Button
                className="identity-varify btn-perple"
                onClick={() => setEditFrom(true)}
              >
                Edit your account details
              </Button>
            </div>
          </div>
        </div>
      )}
      {editForm ? (
        <Form onSubmit={handelUpdate}>
          <div className="w-100">
            <div className="verify-identity signup-verify-identity">
              <h5>update your account details</h5>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  type="text"
                  placeholder="Enter Account Holder Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </Form.Group>

              <Form.Group controlId="formBasicInput">
                <Form.Control
                  type="text"
                  placeholder="Enter Routing Number"
                  value={rountingNo}
                  onChange={(e) => setRoutingNo(e.target.value)}
                />
                {errors.rountingNo && (
                  <p className="error">{errors.rountingNo}</p>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  type="number"
                  placeholder="Enter Account Number"
                  value={accountNo}
                  onChange={(e) => setAccountNo(e.target.value)}
                />
                {errors.accountNo && (
                  <p className="error">{errors.accountNo}</p>
                )}
              </Form.Group>
              {/* <CardElement
  
                    id="card-element"
                    class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleChange}
                  /> */}

              {error}
            </div>
            <div className="verify-btn">
              <Button type="submit" className="btn-perple">
                Update Account
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        ""
      )}
    </div>
  );
};
//export default CheckOutForm;
