/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { confirmAlert } from "react-confirm-alert";
import PartnerWrapper from "../partnerwrapper/wrapper";
import "./voucher.css";
import Discount from "../../../assets/discount.svg";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";

const PartnerVoucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  // eslint-disable-next-line
  const [voucherId, setVoucherId] = useState("");
  const [services, setServices] = useState(0);
  const { username } = useContext(PartnerContext);
  const [loading, setLoading] = useState(false);
  const { currency, sections } = username;
  const titleRef = useRef(sections);

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Display Voucher List"
        );
      if (findBook > -1) {
        getVoucher();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  // get voucher list
  const getVoucher = async () => {
    setLoading(true);
    const voucher = await partnerInstance().get("/getVouchers");
    const { code, voucherData, servicelist } = voucher.data;
    if (code === 200) {
      setVouchers(voucherData);
      setServices(servicelist.length);
      setLoading(false);
    }
  };
  const handleShowDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this voucher ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const payloadd = {
              deleteId: id,
            };
            const deleteVoucher = await partnerInstance().post(
              "/deleteVoucher/",
              payloadd
            );
            const { code, msg } = deleteVoucher.data;
            if (code === 200) {
              getVoucher();
              toast.success(msg, { containerId: "B" });
            } else if (code === 409) {
              toast.error(msg, { containerId: "B" });
            } else {
              toast.error(msg, { containerId: "B" });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  // Close edit modal
  const handleHide = () => {
    setShowDelete(false);
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Vouchers">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="setup voucher">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-right ">
              <h2 className="admin-title">
                {/* Voucher */}
                <Link className="add-voucher-btn" to="/partners/addvoucher">
                  Add voucher type
                </Link>
              </h2>
              </div>
              {vouchers.length > 0 ? (
                <>
                  <div className="voucher-listing">
                    {vouchers.map((el, i) => (
                      <div className="voucher-box">
                        <Link>
                          <div className="service-card">
                            <div className="service-body">
                              <div className="vouchername">
                                <h4>Voucher Name</h4>
                                <h3> {el.voucherName}</h3>
                              </div>
                              <div className="retail">
                                <div className="vouchervalue">
                                  <h4>Voucher Value </h4>
                                  <h3>
                                    {currency} {el.voucherValue}
                                  </h3>
                                </div>
                                <div className="vouchervalue">
                                  <h4>Retail Price</h4>
                                  <h3>
                                    {currency} {el.retailPrice}
                                  </h3>
                                </div>
                              </div>
                              <div className="vouchername disc">
                                <h4>Voucher Description</h4>
                                <p style={{ minHeight: "21px" }}>
                                  {el.voucherdescription
                                    ? el.voucherdescription
                                    : "No Description"}
                                </p>
                              </div>
                              <div className="vouchername">
                                <p style={{ minHeight: "21px" }}>
                                  {services > 0 && (
                                    <>
                                      {services === el.serviceid.length ? (
                                        <p> Redeem in All Services </p>
                                      ) : (
                                        <p>
                                          Redeem in {el.serviceid.length}{" "}
                                          Services
                                        </p>
                                      )}
                                    </>
                                  )}
                                </p>
                              </div>
                              <div className="vouchername">
                                <h4>Sold</h4>
                                <p style={{ minHeight: "21px" }}>
                                  {el.voucherBuyByCustomer.length}/
                                  {el.noofsales}
                                </p>
                              </div>
                              <div className="voucherbtn">
                                <Link
                                  className={
                                    el.voucherBuyByCustomer.length !==
                                    +el.noofsales
                                      ? "add-voucher-btn voucher-edit-btn"
                                      : "voucher-disable"
                                  }
                                  to={`/partners/editvoucher/?id=${el._id}`}
                                >
                                  <span>Edit</span>
                                  {/* <i className="fa fa-pencil-square-o" /> */}
                                </Link>
                                <Link
                                  className={
                                    el.voucherBuyByCustomer.length !==
                                    +el.noofsales
                                      ? "add-voucher-btn"
                                      : "voucher-disable"
                                  }
                                  to={`/partners/vouchercheckout/?voucherId=${el._id}`}
                                >
                                  <span>Sell Voucher</span>
                                </Link>

                                <Button
                                  className="btn-perple"
                                  onClick={() => handleShowDelete(el._id)}
                                >
                                  Delete
                                  {/* <i className="fa fa-trash" /> */}{" "}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <Card>
                  <Card.Img variant="top" />
                  <Card.Body>
                    <Card.Title />
                    <Card.Text>
                      <div className="tax-head">
                        <img src={Discount} className="mb-2" alt="Discount" />
                        <h5>Add a Voucher Type</h5>
                        <p className="text-muted ">
                          You have no active voucher types.
                        </p>
                        <Link
                          className="add-voucher-btn"
                          to="/partners/addvoucher"
                        >
                          Add voucher type
                        </Link>
                      </div>
                      {/* <div>
                        <h1 />
                      </div> */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            DELETE VOUCHER
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeleteVoucher
            voucherId={voucherId}
            setShowDelete={setShowDelete}
            getVoucher={() => getVoucher()}
          />
        </Modal.Body>
      </Modal>
    </PartnerWrapper>
  );
};

export default PartnerVoucher;

function DeleteVoucher({ voucherId, getVoucher, setShowDelete }) {
  const deleteIds = async (e) => {
    e.preventDefault();
    const payloadd = {
      deleteId: voucherId,
    };

    const deleteVoucher = await partnerInstance().post(
      "/deleteVoucher/",
      payloadd
    );

    const { code, msg } = deleteVoucher.data;
    if (code === 200) {
      setShowDelete(false);
      getVoucher();
      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={deleteIds}>
      <div className="voucher-delete">
        <p>Are You sure you want to delete ?</p>
        <Button variant="primary" type="submit">
          Delete
        </Button>
      </div>
    </Form>
  );
}
