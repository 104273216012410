import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Tab, Dropdown, Form, Modal } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import queryString from "query-string";
import { toast } from "react-toastify";
import PartnerWrapper from "../partnerwrapper/wrapper";
import user from "../../../assets/user.jpg";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import "./customer.css";
import Loader from "../../loader/loader";

const CustomerProfile = () => {
  const { username } = useContext(PartnerContext);
  const { currency, sections } = username;
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [customers, setCustomers] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [ID, setID] = useState("");
  const [isBlock, setIsBlock] = useState();
  const [appointments, setAppointments] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [allBookings, setAllBookings] = useState(0);
  const [sales, setSales] = useState(0);
  const [completed, setcompleted] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [loading, setLoading] = useState(false);
  const [contactPerInfo, setContactPerInfo] = useState(false);

  useEffect(() => {
    const setSta =
      sections &&
      sections.findIndex(
        (el) => el.sectionname === "Can see client contact info"
      );
    if (setSta > -1) {
      setContactPerInfo(true);
    } else {
      setContactPerInfo(false);
    }
    const value = queryString.parse(window.location.search);
    const { customerid } = value;
    setID(customerid);

    const getCustomer = async () => {
      setLoading(true);
      const payload = {
        customerid,
      };
      const data = await partnerInstance().post("/getCustomer", payload);

      const { code, customerData } = data.data;
      if (code === 200) {
        const arr = [];
        const inv = [];
        let totalSales = 0;
        const filterCancel = customerData.appointmentId.filter(
          (ele) => ele.cancelStatus
        );
        setEmail(customerData.email);
        setFirstName(customerData.firstname);
        setLastName(customerData.lastname);
        setIsBlock(customerData.isBlock);
        setAllBookings(customerData.appointmentId.length - filterCancel.length);
        setCancelled(filterCancel.length);
        customerData.vouchers.forEach((ele) => {
          inv.push({
            status: "Completed",
            no: ele.invoiceId.invoiceno,
            date: new Date(ele.invoiceId.createdAt).toLocaleDateString(),
            location: ele.partnerId.partnerLocation[0].locationName,
            amount: ele.price,
            voucherID: ele._id,
            appID: null,
            refund: ele.invoiceId.refund,
            cancelStatus: ele.invoiceId.cancelStatus,
          });
        });
        customerData.appointmentId.forEach((el) => {
          el.appointmentPanel.forEach((item) => {
            arr.push({
              service: item.serviceId.serviceName,
              date: el.appointmentDate,
              duration: item.duration,
              staff: item.staffId.firstname,
              price: item.serviceId.priceFrom,
            });
            if (el.invoiceId) {
              inv.push({
                status: "Completed",
                no: el.invoiceId.invoiceno,
                date: new Date(el.invoiceId.createdAt).toLocaleDateString(),
                location: el.partnerId.partnerLocation[0].locationName,
                amount: el.totalAmount,
                appID: el._id,
                voucherID: null,
                refund: el.invoiceId.refund,
                cancelStatus: el.invoiceId.cancelStatus,
              });
              setSales((totalSales += +el.totalAmount));
              setcompleted(inv.length);
            }
          });
        });
        setAppointments(arr);
        setInvoices(inv);
        setLoading(false);
      }
    };

    getCustomer();
    // eslint-disable-next-line
  }, []);

  const handleShowDelete = (id) => {
    setCustomerId(id);
    setShowDelete(true);
  };
  const handleHide = () => {
    setShowDelete(false);
  };
  const handleBlock = async (id) => {
    const response = await partnerInstance().put(`/blockCustomer/${id}`);
    const {
      data: { code, updatedData },
    } = response;
    if (code === 200) {
      //   const oldData = [...data];
      //   const foundIndex = oldData.findIndex((x) => x._id === id);
      //   oldData[foundIndex].isBlock = !!isBlock;
      setIsBlock(updatedData.isBlock);
      //   setData(oldData);
    }
  };

  const Back = (e) => {
    e.preventDefault();
    history.push(`/partners/calendar/?customerid=${ID}`);
  };

  return (
    <PartnerWrapper heading="Customer Profile">
      {loading && <Loader />}
      <div className="staff-page customer-profile">
        <div className="container">
          {/* <h2 className="admin-title">Customer Profile</h2> */}
          <div className="customer-upper">
            {contactPerInfo && (
              <Dropdown className="appointment-action">
                <Dropdown.Toggle id="dropdown-basic">
                  <i className="fa fa-ellipsis-h" aria-hidden="true" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link
                      to={`/partners/editcustomer?customerid=${ID}&view=${14587}`}
                    >
                      Edit
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link onClick={() => handleShowDelete(ID)} variant="danger">
                      Delete
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <div onClick={() => handleBlock(ID)}>
                      {isBlock ? "Unblock" : "Block"}
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            <div className="custommer-info">
              <div className="customer-pic">
                <img src={user} alt="user" />
              </div>
              <h3>
                {firstName} {lastName}
              </h3>
              <span>{email}</span>{" "}
              <Link className="btn btn-primary btn-perple" onClick={Back}>
                New Appointment
              </Link>
            </div>

            <div className="customer-activity">
              <div className="activity-content">
                <div className="activity-box">
                  <h5>
                    {currency} {sales}
                  </h5>
                  <span>Total Sales</span>
                </div>

                <div className="activity-box">
                  <h5>{currency} 0</h5>
                  <span>Outstanding</span>
                </div>

                <div className="activity-box">
                  <h5>{allBookings}</h5>
                  <span>All Booking</span>
                </div>

                <div className="activity-box">
                  <h5>{completed}</h5>
                  <span>Completed</span>
                </div>

                <div className="activity-box">
                  <h5>{cancelled}</h5>
                  <span>Cancelled</span>
                </div>

                <div className="activity-box">
                  <h5>0</h5>
                  <span>No Show</span>
                </div>
              </div>
            </div>
          </div>

          <div className="customer-bottom">
            <Tabs defaultActiveKey="0" id="uncontrolled-tab-example-3">
              <Tab eventKey="0" title="Appointments">
                <CustomerAppointments app={appointments} currency={currency} />
              </Tab>
              <Tab eventKey="1" title="Invoices">
                <CustomerInvoices inv={invoices} currency={currency} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Delete Customer
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeleteCustomer
            customerId={customerId}
            setShowDelete={setShowDelete}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </PartnerWrapper>
  );
};
export default CustomerProfile;

const CustomerAppointments = ({ app, currency }) => (
  <div className="customer-appointment">
    <div className="partner-table">
      {app.length > 0 ? (
        <>
          <Table responsive="lg">
            <thead>
              <tr>
                <th className="text-left">Appointment Date</th>
                <th className="text-left">Service/Duration</th>
                <th className="text-left">Price</th>
              </tr>
            </thead>
            <tbody>
              {app.map((el) => (
                <tr>
                  <td className="text-left">{el.date}</td>
                  <td className="text-left">
                    <h4>{el.service}</h4>
                    <span>
                      {Math.floor(parseInt(el.duration) / 60)}h{" "}
                      {parseInt(el.duration) % 60 === 0 ? (
                        ""
                      ) : (
                        <>
                          ,{parseInt(el.duration) % 60}
                          min
                        </>
                      )}{" "}
                      with {el.staff}
                    </span>
                  </td>
                  <td className="text-left">
                    {currency} {el.price}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* <div className="appoint-box-time">
                <span>{el.date}</span>
              </div>
              <div className="appoint-box-name">
                <h4>{el.service}</h4>
                <span>
                  {Math.floor(parseInt(el.duration) / 60)}
                  h
                  {' '}
                  {parseInt(el.duration) % 60 === 0 ? (
                    ''
                  ) : (
                    <>
                      ,
                      {parseInt(el.duration) % 60}
                      min
                    </>
                  )}
                  {' '}
                  with
                  {' '}
                  {el.staff}
                </span>
              </div>
              <div className="appoint-box-price">
                <h6>
                  {currency}
                  {' '}
                  {el.price}
                </h6>
              </div> */}
        </>
      ) : (
        //   ))}
        // </>
        <p>No Appointments Found</p>
      )}
    </div>
  </div>
);

const CustomerInvoices = ({ inv, currency }) => {
  const App = (data) => {
    if (data.appID) {
      history.push(`/partners/viewinvoice?appointmentId=${data.appID}`);
    } else {
      history.push(`/partners/voucherviewinvoice?voucherId=${data.voucherID}`);
    }
  };

  const getInvoicestatus = (data) => {
    if (data.refund) {
      return <span className="badge pending-badge">Refund</span>;
    }
    if (data.cancelStatus) {
      return <span className="badge pending-badge">Voided</span>;
    }
    return <span className="badge completed">Completed</span>;
  };

  return (
    <div className="customer-invoices">
      <div className="partner-table">
        {inv.length > 0 ? (
          <Table responsive="lg">
            <thead>
              <tr>
                <th className="text-left">Status</th>
                <th>Invoice</th>
                <th>Invoice Date</th>
                <th>Location</th>
                <th>Total</th>
              </tr>
            </thead>
            {inv.map((el, i) => (
              <tbody>
                <tr>
                  <td className="text-left">{getInvoicestatus(el)}</td>
                  <td role="presentation" onClick={() => App(el)}>
                    <a href="#/" className="appoi-id">
                      {el.no}
                    </a>
                  </td>
                  <td>{el.date}</td>
                  <td>{el.location}</td>
                  <td>
                    {currency} {el.amount}
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        ) : (
          <p>No Invoices Found</p>
        )}
      </div>
    </div>
  );
};

function DeleteCustomer({ customerId, setShowDelete }) {
  const deleteIds = async (e) => {
    e.preventDefault();
    const payloadd = {
      deleteId: customerId,
    };

    const deleteVoucher = await partnerInstance().post(
      "/deleteCustomer/",
      payloadd
    );

    const { code, msg } = deleteVoucher.data;
    if (code === 200) {
      setShowDelete(false);
      history.push("/partners/customer");
      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={deleteIds}>
      <p>Are You sure you want to delete ?</p>
      <Button variant="primary" type="submit" className="btn-perple">
        Delete
      </Button>
    </Form>
  );
}
