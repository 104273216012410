import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import check from '../../assets/check.png';

const VoucherSuccess = () => (
  <>
    <Helmet>
      <body className="succe-233" />
    </Helmet>
    <div className="success bg-color">

      <div className="succ-12">

        <img src={check} alt=''/>
        <h4>Voucher Purchase Successfull</h4>
        <Link to="/myvouchers"> Go To Vouchers </Link>
      </div>

    </div>
  </>
);
export default VoucherSuccess;
