import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Form, Modal, Tab } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import ReactStars from "react-stars";
import { Link } from "react-router-dom";
//import ReactPaginate from "react-paginate";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import queryString from "query-string";
import { toast } from "react-toastify";
import Slider from "react-slick";
import store from "../../assets/store/no-image.jpg";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { userInstance, websiteInstance } from "../../config/axios";
import { facebookId, googleId, server } from "../../config/keys";
import cover from "../../assets/store/no-image.jpg";
// import store1 from '../../assets/venue1.jpg';
// import store2 from '../../assets/venue2.jpg';
// import store3 from '../../assets/venue3.jpg';
// import store4 from '../../assets/venue4.jpg';
// import avatar from '../../assets/partner/user.jpg';
import avatar1 from "../../assets/partner/avatar-1.jpg";
import history from "../../config/history";
//import placeImg from "../../assets/placeholder-img.png";
//import sliderImage from "../../assets/venue4.jpg";
import arrowIcon from "../../assets/arrow-set.png";
import "./store.css";
import VoucherListing from "../voucher/voucherListing";
const { google } = window;

const StoreDetail = () => {
  const mapRef = useRef(null);
  
  const [isVisible, setIsVisible] = useState(false);
  const [companyName, setCompanyName] = useState("");
  //const [phone, setPhone] = useState("");
  //const [phoneCode, setPhoneCode] = useState("");
  const [locationname, setLocationname] = useState("");
  const [country, setCountry] = useState("");
  const [categories, setCategories] = useState([]);
  const [bussinessHours, setBussinessHours] = useState([]);
  const [description, setDescription] = useState("");
  //const [website, setWebsite] = useState("");
  //const [facebook, setFacebook] = useState("");
  //const [instagram, setInstagram] = useState("");
  const [images, setImages] = useState([]);
  const [storeImage, setStoreImage] = useState("");
  const [currency, setCurrency] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [servicesLength, setServicesLength] = useState(0);
  const [show, setShow] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [customerReviews, setCustomerReviews] = useState([]);
  //const [avgReviews, setAvgReviews] = useState([]);
  //const [ratingWord,setratingWord]=useState();
  const [reviewDetail,setReviewDetail]=useState('')
  const handleClose = () => setShow(false);
  const [event, setEvent] = useState(0);
  const [openTime, setOpenTime] = useState();
  const [address, setAddress] = useState();

  const [limit] = useState(2);
  // eslint-disable-next-line
  const [skip, setSkip] = useState(0);
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [cordinates, setCordinates] = useState({});

  const handleTime = () => {
    setOpenTime(!openTime);
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const value = queryString.parse(window.location.search);
  const { partnerid } = value;

  const getBusinessPartner = useCallback(async () => {
    const payload = {
      partnerid,
      skip,
      limit,
    };
    const partnersData = await websiteInstance().post(
      "/getPartnerByPartnerId",
      payload
    );
    console.log(partnersData)
    if (partnersData.data.partnerData) {
      setCordinates({
        lat: partnersData.data.partnerData.partnerLocation[0].lat,
        lng: partnersData.data.partnerData.partnerLocation[0].lng,
      });
    }

    const { code, partnerData, data, length } = partnersData.data;
    console.log('partnerdata',partnerData)
    if (code === 200) {
      
      // setPartner(partnerData);
      setAddress(
        partnerData.partnerLocation.length > 0
          ? partnerData.partnerLocation[0].addressFormat
          : ""
      );
      setBussinessHours(
        partnerData.partnerLocation.length > 0
          ? partnerData.partnerLocation[0].availability
          : ""
      );

      setCompanyName(partnerData.companyName);
      // setPhone(
      //   partnerData.partnerLocation.length > 0
      //     ? partnerData.partnerLocation[0].locationContactNumber
      //     : ""
      // );
      setLocationname(
        partnerData.partnerLocation.length > 0
          ? partnerData.partnerLocation[0].locationName
          : ""
      );
      // setPhoneCode(
      //   partnerData.partnerLocation.length > 0
      //     ? partnerData.partnerLocation[0].phoneCode
      //     : ""
      // );
      setCountry(
        partnerData.partnerLocation.length > 0
          ? partnerData.partnerLocation[0].country
          : ""
      );
      setDescription(partnerData.Description);
      //setWebsite(partnerData.website);
      //setFacebook(partnerData.facebook);
      //setInstagram(partnerData.instagram);
      setCategories(partnerData.categoryid);
      setCurrency(partnerData.currency);
      setVouchers(partnerData.voucher);
      setServicesLength(partnerData.serviceid.length);
      setStoreImage(partnerData.photo);
      setReviewDetail(partnerData.review);
      //let avg=0;
      //const avgRate=partnerData.review.map(partner=>avg=avg+partner.rate)
      //const word=avgRate>2.5? 'Good':'Bad';
      //setratingWord(word);
      //console.log(avgRate)
      //setAvgReviews(avgRate.length!==0? avgRate:[]);
      setCustomerReviews(data);
      setTotalCount(length);
      setImages(
        partnerData.partnerLocation[0]
          ? partnerData.partnerLocation[0].images
          : []
      );
    }
  }, [partnerid, skip, limit]);

  const calculateAverage=(data,type)=>{
     let avg=0;
     let num=0;
     let word='N/A'
     let calc=0;
     if(data.length!==0){
      
      data.forEach((d)=>{

        calc+=d.rate;
       
       });
  
       avg=(calc/data.length)
      
       num=data.length

       if(avg>2.5){
         word='Great'
       } else{
         word='Good'
       }
    }
     if(type==='average'){
        if(num===0){
          return 0;
        }else{
        return avg.toFixed(1)
        }
      }else if(type==='number'){
        return num;
      }else{
        return word;
      }
}

let d = new Date();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  

  let weekDay = days[d.getDay()];
  let filtered = bussinessHours.filter((el) => el.Day === weekDay);

  let { startTime, endTime, enabled } = filtered[0] || {};
 

  const getWhishCount = async () => {
    const response = await userInstance().get("/getwishlist");
    
    const { code, wishLists } = response.data;
    if (code === 200) {
      wishLists.forEach((el) => {
        if (el._id === partnerid) {
          setFavorite(true);
        }
      });
    }
  };

  useEffect(() => {
    getBusinessPartner();
    getWhishCount();
     // eslint-disable-next-line 
  }, [getBusinessPartner]);

  const settings = {
    // customPaging(i) {
    //   return (
    //     <img
    //       src={images.length > 0 ? `${server}/${images[i]}` : cover}
    //       alt=""
    //     />
    //   );
    // },
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const active = 2;
  const items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const responseFacebook = async (response) => {
   
    if (response.email) {
      const name = response.name.split(" ");
      const payload = {
        firstname: name[0],
        lastname: name[1],
        email: response.email,
        facebookId: response.userID,
      };
      const saveData = await userInstance().post("/facebookLogin", payload);
      const { code, msg, token, userid } = saveData.data;
      if (code === 200) {
        toast.success(msg, { containerId: "B" });
        if (token) {
          localStorage.setItem("usertoken", token);
          localStorage.setItem("userid", userid);
          handleClose();
          window.location.href = `/storedetail/?partnerid=${partnerid}`;
        }
      } else if (code === 409) {
        toast.error(msg, { containerId: "B" });
      } else {
        toast.error(msg, { containerId: "B" });
      }
    } else {
      toast.error("No Email in FB Plz Signup Manually", { containerId: "B" });
    }
  };

  const responseGoogle = async (response) => {
  
    if (!response.error) {
      if (response.profileObj.email) {
        const name = response.profileObj.name.split(" ");
        const payload = {
          firstname: name[0],
          lastname: name[1],
          email: response.profileObj.email,
          googleId: response.googleId,
        };
        const saveData = await userInstance().post("/googleLogin", payload);
        const { code, msg, token, userid } = saveData.data;
        if (code === 200) {
          toast.success(msg, { containerId: "B" });
          if (token) {
            localStorage.setItem("usertoken", token);
            localStorage.setItem("userid", userid);
            window.location.href = `/storedetail/?partnerid=${partnerid}`;
            handleClose();
          }
        } else if (code === 409) {
          toast.error(msg, { containerId: "B" });
        } else {
          toast.error(msg, { containerId: "B" });
        }
      } else {
        toast.error("No Email in Google Plz Signup Manually", {
          containerId: "B",
        });
      }
    }
    //  else {
    //   toast.error(response.error, { containerId: 'B' });
    // }
  };

  // const handlePageClick = ({ selected }) => {
  //   setSkip(selected * limit);
  // };

  //const pageCount = Math.ceil(totalCount / limit);

  // const calculateAverage = (data) => {
  //   let average = 0;
  //   let avg = 0;
  //   const { length } = data;
  //   if (length > 0) {
  //     data.forEach((el) => {
  //       average += el.rate;
  //     });
  //     avg = average / length;
  //   } else {
  //     avg = 0;
  //   }
  //   return Math.round(avg);
  // };

  const addToFavorite = async () => {
    if (favorite) {
      setFavorite(false);
    }
    const payload = {
      partnerId: partnerid,
    };
    const response = await userInstance().post("/updateWishlist", payload);
    const { code, msg } = response.data;
    if (code === 200) {
      getWhishCount();
      toast.success(msg, { containerId: "B" });
    }
  };
  // const options = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };
  // const options = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       options: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       options: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       options: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };

  const Map = useCallback(async () => {
    try {
      const map = new google.maps.Map(mapRef.current, {
        zoom: 1,
        center: cordinates,
      });
      new google.maps.Marker({ position: cordinates, map });
    } catch (e) {
      console.log("error", e);
    }
  }, [cordinates]);

  useEffect(() => {
    Map();
  }, [cordinates, Map]);
  
  return (
    <div className="single-store">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="store-slider-container">
              {images.length > 0 ? (
                <Slider {...settings}>
                  {images.map((img) => (
                    <div className="store-slider">
                      <img
                        src={img ? `${server}/${img}` : store}
                        alt="store"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = store;
                        }}
                      />
                      {/* <img src={sliderImage} alt="" /> */}
                      <div className="store-slider-box d-flex ">
                        <div className="slider-right-box ml-auto">
                          <div className="slider-right-content">
                            <div className="slider-right-content-box">
                              <div className="src-header">
                                <div className="src-header-box">
                                  <div className="rate-content d-flex">
                                    <div className="rate-text">
                                      <h5>{calculateAverage(reviewDetail,'word')}</h5>
                                      <span>{calculateAverage(reviewDetail,'number')}</span>
                                    </div>
                                    <div className="rate-number">{calculateAverage(reviewDetail,'average')}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="src-mid-content">
                                <h3>{companyName}</h3>
                                <p>{`${locationname} , ${address}`}</p>
                              </div>
                              {localStorage.getItem("usertoken") ? (
                                <div className="book-now-btn">
                                  <Link
                                    className="btn"
                                    to={{
                                      pathname: `/customerappointment/selectservices/${partnerid}`,
                                    }}
                                  >
                                    Book Now
                                  </Link>
                                </div>
                              ) : (
                                // <div className="book-now">
                                <div className="book-now-btn">
                                  <Button
                                    onClick={() => setShow(true)}
                                    className="btn btn-primary"
                                  >
                                    {" "}
                                    Book Now{" "}
                                  </Button>
                                </div>

                                // </div>
                              )}
                              {/* <div className="book-now-btn">

                                <Button onClick={() => setShow(true)} className="btn btn-primary"> Book Now </Button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <Slider {...settings}>
                  <div className="store-slider">
                    <img src={cover} alt="" />
                  </div>
                </Slider>
              )}

              {localStorage.getItem("usertoken") ? (
                <div className="store-favorite">
                  <i
                    role="presentation"
                    className={favorite ? "fa fa-heart" : "fa fa-heart-o"}
                    onClick={() => {
                      addToFavorite();
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="store-favorite">
                    <i
                      role="presentation"
                      className="fa fa-heart-o"
                      onClick={() => history.push("/login")}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="scroll-to-top">
              {isVisible && (
                <div>
                  <div className="booknow-fix-btn d-none">
                    <h2>
                      {companyName}
                      {/* <span>Featured</span> */}
                    </h2>
                    {localStorage.getItem("usertoken") ? (
                      <div className="book-now">
                        <Link
                          className="btn"
                          to={{
                            pathname: `/customerappointment/selectservices/${partnerid}`,
                          }}
                        >
                          Book Now
                        </Link>
                      </div>
                    ) : (
                      <div className="book-now">
                        <Button onClick={() => setShow(true)}>
                          {" "}
                          Book Now{" "}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="store-detail-content">
              <div className="container">
                <div className="store-about">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="store-about-content">
                        <h3>About </h3>
                        <p>{description}</p>
                        {/* <a className='' href='#!'>
                          Show More
                        </a> */}
                      </div>

                      {/* <div className="store-detail-info">
                        <h2>
                          {companyName}
                          {/* <span>Featured</span> */}
                      {/* </h2>
                        <div className="store-detail">
                          <ul>
                            <li>
                              <i className="fa fa-map-marker" />
                              {' '}
                              {locationname}
                              ,
                              {' '}
                              {country}
                              {' '}
                            </li>
                            <li>
                              <i className="fa fa-phone" />
                              (
                              {phoneCode}
                              )
                              {' '}
                              {phone}
                            </li>
                          </ul>
                          <div className="store-rating">

                            <ReactStars
                              count={5}
                              value={calculateAverage(avgReviews)}
                              size={25}
                              edit={false}
                              color2="#ffd700"
                            />
                            <i>
                              (
                              {calculateAverage(avgReviews)}
                              )
                            </i>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="about-store">
                        <h3 className="heading-title">
                          About
                          {' '}
                          {companyName}
                        </h3>

                        <p>{Description}</p> */}

                      {/* <ul>
                          <li>
                            <i className="fa fa-facebook-square" />
                            {' '}
                            <a href={facebook}>Facebook</a>
                          </li>
                          <li>
                            <i className="fa fa-instagram" />
                            {' '}
                            <a href={instagram}>Instagram</a>
                          </li>
                          <li>
                            <i className="fa fa-globe" />
                            {' '}
                            <a href={website}>Website</a>
                          </li>
                        </ul> */}
                      {/* </div> */}

                      <div className="voucher-listing-section">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="heading-title">Vouchers </h3>
                            {/* <Slider {...options} > */}
                            {vouchers && vouchers.length > 0 ? (
                              <VoucherListing
                                vouchers={vouchers}
                                servicesLength={servicesLength}
                                partnerid={partnerid}
                                currency={currency}
                                locationname={locationname}
                                country={country}
                                storeImage={storeImage}
                                companyName={companyName}
                              />
                            ) : (
                              <div className="noservice-found">
                                <h3>No Vouchers Found</h3>
                              </div>
                            )}
                            {/* </Slider> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="store-location-content">
                        <h3>Location</h3>
                        <p>{address}</p>
                        {/* <img src={placeImg} alt="" /> */}
                        <div
                          id="map_canvas"
                          style={{ height: 300 }}
                          ref={mapRef}
                        />
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d56941.071481256426!2d80.9300008!3d26.8775557!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1624368114224!5m2!1sen!2sin" loading="lazy" title="map view" /> */}
                        <div
                          //className=""
                          role="presentation"
                          onClick={handleTime}
                          className={`time-content d-flex align-items-center ${
                            openTime ? "rotate-arrow" : ""
                          }`}
                        >
                          <a className="" href="#!">
                            <span>OPEN NOW</span>{" "}
                            {enabled ? (
                              <span>
                                {startTime}-{endTime}
                              </span>
                            ) : (
                              <span>Closed</span>
                            )}
                          </a>
                          <img src={arrowIcon} alt="arrow-down" />
                        </div>
                        {/* {openTime && (
                          <div className="show-time">
                            <ul>
                              <li><a href="#!" className="time"> 9 : 00 AM</a></li>
                              <li>
                                <a href="#!" className="time">10 : 00 AM</a>
                              </li>

                              <li>
                                <a href="#!" className="time">11 : 00 AM</a>

                              </li>
                              <li>
                                <a href="#!" className="time">12 : 00 AM</a>

                              </li>
                            </ul>

                          </div>

                        )} */}
                      </div>
                      {/* {{localStorage.getItem('usertoken') ? (
                        <div className="book-now">
                          <Link
                            className="btn"
                            to={{
                              pathname: `/customerappointment/selectservices/${ partnerid }`,
                            }}
                          >
                            Book Now
                          </Link>
                        </div>
                      ) : (
                        <div className="book-now">
                          <Button onClick={() => setShow(true)}> Book Now </Button>
                        </div>
                      )} */}
                      <div className="opening-hours-content">
                        {openTime && (
                          <div className="opening-hours">
                            {/* <h3>
                          <i className="fa fa-calendar" />
                          {' '}
                          Business Hours
                        </h3> */}
                            <ul>
                              {bussinessHours &&
                                bussinessHours.map((item) => (
                                  <li>
                                    {item.Day}{" "}
                                    {item.enabled ? (
                                      <span>
                                        {item.startTime}-{item.endTime}
                                      </span>
                                    ) : (
                                      <span>Closed</span>
                                    )}
                                  </li>
                                ))}

                              {/* <li>
                            Tuesday

                            <span>10:00 AM - 06:00 PM</span>
                          </li>
                          <li>
                            Wednesday

                            <span>10:00 AM - 06:00 PM</span>
                          </li>
                          <li>
                            Thursday

                            <span>10:00 AM - 06:00 PM</span>
                          </li>
                          <li>
                            Friday

                            <span>10:00 AM - 06:00 PM</span>
                          </li>
                          <li>
                            Saturday

                            <span>Closed</span>
                          </li>
                          <li>
                            Sunday

                            <span>Closed</span>
                          </li> */}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="store-services">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="heading-title">Services</h3>
                      <div className="store-service-tab">
                        <div className="select-service-tab">
                          {categories && categories.length > 0 ? (
                            <Tabs
                              defaultActiveKey={0}
                              id="uncontrolled-tab-example"
                              onSelect={(e) => setEvent(+e)}
                            >
                              {categories.map((el, i) => (
                                <Tab eventKey={i} title={el.categoryname}>
                                  <TabContent
                                    serviceid={el.serviceid}
                                    packageid={el.packageid}
                                    currency={currency}
                                    partnerid={partnerid}
                                    setShow={setShow}
                                    event={event}
                                  />
                                </Tab>
                              ))}
                            </Tabs>
                          ) : (
                            <div className="noservice-found">
                              <h3>No Services Found</h3>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="store-featured-service">
            <div className="row">
              <div className="col-md-12">
                <h3 className="heading-title">Featured Services</h3>

                <div className="featured-service-list">
                  <div className="featured-service-box">
                    <div className="service-icon">BT</div>
                    <h5>Body Treatment</h5>
                    <span>Senior Stylist</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">AH</div>
                    <h5>Advanced Hair cut</h5>
                    <span>Director</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">HM</div>
                    <h5>Head Massage</h5>
                    <span>Stylist</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">SH</div>
                    <h5>Stylish Hair Cut</h5>
                    <span>Hair Stylist</span>
                  </div>

                  <div className="featured-service-box">
                    <div className="service-icon">BT</div>
                    <h5>Body Treatment</h5>
                    <span>Senior Stylist</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

                <div className="store-review">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="comments utf_listing_reviews">
                        <h3 className="heading-title">Reviews</h3>
                        <ul>
                          {customerReviews && customerReviews.length > 0 ? (
                            <>
                              {customerReviews &&
                                customerReviews.map((el) => (
                                  <>
                                    <li>
                                      <div className="avatar">
                                        <img
                                          src={
                                            el.reviewFrom.image
                                              ? `${server}/${el.reviewFrom.image}`
                                              : avatar1
                                          }
                                          alt=""
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = avatar1;
                                          }}
                                        />
                                      </div>
                                      <div className="utf_comment_content">
                                        <div
                                          className="utf_star_rating_section"
                                          data-rating="5"
                                        >
                                          <ReactStars
                                            count={5}
                                            value={el.rate}
                                            size={25}
                                            edit={false}
                                            color1="#6b6b6b"
                                            color2="#9553ff"
                                          />
                                        </div>

                                        <div className="utf_by_comment">
                                          <div className="name-designation d-flex align-items-center">
                                            <h3>
                                              {" "}
                                              {el.reviewFrom.firstname}{" "}
                                              {el.reviewFrom.lastname}
                                            </h3>
                                            <span>Designer</span>
                                          </div>
                                        </div>
                                        <p>{el.review}</p>
                                        <span className="date">
                                          {/* <i className="fa fa-clock-o" /> */}
                                          {moment(el.createdAt).format(
                                            'MMMM Do YYYY'
                                          )}{" "}
                                          {/* {moment(el.createdAt).format('HH:mm')} */}
                                        </span>
                                      </div>
                                    </li>
                                    {/* </ul> */}
                                  </>
                                ))}
                            </>
                          ) : (
                            <div className="noservice-found">
                              <h3>No Reviews Found</h3>
                            </div>
                          )}
                        </ul>
                      </div>
                      {/*
                      <div className="store-pagination">
                        {totalCount >= limit && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="pagination-section">
                                <ReactPaginate
                                  previousLabel="← Previous"
                                  nextLabel="Next →"
                                  pageCount={pageCount}
                                  onPageChange={handlePageClick}
                                  containerClassName="pagination-chk"
                                  previousLinkClassName="pagination__link"
                                  nextLinkClassName="pagination__link"
                                  disabledClassName="pagination__link--disabled"
                                  activeClassName="pagination__link--active"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div> */}
                    </div>
                    {/*
              <div className="col-md-4">
                <div className="opening-hours">
                  <h3>
                    <i className="fa fa-envelope" />
                    {' '}
                    Add Review
                  </h3>
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="utf_leave_rating margin-bottom-30">
                          <input
                            type="radio"
                            name="rating"
                            id="rating-1"
                            value="1"
                          />
                          <label htmlFor="rating-1" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-2"
                            value="2"
                          />
                          <label htmlFor="rating-2" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-3"
                            value="3"
                          />
                          <label htmlFor="rating-3" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-4"
                            value="4"
                          />
                          <label htmlFor="rating-4" className="fa fa-star" />
                          <input
                            type="radio"
                            name="rating"
                            id="rating-5"
                            value="5"
                          />
                          <label htmlFor="rating-5" className="fa fa-star" />
                          <span>Rating: </span>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-md-12">
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="Your Message"
                          name="message"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <Button>Submit Review</Button>
                  </Form>
                </div>
              </div> */}
                  </div>
                </div>

                {/* <div className="near-venue">
            <h3 className="heading-title">About Emilia Hair Studio</h3>

            <div className="row">
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store1} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>Le Fix</h2>
                    <p>45 Newman Street, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>4.8 Great</span>
                        {' '}
                        97
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store2} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>The Barbery</h2>
                    <p>29 Webber Street, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>5.0 Great</span>
                        {' '}
                        245
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store3} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>Wellnesss Massage</h2>
                    <p>152 Newcavendish, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>4.8 Great</span>
                        {' '}
                        155
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="featured-store-box">
                  <div className="featured-store-thumb">
                    <img src={store4} alt="store" />
                  </div>
                  <div className="featured-store-info">
                    <h2>ELP Barbershop</h2>
                    <p>45 Newman Street, London</p>
                    <div className="featured-rating">
                      <p>
                        <i className="fa fa-star" />
                        {' '}
                        <span>4.8 Great</span>
                        {' '}
                        97
                        ratings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              </div>
            </div>
            <Modal show={show} onHide={handleClose} className="discount-12">
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title ">
                  Signup/Login To Continue
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="select-serve customerlogin new-customer-login">
                  {" "}
                  <div className="store-services ">
                    {" "}
                    <div className="row">
                      {" "}
                      <div className="col-md-12">
                        <div className="service-card">
                          <div className="login-btn">
                            <div className="signup-email">
                              <Link to="/register">
                                {/* <i className="fa fa-envelope" /> */}
                                Sign up with email
                              </Link>{" "}
                            </div>
                            <div className="google-btn">
                              <GoogleLogin
                                clientId={googleId}
                                buttonText="Continue with google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy="single_host_origin"
                              />
                              {/* <img src={google} alt="google-icon" /> */}
                              {/* <Button variant="default">
                                Signup with Google

                                {' '}
                              </Button> */}{" "}
                            </div>
                            <div className="facebook-btn">
                              {/* <img src={facebookIcon} alt="facebook-icon" /> */}
                              <FacebookLogin
                                appId={facebookId}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                textButton="Continue with FaceBook"
                              />
                              {/* <Button variant="default">
                                Signup with Facebook

                                {' '}
                              </Button> */}{" "}
                            </div>{" "}
                          </div>
                          <div className="already-account">
                            <h5>Already have an account?</h5>
                            <Link
                              to={{
                                pathname: "/login",
                                state: {
                                  partnerid,
                                },
                              }}
                            >
                              Log in now
                            </Link>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              </Modal.Body>
              .
              <Modal.Footer />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;

const TabContent = ({
  serviceid,
  currency,
  packageid,
  setShow,
  partnerid,
  event,
}) => {
  const [selectServices] = useState([]);
  const [paymentRequired, setPaymentRequired] = useState(false);
  const [sameCategory] = useState(true);
  const [hasBundle, setHasBundle] = useState(false);

  const Check = (data) => {
    if (localStorage.getItem("usertoken")) {
      const servicesCopy = [...selectServices];
      servicesCopy.push({
        servicename: data.serviceName,
        duration: data.duration,
        amount: data.specialPrice ? data.specialPrice : data.priceFrom,
        id: data._id,
        services: null,
        categoryid: data.serviceCategory,
        payment: data.isPayment,
        outService: data.outService,
      });
      history.push({
        pathname: `/customerappointment/selectservices/${partnerid}`,
        state: {
          servicesPanel: servicesCopy,
          paymentRequired: data.isPayment,
          outService: data.outService,
          sameCategory,
          hasBundle,
          event,
        },
      });
    } else {
      setShow(true);
    }
  };

  const Check2 = (data) => {
    if (localStorage.getItem("usertoken")) {
      const servicesCopy = [...selectServices];
      servicesCopy.push({
        servicename: data.packagename,
        duration: data.packageDuration,
        amount: data.packageSpecialPrice,
        id: data._id,
        services: data.serviceid,
        categoryid: data.packagecategory,
        priceType: data.priceType,
        retailPrice: data.retailPrice,
        packagePrice: data.packagePrice,
        payment: true,
      });
      setHasBundle(true);
      setPaymentRequired(true);
      history.push({
        pathname: `/customerappointment/selectservices/${partnerid}`,
        state: {
          servicesPanel: servicesCopy,
          paymentRequired,
          sameCategory: false,
          hasBundle,
          event,
        },
      });
    } else {
      setShow(true);
    }
  };

  return (
    <div className="store-service-tab-content">
      <div className="row">
        <div className="col-md-12">
          {serviceid && serviceid.length > 0 ? (
            <div className="service-selection">
              {/* // <div className="noservice-found"><h3>No Services Found</h3></div> */}
              {serviceid.map((el, i) => (
                <div className="service-list-view">
                  <div className="service-checkbox">
                    <Form.Check
                      type="checkbox"
                      checked={false}
                      label=""
                      onClick={(e) => Check(el)}
                    />
                  </div>
                  <div className="service-detail">
                    <div className="service-info">
                      {/* <span>{el.serviceName}</span> */}
                      <h3>{el.serviceName}</h3>
                      <p>
                        Welcome to Balance Massage & Wellness, a place where
                        therapeutic massage and relaxation meet.{" "}
                      </p>
                      <span>
                        {Math.floor(parseInt(el.duration, 10) / 60) === 0 ? (
                          <>{parseInt(el.duration, 10) % 60} min</>
                        ) : (
                          <>
                            {Math.floor(parseInt(el.duration) / 60)}h{" "}
                            {parseInt(el.duration) % 60 === 0 ? (
                              ""
                            ) : (
                              <>
                                ,{parseInt(el.duration) % 60}
                                min
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <div className="service-cost">
                      <h5>
                        {el.priceFrom !== "0" ? currency : ""}{" "}
                        {el.specialPrice
                          ? el.specialPrice
                          : el.priceFrom === "0"
                          ? "Free"
                          : el.priceFrom}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
              {packageid.map((el, i) => (
                <div className="service-list-view">
                  <div className="service-checkbox">
                    <Form.Check
                      type="checkbox"
                      label=""
                      checked={false}
                      onClick={(e) => Check2(el)}
                    />
                  </div>
                  <div className="service-detail">
                    <div className="service-info">
                      <h3>{el.packagename}</h3>

                      <span>
                        {Math.floor(parseInt(el.packageDuration) / 60) === 0 ? (
                          <>{parseInt(el.packageDuration) % 60} min</>
                        ) : (
                          <>
                            {Math.floor(parseInt(el.packageDuration) / 60)}h{" "}
                            {parseInt(el.packageDuration) % 60 === 0 ? (
                              ""
                            ) : (
                              <>
                                ,{parseInt(el.packageDuration) % 60}
                                min
                              </>
                            )}
                          </>
                        )}
                      </span>
                      {/* <span></span> */}
                    </div>
                    <div className="service-cost">
                      <h5>
                        {/* {currency} */}$ {el.retailPrice}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="noservice-found">
              <h3>No Services Found</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
