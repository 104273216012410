 /* eslint-disable no-useless-concat */
import axios from "axios";
import { server } from "./keys";

export const userInstance = () => {
 
  return axios.create({
    baseURL: `${server}/userapi`,
    headers: {
      Authorization: localStorage.getItem("usertoken")
        ? `Bearer ${localStorage.getItem("usertoken")}`
        : "",
    },
    // withCredentials: true,
  });
};
export const partnerInstance = () =>
  axios.create({
    baseURL: `${server}/partnerapi`,
    headers: {
      Authorization: localStorage.getItem("partnertoken")
        ? `Bearer ${localStorage.getItem("partnertoken")}`
        : "",
      authtoken: localStorage.getItem("authtoken")
        ? localStorage.getItem("authtoken")
        : "",
    },
    // withCredentials: true,
  });
export const adminInstance = () =>
  axios.create({
    baseURL: `${server}/adminapi`,
    headers: {
      Authorization: localStorage.getItem("admintoken")
        ? `${'Bearer' + ' '}${localStorage.getItem("admintoken")}`
        : "",
    },
    // withCredentials: true,
  });

export const websiteInstance = () =>
  axios.create({
    baseURL: `${server}/websiteapi`,
  });
