import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import queryString from "query-string";
import PropTypes from "prop-types";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Loader from "../../loader/loader";
import PartnerContext from "../../../context/context";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const CalendarTwo = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState(false);
  const [appointments, setAppointments] = useState([]);

  //const [appointmentId, setAppointmentId] = useState('');
  const [ID, setID] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [copyStaffs, setCopyStaffs] = useState([]);
  const [staffId, setStaffId] = useState("");
  const [view, onView] = useState("day");
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  // const [availability, setAvailability] = useState([]);
  // const now = moment(new Date());
  const [message, setMessage] = useState("");

  //const [startTime, setStartTime] = useState('');
  //const [appointmentDate, setAppointmentDate] = useState();
  const [show, setShow] = useState(false);
  const [dateInfo, setdateInfo] = useState(new Date());
  const getAppointments = async () => {
    setLoading(true);
    const responseData = await partnerInstance().get("/getAppointments");
    const { code, appointmentlist } = responseData.data;
    // update();
    if (code === 200) {
      const app = [];
      appointmentlist.forEach((el) => {
        el.appointmentPanel.forEach((ele) => {
          app.push({
            id: ele._id,
            title: ele.serviceId.serviceName,
            start: moment(`${el.appointmentDate} ${ele.startTime}`).toDate(),
            end: moment(`${el.appointmentDate} ${ele.endTime}`).toDate(),
            appointmentId: el._id,
            description: "description for All Day Event",
            resourceId: ele.staffId._id,
          });
        });
      });
      setAppointments(app);

      setLoading(false);
    }
  };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const getStaffs = async () => {
    const response = await partnerInstance().get("/getStaffs");
    const { code, stafflist } = response.data;
    const res = [];
    if (code === 200) {
      const findBook =
        sections &&
        sections.findIndex(
          (boo) => boo.sectionname === "Access Other Staff Calendars"
        );
      const whoHasAppointmentBooking = stafflist.filter(
        (bo) => bo.appointmentBooking
      );
      if (findBook > -1) {
        whoHasAppointmentBooking.forEach((el) => {
          res.push({ id: el._id, title: el.firstname });
        });
        setStaffs(res);
        setCopyStaffs(res);
      } else {
        const filterSt = whoHasAppointmentBooking.filter(
          (six) => six._id === localStorage.getItem("userid")
        );
        res.push({
          id: filterSt[0] && filterSt[0]._id,
          title: filterSt[0] && filterSt[0].firstname,
        });
        setStaffs(res);
        setCopyStaffs(res);
      }
    }
  };
  // const getAvailability = async () => {
  //   const response = await partnerInstance().get('/getPartnerAccount');
  //   const { code, partnerData } = response.data;
  //   if (code === 200) {
  //     setAvailability(partnerData.partnerLocation[0].availability);
  //   }
  // };

  useEffect(() => {
    const value = queryString.parse(window.location.search);
    const { customerid } = value;
    const findBook =
      sections &&
      sections.findIndex((boo) => boo.sectionname === "Can Book Appointments");
    if (findBook > -1) {
      setBook(true);
    } else {
      setBook(false);
    }
    setID(customerid);
    getAppointments();
    getStaffs();
    // getAvailability();
    // eslint-disable-next-line
  }, []);

  const handleDateSelect = async (selectInfo) => {
    //console.log("Select info", selectInfo);
    const findBook = sections.findIndex(
      (bo) => bo.sectionname === "Can Book Appointments"
    );
    if (findBook > -1) {
      const value = queryString.parse(window.location.search);
      const { rebook } = value;
      const selectedDate = selectInfo.start;
      const setFullName = moment(selectedDate).format("dddd");
      const startTim = moment(selectedDate).format("HH:mm");
      const startDat = moment(selectedDate).format("YYYY-MM-DD");
      if (rebook && setFullName !== "Sunday") {
        const idd = {
          staffid: selectInfo.resourceId,
          appointmentDate: startDat,
        };
        const response = await partnerInstance().post("/getStaffShift", idd);
        const {
          code,
          shiftTiming: shifttiming,
          getClosedDates,
        } = response.data;
        const Closed = getClosedDates ? getClosedDates.closeddate : [];
        const filterClosedDates = Closed.filter(
          (el) =>
            new Date(startDat).getTime() >= new Date(el.from).getTime() &&
            new Date(startDat).getTime() <= new Date(el.to).getTime()
        );
        if (filterClosedDates.length > 0) {
          setMessage("Closed");
          setShow(true);
        } else {
          let valid = false;
          if (
            startTim.toString() >= shifttiming[0].starttime &&
            startTim.toString() <= shifttiming[0].endtime
          ) {
            valid = true;
          } else {
            valid = false;
          }
          if (shifttiming.length > 1 && !valid) {
            if (
              startTim.toString() >= shifttiming[1].starttime &&
              startTim.toString() <= shifttiming[1].endtime
            ) {
              valid = true;
            } else {
              valid = false;
            }
          }
          if (code === 200) {
            if (valid) {
              const payload = {
                id: rebook,
                startTime: startTim,
                startDate: startDat,
                staffId: selectInfo.resourceId,
              };
              const saveData = await partnerInstance().post(
                "/rebookAppointment/",
                payload
              );
              const { code, msg } = saveData.data;
              if (code === 200) {
                getAppointments();
                toast.success(msg, { containerId: "B" });
                history.push("/partners/calendar");
              }
            } else {
              setMessage("Staff Doesn't work in this duration");
              setShow(true);
            }
          }
        }
      } else if (setFullName !== "Sunday") {
        if (
          moment(selectedDate).format("YYYY-MM-DD") >=
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          setStartDate(startDat);
          //setStartTime(startTim);
          if (ID) {
            history.push(
              `/partners/addappointment/?startDate=${startDat}&starttime=${startTim}&staffId=${selectInfo.resourceId}&customerid=${ID}`
            );
          } else {
            history.push(
              `/partners/addappointment/?startDate=${startDat}&starttime=${startTim}&staffId=${selectInfo.resourceId}`
            );
          }
        } else {
          setMessage("Cannot Book Appointments OnPrevious Dates");
          setShow(true);
        }
      } else {
        setMessage("Sunday is Closed!");
        setShow(true);
      }
    } else {
      setShow(true);
      setMessage("You Cannot Authorised to this Appointment Booking");
    }
  };

  const handleEventClick = (clickInfo) => {
    history.push(`/partners/viewappointment?id=${clickInfo.appointmentId}`);
  };
  const handleView = (e) => {
    onView(e);
  };
  const handleNavigate = (e) => {
    //console.log("Navigation", e);
    setdateInfo(e);
  };

  const handleDrag = async (e) => {
    //console.log("This is the e in handleDrag", e);
    let resourceId_;
    if (view === "month") {
      resourceId_ = e.event.resourceId;
    } else {
      resourceId_ = e.resourceId;
    }
    const updatedDate = moment(e.start).format("YYYY-MM-DD");
    const setFullName = moment(updatedDate).format("dddd");
    if (setFullName !== "Sunday") {
      //setAppointmentId(e.event.appointmentId);
      //setAppointmentDate(updatedDate);
      const startTim = moment(e.start).format("HH:mm");
      const idd = {
        staffid: resourceId_,
        appointmentDate: updatedDate,
      };
      const response = await partnerInstance().post("/getStaffShift", idd);
      const { code, shiftTiming: shifttiming, getClosedDates } = response.data;
      const Closed = getClosedDates ? getClosedDates.closeddate : [];
      const filterClosedDates = Closed.filter(
        (el) =>
          new Date(updatedDate).getTime() >= new Date(el.from).getTime() &&
          new Date(updatedDate).getTime() <= new Date(el.to).getTime()
      );
      if (filterClosedDates.length > 0) {
        setMessage("Closed");
        setShow(true);
      } else {
        let valid = false;
        if (
          startTim.toString() >= shifttiming[0].starttime &&
          startTim.toString() <= shifttiming[0].endtime
        ) {
          valid = true;
        } else {
          valid = false;
        }
        if (shifttiming.length > 1 && !valid) {
          if (
            startTim.toString() >= shifttiming[1].starttime &&
            startTim.toString() <= shifttiming[1].endtime
          ) {
            valid = true;
          } else {
            valid = false;
          }
        }

        if (code === 200) {
          if (valid) {
            const payload = {
              id: e.event.id,
              staff: resourceId_,
              appointmentId: e.event.appointmentId,
              appointmentDate: updatedDate,
              startTime: startTim,
            };

            const saveData = await partnerInstance().post(
              "/updateAppointment/",
              payload
            );
            const { code, msg } = saveData.data;
            if (code === 200) {
              getAppointments();
              toast.success(msg, { containerId: "B" });
            }
          } else {
            setMessage("Staff Doesn't work in this duration");
            setShow(true);
          }
        }
      }
    } else {
      setMessage("Sunday is Closed!");
      setShow(true);
    }
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }
  //console.log(view);
  //console.log('I am getting the resources',resourcesY)
  return (
    <PartnerWrapper heading="Appointment">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="calendar-page">
        <div className="container">
          {/* <h2 className="admin-title">Appointment </h2> */}

          <div className="calendar-content-view">
            <CalendarFilter
              startDate={startDate}
              staffId={staffId}
              setStaffId={setStaffId}
              staffs={staffs}
              setStaffs={setStaffs}
              copyStaffs={copyStaffs}
              setAppointments={setAppointments}
              book={book}
            />

            <div className="calendar-content">
              <DragAndDropCalendar
                localizer={localizer}
                events={appointments}
                date={dateInfo}
                onNavigate={(e) => handleNavigate(e)}
                selectable
                onEventDrop={(e) => handleDrag(e)}
                onSelectSlot={(e) => handleDateSelect(e)}
                onSelectEvent={(e) => handleEventClick(e)}
                //defaultView="day"
                startAccessor="start"
                format="YYYY-MM-DD HH:mm"
                endAccessor="end"
                style={{ height: 500 }}
                resourceAreaWidth="5%"
                resources={staffs}
                views={["month", "week", "day"]}
                view={view}
                onView={(e) => handleView(e)}
                header={{
                  left: "prev, next",
                }}
              />
            </div>
          </div>
          <Modal className="calender-popup" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>No Scheduled Staff</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default CalendarTwo;

const CalendarFilter = ({
  startDate,
  staffId,
  setStaffs,
  copyStaffs,
  staffs,
  setAppointments,
  book,
}) => {
  const old = [...copyStaffs];

  const SelectStaffId = (e) => {
    if (e !== "All") {
      const filterSatffs = old.filter((el) => el.id === e);
      setStaffs(filterSatffs);
    } else {
      setStaffs(old);
    }
  };
  return (
    <div className="calendar-filter">
      <div className="row">
        <div className="col-md-6">
          <div className="calendar-associate">
            <select
              name="workin_staff"
              className="form-control js-schedule-employee"
              onChange={(e) => SelectStaffId(e.target.value)}
              // value={props.staffId}
            >
              <option value="All">All</option>
              {old &&
                old.map((el, i) => (
                  <option value={el.id} key={i}>
                    {el.title}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          {book && (
            <div className="calendar-days">
              <Link
                to={`/partners/addappointment/?startDate=${startDate}&starttime=${moment(
                  new Date()
                )
                  .add(15, "minutes")
                  .startOf("hour")
                  .format("HH:mm")}&staffId=${localStorage.getItem("userid")}`}
                className="add-voucher-btn"
              >
                {" "}
                Add New
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CalendarFilter.propTypes = {
  copyStaffs: PropTypes.arrayOf(PropTypes.array).isRequired,
  setStaffs: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  staffId: PropTypes.string.isRequired,
};
