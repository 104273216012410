import React, { useContext } from "react";
import { ProgressBar } from "react-bootstrap";
import PartnerWrapper from "../partnerwrapper/wrapper";
import cross from "../../../assets/cross2.png";
import history from "../../../config/history";
import OnlinePayment from "./onlinepayment";
import Helmet from "react-helmet";
import { StepCompletePrevent } from "./StepCompletePrevent";
import PartnerContext from "../../../context/context";

const SignupStep8 = () => {
  const { isStepComplete } = useContext(PartnerContext);
  StepCompletePrevent(isStepComplete);
  const Cross = () => {
    history.push("/partners/intro");
  };
  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form taxes btn-align">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3> Online Payment </h3>
                    <div className="signup-progress">
                      <ProgressBar now={100} />
                    </div>
                  </div>
                  <div className="signup-body">
                    <OnlinePayment />
                  </div>
                </div>
                {/* <div className="signup-right">
                  <div className="signup-footer-btn">
                    <Button type="submit" onClick={Step6}>
                      Back
                    </Button>
                    <Button type="submit" onClick={Cross}>
                      Submit
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupStep8;
