import React, { useState } from "react";
import { Card, Button, ProgressBar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
//import { loadStripe } from '@stripe/stripe-js';
import { toast } from "react-toastify";
import { server } from "../../config/keys";
//import visa from '../../assets/visa-icon.png';
import store2 from "../../assets/no-image.jpg";
//import master from '../../assets/master-icon.png';
import { userInstance } from "../../config/axios";
import { checkKeys } from "../../function/validate";
import { Helmet } from "react-helmet";

//const stripePromise = loadStripe(stripeKey);

const Paymentvoucher = () => {
  const { state } = useLocation();
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [value] = useState(state.value);
  const [total] = useState(state.total);
  const [locationName] = useState(state.locationName);
  const [currency] = useState(state.currency);
  const [country] = useState(state.country);
  const [voucherId] = useState(state.voucherId);
  const [storeImage] = useState(state.storeImage);
  const [companyName] = useState(state.companyName);
  const [voucherType] = useState(state.giftType);
  const [firstname] = useState(state.firstname);
  const [lastname] = useState(state.lastname);
  const [email] = useState(state.email);
  const [message] = useState(state.message);
  const [servicessLength] = useState(state.servicessLength);
  const [retailPrice] = useState(state.retailPrice);
  const [voucherValue] = useState(state.voucherValue);
  const [rt] = useState(state.rt);
  const [quantity] = useState(state.quantity);
  console.log("data", state);
  const [recipient /*setRecipient */] = useState(state.email);

  const handleClick = async () => {
    const stripe = await checkKeys();
    const userid = localStorage.getItem("userid");
    const response = await userInstance().post("/voucher-checkout-session", {
      amount: total,
      currency,
      partnerid: id,
      voucherId,
      userid,
      quantity,
      recipient,
    });
    if (response.data.code === 200) {
      const session = await response.data.id;
      const result = await stripe.redirectToCheckout({
        sessionId: session,
      });
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else {
      toast.error(response.data.msg, { containerId: "B" });
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps" />
      </Helmet>
      <div className="voucher-detail new-voucher-detail">
        <div className="service-bg new-select-service bg-color">
          <div className="container">
            <h2 className="page-title">
              <p>
                <span> Step 4 | </span> Payment Option
              </p>
              {voucherType === "treat" ? (
                <Link
                  className="btn"
                  to={{
                    pathname: `/giftVoucher/${id}`,
                    state: {
                      value,
                      total,
                      voucherId,
                      currency,
                      locationName,
                      country,
                      storeImage,
                      companyName,
                      servicessLength,
                      firstname: null,
                      lastname: null,
                      message: null,
                      email: null,
                      retailPrice,
                      quantity,
                      voucherValue,
                      rt,
                    },
                  }}
                >
                  <i className="fa fa-angle-left" /> Back
                </Link>
              ) : voucherType === "print" ? (
                <Link
                  className="btn"
                  to={{
                    pathname: `/printGift/${id}`,
                    state: {
                      value,
                      total,
                      voucherId,
                      currency,
                      locationName,
                      country,
                      storeImage,
                      companyName,
                      servicessLength,
                      firstname,
                      lastname,
                      message,
                      email: null,
                      retailPrice,
                      quantity,
                      voucherValue,
                      rt,
                    },
                  }}
                >
                  <i className="fa fa-angle-left" /> Back
                </Link>
              ) : (
                <Link
                  className="btn"
                  to={{
                    pathname: `/emailFrom/${id}`,
                    state: {
                      value,
                      total,
                      voucherId,
                      currency,
                      locationName,
                      country,
                      storeImage,
                      companyName,
                      servicessLength,
                      firstname,
                      lastname,
                      message,
                      email,
                      retailPrice,
                      quantity,
                      voucherValue,
                      rt,
                    },
                  }}
                >
                  <i className="fa fa-angle-left" /> Back
                </Link>
              )}
            </h2>
            <div className="select-serve">
              <div className="store-services ">
                <div className="row">
                  <div className="col-md-4">
                    <div className="service-card">
                      <div className="box-details">
                        <Card>
                          <Card.Img
                            variant="top"
                            src={
                              storeImage ? `${server}/${storeImage}` : store2
                            }
                          />
                          <Card.Body>
                            <Card.Title className=" heading-bold">
                              {companyName}
                            </Card.Title>
                            <Card.Text className="">
                              {locationName},{country}
                            </Card.Text>
                            <div className="border-des">
                              <div className="left-scrollbar">
                                <div className="content-detail">
                                  <div className="product">
                                    <h6>Referral Voucher</h6>
                                    <span>{servicessLength} services</span>
                                  </div>
                                  <div className="rate">
                                    <span>
                                      {currency} {value}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="total-rate">
                              <h6>Total</h6>
                              <h6>
                                {currency} {total}
                              </h6>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="service-card">
                      {voucherType === "treat" ? (
                        <div className="stepwizard">
                          <ProgressBar now={100} />
                          <span className="stepwizard-steps">Step 3</span>
                        </div>
                      ) : (
                        // <div className="stepwizard">
                        //   <ol className="stepwizard-panel">
                        //     <li className="stepwizard-step">Step 1</li>
                        //     <li className="stepwizard-step">Step 2</li>
                        //     <li className="current stepwizard-step">
                        //       Ready to go!
                        //     </li>
                        //   </ol>
                        // </div>
                        <div className="stepwizard">
                          <ProgressBar now={100} />
                          <span className="stepwizard-steps">Step 4</span>
                        </div>
                        // <div className="stepwizard">
                        //   <ol className="stepwizard-panel">
                        //     <li className="stepwizard-step">Step 1</li>
                        //     <li className="stepwizard-step">Step 2</li>
                        //     <li className="stepwizard-step">Step 3</li>
                        //     <li className="current stepwizard-step">
                        //       Ready to go!
                        //     </li>
                        //   </ol>
                        // </div>
                      )}
                      <div className="submit-text text-center">
                        <p>All set press continue</p>
                      </div>
                      {/* <div className="paymentdetail">
                      <div className="paymentdetail-content">
                        <div className="sub-heading">
                          <h5>Confirm with card</h5>
                          <p>You wil not be charged now, payment will be collected in store after your appointment</p>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicFirst">
                              <Form.Label>Card holder full name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Add card holder full name"
                                autocomplete="off"
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Card number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Credit or debit card number"
                                autocomplete="off"
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-6">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Expiry date</Form.Label>
                              <Form.Control
                                type="date"
                                placeholder="MM/YY"
                                autocomplete="off"
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-6">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>CVV</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="123"
                                autoComplete="off"
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <div className="paywith">
                              <div><h6>Pay securly with </h6></div>
                              <div>
                                {' '}
                                <img src={visa} alt="payemnt-method" />
                              </div>
                              <div><img src={master} alt="payemnt-method" /></div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="cancel-policy">
                              <h6>Cancellation policy</h6>
                              <p>Cancel for free anytime in advance, otherwise you can be</p>
                              <p>
                                {' '}
                                charged
                                <strong>10%</strong>
                                {' '}
                                of the service price for not showing up.
                              </p>
                            </div>
                            <div className="booking-notes">
                              <Form.Group>
                                <Form.Label>Add booking notes</Form.Label>
                                <p>Include comments or requests about your booking</p>
                                <textarea
                                  className="form-control text optional employee-modal-note"
                                  rows="12"
                                  placeholder="Add booking notes"
                                  id="employee_notes"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <div className="continue-btn text-right">
                        <Button onClick={handleClick} className=" l-btn">
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Paymentvoucher;
