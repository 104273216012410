import React from "react";
// import { Button, Form, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from "react-router-dom";
import queryString from "query-string";
import PartnerWrapper from "../partnerwrapper/wrapper";
import singleicon from "../../../assets/partner/single-service-icon.svg";
import packageicon from "../../../assets/partner/package-service-icon.svg";

const AddServices = () => {
  const value = queryString.parse(window.location.search);
  const { catid, catname } = value;

  return (
    <PartnerWrapper heading="Service Definition">
      <div className="add-services">
        <div className="container">
          <h2 className="admin-title">
            {/* Service Definition{" "} */}
            <Link to="/partners/services">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="partner-add-services-content">
            <div className="row">
              <div className="col-md-6">
                <div className="add-service-box bdr-right">
                  {catid ? (
                    <Link
                      to={`/partners/addsingleservices?catid=${catid}&catname=${catname}`}
                    >
                      <img src={singleicon} alt="single" />
                      <h4>Simple Service</h4>
                      {/* <p>Services which can be booked individually</p> */}
                    </Link>
                  ) : (
                    <Link to="/partners/addsingleservices">
                      <img src={singleicon} alt="single" />
                      <h4>Simple Service</h4>
                      {/* <p>Services which can be booked individually</p> */}
                    </Link>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="add-service-box">
                  {catid ? (
                    <>
                      {" "}
                      <Link
                        to={`/partners/addpackage?catid=${catid}&catname=${catname}`}
                      >
                        <img src={packageicon} alt="package" />
                        <h4>Bundle</h4>
                        {/* <p>
                          Multiple services booked together in one appointment
                        </p> */}
                      </Link>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link to="/partners/addpackage">
                        <img src={packageicon} alt="package" />
                        <h4>Bundle</h4>
                        {/* <p>
                          Multiple services booked together in one appointment
                        </p> */}
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default AddServices;
