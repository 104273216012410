import React, { useState, useEffect, useCallback } from "react";
import { Form, Dropdown, Button, Tab, FormControl } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import moment from "moment";
// import RangePicker from 'react-range-picker';
import { Link } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { partnerInstance } from "../../../config/axios";
// import { countrycode } from '../partners/register/code';
import { validateStaff } from "../../../function/validate";
import history from "../../../config/history";
import { countrycode } from "../register/code";
// import Location from '../setup/locationcomponent';

const array = [];
const AddStaff = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [staffTitle, setStaffTitle] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  // eslint-disable-next-line
  const [country, setCountry] = useState("");
  const [errors, setErrors] = useState({});
  // const [checked2, setChecked2] = useState(false);
  const [userPermission, setUserPermission] = useState("");
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [appointmentBooking, setAppointmentBooking] = useState(true);
  // const [serviceid, setServiceId] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceCommission, setServiceCommission] = useState("");
  const [voucherCommission, setVoucherCommission] = useState("");
  const [selectAll, setSelectAll] = useState(true);
  // const [checked, setChecked] = useState(false);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [startEmployementDate, setStartEmployementDate] = useState(currentDate);
  const [endEmployementDate, setEndEmployementDate] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectAllLocation, setSelectAllLocation] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [key, setKey] = useState("0");

  useEffect(() => {
    const currentUser = async () => {
      const countryy = await partnerInstance().get("/getPartnerAccount");

      const { code, partnerData } = countryy.data;
      if (code === 200) {
        setCountry(partnerData.address[0].country);
        countrycode.forEach((el, i) => {
          partnerData.address.forEach((e) => {
            if (el.name === e.country) {
              setPhoneCode(el.code);
            }
          });
        });
      }
    };
    const getServices = async () => {
      const servicesData = await partnerInstance().get("/getServicesOnly");
      const { code, services: Services } = servicesData.data;
      if (code === 200) {
        Services.forEach((el) => {
          el.checked = true;
        });
        setServices(Services);
      }
    };
    const getLocations = async () => {
      const locat = await partnerInstance().get("/getPartnerLocations");
      const { code, partnerData } = locat.data;
      if (code === 200) {
        setLocations(partnerData.partnerLocation);
        partnerData.partnerLocation.forEach((el) => {
          el.checkedl = true;
        });
        setLocations(partnerData.partnerLocation);
      }
    };

    getLocations();
    currentUser();
    getServices();
    // getPhoneCode();
  }, []);

  const addStaff = async (e) => {
    setDisabled(true);
    const arrayofid = [];
    const arrayoflocation = [];
    e.preventDefault();

    const filterSelectedIds = services.filter((el) => el.checked);
    filterSelectedIds.forEach((el) => {
      arrayofid.push(el._id);
    });

    const filterSelectedLocation = locations.filter((el) => el.checkedl);
    filterSelectedLocation.forEach((el) => {
      arrayoflocation.push(el._id);
    });

    const days = [];
    function getCurrentWeek() {
      const currentDat = moment();
      // if (country === 'United Arab Emirates') {
      //   const weekStart = currentDat.clone().startOf('isoWeek');
      //   for (let i = 0; i <= 6; i++) {
      //     if (i !== 5) {
      //       days.push({
      //         date: new Date(moment(weekStart).add(i, 'days').format('YYYY-MM-DD')).toString(),
      //         timing: [{ starttime: '08:30', endtime: '17:30' }],
      //         repeat: 'weekly',
      //       });
      //     }
      //   }
      // } else {
      const weekStart = currentDat.clone().startOf("isoWeek");
      for (let i = 0; i <= 5; i++) {
        days.push({
          date: new Date(
            moment(weekStart).add(i, "days").format("YYYY-MM-DD")
          ).toString(),
          timing: [{ starttime: "09:00", endtime: "18:00" }],
          repeat: "weekly",
        });
      }
      // }
    }
    getCurrentWeek();

    const payload = {
      firstname,
      lastname,
      email,
      staffTitle,
      note,
      phone,
      phoneCode,
      userPermission: userPermission || "No Access",
      // startDate,
      // endDate,
      locationId: arrayoflocation,
      appointmentBooking,
      serviceid: arrayofid,
      serviceCommission,
      voucherCommission,
      startEmployementDate,
      endEmployementDate,
      shift: days,
      // checked: false,
    };

    const validation = validateStaff({
      firstname,
      email,
      serviceCommission,
      voucherCommission,
    });
    setErrors(validation.errors);

    if (!validation.isValid) {
      setDisabled(false);
      if (
        validation.errors.serviceCommission ||
        validation.errors.voucherCommission
      ) {
        setKey("2");
      } else {
        setKey("0");
      }

      return;
    }
    const saveData = await partnerInstance().post("/addStaff", payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      history.push("/partners/staff");
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const clearValues = () => {
    setEmail("");
    setFirstname("");
    setLastname("");
    // setChecked2('');
    setPhone("");
  };
  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };
  const handleCheck = (e, el) => {
    const oldState = [...services];
    oldState.forEach((element) => {
      const { checked, _id } = element;
      if (el._id === _id) {
        element.checked = !checked;
      }
    });
    const filter = oldState.filter((item) => item.checked);
    if (filter.length === oldState.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setServices(services);

    update();
  };
  const handleSelectAll = (e) => {
    setSelectAll(!selectAll);
    services.forEach((el) => {
      if (selectAll) {
        services.forEach((element) => {
          el.checked = false;
          array.pop(element);
        });
        setServices(array);
      } else {
        services.forEach((el) => {
          el.checked = true;
          array.push(el._id);
        });
        setServices(services);
        update();
      }
    });
    setServices(services);
    update();
  };
  const handleCheckLocation = (e, el) => {
    const oldState = [...locations];
    oldState.forEach((element) => {
      const { checkedl, _id } = element;
      if (el._id === _id) {
        element.checkedl = !checkedl;
      }
    });
    const filter = oldState.filter((item) => item.checkedl);
    if (filter.length === locations.length) {
      handleSelectAllLocation();
    } else {
      setSelectAllLocation(false);
    }
    setLocations(locations);
    update();
  };
  const handleSelectAllLocation = (e) => {
    setSelectAllLocation(!selectAllLocation);
    locations.forEach((el) => {
      if (selectAllLocation) {
        locations.forEach((element) => {
          el.checkedl = false;
          array.pop(element);
        });
        setLocations(array);
      } else {
        locations.forEach((el) => {
          el.checkedl = true;
          array.push(el._id);
        });
        setLocations(locations);
        update();
      }
    });
    setLocations(locations);
    update();
  };

  return (
    <PartnerWrapper heaading="Add Associate">
      <div className="add-staff">
        <div className="container">
          <h2 className="admin-title">Add Associate</h2>

          <div className="add-staff-content">
            <Form onSubmit={addStaff} key={key}>
              <Tabs
                defaultActiveKey={key}
                id="staff-tab-example"
                onSelect={(e) => setKey(e)}
              >
                <Tab eventKey="0" title="Details">
                  <div className="add-staff-details">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicFirst">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your first name"
                                name="firstName"
                                autocomplete="off"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                              />
                              {errors.firstname && (
                                <p className="error">{errors.firstname}</p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your last name"
                                name="nickName"
                                autocomplete="off"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Mobile number</Form.Label>
                              <div className="number-fields">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    {phoneCode}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {countrycode.map((el) => (
                                      <Dropdown.Item
                                        key={el.id}
                                        onClick={() => {
                                          selectPhoneCode(el.code);
                                        }}
                                      >
                                        {" "}
                                        {el.code}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                  type="number"
                                  placeholder="Your mobile number"
                                  name="email"
                                  autocomplete="off"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                />
                              </div>
                              {errors.phone && (
                                <p className="error">{errors.phone}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Your email address"
                                name="email"
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                              {errors.email && (
                                <p className="error">{errors.email}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Permission Level</Form.Label>
                              <Form.Control
                                as="select"
                                onChange={(e) =>
                                  setUserPermission(e.target.value)
                                }
                                value={userPermission}
                              >
                                <option value="No Access" defaultValue>
                                  No Access
                                </option>
                                <option value="Normal">Normal</option>
                                <option value="Advanced">Advanced</option>
                                <option value="Admin">Admin</option>
                              </Form.Control>
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <div className="online-booking">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setAppointmentBooking(!appointmentBooking)
                                  }
                                  checked={appointmentBooking}
                                  // onClick={handleMenu}
                                />
                                <span className="slider round" />
                              </label>
                              Enable Appointments Booking
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Associate Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Associate Title"
                                name="text"
                                autoComplete="off"
                                onChange={(e) => setStaffTitle(e.target.value)}
                                value={staffTitle}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Notes</Form.Label>
                              <textarea
                                className="form-control text optional employee-modal-note"
                                rows="12"
                                placeholder="Add Notes"
                                name="employee[notes]"
                                id="employee_notes"
                                onChange={(e) => setNote(e.target.value)}
                                value={note}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Row>
                                <Form.Group className="col-md-6">
                                  <Form.Label>Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    default={startEmployementDate}
                                    value={startEmployementDate}
                                    onChange={(e) =>
                                      setStartEmployementDate(e.target.value)
                                    }
                                  />
                                </Form.Group>

                                <Form.Group className="col-md-6">
                                  <Form.Label>End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    min={startEmployementDate}
                                    onChange={(e) =>
                                      setEndEmployementDate(e.target.value)
                                    }
                                    value={endEmployementDate}
                                  />
                                </Form.Group>
                              </Row>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="1" title="Services">
                  <div className="add-services">
                    <p className="text-muted ">
                      Assign services this staff member can perform.
                    </p>
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group>
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                              <Form.Label className="sel-1">
                                Select All
                              </Form.Label>
                            </InputGroup.Prepend>
                          </Form.Group>
                        </div>

                        {/* {Object.keys(services).map((el) => (
                          <>
                            {' '}
                            <div className="col-sm-4">
                              <Form.Group>
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox />
                                  <Form.Label>
                                    {services[el].servicename}
                                  </Form.Label>
                                </InputGroup.Prepend>
                              </Form.Group>
                            </div>
                          </>
                        ))} */}
                        {services.map((item, i) => (
                          <div className="col-sm-4" key={i}>
                            <Form.Group>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  checked={item.checked}
                                  value={item._id}
                                  onClick={(e) => handleCheck(e, item)}
                                />
                                <Form.Label>{item.serviceName}</Form.Label>
                              </InputGroup.Prepend>
                            </Form.Group>
                          </div>
                        ))}
                      </div>
                    </Form>
                  </div>
                </Tab>

                <Tab eventKey="2" title="Commission">
                  <div className="add-commission">
                    <Form>
                      <div className="row">
                        <div className="col-sm-6">
                          <Form.Label>Service Commission</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) =>
                                setServiceCommission(e.target.value)
                              }
                              value={serviceCommission}
                            />
                          </InputGroup>
                          {errors.serviceCommission && (
                            <p className="error">{errors.serviceCommission}</p>
                          )}
                        </div>

                        {/* <div className="col-sm-4">
                          <Form.Label>PRODUCT COMMISSION</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              aria-label="Amount (to the nearest dollar)"
                            />
                          </InputGroup>
                        </div> */}

                        <div className="col-sm-6">
                          <Form.Label>Voucher Commission</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="0.00"
                              type="number"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={(e) =>
                                setVoucherCommission(e.target.value)
                              }
                              value={voucherCommission}
                            />
                          </InputGroup>
                          {errors.voucherCommission && (
                            <p className="error">{errors.voucherCommission}</p>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </Tab>
                <Tab eventKey="3" title="Location">
                  <div className="add-commission add-cms">
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group>
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox
                                checked={selectAllLocation}
                                onChange={handleSelectAllLocation}
                              />
                              <Form.Label className="sel-1">
                                Select All
                              </Form.Label>
                            </InputGroup.Prepend>
                          </Form.Group>
                        </div>

                        {locations.map((item, i) => (
                          <div className="col-sm-4" key={i}>
                            <Form.Group>
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  checked={item.checkedl}
                                  value={item._id}
                                  onClick={(e) => handleCheckLocation(e, item)}
                                />
                                <Form.Label>{item.locationName}</Form.Label>
                              </InputGroup.Prepend>
                            </Form.Group>
                          </div>
                        ))}
                      </div>
                    </Form>
                  </div>
                </Tab>
              </Tabs>

              <div className="row">
                <div className="col-md-12 ">
                  <div className="text-right mt">
                    <Button
                      disabled={disabled}
                      className="btn-perple mr-4"
                      type="submit"
                    >
                      Save
                    </Button>
                    <Link to="/partners/staff">
                      <Button variant="primary" className="btn-perple">
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default AddStaff;
