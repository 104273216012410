import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import PartnerWrapper from "../partnerwrapper/wrapper";
import UserPermission from "./userpermission";
import CloseStaff from "./closestaff";
import StaffMember from "./staffmember";
import { partnerInstance } from "../../../config/axios";
import PartnerContext from "../../../context/context";
import StaffWorkingHour from "./staffworkinghour";
import "./staff.css";
import history from "../../../config/history";
//import Loader from "../../loader/loader";

const PartnerStaff = () => {
  const [closedDate, setClosedDate] = useState([]);
  const [weekStart, setWeekStart] = useState("Monday");
  const [wr, setWr] = useState(false);
  const [per, setPer] = useState(false);
  const [st, setSt] = useState(false);
  const [hol, setHol] = useState(false);
  //const [loading, setLoading] = useState(false);
  //const [services, setServices] = useState([]);

  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  console.log("staff section", sections);

  useEffect(() => {
    const getAllShift = async () => {
      const allShiftData = await partnerInstance().get("/getPartnerAccount");
      const { code, partnerData } = allShiftData.data;
      if (code === 200) {
        setWeekStart(partnerData.weekStart);
      }
    };
    const fndWork =
      sections &&
      sections.findIndex((el) => el.sectionname === "Working Hours");
    if (fndWork > -1) {
      setWr(true);
    }
    const fndPer =
      sections &&
      sections.findIndex((el) => el.sectionname === "Permission Levels");
    if (fndPer > -1) {
      setPer(true);
    }
    const setSta =
      sections &&
      sections.findIndex((el) => el.sectionname === "Staff Members");
    if (setSta > -1) {
      setSt(true);
    }
    const setH =
      sections && sections.findIndex((el) => el.sectionname === "Closed Dates");
    if (setH > -1) {
      setHol(true);
    }

    getAllShift();
    // eslint-disable-next-line
  }, []);
  // const getServices = async () => {
  //   setLoading(true);
  //   const servicesData = await partnerInstance().get("/getServices");

  //   const { code, services: Services } = servicesData.data;
  //   if (code === 200) {
  //     //setServices(Services);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    // setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Staff Members"
        );
      if (findBook > -1) {
        //getServices();
      } else {
        history.push("/auth");
      }
    }
  }, []);

  // if (loading) {
  //   return (
  //     <PartnerWrapper>
  //       <Loader />
  //     </PartnerWrapper>
  //   );
  // }

  return (
    <PartnerWrapper heading="Associates">
      <div className="staff-page">
        <div className="container">
          {/* <h2 className="admin-title">Associates</h2> */}
          <div className="staff-content">
            <Tabs defaultActiveKey="0" id="uncontrolled-tab-example">
              {st && (
                <Tab eventKey="0" title="Associate Listing">
                  <StaffMember />
                </Tab>
              )}

              {wr && (
                <Tab eventKey="1" title="Shifts Definition">
                  <StaffWorkingHour
                    closedDate={closedDate}
                    setClosedDate={setClosedDate}
                    weekStart={weekStart}
                  />
                </Tab>
              )}
              {hol && (
                <Tab eventKey="2" title="Holidays">
                  <CloseStaff
                    closedDate={closedDate}
                    setClosedDate={setClosedDate}
                  />
                </Tab>
              )}

              {per && (
                <Tab eventKey="3" title="Permission level">
                  <UserPermission />
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};
export default PartnerStaff;
