import { Button, Tabs, Tab, ProgressBar, Form } from "react-bootstrap";
import cross from "../../../assets/cross2.png";
// import SignupLocation from "./signuplocation";

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
// import { Link } from 'react-router-dom';
// import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import PhoneInput from "react-phone-input-2";
// import ImageUploading from "react-images-uploading";
import PartnerWrapper from "../partnerwrapper/wrapper";
import PartnerContext from "../../../context/context";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import { validateLocationType } from "../../../function/validate";
// import Loader from "../../loader/loader";
import { useHistory } from "react-router-dom";
import publicIp from "react-public-ip";
import Helmet from "react-helmet";
import { StepCompletePrevent } from "./StepCompletePrevent";

const { google } = window;

const SignupStep2 = () => {
  // const [isSubmit , setIsSubmit] = useState();
  const Step1 = () => {
    history.push("/partners/business");
  };
  // const Step3 = () => {
  //   setIsSubmit(true);
  // };
  const Cross = () => {
    history.push("/partners/intro");
  };

  const his = useHistory();
  const { username, isStepComplete } = useContext(PartnerContext);
  StepCompletePrevent(isStepComplete);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [locationName, setLocationName] = useState("");
  const [locationContactNumber, setLocationContactNumber] = useState("");
  const [locationEmail, setLocationEmail] = useState("");
  const [businessTyp, setBusinessTyp] = useState("");
  const [, /*businessData */ setBusinessData] = useState([]);
  const [errors, setErrors] = useState({});
  const [timeData, setTimeData] = useState([]);
  const [address, setAddress] = useState("");
  const [, /*show*/ setShow] = useState(false);
  //const [/*billingShow*/, setBillingShow] = useState(false);
  const [, /*loading*/ setLoading] = useState(false);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [isValid, setValid] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [lat, setlat] = useState("");
  const [lng, setLang] = useState("");
  const [addressFormat, setAddressFormat] = useState("");
  const [appNo /*setAppNo*/] = useState("");
  const [region, setRegion] = useState("");
  const [images /*setImages*/] = useState([]);
  const [phoneCode, setPhoneCode] = useState("");
  const [currentPhoneCode, setCurrentPhoneCode] = useState("");
  // eslint-disable-next-line
  const [call, setCall] = useState(false);
  const [billingState, setBillingState] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingZip, setBillingZip] = useState("");
  const [addressFormatBilling, setaddressFormatBilling] = useState("");
  const [appNoBilling /*setAppNoBilling*/] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [, /*currentCountry*/ setCurrentCountry] = useState("");
  const [addLocation, setAddlocation] = useState();
  // const [refresh, setRefresh]=useState()

  const [notes /*setNotes*/] = useState("");
  // const maxNumber = 69;
  const [cordinates, setCordinates] = useState({
    lat: 27.173891,
    lng: 78.042068,
  });

  const getCountry = async () => {
    const ipv4 = await publicIp.v4();
    // const ipv4 = '5.8.159.255';
    fetch(`https://ipapi.co/${ipv4}/json`)
      .then((res) => res.json())
      .then((response) => {
        const val = response.country_name.toLowerCase();
        // setTimezone(response.timezone);
        setCurrentCountry(val);
        setCurrentPhoneCode(response.country_calling_code);
        // setCurrencyName(response.currency);
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  };
  // getCountry();
  const [availability, setAvailability] = useState([
    {
      Day: "Monday",
      startTime: "09:00AM",
      endTime: "06:00PM",
      enabled: true,
    },
    {
      Day: "Tuesday",
      startTime: "09:00AM",
      endTime: "06:00PM",
      enabled: true,
    },
    {
      Day: "Wednesday",
      startTime: "09:00AM",
      endTime: "06:00PM",
      enabled: true,
    },
    {
      Day: "Thursday",
      startTime: "09:00AM",
      endTime: "06:00PM",
      enabled: true,
    },
    {
      Day: "Friday",
      startTime: "09:00AM",
      endTime: "06:00PM",
      enabled: true,
    },
    {
      Day: "Saturday",
      startTime: "09:00AM",
      endTime: "06:00PM",
      enabled: true,
    },
    {
      Day: "Sunday",
      startTime: "",
      endTime: "",
      enabled: false,
    },
  ]);
  const mapRef = useRef(null);
  const handleAddressChange = async (address) => {
    setCountry("");
    setValid("typing");
    setAddress(address);
    setAddlocation(address);
  };

  const time = () => {
    const x = 5; // minutes interval
    const timeDataList = []; // time array
    let tt = 0; // start time
    const ap = ["AM", "PM"]; // AM-PM

    // loop to increment the time and push results in array
    for (let i = 0; tt < 24 * 60; i++) {
      const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      const mm = tt % 60; // getting minutes of the hour in 0-55 format
      timeDataList[i] = `${`0${hh % 12}`.slice(-2)}:${`0${mm}`.slice(-2)}${
        ap[Math.floor(hh / 12)]
      }`; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt += x;
    }
    setTimeData(timeDataList);
  };

  // const handleClose = () => {
  //   setShow(false);
  //   setBillingShow(false);
  //   setBillingZip("");
  //   setBillingCity("");
  //   setBillingState("");
  //   setaddressFormatBilling("");
  // };

  // const onChange = (imageList, addUpdateIndex) => {
  //   // data for submit
  //   setImages(imageList);
  // };

  const handleAddressSelect = async (address) => {
    setAddress(address);
    let countryL;
    let stateL;
    let cityL;
    let zipL;
    let regionL;
    const geocodeAddress = await geocodeByAddress(address);
    const typeEstablishment = geocodeAddress[0].types.indexOf("establishment");
    const typeSubLocality = geocodeAddress[0].types.indexOf("sublocality");
    if (typeEstablishment !== -1) setValid("success");
    if (typeSubLocality !== -1) setValid("success");
    const getLatLang = await getLatLng(geocodeAddress[0]);
    setCordinates(getLatLang);
    const addressComponent = geocodeAddress[0].address_components.reverse();
    if (addressComponent && addressComponent.length > 0) {
      const zipCheck = addressComponent[0].types[0] === "postal_code";
      if (zipCheck) {
        zipL =
          addressComponent[0].types[0] === "postal_code" &&
          addressComponent[0].long_name;
        countryL = addressComponent[1] && addressComponent[1].long_name;
        stateL = addressComponent[2] && addressComponent[2].long_name;
        cityL = addressComponent[3] && addressComponent[3].long_name;
        regionL = `${addressComponent[2].long_name},${addressComponent[3].long_name}`;
      } else {
        zipL = "";
        countryL = addressComponent[0] && addressComponent[0].long_name;
        stateL = addressComponent[1] && addressComponent[1].long_name;
        cityL = addressComponent[2] && addressComponent[2].long_name;
        regionL = `${addressComponent[1].long_name},${addressComponent[2].long_name}`;
      }

      const add = geocodeAddress[0].formatted_address;
      setlat(getLatLang.lat);
      setLang(getLatLang.lng);
      setCountry(countryL);
      setState(stateL);
      setCity(cityL);
      setRegion(regionL);
      setZip(zipL);
      setAddressFormat(add);
      setBillingZip(zipL);
      setBillingCity(cityL);
      setBillingState(stateL);
      setaddressFormatBilling(add);
    }
  };
  useEffect(() => {
    getCountry();
    setLoading(true);

    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getBusiness();

        time();
      } else {
        his.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const getBusiness = async () => {
    const partnersData = await partnerInstance().get("/getBusinessType");
    const { code, businessType } = partnersData.data;

    const accountData = await partnerInstance().get("/getPartnerAccount");
    const { partnerData } = accountData.data;
    if (code === 200) {
      setBusinessData(businessType);
      setCompanyName(partnerData.companyName);
      setBusinessTyp(partnerData.businessType);
      if (partnerData.partnerLocation.length > 0) {
        setLocationName(partnerData.partnerLocation[0].locationName);
        setLocationContactNumber(
          partnerData.partnerLocation[0].locationContactNumber
        );
        setLocationEmail(partnerData.partnerLocation[0].locationEmail);
        setPhoneCode(partnerData.partnerLocation[0].phoneCode);
        setLang(partnerData.partnerLocation[0].lng);
        setlat(partnerData.partnerLocation[0].lat);
        setCordinates({
          lat: partnerData.partnerLocation[0].lat,
          lng: partnerData.partnerLocation[0].lng,
        });
        setAddress(partnerData.partnerLocation[0].region);
        setAddlocation(partnerData.partnerLocation[0].region);
        setAvailability(partnerData.partnerLocation[0].availability);
      }
      setLoading(false);
    } else {
      setBusinessData([]);
    }
  };

  const selectDays = (data, e) => {
    console.log("Data", data);
    const type = data.Day;
    const index = availability.findIndex((emp) => emp.Day === type);
    const employees = [...availability];
    if (e.target.name === "start") {
      employees[index].startTime = e.target.value;
    } else if (e.target.name === "end") {
      employees[index].endTime = e.target.value;
    } else if (employees[index].enabled) {
      employees[index].enabled = false;
    } else {
      employees[index].enabled = true;
    }
    setAvailability(employees);
  };

  const HandleSave = async (e) => {
    e.preventDefault();
    const payload = {
      availability,
      locationName,
      locationEmail,
      locationContactNumber,
      businessTyp,
      city,
      zip,
      country,
      state,
      addressFormat,
      lat,
      lng,
      phoneCode,
      appNo,
      region,
      billingDetails: [
        {
          city: billingCity,
          state: billingState,
          address: addressFormatBilling,
          code: billingZip,
          compnay: companyName,
          note: notes,
          appNo: appNoBilling,
        },
      ],
    };

    const validation = validateLocationType({
      locationName,
      locationEmail,
      locationContactNumber,
      businessTyp,
      addLocation,
    });

    setErrors(validation.errors);
    if (!validation.isValid) {
      window.scrollTo(0, 0);
      return;
    }

    const saveLocations = await partnerInstance().post(
      "/updatePartnerLocation",
      payload
    );

    if (
      saveLocations &&
      saveLocations.data &&
      saveLocations.data.partnerLocation
    ) {
      if (images !== []) {
        const lastItem =
          saveLocations.data.partnerLocation[
            saveLocations.data.partnerLocation.length - 1
          ];
        await uploadTaskFiles(lastItem._id);
        // his.push("/partners/photo");
      } else {
        his.push("/partners/photo");
      }
    }

    // if (!locationName) {
    //   toast.error('Validation fail', { containerId: 'B' })
    // } else {

    // }
  };
  // if (props.isSubmit === true) {
  //   HandleSave()
  // }

  const Map = useCallback(async () => {
    try {
      const map = new google.maps.Map(mapRef.current, {
        zoom: 16,
        center: cordinates,
      });
      new google.maps.Marker({ position: cordinates, map });
    } catch (e) {
      console.log("error", e);
    }
  }, [cordinates]);

  useEffect(() => {
    Map();
  }, [cordinates, Map, call]);

  const uploadTaskFiles = async (id) => {
    if (images !== []) {
      const data = new FormData();
      images.forEach((el) => {
        data.append("file", el.file);
      });
      data.append("_id", id);
      const payload = {
        _id: id,
      };

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const image = await partnerInstance().post(
        "/uploadpic",
        data,
        config,
        payload
      );

      if (image.data.code === 200) {
        his.push("/partners/photo");
      }
    }
  };

  // const setModal = () => {
  //   setShow(false);
  //   setCall(true);
  // };
  // const setModalTwo = () => {
  //   setBillingShow(false);
  // };

  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form location">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3>Location & Opening Hours</h3>
                    <div className="signup-progress">
                      <ProgressBar now={24} />
                    </div>
                  </div>
                  <div className="signup-body">
                    <div className="signup-location">
                      <Tabs
                        defaultActiveKey="add-new"
                        transition={false}
                        id="noanim-tab-example"
                      >
                        <Tab eventKey="add-new" title="Add New Location">
                          {/* <SignupLocation isSubmit={isSubmit} /> */}
                          <div className="location-page">
                            {/* <div className="container"> */}
                            {/* <div className="row"> */}
                            {/* <div className="col-md-12">
                <h2 className="admin-title">Add new location</h2>
              </div> */}
                            {/* <div className="col-md-12"> */}
                            <Form>
                              <div className="location-upper">
                                <div className="row">
                                  <div className="col-md-12">
                                    <Form.Group controlId="formBasicloginone">
                                      <Form.Label>Location name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Location name"
                                        name="locationName"
                                        onChange={(e) =>
                                          setLocationName(e.target.value)
                                        }
                                        value={locationName}
                                        autoComplete="off"
                                      />
                                      {errors.locationName && (
                                        <p className="error">
                                          {errors.locationName}
                                        </p>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Form.Group controlId="formBasicPassword">
                                      <Form.Label>
                                        Location contact number
                                      </Form.Label>
                                      <div className="number-fields location-field">
                                        <PhoneInput
                                          country="us"
                                          value={currentPhoneCode}
                                          onChange={(e) =>
                                            setPhoneCode(`+${e}`)
                                          }
                                          readonly
                                        />
                                        <Form.Control
                                          className="location-num"
                                          type="number"
                                          placeholder="Location contact number"
                                          name="locationContactNumber"
                                          onChange={(e) =>
                                            setLocationContactNumber(
                                              e.target.value
                                            )
                                          }
                                          value={locationContactNumber}
                                          autocomplete="off"
                                        />
                                      </div>
                                      {errors.locationContactNumber && (
                                        <p className="error">
                                          {errors.locationContactNumber}
                                        </p>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Form.Group controlId="formBasicloginone">
                                      <Form.Label>
                                        Location email address
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Location email address"
                                        name="locationEmail"
                                        onChange={(e) =>
                                          setLocationEmail(e.target.value)
                                        }
                                        value={locationEmail}
                                        autoComplete="off"
                                      />
                                      {errors.locationEmail && (
                                        <p className="error">
                                          {errors.locationEmail}
                                        </p>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Form.Group controlId="formBasicloginone">
                                      <Form.Label>Add your location</Form.Label>
                                      <PlacesAutocomplete
                                        value={address}
                                        onChange={(e) => {
                                          handleAddressChange(e);
                                        }}
                                        onSelect={handleAddressSelect}
                                        types={["establishment"]}
                                      >
                                        {({
                                          getInputProps,
                                          suggestions,
                                          getSuggestionItemProps,
                                        }) => (
                                          <div>
                                            <Form.Control
                                              {...getInputProps({
                                                placeholder:
                                                  "Add Your Business Location",
                                                className: `location-search-input-${
                                                  isValid === "success"
                                                    ? "success"
                                                    : isValid === "error"
                                                    ? "alert"
                                                    : ""
                                                }`,
                                              })}
                                            />
                                            {isValid === "error" && (
                                              <p className="error-msg">
                                                Please enter a valid exact
                                                address
                                              </p>
                                            )}
                                            <div className="autocomplete">
                                              {suggestions.map((suggestion) => {
                                                const className =
                                                  suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                  ? {
                                                      backgroundColor:
                                                        "#fafafa",
                                                      cursor: "pointer",
                                                    }
                                                  : {
                                                      backgroundColor:
                                                        "#ffffff",
                                                      cursor: "pointer",
                                                    };
                                                return (
                                                  <div
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className,
                                                        style,
                                                      }
                                                    )}
                                                  >
                                                    <span>
                                                      {suggestion.description}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        )}
                                      </PlacesAutocomplete>
                                      {errors.addLocation && (
                                        <p className="error">
                                          {errors.addLocation}
                                        </p>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                              <div className="location-lower">
                                <div className="row">
                                  <div className="col-md-12">
                                    {country && (
                                      <div className="location-detail">
                                        <p
                                          role="presentation"
                                          onClick={() => setShow(true)}
                                          className="edit-bill-btn"
                                        >
                                          Edit
                                        </p>
                                        <div className="location-info">
                                          <label>Address</label>
                                          <p>{addressFormat}</p>
                                        </div>

                                        <div className="location-info">
                                          <label>Apt./Suite etc</label>

                                          <p>{appNo}</p>
                                        </div>

                                        <div className="location-info">
                                          <label>State</label>
                                          <p>{state}</p>
                                        </div>

                                        <div className="location-info">
                                          <label>City</label>
                                          <p>{city}</p>
                                        </div>

                                        <div className="location-info">
                                          <label>Region</label>
                                          <p>{region}</p>
                                        </div>

                                        <div className="location-info">
                                          <label>Postal Code</label>

                                          <p>{zip}</p>
                                        </div>

                                        <div className="location-info">
                                          <label>Country</label>
                                          <p>{country}</p>
                                        </div>

                                        {/* <div className="location-info">
                              <label>Directions</label>
                              <p>Add</p>
                            </div> */}
                                      </div>
                                    )}
                                    <div className=" setup-location">
                                      <Form.Group controlId="formBasicloginone">
                                        <Form.Label>Map location</Form.Label>
                                        <div
                                          id="map_canvas"
                                          style={{ height: 300 }}
                                          ref={mapRef}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="availability-location">
                                      <h4>Availability</h4>
                                      <div className="availability-box-container">
                                        {availability.map((el) => (
                                          <>
                                            <div className="availability-box">
                                              <div className="online-booking">
                                                {el.Day}
                                                <label className="switch">
                                                  <input
                                                    type="checkbox"
                                                    name="checked"
                                                    value={el.Day}
                                                    onChange={(e) =>
                                                      selectDays(el, e)
                                                    }
                                                    checked={el.enabled}
                                                  />
                                                  <span className="slider round" />
                                                </label>
                                              </div>
                                              <div className="availability-select">
                                                <Form.Control
                                                  as="select"
                                                  name="start"
                                                  onChange={(e) =>
                                                    selectDays(el, e)
                                                  }
                                                  value={el.startTime}
                                                >
                                                  {timeData.map((ele) => (
                                                    <option value={ele}>
                                                      {ele}
                                                    </option>
                                                  ))}
                                                </Form.Control>
                                              </div>
                                              <div className="availability-select">
                                                <Form.Control
                                                  as="select"
                                                  name="end"
                                                  onChange={(e) =>
                                                    selectDays(el, e)
                                                  }
                                                  value={el.endTime}
                                                >
                                                  {timeData.map((item) => (
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  ))}
                                                </Form.Control>
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>

                            {/* <div className="service-card"> */}
                            {/* <div className="service-body"> */}
                            {/* <Form>
                  <div className="row"> */}
                            {/* <div className="col-md-6">

                        </div>
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6">

                        </div> */}
                            {/* <div className="col-md-6">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Business type</Form.Label>
                            <Form.Control
                              as="select"
                              placeholder="Business Type"
                              value={businessTyp}
                              onChange={(e) => setBusinessTyp(e.target.value)}
                            >
                              {businessData.map((item) => (
                                <option value={item._id}>
                                  {item.businessname}
                                </option>
                              ))}
                            </Form.Control>
                            {errors.businessTyp && (
                              <p className="error">{errors.businessTyp}</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-md-12">

                        </div> */}

                            {/* <div className="col-md-12">
                          <div className="billing-location">
                            <h4>Billing Details</h4>
                            <p>
                              These details will appear on the client’s invoice
                              for this location as well as the information you’ve
                              configured in your Invoice Template settings.
                            </p>
                            <div className="billing-box">
                              <p
                                role="presentation"
                                onClick={() => setBillingShow(true)}
                                className="edit-bill-btn"
                              >
                                Edit
                              </p>
                              <div className="location-detail">
                                <div className="location-info">
                                  <label>Company name</label>
                                  <p>{companyName}</p>
                                </div>
                                <div className="location-info">
                                  <label>Address</label>
                                  <p>{addressFormatBilling}</p>
                                  {/* <p onClick={() => setShow(true)} className="add-btn">
                                    <i className="fa fa-plus" />
                                    {' '}
                                    Add
                                  </p> *}
                                </div>
                                <div className="location-info">
                                  <label>Notes</label>
                                  <p>{notes}</p>
                                  {/* <p onClick={() => setShow(true)} className="add-btn">
                                    <i className="fa fa-plus" />
                                    {' '}
                                    Add
                                  </p> *}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                            {/* </div>
                </Form> */}

                            {/* <div className="">
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="uploaad">
                            <h4>Upload Photos</h4>
                            <div className="upload__image-wrapper">
                              <div className="uploadimg">
                                <i className="fa fa-upload" aria-hidden="true" />
                              </div>
                              <div>
                                <button
                                  role="presentation"
                                  style={isDragging ? { color: 'red' } : null}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Click or Drop here
                                </button>
                              </div>
                              <button
                                onClick={onImageRemoveAll}
                                className="remove-image"
                              >
                                Remove all images
                              </button>
                            </div>
                            <div className="image-item-section">
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image.data_url} alt="" width="100" />
                                  <div className="image-item__btn-wrapper">
                                    <button onClick={() => onImageUpdate(index)}>
                                      Update
                                    </button>
                                    <button
                                      onClick={() => onImageRemove(index)}
                                      className="remove-wrapper-btn delete-btn"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div> */}

                            {/* <div className=" text-right">
                      <Button onClick={HandleSave}>Save</Button>
                    </div> */}
                            {/* </div> */}
                            {/* </div> */}
                          </div>
                        </Tab>
                        {/* <Tab eventKey="remove" title="Remove Location">
                          Remove Location
                        </Tab> */}
                      </Tabs>
                    </div>
                  </div>
                </div>
                {/* <div className="signup-right"> */}
                <div className="signup-footer-btn">
                  <Button type="submit" onClick={Step1}>
                    Back
                  </Button>
                  <Button type="submit" onClick={(e) => HandleSave(e)}>
                    Next
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupStep2;
