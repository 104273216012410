import React, { useState, useEffect, useContext, useRef } from "react";
import { Tabs, Tab, ProgressBar, Button, Form, Table } from "react-bootstrap";
import { useHistory } from "react-router";
import PartnerWrapper from "../partnerwrapper/wrapper";
import cross from "../../../assets/cross2.png";
import { partnerInstance } from "../../../config/axios";
import { toast } from "react-toastify";
import { validateTax } from "../../../function/validate";
import Card from "react-bootstrap/Card";
//import { useLocation } from "react-router-dom";
import SignupInvoiceSeqencing from "./signupInvoiceSeqencing";
import PartnerContext from "../../../context/context";
import InputGroup from "react-bootstrap/InputGroup";
import { validateInvoice } from "../../../function/validate";
import Helmet from "react-helmet";
import { StepCompletePrevent } from "./StepCompletePrevent";

const SignupStep4 = () => {
  const { username, isStepComplete } = useContext(PartnerContext);
  StepCompletePrevent(isStepComplete);

  const [taxName, setTaxName] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [errors, setErrors] = useState({});
  const [list, setList] = useState([]);

  const { sections } = username;
  const titleRef = useRef(sections);
  const [title, setTitle] = useState("");
  const [customLine1, setCustomLine1] = useState("");
  const [customLine2, setCustomLine2] = useState("");
  const [receiptFooter, setReceiptFooter] = useState("");
  const [automaticPrint, setAutomaticPrint] = useState(false);
  const [clientAddress, setClientAddress] = useState(false);
  const [selectedTab, setSelectedTab] = useState("taxes");

  const [ctaxName, setCTaxName] = useState("");

  const history = useHistory();
  const Cross = () => {
    history.push("/partners/intro");
  };
  const step3 = () => {
    history.push("/partners/photo");
  };

  useEffect(() => {
    getImg();
  }, []);
  const getImg = async () => {
    const accountData = await partnerInstance().get("/getPartnerAccount");
    if (
      accountData.data.partnerData.tax &&
      accountData.data.partnerData.tax.length
    ) {
      setTaxName(accountData.data.partnerData.tax[0].taxname);
      setTaxRate(accountData.data.partnerData.tax[0].taxrate);
      setCTaxName(accountData.data.partnerData.tax[0].taxname);
      setList(accountData.data.partnerData.tax);
    }
  };

  const addTaxx = async (e) => {
    e.preventDefault();

    //console.log('status ::::>>',status)
    // if(status)
    // {
    setSelectedTab("taxes");
    const payload = {
      taxName,
      taxRate,
    };
    const validation = validateTax({
      taxName,
      taxRate,
    });

    setErrors(validation.errors);
    if (!validation.isValid && list.length === 0) {
      return;
    }
    const status = await addInvoiceTemplate(e);
    if (status) {
      if (taxName !== ctaxName && taxName) {
        const taxData = await partnerInstance().post("/addTax", payload);
        const { code, msg } = taxData.data;
        if (code === 200) {
          history.push("/partners/payment");
        } else if (code === 201) {
          toast.error(msg, { containerId: "B" });
        } else {
          toast.error(msg, { containerId: "B" });
        }
      } else {
        history.push("/partners/payment");
      }
    } else {
      setSelectedTab("Invoice");
    }

    // else
    // {
    //   setSelectedTab('Invoice')
    // }
    // setSelectedTab('taxes')
  };
  //----------------signup invoice-------------------
  useEffect(() => {
    const getInvoice = async () => {
      //setLoading(true);
      const invoiceData = await partnerInstance().get("/getInvoice");
      const { code, invoice } = invoiceData.data;
      if (code === 200) {
        setTitle(invoice.title);
        setCustomLine1(invoice.customline1);
        setCustomLine2(invoice.customline2);
        setReceiptFooter(invoice.footer);
        setClientAddress(invoice.clientaddress);
        setAutomaticPrint(invoice.automaticprint);
        //setLoading(false);
      }
    };

    //setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getInvoice();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const addInvoiceTemplate = async (e) => {
    e.preventDefault();
    const payload = {
      title,
      customline1: customLine1,
      customline2: customLine2,
      footer: receiptFooter,
      automaticprint: automaticPrint,
      clientaddress: clientAddress,
    };
    const validation = validateInvoice({
      title,
      customLine1,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return false;
    }
    const updateTemplate = await partnerInstance().put("/editInvoice", payload);
    const { code, msg } = updateTemplate.data;
    if (code === 200) {
      return true;
      //toast.success(msg, { containerId: "B" });
      //history.push("/partners/payment");
      // if (info) {
      //   history.push("/partners/availablity");
      // } else {
      //   history.push("/partners/setup");
      // }
      // clearValues();
    } else {
      toast.error(msg, { containerId: "B" });
      return false;
    }
  };
  console.log(selectedTab);
  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form taxes">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3> Taxes & Invoice</h3>
                    <div className="signup-progress">
                      <ProgressBar now={48} />
                    </div>
                  </div>
                  <div className="signup-body">
                    <div className="signup-location signup-taxes">
                      <Tabs
                        // defaultActiveKey="taxes"
                        activeKey={selectedTab}
                        onSelect={(k) => setSelectedTab(k)}
                        transition={false}
                        id="noanim-tab-example"
                      >
                        <Tab eventKey="taxes" title="Taxes">
                          <div className="signuptaxes">
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <Form.Group>
                                    <Form.Label>
                                      Tax name
                                      <span className="red-color">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      size="lg"
                                      type="text"
                                      placeholder="Tax name"
                                      onChange={(e) =>
                                        setTaxName(e.target.value)
                                      }
                                      value={taxName}
                                    />
                                    {errors.taxName && (
                                      <p className="error">{errors.taxName}</p>
                                    )}
                                  </Form.Group>
                                </div>
                                <div className="col-md-6">
                                  <Form.Group className="ml-auto">
                                    <Form.Label>
                                      Tax Rate
                                      <span className="red-color">*</span>
                                      (%)
                                    </Form.Label>
                                    <Form.Control
                                      size="lg"
                                      type="number"
                                      placeholder="Tax rate (%)"
                                      onChange={(e) =>
                                        setTaxRate(e.target.value)
                                      }
                                      value={taxRate}
                                    />
                                    {errors.taxRate && (
                                      <p className="error">{errors.taxRate}</p>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>
                            </Form>
                          </div>
                          {list ? (
                            <div className="partner-table">
                              <Table responsive="lg">
                                <thead>
                                  <tr>
                                    <th className="text-left">#</th>
                                    <th className="text-left">Tax Name</th>
                                    <th className="text-left">Tax Rate</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {list &&
                                    list.map((el, i) => {
                                      i++;
                                      return (
                                        <tr>
                                          <td className="text-left">{i}</td>
                                          <td>{el.taxname}</td>
                                          <td>{el.taxrate}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            ""
                          )}
                        </Tab>

                        <Tab eventKey="Invoice" title="Invoice">
                          {/* <SignupInvoice /> */}
                          <div className="invoice staff-page signupinvoive">
                            <div className="invoice-tab-content">
                              <Tabs defaultActiveKey="0" id="invoice-tab">
                                <Tab eventKey="0" title="Invoice Configuration">
                                  <Form onSubmit={addInvoiceTemplate}>
                                    <Card className="inv-12">
                                      <Card.Img variant="top" />
                                      <Card.Body>
                                        <Card.Text>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Form.Group>
                                                <Form.Label>
                                                  Invoice Title
                                                  <span className="red-color">
                                                    *
                                                  </span>
                                                </Form.Label>

                                                <Form.Control
                                                  size="lg"
                                                  type="text"
                                                  placeholder="Invoice"
                                                  onChange={(e) =>
                                                    setTitle(e.target.value)
                                                  }
                                                  value={title}
                                                />
                                                {errors.title && (
                                                  <p className="error">
                                                    {errors.title}
                                                  </p>
                                                )}
                                              </Form.Group>
                                            </div>

                                            <div className="col-md-6">
                                              <Form.Group>
                                                <Form.Label>
                                                  Invoice Line 1
                                                </Form.Label>

                                                <Form.Control
                                                  size="lg"
                                                  type="text"
                                                  placeholder=""
                                                  onChange={(e) =>
                                                    setCustomLine1(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={customLine1}
                                                />
                                                {errors.customLine1 && (
                                                  <p className="error">
                                                    {errors.customLine1}
                                                  </p>
                                                )}
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                              <Form.Group>
                                                <Form.Label>
                                                  Invoice Line 2
                                                </Form.Label>

                                                <Form.Control
                                                  size="lg"
                                                  type="text"
                                                  placeholder=""
                                                  onChange={(e) =>
                                                    setCustomLine2(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={customLine2}
                                                />
                                              </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                              <Form.Group>
                                                <Form.Label>
                                                  Invoice Footer
                                                </Form.Label>

                                                <Form.Control
                                                  size="lg"
                                                  type="text"
                                                  placeholder=""
                                                  onChange={(e) =>
                                                    setReceiptFooter(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={receiptFooter}
                                                />
                                              </Form.Group>
                                            </div>

                                            <div className="col-md-12">
                                              <Form.Group>
                                                <InputGroup.Prepend>
                                                  <div className="online-booking">
                                                    <label className="switch">
                                                      <input
                                                        type="checkbox"
                                                        checked={automaticPrint}
                                                        onChange={(e) =>
                                                          setAutomaticPrint(
                                                            e.target.checked
                                                          )
                                                        }
                                                      />
                                                      <span className="slider round" />
                                                    </label>
                                                  </div>{" "}
                                                  <Form.Label className="">
                                                    Print receipts upon sale
                                                    completion
                                                  </Form.Label>
                                                </InputGroup.Prepend>
                                              </Form.Group>
                                            </div>

                                            <div className="col-md-12">
                                              <Form.Group>
                                                <InputGroup.Prepend>
                                                  <div className="online-booking">
                                                    <label className="switch">
                                                      <input
                                                        type="checkbox"
                                                        checked={clientAddress}
                                                        onChange={(e) =>
                                                          setClientAddress(
                                                            e.target.checked
                                                          )
                                                        }
                                                      />
                                                      <span className="slider round" />
                                                    </label>
                                                  </div>
                                                  <Form.Label className="">
                                                    Customer contact visible on
                                                    invoices
                                                  </Form.Label>
                                                </InputGroup.Prepend>
                                              </Form.Group>
                                            </div>
                                          </div>
                                        </Card.Text>
                                      </Card.Body>

                                      <div className="account-save mt-2 col-md-12 mb-4">
                                        {/* <Button
                                          type="submit"
                                          className="btn btn-perple"
                                        >
                                          Save
                                        </Button> */}
                                      </div>
                                    </Card>
                                  </Form>
                                </Tab>

                                <Tab eventKey="1" title="Invoice Sequencing">
                                  <SignupInvoiceSeqencing />
                                </Tab>
                              </Tabs>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                  {/* {list ? (
                    <div>
                      <Table responsive="lg">
                        <thead>
                          <tr>
                            <th className="text-left">#</th>
                            <th className="text-left">Tax Name</th>
                            <th className="text-left">Tax Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list &&
                            list.map((el, i) => {
                              i++;
                              return (
                                <tr>
                                  <td>{i}</td>
                                  <td>{el.taxname}</td>
                                  <td>{el.taxrate}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
                {/* <div className="signup-right"> */}
                <div className="signup-footer-btn">
                  <Button type="submit" onClick={() => step3()}>
                    Back
                  </Button>
                  <Button type="submit" onClick={(e) => addTaxx(e)}>
                    Next
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupStep4;
