import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dropdown, Form } from "react-bootstrap";
//import ReactStars from "react-stars";
import Pagination from "react-bootstrap/Pagination";
import ReactPaginate from "react-paginate";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import publicIp from "react-public-ip";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import history from "../../config/history";
//import cover from "../../assets/store/cover.jpg";
import store from "../../assets/store/no-image.jpg";
import "./store.css";
import { websiteInstance, userInstance } from "../../config/axios";
import Loader from "../loader/loader";
import { server } from "../../config/keys";
//import venue from "../../assets/venue1.jpg";
import pagearrow from "../../assets/arrow-set.png";
//import EmptyImage from "../../assets/no-image.jpg";

const { google } = window;
const StoreListing = () => {
  const { state } = useLocation();
  const mapRef = useRef(null);
  const [cordinates, setCordinates] = useState({
    lat: 26.88,
    lng: 80.93,
  });
  
  const active = 2;
  const items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const [menu, setMenu] = useState(false);
  const [partners, setPartners] = useState([]);
  const [limit] = useState(6);
  //const [index, setIndex] = useState(1);
  const [skip, setSkip] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const value = queryString.parse(window.location.search);
  const { categoryid, businessname } = value;

  const getBusinessPartner = useCallback(async () => {
    setLoading(true);
    const payload = {
      categoryid,
      businessname,
      skip,
      limit,
    };
    const partnersData = await websiteInstance().post(
      "/getPartnersBycategory",
      payload
    );
 
    const { code, businesspartners, total } = partnersData.data;
  
    if (code === 200) {
      setPartners(businesspartners);
      setTotalCount(total);
      Map(businesspartners);
      setLoading(false);
    } else {
      setPartners([]);
      Map(null);
      setTotalCount(0);
    }
    // eslint-disable-next-line
  }, [categoryid, businessname, skip, limit]);

  useEffect(() => {
    if (state) {
      setPartners(state.data);
      setTotalCount(state.data.length);
      Map(state.data);
    } else {
      getBusinessPartner();
    }
    getcurrentLocation();
    // eslint-disable-next-line
  }, [menu, skip, limit, getBusinessPartner, state]);

  const getcurrentLocation = async () => {
    const ipv4 = await publicIp.v4();
    fetch(`https://ipapi.co/${ipv4}/json`)
      .then((res) => res.json())
      .then((response) => {
        setCordinates({ lat: response.latitude, lng: response.longitude });
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  };

  const handleMenu = (e) => {
    setMenu(e.target.checked);
    if (e.target.checked) {
      Map(partners);
    }
  };

  // const set = (data) => {
  //   history.push(`/storedetail/?partnerid=${data}`);
  // + `<button onClick=${history.push(`/storedetail/?partnerid=${el._id}`)}>Go To Store</button>`
  // };

  // const lastItem = (data) => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //   setSkip((data > 0 ? ((data - 1) * limit) : 0));
  //   setIndex(data);
  // };

  const Map = (info) => {

    try {
      const map = new google.maps.Map(mapRef.current, {
        zoom: info.length === 0 ? 6 : 11,
        center: info.length === 0 && state ? state.getLatLang : cordinates,
      });
      info.forEach((el) => {
        if (el.partnerLocation.length > 0) {
          el.partnerLocation.forEach((item) => {
            if (item.lat && item.lng) {
              const marker = new google.maps.Marker({
                position: new google.maps.LatLng(item.lat, item.lng),
                map,
                title: el.firstname,
                data: item,
                id: el._id,
              });
              const infowindow = new google.maps.InfoWindow({
                content:
                  '<div id="content">' +
                  `<a href=/storedetail/?partnerid=${el._id}>` +
                  '<div id="bodyContent">' +
                  '<div id="popup-img">' +
                  `<img src= ${
                    el.photo ? `${server}/${el.photo}` : store
                  }  alt="store" />` +
                  "</div>" +
                  '<div id="popup-content">' +
                  `<h1 id="firstHeading" class="firstHeading">${
                    el.companyName ? el.companyName : "N/A"
                  }</h1>` +
                  `<p>${item.locationName},${item.country}` +
                  "</p>" +
                  `<p>${item.phoneCode},${item.locationContactNumber}` +
                  "</p>" +
                  "</div>" +
                  "</div>" +
                  "</a>" +
                  "</div>",
              });
              marker.addListener("click", () => {
                infowindow.open(map, marker);
              });
            }
          });
        }
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);

  const calculateCommission = (data) => {
  
    let average = 0;
    let avg = 0;
    const { length } = data.review;
    if (length > 0) {
      data.review.forEach((el) => {
        average += el.rate;
      });
      avg = average / length;
    } else {
      avg = 0;
    }
    return Math.round(avg);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const setFilter = async (e) => {
    const old = [...partners];
    if (e === "topRated") {
      await Promise.all(
        old.map(async (el) => {
          el.avg = await calculateCommission(el);
        })
      );
      const sortingTop = old.sort((a, b) => b.avg - a.avg);
      setPartners(sortingTop);
    } else if (e === "lowest") {
      await Promise.all(
        old.map(async (el) => {
          el.avg = await calculateCommission(el);
        })
      );
      const sortingLow = old.sort((a, b) => a.avg - b.avg);
      setPartners(sortingLow);
    } else {
      const sortingNew = old.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setPartners(sortingNew);
    }
  };

  return (
    <div className="store-listing">
      {/* <div
        className="store-listing-cover"
      style={{ backgroundImage: `url(${ cover })` }}
      > */}
      {/* <div className="container">
          <h2>{value.businessname}</h2>
        </div> */}
      {/* </div> */}

      <div className="store-listing-content">
        <div className="container">
          <div className="store-listing-filter">
            <div className="store-search-result">
              <div className="breadcrumb-box">
                <Breadcrumb>
                  <Breadcrumb.Item href="#">London</Breadcrumb.Item>
                  <Breadcrumb.Item active> Cleaning Services</Breadcrumb.Item>
                </Breadcrumb>
                <div className=" d-flex align-items-center justify-content-between" />
              </div>
              <p>
                <span className="counter-text">{totalCount}</span>
                Results Found in {value.businessname}
              </p>
            </div>

            <div className="store-filter-box">
              <div className="online-booking">
                Map
                <label className="switch">
                  <input type="checkbox" checked={menu} onClick={handleMenu} />
                  <span className="slider round" />
                </label>
              </div>

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic-nearest">
                  Filter By Rating
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilter("topRated")}>
                    Top Rated
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilter("newest")}>
                    Newest
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilter("lowest")}>
                    Lowest
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* <Dropdown>
                <Dropdown.Toggle id="dropdown-basic-gender">
                  Men and Women
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/">Men and Women</Dropdown.Item>
                  <Dropdown.Item href="/">Men only</Dropdown.Item>
                  <Dropdown.Item href="/">Women only</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>

          <div className={`store-location ${menu ? "show" : ""}`}>
            <Form.Group controlId="formBasicloginone">
              <Form.Label>Map location</Form.Label>
              <div id="map_canvas" style={{ height: 300 }} ref={mapRef} />
            </Form.Group>
          </div>
          {/* {partners.length === 0 && (
          <h1 className="placeholder-text">No Result Found</h1>
          )} */}

          <div className="store-listing-view">
            <Store partners={partners} loading={loading} />
          </div>
          <div className="store-pagination">
            {totalCount >= limit && (
              <div className="row">
                <div className="col-md-12">
                  <div className="pagination-section">
                    <ReactPaginate
                      previousLabel={<img src={pagearrow} alt='' />}
                      nextLabel={<img src={pagearrow} alt='' />}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName="pagination-chk"
                      previousLinkClassName="pagination__link"
                      nextLinkClassName="pagination__link"
                      disabledClassName="pagination__link--disabled"
                      activeClassName="pagination__link--active"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreListing;

const Store = ({ partners, loading }) => {
  const [copyPartners, setCopyPartners] = useState([]);
  //const [ratingWord,setratingWord]=useState();
  

  const getWishlistData = useCallback(async () => {
    const response = await userInstance().get("/getwishlist");
    const { code, wishLists } = response.data;
    const old = [...partners];
    if (code === 200) {
      wishLists.forEach((wishList) => {
        old.forEach((partner) => {
          if (partner._id === wishList._id) {
            partner.addToFavorite = true;
          }
        });
      });
      setCopyPartners(old);
    }
  }, [partners]);

  const addToFavorite = async (e, partnerId) => {
    e.preventDefault();
    copyPartners.forEach((el) => {
      if (el.addToFavorite) {
        if (el._id.toString() === partnerId) {
          el.addToFavorite = false;
        }
      }
    });
    const payload = {
      partnerId,
    };
    const response = await userInstance().post("/updateWishlist", payload);
    const { code, msg } = response.data;
    if (code === 200) {
      getWishlistData();
      toast.success(msg, { containerId: "B" });
    }
  };
  const helperFunction = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  useEffect(() => {
    getWishlistData();
  }, [getWishlistData]);

  const Dataa = copyPartners.length > 0 ? copyPartners : partners;

  const calculateAverage = (data,type) => {
    let averageWord='N/A'
    let average = 0;
    let avg = 0;
    const { length } = data.review;

   
    if (length > 0) {
     
      data.review.forEach((el) => {
        average += el.rate;
      });
      avg = average / length;
      
      if (avg>2.5){
        averageWord='Great'
      }else{
        averageWord='Good'
      }
    } else {
      avg = 0;
    }
    if( type==='avg'){
      if(length===0){
        return 0;
      }else{
      return avg.toFixed(1);
      }
    }else if (type==='num'){
      return length;
    }else {
      return averageWord;
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {/* {Dataa.length > 0 ? (
        <>
          {Dataa.map((el, i) => (
            <>

              <div className="store-list store-list-new">
                <div className="store-image">
                  <Link to={`/storedetail/?partnerid=${ el._id }`}>
                    <img
                      src={el.photo ? `${ server }/${ el.photo }` : store}
                      alt=""
                    />
                  </Link>
                  {localStorage.getItem('usertoken') ? (
                    <div className="store-favorite">
                      <i
                        role="presentation"
                        className={el.addToFavorite ? 'fa fa-heart' : 'fa fa-heart-o'}
                        onClick={() => { addToFavorite(el._id); }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="store-favorite">
                        <i
                          role="presentation"
                          className="fa fa-heart-o"
                          onClick={() => history.push('/login')}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="store-detail">
                  <Link to={`/storedetail/?partnerid=${ el._id }`}>
                    <h3>{el.companyName}</h3>
                    <ul>
                      <li>
                        <i className="fa fa-map-marker" />
                        {' '}
                        {el.partnerLocation.length > 0
                          ? el.partnerLocation[0].locationName
                          : 'N/A'}
                        ,
                        {' '}
                        {el.partnerLocation.length > 0
                          ? el.partnerLocation[0].country
                          : ''}
                      </li>

                      <li>
                        <i className="fa fa-phone" />
                        {' '}
                        {el.phoneCode
                          ? (
                            <>
                              (
                              {el.phoneCode}
                              )
                              {' '}
                            </>
                          )
                          : 'N/A'}
                        {el.phone}
                      </li>
                    </ul>
                    <div className="store-rating">
                      <ReactStars
                        count={5}
                        value={calculateAverage(el)}
                        size={25}
                        edit={false}
                        color2="#ffd700"
                      />
                      <i>
                        (
                        {calculateAverage(el)}
                        )
                      </i>

                    </div>
                  </Link>
                </div>

              </div>

            </>
          ))}
        </>
      ) : (
        <h1 className="placeholder-text">No Result Found</h1>
      )} */}

      <div className="venues-section">
        <div className="container">
          {/* <h2 className="title">
            Venues in London
          </h2> */}

          <div className="row">
            {Dataa.length > 0 ? (
              <>
                {Dataa.map((el, i) => {

                    console.log('el',el)
                    return(
                  <>
                    <div className="col-md-4">
                      {localStorage.getItem("usertoken") ? (
                        <Link to={`/storedetail/?partnerid=${el._id}`}>
                          <div className="featured-store-box">
                            <div className="featured-store-thumb">
                              <img
                                src={el.photo ? `${server}/${el.photo}` : store}
                                alt="store"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = store;
                                }}
                              />

                              {/* <div className="like-icon">
                            <i className="fa fa-heart-o" aria-hidden="true" />
                          </div> */}

                              <div className="like-icon">
                                <i
                                  role="presentation"
                                  className={
                                    el.addToFavorite
                                      ? "fa fa-heart"
                                      : "fa fa-heart-o"
                                  }
                                  onClick={(e) => {
                                    addToFavorite(e, el._id);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="featured-store-info">
                              <div className="venues-heading">
                                <h2>{el.companyName}</h2>
                                <div className="venues-rating">
                                  <p>
                                    <span>{calculateAverage(el,'word')}</span>
                                    <br />
                                    {calculateAverage(el,'num')}
                                  </p>
                                  <h6>{calculateAverage(el,'avg')}</h6>
                                </div>
                              </div>
                              <div className="venues-heading-bottom">
                                <p>
                                  {el.Description ? el.Description : ""}
                                  {/* consectetur adipiscing elit,
                                  <br /> consectetur adipiscing elit, sed do */}
                                </p>
                                <h5>Featured</h5>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        //`/storedetail/?partnerid=${el._id}`
                        <Link to={`/storedetail/?partnerid=${el._id}`}>
                          <div className="featured-store-box">
                            <div className="featured-store-thumb">
                              <img
                                src={el.photo ? `${server}/${el.photo}` : store}
                                alt="store"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = store;
                                }}
                              />
                              {/* <div className="like-icon">
                            <i className="fa fa-heart-o" aria-hidden="true" />
                          </div> */}

                              <>
                                <div className="like-icon">
                                  <i
                                    role="presentation"
                                    className="fa fa-heart-o"
                                    onClick={(e) => helperFunction(e)}
                                  />
                                </div>
                              </>
                            </div>
                            <div className="featured-store-info">
                              <div className="venues-heading">
                                <h2>{el.companyName}</h2>
                                <div className="venues-rating">
                                  <p>
                                    <span>{calculateAverage(el,'word')}</span>
                                    <br />
                                    {calculateAverage(el,'num')}
                                  </p>
                                  <h6>{calculateAverage(el,'avg')}</h6>
                                </div>
                              </div>
                              <div className="venues-heading-bottom">
                                <p>{el.Description ? el.Description : ""}</p>
                                <h5>Featured</h5>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  </>
                    )
                })}
              </>
            ) : (
              <h1 className="placeholder-text">No Result Found</h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
