import React, { useState, useEffect, useCallback } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
//import queryString from 'query-string';
import { toast } from 'react-toastify';
//import cover from '../../assets/store/cover.jpg';
import store from '../../assets/store/store.jpg';

// import store2 from '../../assets/store/store2.jpg';
// import store3 from '../../assets/store/store3.jpg';
// import store4 from '../../assets/store/store4.jpg';
import './store.css';
import { userInstance } from '../../config/axios';

const Wishlist = () => {
  // const handlePageNumber = (number) => {
  //   getBusinessPartner(number);
  // };

  const active = 2;
  const items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  // eslint-disable-next-line
  const [menu, setMenu] = useState(false);
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  //const value = queryString.parse(window.location.search);
  //const { categoryid, businessname } = value;
  const [wishListData, setWishListData] = useState([]);

  const nextPage = () => {
    setSkip(skip + limit);
  };

  const previousPage = () => {
    setSkip(skip - limit);
  };

  const getWishlistData = useCallback(async () => {
    const payload = {
      skip,
      limit,
    };
    const response = await userInstance().post('/wishlists', payload);

    const { code, wishLists, length } = response.data;
    if (code === 200) {
      setWishListData(wishLists);
      setTotalCount(length);
    }
    // eslint-disable-next-line
  }, [menu, skip, limit]);

  useEffect(() => {
    getWishlistData();
  }, [getWishlistData]);

  // const handleMenu = (e) => {
  //   setMenu(e.target.checked);
  // };

  const RemoveWishlistItem = async (partnerId) => {
    const payload = {
      partnerId,
    };
    const response = await userInstance().post('/RemoveWishlistItem', payload);
    const { code, msg } = response.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      getWishlistData();
    }
  };
  return (
    <div className="store-listing wishlist-page">
      <div
        className="store-listing-cover"
      // style={{ backgroundImage: `url(${cover})` }}
      >
        <div className="container">
          <h2>Wishlist</h2>
        </div>
      </div>

      <div className="store-listing-content">
        <div className="container">

          <div className="store-listing-view">
            <Store
              wishListData={wishListData}
              RemoveWishlistItem={RemoveWishlistItem}

            />
          </div>
          <div className="store-pagination">
            {/* <Pagination>{items}</Pagination> */}
          
            <Pagination hidden={wishListData.length === 0}>
              {wishListData && wishListData.length < limit && skip > 0 ? (
                <>
                  {' '}
                  <Pagination.First onClick={previousPage} />
                  {' '}
                </>
              ) : (
                <>
                  {skip === 0 ? (
                    <>
                      {' '}
                      <Pagination.Last onClick={nextPage} hidden={Number(limit) > Number(totalCount)} />
                      {' '}
                    </>
                  ) : (
                    <>
                      {' '}
                      <Pagination.First onClick={previousPage} />
                      {/* <Pagination.Item active>{index}</Pagination.Item> */}
                      <Pagination.Last
                        onClick={nextPage}
                        hidden={Number(skip + limit) === Number(totalCount)}
                      />
                    </>
                  )}
                </>
              )}
              {/* <Pagination.First onClick={previousPage} /> */}

              {/* <Pagination.Item active>{index}</Pagination.Item> */}

              {/* <Pagination.Last onClick={nextPage} /> */}
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;

const Store = ({ wishListData, RemoveWishlistItem }) => (
  <>
    {wishListData.map((el, i) => (
      <>
        <div className="store-list">
          <div className="store-image">
            <img src={store} alt="store" />
            <div className="store-favorite">
              <i onClick={() => { RemoveWishlistItem(el._id); }} className="fa fa-heart" />
            </div>
          </div>

          <div className="store-detail">
            <Link to={`/storedetail/?partnerid=${ el._id }`}>
              <h3>{el.companyName}</h3>
            </Link>
            <ul>
              <li>
                <i className="fa fa-map-marker" />
                {' '}
                {el.partnerLocation.length > 0
                  ? el.partnerLocation[0].locationName
                  : 'N/A'}
                {' '}
                {el.partnerLocation.length > 0
                  ? el.partnerLocation[0].country
                  : ''}
              </li>
              <li>
                <i className="fa fa-phone" />
                {' '}
                {el.phoneCode
                  ? (
                    <>
                      (
                      {el.phoneCode}
                      )
                    </>
                  )
                  : 'N/A'}
                {' '}
                {el.phone}
              </li>
            </ul>
            <div className="store-rating">
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star-half" />
              <span>(4.5)</span>
            </div>
          </div>
        </div>
      </>
    ))}
  </>
);
