/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import {
  Table, Button, Modal, Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';
import { server } from '../../../config/keys';
import Loader from '../../loader/loader';
import admin from '../../../assets/store/no-image.jpg';

const AdminBusinessTypes = ({ name }) => {
  const [bussiness, setAllBusiness] = useState([]);
  const [businessname, setBusinessName] = useState('');
  const [orderno, setOrderNo] = useState(0);
  const [errors, setErrors] = useState({});
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setFile('');
    setBusinessName('');
    setPreview('');
    setshow(false);
  };
  const [show2, setshow2] = useState(false);
  const handleClose2 = () => {
    setFile('');
    setBusinessName('');
    setPreview('');
    setshow2(false);
  };
  const [bussId, setBussId] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [preview, setPreview] = useState('');
  const [image, setImage] = useState('');
  const getAllBussiness = async () => {
    setLoading(true);
    const partnerData = await adminInstance().get('/businessTypes');
    const { code, findData } = partnerData.data;
    if (code === 200) {
      setAllBusiness(findData);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBussiness();
  }, []);

  const validateBusiness = (values) => {
    const errorss = {};
    let isValid = true;

    if (!values.businessname.replace(/ /g, '')) {
      errorss.businessname = 'Business name is required';
      isValid = false;
    }

    return { isValid, errorss };
  };

  const addBusiness = async (e) => {
    e.preventDefault();
    const validation = validateBusiness({
      businessname,
    });
    setErrors(validation.errorss);
    if (!validation.isValid) {
      return;
    }
    const data = new FormData();
    data.append('file', file);
    data.append('businessname', businessname);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const saveData = await adminInstance().post('/businessType/add', data, config);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      handleClose();
      getAllBussiness();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  const handleEdit = (data) => {
    setBussId(data._id);
    setBusinessName(data.businessname);
    setImage(data.image);
    setOrderNo(data.orderno);
    setshow2(true);
  };

  const uploadImage = async (e) => {
    setFile(e.target.files[0]);
    setPreview(window.URL.createObjectURL(e.target.files[0]));
  };

  const Open = () => {
    setFile('');
    setPreview('');
  };

  if (loading) {
    return (
      <AdminWrapper>
        <Loader />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper name={name}>
      <div className="Admin-client">
        <div className="container">
          <div className="admin-main-heading">
            <h2 className="admin-title">Business Names</h2>
            <div className="text-right">
              <Button className="add-business" onClick={() => setshow(true)}>Add Business Types</Button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">

                <div className="card-body">
                  {/* <div className="partner-header">
                    <h2>Business Names</h2>
                  </div> */}
                  <div className="partner-table business-table">
                    <Table responsive="lg" className="business-table">
                      <thead>
                        <tr>
                          <th className="text-left">Business Image</th>
                          <th className="text-left">Business Name</th>
                          <th className="text-left">Order No</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>

                      {bussiness && bussiness.map((el) => (
                        <tbody>
                          <tr>
                            <td><img src={el.image ? `${server}/${el.image}` : admin} alt="User" /></td>
                            <td className="text-left">{el.businessname}</td>
                            <td className="text-left">{el.orderno}</td>
                            <td className="text-right">
                              <Button className="delete-btn" onClick={() => handleEdit(el)}> Edit</Button>
                              {' '}
                              {/* <Button className="delete-btn"> Delete</Button> */}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} className="discount-12 add-bussiness">
            <Modal.Header closeButton>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                Add Bussiness
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={addBusiness}>
                <Form.Group>
                  <Form.Label>Bussiness name</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    onChange={(e) => setBusinessName(e.target.value)}
                    value={businessname}
                    placeholder="Bussiness name"
                  />
                  {errors.businessname && <p className="error">{errors.businessname}</p>}
                </Form.Group>
                <div className="profile-img">
                  <h6>Add Business Image</h6>
                  <span className="wallpaperimg">
                    <label htmlFor="upload-photo1" className="cii">
                      <div className="user-pics">
                        <img src={preview || admin} alt="User" />
                      </div>
                      <i className="fa fa-camera" aria-hidden="true" />
                    </label>
                    {file && (
                    <div className="delete-icon">
                      <i role="presentation" className="fa fa-trash" onClick={() => Open()} />
                    </div>
                    )}
                    <input
                      type="file"
                      name="photo"
                      id="upload-photo1"
                      onChange={(e) => uploadImage(e)}
                    />
                  </span>
                </div>
                <div className="business-save">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={show2} onHide={handleClose2} className="discount-12 add-bussiness">
            <Modal.Header closeButton>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                Edit Business
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <EditBusiness
                validateBusiness={validateBusiness}
                setShow2={setshow2}
                bussId={bussId}
                businessname={businessname}
                getAllBussiness={getAllBussiness}
                setBusinessName={setBusinessName}
                uploadImage={uploadImage}
                preview={preview}
                file={file}
                Open={Open}
                image={image}
                orderno={orderno}
                setOrderNo={setOrderNo}
              />
            </Modal.Body>
            <Modal.Footer />
          </Modal>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminBusinessTypes;

function EditBusiness({
  validateBusiness,
  setShow2,
  bussId,
  businessname,
  getAllBussiness,
  setBusinessName,
  uploadImage,
  preview,
  file,
  image,
  Open,
  orderno,
  setOrderNo,
}) {
  const [errors, setErrors] = useState({});

  const updateBusiness = async (e) => {
    e.preventDefault();
    const validation = validateBusiness({
      businessname,
      orderno,
    });
    setErrors(validation.errorss);
    if (!validation.isValid) {
      return;
    }
    const data = new FormData();
    data.append('file', file);
    data.append('businessname', businessname);
    data.append('orderno', orderno);
    data.append('bussId', bussId);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const saveCategory = await adminInstance().post(
      '/editBusiness',
      data,
      config,
    );
    const { code, msg } = saveCategory.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      getAllBussiness();
      toast.success(msg, { containerId: 'B' });
      // clearValues();
      setShow2(false);
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  const removeImage = async () => {
    const payload = {
      bussId,
    };
    const ImageRemoved = await adminInstance().post(
      '/removeBussinessPhoto', payload,
    );
    if (ImageRemoved.data.code === 200) {
      getAllBussiness();
      setShow2(false);
      toast.success(ImageRemoved.data.msg, { containerId: 'B' });
    }
  };
  return (
    <Form onSubmit={updateBusiness}>
      <Form.Group>
        <Form.Label>Business name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          onChange={(e) => setBusinessName(e.target.value)}
          value={businessname}
          placeholder="Business name"
        />
        {errors.businessname && <p className="error">{errors.businessname}</p>}
      </Form.Group>
      <Form.Group>
        <Form.Label>Order No</Form.Label>
        <Form.Control
          size="lg"
          type="Number"
          onChange={(e) => setOrderNo(e.target.value)}
          value={orderno}
          placeholder="orderno"
        />
        {errors.orderno && <p className="error">{errors.orderno}</p>}
      </Form.Group>

      <div className="profile-img">
        <h6>Add Business Image</h6>
        <span className="wallpaperimg">
          <label htmlFor="upload-photo1" className="cii">
            <div className="user-pics">
              {!preview ? (
                <img src={image ? `${server}/${image}` : admin} alt="User" />
              ) : (
                <img src={preview} alt="User" />
              )}

            </div>
            <i className="fa fa-camera" aria-hidden="true" />

          </label>
          {image && (
            <div className="delete-icon">
              <i role="presentation" className="fa fa-trash" onClick={() => removeImage()} />
            </div>
          )}
          {file && (
          <div className="delete-icon">
            <i role="presentation" className="fa fa-trash" onClick={() => Open('')} />
          </div>
          )}
          <input
            type="file"
            name="photo"
            id="upload-photo1"
            onChange={(e) => uploadImage(e)}
          />
        </span>
      </div>
      <div className="bussiness-update">
        <Button variant="primary" type="submit">
          Update
        </Button>
      </div>

    </Form>
  );
}
