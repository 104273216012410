import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Form,
  //Modal,
  Dropdown,
  //DropdownButton,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import queryString from "query-string";
import moment from "moment";
import { toast } from "react-toastify";
import PartnerWrapper from "../partnerwrapper/wrapper";
//import staffimg from '../../../assets/partner/staff.png';
import user from "../../../assets/partner/user.jpg";
//import invoice from '../../../assets/partner/invoice.png';
import { partnerInstance } from "../../../config/axios";
import search from "../../../assets/partner/search.png";
import PartnerContext from "../../../context/context";
import history from "../../../config/history";
import Loader from "../../loader/loader";

const VoucherCheckout = () => {
  const { username } = useContext(PartnerContext);
  const { currency, sections } = username;
  //const [view, setView] = useState(false);
  const [voucherName, setVoucherName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [voucherValue, setVoucherValue] = useState("");
  const [retailPrice, setRetailPrice] = useState("0");
  const [retailPriceCopy, setRetailPriceCopy] = useState("");
  const [staffId, setStaffId] = useState("");
  //const [validDays, setValidDays] = useState('');
  //const [serviceId, setServiceId] = useState([]);
  // const [packageId, setPackageId] = useState([]);
  //const [noOfSales, setNoOfSales] = useState('');
  //const [voucherTitle, setVoucherTitle] = useState('');
  const [errors, setErrors] = useState({});
  const [paymentListing, setPaymentListing] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customersCopy, setCustomersCopy] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState("");
  // eslint-disable-next-line
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [validDate, setValidDate] = useState("");
  const value = queryString.parse(window.location.search);
  const { voucherId, customerid } = value;
  const getVoucher = async () => {
    setLoading(true);
    const idd = {
      voucherid: voucherId,
    };
    setID(voucherId);
    const voucher = await partnerInstance().post("/getVoucher", idd);
    const { code, voucherData, paymentList } = voucher.data;
    if (code === 200) {
      setVoucherName(voucherData.voucher[0].voucherName);
      setVoucherValue(voucherData.voucher[0].voucherValue);
      setRetailPrice(voucherData.voucher[0].retailPrice);
      setRetailPriceCopy(voucherData.voucher[0].retailPrice);
      setPaymentListing(paymentList.paymentType);
      setValidDate(
        moment(currentDate).add(
          parseInt(voucherData.voucher[0].validDays, 10),
          "days"
        )
      );

      //setValidDays(voucherData.voucher[0].validDays);
      //setNoOfSales(voucherData.voucher[0].noofsales);
      //setVoucherTitle(voucherData.voucher[0].vouchertitle);
      setLoading(false);
      // update();
    }
  };
  const getStaffs = async () => {
    const staffsData = await partnerInstance().get("/getStaffs");
    const { code, stafflist } = staffsData.data;
    if (code === 200) {
      setStaffs(stafflist);
    }
  };
  const getCustomerDetail = async (id) => {
    const idd = {
      customerid: id,
    };
    const response = await partnerInstance().post("/getCustomer", idd);
    const { code, customerData } = response.data;
    if (code === 200) {
      if (customerData) {
        setFirstname(customerData.firstname);
        setLastname(customerData.lastname);
        setEmail(customerData.email);
        setCustomerId(customerData._id);
      } else {
        setFirstname("");
        setLastname("");
        setEmail("");
        setCustomerId("");
        history.push(`/partners/vouchercheckout?voucherId=${ID}`);
      }
    }
  };
  const getCustomers = async () => {
    const response = await partnerInstance().get("/getCustomers");
    const { code, clientId } = response.data;
    if (code === 200) {
      // setCustomers(clientId);
      setCustomersCopy(clientId);
      if (customerid) {
        getCustomerDetail(customerid);
      }
    }
  };
  useEffect(() => {
    getVoucher();
    getStaffs();
    getCustomers();
    // eslint-disable-next-line
  }, []);

  const handleQuantity = (e) => {
    setQuantity(e);
    if (!e) {
      setRetailPrice(0);
    } else {
      setRetailPrice(e * parseInt(retailPriceCopy, 10));
    }
  };
  const searchCustomer = (e) => {
    setCustomers(customersCopy);
    setSearchKeyword(e);
    const filteredCustomers = customersCopy.filter(
      (customer) =>
        customer.firstname.toLowerCase().indexOf(e.toLowerCase()) !== -1
    );
    if (+e.length > 0) {
      setCustomers(filteredCustomers);
    } else {
      setCustomers([]);
    }
  };
  const selectCustomerId = (e) => {
    getCustomerDetail(e);
    setCustomerId(e);
  };
  const removeCustomerId = (e) => {
    getCustomerDetail(null);
  };

  const handleRefresh = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  const completeCheckout = async (e, k) => {
    const setSta = sections.findIndex(
      (el) => el.sectionname === "Can check out sales"
    );
    if (setSta > -1) {
      const oldState = [...paymentListing];
      oldState[k].isLoading = true;
      setPaymentListing(oldState);
      const validateVoucherCheckOut = (values) => {
        const errors = {};
        let isValid = true;

        if (!values.customerId) {
          oldState[k].isLoading = false;
          errors.customerId = "Customer is required";
          isValid = false;
        }

        return { isValid, errors };
      };

      const validation = validateVoucherCheckOut({
        customerId,
      });
      setErrors(validation.errors);
      if (!validation.isValid) {
        return;
      }

      const payload = {
        voucherId: ID,
        price: retailPrice,
        quantity,
        staffId: staffId || null,
        customerId,
        PurchaseDate: currentDate,
        ExpiryDate: validDate,
        voucherValue,
        payment: e,
      };

      const voucher = await partnerInstance().post("/sellVoucher", payload);
      const { code, msg, id } = voucher.data;
      if (code === 200) {
        toast.success(msg, { containerId: "B" });
        localStorage.removeItem("custId");
        history.push(`/partners/voucherviewinvoice?voucherId=${id}`);
      } else {
        const oldStat = [...paymentListing];
        oldStat[k].isLoading = false;
        setPaymentListing(oldStat);
        toast.error(msg, { containerId: "B" });
      }
    } else {
      toast.error("You Dont have Permisssion For Checkout Sales", {
        containerId: "B",
      });
    }
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper>
      {/* {loading && (
      <Loader />
      )} */}
      <div className="checkout-appointment-page appointment-view">
        <div className="container">
          <h2 className="admin-title">
            Checkout{" "}
            <Link to="/partners/voucher">
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>

          <div className="checkout-appointment-content appointment-content">
            <div className="row">
              <div className="col-md-5">
                <div className="appointment-card">
                  {!customerId ? (
                    <div className="customer-search">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="customer-card">
                            <h4>Customers</h4>

                            <Form inline>
                              <FormControl
                                type="text"
                                placeholder="Search Customer"
                                value={searchKeyword}
                                onChange={(e) => searchCustomer(e.target.value)}
                                onKeyDown={handleRefresh}
                              />
                              <Button>
                                <i className="fa fa-search" />
                              </Button>
                            </Form>
                            {errors.customerId && (
                              <p className="error">{errors.customerId}</p>
                            )}
                            <div className="search-container-user">
                              <Link
                                to={`/partners/addcustomer?voucherId=${ID}`}
                              >
                                <i className="fa fa-plus" /> Add new customer
                              </Link>
                              {customers &&
                                customers.map((el) => (
                                  <>
                                    <div
                                      role="presentation"
                                      className="search-items"
                                      onClick={() => selectCustomerId(el._id)}
                                    >
                                      <div className="search-icon">
                                        <img src={user} alt="" />
                                      </div>
                                      <div className="search-content">
                                        <h4>
                                          {" "}
                                          {el.firstname}
                                          {el.lastname}
                                        </h4>
                                        <p>{el.email}</p>
                                      </div>
                                    </div>
                                  </>

                                  // <div className="search-items">
                                  //   <div className="search-icon">
                                  //     <img src={user} />
                                  //   </div>
                                  //   <div className="search-content">
                                  //     <h4>Syed Websul</h4>
                                  //     <p>syedwebsul@hgmail.com</p>
                                  //   </div>
                                  // </div>
                                ))}
                            </div>
                            <div className="no-customer">
                              <img src={search} alt="" />
                              <p>
                                Use the search to add a customers, or keep empty
                                to save as walk-in.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="customer-detail">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="customer-card">
                            <div className="customer-detail-appoint">
                              <div className="customer-detail-icon">
                                <img src={user} alt="" />
                              </div>
                              <div className="customer-detail-content">
                                <h4>
                                  {firstname} {lastname}
                                </h4>
                                <p>{email}</p>
                              </div>
                              <div className="customer-detail-action">
                                <Dropdown className="appointment-action">
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <i
                                      className="fa fa-ellipsis-h"
                                      aria-hidden="true"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link
                                        to={`/partners/editcustomer?customerid=${customerId}&voucherId=${ID}`}
                                      >
                                        Edit customer
                                      </Link>
                                    </Dropdown.Item>

                                    <Dropdown.Item>
                                      <Link
                                        onClick={() =>
                                          removeCustomerId(customerId)
                                        }
                                        variant="danger"
                                      >
                                        Remove from Voucher
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>

                            {/* <div className="customer-details-activities staff-page">
                                <Tabs
                                  defaultActiveKey="0"
                                  id="uncontrolled-tab-example-4"
                                >
                                  <Tab eventKey="0" title="Appointments">
                                    <CustomerDetailAppointments
                                      customerDetail={customerDetail}
                                    />
                                  </Tab>
                                  <Tab eventKey="1" title="Invoices">
                                    <CustomerDetailInvoices />
                                  </Tab>
                                </Tabs>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="pay-status">
                    <Form.Group controlId="formBasicEmail">
                      <label>To Pay</label>
                      <Form.Control
                        placeholder={`${currency} ${retailPrice}`}
                      />
                    </Form.Group>
                  </div>

                  <div className="Cash-buttons ">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="cash-btn">
                          {paymentListing.map((item, k) => (
                            <>
                              {item.isLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <Button
                                  className="btn-perple"
                                  onClick={() =>
                                    completeCheckout(item.paymentname, k)
                                  }
                                >
                                  {item.paymentname}
                                </Button>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="appointment-buttons">
                    {/* <div className="footer-button">
                      <DropdownButton
                        id="dropdown-basic-button-2"
                        title="More Option"
                      >
                        <Link>Save Unpaid</Link>
                        <Dropdown.Item>Invoice Details</Dropdown.Item>
                      </DropdownButton>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="appointment-card">
                  <div className="appointment-body">
                    <div className="view-appointment-form">
                      <div className="scrollbar">
                        <div className="view-appointment-list">
                          <div className="customer-appointment">
                            <div className="customer-appoint-box">
                              <div className="appoint-box-name">
                                <h4>{voucherName}</h4>
                                <span>
                                  Expires on{" "}
                                  {validDate.toString().substring(0, 16)}
                                </span>
                              </div>
                              <div className="appoint-box-price">
                                <h6>
                                  {currency} {retailPrice}
                                </h6>
                              </div>
                              <div className="service-details">
                                <Form.Row>
                                  <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Qty</Form.Label>
                                    <Form.Control
                                      defaultValue={1}
                                      value={quantity}
                                      onChange={(e) =>
                                        handleQuantity(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                      controlId="formGridState"
                                      type="number"
                                      value={retailPrice}
                                      onChange={(e) =>
                                        setRetailPrice(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col}>
                                    <Form.Label>Staff</Form.Label>
                                    <Form.Control
                                      as="select"
                                      onChange={(e) =>
                                        setStaffId(e.target.value)
                                      }
                                      value={staffId}
                                    >
                                      <option value="">Choose staff</option>
                                      {staffs.map((el) => (
                                        <option value={el._id}>
                                          {el.firstname}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                  {/*
                                  <Form.Group
                                    as={Col}
                                    controlId="formGridState"
                                  >
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control
                                      as="select"
                                      defaultValue="Choose..."
                                      disabled
                                    >
                                      <option>Choose...</option>
                                      <option>...</option>
                                    </Form.Control>
                                  </Form.Group> */}
                                </Form.Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-12 ">
                        <div className="total-output">
                          <div className="total-d">
                            <h4>Total</h4>
                            <span>
                              {currency} {retailPrice}
                            </span>
                          </div>

                          <div className="add-more" />

                          <div className="balance-d">
                            <h4>Balance</h4>
                            <span>
                              {currency} {retailPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default VoucherCheckout;
