import React, { useState, useEffect, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
//import { useHistory } from 'react-router';
import { partnerInstance } from "../../../config/axios";

const PricingDetail = ({ handleStep, step, updateFormData, newData, accountData }) => {
  //const history=useHistory();
  const { state } = useLocation();
  const history = useHistory();
  const [accountType, setAccountType] = useState(
    accountData ? accountData.accountType : ""
  );
  const [firstname, setFirstName] = useState(state ? state.firstname : "");
  const [lastname, setLastName] = useState(state ? state.lastname : "");
  const [address, setAddress] = useState(state ? state.address : "");
  const [city, setCity] = useState(state ? state.city : "");
  const [State, setState] = useState(state ? state.State : "");
  const [postalCode, setPostalCode] = useState(accountData ? accountData.postalCode : "");
  const [taxNo, setTaxNo] = useState(state ? state.taxNo : "");
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState(state ? state.email : "");

  const AccountHolder = useCallback(async () => {
    const resData = await partnerInstance().get("/getPartnerAccount");

    const { code, partnerData } = resData.data;
    if (code === 200) {
      setFirstName(partnerData.firstname);
      setLastName(partnerData.lastname);
      setEmail(partnerData.email);
      if (partnerData.partnerLocation.length > 0) {
        setState(partnerData.partnerLocation[0].state);
        setPostalCode(partnerData.partnerLocation[0].zip);
        setCity(partnerData.partnerLocation[0].city);
        setAddress(partnerData.partnerLocation[0].region);
      }
    }
  }, []);

  useEffect(() => {
    AccountHolder();
    // eslint-disable-next-line
  }, []);

  const validateEm = (values) => {
    const errorss = {};
    let isValid = true;
    if (!values.firstname) {
      errorss.firstname = "Firstname is required";
      isValid = false;
    }
    if (!values.accountType) {
      errorss.accountType = "AccountType is required";
      isValid = false;
    }
    if (!values.lastname) {
      errorss.lastname = "Lastname is required";
      isValid = false;
    }
    if (!values.address) {
      errorss.address = "Address is required";
      isValid = false;
    }
    if (!values.city) {
      errorss.city = "City is required";
      isValid = false;
    }
    if (!values.State) {
      errorss.State = "State is required";
      isValid = false;
    }
    if (!values.postalCode) {
      errorss.postalCode = "PostalCode is required";
      isValid = false;
    }
    if (!values.email) {
      errorss.email = "Email is required";
      isValid = false;
    }

    return { isValid, errorss };
  };

  const validateEmail = (e) => {
    e.preventDefault();
    const validation = validateEm({
      accountType,
      firstname,
      lastname,
      address,
      city,
      State,
      postalCode,
      email,
    });
    setErrors(validation.errorss);
    if (!validation.isValid) {
      return;
    }

    // history.push({
    //   pathname: '/partners/step3',
    //   state: {
    //     accountType,
    //     firstname,
    //     lastname,
    //     address,
    //     city,
    //     State,
    //     postalCode,
    //     taxNo,
    //     email,
    //   },
    // });
    const payload = {
      accountType,
      firstname,
      lastname,
      address,
      city,
      State,
      postalCode,
      taxNo,
      email,
    };
    newData = payload;

    updateFormData(newData);
    handleStep(step + 1);
  };
  // const handleAccountType=(e)=>{
  //   setAccountType(e.target.value)
  //   updateFormData({type:e.target.value })
  // }
  // const handleFirstName=(e)=>{
  //   setFirstName(e.target.value)
  //   updateFormData({firstname: e.target.value})
  // }
  return (
    <Form onSubmit={validateEmail}>
      <div className="payment-stripe-container">
        {/* <div className="container-fluid">
        <div className="row">
          <div className="col-md-12"> */}
        <div className="your-details">
          <div className="pricing-section">
            <h1>Step 2 of 3: Your Details</h1>
            <p>
              Add your contact details and tax number, these will appear on
              invoices we issue to you. In the next step you can setup card
              details.
            </p>

            <div className="details-form">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Account type</Form.Label>
                    <Form.Control
                      as="select"
                      value={accountType}
                      onChange={(e) => setAccountType(e.target.value)}
                    >
                      <option value="">Choose Account Type</option>
                      <option value="individual">Individual Sole</option>
                      <option value="trader">Trader</option>
                    </Form.Control>
                    {errors.accountType && (
                      <p className="error">{errors.accountType}</p>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>first Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter First Name"
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {errors.firstname && (
                      <p className="error">{errors.firstname}</p>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Last Name"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {errors.lastname && (
                      <p className="error">{errors.lastname}</p>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label> Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {errors.address && (
                      <p className="error">{errors.address}</p>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    {errors.city && <p className="error">{errors.city}</p>}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter State"
                      value={State}
                      onChange={(e) => setState(e.target.value)}
                    />
                    {errors.State && <p className="error">{errors.State}</p>}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Postal Code </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Postal Code"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                    {errors.postalCode && (
                      <p className="error">{errors.postalCode}</p>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>TRN Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter TRN Number"
                      value={taxNo}
                      onChange={(e) => setTaxNo(e.target.value)}
                    />
                    {errors.taxNo && <p className="error">{errors.taxNo}</p>}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div>
      </div> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="signup-footer-btn">
            <Button
              role="presentation"
              onClick={() => history.push("/partners/online-booking")}
              className="btn-perple"
            >
              Back
            </Button>
            <Button type="submit" className="btn btn-perple">
              Next
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

PricingDetail.propTypes = {
  handleStep: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
};

export default PricingDetail;
