/* eslint-disable radix */
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import "./selectservices.css";
import { Card, ProgressBar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
//import { loadStripe } from "@stripe/stripe-js";
import { server} from "../../../config/keys";
import { userInstance } from "../../../config/axios";
import { checkKeys } from "../../../function/validate";
import history from "../../../config/history";
import Helmet from "react-helmet";
import store2 from "../../../assets/no-image.jpg";

const PaymentDetail = () => (
  <Elements stripe={checkKeys()}>
    <StripeButton />
  </Elements>
);

export default PaymentDetail;

const StripeButton = () => {
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const { state } = useLocation();

  let priceTotal = 0;
  let totalDuration = 0;
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [selectServices] = useState(state.servicesPanel);
  const [staffName] = useState(state.selectedStaff.name);
  const [staffId] = useState(state.selectedStaff.id);
  const [shifts] = useState(state.selectedStaff.shiftSlot);
  let [selectedTime] = useState(state.selectedTime);
  const [selectedDate] = useState(state.selectedDate);
  const [selectStaffs] = useState(state.staffs);
  const [partnerSlot] = useState(state.partnerTime);
  const [partnerId, setPartnerId] = useState("");
  const [appointmentPanel, setAppointmentPanel] = useState([]);
  const [chooseStaff] = useState(state.chooseStaff);
  const [bundle] = useState(state.bundle);
  const [appointmentNotes, setAppointmentNotes] = useState("");
  const [currency] = useState(state.currency);
  const [storeImage] = useState(state.storeImage);
  const [paymentRequired] = useState(state.paymentRequired);
  const [outService] = useState(state.outService);
  const [eventKey] = useState(state.eventKey);
  const [error, setError] = useState(null);
  const [outServiceAddress, setOutAddress] = useState("");
  const [errors, setErrors] = useState({});
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    setPartnerId(id);

    let packAmt = 0;
    state.servicesPanel.forEach((el, i) => {
      if (el.services) {
        el.services.forEach((item, k) => {
          let st = "";
          const { length } = appointmentPanel;
          if (k > 0) {
            st = appointmentPanel[length - 1].endTime;
          } else {
            st = selectedTime;
          }
          const nextStartTime = moment(st, "HH:mm:ss a")
            .add(parseInt(item.duration), "minutes")
            .format("hh:mm a");
          if (el.priceType === "Free") {
            packAmt = 0;
          } else if (el.priceType === "Service pricing") {
            packAmt = parseInt(item.priceFrom);
          } else if (el.priceType === "Custom pricing") {
            const disPer =
              (parseInt(el.retailPrice) / parseInt(el.packagePrice)) * 100;
            packAmt = parseInt(item.priceFrom) * (disPer.toFixed(2) / 100);
          } else {
            const disper =
              parseInt(item.priceFrom) * (parseInt(el.retailPrice) / 100);
            packAmt = parseInt(item.priceFrom) - disper;
          }
          appointmentPanel.push({
            categoryId: item.serviceCategory,
            serviceId: item._id,
            staffId,
            startTime: st,
            endTime: nextStartTime,
            duration: item.duration,
            appointmentNote: "",
            specialPackagePrice: packAmt,
          });
        });
      } else {
        let st = "";
        const { length } = appointmentPanel;
        if (i > 0) {
          st = appointmentPanel[length - 1].endTime;
        } else {
          st = selectedTime;
        }
        const nextStartTime = moment(st, "HH:mm:ss a")
          .add(parseInt(el.duration), "minutes")
          .format("h:mm a");
        appointmentPanel.push({
          categoryId: el.categoryid,
          serviceId: el.id,
          staffId,
          startTime: st,
          endTime: nextStartTime,
          duration: el.duration,
          appointmentNote: "",
          specialPackagePrice: "",
        });
      }
    });
    setAppointmentPanel(appointmentPanel);
    // eslint-disable-next-line
  }, []);

  // const checkedServices = (e) => {
  //   const servicesCopy = [...appointmentPanel];
  //   setAppointmentNotes(e.target.value);
  //   servicesCopy.forEach((el) => {
  //     el.appointmentNote = e.target.value;
  //   });
  //   setAppointmentPanel(servicesCopy);
  // };

  const validatePr = (values) => {
    const errorss = {};
    let isValid = true;
    if (!values.outServiceAddress) {
      errorss.outServiceAddress = "Address is required";
      isValid = false;
    }
    return { isValid, errorss };
  };

  const bookAppointment = async () => {
    if (outService) {
      const validation = validatePr({
        outServiceAddress,
      });
      setErrors(validation.errorss);
      if (!validation.isValid) {
        return;
      }
    }
    if (priceTotal !== 0 && paymentRequired) {
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);

      if (result.error) {
        // Inform the user if there was an error.
        setError(result.error.message);
      } else {
        setError(null);
        // Send the token to your server.
        const payload = {
          stripeToken: result.token.id,
          appointmentDate: selectedDate,
          appointmentPanel,
          staffId,
          totalAmount: priceTotal,
          partnerId,
          selectStaffs,
          chooseStaff,
          bundle,
          totalDuration,
          appointmentNotes,
          currency,
          address: partnerSlot.addressFormat,
          outServiceAddress,
        };
        const response = await userInstance().post("/bookAppointment", payload);
        const { code, msg } = response.data;
        if (code === 200) {
          toast.success(msg, { containerId: "B" });
          history.push("/success");
        } else {
          toast.error(msg, { containerId: "B" });
        }
      }
    } else {
      const payload = {
        stripeToken: null,
        appointmentDate: selectedDate,
        appointmentPanel,
        staffId,
        totalAmount: priceTotal,
        partnerId,
        selectStaffs,
        chooseStaff,
        bundle,
        totalDuration,
        appointmentNotes,
        currency,
        address: partnerSlot.addressFormat,
        outServiceAddress,
      };
      const saveData = await userInstance().post("/bookAppointment", payload);
      const { code, msg } = saveData.data;
      if (code === 200) {
        // toast.success(msg, { containerId: 'B' });
        localStorage.removeItem("bookingData");
        history.push("/success");
      } else {
        toast.error(msg, { containerId: "B" });
      }
    }
  };

  const Reschedule = async () => {
    const AppointmentId = localStorage.getItem("AppointmentId");
    const payload = {
      appointmentDate: selectedDate,
      AppointmentId,
      appointmentPanel,
      partnerId,
    };
    const response = await userInstance().post("/Reschedule", payload);
    const { code, msg } = response.data;
    if (code === 200) {
      localStorage.removeItem("Reschedule");
      localStorage.removeItem("AppointmentId");
      toast.success(msg, { containerId: "B" });
      history.push("/myappointments");
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps" />
      </Helmet>
      <div className="service-bg new-select-service bg-color">
        <div className="container">
          <h2 className="page-title">
            {chooseStaff && !bundle ? (
              <>
                <p>
                  {" "}
                  <span> Step 4 | </span> Review and Confirm{" "}
                </p>
              </>
            ) : (
              <>
                <p>
                  {" "}
                  <span> Step 3 | </span> Review and Confirm{" "}
                </p>
              </>
            )}
            <Link
              className="btn"
              to={{
                pathname: `/customerappointment/select-time/${id}`,
                state: {
                  servicesPanel: selectServices,
                  staffs: selectStaffs,
                  selectedStaff: {
                    name: staffName,
                    id: staffId,
                    shiftSlot: shifts,
                  },
                  partnerTime: partnerSlot,
                  chooseStaff,
                  bundle,
                  currency,
                  storeImage,
                  paymentRequired,
                  outService,
                  eventKey,
                },
              }}
            >
              <i className="fa fa-angle-left" /> Back
            </Link>
          </h2>
          <div className="select-serve paymentdetail">
            <div className="store-services">
              <div className="row">
                <div className="col-md-4">
                  <div className="service-card">
                    <div className="box-details">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={storeImage ? `${server}/${storeImage}` : store2}
                        />
                        <Card.Body>
                          <Card.Title className=" heading-bold">
                            {partnerSlot.locationName}
                          </Card.Title>
                          <Card.Text className="">
                            {partnerSlot.addressFormat},{partnerSlot.country}
                          </Card.Text>
                          <div className="no-service date-time">
                            <h4 className="text-left">
                              {moment(selectedDate).format("dddd D, MMMM YYYY")}
                              <br />
                              {moment(selectedTime, ["HH.mm"]).format(
                                "hh:mm a"
                              )}
                            </h4>
                          </div>
                          <div className="border-des">
                            <div className="left-scrollbar">
                              {selectServices.length > 0 ? (
                                <>
                                  {selectServices.map((ele) => {
                                    priceTotal += parseInt(ele.amount);
                                    totalDuration += parseInt(ele.duration);
                                    return (
                                      <div className="content-detail">
                                        <div className="product">
                                          <h6>{ele.servicename}</h6>
                                          <span>
                                            {Math.floor(
                                              parseInt(ele.duration) / 60
                                            ) === 0 ? (
                                              <>
                                                {parseInt(ele.duration) % 60}{" "}
                                                min
                                              </>
                                            ) : (
                                              <>
                                                {Math.floor(
                                                  parseInt(ele.duration) / 60
                                                )}
                                                h{" "}
                                                {parseInt(ele.duration) % 60 ===
                                                0 ? (
                                                  ""
                                                ) : (
                                                  <>
                                                    ,
                                                    {parseInt(ele.duration) %
                                                      60}
                                                    min
                                                  </>
                                                )}
                                              </>
                                            )}
                                            {} with {staffName}
                                          </span>
                                        </div>
                                        <div className="rate">
                                          <span>
                                            {ele.amount === "0"
                                              ? "Free"
                                              : ele.amount}{" "}
                                            {ele.amount !== "0" ? currency : ""}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="no-service">
                                  <h4>No services selected yet</h4>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="total-rate">
                            <h6>Total</h6>
                            <h6>
                              {selectServices.length > 0
                                ? priceTotal === 0
                                  ? "Free"
                                  : priceTotal
                                : 0}{" "}
                              {selectServices.length > 0
                                ? priceTotal === 0
                                  ? ""
                                  : currency
                                : currency}
                            </h6>
                          </div>
                        </Card.Body>
                      </Card>
                      <div className=" book-now">
                        {localStorage.getItem("Reschedule") ? (
                          <>
                            <Button
                              className="btn"
                              variant="primary"
                              onClick={Reschedule}
                            >
                              Reschedule{" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btn"
                              variant="primary"
                              onClick={bookAppointment}
                            >
                              Confirm{" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="service-card">
                    {chooseStaff && !bundle ? (
                      <div className="stepwizard">
                        <ProgressBar now={100} />
                        <span className="stepwizard-steps">Step 4</span>
                      </div>
                    ) : (
                      // <div className="stepwizard">
                      //   <ol className="stepwizard-panel">
                      //     <li className="stepwizard-step">Step 1</li>
                      //     <li className="stepwizard-step">Step 2</li>
                      //     <li className="stepwizard-step">Step 3</li>
                      //     <li className="current  stepwizard-step">Ready to go!</li>
                      //   </ol>
                      // </div>
                      <div className="stepwizard">
                        <ProgressBar now={100} />
                        <span className="stepwizard-steps">Step 3</span>
                      </div>
                      // <div className="stepwizard">
                      //   <ol className="stepwizard-panel">
                      //     <li className="stepwizard-step">Step 1</li>
                      //     <li className="stepwizard-step">Step 2</li>
                      //     <li className="current stepwizard-step">Ready to go!</li>
                      //   </ol>
                      // </div>
                    )}
                    {priceTotal !== 0 && paymentRequired ? (
                      <div className="paymentdetail-content">
                        <div className="sub-heading">
                          <h5>Confirm with card</h5>
                          <p>
                            You won't be charged now, payment will be collected
                            in store after your appointment
                          </p>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicFirst">
                              <Form.Label>Card holder full name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Add card holder full name"
                                autocomplete="off"
                                required
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Card number</Form.Label>
                              <CardElement
                                id="card-element"
                                class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={handleChange}
                              />
                              {error}
                            </Form.Group>
                          </div>
                          {/* <div className="col-md-6">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label>Expiry date</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="MM/YY"
                              autocomplete="off"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label>CVV</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="123"
                              autoComplete="off"
                            />
                          </Form.Group>
                        </div> */}
                          {/* <div className="col-md-12">
                            <div className="paywith"> */}
                          {/* <div><h6>Pay securly with </h6></div> */}
                          {/* <div> */} {/* <img src={visa} /> */}
                          {/* </div> */}
                          {/* <div><img src={master} /></div> */}
                          {/* </div>
                          </div> */}
                          <div className="col-md-12">
                            <div className="booking-notes">
                              <Form.Group>
                                <Form.Label>Add booking notes</Form.Label>
                                <p>
                                  Include comments or requests about your
                                  booking
                                </p>
                                <textarea
                                  className="form-control text optional employee-modal-note"
                                  rows="12"
                                  placeholder="Add booking notes"
                                  id="employee_notes"
                                  onChange={(e) =>
                                    setAppointmentNotes(e.target.value)
                                  }
                                  value={appointmentNotes}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="additional-notes">
                        <div className="booking-notes">
                          <Form>
                            <Form.Group>
                              <Form.Label>Add booking notes</Form.Label>
                              <p>
                                Include comments or requests about your booking
                              </p>
                              <textarea
                                className="form-control text optional employee-modal-note"
                                rows="12"
                                placeholder="Add booking notes"
                                id="employee_notes"
                                onChange={(e) =>
                                  setAppointmentNotes(e.target.value)
                                }
                                value={appointmentNotes}
                                type="text"
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    )}

                    {outService && (
                      <div className="additional-notes new-notes">
                        <Form>
                          <Form.Group>
                            <Form.Label>Add Address for OutService</Form.Label>
                            <textarea
                              className="form-control text optional employee-modal-note"
                              rows="12"
                              placeholder="Add address"
                              required
                              id="employee_notes"
                              onChange={(e) => setOutAddress(e.target.value)}
                              value={outServiceAddress}
                              type="text"
                            />
                            {errors.outServiceAddress && (
                              <p className="error">
                                {errors.outServiceAddress}
                              </p>
                            )}
                          </Form.Group>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
