import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
//import Pagination from 'react-bootstrap/Pagination';
import ReactStars from 'react-stars';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import MyProfile from './myprofile';
import favourite from '../../assets/no-image.jpg';
import { userInstance } from '../../config/axios';
import { server } from '../../config/keys';
import history from '../../config/history';
import Loader from '../loader/loader';

const MyFavorites = ({ username, userImage }) => {
  const [limit] = useState(6);
  const [skip, setSkip] = useState(0);
  const [wishListData, setWishListData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  //const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  const getWishlistData = useCallback(async () => {
    setLoading(true);
    const payload = {
      skip,
      limit,
    };
    const response = await userInstance().post('/wishlists', payload);
    const { code, wishLists, length } = response.data;
    if (code === 200) {
      setWishListData(wishLists);
      setTotalCount(length);
      setLoading(false);
    }
  }, [skip, limit]);

  useEffect(() => {
    getWishlistData();
  }, [getWishlistData]);

  const RemoveWishlistItem = async (partnerId) => {
    const payload = {
      partnerId,
    };
    const response = await userInstance().post('/RemoveWishlistItem', payload);
    const { code, msg } = response.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      getWishlistData();
    }
  };

  const nextPage = () => {
    setSkip(skip + limit);
  };

  const previousPage = () => {
    setSkip(skip - limit);
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);

  return (
    <div className="store-listing wishlist-page new-store-listing">
      <div className="store-listing-content favorites-pagination">
        <div className="container" style={{ padding: '0px' }}>

          <div className="store-listing-view">
            <Store
              wishListData={wishListData}
              RemoveWishlistItem={RemoveWishlistItem}
              name={username}
              image={userImage}
              loading={loading}
              previousPage={previousPage}
              nextPage={nextPage}
              limit={limit}
              skip={skip}
              totalCount={totalCount}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
          </div>

        </div>
      </div>
    </div>
  // </div>
  );
};
export default MyFavorites;

const Store = ({
  wishListData, RemoveWishlistItem, name, image, loading, previousPage, nextPage, limit, skip,
  totalCount, handlePageClick, pageCount,
}) => {
  const calculateAverage = (data) => {
  
    let average = 0;
    let avg = 0;
    const { length } = data.review;
    if (length > 0) {
      data.review.forEach((el) => {
        average += el.rate;
      });
      avg = average / length;
    } else {
      avg = 0;
    }
    return Math.round(avg);
  };

  if (loading) {
    return (
      <MyProfile username={name} userImage={image}>
        <Loader />
      </MyProfile>
    );
  }

  return (
    <>
      <MyProfile username={name} userImage={image}>
        {/* {loading && (
        <Loader />
        )} */}
        <>
          <div className="my-favorites">
            <h3>My Favorites</h3>
            <div className="favourite-section">
              <div className="row">
                {wishListData.length > 0 ? (
                  <>
                    {wishListData.map((el, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="favourite-card">
                          <div className="box-details">

                            <div className="card">
                              <img
                                role="presentation"
                                className="card-img-top"
                                src={el.photo ? `${server}/${el.photo}` : favourite}
                                alt="favourite-img"
                                onClick={() => history.push(`/storedetail/?partnerid=${el._id}`)}
                              />
                              <div className="client-favorite">
                                <i role="presentation" onClick={() => { RemoveWishlistItem(el._id); }} className="fa fa-heart" />
                              </div>
                              <div className="card-body">
                                <Link to={`/storedetail/?partnerid=${el._id}`}>
                                  <div className="favourite-detail">
                                    <h3>{el.companyName}</h3>
                                    <ul>
                                      <li>
                                        <i className="fa fa-map-marker" />
                                        {' '}
                                        {el.partnerLocation.length > 0
                                          ? el.partnerLocation[0].locationName
                                          : 'N/A'}
                                        ,
                                        {' '}
                                        {el.partnerLocation.length > 0
                                          ? el.partnerLocation[0].country
                                          : ''}
                                      </li>
                                      <li>
                                        <i className="fa fa-phone" />
                                        {' '}
                                        {el.phoneCode
                                          ? (
                                          <>
                                            (
                                            {el.phoneCode}

                                            )
                                          </>
                                          )
                                          : 'N/A'}
                                        {' '}
                                        {el.phone}
                                        {' '}
                                      </li>
                                    </ul>
                                    <div className="store-rating">
                                      <ReactStars
                                        count={5}
                                        value={calculateAverage(el)}
                                        size={25}
                                        edit={false}
                                        color2="#ffd700"
                                      />
                                      <i>
                                        (
                                        {calculateAverage(el)}
                                        )
                                      </i>

                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="col-md-12">
                    <p className="no-result">No Favorites Yet</p>
                  </div>
                )}

              </div>
            </div>
          </div>

          <div className="store-pagination">
            {/* <Pagination>{items}</Pagination> */}
         
            {totalCount >= limit && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel="← Previous"
                    nextLabel="Next →"
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName="pagination-chk"
                    previousLinkClassName="pagination__link"
                    nextLinkClassName="pagination__link"
                    disabledClassName="pagination__link--disabled"
                    activeClassName="pagination__link--active"
                  />
                </div>
              </div>
            </div>
            )}
            {/* <Pagination hidden={totalCount <= limit}>
            {wishListData && wishListData.length < limit && skip > 0 ? (
              <>
                {' '}
                <Pagination.First onClick={previousPage} />
                {' '}
              </>
            ) : (
              <>
                {skip === 0 ? (
                  <>
                    {' '}
                    <Pagination.Last onClick={nextPage} />
                    {' '}
                  </>
                ) : (
                  <>
                    {' '}
                    <Pagination.First onClick={previousPage} />
                    <Pagination.Item active>{index}</Pagination.Item>
                    <Pagination.Last
                      onClick={nextPage}
                      hidden={Number(skip + limit) === Number(totalCount)}
                    />
                  </>
                )}
              </>
            )}
            <Pagination.First onClick={previousPage} />

            <Pagination.Item active>{index}</Pagination.Item>

            <Pagination.Last onClick={nextPage} />
          </Pagination> */}
          </div>
        </>

      </MyProfile>
    </>
  );
};
