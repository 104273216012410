import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
// import PhoneInput from 'react-phone-input-2';
import { toast } from "react-toastify";
import { validateUserProfile } from "../../../function/validate";
import { partnerInstance } from "../../../config/axios";
import user from "../../../assets/user.jpg";
import { server } from "../../../config/keys";
import PartnerWrapper from "../partnerwrapper/wrapper";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";
import "./partnerprofile.css";

const PartnerProfile = () => {
  const [email, setEmail] = useState("");
  const [userImage, setUserImage] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [phoneCode, setPhoneCode] = useState("+91");
  const [loading, setLoading] = useState(false);
  const { setUsername, username } = useContext(PartnerContext);

  const updateProfile = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      firstname,
      lastname,
      phone,
      phoneCode,
    };

    const validation = validateUserProfile({
      firstname,
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const updateUser = await partnerInstance().post(
      "/staffUpdateProfile",
      payload
    );
    const { code, msg, userData } = updateUser.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      const dataa = {
        name: userData.firstname,
        image: username.image,
        sections: username.sections,
        currency: username.currency,
      };
      setUsername(dataa);
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const getUserProfile = async () => {
    setLoading(true);
    const Data = await partnerInstance().get("/validateToken");
    const { code, userData } = Data.data;
    if (code === 200) {
      setEmail(userData.email);
      setFirstname(userData.firstname);
      setLastname(userData.lastname);
      setPhone(userData.phone);
      setPhoneCode(userData.phoneCode);
      setUserImage(userData.image);
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserProfile();
  }, []);

  const uploadImage = async (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const ImageSaved = await partnerInstance().post(
      "/uploadStaffPhoto",
      data,
      config
    );
    if (ImageSaved.data.code === 200) {
      toast.success(ImageSaved.data.msg, { containerId: "B" });
      const dataa = {
        name: username.name,
        image: ImageSaved.data.userData.image,
        sections: username.sections,
        currency: username.currency,
      };
      setUsername(dataa);
      getUserProfile();
    }
  };

  return (
    <PartnerWrapper>
      {loading && <Loader />}
      <div className="partner-profile-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-section partner-profile">
                <div className="profile-card">
                  <div className="profile-img">
                    <span className="wallpaperimg">
                      <label htmlFor="upload-photo1" className="cii">
                        <div className="user-pics">
                          <img
                            src={userImage ? `${server}/${userImage}` : user}
                            alt="User"
                          />
                        </div>
                        <i className="fa fa-camera" aria-hidden="true" />
                      </label>
                      <input
                        type="file"
                        name="photo"
                        id="upload-photo1"
                        onChange={(e) => uploadImage(e)}
                      />
                    </span>
                  </div>

                  <div className="profile-form">
                    <Form onSubmit={updateProfile}>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <Form.Group controlId="formBasicloginone">
                            <Form.Label>First Name</Form.Label>
                            <div className="password-show">
                              <Form.Control
                                type="text"
                                placeholder="Your First Name"
                                name="firstname"
                                autoComplete="off"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                              />
                            </div>
                            {errors.firstname && (
                              <p className="error">{errors.firstname}</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <Form.Group controlId="formBasicloginone">
                            <Form.Label>Last Name</Form.Label>
                            <div className="password-show">
                              <Form.Control
                                type="text"
                                placeholder="Your Last Name"
                                name="lastname"
                                autoComplete="off"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label>Mobile number</Form.Label>

                            <div className="number-fields">
                              <Form.Control
                                type="number"
                                placeholder="Your mobile number"
                                name="phone"
                                autocomplete="off"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <Form.Group controlId="formBasicloginone">
                            <Form.Label>Email </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Your email address"
                              name="email"
                              autoComplete="off"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && (
                              <p className="error">{errors.email}</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-md-12 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-perple"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};
export default PartnerProfile;
