import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
//import user from '../../assets/no-image.jpg';
import newProfile from '../../assets/profile-white.png';
//import { userInstance } from '../../config/axios';
import { server } from '../../config/keys';

const MyProfileMenu = ({ username, userImage }) => {
  // eslint-disable-next-line
  const [isLogin, setIsLogin] = useState(localStorage.getItem('usertoken'));
  // eslint-disable-next-line
  const [Image, setImage] = useState(userImage);
  // eslint-disable-next-line
  const [CusName, setCusName] = useState(username);

  const LogOut = () => {
    localStorage.removeItem('usertoken');
    localStorage.removeItem('userid');
    setIsLogin(null);
    if (localStorage.getItem('admindata')) {
      localStorage.removeItem('admindata');
      window.close();
    } else {
      window.location.href = '/login';
    }
  };
  return (
    <div className="my-profile-nav">
    <div className="my-profile-box">
    <div className="user-info">
        <div className="user-pic">
          <img src={Image ? `${server}/${Image}` : newProfile} alt="User" />
        </div>
        <div className="user-detail">
          <h4>{CusName}</h4>
          <Link to="/editprofile" >Edit Profile</Link>
        </div>
      </div>

      <ul>
        <Nav>

          <NavLink to="/myappointments" className="new-menu-link">
            <i className="fa fa-calendar" />
            {' '}
            My Appointments
          </NavLink>

          <NavLink to="/myvouchers" className="new-menu-link">
            <i className="fa fa-gift" />
            {' '}
            My Vouchers
          </NavLink>

          <NavLink to="/myfavorites" className="new-menu-link">
            <i className="fa fa-heart-o" />
            {' '}
            My Favorites
          </NavLink>

          <Link onClick={() => LogOut()} className="new-menu-link">
            <i className="fa fa-power-off" />

            {' '}
            Logout
          </Link>
        </Nav>
      </ul>
    </div>

   

    </div>
  );
};

export default MyProfileMenu;
