import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { adminInstance } from '../../../config/axios';
import Helmet from 'react-helmet';

const AdminLogin = () => {
  const [passType, setPassType] = useState('password');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loginerrors, setLoginErrors] = useState({});
  useEffect(() => {
    if (
      localStorage.getItem('admintoken')
    ) {
      window.location.href = '/admin/dashboard';
    }
  }, []);

  const validateAdminLogin = (values) => {
    const errors = {};
    let isValid = true;
    if (!values.userName) {
      errors.userName = 'UserName is required';
      isValid = false;
    }
    if (!values.password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    return { isValid, errors };
  };

  const login = async (e) => {
    e.preventDefault();
    const payload = {
      userName,
      password,
    };
    const validation = validateAdminLogin({ userName, password });
    setLoginErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const loginUser = await adminInstance().post('/adminLogin', payload);
   
    const {
      code, msg, token, userid,
    } = loginUser.data;
    if (code === 200) {
      localStorage.setItem('adminid', userid);
      localStorage.setItem('admintoken', token);
      // setIsPartnerLogin(true);
      window.location.href = '/admin/dashboard';
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  return (
    <>
    <Helmet>
    <body className="admin-view" />
  </Helmet>
    <div className="login">
      <div className="login-section">
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="login-form">
                <h1>Admin Login</h1>
                <div className="login-box">
                  <Form onSubmit={login}>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Your username"
                        name="username"
                        autoComplete="off"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      {loginerrors.userName && (
                        <p className="error">{loginerrors.userName}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <div className="password-show">
                        <Form.Control
                          type={passType}
                          placeholder="Your password"
                          name="password"
                          autoComplete="off"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                          role="presentation"
                          className={
                            passType === 'password'
                              ? 'fa fa-eye-slash'
                              : 'fa fa-eye'
                          }
                          onClick={() => setPassType(
                            passType === 'password' ? 'text' : 'password',
                          )}
                        />
                      </div>
                      {loginerrors.password && (
                        <p className="error">{loginerrors.password}</p>
                      )}
                    </Form.Group>
                    {/* <div className="forget-password">
                      <Link className="forget-link" to="/admin/forget">
                        Forget Password?
                      </Link>
                    </div> */}
                    <div className="login-button">
                      <Button type="submit" className="l-btn">
                        Log in
                      </Button>
                    </div>
                  </Form>
                  {/* <p>
                    Don't have partner account yet?{' '}
                    <Link className="signup-link" to="/admin/register">
                      Sign Up
                    </Link>{' '}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AdminLogin;
