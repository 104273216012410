import React from 'react';
// import loaderimg from '../../assets/logo-loader.gif';
import Loaderimg from '../../assets/loader-new.png';
import './loader.css';

const Loader = () => (
  <>
    <div className="overlay">
      <div className="loader-img">
        <img src={Loaderimg} alt="loader" />
      </div>
    </div>
  </>
);

export default Loader;
