import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
//import location from "../../../assets/partner/placeholder-location.png";
import { partnerInstance } from "../../../config/axios";
import PartnerContext from "../../../context/context";
import history from "../../../config/history";
import Loader from "../../loader/loader";
import { server } from "../../../config/keys";
import store from "../../../assets/store/no-image.jpg";

const LocationList = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getLocations();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  // get discount type
  const getLocations = async () => {
    setLoading(true);
    const locat = await partnerInstance().get("/getPartnerLocations");

    const { code, partnerData } = locat.data;
    if (code === 200) {
      setLocations(partnerData.partnerLocation);
      setImage(partnerData.photo ? partnerData.photo : "");
      setLoading(false);
    }
  };

  return (
    <PartnerWrapper heading="Location">
      {loading && <Loader />}
      <div className="location-list-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="admin-title">
                {/* Location */}
                <div className="header-button">
                  <Link
                    className="btn btn-primary btn-perple"
                    to="/partners/locationdetail"
                  >
                    Add New Location
                  </Link>
                </div>
              </h2>
            </div>
          </div>

          <div className="location-listing">
            {locations.map((loc) => (
              <div className="location-box">
                <Link
                  to={{
                    pathname: `/partners/editlocation/${loc._id}`,
                  }}
                >
                  <div className="service-card">
                    <div className="service-body">
                      <img
                        src={image ? `${server}/${image}` : store}
                        alt="store"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = store;
                        }}
                      />
                      {/* <img src={location} alt="location" /> */}
                      <h4>{loc.locationName}</h4>
                      <p>{loc.addressFormat ? loc.addressFormat : "N/A"}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

            {/* <div className="location-box">
              <Link>
                <div className="service-card">
                  <div className="service-body">
                    <img src={location} alt="location" />
                    <h4>Location 2</h4>
                    <p>No business address added</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="location-box">
              <Link>
                <div className="service-card">
                  <div className="service-body">
                    <img src={location} alt="location" />
                    <h4>Location 3</h4>
                    <p>No business address added</p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default LocationList;
