/* eslint-disable radix */
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import ReactExport from 'react-export-excel';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';
import { client } from '../../../config/keys';
import Loader from '../../loader/loader';

const AdminCustomer = ({ name }) => {
  const [customers, setCustomers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [copy, setCopy] = useState([]);
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const getAllCustomers = useCallback(async () => {
    if (skip === 0) {
      setLoading(true);
    }
    const payload = {
      skip,
      limit,
    };
    const partnerData = await adminInstance().post('/users', payload);
    const { code, findData, length } = partnerData.data;
    const arr = [];
    if (code === 200) {
      setCustomers(findData);
      findData.forEach((el) => {
        arr.push({
          name: el.firstname,
          email: el.email,
          phone: el.phone,
          country: el.country,
          blocked: el.isBlock ? 'Yes' : 'No',
        });
        setCopy(arr);
      });
      setTotalCount(length);
      setLoading(false);
    }
  }, [skip, limit]);

  useEffect(() => {
    getAllCustomers();
  }, [getAllCustomers]);

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);

  const setUserBlock = async (id) => {
    const payload = {
      id,
    };
    const saveCategory = await adminInstance().post(
      '/blockCustomer',
      payload,
    );
    const { code } = saveCategory.data;
    if (code === 200) {
      getAllCustomers();
    }
  };

  const setAccess = async (data) => {
    const payload = {
      id: data,
    };
    const loginUser = await adminInstance().post(
      '/accessCustomerAccount',
      payload,
    );
    const {
      code, msg, token, userid,
    } = loginUser.data;
    if (code === 200) {
      localStorage.setItem('usertoken', token);
      localStorage.setItem('userid', userid);
      localStorage.setItem('admindata', 'admin');
      window.open(`${client}/myappointments`);
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  const camelCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(copy[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };

  if (loading) {
    return (
      <AdminWrapper>
        <Loader />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper name={name}>
      <div className="Admin-customers">
        <div className="container">
          <div className="partner-heading">
            {/* <h2 className="admin-title">Customers</h2> */}
            {copy.length > 0 && (
            <ExcelFile filename="Appointlee Customers" element={<button>Download Excel</button>}>
              <ExcelSheet data={copy} name="customers-list">
                {filterColumns(copy).map((col) => (
                  <ExcelColumn label={camelCase(col)} value={col} />
                ))}
              </ExcelSheet>
            </ExcelFile>
            )}
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="partner-header">
                    <h2>Customers</h2>
                  </div> */}
                  <div className="partner-table">
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th className="text-left">Name</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Country</th>
                          <th className="text-center">Phone</th>
                          <th className="text-center">Blocked</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      {customers && customers.map((el) => (
                        <tbody>
                          <tr>
                            <td className="text-left">
                              {el.firstname}
                              {' '}
                              {el.lastname}
                            </td>
                            <td className="text-center">{el.email ? el.email : 'N/A'}</td>
                            <td className="text-center">{el.country ? el.country : 'N/A'}</td>
                            <td className="text-center">{el.phone ? el.phone : 'N/A'}</td>
                            <td className="text-center">{el.isBlock ? 'Yes' : 'No'}</td>
                            <td className="text-center">
                              {/* {el.isBlock ? (
                                <Button className="delete-btn" onClick={() => setUserBlock(el._id)}> UnBlock</Button>
                              ) : (
                                <Button className="delete-btn" onClick={() => setUserBlock(el._id)}> Block</Button>
                              )}
                              {' '} */}
                              <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {el.isBlock ? (
                                    <Dropdown.Item onClick={() => setUserBlock(el._id)}>
                                      UnBlock
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item onClick={() => setUserBlock(el._id)}>
                                      Block
                                    </Dropdown.Item>
                                  )}

                                  <Dropdown.Item onClick={() => setAccess(el._id)}>
                                    Access Account
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        </tbody>
                      ))}

                    </Table>
                  </div>
                </div>
              </div>
              <div className="store-pagination col-md-12 text-center">
                {totalCount >= limit && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="pagination-section">
                      <ReactPaginate
                        previousLabel="← Previous"
                        nextLabel="Next →"
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName="pagination-chk"
                        previousLinkClassName="pagination__link"
                        nextLinkClassName="pagination__link"
                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                      />
                    </div>
                  </div>
                </div>
                )}

              </div>
            </div>
          </div>

        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminCustomer;
