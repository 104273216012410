import React, { useState, useEffect, useContext } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router";
import ImageUploading from "react-images-uploading";
import Helmet from "react-helmet";
import PartnerWrapper from "../partnerwrapper/wrapper";
import cross from "../../../assets/cross2.png";
import { partnerInstance } from "../../../config/axios";
import { server } from "../../../config/keys";
import addimg from "../../../assets/add-black.png";
import addimgwhite from "../../../assets/add.png";
import { StepCompletePrevent } from "./StepCompletePrevent";
import PartnerContext from "../../../context/context";

const SignupStep3 = () => {
  const { isStepComplete } = useContext(PartnerContext);
  StepCompletePrevent(isStepComplete);
  const [picId, setPicId] = useState();
  const [isImg, setIsImg] = useState(false);
  const [data, setData] = useState([]);
  const Step2 = () => {
    history.push("/partners/signuplocation");
  };
  const Step4 = () => {
    if (data.length > 0) history.push("/partners/signuptaxes");
    else setIsImg(true);
  };
  const Cross = () => {
    history.push("/partners/intro");
  };
  const [images, setImages] = useState([]);
  const [errors] = useState({});
  const [, /* storeImage */ setStoreImage] = useState("");
  const [id, setId] = useState("");
  const [img, setImg] = useState([]);
  const [refresh, setRefresh] = useState(1010);
  const [defaultImg, setDefaultImg] = useState();
  // const [notSetDefaultImg, setNotSetDefaultImg] = useState([]);
  // const[diff, setDiff]=useState()
  const history = useHistory();

  const maxNumber = 69;

  // const notSetDefault =async()=> {
  //   if (!diff && notSetDefaultImg.length>0 ) {
  //     // onDefault(`${server}/${notSetDefaultImg}`);
  //     const payload={
  //         data:`${server}/${notSetDefaultImg[0]}`
  //     }
  //     await partnerInstance().post(
  //       "/setDefaultImage",
  //       payload
  //     );
  //   }
  // }

  useEffect(() => {
    getImg(); // eslint-disable-next-line
  }, []);
  const getImg = async () => {
    const accountData = await partnerInstance().get("/getPartnerAccount");

    if (accountData.data.partnerData.partnerLocation[0].images) {
      setData(accountData.data.partnerData.partnerLocation[0].images);
      setImg(accountData.data.partnerData.partnerLocation[0].images);
      // setNotSetDefaultImg(
      //   accountData.data.partnerData.partnerLocation[0].images
      // );
      setPicId(accountData.data.partnerData.partnerLocation[0]._id);
      setDefaultImg(accountData.data.partnerData.photo);
      // setDiff(accountData.data.partnerData.photo);

      try {
        if (!accountData.data.partnerData.photo) {
          if (
            !accountData.data.partnerData.photo &&
            accountData.data.partnerData.partnerLocation[0].images[0]
          ) {
            const payload = {
              data: `${server}/${accountData.data.partnerData.partnerLocation[0].images[0]}`,
            };
            await partnerInstance().post("/setDefaultImage", payload);
            getImg();
          }
        }
        if(accountData.data.partnerData.partnerLocation[0].images[0])
        {
          setIsImg(false)
        }
      } catch (error) {
        console.log(error, refresh);
      }
    }
  };
  const getData = async () => {
    // setLoading(true);
    const url = window.location.pathname;
    const id = url.substring(url.lastIndexOf("/") + 1);
    setId(id);
    const payload = {
      id,
    };
    const partnersData = await partnerInstance().post(
      "/getLocationsById",
      payload
    );

    const { code, partnerData, StorePhoto } = partnersData.data;
    const dataList = [];
    // const dataList2 = [];
    if (code === 200) {
      setStoreImage(StorePhoto.photo ? `${server}/${StorePhoto.photo}` : "");
      if (partnerData.partnerLocation[0].images.length > 0) {
        partnerData.partnerLocation[0].images.forEach((el) => {
          dataList.push({ data_url: `${server}/${el}` });
          setImages(dataList);
        });
      } else {
        setImages([]);
      }

      // setLoading(false);
    }
  };

  const onChange = async (imageList) => {
    setData(imageList);
    setImages(imageList);
    if (imageList !== []) {
      const data = new FormData();
      imageList.forEach((el) => {
        data.append("file", el.file);
      });
      data.append("_id", id);
      data.append("picId", picId);
      const payload = {
        _id: id,
        photoId: picId,
      };

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const image = await partnerInstance().post(
        "/uploadpic1",
        data,
        config,
        payload
      );

      if (image.data.code === 200) {
        getData();
        setRefresh(image.data.code);
        getImg();
        setImages([]);
        setData([]);
      }
    }
  };

  const onRemove = async (data) => {
    const payload = {
      data: `${server}/${data}`,
      id: picId,
    };
    const remove = await partnerInstance().post("/removeImage", payload);
    if (remove.data.code === 200) {
      getData();
      getImg();
    }
  };

  const onDefault = async (data, e) => {
    if (e.target.checked) {
      const payload = {
        data,
      };
      const defaultImage = await partnerInstance().post(
        "/setDefaultImage",
        payload
      );

      if (defaultImage.data.code === 200) {
        getData();
        getImg();
      }
    } else {
      const payload = {
        data: "",
      };
      // eslint-disable-next-line
      const defaultImage = await partnerInstance().post(
        "/setDefaultImage",
        payload
      );
    }
  };

  //console.log("not called  ::::::::::::>", notSetDefaultImg, defaultImg);

  //if(notSetDefaultImg && !defaultImg)

  // useEffect(()=>{
  //   notSetDefault()
  // },[])

  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form photo">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3> Photos </h3>
                    <div className="signup-progress">
                      <ProgressBar now={36} />
                    </div>
                  </div>
                  <div className="signup-body">
                    <div className="signup-location signup-photo-container">
                      <div className="signup-btn">
                        <Button className="btn btn-perple">Add Photo</Button>
                      </div>
                      {/* <div className="signup-photo">
                        <img src={`${server}/${defaultImg}`} alt="" />
                      </div> */}
                      <div
                        className={
                          defaultImg
                            ? "signup-photo adjustImg"
                            : "signup-photo "
                        }
                      >
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="uploaad">
                              <div className="upload__image-wrapper">
                                <div className="signup-photo">
                                  <img src={`${server}/${defaultImg}`} alt="" />
                                </div>
                                <div
                                  className="uploadimg"
                                  style={isDragging ? { color: "red" } : null}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                  role="presentation"
                                >
                                  <img
                                    src={addimg}
                                    alt=""
                                    className="dark-icon"
                                  />
                                  <img
                                    src={addimgwhite}
                                    alt=""
                                    className="white-icon"
                                  />
                                </div>
                                <div />
                              </div>
                              <div className="image-item-section">
                                {img &&
                                  img.map((el, index) => (
                                    <div key={index} className="image-item">
                                      {console.log("Index ::", index)}
                                      <img
                                        src={`${server}/${el}`}
                                        alt=""
                                        width="100"
                                      />
                                      <span
                                        role="presentation"
                                        // onClick={() => onImageRemove(index)}
                                        onClick={() => onRemove(el)}
                                      >
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <>
                                        <div role="presentation">
                                          <div className="online-booking">
                                            <label className="switch">
                                              <input
                                                type="radio"
                                                checked={
                                                  el === defaultImg
                                                    ? "checked"
                                                    : ""
                                                }
                                                id="a"
                                                name="default"
                                                onClick={(e) =>
                                                  onDefault(
                                                    `${server}/${el}`,
                                                    e
                                                  )
                                                }
                                              />
                                              <span className="slider round" />
                                            </label>
                                          </div>
                                          Set As Default
                                        </div>
                                      </>
                                    </div>
                                  ))}
                              </div>
                              {errors.images && (
                                <p className="error">{errors.images}</p>
                              )}
                              {errors.storeImage && (
                                <p className="error">{errors.storeImage}</p>
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                      {isImg && <p className="error">image required</p>}
                    </div>
                  </div>
                </div>
                {/* <div className="signup-right"> */}
                <div className="signup-footer-btn">
                  <Button type="submit" onClick={Step2}>
                    Back
                  </Button>
                  <Button type="submit" onClick={Step4}>
                    Next
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};
export default SignupStep3;
