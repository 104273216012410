// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import {
  Form,
  Table,
  // Dropdown,
  Button,
  Modal,
  // Tab,
  // FormControl,
} from "react-bootstrap";
// import Tabs from 'react-bootstrap/Tabs';
// import InputGroup from 'react-bootstrap/InputGroup';
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import moment from "moment";
import { partnerInstance } from "../../../config/axios";
import { validateShiftTiming } from "../../../function/validate";
// import history from '../../../config/history';
import "./staff.css";
//import PartnerContext from '../../../context/context';

const StaffWorkingHour = ({ closedDate, setClosedDate, weekStart }) => {
  //const { username } = useContext(PartnerContext);
  // const { weekStart } = username;

  const [show, setShow] = useState(false);
  const [call, setCall] = useState(false);
  const [onecall, setOneCall] = useState(false);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(currentDate);
  const [staffs, setStaffs] = useState([]);
  const [staffId, setStaffId] = useState("");
  const [dontrepeatRecord, setDontrepeatRecord] = useState([]);
  const [, /*display*/ setDisplay] = useState([]);
  // eslint-disable-next-line
  const [singleShift, setSingleShift] = useState([]);
  const [starttime, setStarttime] = useState("");
  const [endtime, setEndtime] = useState("");
  const [shiftStart, setShiftStart] = useState("");
  const [shiftEnd, setShiftEnd] = useState("");
  const [shiftPanel, setShiftPanel] = useState([
    {
      starttime: "09:00",
      endtime: "15:00",
    },
  ]);
  const [shiftRepeat, setShiftRepeat] = useState("weekly");
  const [shiftDate, setShiftDate] = useState("");
  const [weekList, setWeekList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [errors, setErrors] = useState({});
  const [firstDay, setFirstDay] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [validd, setValidd] = useState(true);
  const [timeStartData, setStartTimeData] = useState([]);
  const date = new Date();
  date.setDate(date.getDate() + 5);
  const [endDate, setEndDate] = useState(date);
  const [copyStff, setCopyStaff] = useState([]);
  const [partnerSlot, setPartnerSlot] = useState([]);
  //const [shiftid, setShiftID] = useState('');
  const arr = [];
  const validdd = true;

  const getPartnerLocation = async () => {
    const partnersData = await partnerInstance().get("/getPartnerAccount");
    const { code, partnerData } = partnersData.data;
    if (code === 200) {
      setPartnerSlot(partnerData.partnerLocation[0].availability);
    }
  };

  const time = () => {
    const x = 5; // minutes interval
    const timeDataList = []; // time array
    let tt = 0; // start time
    // const ap = ['AM', 'PM']; // AM-PM

    // loop to increment the time and push results in array
    for (let i = 0; tt < 24 * 60; i++) {
      const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      const mm = tt % 60; // getting minutes of the hour in 0-55 format
      timeDataList[i] = `${`0${hh % 24}`.slice(-2)}:${`0${mm}`.slice(-2)}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt += x;
    }
    setStartTimeData(timeDataList);
    return timeDataList;
  };

  const getDaysArray = (start, end) => {
    for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const getDateRange = (startDate, endDate) => {
    const curr = new Date(startDate);
    const next = new Date(endDate);

    // console.log('First & last day ::>',curr, next)
    // curr.setDate(curr.getDate()+7);
    // next.setDate(next.getDate()+7);
    console.log("First & last day :::::::::::::::::::::::>", curr, next);

    setFirstDay(curr.toString());
    setLastDay(next.toString());
  };
  useEffect(() => {
    const a = new Date(startDate);

    const b = new Date(a);
    b.setDate(a.getDate() - 1);

    const aa = new Date(b);
    const bb = new Date(aa);
    bb.setDate(aa.getDate() - 1);

    const aaa = new Date(bb);
    const bbb = new Date(aaa);
    bbb.setDate(aaa.getDate() - 1);

    const aaaa = new Date(bbb);
    const bbbb = new Date(aaaa);
    bbbb.setDate(aaaa.getDate() - 1);

    const aaaaa = new Date(bbbb);
    const bbbbb = new Date(aaaaa);
    bbbbb.setDate(aaaaa.getDate() - 1);

    const aaaaaa = new Date(bbbbb);
    const bbbbbb = new Date(aaaaaa);
    bbbbbb.setDate(aaaaaa.getDate() - 1);
    let startWeek;

    if (weekStart.toString().substring(0, 3) === a.toString().substring(0, 3)) {
      setStartDate(a);
      startWeek = a;
    } else if (
      weekStart.toString().substring(0, 3) === b.toString().substring(0, 3)
    ) {
      setStartDate(b);
      startWeek = b;
    } else if (
      weekStart.toString().substring(0, 3) === bb.toString().substring(0, 3)
    ) {
      setStartDate(bb);
      startWeek = bb;
    } else if (
      weekStart.toString().substring(0, 3) === bbb.toString().substring(0, 3)
    ) {
      setStartDate(bbb);
      startWeek = bbb;
    } else if (
      weekStart.toString().substring(0, 3) === bbbb.toString().substring(0, 3)
    ) {
      setStartDate(bbbb);
      startWeek = bbbb;
    } else if (
      weekStart.toString().substring(0, 3) === bbbbb.toString().substring(0, 3)
    ) {
      setStartDate(bbbbb);
      startWeek = bbbbb;
    } else if (
      weekStart.toString().substring(0, 3) === bbbbbb.toString().substring(0, 3)
    ) {
      setStartDate(bbbbbb);
      startWeek = new Date(bbbbbb);
    }
    // console.log('start week ::>',startWeek)
    // startWeek.setDate(startWeek.getDate()+7);
    //console.log('start week ::>',startWeek)

    const endWeek = new Date(startWeek);
    endWeek.setDate(endWeek.getDate() + 6);
    setEndDate(endWeek);

    const daylist = getDaysArray(startWeek, new Date(endWeek));
    setWeekList(daylist);

    // str.substring(1, 4);
    const getStaffList = async () => {
      const staffData = await partnerInstance().get("/getStaffs");
      const { code, stafflist } = staffData.data;
      if (code === 200) {
        setStaffs(stafflist);
        setDontrepeatRecord(stafflist[0].dontrepeat);
      }
    };

    // getClosedDate();
    getDateRange(startWeek, endWeek);
    getStaffList();
    getAllShift();
    time();
    getPartnerLocation();
  }, [weekStart, onecall]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllShift = async () => {
    const allShiftData = await partnerInstance().get("/getAllShift");
    const { code, shiftlist } = allShiftData.data;

    if (code === 200) {
      setShiftList(shiftlist);
      setCopyStaff(shiftlist);
      // const check = shiftlist[0].shift.some((ele)=> ele.date === moment(shiftlist[0].shift[5].date).toDate());
      // if (check) {
      const now = moment().format("YYYY-MM-DD");

      if (shiftlist.length > 0) {
        shiftlist.forEach((ele) => {
          const end = moment(ele.shift[5].date).add(1, "d").toDate();

          const filterr = ele.shift.find(
            (it) => moment(it.date).format("YYYY-MM-DD") === now
          );

          if (!filterr) {
            end.setDate(end.getDate() + 1);
            setStartDate(end);
            const enddate = new Date(end);
            enddate.setDate(enddate.getDate() + 6);
            setEndDate(enddate);
            const dayslist = getDaysArray(new Date(enddate));
            setWeekList(dayslist);
            getDateRange(new Date(end + 1), new Date(enddate));
            shiftlist.forEach((good, i) => {
              const filterWeek = good.shift.filter(
                (el) =>
                  el.repeat === "weekly" &&
                  new Date(el.date) < new Date(end + 1),
                new Date(enddate)
              );
              filterWeek.forEach(async (item) => {
                const filterDay = dayslist.filter(
                  (ele) =>
                    ele.getDay() === new Date(item.date).getDay() &&
                    ele >= new Date(item.date)
                );
                const onlyListing = good.shift;
                const filter = onlyListing.filter(
                  (great) => great.date >= filterDay[0]
                );
                if (filter.length === 0) {
                  setShiftRepeat("weekly");
                  const obj = {
                    _id: item._id,
                    date: filterDay[0],
                    repeat: "dontrepeat",
                    timing: item.timing,
                  };
                  onlyListing.push(obj);
                }
              });
            });
          }
        });
      }
    }
  };

  const handleAfterRange = async () => {
    endDate.setDate(endDate.getDate() + 1);
    setStartDate(endDate);
    const enddate = new Date(endDate);
    enddate.setDate(enddate.getDate() + 6);
    setEndDate(enddate);
    const dayslist = getDaysArray(new Date(endDate), new Date(enddate));
    setWeekList(dayslist);
    //console.log('next:::::::',dayslist)
    getDateRange(new Date(endDate + 1), new Date(enddate));
    console.log("next:::::::", endDate + 1, new Date(enddate));

    shiftList.forEach((good, i) => {
      const filterWeek = good.shift.filter(
        (el) =>
          el.repeat === "weekly" && new Date(el.date) < new Date(endDate + 1),
        new Date(enddate)
      );

      filterWeek.forEach(async (item) => {
        const filterDay = dayslist.filter(
          (ele) =>
            ele.getDay() === new Date(item.date).getDay() &&
            ele >= new Date(item.date)
        );
        const onlyListing = good.shift;
        const filter = onlyListing.filter(
          (great) => great.date >= filterDay[0]
        );
        if (filter.length === 0) {
          setShiftRepeat("weekly");
          const obj = {
            _id: item._id,
            date: filterDay[0],
            repeat: "weekly",
            timing: item.timing,
          };
          onlyListing.push(obj);
        }
      });
    });
  };

  const handleBeforeRange = async () => {
    startDate.setDate(startDate.getDate() - 1);
    setEndDate(startDate);
    const enddate = new Date(startDate);
    enddate.setDate(enddate.getDate() - 6);
    setStartDate(enddate);
    const dayslist = getDaysArray(new Date(enddate), new Date(startDate));
    setWeekList(dayslist);
    getDateRange(new Date(enddate), new Date(startDate));
  };

  const handleCalendar = (e) => {
    const end = new Date(e);
    end.setDate(end.getDate() + 6);
    setEndDate(end);
    setStartDate(e);
    const dayslist = getDaysArray(new Date(e), new Date(end));
    setWeekList(dayslist);
    getDateRange(new Date(e), new Date(end));
    getAllShift();
  };

  const getStaffById = async (id) => {
    const old = [...copyStff];
    const filter = old.filter((el) => el._id === id);
    const data = [filter[0]];
    setShiftList(data);
    setStaffId(id);
  };

  const getShiftById = async (id, staffid) => {
    const ID = {
      shiftDate: id,
      staffId: staffid,
    };
    const shiftdata = await partnerInstance().post("/getShiftById", ID);
    const { code, shiftData } = shiftdata.data;
    if (code === 200) {
      if (shiftData.length > 0) {
        const setFullName = moment(shiftData[0].date).format("dddd");
        const filterSlots = partnerSlot.filter(
          (item) => item.Day === setFullName
        );
        const getStartTime = filterSlots[0].startTime;
        const getEndTime = filterSlots[0].endTime;
        const timeStartDate = moment(getStartTime, "hh:mm A").format("HH:mm");
        const timeEndDate = moment(getEndTime, "hh:mm A").format("HH:mm");
        if (shiftData.length > 0) {
          setShiftPanel(shiftData[0].timing);
          setShiftRepeat(shiftData[0].repeat);
        } else {
          setShiftPanel([
            {
              starttime: timeStartDate,
              endtime: timeEndDate,
            },
          ]);
        }
        setShow(true);
        setCall(true);
      } else {
        setShow(true);
        setCall(true);
      }
    }
  };

  const handleStaffSelect = (id) => {
    if (id === "all") {
      setShiftList(copyStff);
    } else {
      getStaffById(id);
    }
  };

  const handleShowEdit = (staffid, shiftdate, id, shiftrepeat) => {
    setShiftRepeat(shiftrepeat);
    setStaffId(staffid);
    setShiftDate(shiftdate);
    //setShiftID(id);
    getShiftById(id, staffid);
  };

  const handleShift = (shiftArr, week, weekList, dont, shiftNext) => {
    //----start-- do not remove commented code -------

    console.log("coming array shift::", shiftArr);
    console.log("coming array shift::", weekList);

    // let len = shiftArr.length;
    // console.log("length ::", len);
    // shiftArr=[{}]
    // let adjustArr = [];
    // if (shiftArr.length > 6) {
    //   for (let i = len - 6; i < len; i++) {
    //     adjustArr.push(shiftArr[i]);
    //     console.log("i :::::::::::::::::::::::::::::::>", i);
    //   }

    //   // shiftArr=[];
    //   // shiftArr=adjustArr;
    //   console.log("adjsut::", adjustArr);
    //   console.log("shiftArr::", shiftArr);
    // }

    //-----------------------------
    // let fil =
    //   shiftArr &&
    //   shiftArr.filter(
    //     (el) =>
    //       new Date(el.date).toString().substring(0, 2) ===
    //       weekStart.toString().substring(0, 2)
    //   );
    // let dayNumber = new Date(fil[0].date).getDay();
    // console.log("Day number ::", dayNumber);
    // let fHalfArr =
    //   shiftArr &&
    //   shiftArr.filter((el) => new Date(el.date).getDay() >= dayNumber);

    // let sHalfArr =
    //   shiftArr &&
    //   shiftArr.filter((el) => new Date(el.date).getDay() < dayNumber);
    
    // let newArr = [];
    // let date;
    // console.log(fHalfArr.length);
    // for (let i = 0; i < fHalfArr.length; i++) {
    //   date = new Date(fHalfArr[i].date.toString());
    //   let dt = date.getDate();
    //   dt = dt - 7;
    //   date.setDate(dt);
    //   date = date.toString();
    //   newArr.push({ ...fHalfArr[i], date });
    // }
    // console.log("first half ::", fHalfArr);
    // console.log("second half ::", sHalfArr);
    // console.log("new Array ::", newArr);
    // shiftNext = {};
    // shiftArr = newArr.concat(sHalfArr);
    // console.log("final:: ", shiftArr);

    //------------------------------------------------end-----------------------

    shiftNext = shiftNext && shiftNext.length > 0 && [...shiftNext].reverse();
    let shifts = shiftArr.filter(
      (e) =>
        new Date(e.date).toString().substring(0, 10) ===
        new Date(week).toString().substring(0, 10)
    );
    let dt;
    if (shifts && shifts.length > 0) {
      dt =
        dont &&
        dont.filter(
          (el) =>
            el.sdate.toString().substring(0, 15) ===
            shifts[0].date.toString().substring(0, 15)
        );
    }
    let next;
    if (shifts && shifts.length > 0) {
      next =
        shiftNext &&
        shiftNext.length > 0 &&
        shiftNext.filter(
          (el) =>
            new Date(el.date).toString().substring(0, 3) ===
              new Date(week).toString().substring(0, 3) &&
            moment(new Date(week).toString().substring(0, 15)).isSameOrAfter(
              new Date(el.date).toString().substring(0, 15)
            )
        );
    }

    if (dt && dt.length > 0) {
      const shi = [
        {
          date: dt[0].sdate,
          timing: [
            {
              starttime: dt[0].starttime,
              endtime: dt[0].endtime,
            },
          ],
        },
      ];

      return shi;
    } else if (next && next.length > 0) {
      let date = week;
      let newNext = [{ ...next[0], date }];

      return newNext;
    } else {
      return shifts;
    }
  };

  const handleCloseDate = (closedDate, ell, weekList) => {
    const close =
      closedDate &&
      closedDate.filter(
        (e) =>
          new Date(ell).getTime() >= new Date(e.from).getTime() &&
          new Date(ell).getTime() <= new Date(e.to).getTime()
      );
    return close;
  };

  const handleClose = () => {
    setValidd(true);
    setShow(false);
  };

  const setToday = async () => {
    const now = new Date(moment().clone().weekday(1));
    const end = new Date(now);
    end.setDate(end.getDate() + 6);
    setEndDate(end);
    setStartDate(now);
    const dayslist = getDaysArray(new Date(now), new Date(end));
    setWeekList(dayslist);
    getDateRange(new Date(now + 1), new Date(end));
    await getAllShift();
    setCall(true);
    // shiftList.forEach((good, i) => {
    //   const filterWeek = good.shift.filter(
    //     (el) => el.repeat === 'weekly' && new Date(el.date) < new Date(end + 1),
    //     new Date(end),
    //   );
    //   filterWeek.forEach(async (item) => {
    //     const filterDay = dayslist.filter(
    //       (ele) => ele.getDay() === new Date(item.date).getDay()
    //         && ele >= new Date(item.date),
    //     );
    //     const onlyListing = good.shift;
    //     const filter = onlyListing.filter(
    //       (great) => great.date >= filterDay[0],
    //     );
    //     if (filter.length === 0) {
    //       setShiftRepeat('weekly');
    //       const obj = {
    //         _id: item._id,
    //         date: filterDay[0],
    //         repeat: 'dont repaet',
    //         timing: item.timing,
    //       };
    //       onlyListing.push(obj);
    //     }
    //   });
    // });
  };
  const compare = function (days) {
    if (dontrepeatRecord && dontrepeatRecord.length > 0 && weekList) {
      for (let i = 0; i < dontrepeatRecord.length; i++) {
        for (let j = 0; j < days.length; j++) {
          if (
            dontrepeatRecord[i].sdate.toString().substring(0, 10) ===
            days[j].toString().substring(0, 10)
          ) {
            return dontrepeatRecord[i];
          }
          // else
          // {
          //   return false
          // }
        }
      }
    }
  };
  useEffect(() => {
    setDisplay(compare(weekList));
  }, [dontrepeatRecord]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="staff-working">
      <div className="container">
        <div className="shift-filter">
          <div className="schedule-filters">
            <div className="schedule-date-toolbar">
              <div className="btn-group js-date-toolbar">
                <div
                  className="btn btn-default navigate"
                  data-action="previous"
                  title="Previous"
                >
                  <i
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                    onClick={handleBeforeRange}
                  />
                </div>
                <div
                  role="presentation"
                  className="btn btn-default hidden-xs navigate active"
                  data-action="today"
                  onClick={() => setToday()}
                >
                  Today
                </div>
                <div
                  className="btn btn-default select-date"
                  title="Change Date"
                >
                  <span className="display-date">
                    {firstDay.substring(4, 10)} -{lastDay.substring(4, 10)}
                  </span>
                  <Form.Control
                    // type="date"
                    id="birthday"
                    onChange={(e) => handleCalendar(e.target.value)}
                    name="birthday"
                  />
                  {/* <input
                    type="date"
                    name="date_from"
                    id="date_from"
                    value="jjj"
                    // readOnly="readonly"
                  /> */}
                </div>
                <div
                  className="btn btn-default navigate"
                  data-action="next"
                  title="Next"
                >
                  <i
                    className="fa fa-arrow-right"
                    onClick={handleAfterRange}
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>

            <div className="form-inline">
              <div className="form-group">
                <div className="select-wrapper hidden" />
              </div>
              <div className="form-group">
                <div className="select-wrapper">
                  <select
                    name="employee_id"
                    id="employee_id"
                    className="form-control js-schedule-employee"
                    onChange={(e) => handleStaffSelect(e.target.value)}
                  >
                    <option value="all">All</option>
                    {staffs.map((el, i) => (
                      <>
                        <option key={i} value={el._id}>
                          {el.firstname} {el.lastname}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shift-calendar">
          <div className="partner-table">
            <Table bordered responsive="lg">
              <thead>
                <tr>
                  <th>Associates</th>
                  {weekList.map((el, i) => (
                    <>
                      <th key={i}>
                        {new Date(el).toString().substring(0, 10)}
                      </th>
                    </>
                  ))}{" "}
                  {/* <th>Sat 14 Nov</th> */}
                </tr>
              </thead>

              <tbody>
                {shiftList.map((elem, i) => (
                  <tr key={i}>
                    <td>{elem.firstname}</td>
                    {weekList &&
                      weekList.map((ell, l) =>
                        handleCloseDate(closedDate, ell, weekList).length >
                        0 ? (
                          <td className="add-shift" key={l}>
                            closed
                          </td>
                        ) : handleShift(
                            elem.shift,
                            ell,
                            weekList,
                            elem.dontrepeat,
                            elem.shiftNext
                          ).length > 0 ? (
                          handleShift(
                            elem.shift,
                            ell,
                            weekList,
                            elem.dontrepeat,
                            elem.shiftNext
                          ).map((item, i) => {
                            return (
                              <td
                                key={i}
                                role="presentation"
                                onClick={() =>
                                  handleShowEdit(
                                    elem._id,
                                    item.date,
                                    item._id,
                                    item.shiftRepeat
                                  )
                                }
                              >
                                {" "}
                                {item &&
                                  item.timing.map((e, i) => (
                                    <p key={i}>
                                      {e.starttime}-{e.endtime}
                                    </p>
                                  ))}
                              </td>
                            );
                          })
                        ) : (
                          <td
                            role="presentation"
                            className="sunday-shift"
                            onClick={() => handleShowEdit(elem._id, ell)}
                          >
                            <i className="fa fa-plus" />
                          </td>
                        )
                      )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal
        className="closedatemodal edithour"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title ">
            Edit Associate Hours
          </Modal.Title>
          <span>{new Date(shiftDate).toString().substring(0, 10)}</span>
        </Modal.Header>
        {call ? (
          <EditShift
            setShow={setShow}
            setOneCall={setOneCall}
            singleShift={singleShift}
            setShiftStart={setShiftStart}
            setShiftEnd={setShiftEnd}
            setShiftPanel={setShiftPanel}
            shiftPanel={shiftPanel}
            shiftStart={shiftStart}
            shiftEnd={shiftEnd}
            setStarttime={setStarttime}
            setEndtime={setEndtime}
            endtime={endtime}
            starttime={starttime}
            setShiftRepeat={setShiftRepeat}
            shiftRepeat={shiftRepeat}
            shiftDate={shiftDate}
            setShiftDate={setShiftDate}
            staffId={staffId}
            setWeekList={setWeekList}
            setErrors={setErrors}
            errors={errors}
            setShiftList={setShiftList}
            // setReflectChange={setReflectChange}
            timeStartData={timeStartData}
            handleClose={handleClose}
            getAllShift={() => getAllShift()}
            setValidd={setValidd}
            validd={validd}
            validdd={validdd}
            partnerSlot={partnerSlot}
            setStartTimeData={setStartTimeData}
            copyStff={copyStff}
            getStaffById={getStaffById}
            setToday={setToday}
          />
        ) : (
          <p>Closed</p>
        )}
      </Modal>
    </div>
  );
};

export default StaffWorkingHour;

function EditShift({
  handleClose,
  timeStartData,
  // shift,
  // shiftId,
  setShow,
  staffId,
  // staffname,
  singleShift,
  setShiftStart,
  setShiftEnd,
  shiftStart,
  shiftEnd,
  setShiftPanel,
  shiftPanel,
  starttime,
  endtime,
  setShiftRepeat,
  shiftRepeat,
  shiftDate,
  setShiftDate,
  setStarttime,
  setEndtime,
  setWeekList,
  setErrors,
  errors,
  setValidd,
  validd,
  validdd,
  partnerSlot,
  setStartTimeData,
  setShiftList,
  // eslint-disable-next-line
  copyStff = { copyStff },
  getStaffById,
  setOneCall,
  setToday,
}) {
  setOneCall(false);

  const selectStartTime = (e, index) => {
    if (endtime) {
      if (e.target.value > endtime) {
        // alert('starttime should be less than endtime');
        validdd = false;
        setValidd(validdd);
      } else {
        validdd = true;
        setValidd(validdd);
      }
    }
    setStarttime(e.target.value);
    shiftPanel.forEach((el, j) => {
      if (index === j) {
        el.starttime = e.target.value;
      }
    });
    setShiftPanel(shiftPanel);
  };

  const selectEndTime = (e, index) => {
    if (starttime > e.target.value) {
      // alert('starttime should be less than endtime');
      validdd = false;
      setValidd(validdd);
    } else {
      validdd = true;
      setValidd(validdd);
    }
    setEndtime(e.target.value);
    shiftPanel.forEach((el, j) => {
      if (index === j) {
        el.endtime = e.target.value;
      }
    });
    setShiftPanel(shiftPanel);
  };

  const addMoreShift = async () => {
    const oldArray = [...shiftPanel];
    const secondsToMinutes = oldArray[0].endtime;
    const date = moment(secondsToMinutes, "hh:mm A")
      .add(1, "hours")
      .format("HH:mm");
    oldArray.push({
      starttime: date,
      endtime: "23:55",
    });

    setShiftPanel(oldArray);
  };

  const deleteShiftPanel = (id) => {
    const oldArray = [...shiftPanel];
    oldArray.pop();
    setShiftPanel([...oldArray]);
  };

  const updateShift = async (e) => {
    e.preventDefault();
    singleShift.forEach((el, i) => {
      setShiftDate(el.date);
    });

    const payloadd = {
      shiftDate,
      shiftPanel,
      shiftRepeat,
      staffId,
    };

    const validation = validateShiftTiming({
      shiftPanel,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return true;
    }
    if (!validd) {
      return true;
    }
    const updateshift = await partnerInstance().put("/editShift/", payloadd);
    const { code, msg } = updateshift.data;
    if (code === 200) {
      // setShiftList(copyStff);
      setOneCall(true);
      setShow(false);
      setToday();
      // getStaffById(staffId);

      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <>
      <Form onSubmit={updateShift}>
        <Modal.Body>
          <Row>
            {/* {el.timing.map((ele) => ( */}

            {shiftPanel.map((ele, i, array) => (
              <>
                <div className="col-md-6" key={i}>
                  <Form.Group>
                    <Form.Label>Shift Start</Form.Label>
                    <Form.Control
                      as="select"
                      value={ele.starttime}
                      // value={shiftPanel[ele].}
                      onChange={(e) => selectStartTime(e, i)}
                    >
                      {/* <option>{ele.starttime}</option> */}
                      {/* <option value={ele.starttime}>{ele.starttime}</option> */}
                      {timeStartData.map((el, i) => (
                        <option key={i} value={el}>
                          {el}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Shift End</Form.Label>
                    {array.length > 1 && (
                      <i
                        onClick={() => deleteShiftPanel(i)}
                        className="fa fa-times cross"
                        aria-hidden="true"
                      />
                    )}

                    <Form.Control
                      as="select"
                      value={ele.endtime}
                      onChange={(e) => selectEndTime(e, i)}
                    >
                      {/* <option>{ele.endtime}</option> */}
                      {/* <option value={ele.endtime}>{ele.endtime}</option> */}
                      {timeStartData.map((elem, j) => (
                        <option key={j} value={elem}>
                          {elem}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  {errors.shiftPanel && (
                    <p className="error">{errors.shiftPanel}</p>
                  )}
                  {!validd ? (
                    <p className="error">
                      Start time should be less than end time
                    </p>
                  ) : (
                    ""
                  )}
                  <Form.Group>
                    {array.length < 2 && (
                      <Button className="add-shift" onClick={addMoreShift}>
                        Add another shift
                      </Button>
                    )}
                  </Form.Group>
                </div>
              </>
            ))}

            <div className="col-md-12">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Repeat</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => setShiftRepeat(e.target.value)}
                  value={shiftRepeat}
                >
                  <option value="dontrepeat">Don't repeat</option>
                  <option value="weekly">Weekly</option>
                </Form.Control>
              </Form.Group>
            </div>
          </Row>
          {/* <Button type="submit">Submit</Button> */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="danger">
                                Delete
                            </Button> */}
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn-perple"
          >
            Close
          </Button>
          <Button type="submit" variant="primary" className="btn-perple">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
}
