/* eslint-disable radix */
import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import AdminWrapper from "../adminwrapper/wrapper";
import { adminInstance } from "../../../config/axios";
import Loader from "../../loader/loader";
import { countries } from "./countries";

const AdminPricing = ({ name }) => {
  const [pricingList, setPricingList] = useState([]);
  const [country, setCountry] = useState("AFGHANISTAN");
  const [currency, setCurrency] = useState("AFA");
  const [monthlyFee, setMonthlyFee] = useState("");
  const [commission, setCommission] = useState("");
  const [fixedCharge, setFixedCharge] = useState("");
  const [include, setInclude] = useState(false);
  const [errors, setErrors] = useState({});
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(false);
  const [show2, setshow2] = useState(false);
  const handleClose2 = () => setshow2(false);
  const [bussId, setBussId] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllBussiness = async () => {
    setLoading(true);
    const pricingData = await adminInstance().get("/pricingList");
    const { code, findData } = pricingData.data;
    if (code === 200) {
      setPricingList(findData.commisionPricing);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBussiness();
  }, []);

  const validateBusiness = (values) => {
    const errorss = {};
    let isValid = true;

    if (!values.monthlyFee.replace(/ /g, "")) {
      errorss.monthlyFee = "Monthly Fee is required";
      isValid = false;
    }
    if (!values.commission.replace(/ /g, "")) {
      errorss.commission = "Commission is required";
      isValid = false;
    } else if (values.commission) {
      const x = parseFloat(values.commission);
      if (isNaN(x) || x < 0 || x > 100) {
        errorss.commission =
          "Discount value must be higher than 0 and equal or lower than 100";
        isValid = false;
      }
    }
    if (!values.fixedCharge.replace(/ /g, "")) {
      errorss.fixedCharge = "Fixed Charge is required";
      isValid = false;
    }

    return { isValid, errorss };
  };

  const addBusiness = async (e) => {
    e.preventDefault();
    const payload = {
      monthlyFee,
      commission,
      fixedCharge,
      country,
      currency,
      include,
    };
    const validation = validateBusiness({
      monthlyFee,
      commission,
      fixedCharge,
    });
    setErrors(validation.errorss);
    if (!validation.isValid) {
      return;
    }
    const saveData = await adminInstance().post("/pricing/add", payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      handleClose();
      getAllBussiness();
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  const handleEdit = (data) => {
    setBussId(data._id);
    setCountry(data.country);
    setCurrency(data.currency);
    setMonthlyFee(
      +data.updatedMonthlyFee === 0 ? data.monthlyFee : data.updatedMonthlyFee
    );
    setCommission(data.commission);
    setFixedCharge(data.fixedCharge);
    setInclude(data.include);
    setshow2(true);
  };

  const handleCountrySelect = async (e) => {
    setCountry(e.target.value);
    countries.forEach((el) => {
      if (el.COUNTRY === e.target.value) {
        setCurrency(el.CURRENCY_CODE);
      }
    });
  };

  const deletePricing = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this Pricing ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const deleteCategor = await adminInstance().post("/deletePricing", {
              deleteId: id,
            });
            const { code, msg } = deleteCategor.data;
            if (code === 200) {
              toast.success(msg, { containerId: "B" });
              getAllBussiness();
            } else {
              toast.error(msg, { containerId: "B" });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleShow = () => {
    setCountry("");
    setCurrency("");
    setMonthlyFee("");
    setCommission("");
    setFixedCharge("");
    setInclude(false);
    setshow(true);
  };

  if (loading) {
    return (
      <AdminWrapper>
        <Loader />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper name={name}>
      <div className="Admin-client">
        <div className="container">
          <div className="admin-main-heading">
            <h2 className="admin-title">Pricing</h2>
            <div className="text-right">
              <Button className="add-business" onClick={() => handleShow()}>
                Add Pricing
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="partner-header">
                    <h2>Pricing</h2>
                  </div>
                  <div className="partner-table">
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th className="text-left">Country</th>
                          <th className="text-center">Currency</th>
                          <th className="text-center">Monthly Fee</th>
                          <th className="text-center">Commission(%)</th>
                          <th className="text-center">Fixed Charge</th>
                          <th className="text-center bracket">
                            Monthly Fee Reqiured
                            <span>
                              (Minimum monthly fee is part of commission or not)
                            </span>{" "}
                          </th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      {pricingList &&
                        pricingList.map((el) => (
                          <tbody>
                            <tr>
                              <td className="text-left">{el.country}</td>
                              <td className="text-center">{el.currency}</td>
                              <td className="text-center">
                                {+el.updatedMonthlyFee === 0
                                  ? el.monthlyFee
                                  : el.updatedMonthlyFee}
                              </td>
                              <td className="text-center">{el.commission}</td>
                              <td className="text-center">{el.fixedCharge}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={el.include} />
                                  <span className="slider round" />
                                </label>
                              </td>
                              <td className="text-center">
                                <Button
                                  className="delete-btn"
                                  onClick={() => handleEdit(el)}
                                >
                                  {" "}
                                  Edit
                                </Button>{" "}
                                <Button
                                  className="delete-btn"
                                  onClick={() => deletePricing(el._id)}
                                >
                                  {" "}
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            className="discount-12 add-pricing"
          >
            <Modal.Header closeButton>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                Add Pricing
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={addBusiness}>
                <Form.Group>
                  <Form.Label>Countries</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={country}
                    placeholder="Select Country"
                    selected={country}
                    value={country}
                    onChange={(e) => handleCountrySelect(e)}
                  >
                    {countries &&
                      countries.map((el, i) => (
                        <option value={el.COUNTRY}>{el.COUNTRY}</option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Currencies</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={currency}
                    placeholder="Select Country"
                    selected={currency}
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    {countries &&
                      countries.map((el, i) => (
                        <option value={el.CURRENCY_CODE}>
                          {el.NAME_OF_CURRENCY}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Monthly Fee</Form.Label>
                  <Form.Control
                    size="lg"
                    type="Number"
                    onChange={(e) => setMonthlyFee(e.target.value)}
                    value={monthlyFee}
                    placeholder="Monthly Fee"
                  />
                  {errors.monthlyFee && (
                    <p className="error">{errors.monthlyFee}</p>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Commission</Form.Label>
                  <Form.Control
                    size="lg"
                    type="Number"
                    onChange={(e) => setCommission(e.target.value)}
                    value={commission}
                    placeholder="Commission"
                  />
                  {errors.commission && (
                    <p className="error">{errors.commission}</p>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Fixed Charge</Form.Label>
                  <Form.Control
                    size="lg"
                    type="Number"
                    onChange={(e) => setFixedCharge(e.target.value)}
                    value={fixedCharge}
                    placeholder="Fixed Charge"
                  />
                  {errors.fixedCharge && (
                    <p className="error">{errors.fixedCharge}</p>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label style={{ display: "block" }}>
                    {" "}
                    Monthly Fee Reqiured (Minimum monthly fee is part of
                    commission or not){" "}
                  </Form.Label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={include}
                      onChange={(e) => setInclude(e.target.checked)}
                    />
                    <span className="slider round" />
                  </label>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={show2} onHide={handleClose2} className="discount-12">
            <Modal.Header closeButton>
              <div />
              <Modal.Title id="example-custom-modal-styling-title ">
                Edit Pricing
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <EditBusiness
                validateBusiness={validateBusiness}
                setShow2={setshow2}
                bussId={bussId}
                country={country}
                currency={currency}
                monthlyFee={monthlyFee}
                commission={commission}
                fixedCharge={fixedCharge}
                include={include}
                getAllBussiness={getAllBussiness}
                setCountry={setCountry}
                setCurrency={setCurrency}
                setMonthlyFee={setMonthlyFee}
                setCommission={setCommission}
                setFixedCharge={setFixedCharge}
                setInclude={setInclude}
                handleCountrySelect={handleCountrySelect}
              />
            </Modal.Body>
            <Modal.Footer />
          </Modal>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminPricing;

function EditBusiness({
  validateBusiness,
  setShow2,
  bussId,
  country,
  currency,
  monthlyFee,
  fixedCharge,
  setCurrency,
  commission,
  getAllBussiness,
  setCountry,
  setMonthlyFee,
  setCommission,
  setFixedCharge,
  handleCountrySelect,
  include,
  setInclude,
}) {
  const [errors, setErrors] = useState({});

  const updateBusiness = async (e) => {
    e.preventDefault();
    const payloadd = {
      monthlyFee,
      commission,
      fixedCharge,
      country,
      currency,
      bussId,
      include,
    };
    const validation = validateBusiness({
      monthlyFee,
      commission,
      fixedCharge,
    });
    setErrors(validation.errorss);
    if (!validation.isValid) {
      return;
    }
    const saveCategory = await adminInstance().post("/editPricing", payloadd);
    const { code, msg } = saveCategory.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      getAllBussiness();
      toast.success(msg, { containerId: "B" });
      // clearValues();
      setShow2(false);
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  // const clearValues = () => {
  //   setCategoryname('');
  //   setCategorydescription('');
  // };
  return (
    <Form onSubmit={updateBusiness}>
      <Form.Group>
        <Form.Label>Countries</Form.Label>
        <Form.Control
          as="select"
          defaultValue={country}
          placeholder="Select Country"
          selected={country}
          value={country}
          onChange={(e) => handleCountrySelect(e)}
        >
          {countries &&
            countries.map((el, i) => (
              <option value={el.COUNTRY}>{el.COUNTRY}</option>
            ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Currencies</Form.Label>
        <Form.Control
          as="select"
          defaultValue={currency}
          placeholder="Select Country"
          selected={currency}
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
        >
          {countries &&
            countries.map((el, i) => (
              <option value={el.CURRENCY_CODE}>{el.NAME_OF_CURRENCY}</option>
            ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Monthly Fee</Form.Label>
        <Form.Control
          size="lg"
          type="Number"
          onChange={(e) => setMonthlyFee(e.target.value)}
          value={monthlyFee}
          placeholder="Monthly Fee"
        />
        {errors.monthlyFee && <p className="error">{errors.monthlyFee}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label>Commission</Form.Label>
        <Form.Control
          size="lg"
          type="Number"
          onChange={(e) => setCommission(e.target.value)}
          value={commission}
          placeholder="Commission"
        />
        {errors.commission && <p className="error">{errors.commission}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label>Fixed Charge</Form.Label>
        <Form.Control
          size="lg"
          type="Number"
          onChange={(e) => setFixedCharge(e.target.value)}
          value={fixedCharge}
          placeholder="Fixed Charge"
        />
        {errors.fixedCharge && <p className="error">{errors.fixedCharge}</p>}
      </Form.Group>

      <Form.Group>
        <Form.Label style={{ display: "block" }}>
          {" "}
          Monthly Fee Reqiured (Minimum monthly fee is part of commission or
          not){" "}
        </Form.Label>
        <label className="switch">
          <input
            type="checkbox"
            checked={include}
            onChange={(e) => setInclude(e.target.checked)}
          />
          <span className="slider round" />
        </label>
      </Form.Group>

      <Button variant="primary" type="submit">
        Update
      </Button>
    </Form>
  );
}
