import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import logo from '../../../assets/partner/logo.png';

const AdminMenu = ({ menutoggle1 }) => (
  <div className={`super-admin-menu ${menutoggle1 ? 'menu-hide' : ''}`}>
    <div className="admin-menu-logo">
      <h2>
        <img src={logo} alt="" />
      </h2>
    </div>

    <div className="super-admin-menu-box">
      <div className="admin-menu-list">
        <ul>
          <Nav>
            <NavLink to="/admin/dashboard">
              <i className="fa fa-home" />
              {' '}
              Dashboard
            </NavLink>

            <Link to="/admin/partner">
              <i className="fa fa-handshake-o" />
              {' '}
              Partners
            </Link>

            <Link to="/admin/customer">
              <i className="fa fa-users" />
              {' '}
              Customers
            </Link>

            <Link to="/admin/pricing">
              <i className="fa fa-user" />
              {' '}
              Pricing
            </Link>

            <Link to="/admin/bussinessTypes">
              <i className="fa fa-user" />
              {' '}
              Business Types
            </Link>

            <Link to="/admin/monthlytransaction">
              <i className="fa fa-user" />
              {' '}
              Monthly Transactions
            </Link>

            <NavLink to="/admin/settings">
              <i className="fa fa-calendar" />
              {' '}
              Settings
            </NavLink>
            <NavLink to="/admin/cron">
              <i className="fa fa-calendar" />
              {' '}
              Manually Cron
            </NavLink>
          </Nav>
        </ul>
      </div>
    </div>
  </div>
);

export default AdminMenu;
