import React, { useState } from "react";
import { Card, ProgressBar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import { server } from "../../config/keys";
import store2 from "../../assets/no-image.jpg";

const VoucherQuantity = () => {
  const { state } = useLocation();
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const [voucherValue] = useState(state.voucher.voucherValue);
  const [rt] = useState(state.rt);
  const [retailPrice] = useState(state.voucher.retailPrice);
  const [voucherId] = useState(state.voucher._id);
  const [locationName] = useState(state.locName);
  const [currency] = useState(state.currencyy);
  const [country] = useState(state.countryy);
  const [storeImage] = useState(state.storeImage);
  const [companyName] = useState(state.company);
  const [count, setCount] = useState(state.quantity ? state.quantity : 1);
  const [clicked, setClicked] = useState("");
  const [value, setValue] = useState(state.voucher.retailPrice);
  const [total, setTotal] = useState(state.voucher.retailPrice);
  const [servicessLength] = useState(state.partnerServices);

  const handleIncrement = (e) => {
    e.preventDefault();
    setClicked("plus");
    setCount((prevCount) => {
      const calculate = parseInt(rt, 10) * (prevCount + 1);
      setValue(calculate);
      setTotal(calculate);
      return prevCount + 1;
    });
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    setClicked("minus");
    if (count === 1) return;
    setCount((prevCount) => {
      const calculate = parseInt(rt, 10) * (prevCount - 1);
      setValue(calculate);
      setTotal(calculate);
      return prevCount - 1;
    });
  };
  return (
    <>
      <Helmet>
        <html lang="en" className="login-view-body " />
        <body className="remove-apps" />
      </Helmet>
      <div className="voucher-detail new-voucher-detail">
        <div className="service-bg new-select-service bg-color">
          <div className="container">
            <h2 className="page-title">
              <p>
                <span>Step 1 | </span> Additional info and quantity
              </p>
              <Link className="btn" to={`/storedetail/?partnerid=${id}`}>
                <i className="fa fa-angle-left" /> Back
              </Link>
            </h2>
            <div className="select-serve">
              <div className="store-services ">
                <div className="row">
                  <div className="col-md-4">
                    <div className="service-card">
                      <div className="box-details">
                        <Card>
                          <Card.Img
                            variant="top"
                            src={
                              storeImage ? `${server}/${storeImage}` : store2
                            }
                          />
                          <Card.Body>
                            <Card.Title className="heading-bold">
                              {companyName}
                            </Card.Title>
                            <Card.Text className="">
                              {locationName},{country}
                            </Card.Text>
                            <div className="border-des">
                              <div className="left-scrollbar">
                                <div className="content-detail">
                                  <div className="product">
                                    <h6>Referral Voucher</h6>
                                    <span>{servicessLength} services</span>
                                  </div>
                                  <div className="rate">
                                    <span>
                                      {currency} {value}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="total-rate">
                              <h6>Total</h6>
                              <h6>
                                {currency} {total}
                              </h6>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="service-card">
                      <div className="stepwizard">
                        <ProgressBar now={25} />
                        <span className="stepwizard-steps">Step 1</span>
                      </div>
                      {/* <div className="stepwizard">
                        <ol className="stepwizard-panel">
                          <li className="current stepwizard-step">Step 1</li>
                          <li className="stepwizard-step">Step 2</li>
                          <li className="stepwizard-step">Ready to go!</li>
                        </ol>
                      </div> */}
                      <div className="voucher-detail-quantity">
                        <h4 className="addional-info">
                          Additional info and quantity
                        </h4>
                        <div className="voucher-detail-section">
                          <div className="voucherlisting">
                            <div className="voucher-info">
                              <div className="my-voucher">
                                <div className="voucher-section">
                                  {/* <SignleVocuher /> */}

                                  <div className="signle-voucher">
                                    <div className="voucher-card">
                                      <div className="voucher-body">
                                        <div className="text-center voucher-name">
                                          <h4>Voucher Value </h4>
                                          <h2>
                                            {currency} {voucherValue}
                                          </h2>
                                        </div>
                                        <div className="text-center gift-voucher">
                                          <h4>
                                            Redeem on
                                            {servicessLength} services
                                          </h4>
                                          <h5>
                                            {currency} {rt}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="voucher-quantity">
                            <h6>Quantity</h6>
                            <div className="quantity">
                              <button
                                className={
                                  clicked === "minus"
                                    ? "increment-btn l-btn btn btn-primary"
                                    : ""
                                }
                                type="button"
                                onClick={(e) => handleDecrement(e)}
                              >
                                {" "}
                                -{" "}
                              </button>

                              <span>{count}</span>
                              <button
                                type="button"
                                className={
                                  clicked === "plus"
                                    ? "increment-btn l-btn btn btn-primary"
                                    : ""
                                }
                                onClick={(e) => handleIncrement(e)}
                              >
                                {" "}
                                +{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="continue-btn text-right">
                        <Link
                          to={{
                            pathname: `/giftVoucher/${id}`,
                            state: {
                              value,
                              total,
                              currency,
                              locationName,
                              country,
                              storeImage,
                              companyName,
                              voucherId,
                              retailPrice,
                              servicessLength,
                              quantity: count,
                              voucherValue,
                              rt,
                            },
                          }}
                          className="btn btn-primary l-btn"
                        >
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoucherQuantity;
