/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import AdminWrapper from '../adminwrapper/wrapper';
import { adminInstance } from '../../../config/axios';
//import Loader from '../../loader/loader';

const Settings = ({ name }) => {
  const [liveKey, setLiveKey] = useState();
  const [testKey, setTestKey] = useState(false);

  useEffect(() => {
    getAdminData();
  }, []);

  const getAdminData = async () => {
    const userdata = await adminInstance().get('/validateTokenAdmin');
    const { code, adminData } = userdata.data;
    if (code === 200) {
      setTestKey(adminData.testKey);
      setLiveKey(adminData.liveKey);
    }
  };

  const updateKey = async (e) => {
    e.preventDefault();
    const payloadd = {
      liveKey,
      testKey,
    };

    const taxCalcula = await adminInstance().post('/changeKey', payloadd);
    const { code, msg } = taxCalcula.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  const setKey = (e) => {
    if (e.target.name === 'test') {
      setLiveKey(false);
      setTestKey(true);
    } else {
      setLiveKey(true);
      setTestKey(false);
    }
  };

  // if (loading) {
  //   return (
  //     <AdminWrapper>
  //       <Loader />
  //     </AdminWrapper>
  //   );
  // }
  return (
    <AdminWrapper name={name}>
      <div className="admin-dashboard">
        <div className="container">
          <h2 className="super-admin-title">Dashboard</h2>
          <div className="row">

            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="admin-card">
                <div className="card-body">
                  <div className="row">
                    <div className="custom-switch-setting">
                      <h6 className="text-muted mb-2">Test Keys</h6>
                      <div className="tax-cal-box">
                        <div className="online-booking">
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="test"
                              onChange={(e) => setKey(e)}
                              value={testKey}
                              checked={testKey}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="admin-card">
                <div className="card-body">
                  <div className="row">
                    <div className="custom-switch-setting">
                      <h6 className="text-muted mb-2">Live Keys</h6>
                      <div className="tax-cal-box">
                        <div className="online-booking">
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="live"
                              onChange={(e) => setKey(e)}
                              value={liveKey}
                              checked={liveKey}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="setting-btn">
                <Button onClick={(e) => updateKey(e)}> Save Changes </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};
export default Settings;
