import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Button } from "react-bootstrap";
// import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import PartnerWrapper from "../../partnerwrapper/wrapper";
import { partnerInstance } from "../../../../config/axios";
import PartnerContext from "../../../../context/context";
// import history from "../../../../config/history";
import Loader from "../../../loader/loader";
import OnlineBooking from "./onlineBooking";

import BookingScreen from "../../../../assets/booking-new.png";
import cross from "../../../../assets/cross2.png";

import check from "../../../../assets/check-new.png";
// import PartnerWrapper from "../../partnerwrapper/wrapper";
import history from "../../../../config/history";
import "../../partnersignup/signupmain.css";

const PricingPackage = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [loading, setLoading] = useState(false);
  const [publish, setPublish] = useState(false);
  // const [pricingData, setPricingData] = useState(null);

  const AccountHolder = useCallback(async () => {
    setLoading(true);
    const resData = await partnerInstance().get("/getPartnerAccount");
    if (resData.data.code === 200) {
      setPublish(!resData.data.partnerData.stripeCardId);
      // setPricingData(resData.data.data);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Billing and card processing"
        );
      if (findBook > -1) {
        AccountHolder();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  const Step6 = () => {
    history.push("/partners/details");
  };
  return (
    <>
      {/* <Helmet>
        <html className="remove-sidebar" />
      </Helmet> */}
      <PartnerWrapper>
        {publish ? (
          <div className="partners-profile">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="signup-block-container online-booking-container">
                    <div className="cross-icon">
                      <Link to="/">
                        <img src={cross} alt="" />
                      </Link>
                    </div>
                    <div className="sb-left">
                      <h2>
                        You’re All Setup For More Benefits Proceed to Online
                        Booking
                      </h2>
                      <ul className="booking-list">
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>Online Booking</h3>
                            <p>
                              Appointlee mobile app plus website and social page
                              integration
                            </p>
                          </div>
                        </li>
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>No Show Protection</h3>
                            <p>
                              Eleminate no shows and late cancellations by
                              charging clients
                            </p>
                          </div>
                        </li>
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>Intelligent Marketing</h3>
                            <p>
                              Send campaigns to boots sales and fill your
                              calendar
                            </p>
                          </div>
                        </li>
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>Integrated Card Processing</h3>
                            <p>
                              Effortless and secure in-app payments with
                              Appointlee Pay
                            </p>
                          </div>
                        </li>
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>SMS text Messages</h3>
                            <p>
                              Automatically reminds clients about thier
                              appointments
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="sb-btn">
                        <Button onClick={() => Step6()}>Next</Button>
                      </div>
                    </div>
                    <div className="sb-right">
                      <div className="sb-right-img">
                        <img src={BookingScreen} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <OnlineBooking />
        )}
      </PartnerWrapper>
    </>
  );
};

export default PricingPackage;

// import React, {
//   useState,
//   useEffect,
//   useCallback,
//   useRef,
//   useContext,
// } from "react";
// import { Link } from "react-router-dom";
// import PartnerWrapper from "../../partnerwrapper/wrapper";
// import { partnerInstance } from "../../../../config/axios";
// import PartnerContext from "../../../../context/context";
// import history from "../../../../config/history";
// import Loader from "../../../loader/loader";
// import OnlineBooking from "./onlineBooking";

// const PricingPackage = () => {
// const { username } = useContext(PartnerContext);
// const { sections } = username;
// const titleRef = useRef(sections);
// const [loading, setLoading] = useState(false);
// const [publish, setPublish] = useState(false);
// const [pricingData, setPricingData] = useState(null);

// const AccountHolder = useCallback(async () => {
//   setLoading(true);
//   const resData = await partnerInstance().get("/getPartnerAccount");
//   if (resData.data.code === 200) {
//     setPublish(!resData.data.partnerData.stripeCardId);
//     setPricingData(resData.data.data);
//     setLoading(false);
//   }
// }, []);

// useEffect(() => {
//   setLoading(true);
//   if (titleRef.current) {
//     const findBook =
//       titleRef.current &&
//       titleRef.current.findIndex(
//         (boo) => boo.sectionname === "Billing and card processing"
//       );
//     if (findBook > -1) {
//       AccountHolder();
//     } else {
//       history.push("/auth");
//     }
//   }
//   // eslint-disable-next-line
// }, [titleRef.current]);

// if (loading) {
//   return (
//     <PartnerWrapper>
//       <Loader />
//     </PartnerWrapper>
//   );
// }

//   return (
//     <PartnerWrapper>
//       {publish ? (
//         <div className="payment-stripe-container">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="pricing-package">
//                   <div className="pricing-package-section">
//                     <h1>Step 1 of 3: Pricing Package</h1>
//                     <p>
//                       Review the pricing and list of features included with
//                       Appointlee
//                     </p>
//                     <div className="feature-listing">
//                       <div className="feature-list-one">
//                         <h6>Online Booking</h6>
//                         <p>
//                           Appointlee mobile app plus website and social page
//                           integration
//                         </p>
//                       </div>
//                       <div className="feature-list-one">
//                         <h6>No Show Protection</h6>
//                         <p>
//                           Eleminate no shows and late cancellations by charging
//                           clients
//                         </p>
//                       </div>
//                       <div className="feature-list-one">
//                         <h6>Intelligent Marketing</h6>
//                         <p>
//                           Send campaigns to boots sales and fill your calendar
//                         </p>
//                       </div>
//                       <div className="feature-list-one">
//                         <h6>Integrated Card Processing </h6>
//                         <p>
//                           Effortless and secure in-app payments with Appointlee
//                           Pay
//                         </p>
//                       </div>
//                       <div className="feature-list-one">
//                         <h6>SMS text Messages</h6>
//                         <p>
//                           Automatically reminds clients about thier appointments
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="pricing-section">
//                     <h1>Pricing</h1>
//                     <div className="pricing-row">
//                       <h6>
//                         {pricingData ? pricingData.commission : 5}% +{" "}
//                         {pricingData ? pricingData.currency : "INR"}{" "}
//                         {pricingData ? pricingData.fixedCharge : 0.99} per
//                         Booking
//                       </h6>
//                       <p>
//                         Fully Inclusive of all card processing and bank fees
//                       </p>
//                     </div>
//                     <div className="pricing-row">
//                       <h6>50% Per first-time appointlee client </h6>
//                       <p>
//                         Appliens only when gaining new client from appointlee
//                         marketplace
//                       </p>
//                     </div>
//                   </div>
//                   <div className="step-btn text-right">
//                     <Link to="/partners/details" className="btn-perple">
//                       Continue
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <OnlineBooking />
//       )}
//     </PartnerWrapper>
//   );
// };
// export default PricingPackage;
