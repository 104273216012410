import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import queryString from "query-string";
import moment from "moment";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { partnerInstance } from "../../../config/axios";
import { validateCustomer } from "../../../function/validate";
import history from "../../../config/history";
import "./customer.css";

const AddCustomer = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("Male");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [telephone, setTelephone] = useState("");
  const [notificationMethod, setNotificationMethod] = useState("Don't");
  const [language, setLanguage] = useState("English");
  const [referralSource, setReferralSource] = useState("");
  const [dob, setDob] = useState("");
  const [notes, setNotes] = useState("");
  const [address, setAddress] = useState("");
  const [subUrb, setSubUrb] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errors, setErrors] = useState({});
  const [referrals, setReferrals] = useState([]);
  //const [custId, setCustId] = useState('');

  const value = queryString.parse(window.location.search);
  const { starttime, startDate, staffId, voucherId, appointmentId } = value;

  useEffect(() => {
    const getReferral = async () => {
      const referralData = await partnerInstance().get("/getAllReferral");
      const { code, referral } = referralData.data;
      if (code === 200) {
        setReferrals(referral);
      }
    };
    getReferral();
  }, []);

  const addCustomer = async (e) => {
    e.preventDefault();
    const payload = {
      firstName,
      lastName,
      email,
      gender,
      phoneCode,
      phone,
      telephone,
      notificationMethod,
      language,
      referralSource,
      dob,
      notes,
      address,
      subUrb,
      city,
      state,
      postalCode,
    };
    const validation = validateCustomer({
      firstname: firstName,
      // email,
      // address,
      // city,
      // state,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    const saveData = await partnerInstance().post("/addCustomer", payload);
    const { code, msg, customerId } = saveData.data;
    //setCustId(customerId);
    if (code === 200) {
      if (startDate) {
        toast.success(msg, { containerId: "B" });
        history.push(
          `/partners/addappointment/?startDate=${startDate}&starttime=${starttime}&staffId=${staffId}&customerid=${customerId}`
        );
      } else if (voucherId) {
        history.push(
          `/partners/vouchercheckout/?voucherId=${voucherId}&customerid=${customerId}`
        );
      } else if (appointmentId) {
        history.push(
          `/partners/editappointment/?appointmentId=${appointmentId}&customerid=${customerId}`
        );
      } else {
        toast.success(msg, { containerId: "B" });
        history.push("/partners/customer");
      }
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const Back = (e) => {
    e.preventDefault();
    if (startDate) {
      history.push(
        `/partners/addappointment/?startDate=${startDate}&starttime=${starttime}&staffId=${staffId}`
      );
    } else if (appointmentId) {
      history.push(`/partners/editappointment/?appointmentId=${appointmentId}`);
    } else {
      history.push(`/partners/vouchercheckout/?voucherId=${voucherId}`);
    }
  };

  return (
    <PartnerWrapper heading="Add Customer">
      <div className="setup add-voucher add-customer-page">
        <div className="container">
          <Form onSubmit={addCustomer}>
            <div className="row">
              <div className="col-md-12">
                <div className="title-right">
                <h2 className="admin-title">
                  {/* Add Customer */}
                  {startDate || voucherId || appointmentId ? (
                    <Link role="button" onClick={Back}>
                      <i className="fa fa-angle-left" /> Back
                    </Link>
                  ) : (
                    <Link to="/partners/customer">
                      <i className="fa fa-angle-left" /> Back
                    </Link>
                  )}
                </h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-card">
                  <div className="service-body">
                    <h4>Customer Info </h4>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>
                        First Name
                        <span className="red-color">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                      {errors.firstname && (
                        <p className="error">{errors.firstname}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Mobile number</Form.Label>
                      <div className="number-fields">
                        <PhoneInput
                          country="us"
                          value={phoneCode}
                          onChange={(e) => setPhoneCode(`+${e}`)}
                          readonly
                        />

                        <Form.Control
                          type="number"
                          placeholder="Your mobile number"
                          name="email"
                          autocomplete="off"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                        {errors.phone && (
                          <p className="error">{errors.phone}</p>
                        )}
                      </div>
                    </Form.Group>

                    {/* <Form.Group controlId="formBasicloginone">
                      <Form.Label>Mobile No.*</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>+91</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control placeholder="Mobile No." type="number" />
                      </InputGroup>
                    </Form.Group> */}
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                      >
                        <option value="Male" selected>
                          Male
                        </option>
                        <option value="Female">Female</option>
                        <option value="Unknown">Unknown</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Telephone</Form.Label>
                      <Form.Control
                        placeholder="Telephone"
                        type="number"
                        onChange={(e) => setTelephone(e.target.value)}
                        value={telephone}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Send Notification by</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setNotificationMethod(e.target.value)}
                        value={notificationMethod}
                      >
                        <option value="Don't">Don't send Notification</option>
                        <option value="Email">Email</option>
                        <option value="SMS">SMS</option>
                        <option value="Email & SMS">Email & SMS</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Preffered Language</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setLanguage(e.target.value)}
                        value={language}
                      >
                        <option value="English">English</option>
                        <option value="Polish">Polish</option>
                        <option value="Italian">Italian</option>
                        <option value="French">French</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-card">
                  <div className="service-body">
                    <h4>Personal Info </h4>

                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Refferal Source</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setReferralSource(e.target.value)}
                        value={referralSource}
                      >
                        <option value="">Select Referral Source</option>
                        {referrals &&
                          referrals.map((el, i) => (
                            <option key={i} value={el.name}>
                              {el.name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label> Birthdate</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="DD/MM/YYYY"
                        name="name"
                        autoComplete="off"
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Client Notes </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Notes"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setNotes(e.target.value)}
                        value={notes}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address "
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Suburb </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" Suburb"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setSubUrb(e.target.value)}
                        value={subUrb}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>State </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" State"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Zip/ Post Code</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Post Code"
                        name="name"
                        autoComplete="off"
                        onChange={(e) => setPostalCode(e.target.value)}
                        value={postalCode}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-right">
                <Button type="submit" className=" btn-perple">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </PartnerWrapper>
  );
};
export default AddCustomer;
