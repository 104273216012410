import React, { useState, useEffect } from "react";
import { Form, Button, Dropdown } from "react-bootstrap";
//import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
//import { Helmet } from "react-helmet";
import MyProfile from "./myprofile";
import { validateUserProfile } from "../../function/validate";
import { userInstance } from "../../config/axios";
//import user from "../../assets/no-image.jpg";
import { server } from "../../config/keys";
import { countrycode1 } from "../../config/phonePrefix";
import blueImage from "../../assets/profile-blue.png";

const EditProfile = ({ getData, username, userImage }) => {
  const [email, setEmail] = useState("");
  const [userImagee, setUserImage] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [phoneCode, setPhoneCode] = useState("+91");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line
  const [loginerrors, setLoginErrors] = useState({});
  const [passType, setPassType] = useState("password");

  const updateProfile = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      firstname,
      lastname,
      phone,
      phoneCode,
      password,
    };

    const validation = validateUserProfile({
      firstname,
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const updateUser = await userInstance().post("/userUpdateProfile", payload);
    const { code, msg } = updateUser.data;

    if (code === 200) {
      getData();
      toast.success(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const getUserProfile = async () => {
    const Data = await userInstance().get("/validateToken");
    const { code, userData } = Data.data;
    if (code === 200) {
      setEmail(userData.email);
      setFirstname(userData.firstname);
      setLastname(userData.lastname);
      setPhone(userData.phone);
      setPhoneCode(userData.phoneCode ? userData.phoneCode : "91");
      setUserImage(userData.image);
    }
  };
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };
  useEffect(() => {
    getUserProfile();
  }, []);

  const uploadImage = async (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const ImageSaved = await userInstance().post(
      "/uploadUserPhoto",
      data,
      config
    );
    if (ImageSaved.data.code === 200) {
      toast.success(ImageSaved.data.msg, { containerId: "B" });
      getData();
    }
  };

  const removeImage = async () => {
    const ImageRemoved = await userInstance().post("/removeUserPhoto");
    if (ImageRemoved.data.code === 200) {
      toast.success(ImageRemoved.data.msg, { containerId: "B" });
      getData();
    }
  };

  const Cancel = () => {
    getUserProfile();
  };

  return (
    <MyProfile username={username} userImage={userImage}>
      <div className="edit-profile set-height">
        <div className="profile-section mt-0 set-height">
          <div className="row set-height">
            <div className="col-md-12 col-sm-12 set-height">
              <div className="profile-card new-profile-card set-height">
                <h3 className="pt-0">Edit Profile</h3>
                <div className="profile-img">
                  <span className="wallpaperimg">
                    <label htmlFor="upload-photo1" className="cii">
                      <div className="user-pics">
                        <img
                          src={
                            userImagee ? `${server}/${userImagee}` : blueImage
                          }
                          alt="User"
                        />
                      </div>
                      <i className="fa fa-camera" aria-hidden="true" />
                    </label>

                    <input
                      type="file"
                      name="photo"
                      id="upload-photo1"
                      onChange={(e) => uploadImage(e)}
                    />
                    {userImagee && (
                      <div className="delete-icon">
                        <i
                          role="presentation"
                          className="fa fa-trash"
                          onClick={() => removeImage()}
                        />
                      </div>
                    )}
                  </span>
                </div>

                <div className="profile-form">
                  <Form onSubmit={updateProfile}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 pr-4">
                        <Form.Group controlId="formBasicloginone">
                          <Form.Label>First Name</Form.Label>
                          <div className="password-show">
                            <Form.Control
                              type={firstname}
                              placeholder="Your FirstName"
                              name="firstname"
                              autoComplete="off"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                            />
                          </div>
                          {errors.firstname && (
                            <p className="error">{errors.firstname}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6 col-sm-12 pl-4">
                        <Form.Group controlId="formBasicloginone">
                          <Form.Label>Last Name</Form.Label>
                          <div className="password-show">
                            <Form.Control
                              type={lastname}
                              placeholder="Your LastName"
                              name="lastname"
                              autoComplete="off"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6 col-sm-12 pr-4">
                        <Form.Group
                          controlId="formBasicPassword"
                          className="new-form-group"
                        >
                          <Form.Label>Mobile Number</Form.Label>
                          <div className="number-fields new-number-fields">
                            {/* <PhoneInput
                                  country="us"
                                  value={phoneCode}
                                  onChange={(e) => selectPhoneCode(`+${ e }`)}
                                  readonly
                                /> */}
                            <Dropdown className="select-dropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                {phoneCode}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {Object.values(countrycode1).map((el) => (
                                  <Dropdown.Item
                                    key={el.prefix}
                                    onClick={() => {
                                      selectPhoneCode(el.prefix);
                                    }}
                                  >
                                    {" "}
                                    {el.prefix}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                              type="number"
                              placeholder="Your Mobile Number"
                              name="phone"
                              autocomplete="off"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </div>
                          {errors.phone && (
                            <p className="error">{errors.phone}</p>
                          )}
                        </Form.Group>
                        {/* <Form.Group controlId="formBasicPassword">
                          <Form.Label>Mobile number</Form.Label>
                       
                          <div className="number-fields">
                            <PhoneInput
                              country="us"
                              value={phoneCode}
                              onChange={(e) => setPhoneCode(`+${e}`)}
                            />
                            <Form.Control
                              type="number"
                              placeholder="Your mobile number"
                              name="phone"
                              autocomplete="off"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </div>
                        </Form.Group> */}
                      </div>
                      <div className="col-md-6 col-sm-12 pl-4">
                        <Form.Group controlId="formBasicloginone">
                          <Form.Label>Email Address </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Your Email Address"
                            name="email"
                            // disabled
                            autoComplete="off"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && (
                            <p className="error">{errors.email}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6 col-sm-6 pr-4">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <div className="password-show">
                            <Form.Control
                              type={passType}
                              placeholder="Create a Password"
                              name="password"
                              autoComplete="off"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                              role="presentation"
                              className={
                                passType === "password"
                                  ? "fa fa-eye-slash"
                                  : "fa fa-eye"
                              }
                              onClick={() =>
                                setPassType(
                                  passType === "password" ? "text" : "password"
                                )
                              }
                            />
                          </div>
                          {loginerrors.password && (
                            <p className="error">{loginerrors.password}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-12 text-center space-x-2 book-now-btn btn-width ">
                        <Button onClick={() => Cancel()}>Back</Button>
                        <Button type="submit">Save</Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyProfile>
  );
};

export default EditProfile;
