import React, { useState, useContext } from "react";
import { ProgressBar } from "react-bootstrap";
import PartnerWrapper from "../partnerwrapper/wrapper";
import cross from "../../../assets/cross2.png";
import history from "../../../config/history";
import PricingDetail from "./pricingdetail";
import PricingPayment from "./pricingpayment";
import Helmet from "react-helmet";
import { StepCompletePrevent } from "./StepCompletePrevent";
import PartnerContext from "../../../context/context";

const SignupStep7 = () => {
  const { isStepComplete } = useContext(PartnerContext);
  StepCompletePrevent(isStepComplete);
  const [stepform, setStepForm] = useState(1);
  const [formData, setFormData] = useState({});
  const[accountData, setAccountData]=useState({})

  const updateFormData = (newData) => {
    setFormData({ ...formData, newData });
  };

  // const Step6 = () => {
  //   history.push("/partners/discount");
  // };
  // const Step8 = () => {
  //   history.push("/partners/online-payment");
  // };
  const Cross = () => {
    history.push("/partners/discount");
  };

  const handleStep = (step) => {
    setStepForm(step);
  };
  console.log('account data',accountData)
  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-step-form taxes btn-align">
                <div className="cross-icon" onClick={Cross} role="presentation">
                  <img src={cross} alt="" />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h3> Online Pricing Package</h3>
                    <div className="signup-progress">
                      <ProgressBar now={84} />
                    </div>
                  </div>

                  <div className="signup-body signup-online-pricing">
                    {stepform === 1 ? (
                      <PricingDetail
                        handleStep={handleStep}
                        step={stepform}
                        updateFormData={updateFormData}
                        accountData={accountData}
                      />
                    ) : stepform === 2 ? (
                      <PricingPayment
                        handleStep={handleStep}
                        step={stepform}
                        data={formData}
                        setAccountData={setAccountData}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="signup-body signup-online-pricing">
                    {stepform === 1 ? (
                      <SignupPricing handleStep={handleStep} />
                    ) : stepform === 2 ? (
                      <PricingDetail
                        handleStep={handleStep}
                        step={stepform}
                        updateFormData={updateFormData}
                      />
                    ) : stepform === 3 ? (
                      <PricingPayment handleStep={handleStep} step={stepform} />
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>
                {/* <div className="signup-right">
                 
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default SignupStep7;
