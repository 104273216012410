/* eslint-disable radix */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, Form, Modal, InputGroup, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Accordion from 'react-bootstrap/Accordion';
import { toast } from "react-toastify";
import queryString from "query-string";
import { partnerInstance } from "../../../config/axios";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { validatePackage } from "../../../function/validate";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";

// const j = 1;

const PackageServices = () => {
  const [view, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceid, setServiceId] = useState([]);
  const [packageCategoryId, setPackageCategoryId] = useState("");
  const [packagedescription, setPackagedescription] = useState("");
  const [errors, setErrors] = useState({});
  const [scheduleType, setScheduleType] = useState("sequence");
  const [retailPrice, setRetailPrice] = useState("");
  const [priceType, setPriceType] = useState("Service pricing");
  const [packagename, setPackagename] = useState("");
  const [servicePanel, setServicePanel] = useState([]);
  //const [serviceCopyPanel, setServiceCopyPanel] = useState([]);
  const [valid, setValid] = useState(true);
  const [discountedValue, setDiscountedValue] = useState(0);
  //const [discountInPercent, setDiscountInPercent] = useState(0);
  const [servicePriceTotal, setServicePriceTotal] = useState(0);
  const [servicesCopy, setServicesCopy] = useState([]);
  const { username } = useContext(PartnerContext);
  const { currency } = username;
  const value = queryString.parse(window.location.search);
  const { catid, catname } = value;
  let pricetotal = 0;
  let dur = 0;
  const getServices = useCallback(async () => {
    const payloadd = {
      catId: packageCategoryId,
    };
    const servicesData = await partnerInstance().post(
      "/getServicesByCategory",
      payloadd
    );
    const { code, services: Services } = servicesData.data;
    if (code === 200) {
      const dataa = [Services];
      setServices(dataa[0].serviceid);
      setServicesCopy(dataa[0].serviceid);
    }
  }, [packageCategoryId]);
  const getCategories = useCallback(async () => {
    const categoriesData = await partnerInstance().get("/getCategories");
    const { code, categories: Categories } = categoriesData.data;
    if (code === 200) {
      setCategories(Categories);
      // setCategoriesCopy(Categories);
    }
  }, []);

  useEffect(() => {
    if (catid) {
      // setCategory(catname);
      setPackageCategoryId(catid);
    }
    getCategories();
    getServices();
    // eslint-disable-next-line
  }, [getServices, getCategories]);

  function useUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);
    return update;
  }

  const update = useUpdate();

  const handleCustomPrice = (e) => {
    const state = [...servicePanel];
    if (e) {
      if (+e > servicePriceTotal) {
        setValid(false);
        setDiscountedValue(0);
        state.forEach((cus) => {
          cus.servicePrice = parseInt(cus.priceFrom);
        });
        setServicePanel(state);
        //setDiscountInPercent(0);
        setRetailPrice(servicePriceTotal);
        update();
      } else {
        setValid(true);
        const discount = servicePriceTotal - +e;
        setDiscountedValue(discount);
        const disPer = (parseInt(e) / servicePriceTotal) * 100;
        state.forEach((cus) => {
          cus.servicePrice =
            parseInt(cus.priceFrom) * (disPer.toFixed(2) / 100);
        });
        //setDiscountInPercent(disPer.toFixed(2));
        setRetailPrice(+e);
        setServicePanel(state);
        update();
      }
    } else {
      setDiscountedValue(servicePriceTotal);
      state.forEach((cus) => {
        cus.servicePrice *= 0;
      });
      setServicePanel(state);
      //setDiscountInPercent(0);
      setRetailPrice(0);
      update();
    }
  };

  const handlePercentDiscount = (e) => {
    const state = [...servicePanel];
    if (e) {
      if (e > 100) {
        setValid(false);
        setDiscountedValue(0);
        state.forEach((cus) => {
          cus.servicePrice = parseInt(cus.priceFrom);
        });
        setServicePanel(state);
        setRetailPrice(e);
        update();
      } else {
        setValid(true);
        const discount = servicePriceTotal * (parseInt(e) / 100);
        setDiscountedValue(discount);
        setRetailPrice(e);
        state.forEach((cus) => {
          const disper = parseInt(cus.priceFrom) * (parseInt(e) / 100);
          cus.servicePrice = parseInt(cus.priceFrom) - disper;
        });
        setServicePanel(state);
      }
    } else {
      setDiscountedValue(servicePriceTotal);
      state.forEach((cus) => {
        cus.servicePrice *= 0;
      });
      setServicePanel(state);
      //setDiscountInPercent(0);
      setRetailPrice(0);
      update();
    }
  };

  const handleFreePrice = (e) => {
    const state = [...servicePanel];
    setDiscountedValue(servicePriceTotal);
    setRetailPrice(e);
    state.forEach((cus) => {
      const disper = parseInt(cus.priceFrom) * (parseInt(100) / 100);
      cus.servicePrice = parseInt(cus.priceFrom) - disper;
    });
    setServicePanel(state);
    update();
  };

  const handleServicePricing = (e) => {
    const state = [...servicePanel];
    setDiscountedValue(servicePriceTotal);
    setRetailPrice(e);
    state.forEach((cus) => {
      cus.servicePrice = parseInt(cus.priceFrom);
    });
    setServicePanel(state);
    update();
  };

  const addMoreService = async (ee) => {
    const oldStatee = [...serviceid];
    oldStatee.push([ee._id]);
    setServiceId(oldStatee);
    const oldState = [...servicePanel];
    oldState.push({
      serviceDuration: ee.duration,
      servicename: ee.serviceName,
      servicePrice: ee.specialPrice ? ee.specialPrice : ee.priceFrom,
      priceFrom: ee.specialPrice ? ee.specialPrice : ee.priceFrom,
      serviceDurationBefore: ee.extraDurationBefore,
      serviceDurationAfter: ee.extraDurationAfter,
    });
    setServicePanel(oldState);
    setView(false);
    setServicePriceTotal(
      servicePriceTotal +
        parseInt(ee.specialPrice ? ee.specialPrice : ee.priceFrom)
    );
  };

  const deleteService = (id, price) => {
    const oldStatee = [...serviceid];
    oldStatee.splice(id, 1);
    setServiceId([...oldStatee]);
    const oldState = [...servicePanel];
    oldState.splice(id, 1);
    setServicePanel([...oldState]);
    setServicePriceTotal(servicePriceTotal - parseInt(price));
  };

  const addPackage = async (e) => {
    e.preventDefault();
    const packagePrice = servicePriceTotal;
    let packageDuration = 0;
    let packageSpecialPrice = 0;
    let packageDurationAfter = 0;
    let packageDurationBefore = 0;
    let retailprice = 0;

    servicePanel.forEach((elem, i) => {
      packageDuration += parseInt(elem.serviceDuration);
      packageSpecialPrice += parseInt(elem.servicePrice);
      packageDurationBefore += parseInt(elem.serviceDurationBefore);
      packageDurationAfter += parseInt(elem.serviceDurationAfter);
    });

    if (retailPrice === "") {
      retailprice = packagePrice;
    } else {
      retailprice = retailPrice;
    }
    const payload = {
      packagename,
      packagedescription,
      packageCategoryId,
      scheduleType,
      priceType,
      retailPrice: retailprice,
      serviceid,
      packagePrice,
      packageDuration,
      packageSpecialPrice,
      packageDurationBefore,
      packageDurationAfter,
    };

    const validation = validatePackage({
      packagename,
      category: packageCategoryId,
      servicePanel,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (!valid) {
      return;
    }

    const saveData = await partnerInstance().post("/addPackage", payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      history.push("/partners/services");
      toast.success(msg, { containerId: "B" });
      // clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  const handlePriceType = (data) => {
    setPriceType(data);
    if (data === "Service pricing" || data === "Percentage discount") {
      handleServicePricing(servicePriceTotal);
    } else if (data === "Free") {
      handleFreePrice(0);
    } else if (data === "Custom pricing") {
      handleServicePricing();
    }
  };

  return (
    <PartnerWrapper heading="Bundle Definition">
      <div className="package-services">
        <div className="container">
          <h2 className="admin-title">
            {/* Bundle Definition{" "} */}
            {catid ? (
              <Link
                to={`/partners/addservices?catid=${catid}&catname=${catname}`}
              >
                <i className="fa fa-angle-left" /> Back
              </Link>
            ) : (
              <Link to="/partners/addservices">
                <i className="fa fa-angle-left" /> Back
              </Link>
            )}
          </h2>

          <div className="package-services-content">
            <Form onSubmit={addPackage}>
              <div className="row">
                <div className="col-md-6">
                  <div className="h-100">
                    <div className="service-card">
                      <div className="service-body">
                        <Form.Group controlId="formBasicloginone">
                          <Form.Label>
                            Bundle Name
                            <span className="red-color">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Bundle Name"
                            name="name"
                            autoComplete="off"
                            onChange={(e) => setPackagename(e.target.value)}
                            value={packagename}
                          />
                          {errors.packagename && (
                            <p className="error">{errors.packagename}</p>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formBasicloginone">
                          <Form.Label>
                            Bundle Category
                            <span className="red-color">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            disabled={!!catid}
                            onChange={(e) =>
                              setPackageCategoryId(e.target.value)
                            }
                            value={packageCategoryId}
                          >
                            <option value="">Select Category</option>
                            {categories.map((el, i) => (
                              <>
                                <option key={i} value={el._id}>
                                  {el.categoryname}
                                </option>
                              </>
                            ))}
                          </Form.Control>
                          {errors.category && (
                            <p className="error">{errors.category}</p>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formBasicloginone">
                          <Form.Label>Bundle Description</Form.Label>
                          <Form.Control
                            placeholder="Add a short description"
                            as="textarea"
                            rows="3"
                            onChange={(e) =>
                              setPackagedescription(e.target.value)
                            }
                            value={packagedescription}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="h-100">
                    <div className="service-card">
                      <div className="service-header">
                        <h2 className="admin-inner-title">
                          Services
                          <span className="red-color">*</span>
                        </h2>
                        <p>Add services to your bundle</p>
                      </div>

                      <div className="service-body">
                        <div className="select-services">
                          {/* After Select the service this div will sjow selected services */}

                          <div className="select-categories">
                            {servicePanel.map((el, i) => {
                              pricetotal += +el.servicePrice;
                              dur += parseInt(el.serviceDuration);
                              // setTotalPrice(pricetotal);
                              return (
                                <>
                                  <div className="select-list">
                                    <div>
                                      <h5>{el.servicename}</h5>

                                      <>
                                        <p>
                                          {/* {hours}h {minutes}min,{' '}
                                          {ele.pricingName} */}
                                          {Math.floor(
                                            parseInt(el.serviceDuration) / 60
                                          )}
                                          h {parseInt(el.serviceDuration) % 60}
                                          min
                                        </p>
                                      </>

                                      <div className="add-service-price">
                                        <span>
                                          {currency} {el.servicePrice}
                                        </span>
                                        <i
                                          role="presentation"
                                          onClick={() =>
                                            deleteService(i, el.servicePrice)
                                          }
                                          className="fa fa-times"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                            {errors.servicePanel && (
                              <p className="error">{errors.servicePanel}</p>
                            )}
                          </div>

                          <div className="add-pricing-option">
                            <span onClick={handleViewShow}>
                              <i className="fa fa-plus" /> Select service
                            </span>
                            <div>
                              {/* {setRetailPrice(pricetotal)} */}
                              Total: {currency} {pricetotal.toFixed(2)} (
                              {Math.floor(dur / 60)}
                              h, {dur % 60}
                              min)
                            </div>
                          </div>
                        </div>

                        <div className="extra-option">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Appointment Definition</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) => setScheduleType(e.target.value)}
                              value={scheduleType}
                            >
                              <option value="sequence">
                                Services performed at the same time
                              </option>
                              <option value="parallel">
                                Services performed in parallel
                              </option>
                            </Form.Control>
                            <p className="treatment-info">
                              Services can be booked by sequential order, or in
                              parallel with the same start time. Learn more
                              about packages.
                            </p>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="h-100">
                    <div className="service-card">
                      <div className="service-header">
                        <h2 className="admin-inner-title">Price</h2>
                      </div>

                      <div className="service-body">
                        <div className="service-pricing">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Choose a Pricing Method</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) => handlePriceType(e.target.value)}
                              value={priceType}
                            >
                              <option value="Service pricing">
                                Same as Service Price
                              </option>
                              <option value="Custom pricing">
                                Custom Pricing
                              </option>
                              <option value="Percentage discount">
                                Percentage Discount
                              </option>
                              <option value="Free">Free</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Bundle Price</Form.Label>
                            <InputGroup>
                              {priceType === "Free" ? (
                                <>
                                  {" "}
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>₹</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    placeholder="0.00"
                                    type="number"
                                    value={0}
                                    disabled
                                  />{" "}
                                  <p>Discounted By {pricetotal}</p>
                                </>
                              ) : priceType === "Custom pricing" ? (
                                <>
                                  {" "}
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>₹</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    placeholder="0.00"
                                    type="number"
                                    onChange={(e) =>
                                      handleCustomPrice(e.target.value)
                                    }
                                  />
                                  {/* {`discounted by ${discountedValue}`} */}
                                  {discountedValue < servicePriceTotal &&
                                  discountedValue > 0
                                    ? `discounted by ${discountedValue}`
                                    : ""}
                                  {!valid ? (
                                    <p className="error">
                                      Enter a Price less than{" "}
                                      {servicePriceTotal}
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : priceType === "Percentage discount" ? (
                                <>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>%</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    placeholder="0.00"
                                    onChange={(e) =>
                                      handlePercentDiscount(e.target.value)
                                    }
                                  />
                                  {discountedValue > 0
                                    ? `discounted by ${discountedValue}`
                                    : ""}
                                  {/* {`discounted by ${discountedValue}`} */}
                                  {!valid ? (
                                    <p className="error">
                                      Please Enter value 100 or less
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>₹</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    placeholder="0.00"
                                    value={servicePriceTotal}
                                    disabled
                                  />
                                </>
                              )}
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="login-button service-save">
                    <Button type="submit" className=" btn-perple">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Modal
        className="category-popup select-service-popup"
        show={view}
        onHide={handleViewClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectServices
            services={services}
            setView={setView}
            setServiceId={setServiceId}
            // setServicename={setServicename}
            // setServiceDuration={setServiceDuration}
            // setServicePrice={setServicePrice}
            setServicePanel={setServicePanel}
            servicePanel={servicePanel}
            addMoreService={addMoreService}
            servicesCopy={servicesCopy}
            setServices={setServices}
            currency={currency}
            // setServiceDurationAfter={setServiceDurationAfter}
            // setServiceDurationBefore={setServiceDurationBefore}
          />
        </Modal.Body>
      </Modal>
    </PartnerWrapper>
  );
};

export default PackageServices;

const SelectServices = ({
  currency,
  setServices,
  servicesCopy,
  services,
  setView,
  addMoreService,
  // setServicename,
  setServiceId,
  // setServiceDuration,
  // setServicePrice,
  // setServiceDurationBefore,
  // setServiceDurationAfter,
  servicePanel,
  setServicePanel,
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  // const selectService = (ee, item) => {
  //   setServiceId(item._id);
  //   // setServicename(item.servicename);
  //   setServiceDuration(ee.duration);
  //   setServicePrice(ee.priceFrom);
  //   setServiceDurationAfter(ee.extraDurationAfter);
  //   setServiceDurationBefore(ee.extraDurationBefore);
  //   setView(false);
  // };
  // const filteredCategories = categoriesCopy.filter(
  //   (category) =>
  //     category.categoryname.toLowerCase().indexOf(e.toLowerCase()) !== -1
  // );
  // setCategories(filteredCategories);
  const serviceSearch = (e) => {
    setSearchKeyword(e);
    if (parseInt(e.length) > 0) {
      const filteredServices = services.filter(
        (service) =>
          service.serviceName.toLowerCase().indexOf(e.toLowerCase()) !== -1
      );

      setServices(filteredServices);
    } else {
      setServices(servicesCopy);
    }
  };

  return (
    <div className="add-category">
      <Form.Group controlId="formBasicloginone">
        <Form.Control
          type="text"
          placeholder="Search Service"
          name="category"
          autoComplete="off"
          value={searchKeyword}
          onChange={(e) => serviceSearch(e.target.value)}
        />
      </Form.Group>
      {services && services.length > 0 ? (
        <Form>
          <div className="select-categories">
            {services.map((ee, i) => (
              <Card>
                <Card.Body>
                  <div className="select-list">
                    <div
                      role="presentation"
                      onClick={() => {
                        addMoreService(ee);
                      }}
                    >
                      <h5>{ee.serviceName}</h5>
                      <p>
                        {" "}
                        {Math.floor(parseInt(ee.duration) / 60)} h,{" "}
                        {parseInt(ee.duration) % 60}
                        min
                      </p>

                      <div className="add-service-price">
                        <span>
                          {currency}{" "}
                          {ee.specialPrice ? ee.specialPrice : ee.priceFrom}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>

          {/* <div className="login-button">
  <Button type="submit" className="l-btn">
    Save
  </Button>
</div> */}
        </Form>
      ) : (
        <>
          <p>No Service Found</p>
        </>
      )}
    </div>
  );
};
