import React, { useState, useEffect, useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import { validateCancelType } from "../../../function/validate";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import { partnerInstance } from "../../../config/axios";

const SignupCancelReasion = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [showEdit, setShowEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [Name, setName] = useState("");
  const [cancels, setCancels] = useState([]);
  const [paymentTypeId, setPaymentTypeId] = useState("");
  const [errors, setErrors] = useState({});
  //const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setShow(true);
    setName("");
    setErrors("");
  };

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
  };

  useEffect(() => {
    //setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getPaymentType();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const getPaymentType = async () => {
    //setLoading(true);
    const paymentData = await partnerInstance().get("/getCancellationReasons");
    const { code, cancellationReason } = paymentData.data;
    if (code === 200) {
      setCancels(cancellationReason);
      //setLoading(false);
    }
  };

  const addPaymenttype = async (e) => {
    e.preventDefault();
    const payload = {
      Name,
    };
    const validation = validateCancelType({
      Name,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const paymenttype = await partnerInstance().post(
      "/addCancellationReason",
      payload
    );
    const { code, msg } = paymenttype.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      setName("");
      setShow(false);
      getPaymentType();
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const handleShowEdit = (id, paymentname) => {
    setPaymentTypeId(id);
    setName(paymentname);
    setShowEdit(true);
    setErrors("");
  };
  return (
    <>
      <div className="payment-tax">
        {/* <div className="container">
          <div className="row">
            <div className="col-md-12"> */}
        <h2 className="admin-title">
          {/* Cancellation Reason */}
          <Button onClick={handleShow} className="btn-perple">
            Add Cancellation Reason{" "}
          </Button>
        </h2>
        <div className="partner-table">
          <Table responsive="lg">
            <thead>
              <tr>
                <th>Seriel No.</th>
                <th> Cancellation Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cancels.map((el, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{el.Name}</td>
                  <td>
                    <Button
                      onClick={() => handleShowEdit(el._id, el.Name)}
                      className="btn-perple"
                    >
                      Edit
                      {/* <i className="fa fa-pencil-square-o" /> */}
                    </Button>
                    {/* <Button>
                           <i className="fa fa-trash" />
                        </Button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* </div>
          </div>
        </div> */}
      </div>

      <Modal show={show} onHide={handleClose} className="pay-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Add Cancellation Reason
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addPaymenttype}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Cancellation Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Reason"
                onChange={(e) => setName(e.target.value)}
                value={Name}
              />
              {errors.Name && <p className="error">{errors.Name}</p>}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary" className="btn-perple">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEdit} onHide={handleClose} className="pay-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Edit Cancellation Reason
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditPaymentType
            paymentTypeId={paymentTypeId}
            Name={Name}
            setName={setName}
            setShowEdit={setShowEdit}
            getPaymentType={() => getPaymentType()}
            setErrors={setErrors}
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
};

export default SignupCancelReasion;

function EditPaymentType({
  paymentTypeId,
  Name,
  setShowEdit,
  setName,
  getPaymentType,
  setErrors,
  errors,
}) {
  const updatePaymentType = async (e) => {
    try {
      e.preventDefault();
      const payloadd = {
        Name,
      };
      const validation = validateCancelType({
        Name,
      });
      setErrors(validation.errors);
      if (!validation.isValid) {
        return;
      }

      const updatepaymenttype = await partnerInstance().put(
        `/editCancelType/${paymentTypeId}`,
        payloadd
      );
      const { code, msg } = updatepaymenttype.data;
      if (code === 200) {
        // setCategoryname(categoryAdded.categoryname);

        setShowEdit(false);
        getPaymentType();
        toast.success(msg, { containerId: "B" });
        clearValues();
      } else if (code === 409) {
        toast.error(msg, { containerId: "B" });
      } else {
        toast.error(msg, { containerId: "B" });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const clearValues = () => {
    setName("");
  };
  return (
    <Form onSubmit={updatePaymentType}>
      <Form.Group>
        <Form.Label>Cancellation name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Tax name"
          onChange={(e) => setName(e.target.value)}
          value={Name}
        />
        {errors.Name && <p className="error">{errors.Name}</p>}
      </Form.Group>
      <div className="text-right p-0">
        <Button variant="primary" type="submit" className="btn-perple">
          Update
        </Button>
      </div>
    </Form>
  );
}

EditPaymentType.propTypes = {
  paymentTypeId: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  setShowEdit: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  getPaymentType: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
};
