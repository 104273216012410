import React, { useState, useEffect } from "react";
import "../partners.css";
import Helmet from "react-helmet";
import PartnerHeader from "../partnerheader/header";
import PartnerMenu from "../partnermenu/menu";
// import { partnerInstance } from "../../../config/axios";

const PartnerWrapper = (props) => {
  const [menutoggle, setMenuToggle] = useState(false);

  useEffect(() => {
    // const getPartnerData = async () => {
    //   const partnerdata = await partnerInstance().get("/validateToken");

    //   const { code, partnerData } = partnerdata.data;
    //   if (code === 200) {

    //   }
    //   if (!partnerData.isStepComplete) {

    //     setMenu(partnerData.isStepComplete);
    //   }
    // };
    // getPartnerData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="admin-wrapper">
      <Helmet>
        <body className="admin-view" />
      </Helmet>

      <div className="admin-content-view">
        {/* {!menu && (

        )} */}
        <PartnerMenu menutoggle={menutoggle} setMenuToggle={setMenuToggle} />
        <div
          className={`admin-container ${menutoggle ? "wrapper-expand" : ""}`}
        >
          <div className="header-view">
            <PartnerHeader
              setMenuToggle={setMenuToggle}
              menutoggle={menutoggle}
              heading={props.heading}
            />
          </div>

          <div className="admin-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWrapper;
