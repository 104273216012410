import React, { useState, useEffect } from 'react';
import './register.css';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
//import PhoneInput from 'react-phone-input-2';
import publicIp from 'react-public-ip';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { userInstance } from '../../config/axios';
import { facebookId, googleId } from '../../config/keys';
//import google from '../../assets/google-icon-svg.png';
//import facebookIcon from '../../assets/facebook-icon.png';
import { validateRegister } from '../../function/validate';
import { countrycode1 } from '../../config/phonePrefix';
import 'react-phone-input-2/lib/material.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [country, setCountry] = useState('');
  const [errors, setErrors] = useState({});
  const [checked2, setChecked2] = useState(false);
  const [passType, setPassType] = useState('password');

  const signUp = async (e) => {
    e.preventDefault();
    const payload = {
      firstname,
      lastname,
      email,
      password,
      phoneCode,
      phone,
      country,
    };
    const validation = validateRegister({
      firstname,
      lastname,
      email,
      phone,
      password,
      checked2,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveData = await userInstance().post('/signup', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setEmail('');
    setPassword('');
    setFirstname('');
    setLastname('');
    setChecked2('');
    setPhone('');
  };
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };
  useEffect(() => {
    const getCountry = async () => {
      const ipv4 = await publicIp.v4();
      // const ipv4 = '5.8.159.255';
      fetch(`https://ipapi.co/${ipv4}/json`)
        .then((res) => res.json())
        .then((response) => {
          setPhoneCode(response.country_calling_code);
          setCountry(response.country_name);
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    };
    // getPhoneCode();

    getCountry();
  }, []);

  const responseFacebook = async (response) => {
    
    if (response.email) {
      const name = response.name.split(' ');
      const payload = {
        firstname: name[0],
        lastname: name[1],
        email: response.email,
        facebookId: response.userID,
        phoneCode,
        country,
      };
      const saveData = await userInstance().post('/facebookLogin', payload);
      const {
        code, msg, token, userid,
      } = saveData.data;
      if (code === 200) {
        toast.success(msg, { containerId: 'B' });
        if (token) {
          localStorage.setItem('usertoken', token);
          localStorage.setItem('userid', userid);
          window.location.href = '/';
        }
        clearValues();
      } else if (code === 409) {
        toast.error(msg, { containerId: 'B' });
      } else {
        toast.error(msg, { containerId: 'B' });
      }
    } else {
      toast.error('FaceBoook Signup Unsuccessfull', { containerId: 'B' });
    }
  };

  const responseGoogle = async (response) => {
    
    if (!response.error) {
      if (response.profileObj.email) {
        const name = response.profileObj.name.split(' ');
        const payload = {
          firstname: name[0],
          lastname: name[1],
          email: response.profileObj.email,
          googleId: response.googleId,
          phoneCode,
          country,
        };
        const saveData = await userInstance().post('/googleLogin', payload);
        const {
          code, msg, token, userid,
        } = saveData.data;
        if (code === 200) {
          toast.success(msg, { containerId: 'B' });
          if (token) {
            localStorage.setItem('usertoken', token);
            localStorage.setItem('userid', userid);
            window.location.href = '/';
          }
          clearValues();
        } else if (code === 409) {
          toast.error(msg, { containerId: 'B' });
        } else {
          toast.error(msg, { containerId: 'B' });
        }
      } else {
        toast.error('Google Signup Unsuccessfull', { containerId: 'B' });
      }
    }
    //  else {
    //   toast.error(response.error, { containerId: 'B' });
    // }
  };

  return (
    <>
      <Helmet>
        <body className="login-view-body" />
      </Helmet>
      <div className="signup">
        <div className="register-section">
          <div className="container back-color">
            <div className="rigister-tab">
              <Form onSubmit={signUp}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="register-form new-register-form">
                      <h1>Sign Up to Book</h1>
                      <div className="rigister-tab">
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicFirst" className="new-form-group">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your First Name"
                                name="firstName"
                                autoComplete="off"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                              />
                              {errors.firstname && (
                                <p className="error">{errors.firstname}</p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6">
                            <Form.Group controlId="formBasicEmail" className="new-form-group">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Your Last Name"
                                name="nickName"
                                autocomplete="off"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                              />
                              {errors.lastname && (
                                <p className="error">{errors.lastname}</p>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-6">

                            <Form.Group controlId="formBasicPassword" className="new-form-group">
                              <Form.Label>Mobile Number</Form.Label>
                              <div className="number-fields">
                                {/* <PhoneInput
                                  country="us"
                                  value={phoneCode}
                                  onChange={(e) => selectPhoneCode(`+${ e }`)}
                                  readonly
                                /> */}
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    {phoneCode}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {Object.values(countrycode1).map((el) => (

                                      <Dropdown.Item
                                        key={el.prefix}
                                        onClick={() => {
                                          selectPhoneCode(el.prefix);
                                        }}
                                      >
                                        {' '}
                                        {el.prefix}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                  type="number"
                                  placeholder="Your Mobile Number"
                                  name="phone"
                                  autocomplete="off"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                />
                              </div>
                              {errors.phone && (
                                <p className="error">{errors.phone}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-6">

                            <Form.Group controlId="formBasicPassword" className="new-form-group">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Your Email Address"
                                name="email"
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                              {errors.email && (
                                <p className="error">{errors.email}</p>
                              )}
                            </Form.Group>

                          </div>

                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword" className="new-form-group">
                              <Form.Label>Password</Form.Label>
                              <div className="password-show">
                                <Form.Control
                                  type={passType}
                                  placeholder="Create a password"
                                  name="password"
                                  autocomplete="off"
                                  onChange={(e) => setPassword(e.target.value)}
                                  value={password}
                                />
                                <i
                                  role="presentation"
                                  className={
                                    passType === 'password'
                                      ? 'fa fa-eye-slash'
                                      : 'fa fa-eye'
                                  }
                                  onClick={() => setPassType(
                                    passType === 'password'
                                      ? 'text'
                                      : 'password',
                                  )}
                                />
                              </div>
                              {errors.password && (
                                <p className="error">{errors.password}</p>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-12">
                            <Form.Group
                              controlId="formBasicCheckbox"
                              className="dis-show new-form-group"
                              name="tnc"
                              checked=""
                            >
                              <Form.Check
                                type="checkbox"
                                name="terms"
                                checked={checked2}
                                onChange={() => setChecked2(!checked2)}
                              />
                              <p>
                                Yes, I agree to the
                                {' '}
                                <Link className="terms-link" to="/">
                                  Terms of Service
                                </Link>
                                {' '}
                                and
                                {' '}
                                <Link className="terms-link" to="/">
                                  Privacy Policy
                                </Link>
                                .
                              </p>
                            </Form.Group>
                            {errors.checked2 && (
                              <span className="error">{errors.checked2}</span>
                            )}
                          </div>
                          <div className="col-md-12 new-form-group">
                            <div className="text-center">
                              <Button className="s-btn" type="submit">
                                Register
                              </Button>
                            </div>
                          </div>

                          <div className="col-md-12 new-form-group">

                            <div className="login-btn">
                              <div className="google-btn">
                                <GoogleLogin
                                  clientId={googleId}
                                  buttonText="Sign Up with Google"
                                  onSuccess={responseGoogle}
                                  onFailure={responseGoogle}
                                  cookiePolicy="single_host_origin"
                                />
                                {/* <img src={google} alt="google-icon" /> */}
                                {/* <Button variant="default">
                                Signup with Google

                                {' '}
                              </Button> */}

                                {' '}
                              </div>
                              <div className="facebook-btn">
                                {/* <img src={facebookIcon} alt="facebook-icon" /> */}
                                <FacebookLogin
                                  appId={facebookId}
                                  autoLoad={false}
                                  fields="name,email,picture"
                                  callback={responseFacebook}
                                  textButton="Sign Up with Facebook"
                                />
                                {/* <Button variant="default">
                                Signup with Facebook

                                {' '}
                              </Button> */}
                                {' '}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <p>
                              Already have an account?
                              {' '}
                              <Link className="signup-link" to="/login">
                                Log in
                              </Link>
                              {' '}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <div className="signup-box">
                              <p>
                                SignUp As Partner
                                {' '}
                                <Link className="signup-link" to="/partners/register">
                                  Sign up
                                </Link>
                                {' '}
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
