import React from "react";
import { Button } from "react-bootstrap";
// import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
// import PaymentScreen from "../../../assets/online-payment.png";
import cross from "../../../assets/cross2.png";
// import history from '../../../config/history';
import "./signupmain.css";
import history from "../../../config/history";
//import { partnerInstance } from "../../../config/axios";
import Helmet from "react-helmet";
import BookingScreen from "../../../assets/booking-2.png";
import check from "../../../assets/check-new.png";

const OnlinePayment = () => {
  const Step8 = () => {
    history.push("/partners/onlinepayment");
  };
  const handleClick = () => {
    // const update = partnerInstance().post("/updateIsStep", {
    //   isStepComplete: true,
    // });
    history.push("/partners/dashboard");
  };
  return (
    <>
      <Helmet>
        <html className="remove-sidebar" />
      </Helmet>
      <PartnerWrapper>
        <div className="partners-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="signup-block-container online-booking-container">
                  <div className="cross-icon">
                    <Link to="/partners/dashboard">
                      <img src={cross} alt="" />
                    </Link>
                  </div>
                  <div className="sb-left">
                    <h2>Incorporated card payments with no Show protection</h2>
                    <ul className="booking-list">
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>Say Goodbye to No Show and Late Cancellations</h3>
                          <p>
                            Eleminate no shows and late cancellations by
                            charging clients
                          </p>
                        </div>
                      </li>
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>No Additional Fees</h3>
                          <p>This feature is already in your Appointlee plan</p>
                        </div>
                      </li>
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>Easy Instant setup</h3>
                          <p>Follow our guided steps</p>
                        </div>
                      </li>
                      <li className="booking-list-item">
                        <div className="bl-icon">
                          <img src={check} alt="" />
                        </div>
                        <div className="bl-content">
                          <h3>Secure PCI Compliance</h3>
                          <p>
                            Your information is safe with us, card details are
                            protected with end-to-end encryptions and visible to
                            you only
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div className="sb-btn">
                      <Button onClick={() => Step8()}>Start Now</Button>
                      <Button onClick={() => handleClick()}>Skip</Button>
                    </div>
                  </div>
                  <div className="sb-right">
                    <div className="sb-right-img">
                      <img src={BookingScreen} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PartnerWrapper>
    </>
  );
};

export default OnlinePayment;
