import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, DropdownButton } from "react-bootstrap";
//import { Link } from 'react-router-dom';
//import moment from 'moment';
import ReactExport from "react-export-excel";
import { CSVLink } from "react-csv";
import Pdf from "react-to-pdf";
import close from "../../../assets/close.png";
import { partnerInstance } from "../../../config/axios";
//import history from '../../../config/history';
import PartnerContext from "../../../context/context";

const ref = React.createRef();
const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const RevenueCommission = () => {
  const [vouchers, setVouchers] = useState([]);
  const [copy, setCopy] = useState([]);
  const { username } = useContext(PartnerContext);
  const { currency } = username;

  useEffect(() => {
    getVouchers();
  }, []);
  const getVouchers = async () => {
    const response = await partnerInstance().get("/StaffCommission");
    const { code, result } = response.data;
    //const arr = [];
    if (code === 200) {
      setVouchers(result);
      setCopy(result);
    }
  };

  // const App = (data) => {
  //   history.push(
  //     `/partners/voucherviewinvoice?voucherId=${data}`,
  //   );
  // };

  return (
    <div className="revenue-voucher">
      <VouchersFilter
        vouchers={vouchers}
        voucherCopy={copy}
        setVouchers={setVouchers}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="revenue-body">
            <h4>Commisions</h4>
            <div className="revenue-table">
              <Table responsive="lg" ref={ref}>
                {vouchers.length > 0 ? (
                  <>
                    <thead>
                      <tr>
                        <th>Associate Name</th>
                        <th>Service Sales Total</th>
                        <th>Service Commission</th>
                        <th>Voucher Sales Total</th>
                        <th>Voucher Commission</th>
                        <th>Commission Total</th>
                        {/* <th>Paid Plan Sales Total</th>
                        <th>Paid Plan Commission</th> */}
                        {/* <th>Total</th>
                        <th>Redeemed</th>
                        <th>Remaining</th> */}
                      </tr>
                    </thead>
                    {vouchers.map((item) => (
                      <tbody>
                        <tr>
                          <td>{item.staffName}</td>
                          <td>
                            {currency} {item.serviceSales}
                          </td>
                          <td>{item.servicecommission} % </td>
                          <td>
                            {currency} {item.voucherSales}
                          </td>
                          <td>{item.vouchercommission}%</td>
                          <td>
                            {currency} {item.Amt}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <p>No Commissions</p>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueCommission;

const VouchersFilter = ({ vouchers, setVouchers, voucherCopy }) => {
  const [sidebartoggle, setSidebarToggle] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const copy = [...vouchers];
  const serviceSearch = (e) => {
    setSearchKeyword(e);
    if (+e.length > 0) {
      const filteredServices = copy.filter(
        (service) =>
          service.staffName.toLowerCase().indexOf(e.toLowerCase()) !== -1
      );
      setVouchers(filteredServices);
    } else {
      setVouchers(voucherCopy);
    }
  };

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(copy[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };

  const handleRefresh = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };
  const options = {
    orientation: "landscape",
    format: [1000, 1000],
  };

  return (
    <div className="vouchers-filter">
      <div className="revenue-filter">
        <div className="filter-left-block">
          <div className="revenue-search">
            <Form>
              <input
                type="text"
                placeholder="Search by Staff"
                className="form-control"
                value={searchKeyword}
                onChange={(e) => serviceSearch(e.target.value)}
                onKeyDown={handleRefresh}
              />
              <Button>
                <i className="fa fa-search" />
              </Button>
            </Form>
          </div>
        </div>

        <div className="filter-right-block">
          <div className="header-buttons">
            <DropdownButton
              className="dark-btn gbu6"
              id="dropdown-basic-button-export"
              title="Export"
            >
              <Pdf
                targetRef={ref}
                filename="commission-list.pdf"
                options={options}
              >
                {({ toPdf }) => <button onClick={toPdf}>PDF</button>}
              </Pdf>
              <h6>
                {vouchers.length > 0 && (
                  <ExcelFile
                    filename="commission-list"
                    element={<button>Excel</button>}
                  >
                    <ExcelSheet data={vouchers} name="commission-list">
                      {filterColumns(vouchers).map((col) => (
                        <ExcelColumn label={camelCase(col)} value={col} />
                      ))}
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </h6>
              <CSVLink data={vouchers} filename="commission-list.csv">
                CSV
              </CSVLink>
            </DropdownButton>
            <Button
              variant="default"
              onClick={() => setSidebarToggle(!sidebartoggle)}
            >
              Filter <i className="fa fa-sliders" />
            </Button>
          </div>
        </div>
      </div>

      <SidebarFilter
        sidebartoggle={sidebartoggle}
        setSidebarToggle={setSidebarToggle}
        vouchers={vouchers}
        setVouchers={setVouchers}
        voucherCopy={voucherCopy}
      />
    </div>
  );
};

const SidebarFilter = ({
  sidebartoggle,
  setSidebarToggle,
  vouchers,
  setVouchers,
  voucherCopy,
}) => {
  const [vStatus, setVStatus] = useState("");
  const [staffs, setStaffs] = useState([]);

  const getStaff = async () => {
    const staffData = await partnerInstance().get("/getStaffs");
    const { code, stafflist } = staffData.data;
    if (code === 200) {
      setStaffs(stafflist);
    }
  };
  useEffect(() => {
    getStaff();
  }, []);

  const filterVouchers = () => {
    const old = [...voucherCopy];
    if (vStatus !== "All") {
      const filterVoucher = old.filter((el) => el.staffName === vStatus);
      if (filterVoucher.length > 0) {
        setVouchers(filterVoucher);
      } else {
        setVouchers([]);
      }
    } else {
      setVouchers(voucherCopy);
    }
    setSidebarToggle(!sidebartoggle);
  };

  const Close = () => {
    setVouchers(voucherCopy);
    setVStatus("All");
    setSidebarToggle(!sidebartoggle);
  };

  return (
    <div className={`sidebar-items ${sidebartoggle ? "sidebar-expand" : ""}`}>
      <div className="sidebar-title">
        <h2>Filters</h2>
        <span onClick={() => setSidebarToggle(!sidebartoggle)}>
          <img src={close} alt="close" />
        </span>
      </div>
      <Form>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Staff</label>
              <select
                className="form-control"
                onChange={(e) => setVStatus(e.target.value)}
                value={vStatus}
              >
                <option value="All">All</option>
                {staffs.map((el) => (
                  <option value={el.firstname}>{el.firstname}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-12 text-right filter-buttons">
            <Button onClick={Close} variant="default" className="btn-perple">
              Clear
            </Button>
            <Button
              variant="primary"
              onClick={filterVouchers}
              className="btn-perple"
            >
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
