import React, { useState } from 'react';
import './login.css';
import { Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { facebookId, googleId } from '../../config/keys';
import { validateLogin } from '../../function/validate';
import { userInstance } from '../../config/axios';
// dummy text for code setup
const Login = ({ setIsLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginerrors, setLoginErrors] = useState({});
  const [passType, setPassType] = useState('password');
  const { state } = useLocation();
  const [partnerId] = useState(state ? state.partnerid : null);

  const login = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      password,
    };
    const validation = validateLogin({ email, password });
    setLoginErrors(validation.loginerrors);
    if (!validation.isValid) {
      return;
    }
    const loginUser = await userInstance().post('/login', payload);
    const {
      code, msg, token, userid,
    } = loginUser.data;
    if (code === 200) {
      clearValues();
      localStorage.setItem('usertoken', token);
      localStorage.setItem('userid', userid);
      setIsLogin(true);
      if (partnerId) {
        window.location.href = `/storedetail/?partnerid=${ partnerId }`;
      } else {
        window.location.href = '/';
      }
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };

  const clearValues = () => {
    setEmail('');
    setPassword('');
  };

  const responseFacebook = async (response) => {
  
    if (response.email) {
      const name = response.name.split(' ');
      const payload = {
        firstname: name[0],
        lastname: name[1],
        email: response.email,
        facebookId: response.userID,
      };
      const saveData = await userInstance().post('/facebookLogin', payload);
      const {
        code, msg, token, userid,
      } = saveData.data;
      if (code === 200) {
        toast.success(msg, { containerId: 'B' });
        localStorage.setItem('usertoken', token);
        localStorage.setItem('userid', userid);
        window.location.href = '/';
        clearValues();
      } else if (code === 409) {
        toast.error(msg, { containerId: 'B' });
      } else {
        toast.error(msg, { containerId: 'B' });
      }
    } else {
      toast.error('FaceBoook Login Unsuccessfull', { containerId: 'B' });
    }
  };

  const responseGoogle = async (response) => {
 
    if (!response.error) {
      if (response.profileObj.email) {
        const name = response.profileObj.name.split(' ');
        const payload = {
          firstname: name[0],
          lastname: name[1],
          email: response.profileObj.email,
          googleId: response.googleId,
        };
        const saveData = await userInstance().post('/googleLogin', payload);
        const {
          code, msg, token, userid,
        } = saveData.data;
        if (code === 200) {
          toast.success(msg, { containerId: 'B' });
          localStorage.setItem('usertoken', token);
          localStorage.setItem('userid', userid);
          window.location.href = '/';
        } else if (code === 409) {
          toast.error(msg, { containerId: 'B' });
        } else {
          toast.error(msg, { containerId: 'B' });
        }
      } else {
        toast.error('Google Login Unsuccessfull', { containerId: 'B' });
      }
    }
    // else {
    //   toast.error(response.error, { containerId: 'B' });
    // }
  };

  return (
    <>
      <Helmet>
        <body className="login-view-body" />
      </Helmet>
      <div className="login">
        <div className="login-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-form">
                  <h1>Login to Book</h1>
                  <div className="login-box">
                    <Form onSubmit={login}>
                      <Form.Group controlId="formBasicloginone">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Your email address"
                          name="email"
                          autoComplete="off"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {loginerrors.email && (
                          <p className="error">{loginerrors.email}</p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <div className="password-show">
                          <Form.Control
                            type={passType}
                            placeholder="Your password"
                            name="password"
                            autoComplete="off"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i
                            role="presentation"
                            className={
                              passType === 'password'
                                ? 'fa fa-eye-slash'
                                : 'fa fa-eye'
                            }
                            onClick={() => setPassType(
                              passType === 'password' ? 'text' : 'password',
                            )}
                          />
                        </div>
                        {loginerrors.password && (
                          <p className="error">{loginerrors.password}</p>
                        )}
                      </Form.Group>
                      <div className="forget-password">
                        <Link className="forget-link" to="/forget">
                          Forgot Password?
                        </Link>
                      </div>
                      <div className="login-button">
                        <Button type="submit" className="l-btn">
                          Log in
                        </Button>
                      </div>

                      <div className="row">
                        <div className="col-md-12">

                          <div className="login-btn">
                            <div className="google-btn">
                              <GoogleLogin
                                clientId={googleId}
                                buttonText="Log In with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy="single_host_origin"
                              />
                              {/* <img src={google} alt="google-icon" /> */}
                              {/* <Button variant="default">
      Signup with Google

      {' '}
    </Button> */}

                              {' '}
                            </div>
                            <div className="facebook-btn">
                              {/* <img src={facebookIcon} alt="facebook-icon" /> */}
                              <FacebookLogin
                                appId={facebookId}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                textButton="Log In with Facebook"
                              />
                              {/* <Button variant="default">
      Signup with Facebook

      {' '}
    </Button> */}
                              {' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                    <p>
                      Don't have an account yet?
                      {' '}
                      <Link className="signup-link" to="/register">
                        Sign Up
                      </Link>
                      {' '}
                    </p>
                    <p>
                      Login As Partner
                      {' '}
                      <Link className="signup-link" to="/partners/login">
                        Log In
                      </Link>
                      {' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
