/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import {
  Navbar,
  Form,
  Dropdown,
  Button,
  FormControl,
  Badge,
  //Modal,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import publicIp from "react-public-ip";
import logo from "../../assets/appointlee-new-logo1.png";
import logowhite from "../../assets/appointlee-new-logo-white.png";
import toggle from "../../assets/menu-new.png";
//import close from "../../assets/close.png";
import cross from "../../assets/cross2.png";
import { server } from "../../config/keys";
import history from "../../config/history";
//import user from "../../assets/user.jpg";
import searchimg from "../../assets/search1.png";
import NotificationPage from "../notification/notification";
import { time } from "../../socket";
import { userInstance, websiteInstance } from "../../config/axios";
import blueImage from "../../assets/profile-blue.png";

const Header = ({
  isLogin,
  LogOut,
  username,
  userImage,
  userNotifications,
}) => {
  const location = useLocation();
  const [menutoggle, setMenuToggle] = useState(false);
  const [notification, setNotification] = useState(userNotifications);

  const [notificatio, setNotificatio] = useState(false);
  const [count, setCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [businessCompany, setBusinessCompany] = useState([]);
  const [category, setCategory] = useState([]);
  const [services, setServices] = useState([]);
  const [allSearchingCopy, setAllSearchingCopy] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [address, setAddress] = useState("");
  const [search, setSearch] = useState(false);
  const [isSignUpActive, setSignUpActive] = useState(false);
  const [isLoginActive, setLoginActive] = useState(false);
  //const [cusDropdown, setCusDropdown] = useState(true);
  // const [cordinates, setCordinates] = useState({
  //   lat: 37.09024,
  //   lng: -95.712891,
  // });
  history.listen((location) => {
    if (location.pathname === "/") {
      setSearchKeyword("");
      setAddress("");
      localStorage.removeItem("key");
      localStorage.removeItem("key2");
    }
  });
  useEffect(() => {
    const url = window.location.pathname;

    if (url === "/login") {
      setLoginActive(true);
      setSignUpActive(false);
    } else if (url === "/register") {
      setSignUpActive(true);
      setLoginActive(false);
    } else if (url === "/partners/login") {
      setLoginActive(true);
      setSignUpActive(false);
    } else if (url === "/partners/register") {
      setSignUpActive(true);
      setLoginActive(false);
    } else {
      setSignUpActive(false);
      setLoginActive(false);
    }
  }, [location]);
  const handleProfile = () => {
    history.push({
      pathname: "/editprofile",
    });
  };
  const handleApp = () => {
    history.push({
      pathname: "/myappointments",
    });
  };
  const handleVoucher = () => {
    history.push({
      pathname: "/myvouchers",
    });
  };
  const handleFav = () => {
    history.push({
      pathname: "/myfavorites",
    });
  };
  const data = () => {
    setSearch(!search);
  };

  useEffect(() => {
    time((data) => {
      if (data) {
        setNotification(data);
        const filter = data && data.filter((item) => !item.read);
        setCount(filter.length);
      }
    });
    setNotification(userNotifications);
    const filter =
      userNotifications && userNotifications.filter((item) => !item.read);
    setCount(filter.length);
  }, [userNotifications]);

  const LogOutH = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userid");
    if (localStorage.getItem("admindata")) {
      localStorage.removeItem("admindata");
      window.close();
    } else {
      window.location.href = "/login";
    }
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setNotificatio(false);
          setAllSearchingCopy([]);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  const handleshownotify = async () => {
    setNotificatio(!notificatio);
    if (count > 0) {
      // eslint-disable-next-line
      const markAll = await userInstance().post("/readAllUserNotifications");
      setCount(0);
    }
  };

  useEffect(() => {
    const getBusinessCategories = async () => {
      const categoriesData = await websiteInstance().get("/HomeSearch");
      const {
        code,
        Categories,
        businessCompanies,
        AllServices,
        CategoriesServices,
      } = categoriesData.data;
      if (code === 200) {
        setCategories(Categories);
        setBusinessCompany(businessCompanies);
        setServices(AllServices);
        setCategory(CategoriesServices);
      }
    };
    getBusinessCategories();

    if (localStorage.getItem("key")) {
      setSearchKeyword(localStorage.getItem("key"));
    }
    if (localStorage.getItem("key2")) {
      setAddress(localStorage.getItem("key2"));
    }
  }, []);

  const HomeSearch = (e) => {
    setSearchKeyword(e);
    const newArr = [
      ...categories,
      ...businessCompany,
      ...services,
      ...category,
    ];

    const FilterData = newArr.filter(
      (el) =>
        (el.businessname &&
          el.businessname.toLowerCase().includes(e.toLowerCase())) ||
        (el.companyName &&
          el.companyName.toLowerCase().includes(e.toLowerCase())) ||
        (el.serviceName &&
          el.serviceName.toLowerCase().includes(e.toLowerCase())) ||
        (el.categoryname &&
          el.categoryname.toLowerCase().includes(e.toLowerCase()))
    );

    if (e.length > 0) {
      setAllSearchingCopy(FilterData);
    } else {
      setAllSearchingCopy([]);
    }
  };

  const selectCategory = (el) => {
    setSearchKeyword(el.businessname);
    localStorage.setItem("key", el.businessname);
    setAllSearchingCopy([]);
    history.push(
      `/store/?categoryid=${el._id}&businessname=${el.businessname}`
    );
  };

  const selectCompany = (el) => {
    setSearchKeyword(el.companyName);
    localStorage.setItem("key", el.companyName);
    setAllSearchingCopy([]);
    history.push(`/storedetail/?partnerid=${el._id}`);
  };

  const selectCategories = (el) => {
    setSearchKeyword(el.categoryname);
    localStorage.setItem("key", el.categoryname);
    const Companies = [...businessCompany];
    const result = Companies.map((item) => ({
      ...item,
      categoryid: item.categoryid.filter(
        (child) => child.categoryname === el.categoryname
      ),
    })).filter((item) => item.categoryid.length > 0);
    setAllSearchingCopy([]);
    history.push({
      pathname: "/store",
      state: {
        data: result,
        getLatLang: null,
      },
    });
  };

  const selectServices = (el) => {
    setSearchKeyword(el.serviceName);
    localStorage.setItem("key", el.serviceName);
    const Companies = [...businessCompany];
    const result = Companies.map((item) => ({
      ...item,
      serviceid: item.serviceid.filter(
        (child) => child.serviceName === el.serviceName
      ),
    })).filter((item) => item.serviceid.length > 0);

    setAllSearchingCopy([]);
    history.push({
      pathname: "/store",
      state: {
        data: result,
        getLatLang: null,
      },
    });
  };

  const handleAddressChange = async (addresss) => {
    setAddress(addresss);
  };

  const handleAddressSelect = async (addresss) => {
    setAddress(addresss);
    localStorage.setItem("key2", addresss);
    let country;
    const geocodeAddress = await geocodeByAddress(addresss);
    const getLatLang = await getLatLng(geocodeAddress[0]);
    //setCordinates(getLatLang);
    const latitude = getLatLang.lat;
    const longitude = getLatLang.lng;
    const Companies = [...businessCompany];
    const result = Companies.map((item) => ({
      ...item,
      partnerLocation: item.partnerLocation.filter(
        (child) => child.lat === latitude && child.lng === longitude
      ),
    })).filter((item) => item.partnerLocation.length > 0);
    if (result.length === 0) {
      const result3 = geocodeAddress[0].address_components
        .map((item) => ({
          ...item,
          types: item.types.filter((child) => child === "country"),
        }))
        .filter((item) => item.types.length > 0);
      country = result3[0].long_name;
      const result2 = Companies.map((item) => ({
        ...item,
        partnerLocation: item.partnerLocation.filter(
          (child) => child.country === country
        ),
      })).filter((item) => item.partnerLocation.length > 0);
      setSearch(!search);
      history.push({
        pathname: "/store",
        state: {
          data: result2,
          getLatLang,
        },
      });
    } else {
      setSearch(!search);
      history.push({
        pathname: "/store",
        state: {
          data: result,
          getLatLang,
        },
      });
    }
  };

  const getcurrentLocation = async () => {
    const ipv4 = await publicIp.v4();
    fetch(`https://ipapi.co/${ipv4}/json`)
      .then((res) => res.json())
      .then((response) => {
        const Companies = [...businessCompany];
        const result = Companies.map((item) => ({
          ...item,
          partnerLocation: item.partnerLocation.filter(
            (child) => child.city === response.city
          ),
        })).filter((item) => item.partnerLocation.length > 0);

        history.push({
          pathname: "/store",
          state: {
            data: result,
            getLatLang: null,
          },
        });
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  };

  return (
    <div className={`header ${search ? "searchwrap" : ""}`}>
      <div className="container">
        <Navbar expand="lg">
          <div className="header-left">
            <div className="toggle">
              <Button
                // href="javaScript:void(0)"
                onClick={() => setMenuToggle(!menutoggle)}
              >
                <img src={toggle} alt="menu" />
              </Button>
            </div>

            <Link className="navbar-brand" to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="header-right">
            <div
              className="mobile-search"
              onClick={() => data()}
              role="presentation"
            >
              {/* <i className="fa fa-search" /> */}
              <img src={searchimg} alt="search" />
            </div>
            <div className="search-container">
              <span onClick={data}>
                <i className="fa fa-times" aria-hidden="true" />
              </span>

              <Form inline>
                <Form.Group controlId="formBasicloginone">
                  <PlacesAutocomplete
                    value={address}
                    autoComplete
                    onChange={(e) => {
                      handleAddressChange(e);
                    }}
                    onSelect={handleAddressSelect}
                    types={["establishment"]}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                    }) => (
                      <div className="search-location">
                        <input
                          {...getInputProps({
                            placeholder: "Choose location",
                          })}
                        />
                        <div className="autocomplete">
                          {suggestions.map((suggestion) => (
                            <div
                              {...getSuggestionItemProps(suggestion, {})}
                              className="autocomplete-box"
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <i
                    className="fa fa-map-marker"
                    role="presentation"
                    onClick={() => getcurrentLocation()}
                  />
                </Form.Group>
                <FormControl
                  type="text"
                  placeholder="Search for a service or venue"
                  className="mr-sm-2"
                  autoComplete="off"
                  value={searchKeyword}
                  onChange={(e) => HomeSearch(e.target.value)}
                />
                <Button>
                  <img src={searchimg} alt="search" />
                </Button>
              </Form>
              {allSearchingCopy.length > 0 && (
                <div className="search-dropdown-container" ref={wrapperRef}>
                  {allSearchingCopy &&
                    allSearchingCopy.map((el) => (
                      <>
                        <div className="search-dropdown">
                          {el.businessname ? (
                            <>
                              <div
                                role="presentation"
                                className="search-items"
                                onClick={() => selectCategory(el)}
                              >
                                {" "}
                                <h4>Bussiness Category</h4>{" "}
                                <div className="search-content">
                                  <h4> {el.businessname}</h4>
                                </div>
                              </div>
                            </>
                          ) : el.companyName ? (
                            <>
                              <div
                                role="presentation"
                                className="search-items"
                                onClick={() => selectCompany(el)}
                              >
                                {" "}
                                <h4>Company Name</h4>{" "}
                                <div className="search-content">
                                  <h4> {el.companyName}</h4>
                                </div>
                              </div>
                              {/* <h3>Company Name</h3> */}
                            </>
                          ) : el.categoryname ? (
                            <>
                              <div
                                role="presentation"
                                className="search-items"
                                onClick={() => selectCategories(el)}
                              >
                                {" "}
                                <h3>Category</h3>{" "}
                                <div className="search-content">
                                  <h4> {el.categoryname}</h4>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                role="presentation"
                                className="search-items"
                                onClick={() => selectServices(el)}
                              >
                                {" "}
                                <h3>Services</h3>{" "}
                                <div className="search-content">
                                  <h4> {el.serviceName}</h4>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ))}
                </div>
              )}

              <div className="col-md-12" />
            </div>
          </div>
          <div className="header-right access-section">
            <div className="author__access_area">
              {isLogin ? (
                <>
                  {!localStorage.getItem("admindata") ? (
                    <div className="notification-area">
                      <i
                        className="fa fa-bell"
                        aria-hidden="true"
                        onClick={() => handleshownotify()}
                      />
                      <Badge variant="warning">{count}</Badge>
                    </div>
                  ) : (
                    <>
                      <div className="admin-info-view">
                        <span className="admin-access">Admin Access</span>
                      </div>
                    </>
                  )}
                  <div className="my-profile-menu">
                    <div className="p-pic">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic-admin">
                          <img
                            src={
                              userImage ? `${server}/${userImage}` : blueImage
                            }
                            alt=""
                          />
                          <div className="p-name">{username}</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => (
                              // eslint-disable-next-line
                              setSearchKeyword(""),
                              setAddress(""),
                              handleProfile()
                            )}
                          >
                            My Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => (
                              // eslint-disable-next-line
                              setSearchKeyword(""), setAddress(""), handleApp()
                            )}
                          >
                            My Appointments
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => (
                              // eslint-disable-next-line
                              setSearchKeyword(""),
                              setAddress(""),
                              handleVoucher()
                            )}
                          >
                            My Vouchers
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => (
                              // eslint-disable-next-line
                              setSearchKeyword(""), setAddress(""), handleFav()
                            )}
                          >
                            My Favourites
                          </Dropdown.Item>
                          <Dropdown.Item onClick={LogOutH}>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </>
              ) : (
                <ul className="d-flex list-unstyled align-items-center">
                  <li>
                    <Link
                      to="/login"
                      onClick={() => {
                        setLoginActive(true);
                        setSignUpActive(false);
                      }}
                      className={`access-link ${isLoginActive ? "active" : ""}`}
                    >
                      Log in
                    </Link>
                    <span> | </span>
                    <Link
                      to="/register"
                      onClick={() => {
                        setSignUpActive(true);
                        setLoginActive(false);
                      }}
                      className={`access-link signup-link ${
                        isSignUpActive ? "active" : ""
                      }`}
                    >
                      Sign up
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
          {notificatio && (
            <NotificationPage
              handleshownotify={handleshownotify}
              notification={notification}
              count={count}
              wrapperRef={wrapperRef}
            />
          )}
        </Navbar>
      </div>

      <div
        className={`sidebar-wrapper new-sidebar ${menutoggle ? "expand" : ""}`}
      >
        <div className="sidebar-menu">
          <Link className="navbar-brand" to="/">
            <img src={logowhite} alt="logo" />
          </Link>
          <div className={`close-icon ${menutoggle ? "show" : ""}`}>
            <Button onClick={() => setMenuToggle(!menutoggle)}>
              <img src={cross} alt="close" />
            </Button>
          </div>

          <div className="side-nav-content">
            <div className="row ">
              <div className="col-md-12">
                <ul className="main-navigation">
                  <li>
                    <Link to="/">Customer support</Link>
                  </li>
                  <li>
                    <Link to="/">Download our app</Link>
                  </li>
                  {/* <li>
                    <Link to="/">English</Link>
                  </li> */}
                  <li>
                    <Link to="/">For Professional</Link>
                  </li>
                  <li>
                    <Link to="/">Careers at appointlee</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="axil-contact-info-bottom">
            <div className="row ">
              <div className="col-md-12">
                <div className="axil-contact-info-inner">
                  <div className="axil-contact-info">
                    <address className="address">
                      <span className="title">Contact Information</span>
                      <p>
                        Lorem ipsum, Ap #76-756 <br /> Sit Rd, Azusa New York
                      </p>
                    </address>
                    <address className="address">
                      <span className="title">
                        We're Available 24/ 7. Call Now.
                      </span>
                      <p>
                        <a className="tel" href="tel:8884562790">
                          {/* <i className="fa fa-phone" /> */}
                          (888) 123-45678
                        </a>
                      </p>
                      <p>
                        <a className="tel" href="tel:12125553333">
                          {/* <i className="fa fa-fax" /> */}
                          (121) 987-654321
                        </a>
                      </p>
                    </address>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-7 col-xl-6 col-12">
                <div className="axil-contact-info-inner">
                  <div className="axil-contact-info">
                    <address className="address">
                      <span className="title">Contact Information</span>
                      <p>
                        Lorem ipsum, Ap #76-756
                        {' '}
                        <br />
                        {' '}
                        Sit Rd, Azusa New York
                      </p>
                    </address>
                    <address className="address">
                      <span className="title">
                        We're Available 24/ 7. Call Now.
                      </span>
                      <p>
                        <a className="tel" href="tel:8884562790">
                          <i className="fa fa-phone" />
                          (888) 123-45678
                        </a>
                      </p>
                      <p>
                        <a className="tel" href="tel:12125553333">
                          <i className="fa fa-fax" />
                          (121) 987-654321
                        </a>
                      </p>
                    </address>
                  </div>

                  <div className="axil-contact-info">
                    <h5 className="title">Find us here</h5>
                    <div className="contact-social-share d-flex align-items-center">
                      <ul className="social-share style-rounded">
                        <li>
                          <a href="">
                            <i className="fa fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
      {/* <Modal show={showSearch} onHide={handleClose} className="search-modal">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="search-container">
            <Form inline>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">London</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/">America</Dropdown.Item>
                  <Dropdown.Item href="/">China</Dropdown.Item>
                  <Dropdown.Item href="/">Russia</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <FormControl
                type="text"
                placeholder="Search for a service or venue"
                className="mr-sm-2"
              />
              <Button className="searchimg">
                <i className="fa fa-search" />
              </Button>
            </Form>
          </div>
        </Modal.Body>

      </Modal> */}
    </div>
  );
};

export default Header;
