/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, DropdownButton } from "react-bootstrap";
//import { Link } from 'react-router-dom';
import moment from "moment";
import Pdf from "react-to-pdf";
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";
import history from "../../../config/history";
import close from "../../../assets/close.png";
import { partnerInstance } from "../../../config/axios";
import { validateRange } from "../../../function/validate";
import PartnerContext from "../../../context/context";

const ref = React.createRef();
const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;
const RevenueInvoice = ({ invoices }) => {
  const [inv, setInv] = useState([]);
  const { username } = useContext(PartnerContext);
  const { currency } = username;
  useEffect(() => {
    setInv(invoices);
  }, [invoices]);

  const App = (data) => {
    if (data.appointmentID) {
      history.push(
        `/partners/viewinvoice?appointmentId=${data.appointmentID._id}`
      );
    } else {
      history.push(
        `/partners/voucherviewinvoice?voucherId=${data.voucherID[0]._id}`
      );
    }
  };

  const getInvoicestatus = (data) => {
    if (data.refund) {
      return <span className=" badge pending-badge">Refund</span>;
    }
    if (data.cancelStatus) {
      return <span className="badge pending-badge">Voided</span>;
    }
    return <span className="badge completed">Completed</span>;
  };

  const calculateVoucherPrice = (data) => {
    let amount = 0;
    data.forEach((el) => {
      amount += el.price;
    });
    return amount;
  };

  return (
    <div className="revenue-invoice">
      <InvoicesFilter invoice={invoices} setInv={setInv} />

      <div className="row">
        <div className="col-md-12" ref={ref}>
          <div className="revenue-body">
            <h4>Invoices</h4>
            <div className="revenue-table">
              <Table responsive="lg">
                {inv.length > 0 ? (
                  <>
                    <thead>
                      <tr>
                        <th className="text-left">Invoice #</th>
                        <th className="text-center">Client</th>
                        <th className="text-center">Status</th>
                        <th>Invoice date</th>
                        <th>Billing name</th>
                        <th>Location</th>
                        <th>Tips</th>
                        <th className="text-center">Gross total</th>
                      </tr>
                    </thead>

                    {inv &&
                      inv.map((el, i) => (
                        <tbody>
                          <tr>
                            <td role="presentation" onClick={() => App(el)}>
                              <a href="javascript:void(0)" className="appoi-id">
                                {el.invoiceno}
                              </a>
                            </td>
                            <td className="text-center">
                              {el.appointmentID
                                ? el.appointmentID.customerId.firstname
                                : el.voucherID[0].customerId.firstname}
                            </td>
                            <td className="text-center">
                              {getInvoicestatus(el)}
                            </td>
                            <td>{new Date(el.createdAt).toDateString()}</td>
                            <td>
                              {el.partnerId.partnerLocation[0].locationName}
                            </td>
                            <td>
                              {el.partnerId.partnerLocation[0].locationName}
                            </td>
                            <td>
                              {" "}
                              {currency}{" "}
                              {el.appointmentID ? el.appointmentID.tip : "0"}
                            </td>
                            <td className="text-center">
                              {currency}{" "}
                              {el.appointmentID
                                ? el.appointmentID.totalAmount
                                : calculateVoucherPrice(el.voucherID)}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </>
                ) : (
                  <p>No Invoices Found</p>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueInvoice;

const InvoicesFilter = ({ invoice, setInv }) => {
  const [menutoggle, setMenuToggle] = useState(false);
  const [rangeName, setRangeName] = useState("all_past_time");
  const [sidebartoggle, setSidebarToggle] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const csvData = [];
  const copy = [...invoice];
  copy.forEach((el, i) => {
    csvData.push({
      Invoice: el.invoiceno,
      Client: el.appointmentID
        ? el.appointmentID.customerId.firstname
        : el.voucherID[0].customerId.firstname,
      Status: "Completed",
      Date: new Date(el.createdAt).toDateString(),
      Billing_name: "Billing",
      Location: el.partnerId.partnerLocation[0].locationName,
      Tips: 0,
      Gross_total: el.appointmentID
        ? el.appointmentID.totalAmount
        : el.voucherID.price,
    });
  });
  const serviceSearch = (e) => {
    setSearchKeyword(e);
    // eslint-disable-next-line radix
    if (parseInt(e.length) > 0) {
      const filteredServices = copy.filter((service) =>
        service.appointmentID
          ? service.appointmentID.customerId.firstname
              .toLowerCase()
              .indexOf(e.toLowerCase()) !== -1
          : service.voucherID[0].customerId.firstname
              .toLowerCase()
              .indexOf(e.toLowerCase()) !== -1
      );
      setInv(filteredServices);
    } else {
      setInv(invoice);
    }
  };

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);
  const filterColumns = () => {
    const columns = Object.keys(csvData[0]);
    const filterColsByKey = columns;
    return filterColsByKey; // OR return columns
  };

  const handleRefresh = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  const options = {
    orientation: "landscape",
    format: [1000, 1000],
  };

  return (
    <div className="invoices-filter">
      <div className="revenue-filter">
        <div className="filter-left-block">
          <div className="calendar-box-view">
            <div className="calendar-filter-content">
              <div className="calendar-box">
                <span onClick={() => setMenuToggle(!menutoggle)}>
                  {rangeName}
                  <i className="fa fa-calendar" />
                </span>
              </div>
            </div>

            <div className="revenue-search">
              <Form>
                <input
                  type="text"
                  placeholder="Search by Invoice or Client"
                  className="form-control"
                  value={searchKeyword}
                  onChange={(e) => serviceSearch(e.target.value)}
                  onKeyDown={handleRefresh}
                />
                <Button>
                  <i className="fa fa-search" />
                </Button>
              </Form>
            </div>
          </div>
        </div>

        <div className="filter-right-block">
          <div className="header-buttons ">
            <DropdownButton
              className="dark-btn gbu6 dropdown"
              id="dropdown-basic-button-export"
              title="Export"
            >
              <Pdf targetRef={ref} filename="invoices.pdf" options={options}>
                {({ toPdf }) => <button onClick={toPdf}>PDF</button>}
              </Pdf>
              {csvData.length > 0 && (
                <ExcelFile filename="invoices" element={<button>Excel</button>}>
                  <ExcelSheet data={csvData} name="invoices">
                    {filterColumns(csvData).map((col) => (
                      <ExcelColumn label={camelCase(col)} value={col} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
              )}
              <CSVLink data={csvData} filename="invoices.csv">
                CSV
              </CSVLink>
            </DropdownButton>
            <Button
              variant="default"
              onClick={() => setSidebarToggle(!sidebartoggle)}
            >
              Filter <i className="fa fa-sliders" />
            </Button>
          </div>
        </div>
      </div>

      <FilterItems
        menutoggle={menutoggle}
        setMenuToggle={setMenuToggle}
        invoice={invoice}
        setInv={setInv}
        setRangeName={setRangeName}
        rangeName={rangeName}
      />
      <SidebarFilter
        sidebartoggle={sidebartoggle}
        setSidebarToggle={setSidebarToggle}
        setInv={setInv}
        invoice={invoice}
      />
    </div>
  );
};

const FilterItems = ({
  menutoggle,
  setMenuToggle,
  invoice,
  setInv,
  rangeName,
  setRangeName,
}) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(4, "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [errors, setErrors] = useState({});

  const setRange = (data) => {
    setRangeName(data);
    if (data === "today") {
      setStartDate(moment(new Date()).format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "yesterday") {
      setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_7_days") {
      setStartDate(moment().subtract(7, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_30_days") {
      setStartDate(moment().subtract(30, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_90_days") {
      setStartDate(moment().subtract(90, "day").format("YYYY-MM-DD"));
      setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
    } else if (data === "last_month") {
      setStartDate(
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
      );
      setEndDate(
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
      );
    } else if (data === "last_year") {
      setStartDate(
        moment().subtract(1, "year").startOf("year").format("YYYY-DD-MM")
      );
      setEndDate(
        moment().subtract(1, "year").endOf("month").format("YYYY-MM-DD")
      );
    } else if (data === "week_to_date") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "month_to_date") {
      setStartDate(moment().clone().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "quarter_to_date") {
      setStartDate(moment().startOf("quarter").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "year_to_date") {
      setStartDate(moment().startOf("year").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (data === "all_past_time") {
      setStartDate(moment().subtract(4, "year").format("YYYY-MM-DD"));
      setEndDate(moment().add(10, "year").format("YYYY-MM-DD"));
    }
  };
  const getDate = () => {
    const old = [...invoice];
    const validation = validateRange({
      startDate,
      endDate,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const filterAppByDate = old.filter(
      (el) =>
        moment(el.createdAt).format("YYYY-MM-DD") >=
          moment(startDate).format("YYYY-MM-DD") &&
        moment(el.createdAt).format("YYYY-MM-DD") <=
          moment(endDate).format("YYYY-MM-DD")
    );
    setInv(filterAppByDate);
    setMenuToggle(!menutoggle);
  };

  const Can = () => {
    setRangeName("all_past_time");
    setInv(invoice);
    setMenuToggle(!menutoggle);
  };

  const Start = (e) => {
    setStartDate(e.target.value);
  };

  const End = (e) => {
    setEndDate(e.target.value);
    setRangeName(`${startDate}  To  ${e.target.value}`);
  };
  return (
    <div className={`filter-items ${menutoggle ? "filter-expand" : ""}`}>
      <Form>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Date range</label>
              <select
                className="form-control"
                onChange={(e) => setRange(e.target.value)}
                value={rangeName}
              >
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last_7_days">Last 7 days</option>
                <option value="last_30_days">Last 30 days</option>
                <option value="last_90_days">Last 90 days</option>
                <option value="last_month">Last month</option>
                <option value="last_year">Last year</option>
                <option value="week_to_date">Week to date</option>
                <option value="month_to_date">Month to date</option>
                <option value="quarter_to_date">Quarter to date</option>
                <option value="year_to_date">Year to date</option>
                <option value="all_past_time">All time</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Starting</label>
              <input
                type="date"
                placeholder=""
                className="form-control"
                value={startDate}
                onChange={(e) => Start(e)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Ending</label>
              <input
                type="date"
                placeholder=""
                className="form-control"
                value={endDate}
                onChange={(e) => End(e)}
              />
            </div>
            {errors.endDate && <p className="error">{errors.endDate}</p>}
          </div>

          <div className="col-md-12 text-right filter-buttons">
            <Button onClick={Can} variant="default" className="btn-perple">
              Cancel
            </Button>
            <Button variant="primary" onClick={getDate} className="btn-perple">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

const SidebarFilter = ({
  sidebartoggle,
  setSidebarToggle,
  setInv,
  invoice,
}) => {
  const [locations, setLocations] = useState([]);
  const [loc, setLoc] = useState("");
  const getLocations = async () => {
    const locat = await partnerInstance().get("/getPartnerLocations");
    const { code, partnerData } = locat.data;
    if (code === 200) {
      setLocations(partnerData.partnerLocation);
    }
  };
  useEffect(() => {
    getLocations();
  }, []);

  const filterStaff = () => {
    const old = [...invoice];
    if (loc !== "All") {
      const filterStaffs = old.filter(
        (item) => item.partnerId.partnerLocation[0].locationName === loc
      );
      setInv(filterStaffs);
    } else {
      setInv(old);
    }

    setSidebarToggle(!sidebartoggle);
  };

  const Close = () => {
    setInv(invoice);
    setLoc("All");
    setSidebarToggle(!sidebartoggle);
  };
  return (
    <div className={`sidebar-items ${sidebartoggle ? "sidebar-expand" : ""}`}>
      <div className="sidebar-title">
        <h2>Filters</h2>
        <span onClick={() => setSidebarToggle(!sidebartoggle)}>
          <img src={close} alt="close" />
        </span>
      </div>
      <Form>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Location</label>
              <select
                className="form-control"
                onChange={(e) => setLoc(e.target.value)}
                value={loc}
              >
                <option value="All">All Locations</option>
                {locations.map((item) => (
                  <option value={item.locationName}>{item.locationName}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-12 text-right filter-buttons">
            <Button onClick={Close} variant="default" className="btn-perple">
              Clear
            </Button>
            <Button
              variant="primary"
              onClick={filterStaff}
              className="btn-perple"
            >
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
