/* eslint-disable radix */
import React from "react";
//import Card from "react-bootstrap/Card";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
//import { userInstance } from "../../../config/axios";
//import PartnerWrapper from "../partnerwrapper/wrapper";
import check from "../../../assets/success.png";

const Success = () => {
  // const [count, setCount] = useState(0);

  // // useEffect(() => {
  // //   if (count === 0) {
  // //     booking();
  // //   }
  // // }, []);

  // useEffect(() => {
  //   if (count < 1) {
  //     setTimeout(() => {
  //       booking();
  //     }, 500);
  //   }
  // }, [count]); // <-- See the change here

  // const booking = useCallback(async () => {

  //   const data = JSON.parse(localStorage.getItem('bookingData'));

  //   if (data) {
  //     const saveData = await userInstance().post('/bookAppointment', data);
  //     const { code, msg } = saveData.data;
  //     if (code === 200) {
  //       // toast.success(msg, { containerId: 'B' });
  //       setCount(count + 1);
  //       localStorage.removeItem('bookingData');
  //     } else if (code === 409) {
  //       // toast.error(msg, { containerId: 'B' });
  //     } else {
  //       // toast.error(msg, { containerId: 'B' });
  //     }
  //   } else {
  //     setCount(count + 1);
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        <body className="succe-233" />
      </Helmet>
      <div className="success bg-color">
        <div className="succ-12">
          <img src={check} alt='' />
          <h4>Appointment Booking Successful</h4>
          <Link to="/myappointments"> Go To Appointments </Link>
        </div>
      </div>
    </>
  );
};

export default Success;
