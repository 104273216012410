import React, { useState, useEffect } from "react";
import Slider from "react-slick";
// import {Button} from 'react-bootstrap';
import "./home.css";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { server } from "../../config/keys";
import category1 from "../../assets/beauty.jpg";
import beauty from "../../assets/user1.jpg";
//import venue from "../../assets/venue1.jpg";
import user from "../../assets/user1.jpg";
import { websiteInstance } from "../../config/axios";
import logoimage from "../../assets/logoa.png";
import owner from "../../assets/bussiness-owner.png";
import history from "../../config/history";

const Home = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getBusinessCategories = async () => {
      const categoriesData = await websiteInstance().get(
        "/getBusinessCategories"
      );
      
      const { code, Categories } = categoriesData.data;
      if (code === 200) {
        setCategories(Categories);
      }
    };
    getBusinessCategories();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleSignup = () => {
    history.push({
      pathname: "/register",
    });
  };

  const addDefaultSrc = (ev) => {
    ev.currentTarget.src = beauty;
  };

  return (
    <div className="home-wrapper">
      <div className="home-banner">
        <div className="container">
          <div className="banner-content">
            <h2>
              Appointment
              <br /> Made Easy
            </h2>
            <p>
              Aenean lacinia bibendum nulla sed consectetur. Nulla vitae elit
              libero, a pharetra augue.
            </p>
            {/* <div className="social-icon">
              <i className="fa fa-instagram" aria-hidden="true" />
              <i className="fa fa-facebook" aria-hidden="true" />
              <i className="fa fa-twitter" aria-hidden="true" />
              <i className="fa fa-youtube-play" aria-hidden="true" />
            </div> */}
          </div>
        </div>
      </div>

      <div className="home-category">
        <div className="container">
          <h2 className="title pl-0">
            Categories
            <span> in your area</span>
          </h2>
          <Slider {...settings} className="slick-width">
            {categories.map((el, i) => (
              <div className="category-box" key={i}>
                <Link
                  to={`/store/?categoryid=${el._id}&businessname=${el.businessname}`}
                >
                  <div className="category-thumb">
                    <img
                      src={el.image ? `${server}/${el.image}` : category1}
                      onError={(ev) => addDefaultSrc(ev)}
                      alt="Category"
                    />
                  </div>
                  <div className="category-info">
                    <h2>{el.businessname}</h2>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* <div className="venues-section">
        <div className="container">
          <h2 className="title">
            Venues in London
          </h2>

          <div className="row">
            <div className="col-md-4">
              <div className="featured-store-box">
                <div className="featured-store-thumb">
                  <img src={venue} alt="store" />
                  <div className="like-icon">
                    <i className="fa fa-heart-o" aria-hidden="true" />
                  </div>
                </div>
                <div className="featured-store-info">
                  <div className="venues-heading">
                    <h2>Le Fix</h2>
                    <div className="venues-rating">
                      <p>
                        <span>Great</span>
                        <br />
                        101 Ratings
                      </p>
                      <h6>3.9</h6>
                    </div>
                  </div>
                  <div className="venues-heading-bottom">
                    <p>
                      Lorem ipsum dolor sit amet,
                      {' '}
                      <br />
                      {' '}
                      consectetur adipiscing elit, sed do
                    </p>
                    <h5>Featured</h5>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-store-box">
                <div className="featured-store-thumb">
                  <img src={venue} alt="store" />
                  <div className="like-icon">
                    <i className="fa fa-heart-o" aria-hidden="true" />
                  </div>
                </div>
                <div className="featured-store-info">
                  <div className="venues-heading">
                    <h2>Le Fix</h2>
                    <div className="venues-rating">
                      <p>
                        <span>Great</span>
                        <br />
                        101 Ratings
                      </p>
                      <h6>3.9</h6>
                    </div>
                  </div>
                  <div className="venues-heading-bottom">
                    <p>
                      Lorem ipsum dolor sit amet,
                      {' '}
                      <br />
                      {' '}
                      consectetur adipiscing elit, sed do
                    </p>
                    <h5>Featured</h5>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-store-box">
                <div className="featured-store-thumb">
                  <img src={venue} alt="store" />
                  <div className="like-icon">
                    <i className="fa fa-heart-o" aria-hidden="true" />
                  </div>
                </div>
                <div className="featured-store-info">
                  <div className="venues-heading">
                    <h2>Le Fix</h2>
                    <div className="venues-rating">
                      <p>
                        <span>Great</span>
                        <br />
                        101 Ratings
                      </p>
                      <h6>3.9</h6>
                    </div>
                  </div>
                  <div className="venues-heading-bottom">
                    <p>
                      Lorem ipsum dolor sit amet,
                      {' '}
                      <br />
                      {' '}
                      consectetur adipiscing elit, sed do
                    </p>
                    <h5>Featured</h5>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-store-box">
                <div className="featured-store-thumb">
                  <img src={venue} alt="store" />
                  <div className="like-icon">
                    <i className="fa fa-heart-o" aria-hidden="true" />
                  </div>
                </div>
                <div className="featured-store-info">
                  <div className="venues-heading">
                    <h2>Le Fix</h2>
                    <div className="venues-rating">
                      <p>
                        <span>Great</span>
                        <br />
                        101 Ratings
                      </p>
                      <h6>3.9</h6>
                    </div>
                  </div>
                  <div className="venues-heading-bottom">
                    <p>
                      Lorem ipsum dolor sit amet,
                      {' '}
                      <br />
                      {' '}
                      consectetur adipiscing elit, sed do
                    </p>
                    <h5>Featured</h5>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-store-box">
                <div className="featured-store-thumb">
                  <img src={venue} alt="store" />
                  <div className="like-icon">
                    <i className="fa fa-heart-o" aria-hidden="true" />
                  </div>
                </div>
                <div className="featured-store-info">
                  <div className="venues-heading">
                    <h2>Le Fix</h2>
                    <div className="venues-rating">
                      <p>
                        <span>Great</span>
                        <br />
                        101 Ratings
                      </p>
                      <h6>3.9</h6>
                    </div>
                  </div>
                  <div className="venues-heading-bottom">
                    <p>
                      Lorem ipsum dolor sit amet,
                      {' '}
                      <br />
                      {' '}
                      consectetur adipiscing elit, sed do
                    </p>
                    <h5>Featured</h5>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="featured-store-box">
                <div className="featured-store-thumb">
                  <img src={venue} alt="store" />
                  <div className="like-icon">
                    <i className="fa fa-heart-o" aria-hidden="true" />
                  </div>
                </div>
                <div className="featured-store-info">
                  <div className="venues-heading">
                    <h2>Le Fix</h2>
                    <div className="venues-rating">
                      <p>
                        <span>Great</span>
                        <br />
                        101 Ratings
                      </p>
                      <h6>3.9</h6>
                    </div>
                  </div>
                  <div className="venues-heading-bottom">
                    <p>
                      Lorem ipsum dolor sit amet,
                      {' '}
                      <br />
                      {' '}
                      consectetur adipiscing elit, sed do
                    </p>
                    <h5>Featured</h5>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div> */}
      <div className="bussiness-owner">
        <div className="container">
          <div className="business-owner-section">
            <img src={logoimage} alt="logo" />
            <div className="business-content">
              <h6>
                Business
                <br /> Owners
                <br /> join us{" "}
              </h6>
              <p>
                Join our large community of local
                <br /> businesses that run on Appointlee.
              </p>
              <button type="submit" onClick={() => handleSignup()}>
                Learn More
              </button>
            </div>
            <div className="business-owner-image">
              <img src={owner} alt="owner" />
            </div>
          </div>
        </div>
      </div>
      <div className="featured-store">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h2 className="title">
                Featured <span>Store</span>
              </h2>

              <div className="row">
                <div className="col-md-12">
                  <div className="featured-video">
                    <ReactPlayer
                      light={false}
                      width="100%"
                      height="395px"
                      url="https://www.youtube-nocookie.com/embed/AXRZjCske8I?controls=0"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <h2 className="title">Testimonials</h2>

              <div className="scrollable-testimonial">
                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>
                      Charlie Rothes
                      <span>Businessman</span>
                    </h2>

                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>

                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>
                      Charlie Rothes
                      <span>Businessman</span>
                    </h2>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>
                      Charlie Rothes
                      <span>Businessman</span>
                    </h2>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>
                      Charlie Rothes
                      <span>Businessman</span>
                    </h2>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>
                      Charlie Rothes
                      <span>Businessman</span>
                    </h2>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>
                      Charlie Rothes
                      <span>Businessman</span>
                    </h2>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>
                <div className="testimonial-box">
                  <div className="testimonial-thumb">
                    <img src={user} alt="store" />
                  </div>
                  <div className="testimonial-info">
                    <h2>
                      Charlie Rothes
                      <span>Businessman</span>
                    </h2>
                    <p>
                      Aenean lacinia bibendum nulla sed consectetur. Nulla vitae
                      elit libero.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
