import React, { useEffect, useState, useContext } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
//import PhoneInput from 'react-phone-input-2';
import publicIp from 'react-public-ip';
//import { getClippingParents } from '@fullcalendar/react';
import moment from 'moment';
import { partnerInstance } from '../../../config/axios';
import { validatePartnerRegister } from '../../../function/validate';
import { countrycode1 } from '../../../config/phonePrefix';
import { countrycode } from './code';
import PartnerContext from '../../../context/context';
import 'react-phone-input-2/lib/material.css';

const PartnerRegister = () => {
  const { isPartnerLogin } = useContext(PartnerContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [country, setCountry] = useState('');
  // const [language, setLanguage] = useState('English');
  // const [currency, setCurrency] = useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [errors, setErrors] = useState({});
  const [checked2, setChecked2] = useState(false);
  const [countries, setCountries] = useState([]);
  const [anotherCountries, setAnotherCountries] = useState([]);
  const [timezone, setTimezone] = useState('');
  //const [timezones, setTimezones] = useState({});
  const [passType, setPassType] = useState('password');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [businessType, setBusinessType] = useState('');
  // const [staffShift, setStaffShift] = useState([]);
  const currencyDataa = [
    {
      code: 'AED',
      description: 'United Arab Emirates Dirham',
    },
    {
      code: 'AFN',
      description: 'Afghan Afghani**',
    },
    {
      code: 'ALL',
      description: 'Albanian Lek',
    },
    {
      code: 'AMD',
      description: 'Armenian Dram',
    },
    {
      code: 'ANG',
      description: 'Netherlands Antillean Gulden',
    },
    {
      code: 'AOA',
      description: 'Angolan Kwanza**',
    },
    {
      code: 'ARS',
      description: 'Argentine Peso**',
    },
    {
      code: 'AUD',
      description: 'Australian Dollar',
    },
    {
      code: 'AWG',
      description: 'Aruban Florin',
    },
    {
      code: 'AZN',
      description: 'Azerbaijani Manat',
    },
    {
      code: 'BAM',
      description: 'Bosnia & Herzegovina Convertible Mark',
    },
    {
      code: 'BBD',
      description: 'Barbadian Dollar',
    },
    {
      code: 'BDT',
      description: 'Bangladeshi Taka',
    },
    {
      code: 'BGN',
      description: 'Bulgarian Lev',
    },
    {
      code: 'BIF',
      description: 'Burundian Franc',
    },
    {
      code: 'BMD',
      description: 'Bermudian Dollar',
    },
    {
      code: 'BND',
      description: 'Brunei Dollar',
    },
    {
      code: 'BOB',
      description: 'Bolivian Boliviano**',
    },
    {
      code: 'BRL',
      description: 'Brazilian Real**',
    },
    {
      code: 'BSD',
      description: 'Bahamian Dollar',
    },
    {
      code: 'BWP',
      description: 'Botswana Pula',
    },
    {
      code: 'BZD',
      description: 'Belize Dollar',
    },
    {
      code: 'CAD',
      description: 'Canadian Dollar',
    },
    {
      code: 'CDF',
      description: 'Congolese Franc',
    },
    {
      code: 'CHF',
      description: 'Swiss Franc',
    },
    {
      code: 'CLP',
      description: 'Chilean Peso**',
    },
    {
      code: 'CNY',
      description: 'Chinese Renminbi Yuan',
    },
    {
      code: 'COP',
      description: 'Colombian Peso**',
    },
    {
      code: 'CRC',
      description: 'Costa Rican Colón**',
    },
    {
      code: 'CVE',
      description: 'Cape Verdean Escudo**',
    },
    {
      code: 'CZK',
      description: 'Czech Koruna**',
    },
    {
      code: 'DJF',
      description: 'Djiboutian Franc**',
    },
    {
      code: 'DKK',
      description: 'Danish Krone',
    },
    {
      code: 'DOP',
      description: 'Dominican Peso',
    },
    {
      code: 'DZD',
      description: 'Algerian Dinar',
    },
    {
      code: 'EGP',
      description: 'Egyptian Pound',
    },
    {
      code: 'ETB',
      description: 'Ethiopian Birr',
    },
    {
      code: 'EUR',
      description: 'Euro',
    },
    {
      code: 'FJD',
      description: 'Fijian Dollar',
    },
    {
      code: 'FKP',
      description: 'Falkland Islands Pound**',
    },
    {
      code: 'GBP',
      description: 'British Pound',
    },
    {
      code: 'GEL',
      description: 'Georgian Lari',
    },
    {
      code: 'GIP',
      description: 'Gibraltar Pound',
    },
    {
      code: 'GMD',
      description: 'Gambian Dalasi',
    },
    {
      code: 'GNF',
      description: 'Guinean Franc**',
    },
    {
      code: 'GTQ',
      description: 'Guatemalan Quetzal**',
    },
    {
      code: 'GYD',
      description: 'Guyanese Dollar',
    },
    {
      code: 'HKD',
      description: 'Hong Kong Dollar',
    },
    {
      code: 'HNL',
      description: 'Honduran Lempira**',
    },
    {
      code: 'HRK',
      description: 'Croatian Kuna',
    },
    {
      code: 'HTG',
      description: 'Haitian Gourde',
    },
    {
      code: 'HUF',
      description: 'Hungarian Forint**',
    },
    {
      code: 'IDR',
      description: 'Indonesian Rupiah',
    },
    {
      code: 'ILS',
      description: 'Israeli New Sheqel',
    },
    {
      code: 'INR',
      description: 'Indian Rupee**',
    },
    {
      code: 'ISK',
      description: 'Icelandic Króna',
    },
    {
      code: 'JMD',
      description: 'Jamaican Dollar',
    },
    {
      code: 'JPY',
      description: 'Japanese Yen',
    },
    {
      code: 'KES',
      description: 'Kenyan Shilling',
    },
    {
      code: 'KGS',
      description: 'Kyrgyzstani Som',
    },
    {
      code: 'KHR',
      description: 'Cambodian Riel',
    },
    {
      code: 'KMF',
      description: 'Comorian Franc',
    },
    {
      code: 'KRW',
      description: 'South Korean Won',
    },
    {
      code: 'KYD',
      description: 'Cayman Islands Dollar',
    },
    {
      code: 'KZT',
      description: 'Kazakhstani Tenge',
    },
    {
      code: 'LAK',
      description: 'Lao Kip**',
    },
    {
      code: 'LBP',
      description: 'Lebanese Pound',
    },
    {
      code: 'LKR',
      description: 'Sri Lankan Rupee',
    },
    {
      code: 'LRD',
      description: 'Liberian Dollar',
    },
    {
      code: 'LSL',
      description: 'Lesotho Loti',
    },
    {
      code: 'MAD',
      description: 'Moroccan Dirham',
    },
    {
      code: 'MDL',
      description: 'Moldovan Leu',
    },
    {
      code: 'MGA',
      description: 'Malagasy Ariary',
    },
    {
      code: 'MKD',
      description: 'Macedonian Denar',
    },
    {
      code: 'MNT',
      description: 'Mongolian Tögrög',
    },
    {
      code: 'MOP',
      description: 'Macanese Pataca',
    },
    {
      code: 'MRO',
      description: 'Mauritanian Ouguiya',
    },
    {
      code: 'MUR',
      description: 'Mauritian Rupee**',
    },
    {
      code: 'MVR',
      description: 'Maldivian Rufiyaa',
    },
    {
      code: 'MWK',
      description: 'Malawian Kwacha',
    },
    {
      code: 'MXN',
      description: 'Mexican Peso**',
    },
    {
      code: 'MYR',
      description: 'Malaysian Ringgit',
    },
    {
      code: 'MZN',
      description: 'Mozambican Metical',
    },
    {
      code: 'NAD',
      description: 'Namibian Dollar',
    },
    {
      code: 'NGN',
      description: 'Nigerian Naira',
    },
    {
      code: 'NIO',
      description: 'Nicaraguan Córdoba**',
    },
    {
      code: 'NOK',
      description: 'Norwegian Krone',
    },
    {
      code: 'NPR',
      description: 'Nepalese Rupee',
    },
    {
      code: 'NZD',
      description: 'New Zealand Dollar',
    },
    {
      code: 'PAB',
      description: 'Panamanian Balboa**',
    },
    {
      code: 'PEN',
      description: 'Peruvian Nuevo Sol**',
    },
    {
      code: 'PGK',
      description: 'Papua New Guinean Kina',
    },
    {
      code: 'PHP',
      description: 'Philippine Peso',
    },
    {
      code: 'PKR',
      description: 'Pakistani Rupee',
    },
    {
      code: 'PLN',
      description: 'Polish Złoty',
    },
    {
      code: 'PYG',
      description: 'Paraguayan Guaraní**',
    },
    {
      code: 'QAR',
      description: 'Qatari Riyal',
    },
    {
      code: 'RON',
      description: 'Romanian Leu',
    },
    {
      code: 'RSD',
      description: 'Serbian Dinar',
    },
    {
      code: 'RUB',
      description: 'Russian Ruble',
    },
    {
      code: 'RWF',
      description: 'Rwandan Franc',
    },
    {
      code: 'SAR',
      description: 'Saudi Riyal',
    },
    {
      code: 'SBD',
      description: 'Solomon Islands Dollar',
    },
    {
      code: 'SCR',
      description: 'Seychellois Rupee',
    },
    {
      code: 'SEK',
      description: 'Swedish Krona',
    },
    {
      code: 'SGD',
      description: 'Singapore Dollar',
    },
    {
      code: 'SHP',
      description: 'Saint Helenian Pound**',
    },
    {
      code: 'SLL',
      description: 'Sierra Leonean Leone',
    },
    {
      code: 'SOS',
      description: 'Somali Shilling',
    },
    {
      code: 'SRD',
      description: 'Surinamese Dollar**',
    },
    {
      code: 'STD',
      description: 'São Tomé and Príncipe Dobra',
    },
    {
      code: 'SVC',
      description: 'Salvadoran Colón**',
    },
    {
      code: 'SZL',
      description: 'Swazi Lilangeni',
    },
    {
      code: 'THB',
      description: 'Thai Baht',
    },
    {
      code: 'TJS',
      description: 'Tajikistani Somoni',
    },
    {
      code: 'TOP',
      description: 'Tongan Paʻanga',
    },
    {
      code: 'TRY',
      description: 'Turkish Lira',
    },
    {
      code: 'TTD',
      description: 'Trinidad and Tobago Dollar',
    },
    {
      code: 'TWD',
      description: 'New Taiwan Dollar',
    },
    {
      code: 'TZS',
      description: 'Tanzanian Shilling',
    },
    {
      code: 'UAH',
      description: 'Ukrainian Hryvnia',
    },
    {
      code: 'UGX',
      description: 'Ugandan Shilling',
    },
    {
      code: 'USD',
      description: 'United States Dollar',
    },
    {
      code: 'UYU',
      description: 'Uruguayan Peso**',
    },
    {
      code: 'UZS',
      description: 'Uzbekistani Som',
    },
    {
      code: 'VND',
      description: 'Vietnamese Đồng',
    },
    {
      code: 'VUV',
      description: 'Vanuatu Vatu',
    },
    {
      code: 'WST',
      description: 'Samoan Tala',
    },
    {
      code: 'XAF',
      description: 'Central African Cfa Franc',
    },
    {
      code: 'XCD',
      description: 'East Caribbean Dollar',
    },
    {
      code: 'XOF',
      description: 'West African Cfa Franc**',
    },
    {
      code: 'XPF',
      description: 'Cfp Franc**',
    },
    {
      code: 'YER',
      description: 'Yemeni Rial',
    },
    {
      code: 'ZAR',
      description: 'South African Rand',
    },
    {
      code: 'ZMW',
      description: 'Zambian Kwacha',
    },
  ];

  useEffect(() => {
    if (
      localStorage.getItem('authtoken')
      && localStorage.getItem('partnertoken')
    ) {
      window.location.href = '/partners/dashboard';
    }
  }, []);
  useEffect(() => {
    const getBusinessType = async () => {
      const businessData = await partnerInstance().get('/getBusinessType');
      const { code, businessType } = businessData.data;
      if (code === 200) {
        setBusinessTypes(businessType);
      }
    };

    const getCountries = async () => {
      const countryData = await partnerInstance().get('/countries');
    
      const { code, countriesdata } = countryData.data;
      if (code === 200) {
        setCountries(countrycode);
        setAnotherCountries(countriesdata);
      }
    };
    const getTimezones = async () => {
      //const timezoneData = await partnerInstance().get('/timezones');

      // const { code, timezonesdata } = timezoneData.data;
      //  if (code === 200) {
      //   //setTimezones(timezonesdata);
      //  }
    };
    // const getPhoneCode = async () => {
    //   // countrycode.map((el) => {
    //   //   if (el.name === country) {
    //   //     setPhoneCode(el.code);
    //   //   }
    //   // });
    // };
    const getCountry = async () => {
      const ipv4 = await publicIp.v4();
      // const ipv4 = '5.8.159.255';
      fetch(`https://ipapi.co/${ipv4}/json`)
        .then((res) => res.json())
        .then((response) => {
          
          setTimezone(response.timezone);
          setCountry(response.country_name);
          setPhoneCode(response.country_calling_code);
          setCurrencyName(response.currency);
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });

      
    };
   
    getBusinessType();
    getTimezones();
    getCountries();
    
    getCountry();
    if (isPartnerLogin) {
      window.location.href = '/partners/dashboard';
    }
    // eslint-disable-next-line
  }, []);

  const handleCountrySelect = async (e) => {
    
    e.preventDefault();
    const countryName = e.target.value;
    setCountry(countryName);
    Object.keys(anotherCountries).forEach((country) => {
      if (anotherCountries[country].name === countryName) {
        setTimezone(anotherCountries[country].timezones[0]);
      }
    });
  };
 
  const selectPhoneCode = (item) => {
    setPhoneCode(item);
  };

  const signUp = async (e) => {
    e.preventDefault();
    // Default Working Hour
    const days = [];
    function getCurrentWeek() {
      const currentDat = moment();
      // if (country === 'United Arab Emirates') {
      //   const weekStart = currentDat.clone().startOf('isoWeek');
      //   for (let i = 0; i <= 6; i++) {
      //     if (i !== 5) {
      //       days.push({
      //         date: new Date(moment(weekStart).add(i, 'days').format('YYYY-MM-DD')).toString(),
      //         timing: [{ starttime: '08:30', endtime: '17:30' }],
      //         repeat: 'weekly',
      //       });
      //     }
      //   }
      // } else {
      const weekStart = currentDat.clone().startOf('isoWeek');
      for (let i = 0; i <= 5; i++) {
        days.push({
          date: new Date(moment(weekStart).add(i, 'days').format('YYYY-MM-DD')).toString(),
          timing: [{ starttime: '09:00', endtime: '18:00' }],
          repeat: 'weekly',
        });
      }
      // }
    }

    getCurrentWeek();
    const payload = {
      firstname,
      lastname,
      email,
      password,
      country,
      currency: currencyName,
      timezone,
      companyName,
      businessType,
      phone,
      phoneCode,
      shift: days,
    };
    const validation = validatePartnerRegister({
      firstname,
      lastname,
      email,
      phone,
      password,
      checked2,
      companyName,
      businessType,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const saveData = await partnerInstance().post('/partnerSignup', payload);
    const { code, msg } = saveData.data;
    if (code === 200) {
      toast.success(msg, { containerId: 'B' });
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const clearValues = () => {
    setEmail('');
    setPassword('');
    setFirstname('');
    setLastname('');
    setChecked2('');
    setPhone('');
    setCompanyName('');
  };

  return (
    <>
      <Helmet>
        <body className="login-view-body" />
      </Helmet>
      <div className="signup">
        <div className="register-section">
          <div className="container back-color">
            <div className="row">
              <div className="col-md-12">
                <div className="register-form new-register-form">
                  <h1>Sign Up as a Partner</h1>
                  <div className="rigister-tab">
                    <Form onSubmit={signUp}>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicFirst" className="new-form-group">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your First Name"
                              name="firstName"
                              autocomplete="off"
                              onChange={(e) => setFirstname(e.target.value)}
                              value={firstname}
                            />
                            {errors.firstname && (
                              <p className="error">{errors.firstname}</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicEmail" className="new-form-group">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your Last Name"
                              name="nickName"
                              autocomplete="off"
                              onChange={(e) => setLastname(e.target.value)}
                              value={lastname}
                            />
                            {errors.lastname && (
                              <p className="error">{errors.lastname}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group controlId="formBasicPassword" className="new-form-group">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Your Email Address"
                              name="email"
                              autocomplete="off"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                            {errors.email && (
                              <p className="error">{errors.email}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group controlId="formBasicPassword" className="new-form-group">
                            <Form.Label>Mobile Number</Form.Label>
                            <div className="number-fields">
                              {/* <PhoneInput
                                country="us"
                                value={phoneCode}
                                onChange={(e) => selectPhoneCode(`+${ e }`)}
                                readonly
                              // onChange={(e) => selectPhoneCode(e)}
                              /> */}
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  {phoneCode}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {Object.values(countrycode1).map((el) => (

                                    <Dropdown.Item
                                      key={el.prefix}
                                      onClick={() => {
                                        selectPhoneCode(el.prefix);
                                      }}
                                    >
                                      {' '}
                                      {el.prefix}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                              <Form.Control
                                type="number"
                                placeholder="Your Mobile Number"
                                name="phone"
                                autocomplete="off"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                              />
                            </div>
                            {errors.phone && (
                              <p className="error">{errors.phone}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-12">
                          <Form.Group controlId="formBasicPassword" className="new-form-group">
                            <Form.Label>Password</Form.Label>
                            <div className="password-show">
                              <Form.Control
                                type={passType}
                                placeholder="Create a password"
                                name="password"
                                autocomplete="off"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                              />
                              <i
                                className={
                                  passType === 'password'
                                    ? 'fa fa-eye-slash'
                                    : 'fa fa-eye'
                                }
                                onClick={() => setPassType(
                                  passType === 'password' ? 'text' : 'password',
                                )}
                              />
                            </div>
                            {errors.password && (
                              <p className="error">{errors.password}</p>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group controlId="formBasicFirst" className="new-form-group">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your Company Name"
                              name="companyName"
                              autocomplete="off"
                              onChange={(e) => setCompanyName(e.target.value)}
                              value={companyName}
                            />
                            {errors.companyName && (
                              <p className="error">{errors.companyName}</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicEmail" className="new-form-group">
                            <Form.Label>Business Type</Form.Label>
                            <Form.Control
                              as="select"
                              placeholder="Business Type"
                              onChange={(e) => setBusinessType(e.target.value)}
                              value={businessType}
                            >
                              <option value="">Choose Bussines </option>
                              {businessTypes.map((item, i) => (
                                <option key={i} value={item._id}>
                                  {item.businessname}
                                </option>
                              ))}
                            </Form.Control>
                            {errors.businessType && (
                              <p className="error">{errors.businessType}</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicFirst" className="new-form-group">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                              as="select"
                              defaultValue={country}
                              placeholder="Select Country"
                              selected={country}
                              value={country}
                              onChange={(e) => handleCountrySelect(e)}
                            // onSelect={(value) => handleCountrySelect(value)}
                            >
                              {Object.keys(countries).map((el, i) => (
                                <option>{countries[el].name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        {/* <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>TimeZone</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue={timezone}
                            selected={timezone}
                            value={timezone}
                            onChange={(e) => setTimezone(e.target.value)}
                          >
                            {Object.keys(timezones).map((el, i) => (
                              <option value={timezones[el].name}>
                                {timezones[el].name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </div> */}
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicEmail" className="new-form-group">
                            <Form.Label>Currency</Form.Label>
                            <Form.Control
                              as="select"
                              // defaultValue={currencyName}
                              onChange={(e) => setCurrencyName(e.target.value)}
                              value={currencyName}
                              selected={currencyName}
                            >
                              {currencyDataa.map((item, i) => (
                                <option key={i} value={item.code}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          {/* {currency && (
                          <SelectCurrency
                            value={currency}
                            defaultValue={currency}
                            onCurrencySelected={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            selected={currency}
                            placeholder="ggg"
                          />
                        )} */}
                        </div>
                        {/* <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Language</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => setLanguage(e.target.value)}
                            value={language}
                            selected="English"
                          >
                            <option value="English" selected>
                              English
                            </option>
                            <option value="Soumi">Soumi</option>
                            <option value="Polski">Polski</option>
                            <option value="Netherlands">Netherlands</option>
                          </Form.Control>
                        </Form.Group>
                      </div> */}

                        <div className="col-md-12">
                          <Form.Group
                            controlId="formBasicCheckbox"
                            className="dis-show new-form-group"
                            name="tnc"
                            checked=""
                          >
                            <Form.Check
                              type="checkbox"
                              name="terms"
                              checked={checked2}
                              onChange={() => setChecked2(!checked2)}
                            />
                            <p>
                              Yes, I agree to the
                              {' '}
                              <Link className="terms-link" to="/">
                                Terms of Service
                              </Link>
                              {' '}
                              and
                              {' '}
                              <Link className="terms-link" to="/">
                                Privacy Policy
                              </Link>
                              .
                            </p>
                          </Form.Group>
                          {errors.checked2 && (
                            <span className="error">{errors.checked2}</span>
                          )}
                        </div>



                        <div className="col-md-12 new-form-group">
                          <div className="text-center">
                            <Button className="s-btn" type="submit">
                              Register
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <p>
                      Already have a partner account?
                      {' '}
                      <Link className="signup-link" to="/partners/login">
                        Log in
                      </Link>
                      {' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerRegister;
