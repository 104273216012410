/* eslint-disable radix */
import React, { useEffect, useState, useCallback } from "react";
import { Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
//import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
//import ReactExport from 'react-export-excel';
import AdminWrapper from "../adminwrapper/wrapper";
import { adminInstance } from "../../../config/axios";
//import { client } from '../../../config/keys';
import Loader from "../../loader/loader";

const AdminCron = ({ name }) => {
  const [partners, setPartners] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllPartners = useCallback(async () => {
    if (skip === 0) {
      setLoading(true);
    }

    const payload = {
      skip,
      limit,
    };
    const partnerData = await adminInstance().post("/partners", payload);
    const { code, findData, length } = partnerData.data;
    const arr = [];
    if (code === 200) {
      setPartners(findData);
      setTotalCount(length);
      findData.forEach((el) => {
        arr.push({
          name: el.firstname,
          email: el.email,
          phone: el.phone,
          Address: el.address[0].country,
          BussinessType: el.businessType.businessname,
          status: el.publish ? "Online" : "Offline",
        });
      });
      setLoading(false);
    }
  }, [skip, limit]);

  useEffect(() => {
    getAllPartners();
  }, [getAllPartners]);

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount = Math.ceil(totalCount / limit);

  const setRun = async (id) => {
    const payload = {
      Id: id,
    };
    const saveCategory = await adminInstance().post("/singleCron", payload);
    const { code, msg } = saveCategory.data;
    if (code === 200) {
      toast.error(msg, { containerId: "B" });
      getAllPartners();
    }
  };

  if (loading) {
    return (
      <AdminWrapper>
        <Loader />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper name={name}>
      <div className="Admin-partners">
        <div className="container">
          <div className="partner-heading">
            <h2 className="admin-title">Partners</h2>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="partner-header">
                    <h2>Partners</h2>
                  </div> */}
                  <div className="partner-table">
                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th className="text-left">Name</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Phone</th>
                          <th className="text-center">Address</th>
                          <th className="text-center">Business Type</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      {partners &&
                        partners.map((el) => (
                          <tbody>
                            <tr>
                              <td className="text-left">
                                {el.firstname} {el.lastname}
                              </td>
                              <td className="text-center">{el.email}</td>
                              <td className="text-center">{el.phone}</td>
                              <td className="text-center">
                                {el.address[0].country}
                              </td>
                              <td className="text-center">
                                {el.businessType.businessname}
                              </td>
                              <td className="text-center">
                                {el.publish ? "Online" : "Offline"}
                              </td>
                              <td className="text-center">
                                {el.iscron ? (
                                  <Button className="delete-btn"> Done</Button>
                                ) : (
                                  <Button
                                    className="delete-btn"
                                    onClick={() => setRun(el._id)}
                                  >
                                    {" "}
                                    Run
                                  </Button>
                                )}{" "}
                                {/* <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname: `/admin/monthlisting/${el._id}`,
                                    }}
                                  >
                                    {' '}
                                    Account Balance
                                  </Link>
                                  {el.publish ? (
                                    <Dropdown.Item onClick={() => setPublish(el._id)}>
                                      Online
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item onClick={() => setPublish(el._id)}>
                                      Offline
                                    </Dropdown.Item>
                                  )}

                                  <Dropdown.Item onClick={() => setAccess(el._id)}>
                                    Access Account
                                  </Dropdown.Item>
                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname: `/admin/partnercommission/${el._id}`,
                                    }}
                                  >
                                    {' '}
                                    Commission Settings
                                    {' '}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname: `/admin/payout/${el._id}`,
                                    }}
                                  >
                                    {' '}
                                    Payouts
                                    {' '}
                                  </Link>
                                </Dropdown.Menu>
                              </Dropdown> */}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </Table>
                  </div>
                  {/* <Button onClick={() => Pay()}>Pay </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="store-pagination col-md-12 text-center">
            {totalCount >= limit && (
              <div className="row">
                <div className="col-md-12">
                  <div className="pagination-section">
                    <ReactPaginate
                      previousLabel="← Previous"
                      nextLabel="Next →"
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName="pagination-chk"
                      previousLinkClassName="pagination__link"
                      nextLinkClassName="pagination__link"
                      disabledClassName="pagination__link--disabled"
                      activeClassName="pagination__link--active"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminCron;
