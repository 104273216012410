import React, { useState } from 'react';
import Helmet from 'react-helmet';
import MyProfileMenu from './menu';
import './myprofile.css';

const MyProfile = (prop) => {
  const [toggle, setToggle] = useState();
  const handletoggle = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <Helmet>
        <html lang="en" className={`login-view-body bg-show ${toggle ? 'bg-hide' : ''}`} />
      </Helmet>
      <div className="my-profile bg-color">
        <div className="container">
          <div className="profile-toggle">
            {/* <h2 className="profile-title">My Profile</h2> */}
            <div className="toggle-sidebar">
              <i className="fa fa-bars" aria-hidden="true" onClick={handletoggle} role="presentation" />
            </div>
          </div>

          <div className={`my-profile-container new-profile-container ${toggle ? 'main-layout-expand' : ''}`}>

            <div className="my-profile-sidebar new-profile-sidebar">
              <MyProfileMenu username={prop.username} userImage={prop.userImage} />
            </div>

            <div className="my-profile-content">
              {prop.children}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
