import React from "react";
//import { Button } from "react-bootstrap";
import PartnerWrapper from "../../partnerwrapper/wrapper";
import cross from "../../../../assets/cross2.png";
import SignupBusiness from "../../partnersignup/business";

const Business = () => (
  <PartnerWrapper>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="signup-step-form">
            <div className="cross-icon">
              <img src={cross} alt="" />
            </div>
            <div className="signup-left">
              <div className="signup-head">
                <h3>Business Partner Setup</h3>
              </div>
              <div className="signup-body">
                <SignupBusiness />
              </div>
            </div>
            {/* <div className="signup-right">
              <div className="signup-footer-btn">
                <Button typr="submit">Save</Button>
                <Button type="submit">Update</Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </PartnerWrapper>
);

export default Business;
