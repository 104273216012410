import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import ReactPaginate from 'react-paginate';
// import Tabs from 'react-bootstrap/Tabs';
// import moment from 'moment';
import { Link, useLocation } from "react-router-dom";
import AdminWrapper from "../adminwrapper/wrapper";
import { adminInstance } from "../../../config/axios";
// import Loader from '../../loader/loader';

const AccountBalance = ({ name }) => {
  const [dataa, setData] = useState([]);
  const [monthBill, setMonthBill] = useState(0);
  const [monthFee, setMonthFee] = useState(0);
  const [commission, setCommission] = useState(0);
  const [onlinePaisa, setOnlinePaisa] = useState(0);
  const [payoutPartner, setPayoutPartner] = useState(0);
  //const now = new Date();
  //const currentYear = now.getFullYear();
  //const currentMonth = (`0${now.getMonth() + 1}`).slice(-2);
  //const [current, setCurrent] = useState(`${currentYear}-${currentMonth}`);
  //const monthData = `${currentYear}-${currentMonth}`;
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf("/") + 1);
  const { state } = useLocation();
  useEffect(() => {
    updateBusiness();
    // Payment();
    // eslint-disable-next-line
  }, []);

  const updateBusiness = async () => {
    const payloadd = {
      Id: id,
      month: state.month,
      year: state.year,
    };

    const saveCategory = await adminInstance().post(
      "/partnerTransactions",
      payloadd
    );
    const {
      code,
      data,
      monthlyBill,
      totalCommission,
      monthlyFee,
      onlineAmount,
      payout,
    } = saveCategory.data;
    if (code === 200) {
      setData(data);
      setMonthBill(monthlyBill);
      setMonthFee(monthlyFee);
      setCommission(totalCommission);
      setOnlinePaisa(onlineAmount);
      setPayoutPartner(payout);
    }
  };

  // const setRange = (e) => {
  //   setCurrent(e.target.value);
  //   updateBusiness(e.target.value);
  // };

  // const Payment = async () => {
  //   // await adminInstance().post(
  //   //   '/singlePartnerPayout', { Id: id },
  //   // );
  //   const response = await adminInstance().get(
  //     '/allPartnerPayout',
  //   );

  // };

  return (
    <AdminWrapper name={name}>
      <div className="Admin-partners">
        <div className="container">
          <div className="back-btn">
            <h2 className="admin-title">Account</h2>
            <Link to={`/admin/monthlisting/${id}`}>
              <h2>Back</h2>
            </Link>
          </div>
          <div className="row">
            {/* <input
              type="month"
              value={current}
              onChange={(e) => setRange(e)}
            /> */}
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="partner-header">
                    <h2>Account</h2>
                  </div> */}
                  <div className="partner-table">
                    {dataa && dataa.length > 0 ? (
                      <Table responsive="lg">
                        <thead>
                          <tr>
                            <th className="text-left">Date</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Payment Method</th>
                            <th className="text-center">Amount</th>
                            <th className="text-center">
                              (Commission + Fixed Charge)
                            </th>
                          </tr>
                        </thead>
                        {dataa &&
                          dataa.map((el) => (
                            <tbody>
                              <tr>
                                <td className="text-left">{el.date}</td>
                                <td className="text-center">{el.status}</td>
                                <td className="text-center">
                                  {el.paymentMethod}
                                </td>
                                <td className="text-center">{el.amount}</td>
                                <td className="text-center">{el.commission}</td>
                              </tr>
                            </tbody>
                          ))}
                        <tr className="no-border">
                          <td colSpan="3" />
                          <td>Total Commission</td>
                          <td> {commission}</td>
                        </tr>
                        <tr className="no-border">
                          <td colSpan="3" />
                          <td>Online Amount</td>
                          <td> {onlinePaisa}</td>
                        </tr>
                        <tr className="no-border">
                          <td colSpan="3" />
                          <td>Monthly Fee</td>
                          <td> {monthFee}</td>
                        </tr>
                        <tr className="no-border">
                          <td colSpan="3" />
                          <td>Monthly Bill</td>
                          <td> {monthBill}</td>
                        </tr>

                        <tr className="no-border">
                          <td colSpan="3" />
                          <td>Payout</td>
                          <td> {payoutPartner}</td>
                        </tr>
                      </Table>
                    ) : (
                      <div className="noservice-found">
                        <h3>No Transactions</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="store-pagination col-md-12 text-center">
            {/* {totalCount >= limit && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel="← Previous"
                    nextLabel="Next →"
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName="pagination-chk"
                    previousLinkClassName="pagination__link"
                    nextLinkClassName="pagination__link"
                    disabledClassName="pagination__link--disabled"
                    activeClassName="pagination__link--active"
                  />
                </div>
              </div>
            </div>
            )} */}
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AccountBalance;
