import React, { useState, useEffect, useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
import "./style.css";
import { partnerInstance } from "../../../config/axios";
import { validateTax } from "../../../function/validate";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";

const Taxes = () => {
  const { info } = useLocation();
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [taxName, setTaxName] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [taxess, setTaxess] = useState([]);
  const [taxId, setTaxId] = useState("");
  const [errors, setErrors] = useState({});
  const [showDefaultTax, setShowDefaultTax] = useState("");
  const [defaultTaxValue, setDefaultTaxValue] = useState("");
  const [showtaxcalculation, setTaxClaculation] = useState(false);
  const [taxInclude, setTaxInclude] = useState(false);
  const [taxExclude, setTaxExclude] = useState(false);
  const [company, setCompany] = useState("");

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Account Setup"
        );
      if (findBook > -1) {
        getTaxes();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const getTaxes = async () => {
    setLoading(true);
    const taxDataa = await partnerInstance().get("/getTaxes");
    const { code, taxData } = taxDataa.data;
    if (code === 200) {
      setTaxess(taxData.tax);
      setTaxExclude(taxData.retailPriceExcludeTax);
      setTaxInclude(taxData.retailPriceIncludeTax);
      setCompany(taxData.companyName);
      const filterDefaultTax = taxData.tax.filter((el) => el.default === true);
      if (filterDefaultTax.length > 0) {
        setShowDefaultTax(filterDefaultTax[0].taxname);
        setDefaultTaxValue(filterDefaultTax[0]._id);
      } else {
        setShowDefaultTax("No Tax");
        setDefaultTaxValue("");
      }
    } else {
      setTaxInclude(false);
      setTaxExclude(false);
    }
    setLoading(false);
  };

  const addTaxx = async (e) => {
    e.preventDefault();
    const payload = {
      taxName,
      taxRate,
    };
    const validation = validateTax({
      taxName,
      taxRate,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const taxData = await partnerInstance().post("/addTax", payload);
    const { code, msg } = taxData.data;
    if (code === 200) {
      toast.success(msg, { containerId: "B" });
      setShow(false);
      getTaxes();
      if (info) {
        history.push("/partners/availablity");
      }
      clearValues();
    } else if (code === 201) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const clearValues = () => {
    setTaxName("");
    setTaxRate("");
  };

  // const updateTax = async (e) => {
  //   e.preventDefault();
  //   const payload = {
  //     taxName,
  //     taxRate,
  //   };
  //   // const validation = validateInvoiceSequencing({
  //   //   invoiceno,
  //   //   invoiceprefix,
  //   // });
  //   // setErrors(validation.errors);
  //   // if (!validation.isValid) {
  //   //   return;
  //   // }
  //   const taxData = await partnerInstance().put(`/editTax/${ID}`, payload);
  //   const { code, msg } = taxData.data;
  //   if (code === 200) {
  //     toast.success(msg, { containerId: 'B' });
  //     setShow(false);
  //     // getInvoiceSequencing();
  //     // history.push('/partners/staff');
  //     clearValues();
  //   } else {
  //     toast.error(msg, { containerId: 'B' });
  //   }
  // };

  const handleHide = () => {
    setShowEdit(false);
    setShowDelete(false);
    setShowDefault(false);
    setTaxClaculation(false);
    const clearValues = () => {
      setTaxName("");
      setTaxRate("");
    };
    clearValues();
  };
  const handleShowEdit = (id, taxname, taxrate) => {
    setTaxId(id);
    setTaxName(taxname);
    setTaxRate(taxrate);
    setShowEdit(true);
    setErrors("");
  };
  const handleShowDelete = (id) => {
    setTaxId(id);
    setShowDelete(true);
  };

  const handleDefault = () => {
    setShowDefault(true);
  };

  const handletaxshow = () => {
    setTaxClaculation(true);
  };

  const taxCalculate = (e) => {
    if (e.target.name === "Exclude") {
      setTaxExclude(true);
      setTaxInclude(false);
    } else {
      setTaxInclude(true);
      setTaxExclude(false);
    }
  };

  const updateTaxCalculate = async (e) => {
    e.preventDefault();
    const payloadd = {
      taxExclude,
      taxInclude,
    };

    const taxCalcula = await partnerInstance().post("/taxCalculate/", payloadd);
    const { code, msg } = taxCalcula.data;
    if (code === 200) {
      setTaxClaculation(false);
      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Taxes">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="taxes">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="admin-title">
                {/* Taxes */}
                <Button className="btn-perple" onClick={handleShow}>
                  Add New
                </Button>
              </h2>

              <div>
                {taxess.length > 0 ? (
                  <>
                    <div className="partner-table">
                      <Table responsive="lg">
                        <thead>
                          <tr>
                            <th className="text-left">Tax Name</th>
                            <th className="text-center">Tax Rate</th>
                            <th className="text-right">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {taxess.map((el, i) => (
                            <tr>
                              <td className="text-left">{el.taxname}</td>
                              <td className="text-center">{el.taxrate}</td>
                              <td className="text-right">
                                <Button
                                  onClick={() =>
                                    handleShowEdit(
                                      el._id,
                                      el.taxname,
                                      el.taxrate
                                    )
                                  }
                                  className="btn-perple"
                                >
                                  Edit
                                  {/* <i className="fa fa-pencil-square-o" /> */}
                                </Button>
                                <Button
                                  className="btn-perple"
                                  onClick={() => handleShowDelete(el._id)}
                                >
                                  Delete
                                  {/* <i className="fa fa-trash" /> */}
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="tax-head">
                      <span className="mb-2">
                        <i className="fa fa-file-text" />
                      </span>

                      <p>
                        Your business name is displayed across many areas
                        including on your online booking profile, sales invoices
                        and messages to clients
                      </p>
                      <Button onClick={handleShow}> Add Tax</Button>
                    </div>
                  </>
                )}
              </div>

              <div className="mt-5 mb-5 default-tax">
                <div className="row">
                  <div className="col-md-8">
                    <h5>Tax Defaults</h5>
                    <p>
                      Setup the default taxes for your business, you can still
                      override defaults in the settings of individual products
                      and services
                    </p>
                  </div>
                  <div className="col-md-4 text-right">
                    <Button onClick={handleDefault}>Apply Defaults</Button>
                  </div>
                </div>
              </div>

              <div className="tax-show mt-5 mb-5">
                <Card>
                  <Card.Img variant="top" />
                  <Card.Body>
                    <Card.Text>
                      <h5>{company}</h5>
                      <p>
                        Services Default:
                        {showDefaultTax}
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>

              <div className="tax-calculation mt-5 mb-5">
                <h5>Tax Calculation</h5>
                <p>
                  Your retail prices are{" "}
                  {taxInclude ? "including taxes" : "excluding taxes"}.{" "}
                  <span
                    role="presentation"
                    onClick={handletaxshow}
                    className="text-primary"
                  >
                    Change this setting
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Add Tax
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={addTaxx}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Tax name
                <span className="red-color">*</span>
              </Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Tax name"
                onChange={(e) => setTaxName(e.target.value)}
                value={taxName}
              />
              {errors.taxName && <p className="error">{errors.taxName}</p>}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Tax Rate
                <span className="red-color">*</span>
                (%)
              </Form.Label>
              <Form.Control
                size="lg"
                type="number"
                placeholder="Tax rate (%)"
                onChange={(e) => setTaxRate(e.target.value)}
                value={taxRate}
              />
              {errors.taxRate && <p className="error">{errors.taxRate}</p>}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="btn-perple">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEdit} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Edit Tax
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditTax
            taxId={taxId}
            taxName={taxName}
            taxRate={taxRate}
            setTaxName={setTaxName}
            setTaxRate={setTaxRate}
            setShowEdit={setShowEdit}
            getTaxes={() => getTaxes()}
            setErrors={setErrors}
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>

      <Modal show={showDelete} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Delete Tax
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DeleteTax
            taxId={taxId}
            setShowDelete={setShowDelete}
            getTaxes={() => getTaxes()}
          />
        </Modal.Body>
        <Modal.Footer />
      </Modal>

      <Modal show={showDefault} onHide={handleHide} className="discount-12">
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Select Default Tax
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DefaultTax
            taxess={taxess}
            setShowDefault={setShowDefault}
            setDefaultTaxValue={setDefaultTaxValue}
            defaultTaxValue={defaultTaxValue}
            getTaxes={() => getTaxes()}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showtaxcalculation}
        onHide={handleHide}
        className="tax-calculation-popup"
      >
        <Modal.Header closeButton>
          <div />
          <Modal.Title id="example-custom-modal-styling-title ">
            Change Tax Calculation
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div className="tax-calculation-content">
              <div className="tax-sale">
                This change will have a big impact on your sales. Make sure you
                understand all implications
              </div>

              <div className="tax-cal-box">
                <div className="online-booking">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="Exclude"
                      onChange={(e) => taxCalculate(e)}
                      value={taxExclude}
                      checked={taxExclude}
                    />
                    <span className="slider round" />
                  </label>
                </div>
                <div className="cal-box-detail">
                  <h4>Retail Prices Exclude Tax</h4>
                  <p>
                    If selected, all taxes will be calculated using this
                    formula:
                    <span>Tax = Retail * Tax</span>
                    For example: 20% tax on a $10.00 item comes to $2.00
                  </p>
                </div>
              </div>

              <div className="tax-cal-box">
                <div className="online-booking">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="Include"
                      onChange={(e) => taxCalculate(e)}
                      value={taxInclude}
                      checked={taxInclude}
                    />
                    <span className="slider round" />
                  </label>
                </div>
                <div className="cal-box-detail">
                  <h4>Retail Prices Include Tax</h4>
                  <p>
                    If selected, all taxes will be calculated using this
                    formula:
                    <span>
                      Tax = (Tax Rate * Retail Price) / (1 + Tax Rate)
                    </span>
                    For example: 20% tax on a $10.00 item comes to $1.67
                  </p>
                </div>
              </div>
            </div>

            <div className="text-right">
              <Button className="btn-perple" onClick={updateTaxCalculate}>
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </PartnerWrapper>
  );
};

export default Taxes;
function EditTax({
  taxId,
  taxName,
  taxRate,
  setShowEdit,
  setTaxName,
  setTaxRate,
  getTaxes,
  setErrors,
  errors,
}) {
  const updateTax = async (e) => {
    e.preventDefault();
    const payloadd = {
      taxName,
      taxRate,
    };
    const validation = validateTax({
      taxName,
      taxRate,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }

    const updatetax = await partnerInstance().put(
      `/editTax/${taxId}`,
      payloadd
    );
    const { code, msg } = updatetax.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      setShowEdit(false);
      getTaxes();
      toast.success(msg, { containerId: "B" });
      clearValues();
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };
  const clearValues = () => {
    setTaxName("");
    setTaxRate("");
  };
  return (
    <Form onSubmit={updateTax}>
      <Form.Group>
        <Form.Label>Tax name</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          placeholder="Tax name"
          onChange={(e) => setTaxName(e.target.value)}
          value={taxName}
        />
        {errors.taxName && <p className="error">{errors.taxName}</p>}
      </Form.Group>
      <Form.Group>
        <Form.Label>Tax Rate(%)</Form.Label>
        <Form.Control
          size="lg"
          type="number"
          placeholder="Tax rate(%)"
          onChange={(e) => setTaxRate(e.target.value)}
          value={taxRate}
        />
        {errors.taxRate && <p className="error">{errors.taxRate}</p>}
      </Form.Group>
      <div className="text-right p-0">
        <Button variant="primary" type="submit" className="btn-perple">
          Save
        </Button>
      </div>
    </Form>
  );
}

function DeleteTax({ taxId, setShowDelete, getTaxes }) {
  const deleteTax = async (e) => {
    e.preventDefault();
    const payload = {
      deleteId: taxId,
    };
    const updatetax = await partnerInstance().post("/deleteTax/", payload);
    const { code, msg } = updatetax.data;
    if (code === 200) {
      // setCategoryname(categoryAdded.categoryname);
      setShowDelete(false);
      getTaxes();
      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={deleteTax} className="text-center">
      <Form.Group>
        <Form.Label>Delete Tax</Form.Label>
        <p>Are You Sure You Want To Delete This Tax ?</p>
      </Form.Group>
      <div className="text-right p-0">
        <Button variant="primary" type="submit" className="btn-perple">
          Delete
        </Button>
      </div>
    </Form>
  );
}
function DefaultTax({
  taxess,
  setShowDefault,
  getTaxes,
  setDefaultTaxValue,
  defaultTaxValue,
}) {
  const defaultTax = async (e) => {
    e.preventDefault();
    const payload = {
      Tax: defaultTaxValue,
    };

    const updatetax = await partnerInstance().post("/defaultTax/", payload);
    const { code, msg } = updatetax.data;
    if (code === 200) {
      setShowDefault(false);
      getTaxes();
      toast.success(msg, { containerId: "B" });
    } else if (code === 409) {
      toast.error(msg, { containerId: "B" });
    } else {
      toast.error(msg, { containerId: "B" });
    }
  };

  return (
    <Form onSubmit={defaultTax}>
      <div>
        <Form.Group>
          <select
            className="browser-default custom-select"
            onChange={(e) => setDefaultTaxValue(e.target.value)}
            value={defaultTaxValue}
          >
            <option value="No Tax">No Tax </option>
            {taxess.map((item, i) => (
              <option key={i} value={item._id}>
                {item.taxname}
              </option>
            ))}
          </select>
        </Form.Group>
      </div>
      <div className="text-right p-0">
        <Button type="submit" className="btn-perple">
          Set
        </Button>
      </div>
    </Form>
  );
}

EditTax.propTypes = {
  taxId: PropTypes.string.isRequired,
  taxName: PropTypes.string.isRequired,
  taxRate: PropTypes.string.isRequired,
  setShowEdit: PropTypes.string.isRequired,
  setTaxName: PropTypes.func.isRequired,
  setTaxRate: PropTypes.func.isRequired,
  getTaxes: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
};

DeleteTax.propTypes = {
  taxId: PropTypes.string.isRequired,
  getTaxes: PropTypes.func.isRequired,
  setShowDelete: PropTypes.func.isRequired,
};

DefaultTax.propTypes = {
  taxess: PropTypes.arrayOf(PropTypes.array).isRequired,
  setShowDefault: PropTypes.func.isRequired,
  getTaxes: PropTypes.func.isRequired,
  setDefaultTaxValue: PropTypes.func.isRequired,
  defaultTaxValue: PropTypes.func.isRequired,
};
