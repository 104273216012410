import React from 'react';
import moment from 'moment';
import { server } from '../../config/keys';
import './notification.css';
import user from '../../assets/no-image.jpg';
import history from '../../config/history';

const NotificationPage = ({
  handleshownotify, wrapperRef, notification,
}) => {
  const Redirect = () => {
    if (localStorage.getItem('partnertoken')) {
      history.push('/partners/calendar');
    } else {
      history.push('/myappointments');
    }
  };

  const not = notification.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div className="notification-section" ref={wrapperRef}>
      <div className="notification">
        <button
          onClick={() => handleshownotify()}
          className="cancel"
          type="submit"
        >
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        {/* <button onClick={() => handleshownotify()} className="cancel" type="submit"><i className="fa fa-times" aria-hidden="true" /></button> */}
        <div className="heading">
          <h6>Notification</h6>
          {/* <h6>Mark all as Read</h6> */}
        </div>
        <div className="notify">
          {not && not.length > 0 ? (
            <>
              {not && not.map((el) => (
                <>
                  <div
                    role="presentation"
                    className="notification-row"
                    onClick={Redirect}
                  >
                    <div className="profile-text">
                      {localStorage.getItem('partnertoken') ? (
                        <img src={el.customerId.image ? `${server}/${el.customerId.image}` : user} alt="jnjk" />
                      ) : (
                        <img src={el.partnerid.photo ? `${server}/${el.partnerid.photo}` : user} alt="jnjk" />
                      )}
                      <div className="d-flex flex-column align-items-start">
                        <h5>{el.message}</h5>
                        <span className="date">
                          <i className="fa fa-clock-o" />
                          {moment(el.createdAt).format('YYYY-MM-DD')}
                          {' '}
                          {moment(el.createdAt).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <div className="noservice-found"><h3>No Notifications</h3></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationPage;
