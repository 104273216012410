/* eslint-disable radix */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
import { partnerInstance } from "../../../config/axios";
import history from "../../../config/history";
import PartnerContext from "../../../context/context";
import Loader from "../../loader/loader";
import calendar from "../../../assets/calendar-perple.png";
import revenue from "../../../assets/revenue-perple.png";
import customer from "../../../assets/customer-perple.png";
import staff from "../../../assets/staff-perple.png";

let totalRevenue = 0;
const PartnerDashboard = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalStaff, setTotalStaff] = useState(0);
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getData = useCallback(async () => {
    setLoading(true);
    const accountData = await partnerInstance().get("/partnerDashboard");

    const { code, partnerData } = accountData.data;
    if (code === 200) {
      setTotalAppointments(partnerData.appointmentId.length);
      setTotalStaff(partnerData.staff.length);
      setTotalCustomer(partnerData.clientId.length);
      setAppointmentData(partnerData.appointmentId);
      totalRevenue = 0;
      partnerData.appointmentId.forEach((el) => {
        if (el.invoiceId) {
          totalRevenue += parseInt(el.totalAmount);
        }
      });
      partnerData.voucher.forEach((el) => {
        el.voucherBuyByCustomer.forEach((item) => {
          totalRevenue += parseInt(item.price);
        });
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex((boo) => boo.sectionname === "Home");
      if (findBook > -1) {
        getData();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  const getStatus = (data) => {
    if (data === "Canceled") {
      return <span className=" badge cancelled">Cancelled</span>;
    }
    if (data === "Confirmed") {
      return <span className=" badge completed">Confirmed</span>;
    }
    if (data === "Started") {
      return <span className="badge started">Started</span>;
    }
    if (data === "Completed") {
      return <span className="badge completed">Completed</span>;
    }
    if (data === "Arrived") {
      return <span className="badge started">Arrived</span>;
    }
    return <span className="badge new">New</span>;
  };

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper heading="Dashboard">
      {/* {loading && (
      <Loader />
      )} */}
      <div className="dashboard">
        <div className="container">
          {/* <h2 className="admin-title">Dashboard</h2> */}
          <div className="row">
            <div className="col-sm-3 col-md-3  col-lg-3">
              <div className="card">
                <Link to="/partners/revenue">
                  <div className="card-body">
                    <div className="card-box">
                      <div className="col-auto">
                        <span className="card-icon">
                          <img src={calendar} alt="" />
                        </span>
                        {/* <span className="fa fa-calendar text-muted" /> */}
                      </div>
                      <div className="col">
                        <h6 className="text-muted mb-2">Total Appointments</h6>
                        <span className="font-weight-700 h4 mb-0">
                          {totalAppointments}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-md-3  col-lg-3">
              <div className="card">
                <Link to="/partners/staff">
                  <div className="card-body">
                    <div className="card-box">
                      <div className="col-auto">
                        <span className="card-icon">
                          <img src={revenue} alt="" />
                        </span>
                      </div>
                      <div className="col">
                        <h6 className="text-muted mb-2">Total Revenue</h6>
                        <span className="font-weight-700 h4 mb-0">
                          {username.currency} {totalRevenue}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-md-3  col-lg-3">
              <div className="card">
                <Link to="/partners/revenue">
                  <div className="card-body">
                    <div className="card-box">
                      <div className="col-auto">
                        {/* <span className="fa fa-users text-muted" /> */}
                        <span className="card-icon">
                          <img src={customer} alt="" />
                        </span>
                      </div>
                      <div className="col">
                        <h6 className="text-muted mb-2">Total Customers</h6>
                        <span className="font-weight-700 h4 mb-0">
                          {totalCustomer}
                        </span>
                      </div>
                      {/* <div className="col-auto">
                        <span className="fa fa-chart-bar text-muted" />
                      </div>
                      <div className="col">
                        <h6 className="text-muted mb-2">Total Revenue</h6>
                        <span className="font-weight-700 h4 mb-0">
                          {username.currency}
                          {' '}
                          {totalRevenue}
                        </span>
                      </div> */}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-md-3  col-lg-3">
              <div className="card">
                <Link to="/partners/customer">
                  <div className="card-body">
                    <div className="card-box">
                      <div className="col-auto">
                        {/* <span className="fa fa-user text-muted" /> */}
                        <span className="card-icon">
                          <img src={staff} alt="" />
                        </span>
                      </div>
                      <div className="col">
                        <h6 className="text-muted mb-2">Total Staff</h6>
                        <span className="font-weight-700 h4 mb-0">
                          {totalStaff}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="partner-header">
                    <h2>Booked Appointment</h2>
                  </div>
                  <div className="partner-table">
                    {appointmentData.length > 0 ? (
                      <Table responsive="lg">
                        <thead>
                          <tr>
                            <th className="text-left">Date</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Duration</th>
                            <th className="text-center">Customer</th>
                            <th className="text-center">Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {appointmentData.map((el) => (
                            <tr
                              onClick={() =>
                                history.push(
                                  `/partners/viewappointment?id=${el._id}`
                                )
                              }
                            >
                              <td className="text-left">
                                {el.appointmentDate}
                              </td>
                              <td className="text-center">
                                {" "}
                                {getStatus(el.appointmentStatus)}
                              </td>
                              <td className="text-center">
                                {Math.floor(parseInt(el.totalDuration) / 60) ===
                                0 ? (
                                  <>{parseInt(el.totalDuration) % 60} min</>
                                ) : (
                                  <>
                                    {Math.floor(
                                      parseInt(el.totalDuration) / 60
                                    )}
                                    h{" "}
                                    {parseInt(el.totalDuration) % 60 === 0 ? (
                                      ""
                                    ) : (
                                      <>
                                        ,{parseInt(el.totalDuration) % 60}
                                        min
                                      </>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="text-center">
                                {el.customerId ? el.customerId.firstname : ""}
                              </td>
                              <td className="text-center font-weight-700">
                                {el.totalAmount === "0"
                                  ? ""
                                  : username.currency}{" "}
                                {el.appointmentStatus === "New"
                                  ? "0"
                                  : el.totalAmount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p className="placeholder-text">No Appointment Found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PartnerWrapper>
  );
};

export default PartnerDashboard;
