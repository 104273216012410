import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Button, Form } from "react-bootstrap";
// import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PartnerWrapper from "../partnerwrapper/wrapper";
//import { stripeKey } from '../../../config/keys';
import { partnerInstance } from "../../../config/axios";
import { checkKeys } from "../../../function/validate";
import PartnerContext from "../../../context/context";
import history from "../../../config/history";
import Loader from "../../loader/loader";
import BookingScreen from "../../../assets/booking-2.png";
import cross from "../../../assets/cross2.png";
import check from "../../../assets/check-new.png";
// import history from '../../../config/history';
// const stripePromise = loadStripe(stripeKey);
const Connect = () => {
  const { username } = useContext(PartnerContext);
  const { sections } = username;
  const titleRef = useRef(sections);
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [show, setShow] = useState(false);

  const Step8 = () => {
    setShow(true);
  };

  const AccountHolder = useCallback(async () => {
    setLoading(true);
    const resData = await partnerInstance().get("/getStripeAccountStatus");

    if (resData.data.code === 200) {
      setAccountId(resData.data.status);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (titleRef.current) {
      const findBook =
        titleRef.current &&
        titleRef.current.findIndex(
          (boo) => boo.sectionname === "Online Booking"
        );
      if (findBook > -1) {
        AccountHolder();
      } else {
        history.push("/auth");
      }
    }
    // eslint-disable-next-line
  }, [titleRef.current]);

  if (loading) {
    return (
      <PartnerWrapper>
        <Loader />
      </PartnerWrapper>
    );
  }

  return (
    <PartnerWrapper>
      {/* {loading && (
      <Loader />
      )} */}
      <div className="w-100">
        {!show && accountId === null ? (
          <div className="partners-profile">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="signup-block-container online-booking-container">
                    <div className="cross-icon">
                      <Link to="/partners/dashboard">
                        <img src={cross} alt="" />
                      </Link>
                    </div>
                    <div className="sb-left">
                      <h2>
                        Incorporated card payments with no Show protection
                      </h2>
                      <ul className="booking-list">
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>
                              Say Goodbye to No Show and Late Cancellations
                            </h3>
                            <p>
                              Eleminate no shows and late cancellations by
                              charging clients
                            </p>
                          </div>
                        </li>
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>No Additional Fees</h3>
                            <p>
                              This feature is already in your Appointlee plan
                            </p>
                          </div>
                        </li>
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>Easy Instant setup</h3>
                            <p>Follow our guided steps</p>
                          </div>
                        </li>
                        <li className="booking-list-item">
                          <div className="bl-icon">
                            <img src={check} alt="" />
                          </div>
                          <div className="bl-content">
                            <h3>Secure PCI Compliance</h3>
                            <p>
                              Your information is safe with us, card details are
                              protected with end-to-end encryptions and visible
                              to you only
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div className="sb-btn">
                        <Button onClick={() => Step8()}>Start Now</Button>
                      </div>
                    </div>
                    <div className="sb-right">
                      <div className="sb-right-img">
                        <img src={BookingScreen} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Elements stripe={checkKeys()}>
            <CheckOutForm
              accountId={accountId}
              setShow={setShow}
              setLoading={setLoading}
            />
          </Elements>
        )}
      </div>
    </PartnerWrapper>
  );
};

export default Connect;

const CheckOutForm = ({ accountId, setLoading, setShow }) => {
  const stripe = useStripe();
  // const elements = useElements();
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [rountingNo, setRoutingNo] = useState("11000-000");
  const [accountNo, setAccountNo] = useState("");
  const [editForm, setEditFrom] = useState(false);
  const [loader, setLoader] = useState();

  const validateEm = (values) => {
    const errors = {};
    let isValid = true;
    if (!values.name) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!values.rountingNo) {
      errors.rountingNo = "Routing Number is required";
      isValid = false;
    }
    if (!values.accountNo) {
      errors.accountNo = "Account Number is required";
      isValid = false;
    }

    return { isValid, errors };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validation = validateEm({
      name,
      rountingNo,
      accountNo,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const result = await stripe.createToken("bank_account", {
      country: "CA",
      currency: "CAD",
      routing_number: rountingNo,
      account_number: accountNo,
      account_holder_name: name,
      account_holder_type: "individual",
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      setError(null);
      setLoading(true);
      const payload = {
        stripeToken: result.token.id,
      };
      const response = await partnerInstance().post(
        "/connecttostripe",
        payload
      );

      const { code, msg } = response.data;
      if (code === 200) {
        setLoading(false);
        window.location.href = `${response.data.link}`;
      } else {
        setLoading(false);
        toast.error(msg, { containerId: "B" });
      }
    }
  };

  const handelUpdate = async (event) => {
    event.preventDefault();

    setLoader(true);
    const validation = validateEm({
      name,
      rountingNo,
      accountNo,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    if (loader === true) {
      return <Loader />;
    }
    const result = await stripe.createToken("bank_account", {
      country: "CA",
      currency: "CAD",
      routing_number: rountingNo,
      account_number: accountNo,
      account_holder_name: name,
      account_holder_type: "individual",
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      setError(null);
      // setLoading(true);
      const payload = {
        stripeToken: result.token.id,
      };
      console.log("Payload::", payload);
      const response = await partnerInstance().post(
        "/connecttostripeupdate",
        payload
      );
      setLoader(false);
      const { code, msg } = response.data;
      if (code === 200) {
        // setLoading(false);

        window.location.href = `${response.data.link}`;
      } else {
        // setLoading(false);
        toast.error(msg, { containerId: "B" });
      }
    }
  };

  return (
    <div className="verify-container">
      <div className="container">
        <div className="row">
          {accountId === null ? (
            <Form className="col-md-12" onSubmit={handleSubmit}>
              <div className="w-100">
                <div className="verify-identity">
                  <h5>Please Add your Account</h5>
                  <Form.Group controlId="formBasicInput">
                    <Form.Control
                      type="text"
                      placeholder="Enter Account Holder Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </Form.Group>

                  <Form.Group controlId="formBasicInput">
                    <Form.Control
                      type="text"
                      placeholder="Enter Routing Number"
                      value={rountingNo}
                      onChange={(e) => setRoutingNo(e.target.value)}
                    />
                    {errors.rountingNo && (
                      <p className="error">{errors.rountingNo}</p>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicInput">
                    <Form.Control
                      type="number"
                      placeholder="Enter Account Number"
                      value={accountNo}
                      onChange={(e) => setAccountNo(e.target.value)}
                    />
                    {errors.accountNo && (
                      <p className="error">{errors.accountNo}</p>
                    )}
                  </Form.Group>
                  {/* <CardElement

                  id="card-element"
                  class="StripeElement StripeElement--invalid StripeElement--focus StripeElement--webkit-autofill"
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={handleChange}
                /> */}

                  {error}
                  <div className="verify-btn">
                    <Button
                      type="button"
                      className="btn-perple mr-2"
                      onClick={() => setShow(false)}
                    >
                      Back
                    </Button>
                    <Button type="submit" className="btn-perple">
                      Add Account
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            <div className="col-md-12">
              <div className="verify-identity">
                <div className="verify-btn text-center mt-0">
                  <Button className="identity-varify btn-perple">
                    Your Identity is Verified
                  </Button>
                </div>
                <br />
                <div className="verify-btn text-center mt-0">
                  <Button
                    className="identity-varify btn-perple"
                    onClick={() => setEditFrom(true)}
                  >
                    Edit your account details
                  </Button>
                </div>
              </div>
              {editForm ? (
                <div className="verify-identity">
                  <div className="verify-btn text-center mt-0">
                    <Form onSubmit={handelUpdate}>
                      <div className="w-100">
                        <div className="verify-identity signup-verify-identity">
                          <h5>update your account details</h5>
                          <Form.Group controlId="formBasicInput">
                            <Form.Control
                              type="text"
                              placeholder="Enter Account Holder Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            {errors.name && (
                              <p className="error">{errors.name}</p>
                            )}
                          </Form.Group>

                          <Form.Group controlId="formBasicInput">
                            <Form.Control
                              type="text"
                              placeholder="Enter Routing Number"
                              value={rountingNo}
                              onChange={(e) => setRoutingNo(e.target.value)}
                            />
                            {errors.rountingNo && (
                              <p className="error">{errors.rountingNo}</p>
                            )}
                          </Form.Group>
                          <Form.Group controlId="formBasicInput">
                            <Form.Control
                              type="number"
                              placeholder="Enter Account Number"
                              value={accountNo}
                              onChange={(e) => setAccountNo(e.target.value)}
                            />
                            {errors.accountNo && (
                              <p className="error">{errors.accountNo}</p>
                            )}
                          </Form.Group>

                          {error}
                          <div className="verify-btn">
                            <Button type="submit" className="btn-perple">
                              Update Account
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
