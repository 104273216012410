import openSocket from 'socket.io-client';
import { server } from './config/keys';

const socket = openSocket(server, {
  transports: ['websocket'],
  rejectUnauthorized: false,
});

socket.on('connect', () => {
  console.log('connected');
});

const time = (cb) => {
  socket.on('countdown', (data) => {
    cb(data);
  });
};

const time2 = (cb) => {
  socket.on('book', (data) => {
    cb(data);
  });
};

export {
  time,
  socket,
  time2,
};
