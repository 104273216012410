import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
// import InputGroup from 'react-bootstrap/InputGroup';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast } from "react-toastify";
import Closedatemodal from "./closedatemodal";
import { partnerInstance } from "../../../config/axios";

const CloseStaff = ({ closedDate, setClosedDate }) => {
  const [dateModal, setDateModal] = useState(false);
  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [call, setCall] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    fetchUsers();
    // changes made by kashif
    // eslint-disable-next-line
  }, [dateModal, call]);

  const fetchUsers = async () => {
    const response = await partnerInstance().get("/listCloseDate");

    if (response.data.closeddate.length > 0) {
      setData(response.data.closeddate);
      setClosedDate(response.data.closeddate);
      setLocation(response.data.partnerLocation[0].locationName);
      // getClosedDate();
    } else {
      setData([]);
    }
  };

  const confirmPopup = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to delete this Closed Date.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            removeClosedDate(id);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const removeClosedDate = async (id) => {
    setCall(false);
    const response = await partnerInstance().delete(`/deleteclosedDate/${id}`);
    const { code } = response.data;
    if (code === 200) {
      toast.success("closed Date Deleted Successfully", {
        containerId: "B",
      });
      const responseData = await partnerInstance().get("/listCloseDate");
      setClosedDate(responseData.data.closeddate);
      setCall(true);
    }
  };

  return (
    <div className="close-staff">
      <div className="container">
        <div className="close-date user-12">
          <Button
            onClick={() => {
              setDateModal(true);
              setItem(null);
            }}
            className="btn-perple"
          >
            New Closed Date
          </Button>
        </div>
        <div className="partner-table">
          <Table responsive="lg">
            <thead>
              <tr>
                <th>From Date</th>
                <th>To Date</th>
                <th className="text-center">No. of days</th>
                <th>Locations</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                <></>
              ) : (
                <>
                  <tr>
                    <td colSpan={6} className="placeholder-text">
                      <div className="noservice-found w-100">
                        <h3>No Holidays</h3>
                      </div>
                    </td>
                  </tr>
                </>
              )}
              {data.map((item, i) => (
                <tr>
                  <td>{`${item.from}`}</td>
                  <td>{`${item.to}`}</td>
                  <td className="text-center">
                    {Math.round(
                      (new Date(item.to) - new Date(item.from)) /
                        (1000 * 60 * 60 * 24)
                    )}
                  </td>
                  <td>{location}</td>
                  <td width="20%">{item.description}</td>
                  <td>
                    <Button
                      className="btn-perple-icon"
                      onClick={() => {
                        setDateModal(true);
                        setItem(item);
                      }}
                    >
                      Edit
                      {/* <i className="fa fa-edit" /> */}
                    </Button>
                    <Button
                      className="btn-perple-icon"
                      onClick={() => {
                        confirmPopup(item._id);
                      }}
                    >
                      Delete
                      {/* <i className="fa fa-trash" /> */}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Closedatemodal show={dateModal} setShow={setDateModal} item={item} />
    </div>
  );
};

export default CloseStaff;
