import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { facebookId, googleId } from "../../config/keys";
import { userInstance } from "../../config/axios";
import "./voucher.css";

const VoucherListing = ({
  vouchers,
  servicesLength,
  partnerid,
  currency,
  locationname,
  country,
  storeImage,
  companyName,
}) => {
  const [partnerVouchers, setPartnerVouchers] = useState([]);
  const [partnerServices, setPartnerServices] = useState(0);
  const [id, setId] = useState("");
  const [currencyy, setCurrency] = useState("");
  const [locName, setLocName] = useState("");
  const [countryy, setCountry] = useState("");
  const [image, setImage] = useState("");
  const [company, setCompany] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    setPartnerVouchers(vouchers);
    setPartnerServices(servicesLength);
    setId(partnerid);
    setCurrency(currency);
    setLocName(locationname);
    setCountry(country);
    setImage(storeImage);
    setCompany(companyName);
    // eslint-disable-next-line
  }, [
    vouchers,
    servicesLength,
    partnerid,
    currency,
    locationname,
    country,
    storeImage,
  ]);

  const responseFacebook = async (response) => {
    if (response.email) {
      const name = response.name.split(" ");
      const payload = {
        firstname: name[0],
        lastname: name[1],
        email: response.email,
        facebookId: response.userID,
      };
      const saveData = await userInstance().post("/facebookLogin", payload);
      const { code, msg, token, userid } = saveData.data;
      if (code === 200) {
        toast.success(msg, { containerId: "B" });
        if (token) {
          localStorage.setItem("usertoken", token);
          localStorage.setItem("userid", userid);
          handleClose();
          window.location.href = `/storedetail/?partnerid=${partnerid}`;
        }
      } else if (code === 409) {
        toast.error(msg, { containerId: "B" });
      } else {
        toast.error(msg, { containerId: "B" });
      }
    } else {
      toast.error("No Email in FB Plz Signup Manually", { containerId: "B" });
    }
  };

  const responseGoogle = async (response) => {
    if (!response.error) {
      if (response.profileObj.email) {
        const name = response.profileObj.name.split(" ");
        const payload = {
          firstname: name[0],
          lastname: name[1],
          email: response.profileObj.email,
          googleId: response.googleId,
        };
        const saveData = await userInstance().post("/googleLogin", payload);
        const { code, msg, token, userid } = saveData.data;
        if (code === 200) {
          toast.success(msg, { containerId: "B" });
          if (token) {
            localStorage.setItem("usertoken", token);
            localStorage.setItem("userid", userid);
            window.location.href = `/storedetail/?partnerid=${partnerid}`;
            handleClose();
          }
        } else if (code === 409) {
          toast.error(msg, { containerId: "B" });
        } else {
          toast.error(msg, { containerId: "B" });
        }
      } else {
        toast.error("No Email in Google Plz Signup Manually", {
          containerId: "B",
        });
      }
    }
    //  else {
    //   toast.error(response.error, { containerId: 'B' });
    // }
  };
  // const options = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   arrows: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       options: {
  //         slidesToShow: 2,
  //         arrows: true,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       options: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       options: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className="voucherlisting new-voucherlisting">
      <div className="my-voucher">
        <div className="voucher-section">
          <div className="voucher-section-list">
            <Slider {...settings}>
              {partnerVouchers.map((el) => (
                <div className="voucher-section-item">
                  <div className="signle-voucher">
                    <div className="voucher-card">
                      {localStorage.getItem("usertoken") ? (
                        <>
                          {el.voucherBuyByCustomer.length === +el.noofsales ? (
                            <div className="voucher-body">
                              <span
                                role="presentation"
                                onClick={() =>
                                  toast.error(
                                    "This voucher can not be sold because limit exceeds",
                                    { containerId: "B" }
                                  )
                                }
                              >
                                <div className="voucher-body-box">
                                  <h4>Voucher Value </h4>
                                  <div className="text-center voucher-name">
                                    <h2>
                                      {currencyy} {el.voucherValue}
                                    </h2>
                                  </div>
                                  <div className="text-center gift-voucher">
                                    {partnerServices > 0 && (
                                      <>
                                        {partnerServices ===
                                        el.serviceid.length ? (
                                          <h4>Redeem on all services</h4>
                                        ) : (
                                          <h4>
                                            Redeem in {el.serviceid.length}{" "}
                                            Services
                                          </h4>
                                        )}
                                      </>
                                    )}
                                    <h4>Retail Price</h4>
                                    <h5>
                                      {currencyy} {el.retailPrice}
                                    </h5>
                                  </div>
                                </div>
                              </span>
                            </div>
                          ) : (
                            <>
                              <div className="voucher-body">
                                <Link
                                  to={{
                                    pathname: `/voucherDetail/${id}`,
                                    state: {
                                      voucher: el,
                                      rt: el.retailPrice,
                                      currencyy,
                                      locName,
                                      countryy,
                                      storeImage: image,
                                      company,
                                      partnerServices:
                                        partnerServices === el.serviceid.length
                                          ? "All"
                                          : `${el.serviceid.length}`,
                                    },
                                  }}
                                >
                                  <div className="voucher-body-box">
                                    <h4>Voucher Value </h4>
                                    <div className="text-center voucher-name">
                                      <h2>
                                        {currencyy} {el.voucherValue}
                                      </h2>
                                    </div>
                                    <div className="text-center gift-voucher">
                                      {partnerServices > 0 && (
                                        <>
                                          {partnerServices ===
                                          el.serviceid.length ? (
                                            <h4>Redeem on all services</h4>
                                          ) : (
                                            <h4>
                                              Redeem in {el.serviceid.length}{" "}
                                              Services
                                            </h4>
                                          )}
                                        </>
                                      )}
                                      {/* <h4>Retail Price</h4> */}
                                      <h5>
                                        {currencyy} {el.retailPrice}
                                      </h5>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <div className="book-now">
                          <div className="voucher-body">
                            <span
                              role="presentation"
                              onClick={() => setShow(true)}
                            >
                              <div className="voucher-body-box">
                                <h4>Voucher Value</h4>
                                <div className="text-center voucher-name ">
                                  <h2>
                                    {currencyy} {el.voucherValue}
                                  </h2>
                                </div>

                                <div className="text-center gift-voucher pt-0">
                                  {partnerServices > 0 && (
                                    <>
                                      {partnerServices ===
                                      el.serviceid.length ? (
                                        <h4>Redeem on all services</h4>
                                      ) : (
                                        <h4>
                                          Redeem in {el.serviceid.length}{" "}
                                          Services
                                        </h4>
                                      )}
                                    </>
                                  )}
                                  <h4>Retail Price</h4>
                                  <h5>
                                    {currencyy} {el.retailPrice}
                                  </h5>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="discount-12"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title ">
                          Signup/Login To Continue
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="select-serve customerlogin">
                          {" "}
                          <div className="store-services ">
                            {" "}
                            <div className="row">
                              {" "}
                              <div className="col-md-12">
                                <div className="service-card">
                                  <div className="login-btn">
                                    <div className="signup-email">
                                      <Link to="/register">
                                        <i className="fa fa-envelope" /> Sign up
                                        with email
                                      </Link>{" "}
                                    </div>
                                    <div className="google-btn">
                                      <GoogleLogin
                                        clientId={googleId}
                                        buttonText="Continue with google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy="single_host_origin"
                                      />
                                      {/* <img src={google} alt="google-icon" /> */}
                                      {/* <Button variant="default">
                              Signup with Google

                              {' '}
                            </Button> */}{" "}
                                    </div>
                                    <div className="facebook-btn">
                                      {/* <img src={facebookIcon} alt="facebook-icon" /> */}
                                      <FacebookLogin
                                        appId={facebookId}
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        textButton="Continue with FaceBook"
                                      />
                                      {/* <Button variant="default">
                              Signup with Facebook

                              {' '}
                            </Button> */}{" "}
                                    </div>{" "}
                                  </div>
                                  <div className="already-account">
                                    <h5>Already have an account?</h5>
                                    <Link
                                      to={{
                                        pathname: "/login",
                                        state: {
                                          partnerid,
                                        },
                                      }}
                                    >
                                      Log in now
                                    </Link>{" "}
                                  </div>{" "}
                                </div>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>
                      </Modal.Body>
                      .
                      <Modal.Footer />
                    </Modal>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VoucherListing;
