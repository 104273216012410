import React from 'react';
// import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import history from '../../../config/history';
import menu from '../../../assets/partner/menu-icon.png';
import admin from '../../../assets/store/no-image.jpg';

const AdminHeader = ({ setMenuToggle1, menutoggle1, name }) => {
  const LogOut = () => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminid');
    history.push('/admin/login');
  };

  return (
    <div className="super-admin-header">
      <div className="menu-toggle">
        <span onClick={() => setMenuToggle1(!menutoggle1)}>
          <img src={menu} alt="Menu" />
        </span>
      </div>

      <div className="super-admin-info">
        <img src={admin} alt="Admin" />
        <DropdownButton id="dropdown-basic-button" title={name}>
          <Link className="dropdown-item" to="/admin/changepassword">Change Password</Link>
          <Link className="dropdown-item" to="/admin/pricing">Settings</Link>
          <Dropdown.Item onClick={LogOut}>
            Logout
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
};

AdminHeader.propTypes = {
  setMenuToggle1: PropTypes.func.isRequired,
  menutoggle1: PropTypes.bool.isRequired,
};
export default AdminHeader;
