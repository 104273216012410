import React, { useState, useEffect } from 'react';
import './forget.css';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { validateForget, validateReset } from '../../function/validate';
import { userInstance } from '../../config/axios';
import history from '../../config/history';

const Forget = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [reseterrors, setResetErrors] = useState(false);
  const [passType, setPassType] = useState('password');
  const [passType2, setPassType2] = useState('password');
  const [id, setId] = useState(false);
  useEffect(() => {
    const getData = async () => {
      const value = queryString.parse(window.location.search);
      const idd = value.id;
      
      if (idd) {
        setId(true);
      }
    };
    getData();
  }, []);
  const forgetPassword = async (e) => {
    e.preventDefault();
    
    const validation = validateForget({
      email,
    });
    setErrors(validation.errors);
    if (!validation.isValid) {
      return;
    }
    const payload = { email };
    const saveData = await userInstance().post('/forgetPass', payload);
    const { code, msg } = saveData.data;
    const value = queryString.parse(window.location.search);
    const idd = value.id;
    
    if (idd) {
      setId(true);
    }
    if (code === 200) {
      setEmail('');
      toast.success(msg, { containerId: 'B' });
    } else {
      toast.error(msg, { containerId: 'B' });
    }
  };
  const resetPassword = async (e) => {
    e.preventDefault();

    const validation = validateReset({
      password,
      repeatPassword: confirmPassword,
    });
    setResetErrors(validation.reseterrors);
    if (!validation.isValid) {
      return;
    }
    const token = window.location.href.split('id=')[1];
  
    if (token) {
      const payload = { password, token };
      const saveData = await userInstance().post('/reset', payload);
      const { code, msg } = saveData.data;
      if (code === 200) {
        setPassword('');
        setConfirmPassword('');
        toast.success(msg, { containerId: 'B' });
        history.push('/login');
      } else {
        toast.error(msg, { containerId: 'B' });
      }
    } else {
      setResetErrors({ unexpected: 'Invalid request' });
    }
  };

  return (
    <>
      <Helmet>
        <body className="login-view-body" />
      </Helmet>
      <div className="forget-password-section">
        <div className="forget-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {id ? (
                  <div className="forget-form login">
                    <h1>Reset Password</h1>
                    <div className="reset-box">
                      <Form onSubmit={resetPassword}>
                        <Form.Group controlId="formBasicloginone">
                          <Form.Control
                            type={passType}
                            placeholder="New Password"
                            name="password"
                            value={password}
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i
                            role="presentation"
                            className={
                                passType === 'password'
                                  ? 'fa fa-eye-slash'
                                  : 'fa fa-eye'
                              }
                            onClick={() => setPassType(
                              passType === 'password' ? 'text' : 'password',
                            )}
                          />
                          {reseterrors.password && (
                          <span className="error">{reseterrors.password}</span>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formBasicloginone">
                          <Form.Control
                            type={passType2}
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            autoComplete="off"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <i
                            role="presentation"
                            className={
                                passType2 === 'password'
                                  ? 'fa fa-eye-slash'
                                  : 'fa fa-eye'
                              }
                            onClick={() => setPassType2(
                              passType2 === 'password' ? 'text' : 'password',
                            )}
                          />
                          {reseterrors.repeatPassword && (
                          <span className="error">
                            {reseterrors.repeatPassword}
                          </span>
                          )}
                        </Form.Group>
                        <Button type="submit" className="l-btn">Continue</Button>
                      </Form>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="forget-form login">
                      <h1>Forgot Password</h1>
                      <p>Enter your email address to reset your password</p>
                      <div className="forget-box">
                        <Form onSubmit={forgetPassword}>
                          <Form.Group controlId="formBasicloginone">
                            <Form.Control
                              type="email"
                              placeholder="Email address"
                              name="email"
                              value={email}
                              autoComplete="off"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && (
                            <span className="error">{errors.email}</span>
                            )}
                          </Form.Group>
                          <Button type="submit" className="l-btn">Reset Password</Button>
                        </Form>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forget;
